import {
  Banner,
  Button,
  Checkbox,
  DateInput,
  FileAddIcon,
  Input,
  NavigationButton,
  PrinterIcon,
  Select,
} from "@jutro/ui";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Loader } from "components/new";
import {
  DoctorMSDoctor,
  DoctorMSPatient,
  useDoctorGetDoctorsQuery,
} from "lib/graphql/megaSchema";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { toaster } from "lib/tools/toaster";
import { DeclaredStaffCard } from "views/Patient/Profile/DeclaredStaffCard";
import { LegalGuardianDetails } from "views/Patient/Profile/LegalGuardianDetails";
import { usePatient } from "views/Patient/Profile/Patient.hooks";
import { useDeclarationCreatorForm } from "views/Patient/Profile/useDeclarationCreatorForm";
import { useGetAsyncPatientsData } from "views/Patient/Profile/useGetAsyncPatientsData";
import { declarationTypeOptions } from "views/Patient/Profile/utils";

export const DeclarationCreatorFormWrapper = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { patient: patientData } = usePatient(patientId);
  const { data: doctorsData } = useDoctorGetDoctorsQuery();

  if (!doctorsData || !patientData) {
    return <Loader />;
  }

  const { doctorDoctors } = doctorsData;

  return (
    <DeclarationCreatorForm patient={patientData} doctors={doctorDoctors} />
  );
};

type DeclarationCreatorFormProps = {
  patient: DoctorMSPatient | any;
  doctors: DoctorMSDoctor[] | any;
};

const DeclarationCreatorForm = ({
  patient,
  doctors,
}: DeclarationCreatorFormProps) => {
  const { removeTile } = useTileSystem();

  const {
    control,
    vojevodshipsWithCities,
    isPatientNotAdult,
    availableDoctors,
    availableNurse,
    availableMidwife,
    legalGuardian,
    doctorDeclaration,
    nurseDeclaration,
    midwifeDeclaration,
    doctorDeclarationData,
    nurseDeclarationData,
    midwifeDeclarationData,
    generatePDFLoading,
    createNfzEventLoading,
    isMidwifeAvailableForPatient,
    isPatientRegisteredFromMobile,
    handleGenerateDeclarationPdf,
    addNewDeclaration,
    handleSubmit,
  } = useDeclarationCreatorForm({
    patient,
    doctors,
  });

  const { loadPatientsData } = useGetAsyncPatientsData();

  return (
    <form
      className="flex flex-col gap-2"
      onSubmit={handleSubmit((data) => {
        toaster.promise(addNewDeclaration(data), {
          loading: "Dodaje deklaracje...",
          success: "Dodano deklarację!",
          error: "Nie udało się dodać deklaracji",
        });
      })}
    >
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
        <div className="flex justify-between">
          <h4 className="font-heading-4">Kreator deklaracji</h4>
          <NavigationButton
            onClick={() => removeTile("patient-declaration-creator")}
          />
        </div>

        <div className="hidden">
          <Controller
            control={control}
            name="pesel"
            render={({ field: { value, onChange } }) => (
              <Input value={value ?? ""} onChange={onChange} />
            )}
          />
        </div>

        <div className="flex flex-col gap-1">
          <span className="font-label">Typ deklaracji</span>
          <div className="flex flex-wrap gap-x-6 rounded-md bg-jutro-new-warm-gray-50 p-2">
            <Controller
              name="doctorDeclaration"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="Lekarska"
                  name="Doctor"
                  checked={value}
                  onChange={() => onChange(!value)}
                  disabled={!availableDoctors.length}
                />
              )}
            />
            <Controller
              name="nurseDeclaration"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="Pielęgniarska"
                  name="Nurse"
                  checked={value}
                  onChange={() => onChange(!value)}
                  disabled={!availableNurse.length}
                />
              )}
            />
            <Controller
              name="midwifeDeclaration"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="Położnicza"
                  name="Midwife"
                  checked={value}
                  onChange={() => onChange(!value)}
                  disabled={
                    !availableMidwife.length || !isMidwifeAvailableForPatient
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex flex-1">
            <Controller
              control={control}
              name="organizationId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  label="Województwo"
                  isSearchable={false}
                  error={error?.message}
                  options={vojevodshipsWithCities}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="date"
              defaultValue={dayjs().toDate()}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateInput
                  label="Data na deklaracji"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                isSearchable={false}
                label="Źródło"
                placeholder="Wybierz z listy"
                error={error?.message}
                options={declarationTypeOptions}
                value={value}
                disabled={isPatientRegisteredFromMobile}
                onChange={(option) => onChange(option)}
              />
            )}
          />
        </div>

        {isPatientRegisteredFromMobile && (
          <div>
            <Banner
              title="Dlaczego nie mogę zmienić źródła deklaracji?"
              description="Pacjent rozpoczął proces deklarowania w aplikacji"
            />
          </div>
        )}

        {isPatientNotAdult && (
          <div>
            <Controller
              control={control}
              name="legalGuardian"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="flex flex-col gap-4">
                  <Select
                    placeholder="Wpisz numer PESEL i wybierz z listy"
                    label="Numer PESEL rodzica lub opiekuna"
                    isAsync
                    error={error?.message}
                    isSearchable
                    onChange={(input) => onChange(input)}
                    loadOptions={(search) => loadPatientsData(search)}
                    value={value ?? null}
                  />
                  {legalGuardian && (
                    <LegalGuardianDetails legalGuardianData={value?.value} />
                  )}
                </div>
              )}
            />
          </div>
        )}
      </div>

      {(doctorDeclaration || nurseDeclaration || midwifeDeclaration) && (
        <>
          {doctorDeclaration && (
            <DeclaredStaffCard
              control={control}
              name="doctorDeclarationData.staffData"
              howManyProviderChangesName="doctorDeclarationData.howManyProviderChanges"
              facilityChangeReasonName="doctorDeclarationData.facilityChangeReason"
              otherReasonProviderChange="doctorDeclarationData.otherReasonProviderChange"
              label="Lekarka / Lekarz*"
              legacyStaffCheckbox
              availableStaff={availableDoctors}
              declaredStaffData={doctorDeclarationData}
            />
          )}

          {nurseDeclaration && (
            <DeclaredStaffCard
              control={control}
              name="nurseDeclarationData.staffData"
              howManyProviderChangesName="nurseDeclarationData.howManyProviderChanges"
              facilityChangeReasonName="nurseDeclarationData.facilityChangeReason"
              otherReasonProviderChange="nurseDeclarationData.otherReasonProviderChange"
              label="Pielęgniarka / Pielęgniarz*"
              availableStaff={availableNurse}
              declaredStaffData={nurseDeclarationData}
            />
          )}

          {midwifeDeclaration && (
            <DeclaredStaffCard
              control={control}
              name="midwifeDeclarationData.staffData"
              howManyProviderChangesName="midwifeDeclarationData.howManyProviderChanges"
              facilityChangeReasonName="midwifeDeclarationData.facilityChangeReason"
              otherReasonProviderChange="midwifeDeclarationData.otherReasonProviderChange"
              label="Położna / Położny"
              availableStaff={availableMidwife}
              declaredStaffData={midwifeDeclarationData}
            />
          )}
        </>
      )}

      {(doctorDeclaration || nurseDeclaration || midwifeDeclaration) && (
        <div className="flex gap-2 rounded-lg bg-white p-4">
          <Button
            onClick={handleGenerateDeclarationPdf}
            type="button"
            icon={<PrinterIcon />}
            text="Wydrukuj"
            variant="secondary"
            loading={generatePDFLoading}
          />
          <Button
            text="Dodaj do profilu"
            icon={<FileAddIcon />}
            loading={createNfzEventLoading}
            type="submit"
          />
        </div>
      )}
    </form>
  );
};

import { useAtom } from "jotai";
import { useDoctorGetDrugDetailsLazyQuery } from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { selectedDrugsAtom } from "views/Visit/RightPanel/NewPrescription/atoms";
import { DrugDetails as RegularDrug } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/types";
import { isDrugRegularOrOrdered } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/utils";
import { RecentDrug } from "views/Visit/RightPanel/NewPrescription/types";

const setSelfOrdinationForMonthsOutput = (drug: RecentDrug) => {
  if (drug.enableSelfOrdinationForMonths) {
    return drug.enableSelfOrdinationForMonths;
  }
  if (drug.psychoSubstances) {
    return null;
  }

  return 12;
};

export const useCopyButton = () => {
  const [selectedDrugs, setSelectedDrugs] = useAtom(selectedDrugsAtom);

  const [getPackages, { loading, called, data }] =
    useDoctorGetDrugDetailsLazyQuery();

  const isCopying = called && loading;

  const handleAddDrugToPrescription = async (
    drug: RecentDrug | RegularDrug,
    orderedAmount: number,
  ) => {
    if (isCopying) return;

    toaster.loading("Kopiuje lek do recepty", { duration: 1000 });

    if (isDrugRegularOrOrdered(drug)) {
      if (!drug) {
        toaster.error("Coś poszło nie tak");
        return;
      }

      const isDrugAlreadyAdded = selectedDrugs.find(
        (drug) => drug.ean === data?.doctorGetDrugDetails.ean,
      );

      if (isDrugAlreadyAdded) {
        return toaster.error("Lek znajduje się już na recepcie", {
          duration: 2000,
        });
      }

      const newRegularDrug = {
        ...drug,
        paymentLevel: "100%",
        boxesAmount: orderedAmount.toString(),
        enableSelfOrdinationForMonths: drug.hasPsychoSubstances ? null : 12,
      };

      setSelectedDrugs((draft) => {
        draft.unshift(newRegularDrug);
      });
      return;
    }

    try {
      const { data, error } = await getPackages({
        variables: { ean: drug.ean ?? "" },
      });
      if (error || !data) throw new Error();
      const { doctorGetDrugDetails } = data;
      const newDrug = {
        ...doctorGetDrugDetails,
        type:
          drug.form && drug.quantity
            ? `${drug.form} ${drug.quantity}`
            : undefined,
        interval: drug.interval ? drug.interval : undefined,
        privilege: drug.additionalPermission
          ? drug.additionalPermission
          : undefined,
        paymentLevel: drug.cost ? drug.cost : "100%",
        boxesAmount: drug.boxAmount ? drug.boxAmount.toString() : "1",
        enableSelfOrdinationForMonths: setSelfOrdinationForMonthsOutput(drug),
      };

      const isDrugAlreadyAdded = selectedDrugs.find(
        (drug) => drug.ean === data.doctorGetDrugDetails.ean,
      );

      if (isDrugAlreadyAdded) {
        return toaster.error("Lek znajduje się już na recepcie", {
          duration: 2000,
        });
      }

      setSelectedDrugs((draft) => {
        draft.unshift(newDrug);
      });
    } catch {
      toaster.error("Coś poszło nie tak");
    }
  };

  const handleRemoveDrugFromPrescription = (ean: RecentDrug["ean"]) => {
    setSelectedDrugs(
      (draft) => (draft = draft.filter((drugItem) => drugItem.ean !== ean)),
    );
  };

  const isDrugAddedToPrescription = (ean: RecentDrug["ean"]) =>
    selectedDrugs.some((selectedDrug) => selectedDrug.ean === ean);

  return {
    handleAddDrugToPrescription,
    isDrugAddedToPrescription,
    handleRemoveDrugFromPrescription,
  };
};

import { useSynchronizedAnimation } from "lib/hooks/useSynchronizedAnimation";

type Size = "xs" | "sm" | "md" | "lg" | "xl";
type Color = "blue" | "gray" | "white" | "darkBlue";

const colorMap: Record<Color, string> = {
  blue: "text-jutro-new-blue-500",
  gray: "text-jutro-new-warm-gray-300",
  white: "text-white",
  darkBlue: "text-jutro-new-blue-800",
};

const sizeMap: Record<Size, string> = {
  xs: "h-6 w-6",
  sm: "h-8 w-8",
  md: "h-10 w-10",
  lg: "h-12 w-12",
  xl: "h-14 w-14",
};

type Props = {
  screen?: boolean;
  color?: Color;
  size?: Size;
};

export const Loader = ({
  screen = false,
  color = "gray",
  size = "md",
}: Props) => {
  const ref = useSynchronizedAnimation("spin");

  return (
    <div
      className={`z-40 grid w-full place-items-center overflow-hidden ${
        screen ? "h-screen" : "h-full"
      }`}
    >
      <svg
        ref={ref}
        className={`animate-spin ${sizeMap[size]} ${colorMap[color]}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />

        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
};

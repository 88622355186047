import {
  BinIcon,
  Button,
  CloseIcon,
  PDFIcon,
  PNGIcon,
  PencilIcon,
  XMLIcon,
} from "@jutro/ui";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { Block } from "components/components/Doctor/Block";
import { Loader } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorDeclarationSubmittedFragment,
  DoctorGetPatientDocument,
  DoctorMSDeclarationType,
  DoctorMSUserGenre,
  DoctorPatientDeclarationHistoryDocument,
  useDoctorDeleteNFZEventMutation,
} from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";
import { fileConfig, httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import { patientDeclarationAtom } from "./atoms";
import { useDeclarationHistory } from "./useDeclarationHistory";

export const DeclarationHistory = () => {
  const { declarations, loading, error } = useDeclarationHistory();

  const { removeTile } = useTileSystem();

  if (loading) {
    return <Loader />;
  }

  if (declarations.length === 0) {
    return (
      <div className="text-center text-jutro-new-warm-gray-700">
        Brak deklaracji
      </div>
    );
  }

  if (error) {
    return <span>Wystąpił błąd</span>;
  }

  return (
    <div className="relative flex flex-col justify-center gap-3">
      <div className="font-heading-4 flex items-center justify-between rounded-lg bg-white p-4">
        <h4 className="font-paragraph-2 text-jutro-new-warm-gray-700">
          Historia deklaracji
        </h4>
        <Button
          full={false}
          variant="ghost"
          size="condensed"
          icon={<CloseIcon />}
          label="Zamknij"
          tooltipPosition="bottom"
          onClick={() => removeTile("patient-declaration-history")}
        />
      </div>
      <div className="flex max-h-screen flex-col space-y-4 overflow-y-scroll">
        {declarations.map((declaration) => {
          if (declaration.__typename === "DoctorMSUnknownEvent") {
            return null;
          }

          return <Declaration key={declaration.id} declaration={declaration} />;
        })}
      </div>
    </div>
  );
};

const getDisplayGenres = (
  genre: DoctorMSUserGenre,
  npwz: string | undefined | null,
): { header: string; person: string } => {
  if (genre === "DOCTOR") {
    return { header: "lekarska", person: "Lekarz" };
  }

  if (genre === "NURSE" && npwz?.endsWith("A")) {
    return { header: "Położnicza", person: "Położna" };
  }

  return { header: "pielęgniarska", person: "Pielęgniarka" };
};

type DeclarationItemProps = {
  title: string;
  content: string;
  contentColor?: string;
};

const DeclarationItem = ({
  title,
  content,
  contentColor,
}: DeclarationItemProps) => {
  return (
    <div>
      <span className="font-bold">{title}: </span>
      <span className={contentColor}>{content}</span>
    </div>
  );
};

export const declarationTypeMap: Record<DoctorMSDeclarationType, string> = {
  APP: "Podpis w aplikacji",
  EPUAP: "ePUAP",
  IKP: "IKP",
  PAPER: "Papierowa",
};

type DeclarationProps = {
  declaration: DoctorDeclarationSubmittedFragment;
};

const Declaration = ({ declaration }: DeclarationProps) => {
  const { id, comment, createdAt, doctor, files, source } = declaration;

  const [, setPatientDeclarationAtom] = useAtom(patientDeclarationAtom);

  const [deleteDeclarationModalOpen, setDeleteDeclarationModalOpen] =
    useState(false);

  const { addTile } = useTileSystem();

  const [deleteNFZEvent] = useDoctorDeleteNFZEventMutation({
    refetchQueries: [
      DoctorPatientDeclarationHistoryDocument,
      DoctorGetPatientDocument,
    ],
    awaitRefetchQueries: true,
  });

  const deleteDeclaration = async (id: string) => {
    toaster.notify("Usuwam deklarację");
    try {
      await deleteNFZEvent({ variables: { id } });
      toaster.success("Poprawnie usunięto deklarację");
    } catch {
      toaster.error("Nie udało się usunąć deklaracji");
    }
  };

  const { header: headerGenre, person: personGenre } = getDisplayGenres(
    doctor.genre,
    doctor.npwz,
  );

  const displayDoctor = useMemo(() => {
    const { firstName, lastName, adultDeclarable, childrenDeclarable } = doctor;

    const doctorName = getDisplayDoctor(
      firstName,
      lastName,
      "Nie znaleziono lekarza",
    );

    if (!adultDeclarable && !childrenDeclarable) {
      return `${doctorName} (lekarz nieaktywny)`;
    }

    return doctorName;
  }, [doctor]);

  const [declarationId] = files;

  return (
    <Block title={`Deklaracja ${headerGenre}`}>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <DeclarationItem
            title={personGenre}
            content={displayDoctor}
            contentColor={
              !doctor.adultDeclarable && !doctor.childrenDeclarable
                ? "text-red-600"
                : ""
            }
          />
          <DeclarationItem
            title="Typ deklaracji"
            content={declarationTypeMap[source]}
          />
          <DeclarationItem
            title="Data złożenia"
            content={dayjs(createdAt).format("YYYY-MM-DD HH:mm")}
          />
          {declaration.comment && (
            <DeclarationItem title="Komentarz" content={comment} />
          )}
        </div>

        <div className="inline-flex justify-center gap-2 px-2">
          {declaration.isElectronicallySigned && (
            <a
              className="link max-w-max"
              href={`${
                fileConfig[envVars.REACT_APP_CONFIG]
              }${declarationId}&filename=deklaracja.xml`}
            >
              <Button
                full={false}
                icon={<XMLIcon />}
                label="Pobierz XML"
                tooltipPosition="top"
                variant="ghost"
                size="condensed"
              />
            </a>
          )}
          {declaration.signature && (
            <a
              className="link max-w-max"
              href={`${fileConfig[envVars.REACT_APP_CONFIG]}${
                declaration.signature
              }`}
            >
              <Button
                full={false}
                icon={<PNGIcon />}
                label="Pobierz PNG"
                tooltipPosition="top"
                variant="ghost"
                size="condensed"
              />
            </a>
          )}
          {!declaration.signature && declaration.files.length !== 0 && (
            <a
              className="link max-w-max"
              href={`${
                httpConfig[envVars.REACT_APP_CONFIG]
              }/nfz-history/declaration?eventId=${declaration.id}`}
            >
              <Button
                full={false}
                icon={<PDFIcon />}
                label="Pobierz PDF"
                tooltipPosition="top"
                variant="ghost"
                size="condensed"
              />
            </a>
          )}
          {declaration.filename && (
            <a
              className="link max-w-max"
              href={`${fileConfig[envVars.REACT_APP_CONFIG]}${
                declaration.filename
              }`}
            >
              <Button
                full={false}
                icon={<XMLIcon />}
                label="Pobierz XML"
                tooltipPosition="top"
                variant="ghost"
                size="condensed"
              />
            </a>
          )}

          <Button
            full={false}
            icon={<PencilIcon />}
            label="Edytuj"
            tooltipPosition="top"
            variant="ghost"
            size="condensed"
            onClick={() => {
              addTile("patient-declaration-edit", TileColumn.Right);
              setPatientDeclarationAtom(declaration);
            }}
          />

          <Button
            full={false}
            icon={<BinIcon />}
            label="Usuń"
            tooltipPosition="top"
            variant="negativeGhost"
            size="condensed"
            onClick={() => setDeleteDeclarationModalOpen(true)}
          />
        </div>
      </div>
      <ConfirmDialog
        open={deleteDeclarationModalOpen}
        title="Usuwanie deklaracji"
        text="Czy na pewno chcesz usunąć deklarację?"
        onConfirm={() => {
          deleteDeclaration(id);
        }}
        setOpen={setDeleteDeclarationModalOpen}
      />
    </Block>
  );
};

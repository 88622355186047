import { Control, Controller } from "react-hook-form";
import { PaymentModalSchema } from "components/PatientDocs/Ordered/types";

type Props = {
  control: Control<PaymentModalSchema> | undefined;
};

export const EmployeeBenefitPaymentModal = ({ control }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue=""
      name="employeeBenefit.benefit"
      render={({ field }) => (
        <div className="hidden">
          <input {...field} />
        </div>
      )}
    />
  );
};

import { Option } from "@jutro/types";
import dayjs, { Dayjs } from "dayjs";
import { SetStateAction } from "jotai";
import { range } from "ramda";
import { Dispatch } from "react";
import { z } from "zod";
import { UserGenre } from "lib/graphql/doctor";
import {
  DoctorMSPlacement,
  DoctorMSShiftOutputDynamic,
} from "lib/graphql/megaSchema";
import { organizationClinicOption } from "lib/hooks/useVojevodshipOptions";

export type SlotActionModalMode = "create" | "edit" | "delete" | "cancelDelete";

export type StaffOptions = Option<string> & {
  genre: UserGenre;
  npwz: string | null | undefined;
};

type OnSiteOptions = Option<DoctorMSPlacement>;

export const createShiftFormSchema = z
  .object({
    doctor: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "To pole jest wymagane" },
    ),
    organizationId: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "To pole jest wymagane" },
    ),
    slotType: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "To pole jest wymagane" },
    ),
    placement: z.object(
      {
        label: z.string(),
        value: z.union([
          z.literal("ON_SITE"),
          z.literal("OFF_SITE"),
          z.literal("NOT_APPLICABLE"),
        ]),
      },
      { required_error: "To pole jest wymagane" },
    ),
    day: z.date({ message: "To pole jest wymagane" }),
    timeFrom: z.string().min(1),
    timeTo: z.string().min(1),
    slotDurationEdit: z.boolean().optional(),
    slotDuration: z.string().optional(),
    comment: z.string().optional(),
  })
  .refine(
    (fields) => {
      if (!fields.slotDurationEdit) {
        return true;
      }

      return fields.slotDuration;
    },
    { message: "To pole jest wymagane", path: ["slotDuration"] },
  );

export type CreateShiftFormSchemaType = z.infer<typeof createShiftFormSchema>;

export const onSiteOptions: OnSiteOptions[] = [
  {
    label: "W przychodni",
    value: "ON_SITE",
  },
  {
    label: "Zdalnie",
    value: "OFF_SITE",
  },
  {
    label: "Nie dotyczy",
    value: "NOT_APPLICABLE",
  },
];

export const getColumnBackgroundColor = (day: dayjs.Dayjs) => {
  const weekendDays = [0, 6];

  if (day.isSame(dayjs(), "day")) {
    return "bg-jutro-new-blue-100";
  }

  if (weekendDays.includes(day.day())) {
    return "bg-jutro-new-warm-gray-200";
  }

  return "white";
};

export const generateDaysInCurrentMonthRow = (from: Dayjs, to: Dayjs) => {
  const distance = to.diff(from, "day");
  return range(0, distance + 1).map((val) => from.add(val, "day"));
};

export const handleDateChange = (
  action: "prev" | "next",
  setStartDate: Dispatch<SetStateAction<dayjs.Dayjs>>,
  setEndDate: Dispatch<SetStateAction<dayjs.Dayjs>>,
) => {
  if (action === "prev") {
    setStartDate((prev) => prev.subtract(1, "month").startOf("month"));
    setEndDate((prev) => prev.subtract(1, "month").endOf("month"));
    return;
  }

  setStartDate((prev) => prev.add(1, "month").startOf("month"));
  setEndDate((prev) => prev.add(1, "month").endOf("month"));
};

export const getFormDefaultValues = (
  doctorsOptions: StaffOptions[] | undefined,
  selectedSlotDoctorId: string | undefined,
  organizationsClinicsOptions: organizationClinicOption[],
  selectedOrganization: string | undefined,
  selectedShift: DoctorMSShiftOutputDynamic | undefined,
  selectedDay: Dayjs | undefined,
  startDate: Dayjs,
) => {
  const defaultStaff = doctorsOptions?.find(
    (doctor) => doctor.value === selectedSlotDoctorId,
  );
  const defaultOrganization = selectedShift
    ? organizationsClinicsOptions.find(
        (org) => org.value === selectedShift.placeId,
      )
    : organizationsClinicsOptions.find(
        (org) => org.value === selectedOrganization,
      );
  const defaultSLotType = selectedShift
    ? { label: selectedShift.shiftType, value: selectedShift.shiftType }
    : undefined;
  const defaultPlacement = selectedShift?.placement
    ? onSiteOptions.find((o) => o.value === selectedShift.placement)
    : undefined;
  const defaultDay = selectedDay
    ? new Date(selectedDay.toDate())
    : startDate.startOf("month").toDate();
  const defaultSlotDurationEdit =
    selectedShift?.defaultSlotLength === selectedShift?.slotDuration
      ? false
      : true;
  const defaultSlotDuration = selectedShift
    ? selectedShift.slotDuration.toString()
    : undefined;

  return {
    doctor: defaultStaff,
    organizationId: defaultOrganization,
    slotType: defaultSLotType,
    placement: defaultPlacement,
    day: defaultDay,
    timeFrom: selectedShift ? selectedShift.hourStart : undefined,
    timeTo: selectedShift ? selectedShift.hourEnd : undefined,
    slotDurationEdit: defaultSlotDurationEdit,
    slotDuration: defaultSlotDuration,
    comment: selectedShift ? selectedShift.comment : undefined,
  };
};

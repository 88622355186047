import { Option } from "@jutro/types";
import { Tabs } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { DocsOfPatientTab } from "components/PatientDocs";
import { selectedDocsOfPatientTabAtom } from "lib/atoms";

const options: Option<DocsOfPatientTab>[] = [
  { label: "Ogólne", value: "Overview" },
  { label: "Szczegóły", value: "Details" },
  { label: "Wizyty", value: "Visits" },
  { label: "Recepty", value: "Prescriptions" },
  { label: "Zlecone", value: "Procedures" },
];

export const PatientTabsNavbar = () => {
  const [selectedLeftTabId, setSelectedLeftTabId] = useAtom(
    selectedDocsOfPatientTabAtom,
  );

  useEffect(() => {
    return () => {
      setSelectedLeftTabId("Overview");
    };
  }, []);

  return (
    <Tabs
      options={options}
      value={options.find((option) => option.value === selectedLeftTabId)!}
      width="hug"
      onChange={({ value }) => setSelectedLeftTabId(value)}
    />
  );
};

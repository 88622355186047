import { EventHandler, NfzEventToComponent } from "../NfzEvent/infer";
import { DeclarationAcceptedEventHandler } from "./DeclarationAccepted";
import { DeclarationNoLongerValidHandler } from "./DeclarationNoLongerValid";
import { DeclarationRejectedEventHandler } from "./DeclarationRejected";
import { DeclarationSubmittedEventHandler } from "./DeclarationSubmitted";
import { InsuranceStatusEventHandler } from "./InsuranceStatus";
import { UnknownEventHandler } from "./Unknown";

const mapping = {
  DoctorMSUnknownEvent: UnknownEventHandler,
  DoctorMSDeclarationSubmittedEvent: DeclarationSubmittedEventHandler,
  DoctorMSDeclarationRejectedEvent: DeclarationRejectedEventHandler,
  DoctorMSDeclarationAcceptedEvent: DeclarationAcceptedEventHandler,
  DoctorMSDeclarationNoLongerValidEvent: DeclarationNoLongerValidHandler,
  DoctorMSInsuranceStatusEvent: InsuranceStatusEventHandler,
} as const;

type NfzHistoryEventKeys = keyof typeof mapping;

type NfzHistoryEventValues = (typeof mapping)[NfzHistoryEventKeys];

type InferEventTypes<THandlers> =
  THandlers extends EventHandler<infer TEvent> ? TEvent : never;

type NfzHistoryEventTypes = InferEventTypes<NfzHistoryEventValues>;

export const NfzHistoryEventHandler: EventHandler<NfzHistoryEventTypes> = ({
  event,
}) => {
  return <NfzEventToComponent mapping={mapping} event={event} />;
};

import { Loader } from "components/new";
import { useRecentPrescriptionsQuery } from "lib/graphql/megaSchema";
import { RecentPrescription } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/Item";
import { useVisitData } from "views/Visit/hooks";

export const RecentlyIssuedDrugs = () => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  const {
    data: recentPrescriptions,
    loading,
    error,
  } = useRecentPrescriptionsQuery({
    variables: {
      patientId: currentVisit!.patient!.id,
    },
    skip: !currentVisit?.patient?.id,
  });

  const prescriptions = recentPrescriptions?.doctorGetPrescriptionsForPatient;

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <span>Błąd wczytywania ostatnio wystawionych leków</span>;
  }

  if (!prescriptions || prescriptions.length === 0) {
    return <span>Brak danych</span>;
  }

  return (
    <div className="flex flex-col gap-4 divide-y">
      {prescriptions.map((prescription) => {
        return (
          <RecentPrescription
            key={prescription.id}
            drugs={prescription.drugs}
            writeup={prescription.writeup}
          />
        );
      })}
    </div>
  );
};

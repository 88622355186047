type Props = {
  mainText: string;
  additionalText?: string;
};

export const ChukBaseInfoSection = ({ mainText, additionalText }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="font-paragraph-2">Informacje podstawowe</p>
      <div className="font-paragraph-2 flex w-full flex-col gap-4 rounded-lg border border-jutro-new-warm-gray-300 p-2">
        <div>{`Uwagi: ${mainText ?? "-"}`}</div>

        {additionalText && <div>{additionalText}</div>}
      </div>
    </div>
  );
};

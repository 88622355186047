import { zodResolver } from "@hookform/resolvers/zod";
import { Button, NavigationButton } from "@jutro/ui";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ReactModal from "react-modal";
import { useSyncIpomFormData } from "components/IpomForm/hooks/useSyncIpomFormData";
import { ScreenLoader } from "components/new";
import { PdfPreview } from "components/new/PdfPreview/PdfPreview";
import {
  DoctorGetIpomDocument,
  DoctorPatientProfileVisitDocument,
  DoctorVisitDocument,
  PerDayVisitsDocument,
  useDoctorCreateChatMessageMutation,
  useDoctorCreateIpomMutation,
  useDoctorGenerateIpomPdfDocumentMutation,
  useDoctorGetIpomCodesQuery,
  useDoctorGetIpomLazyQuery,
  useDoctorLaboratoryExaminationsQuery,
  useDoctorPatientMessagesQuery,
  useDoctorProceduresQuery,
} from "lib/graphql/megaSchema";
import { useGetPatientId } from "lib/hooks/useGetPatientId";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import { uploadPdf } from "lib/tools/uploadPdf";
import { isMedicalServiceConfirmModalOpenAtom } from "views/Visit/RightPanel/Visit/atoms";
import { useVisitData } from "views/Visit/hooks";
import { ControlVisitsSection } from "./ControlVisitsSection";
import { DiagnosisSection } from "./DiagnosisSection";
import { DiagnosticTestsSection } from "./DiagnosticTestsSection";
import { DrugsSection } from "./DrugsSection";
import { EducationSection } from "./EducationSection";
import { PreviewModeHeader } from "./PreviewModeHeader";
import { SpecialistVisitsSection } from "./SpecialistVisitsSection";
import { SummarySection } from "./SummarySection";
import { ipomFormDataAtom, ipomFormModeAtom } from "./atoms";
import {
  IpomFormSchema,
  defaultIpomFormValues,
  ipomFormSchema,
} from "./schema";
import {
  downloadIpomPDF,
  prepareIpomFormData,
  transformIpomDataToForm,
  transformIpomFormDataToInput,
} from "./tools";

export const IpomForm = () => {
  const [ipomFormMode] = useAtom(ipomFormModeAtom);
  const [ipomFormData] = useAtom(ipomFormDataAtom);
  const [, setIsMedicalServiceConfirmModalOpen] = useAtom(
    isMedicalServiceConfirmModalOpenAtom,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState("");

  const patientId = useGetPatientId();

  const { removeTile } = useTileSystem();

  const methods = useForm<IpomFormSchema>({
    resolver: zodResolver(ipomFormSchema),
    defaultValues: defaultIpomFormValues,
  });

  const {
    setValue,
    handleSubmit,
    watch,
    trigger,
    formState: { isSubmitted },
  } = methods;

  useSyncIpomFormData(watch);

  const { data: visitData } = useVisitData({ from: "cache-only" });

  const { data: ipomCodesData, loading: ipomCodesLoading } =
    useDoctorGetIpomCodesQuery();

  const variables = visitData?.patient?.id
    ? { patientId: visitData.patient.id }
    : undefined;

  const { data: patientMessagesData, loading: patientMessagesLoading } =
    useDoctorPatientMessagesQuery({
      variables,
      skip: !visitData,
      fetchPolicy: "cache-and-network",
    });

  const { data: labExamsData, loading: labExamsLoading } =
    useDoctorLaboratoryExaminationsQuery({
      variables,
      skip: !visitData,
      fetchPolicy: "cache-and-network",
    });

  const { data: proceduresData, loading: proceduresLoading } =
    useDoctorProceduresQuery({
      variables,
      skip: !visitData,
      fetchPolicy: "cache-and-network",
    });

  const [getIpom, { data: ipomData, loading: getIpomLoading }] =
    useDoctorGetIpomLazyQuery();

  const [createIpom] = useDoctorCreateIpomMutation({
    refetchQueries: [
      DoctorGetIpomDocument,
      DoctorPatientProfileVisitDocument,
      DoctorVisitDocument,
      PerDayVisitsDocument,
    ],
  });

  const [generateIpomPdf] = useDoctorGenerateIpomPdfDocumentMutation();
  const [createMessage] = useDoctorCreateChatMessageMutation();

  const isPreviewMode = ipomFormMode === "preview";
  const isDisabled = isPreviewMode || isLoading;

  const preparePreview = async () => {
    if (!patientId) {
      return;
    }

    try {
      const { data } = await getIpom({
        variables: {
          patientId,
        },
      });

      if (!data || !data.doctorGetIpom) {
        return;
      }

      const formData = transformIpomDataToForm(data.doctorGetIpom);

      Object.entries(formData).forEach(([key, value]) => {
        setValue(key as keyof typeof formData, value, { shouldDirty: false });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isCreateFormDataReady =
    visitData &&
    patientMessagesData &&
    labExamsData &&
    proceduresData &&
    ipomCodesData;

  const prepareCreate = () => {
    if (!isCreateFormDataReady) {
      return;
    }

    const formDataFromAtom = Object.keys(ipomFormData).includes(visitData.id)
      ? ipomFormData[visitData.id]
      : defaultIpomFormValues;

    const formData = prepareIpomFormData({
      visitData,
      labExamsData,
      proceduresData,
      patientMessagesData,
      ipomCodesData,
      formDataFromAtom,
      existingIpomData: ipomData,
    });

    Object.entries(formData).forEach(([key, value]) => {
      setValue(key as keyof typeof formData, value, { shouldDirty: false });
    });
  };

  useEffect(() => {
    if (isPreviewMode) {
      preparePreview();
      return;
    }

    prepareCreate();
  }, [
    visitData,
    patientMessagesData,
    proceduresData,
    labExamsData,
    ipomCodesData,
    ipomFormMode,
  ]);

  useEffect(() => {
    if (!visitData || ipomFormMode === "preview" || !isSubmitted) {
      return;
    }

    trigger();
  }, [watch()]);

  const isQueryDataLoading =
    ipomCodesLoading ||
    patientMessagesLoading ||
    labExamsLoading ||
    proceduresLoading ||
    getIpomLoading;

  if (isQueryDataLoading) {
    return <ScreenLoader />;
  }

  const handleSendMessageWithIpomPdf = async () => {
    const { data } = await generateIpomPdf({
      variables: {
        patientId: patientId ?? "",
      },
    });

    const downloadedIpomPDF = await downloadIpomPDF(
      data?.doctorGenerateIpomPdfDocument.fileUrl,
    );

    const fileName = `${dayjs().format("YYYY-MM-DD@HH:mm:ss")}-${
      visitData?.patient?.firstName?.[0]
    }${visitData?.patient?.lastName}-IPOM.pdf`;

    const fileId = await uploadPdf(downloadedIpomPDF, fileName);

    createMessage({
      variables: {
        id: patientId ?? "",
        input: { content: `[file-<${fileId}>]` },
      },
    });
  };

  const onSubmit: SubmitHandler<IpomFormSchema> = async (data) => {
    setIsLoading(true);

    const result = await toaster.promise(
      createIpom({
        variables: {
          input: {
            visitId: visitData!.id,
            details: transformIpomFormDataToInput(data),
          },
        },
      }),
      {
        loading: "Wystawiam IPOM...",
        success: "Poprawnie wystawiono IPOM",
        error: "Nie udało się wystawić IPOMu, spróbuj ponownie",
      },
    );

    setIsLoading(false);

    if (!result?.data) {
      return;
    }

    removeTile("ipom-form");

    setIsMedicalServiceConfirmModalOpen(true);

    toaster.promise(handleSendMessageWithIpomPdf(), {
      loading: "Wysyłam wiadomość...",
      success: "Poprawnie wysłano wiadomość",
      error: "Nie udało się wysłać wiadomosci",
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-2 text-jutro-new-warm-gray-800"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
            <div className="flex items-center justify-between">
              <h4 className="font-heading-4">
                Indywidualny plan opieki medycznej
              </h4>
              <NavigationButton
                onClick={() => {
                  removeTile("ipom-form");
                }}
              />
            </div>

            {isPreviewMode && ipomData?.doctorGetIpom ? (
              <PreviewModeHeader
                ipomData={ipomData.doctorGetIpom}
                setPdfPreviewUrl={setPdfPreviewUrl}
              />
            ) : null}

            <DiagnosisSection disabled={isDisabled} />
          </div>

          <DrugsSection disabled={isDisabled} />
          <EducationSection disabled={isDisabled} />
          <DiagnosticTestsSection disabled={isDisabled} />
          <SummarySection disabled={isDisabled} />
          <ControlVisitsSection disabled={isDisabled} />

          <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
            <SpecialistVisitsSection disabled={isDisabled} />

            {!isPreviewMode ? (
              <div className="flex gap-1">
                <Button
                  variant="secondary"
                  disabled={isLoading}
                  text="Anuluj"
                  type="button"
                  onClick={() => {
                    removeTile("ipom-form");
                  }}
                />
                <Button text="Wystaw IPOM" disabled={isLoading} />
              </div>
            ) : null}
          </div>
        </form>
      </FormProvider>

      <ReactModal
        isOpen={Boolean(pdfPreviewUrl)}
        ariaHideApp={false}
        className="flex h-[80%] w-[600px] flex-col items-center justify-center gap-4 rounded-lg bg-white p-4"
        overlayClassName={
          "fixed w-full h-screen top-0 left-0 z-[1000] bg-black bg-opacity-60 flex justify-center items-center"
        }
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={() => {
          setPdfPreviewUrl("");
        }}
      >
        <PdfPreview
          file={`${httpConfig[envVars.REACT_APP_CONFIG]}${pdfPreviewUrl}`}
        />
      </ReactModal>
    </>
  );
};

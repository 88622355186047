import { envVars } from "envvars";
import { useAtom } from "jotai";
import { returnFormattedDiagData } from "components/ExaminationPanel/utils/returnFormattedDiagData";
import { returnFormattedPacketsData } from "components/ExaminationPanel/utils/returnFormattedPacketsData";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSAssignmentType,
  DoctorVisitQuery,
  useDoctorICD10LazyQuery,
  useDoctorPackagesLazyQuery,
  useDoctorPatientProfileDiagLazyQuery,
} from "lib/graphql/megaSchema";
import { ecommercePosConfig } from "lib/tools/httpConfigs";
import { getDrugDescription } from "views/Visit/RightPanel/NewPrescription/components/DrugSelect/Label";
import { SelectLabel } from "views/Visit/RightPanel/Visit/components/SelectLabel";

export type PacketsResponseData = {
  promotion: number | null;
  id: string;
  price: number;
  totalPrice: number;
  name: string;
};

export const useAsyncDrugsOptions = () => {
  const [getPackages] = useDoctorPackagesLazyQuery();

  const loadDrugOptions = async (name: string) => {
    const { data } = await getPackages({ variables: { name } });

    if (!data || data.doctorPackages.length === 0) {
      return [];
    }

    return data.doctorPackages.map((drug) => {
      const { dosage, form, box, name } = drug;
      return {
        value: drug,
        label: `${name} | ${getDrugDescription(dosage, form, box)}`,
      };
    });
  };

  return {
    loadDrugOptions,
  };
};

export const useAsyncTestsOptions = () => {
  const [getTests] = useDoctorPatientProfileDiagLazyQuery();
  const [currentUser] = useAtom(currentUserAtom);

  const loadTestsOptions = async (
    search: string,
    type: DoctorMSAssignmentType,
    organizationId?: string,
  ) => {
    if (!currentUser) {
      return [];
    }

    const { data } = await getTests({
      variables: { search, type, organizationId },
    });

    if (!data || data.doctorDiag.length === 0) {
      return [];
    }

    const formattedDiagData = returnFormattedDiagData(data, currentUser.genre);

    return formattedDiagData;
  };

  return {
    loadTestsOptions,
  };
};

export const useAsyncPacketsOptions = () => {
  const [currentUser] = useAtom(currentUserAtom);

  const API_URL = `${
    ecommercePosConfig[envVars.REACT_APP_CONFIG]
  }/ecommerce/products/packets?search=`;

  const loadPacketsOptions = async (search: string) => {
    if (!currentUser) {
      return [];
    }

    try {
      const response = await fetch(API_URL + `${search}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      const formattedData = returnFormattedPacketsData(data);

      return formattedData;
    } catch (error) {
      return [];
    }
  };

  return {
    loadPacketsOptions,
  };
};

export const useAsyncIcd10Options = () => {
  const [getIcd10s] = useDoctorICD10LazyQuery();

  const icd10Options = async (search: string) => {
    const { data } = await getIcd10s({ variables: { search } });

    if (!data || data.doctorICD10.length === 0) {
      return [];
    }

    return data.doctorICD10.map((value) => {
      return {
        value,
        label: <SelectLabel value={value} />,
      };
    });
  };

  return {
    icd10Options,
  };
};

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

export const useComplexIcd10Search = (
  tags: Patient["tags"] | undefined,
  alreadySelectedOptions: Visit["doctorDiagnosis"],
) => {
  const { icd10Options } = useAsyncIcd10Options();
  const alreadySelectedCodes = alreadySelectedOptions?.map(
    (option) => option?.code ?? "",
  );

  const isAlreadySelected = (icd10: string) =>
    alreadySelectedCodes?.some((selectedCode) => {
      return icd10.startsWith(selectedCode);
    });

  const filterSelectedValues = (
    values: Awaited<ReturnType<typeof icd10Options>>,
  ) => values.filter((icd10) => !isAlreadySelected(icd10.value));

  const renderSelectLabel = (
    value: string,
    chronicDiseaseTags: Patient["tags"] | undefined,
  ) => {
    const chronicDiseaseTag = chronicDiseaseTags?.find((tag) => {
      return tag.data.name === value;
    });
    return <SelectLabel value={value} chronicDiseaseTag={chronicDiseaseTag} />;
  };

  const searchIcd10Options = async (
    search: string,
  ): ReturnType<typeof icd10Options> => {
    if (search !== "") {
      const options = await icd10Options(search);
      const filteredOptions = filterSelectedValues(options);
      return filteredOptions.map((option) => ({
        value: option.value,
        label: renderSelectLabel(option.value, tags),
      }));
    }

    const mappedTags = tags
      ?.filter((tag) => tag.type === "CHRONIC_DISEASE")
      .map((tag) => ({
        value: tag.data.name,
        label: renderSelectLabel(tag.data.name, tags),
      }));

    return filterSelectedValues(mappedTags ?? []);
  };

  return {
    searchIcd10Options,
  };
};

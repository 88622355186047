/* eslint-disable func-style */
import { useAtom } from "jotai";
import { currentUserAtom } from "lib/atoms/auth";
import { DoctorMSUserGenre } from "lib/graphql/megaSchema";

export const useUserGenre = () => {
  const [currentUser] = useAtom(currentUserAtom);

  function performActionByRole(
    action: () => Promise<void>,
    roles: DoctorMSUserGenre[],
  ): () => Promise<void>;

  function performActionByRole(
    action: () => void,
    roles: DoctorMSUserGenre[],
  ): () => void;

  function performActionByRole(
    action: (() => Promise<void>) | (() => void),
    roles: DoctorMSUserGenre[],
  ) {
    if (!currentUser?.genre || !roles.includes(currentUser.genre)) {
      return () => {};
    }

    return action;
  }

  if (!currentUser?.genre) {
    return {
      isNurse: false,
      isAssistant: false,
      isDoctor: false,
      isAdministration: false,
      performActionByRole,
    };
  }

  const isNurse = currentUser.genre === "NURSE";
  const isAssistant = currentUser.genre === "ASSISTANT";
  const isDoctor = currentUser.genre === "DOCTOR";
  const isAdministration = currentUser.genre === "STAFF";

  return {
    isNurse,
    isAssistant,
    isDoctor,
    isAdministration,
    performActionByRole,
  };
};

import { useAtom } from "jotai";
import { useEffect } from "react";
import { isScreenLoadingAtom } from "lib/atoms";

export const ScreenLoader = () => {
  const [, setIsScreenLoading] = useAtom(isScreenLoadingAtom);

  useEffect(() => {
    setIsScreenLoading(true);

    return () => {
      setIsScreenLoading(false);
    };
  }, []);

  return null;
};

import { useGetPatientId } from "lib/hooks/useGetPatientId";

type Props = {
  children: (patientId: string) => JSX.Element;
};

export const PatientIdProvider = ({ children }: Props) => {
  const patientId = useGetPatientId();

  if (!patientId) return null;

  return children(patientId);
};

import * as sentry from "@sentry/react";
import { envVars } from "envvars";
import { useEffect } from "react";

export const useInitSentry = () => {
  useEffect(() => {
    if (envVars.REACT_APP_ENVIRONMENT !== "PROD") return;

    sentry.init({
      dsn: envVars.REACT_APP_SENTRY_DSN,
      environment: envVars.REACT_APP_ENVIRONMENT,
      integrations: [
        sentry.browserTracingIntegration(),
        sentry.httpClientIntegration(),
      ],
      tracesSampleRate: 0.2,
    });
  }, []);
};

import { Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { currentUserAtom } from "lib/atoms/auth";
import { useHome } from "./hooks";

export enum TestId {
  PENDING_VISITS_COUNT = "pendingVisitsCount",
  INFO_SEARCH_STARTED = "infoSearchStarted",
}

export const HomeView = () => {
  const { none, pendingVisitsCount, start, setStart } = useHome();
  const [currentUser] = useAtom(currentUserAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.genre === "DOCTOR" || currentUser?.genre === "NURSE") {
      navigate("/visits/per-day");
    }
  }, [currentUser]);

  if (start) {
    return (
      <div
        data-testid={TestId.INFO_SEARCH_STARTED}
        className="font-display-2 grid h-1/2 place-items-center font-medium text-jutro-new-warm-gray-500"
      >
        {none
          ? "Nie ma nowych zgłoszeń. Gdy jakieś się pojawi, apka Cię do niego przeniesie ..."
          : "Gdy pojawi się pacjent, apka Cię do niego przeniesie ..."}
      </div>
    );
  }

  return (
    <div className="font-display-2 fixed left-9 top-0 grid h-screen w-screen place-items-center text-jutro-new-warm-gray-200">
      <div className="flex flex-col space-y-3 bg-jutro-new-warm-gray-100 px-32 py-40">
        <div className="font-heading-1 font-medium text-jutro-new-warm-gray-500">
          Liczba oczekujących:{" "}
          <span
            data-testid={TestId.PENDING_VISITS_COUNT}
            className="font-medium text-jutro-new-warm-gray-500"
          >
            {pendingVisitsCount}
          </span>
        </div>

        <Button
          id="next-patient-button"
          text="Rozpocznij"
          onClick={() => setStart(true)}
        />
      </div>
    </div>
  );
};

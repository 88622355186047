import { Maybe } from "@jutro/types";
import dayjs from "dayjs";
import { useDoctorGetDoctorFullNameQuery } from "lib/graphql/megaSchema";

export const VisitCreatedByInfo = ({
  medicId,
  createdAt,
}: {
  medicId: Maybe<string>;
  createdAt: Maybe<string>;
}) => {
  const { data } = useDoctorGetDoctorFullNameQuery({
    variables: {
      id: medicId ?? "",
    },
    skip: !medicId,
  });

  if (!medicId || !data?.doctorDoctor || !createdAt) {
    return null;
  }

  const date = dayjs(createdAt).format("DD MMM YYYY, HH:mm");

  const medic = data.doctorDoctor;
  const medicName = `${medic.firstName} ${medic.lastName}`;

  return (
    <div className="font-paragraph-2">{`Umówiona przez: ${medicName} ${date}`}</div>
  );
};

import { Button, DocumentIcon } from "@jutro/ui";
import { useAtom } from "jotai";
import { ipomFormModeAtom } from "components/IpomForm/atoms";
import { useFlag } from "lib/hooks/flags";
import { workspaceTilesInjectAtom } from "lib/tools/createWorkspace/atoms";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { VisitsItem } from "views/Visits/PerDay/components/SlotList/types";
import { getProcedureVisitAdditionalInfo } from "views/Visits/PerDay/tools";

type Props = {
  visit: VisitsItem;
};

export const AdditionalVisitInfo = ({ visit }: Props) => {
  const isIpomEnabled = useFlag("ipom");

  const isQualifiedForIPOM =
    Boolean(visit.determineIpomEligibility) && isIpomEnabled;
  const patientHasIPOM = Boolean(visit.patient?.ipomCode) && isIpomEnabled;

  const [, setWorkspaceTilesInject] = useAtom(workspaceTilesInjectAtom);
  const [, setIpomFormMode] = useAtom(ipomFormModeAtom);

  const procedureVisitAdditionalInfo = getProcedureVisitAdditionalInfo(
    visit.procedures,
    visit.laboratoryExaminations,
  );

  if (procedureVisitAdditionalInfo) {
    return procedureVisitAdditionalInfo;
  }

  if (isQualifiedForIPOM) {
    return "Rozpoznanie kwalifikujące do OK";
  }

  if (patientHasIPOM) {
    return (
      <Button
        text="Opieka koordynowana: IPOM"
        variant="secondary"
        size="condensed"
        full={false}
        icon={<DocumentIcon />}
        onClick={() => {
          setIpomFormMode("preview");
          setWorkspaceTilesInject([
            {
              tile: "ipom-form",
              column: TileColumn.Center,
            },
          ]);
        }}
      />
    );
  }

  return "-";
};

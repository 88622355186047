import { AddCircleIcon, Button, HistoryIcon, Tag } from "@jutro/ui";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { MedicDeclarationInfo } from "views/Patient/Profile/NfzStatus/MedicDeclarationInfo";
import { useNfzStatus } from "views/Patient/Profile/NfzStatus/useNfzStatus";

export const NfzStatus = () => {
  const { addTile } = useTileSystem();

  const { doctor, nurse, midwife, isDeclared } = useNfzStatus();

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <span className="font-label">Deklaracja POZ</span>

          {isDeclared ? (
            <Tag text="Aktywna" color="green" />
          ) : (
            <Tag text="Brak" color="rose" />
          )}
        </div>

        <div className="flex gap-1">
          <Button
            full={false}
            text="Historia"
            variant="secondary"
            onClick={() =>
              addTile("patient-declaration-history", TileColumn.Right)
            }
            size="condensed"
            icon={<HistoryIcon />}
          />
          <Button
            full={false}
            text="Dodaj"
            variant="secondary"
            onClick={() =>
              addTile("patient-declaration-creator", TileColumn.Right)
            }
            size="condensed"
            icon={<AddCircleIcon />}
          />
        </div>
      </div>

      <div className="grid gap-2">
        <MedicDeclarationInfo label="Lekarska" medic={doctor} />
        <MedicDeclarationInfo label="Pielęgniarska" medic={nurse} />
        <MedicDeclarationInfo label="Położnicza" medic={midwife} />
      </div>
    </div>
  );
};

import { ReactNode } from "react";
import { tv } from "tailwind-variants";
import type { VariantProps } from "tailwind-variants";
import { InfoIcon } from "components/new/InfoIcon";

const getStyles = tv({
  base: "rounded-lg p-4 font-paragraph-2 text-jutro-new-warm-gray-800",
  variants: {
    color: {
      blue: "bg-jutro-new-blue-800/10",
      red: "bg-jutro-new-rose-100",
    },
  },
});

type Props = {
  infos: (string | ReactNode)[];
} & VariantProps<typeof getStyles>;

export const InfoBox = ({ infos, color = "blue" }: Props) => {
  return (
    <div className={getStyles({ color })}>
      {infos.map((info) => (
        <div key={info?.toString()} className="flex items-start gap-2">
          <InfoIcon color={color} />
          <span className="w-[90%]">{info}</span>
        </div>
      ))}
    </div>
  );
};

import { NetworkStatus } from "@apollo/client";
import { Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { ErrorMessage } from "components/TagFormNew/ErrorMessage";
import { InputWrapper } from "components/TagFormNew/InputWrapper";
import { MedicalDocument, TagForm } from "components/TagFormNew/types";
import { isImage, outDocuments } from "components/TagFormNew/utils";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { MedicalDocumentationList } from "components/new/MedicalDocumentation/List";
import { useDoctorGetDocumentsQuery } from "lib/graphql/megaSchema";
import { chosenDocumentsAtom, hasDocumentErrorAtom } from "../atoms";
import { FileElement } from "./FileElement";
import { ImageElement } from "./ImageElement";

type DocumentationSectionProps = {
  patientId: string;
  tagForm: TagForm;
};

export const DocumentationSection = ({
  patientId,
  tagForm,
}: DocumentationSectionProps) => {
  const [chosenDocuments, setChosenDocuments] = useAtom(chosenDocumentsAtom);
  const [documentsDraft, setDocumentsDraft] = useState<MedicalDocument[]>([]);

  const [hasDocumentError, setHasDocumentError] = useAtom(hasDocumentErrorAtom);
  const { tag: editedTag } = tagForm;

  const { data, loading, networkStatus } = useDoctorGetDocumentsQuery({
    variables: { id: patientId },
    fetchPolicy: "no-cache",
  });

  const [isAddDocsOpen, setIsAddDocsOpen] = useState(false);

  useEffect(() => {
    if (!data?.doctorPatient) {
      return;
    }

    const { documents } = data.doctorPatient;

    setDocumentsDraft(documents.filter(({ tagId }) => tagId === editedTag?.id));

    setChosenDocuments(
      documents.filter(({ tagId }) => tagId === editedTag?.id),
    );
  }, [data, editedTag]);

  useEffect(() => {
    setHasDocumentError(false);
  }, [chosenDocuments]);

  const { images, files } = useMemo(() => {
    if (!data?.doctorPatient?.documents) {
      return {
        images: [],
        files: [],
      };
    }
    const { documents } = data.doctorPatient;

    return {
      images: documents.filter(
        (document) =>
          outDocuments(document, editedTag?.id) && isImage(document.name),
      ),
      files: documents.filter(
        (document) =>
          outDocuments(document, editedTag?.id) && !isImage(document.name),
      ),
    };
  }, [data]);

  return (
    <>
      <div>
        <InputWrapper header="Lista plików">
          <MedicalDocumentationList
            documents={chosenDocuments}
            loading={loading || networkStatus === NetworkStatus.refetch}
            patientId={patientId}
            isDisplayOnly
          />
        </InputWrapper>
        <Button
          text={
            chosenDocuments.length === 0
              ? "Dodaj plik/pliki z dokumentacji"
              : `Edytuj dodane pliki (${chosenDocuments.length})`
          }
          variant="secondary"
          type="button"
          onClick={() => {
            setIsAddDocsOpen(true);
          }}
        />
        {hasDocumentError && (
          <ErrorMessage text="Dodaj plik, lub zmień źródło" />
        )}
      </div>
      <ConfirmDialog
        open={isAddDocsOpen}
        setOpen={setIsAddDocsOpen}
        title="Wybierz plik/pliki z dokumentacji"
        confirmText="Zatwierdź"
        submitVariant="primary"
        onConfirm={() => {
          setChosenDocuments(documentsDraft);
        }}
        onClose={() => {
          setDocumentsDraft(chosenDocuments);
        }}
        isResponsive
      >
        <InputWrapper header="Zdjęcia z dokumentacji">
          <div className="mb-4 h-80 overflow-y-scroll">
            <div className="grid grid-cols-2 content-center gap-2">
              {images.length > 0 ? (
                images.map((document) => (
                  <ImageElement
                    key={document.id}
                    document={document}
                    isChecked={Boolean(
                      documentsDraft.find(({ id }) => id === document.id),
                    )}
                    handleChecked={setDocumentsDraft}
                  />
                ))
              ) : (
                <span className="font-paragraph-2">Brak</span>
              )}
            </div>
          </div>
        </InputWrapper>
        <InputWrapper header="Pliki z dokumentacji">
          <div className="flex flex-col gap-2">
            {files.length > 0 ? (
              files.map((document) => {
                return (
                  <FileElement
                    key={document.id}
                    document={document}
                    isChecked={Boolean(
                      documentsDraft.find(({ id }) => id === document.id),
                    )}
                    handleChecked={setDocumentsDraft}
                  />
                );
              })
            ) : (
              <span className="font-paragraph-2">Brak</span>
            )}
          </div>
        </InputWrapper>
      </ConfirmDialog>
    </>
  );
};

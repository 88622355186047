import { useState } from "react";
import { FileWithPath } from "react-dropzone";
import ReactModal from "react-modal";
import { useDropzoneHiddenState } from "components/new/Chat/hooks";
import { DropArea } from "components/new/Dropzone/DropArea";
import { DropppedFilesPreview } from "components/new/Dropzone/DroppedFilesPreview";
import { DropzoneFilesList } from "components/new/Dropzone/DropzoneFilesList";

export type TextSize = "xs" | "sm" | "md" | "lg";

const modalOverlayStyles = {
  overlay: {
    zIndex: 50,
    backgroundColor: "rgba(0,0,0, .6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const textSizeMap: Record<TextSize, string> = {
  xs: "font-paragraph-2",
  sm: "font-paragraph-2",
  md: "font-paragraph-1",
  lg: "font-paragraph-0",
};

export type FileWithPreview = FileWithPath & {
  preview?: string;
};

type Props = {
  maxFiles: number;
  selectedFiles: FileWithPreview[];
  addFileText: string;
  isModal: boolean;
  onFilesListUpdate: (files: FileWithPreview[]) => void;
  size?: TextSize;
};

export const Dropzone = ({
  maxFiles,
  isModal,
  selectedFiles,
  addFileText,
  size = "sm",
  onFilesListUpdate,
}: Props) => {
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const [isFilePdf, setIsFilePdf] = useState(false);

  const hidden = useDropzoneHiddenState();

  const showDropArea =
    (selectedFiles.length === 0 || selectedFiles.length < maxFiles) && !hidden;

  return (
    <>
      {showDropArea && (
        <DropArea
          maxFiles={maxFiles}
          selectedFiles={selectedFiles}
          size={textSizeMap[size]}
          setIsFilePdf={setIsFilePdf}
          setIsFilePreviewModalOpen={setIsFilePreviewModalOpen}
          onFilesListUpdate={onFilesListUpdate}
        />
      )}

      {selectedFiles.length !== 0 && (
        <DropzoneFilesList
          selectedFiles={selectedFiles}
          size={textSizeMap[size]}
          onFilesListUpdate={onFilesListUpdate}
          setIsFilePreviewModalOpen={setIsFilePreviewModalOpen}
        />
      )}

      <ReactModal
        isOpen={isFilePreviewModalOpen}
        style={modalOverlayStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        contentElement={() => (
          <DropppedFilesPreview
            file={selectedFiles[0]?.preview ?? ""}
            addFileText={addFileText}
            isPdf={isFilePdf}
            isModal={isModal}
            onClose={() => setIsFilePreviewModalOpen(false)}
            onAbort={() => {
              setIsFilePreviewModalOpen(false);
              selectedFiles.pop();
            }}
          />
        )}
      />
    </>
  );
};

import { DoctorMSMedicalDocumentSource } from "lib/graphql/megaSchema";

const statusMap: Record<DoctorMSMedicalDocumentSource, string> = {
  EMAIL: "E-MAIL",
  DRIVE: "RĘCZNIE",
  CHAT: "Z CZATU",
  EKG: "EKG",
  CHAT_LLM: "Z CZATU (LLM)",
};

const colorMap: Record<DoctorMSMedicalDocumentSource, string> = {
  EMAIL: "bg-jutro-new-blue-500",
  DRIVE: "bg-jutro-new-green-400",
  CHAT: "bg-jutro-new-blue-800",
  EKG: "bg-jutro-new-orange-400",
  CHAT_LLM: "bg-jutro-new-blue-900",
};

type StatusPillProps = {
  value: DoctorMSMedicalDocumentSource | undefined | null;
};

const returnPill = (
  value: DoctorMSMedicalDocumentSource | undefined | null,
) => {
  if (!value || !Object.keys(statusMap).includes(value)) {
    return { status: "NIEZNANE", color: "bg-jutro-new-warm-gray-300" };
  }

  return { status: statusMap[value], color: colorMap[value] };
};

export const StatusPill = ({ value }: StatusPillProps) => {
  const { color, status } = returnPill(value);

  return (
    <div
      className={`font-caption flex items-center justify-center rounded-lg px-3 py-1 text-white shadow-sm ${color} `}
    >
      {status}
    </div>
  );
};

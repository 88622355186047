import { ScheduleVisitTileData } from "components/ScheduleVisitForm/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

const tileModeConfigMap: Record<
  ScheduleVisitTileData["mode"],
  {
    title: string;
    toggleText: string;
    switchMode: ScheduleVisitTileData["mode"];
  }
> = {
  edit: {
    title: "Edytuj wizytę",
    toggleText: "Edytuj wizytę przez wymuszenie",
    switchMode: "forceEdit",
  },
  forceEdit: {
    title: "Edytuj wizytę przez wymuszenie",
    toggleText: "Cofnij",
    switchMode: "edit",
  },
  create: {
    title: "Umów wizytę",
    toggleText: "Wymuś wizytę",
    switchMode: "forceCreate",
  },
  forceCreate: {
    title: "Wymuś wizytę",
    toggleText: "Cofnij",
    switchMode: "create",
  },
};
type Props = {
  tileId: string;
  mode: ScheduleVisitTileData["mode"];
};

export const useTileModeConfig = ({ tileId, mode }: Props) => {
  const { updateTileData } = useTileSystem();

  const switchMode = () => {
    const modeToSwitch = tileModeConfigMap[mode].switchMode;

    updateTileData({ tileId, data: { mode: modeToSwitch } });
  };

  return {
    ...tileModeConfigMap[mode],
    switchMode,
  };
};

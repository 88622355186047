import { Option } from "@jutro/types";
import {
  DoctorMSPrimaryMedicalService,
  DoctorMSVisitFilterInput,
  DoctorMSVisitStatus,
  DoctorMSVisitType,
} from "lib/graphql/megaSchema";

export type VisitArchiveFilters =
  | "SHOW_ALL"
  | "INPROGRESS"
  | "CLOSED"
  | "HAS_PRIMARY_MEDICAL_SERVICE"
  | "PROCEDURES"
  | "ADVICES";

export const visitsArchiveFiltersOptions: Option<VisitArchiveFilters>[] = [
  { value: "SHOW_ALL", label: "Pokaż wszystkie" },
  { value: "INPROGRESS", label: "Wizyty w trakcie" },
  { value: "CLOSED", label: "Wizyty zamknięte" },
  { value: "HAS_PRIMARY_MEDICAL_SERVICE", label: "Opieka koordynowana" },
  { value: "PROCEDURES", label: "Zabiegowe" },
  { value: "ADVICES", label: "Lekarskie" },
];

type Filters = Pick<
  DoctorMSVisitFilterInput,
  "primaryMedicalService" | "status" | "visitType"
>;

export const buildVisitsArchiveFiltersInput = (
  filters: Option<VisitArchiveFilters>[],
) => {
  const buildedFilters: Filters = {
    status: null,
    primaryMedicalService: null,
    visitType: null,
  };

  if (filters.some(({ value }) => value === "HAS_PRIMARY_MEDICAL_SERVICE")) {
    buildedFilters.primaryMedicalService = [
      "INITIAL",
      "COMPLEX",
      "IPOM_DIET",
      "IPOM_PULMONOLOGY",
      "IPOM_ALLERGOLOGY",
      "IPOM_CARDIOLOGY",
    ];
  }

  const statusValues: DoctorMSVisitStatus[] = [];

  if (filters.some(({ value }) => value === "INPROGRESS")) {
    statusValues.push("INPROGRESS");
  }

  if (filters.some(({ value }) => value === "CLOSED")) {
    statusValues.push("SUCCESS", "ARCHIVE");
  }

  if (statusValues.length > 0) {
    buildedFilters.status = statusValues;
  }

  const typeValues: DoctorMSVisitType[] = [];

  if (filters.some(({ value }) => value === "PROCEDURES")) {
    typeValues.push("PROCEDURE");
  }

  if (filters.some(({ value }) => value === "ADVICES")) {
    typeValues.push("STANDARD", "VIRTUAL");
  }

  if (typeValues.length > 0) {
    buildedFilters.visitType = typeValues;
  }

  return buildedFilters;
};

export const primaryMedicalServiceContentMap: Record<
  DoctorMSPrimaryMedicalService,
  { label: string; tooltip?: string } | null
> = {
  COMPLEX: { label: "Kompleksowa  w OK" },
  INITIAL: { label: "Wstępna w OK" },
  IPOM_DIET: { label: "Dietetyczna w OK" },
  IPOM_PULMONOLOGY: {
    label: "Specjalista w OK",
    tooltip: "Porada pacjent-specjalista w dziedzinie pulmonologii",
  },
  IPOM_ALLERGOLOGY: {
    label: "Specjalista w OK",
    tooltip: "Porada pacjent-specjalista w dziedzinie alergologii",
  },
  IPOM_CARDIOLOGY: {
    label: "Specjalista w OK",
    tooltip: "Porada pacjent-specjalista w dziedzinie kardiologii",
  },
  NONE: null,
};

import { CalendarAddIcon, Input } from "@jutro/ui";
import { Dayjs } from "dayjs";
import DateTimePicker from "react-datetime-picker";
import { Controller } from "react-hook-form";
import ReactModal from "react-modal";
import ReactSelect, {
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
import { customSelectStyles } from "components/PatientDocs/Ordered/utils";
import { ErrorMessage } from "components/TagFormNew/ErrorMessage";
import { Avatar, Checkbox } from "components/new";
import { SelectWrapper } from "components/new/SelectWrapper";
import { DoctorMSShiftOutputDynamic } from "lib/graphql/megaSchema";
import { organizationClinicOption } from "lib/hooks/useVojevodshipOptions";
import { ButtonsController } from "views/StaffScheduleCreator/components/SlotActionModal/ButtonsController";
import { useActionModalForm } from "views/StaffScheduleCreator/hooks/useActionModalForm";
import {
  SlotActionModalMode,
  StaffOptions,
  onSiteOptions,
} from "views/StaffScheduleCreator/utils/index";

type Props = {
  doctorsOptions?: StaffOptions[];
  isModalOpen: boolean;
  selectedSlotDoctorId?: string;
  selectedDay?: Dayjs;
  startDate: Dayjs;
  endDate: Dayjs;
  modalMode: SlotActionModalMode;
  selectedShift: DoctorMSShiftOutputDynamic | undefined;
  selectedOrganization?: string | undefined;
  organizationsClinicsOptions: organizationClinicOption[];
  onCloseModal: () => void;
};

export const SlotActionModal = ({
  isModalOpen,
  endDate,
  modalMode,
  ...actionModalProps
}: Props) => {
  const {
    control,
    errors,
    shiftUpsertMutationLoading,
    slotDurationFieldValue,
    doctorFieldValue,
    shiftTypesOptions,
    handleSubmit,
    onSubmit,
  } = useActionModalForm({
    ...actionModalProps,
  });

  const isEditMode = modalMode === "edit";
  const isCreateMode = modalMode === "create";
  const isDeletable = Boolean(
    actionModalProps.selectedShift?.draftData &&
      !actionModalProps.selectedShift.draftData.active,
  );

  const {
    timeFrom: timeFromFieldError,
    timeTo: timeToFieldError,
    slotDuration: slotDurationFieldError,
  } = errors;

  return (
    <ReactModal
      isOpen={isModalOpen}
      shouldCloseOnEsc
      onRequestClose={actionModalProps.onCloseModal}
      className="font-paragraph-2 z-50 flex h-full w-full flex-col items-center justify-center gap-2 text-xs font-medium text-jutro-new-warm-gray-800"
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      style={{
        overlay: { zIndex: 50, backgroundColor: "rgba(24, 24, 26, 0.5)" },
      }}
    >
      <form
        className="flex w-[640px] flex-col justify-between gap-4 rounded-lg bg-white p-4"
        onSubmit={handleSubmit((data) => onSubmit(data, "create"))}
      >
        <div>
          <h4 className="text-lg font-bold text-jutro-new-warm-gray-700">
            {isEditMode ? "Podgląd" : "Utwórz zmianę"}
          </h4>
        </div>

        <div className="flex flex-col gap-4">
          <Controller
            control={control}
            name="organizationId"
            render={({ field: { value }, fieldState: { error } }) => (
              <div className="flex flex-col gap-1">
                <SelectWrapper label="Lokalizacja">
                  <ReactSelect
                    options={actionModalProps.organizationsClinicsOptions}
                    isDisabled
                    value={value}
                    styles={customSelectStyles(error)}
                  />
                </SelectWrapper>
                {error?.message && <ErrorMessage text={error.message} />}
              </div>
            )}
          />

          <Controller
            control={control}
            name="doctor"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectWrapper label="Przypisz do">
                <ReactSelect
                  placeholder="Wybierz z listy"
                  options={actionModalProps.doctorsOptions}
                  value={value}
                  onChange={onChange}
                  isDisabled={
                    Boolean(actionModalProps.selectedSlotDoctorId) || isEditMode
                  }
                  styles={customSelectStyles<string>(error)}
                  components={{
                    Option: MyOption,
                    SingleValue: MySingleValue,
                  }}
                />
                {error?.message && <ErrorMessage text={error.message} />}
              </SelectWrapper>
            )}
          />

          <div className="flex flex-col gap-1">
            <Controller
              control={control}
              name="slotType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="flex flex-col gap-1">
                  <SelectWrapper label="Typ slotu">
                    <ReactSelect
                      placeholder="Wybierz z listy"
                      options={shiftTypesOptions}
                      value={value}
                      isDisabled={
                        isEditMode ||
                        (isCreateMode && Boolean(!doctorFieldValue))
                      }
                      onChange={onChange}
                      styles={customSelectStyles(error)}
                    />
                  </SelectWrapper>
                  {error?.message && <ErrorMessage text={error.message} />}
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-1">
            <Controller
              control={control}
              name="placement"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="flex flex-col gap-1">
                  <SelectWrapper label="Miejsce wykonywania pracy">
                    <ReactSelect
                      placeholder="Wybierz z listy"
                      options={onSiteOptions}
                      value={value}
                      isDisabled={isEditMode}
                      onChange={onChange}
                      styles={customSelectStyles(error)}
                    />
                  </SelectWrapper>
                  {error?.message && <ErrorMessage text={error.message} />}
                </div>
              )}
            />
          </div>

          <Controller
            control={control}
            name="day"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="flex w-1/4 flex-col gap-2">
                <SelectWrapper label="Data">
                  <DateTimePicker
                    format="yyyy-MM-dd"
                    maxDate={endDate.endOf("month").toDate()}
                    minDate={actionModalProps.startDate
                      .startOf("month")
                      .toDate()}
                    disableClock
                    clearIcon={null}
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="RRRR"
                    calendarIcon={<CalendarAddIcon size="sm" />}
                    locale="pl"
                    value={value}
                    disabled={isEditMode}
                    onChange={onChange}
                    className={`declaration-date-time-picker h-[40px] rounded-lg border text-sm font-normal text-jutro-new-warm-gray-800 focus-within:border-jutro-new-blue-800 ${
                      error
                        ? "border-jutro-new-rose-600"
                        : "border-jutro-new-warm-gray-300"
                    } ${isEditMode ? "" : "hover:border-jutro-new-blue-800"}`}
                  />
                  {error?.message && (
                    <ErrorMessage text={"Te pola są wymagane"} />
                  )}
                </SelectWrapper>
              </div>
            )}
          />

          <SelectWrapper label="Przedział czasowy">
            <div className="flex flex-1 flex-row justify-start gap-2">
              <Controller
                control={control}
                name="timeFrom"
                render={({ field: { onChange, value } }) => (
                  <div
                    className={`flex items-center gap-1 rounded-lg border px-2 focus-within:border-jutro-new-blue-800 ${timeFromFieldError ? "border-jutro-new-rose-600" : "border-jutro-new-warm-gray-300"} ${isEditMode ? "cursor-not-allowed bg-jutro-new-warm-gray-100" : "hover:border-jutro-new-blue-800"}`}
                  >
                    <span>od</span>
                    <input
                      type="time"
                      disabled={isEditMode}
                      value={value}
                      min="20"
                      onChange={onChange}
                      className={`rounded-lg px-1 outline-none ${isEditMode && "cursor-not-allowed bg-jutro-new-warm-gray-100"}`}
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="timeTo"
                render={({ field: { onChange, value } }) => (
                  <div
                    className={`flex items-center gap-1 rounded-lg border px-2 py-2 focus-within:border-jutro-new-blue-800 ${timeToFieldError ? "border-jutro-new-rose-600" : "border-jutro-new-warm-gray-300"} ${isEditMode ? "cursor-not-allowed bg-jutro-new-warm-gray-100" : "hover:border-jutro-new-blue-800"}`}
                  >
                    <span>do</span>
                    <input
                      type="time"
                      disabled={isEditMode}
                      value={value}
                      onChange={onChange}
                      className={`rounded-lg px-1 outline-none ${isEditMode && "cursor-not-allowed bg-jutro-new-warm-gray-100"}`}
                    />
                  </div>
                )}
              />
            </div>
            {(timeFromFieldError || timeToFieldError) && (
              <ErrorMessage text={"Te pola są wymagane"} />
            )}
          </SelectWrapper>

          <div className="flex flex-col gap-2">
            <Controller
              control={control}
              name="slotDurationEdit"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label="Niestandardowa długość slotu"
                  value={value}
                  disabled={isEditMode}
                  onChange={onChange}
                />
              )}
            />

            {slotDurationFieldValue && (
              <div className="flex w-1/4 flex-col gap-2">
                <Controller
                  control={control}
                  name="slotDuration"
                  render={({ field: { onChange, value } }) => (
                    <SelectWrapper label="Długość slotu">
                      <div
                        className={`flex min-h-[40px] items-center justify-between rounded-lg border px-2 py-2 focus-within:border-jutro-new-blue-800 ${slotDurationFieldError ? "border-jutro-new-rose-600" : "border-jutro-new-warm-gray-300"} ${isEditMode ? "cursor-not-allowed bg-jutro-new-warm-gray-100" : "hover:border-jutro-new-blue-800"}`}
                      >
                        <input
                          type="number"
                          disabled={isEditMode}
                          value={value}
                          onChange={(e) => {
                            let value = Number(e.target.value);
                            if (value > 59) {
                              value = 59;
                            } else if (value <= 1) {
                              value = 1;
                            }
                            onChange(value.toString());
                          }}
                          className={`flex w-3/4 justify-end outline-none ${isEditMode && "cursor-not-allowed bg-jutro-new-warm-gray-100"}`}
                        />
                        <span>min</span>
                      </div>
                      {slotDurationFieldError && (
                        <ErrorMessage text={"To pole jest wymagane"} />
                      )}
                    </SelectWrapper>
                  )}
                />
              </div>
            )}
          </div>

          <div>
            <Controller
              control={control}
              name="comment"
              render={({ field: { onChange, value } }) => (
                <SelectWrapper label="Komentarz (nieobowiązkowe)">
                  <Input
                    placeholder="np. szczegóły dotyczące urlopu lub powodu nadgodzin"
                    disabled={isEditMode}
                    value={value ?? ""}
                    onChange={onChange}
                  />
                </SelectWrapper>
              )}
            />
          </div>
        </div>

        <div className="flex justify-between">
          <ButtonsController
            modalMode={modalMode}
            isDeletable={isDeletable}
            loading={shiftUpsertMutationLoading}
            onCloseModal={actionModalProps.onCloseModal}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </div>
      </form>
    </ReactModal>
  );
};

const MyOption = (props: OptionProps<{ label: string; value: string }>) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-2">
        <Avatar userId={props.data.value} />
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

const MySingleValue = (
  props: SingleValueProps<{ label: string; value: string }, false>,
) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center gap-2">
        <Avatar userId={props.data.value} />
        <span>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );
};

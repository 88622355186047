import { RunOptions, run } from "@mdx-js/mdx";
import * as provider from "@mdx-js/react";
import { MDXContent } from "mdx/types";
import { useState } from "react";
import * as runtime from "react/jsx-runtime";
import {
  DoctorMSProcedureType,
  useDoctorGetManyProcedureQuery,
  useDoctorGetProcedureRenderQuery,
} from "lib/graphql/megaSchema";

type BaseProps = {
  procedureType: DoctorMSProcedureType;
  visitId: string | undefined;
  skip?: boolean;
};

type Props = BaseProps &
  (
    | {
        formType: "edit";
        procedureId: string;
      }
    | { formType: "create" }
  );

export const useRenderMdxForm = ({
  visitId,
  procedureType,
  skip,
  ...props
}: Props) => {
  const [Component, setComponent] = useState<{
    Content: MDXContent;
  } | null>(null);

  const { data, loading } = useDoctorGetProcedureRenderQuery({
    fetchPolicy: "network-only",
    variables: {
      type: procedureType,
      visitId: visitId ?? "",
      procedureId: props.formType === "edit" ? props.procedureId : undefined,
    },
    skip,
    onCompleted: async (data) => {
      setComponent(null);
      const context = data.doctorProcedureRender.context;
      const render = data.doctorProcedureRender[props.formType];

      if (!render) {
        return;
      }

      const ctx = Object.keys(context).length
        ? Object.entries(context)
            .map(([key, value]) => `const ${key} = ${JSON.stringify(value)};`)
            .join(" ")
        : "";

      const result = await run(ctx + render, {
        ...provider,
        ...(runtime as any),
        Fragment: <></>,
        baseUrl: import.meta.url,
      } as RunOptions);
      setComponent({ Content: result.default });
    },
  });

  return {
    Component,
    schema: data?.doctorProcedureRender.schema[props.formType],
    formData: data?.doctorProcedureRender.data,
    loading,
  };
};

export const useRenderManyMdxProcedures = ({
  formType,
  procedureIds,
  visitId,
}: {
  formType: "create" | "edit" | "view";
  procedureIds: string[];
  visitId: string;
}) => {
  const [isCompiling, setIsCompiling] = useState(false);

  const [Components, setComponents] = useState<
    | {
        Content: MDXContent;
      }[]
    | null
  >(null);

  const [procedureIdsWithoutRender, setProcedureIdsWithoutRender] = useState<
    string[]
  >([]);

  const { loading } = useDoctorGetManyProcedureQuery({
    fetchPolicy: "network-only",
    variables: {
      procedureIds,
      visitId,
    },
    onCompleted: async (data) => {
      setComponents(null);
      setIsCompiling(true);

      const proceduresPromises = data.doctorManyProceduresRender.map(
        async (procedure, index) => {
          const context = procedure.context;
          const data = procedure.data;
          const render = procedure[formType];

          if (!render) {
            setProcedureIdsWithoutRender((prev) => [
              ...prev,
              procedureIds[index],
            ]);
            return;
          }

          const ctx = Object.keys({ ...context, data }).length
            ? Object.entries({ ...context, data })
                .map(
                  ([key, value]) => `const ${key} = ${JSON.stringify(value)};`,
                )
                .join(" ")
            : "";

          const result = await run(ctx + render, {
            ...provider,
            ...(runtime as any),
            Fragment: <></>,
            baseUrl: import.meta.url,
          } as RunOptions);

          return { Content: result.default };
        },
      );

      const proceduresResult = (await Promise.all(proceduresPromises)).filter(
        (p) => Boolean(p?.Content),
      ) as {
        Content: MDXContent;
      }[];

      setComponents(proceduresResult);
      setIsCompiling(false);
    },
  });

  return {
    procedureIdsWithoutRender,
    Components,
    loading: loading || isCompiling,
  };
};

import React, { Component, ContextType, ReactNode } from "react";
import {
  ErrorContext,
  ErrorContextType,
} from "views/MdxPlayground/ErrorContextProvider";

type Props = {
  children?: ReactNode;
};

export class ErrorBoundary extends Component<Props> {
  static contextType: React.Context<ErrorContextType> = ErrorContext;

  context!: ContextType<typeof ErrorContext>;

  componentDidCatch({ message }: Error) {
    this.context.updateError({ isError: true, message });
  }

  public render() {
    if (this.context.error.isError) {
      return (
        <div className="flex flex-col gap-2">
          <span className="font-lg font-bold">Wystąpił błąd!</span>

          <span className="text-red-500">{this.context.error.message}</span>
        </div>
      );
    }

    return this.props.children;
  }
}

import { Maybe } from "@jutro/types";
import { Tag } from "@jutro/ui";
import { DoctorMSVisitType } from "lib/graphql/megaSchema";
import { isPastVisit } from "lib/tools/isPastVisit";

type VisitElement = {
  type?: Maybe<DoctorMSVisitType>;
  snapshotAt?: Maybe<string>;
  startOn?: Maybe<string>;
  showOn?: Maybe<string>;
  prescriptionRequestId?: Maybe<string>;
  plannedStart?: Maybe<{ iso: string }>;
};

type Props = {
  visitElement: VisitElement;
};

export const VisitStatusTag = ({ visitElement }: Props) => {
  const { type, snapshotAt, startOn, showOn, prescriptionRequestId } =
    visitElement;

  if (snapshotAt) {
    return <Tag color="green" text="Zakończona" />;
  }

  if (startOn) {
    return <Tag color="blue" text="W trakcie" />;
  }

  if (type === "STANDARD") {
    if (showOn) {
      return <Tag color="green" text="Potwierdzona obecność" />;
    }

    if (isPastVisit(visitElement)) {
      return <Tag color="rose" text="Nieodwołana wizyta" />;
    }

    return (
      <Tag
        color="black-or-white"
        variant="inverted"
        text="Niepotwierdzona obecność"
      />
    );
  }

  if (isPastVisit(visitElement) && !prescriptionRequestId) {
    return <Tag color="rose" text="Nieodwołana wizyta" />;
  }

  if (visitElement.type === "PROCEDURE") {
    return null;
  }

  return <Tag color="black-or-white" variant="inverted" text="Nierozpoczęta" />;
};

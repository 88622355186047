import { Button, CircleCheckIcon, SendIcon } from "@jutro/ui";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  DoctorVisitDocument,
  useDoctorSendRecommendationMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";

type Props = {
  recommendationsMessageId?: string | null;
  isLoading?: boolean;
};

export const SendRecommendationButton = ({
  recommendationsMessageId,
  isLoading,
}: Props) => {
  const { visitId } = useParams<{ visitId: string }>();

  const [sendRecommendation, { loading: isSendingRecommendation, error }] =
    useDoctorSendRecommendationMutation({
      variables: {
        visitId: visitId ?? "",
      },
      refetchQueries: [DoctorVisitDocument],
      awaitRefetchQueries: true,
    });

  const { text, icon } = useMemo((): {
    text: string;
    icon: JSX.Element;
  } => {
    if (!recommendationsMessageId) {
      return {
        icon: <SendIcon />,
        text: "Wyślij zalecenia na czacie",
      };
    }

    return {
      icon: <CircleCheckIcon />,
      text: "Wysłano zalecenia na czacie",
    };
  }, [recommendationsMessageId]);

  return (
    <div>
      <Button
        full={false}
        text={text}
        icon={icon}
        type="button"
        variant="secondary"
        onClick={() =>
          toaster.promise(sendRecommendation(), {
            success: "Zalecenia zostały poprawnie wysłane.",
            error: "Wystąpił błąd. Nie udało się wysłać zaleceń na czat.",
            loading: "Wysyłam zalecenia na czat",
          })
        }
        disabled={Boolean(recommendationsMessageId)}
        loading={isLoading || isSendingRecommendation}
      />
    </div>
  );
};

import { PropsWithChildren, ReactNode } from "react";

type Flex = "none" | "1" | "2" | "3";

type FontFamily = "none" | "nunitoSans";

type FontWeight = "none" | "600";

type FontSize = "none" | "xs";

const flexMap: Record<Flex, string> = {
  none: "",
  "1": "flex-1",
  "2": "flex-2",
  "3": "flex-3",
};

const fontFamilyMap: Record<FontFamily, string> = {
  none: "font-geologica",
  nunitoSans: "font-nunitoSans",
};

const fontWeightMap: Record<FontWeight, string> = {
  none: "font-medium",
  600: "font-semibold",
};

const fontSizeMap: Record<FontSize, string> = {
  none: "font-paragraph-2",
  xs: "font-paragraph-2",
};

type Props = {
  title?: ReactNode;
  flex?: Flex;
  noPadding?: boolean;
  required?: boolean;
  fontFamily?: FontFamily;
  fontWeight?: FontWeight;
  fontSize?: FontSize;
};

export const Block = ({
  title,
  flex = "none",
  noPadding = false,
  children,
  fontSize = "none",
  fontWeight = "none",
  fontFamily = "none",
  required = false,
}: PropsWithChildren<Props>) => (
  <div
    className={`block w-full rounded-lg bg-white ${
      noPadding ? "p-0" : "px-4 py-3"
    } ${flexMap[flex]} ${fontFamilyMap[fontFamily]} ${
      fontWeightMap[fontWeight]
    }`}
  >
    <div className="flex justify-between">
      {title && (
        <div
          className={`${fontWeightMap[fontWeight]} ${fontSizeMap[fontSize]} text-jutro-new-warm-gray-800`}
        >
          {title}
        </div>
      )}
      {required && <span className="text-jutro-new-rose-500">*</span>}
    </div>

    {children}
  </div>
);

import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  header: string;
  bold?: boolean;
}>;

export const InputWrapper = ({ bold = false, header, children }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <header
        className={`${bold && "font-medium"} text-jutro-new-warm-gray-900`}
      >
        {header}
      </header>
      {children}
    </div>
  );
};

type Color = "blue" | "red";

const colorMap: Record<Color, string> = {
  blue: "#0A40A1",
  red: "#FF386D",
};

export const InfoIcon = ({
  color,
  onClick,
}: {
  color: Color;
  onClick?: () => void;
}) => {
  const hexColor = colorMap[color];

  return (
    <svg
      className={`h-4 w-4 ${onClick ? "cursor-pointer" : ""}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16Z"
        fill={hexColor}
      />
      <path d="M8 11.5V8Z" fill={hexColor} />
      <path
        d="M8 11.5V8"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.44507C7.75838 5.44507 7.5625 5.24919 7.5625 5.00757C7.5625 4.76595 7.75838 4.57007 8 4.57007"
        fill={hexColor}
      />
      <path
        d="M8 5.44507C7.75838 5.44507 7.5625 5.24919 7.5625 5.00757C7.5625 4.76595 7.75838 4.57007 8 4.57007"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M8 5.44507C8.24162 5.44507 8.4375 5.24919 8.4375 5.00757C8.4375 4.76595 8.24162 4.57007 8 4.57007"
        fill={hexColor}
      />
      <path
        d="M8 5.44507C8.24162 5.44507 8.4375 5.24919 8.4375 5.00757C8.4375 4.76595 8.24162 4.57007 8 4.57007"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

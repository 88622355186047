import { Defined } from "@jutro/types";
import { unique } from "radash";
import { IpomFormSchema } from "components/IpomForm/schema";
import {
  DoctorLaboratoryExaminationsQuery,
  DoctorMSLaboratoryExaminationEntity,
  DoctorProceduresQuery,
} from "lib/graphql/megaSchema";

export const extractLabExams = (
  labExamsData: Defined<DoctorLaboratoryExaminationsQuery>,
  visitId: string,
) =>
  labExamsData.doctorLaboratoryExaminations
    .filter(({ createdOnVisitId }) => createdOnVisitId === visitId)
    .filter(
      ({ assignment }) => assignment.icd9,
    ) as DoctorMSLaboratoryExaminationEntity[];

const transformLabExams = (
  visitLabExams: DoctorMSLaboratoryExaminationEntity[],
): IpomFormSchema["scheduledDiagnosticTests"] => {
  return visitLabExams.map((labExam) => ({
    name: labExam.assignment.name,
    time: "NAJSZYB",
    timeUnit: null,
    timeValue: null,
    type: "LAB_EXAM",
    icd9: labExam.assignment.icd9,
    active: true,
  }));
};

export const extractSpirometryProcedure = (
  procedures: Defined<DoctorProceduresQuery>,
  visitId: string,
): IpomFormSchema["scheduledDiagnosticTests"] => {
  const visitSpirometryProcedures = procedures.doctorProcedures.filter(
    ({ createdOnVisitId, type }) =>
      createdOnVisitId === visitId && type === "SPIROMETRY",
  );

  if (visitSpirometryProcedures.length === 0) {
    return [];
  }

  const results: IpomFormSchema["scheduledDiagnosticTests"] = [];

  const baseSpirometryData = {
    time: "NAJSZYB",
    timeUnit: null,
    timeValue: null,
    type: "PROCEDURE",
    icd9: null,
    active: true,
  } as const;

  if (
    visitSpirometryProcedures.some(
      ({ spirometry }) => spirometry?.diastolicTest,
    )
  ) {
    results.push({
      name: "Spirometria z próbą rozkurczową",
      ...baseSpirometryData,
    });
  }

  if (
    visitSpirometryProcedures.some(
      ({ spirometry }) => !spirometry?.diastolicTest,
    )
  ) {
    results.push({
      name: "Spirometria",
      ...baseSpirometryData,
    });
  }

  return results;
};

export const filterUniqueLabExams = (
  existingLabExams: IpomFormSchema["scheduledDiagnosticTests"],
  visitSpirometryProcedures: IpomFormSchema["scheduledDiagnosticTests"],
  visitLabExams: DoctorMSLaboratoryExaminationEntity[],
) => {
  return unique(
    [
      ...existingLabExams,
      ...visitSpirometryProcedures,
      ...transformLabExams(visitLabExams),
    ],
    (labExam) => labExam.name,
  );
};

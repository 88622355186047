import { Avatar, Tag, Tooltip } from "@jutro/ui";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { getAgeLabelsFromPesel } from "components/PatientDocs/tools";
import { DoctorMSDoctor } from "lib/graphql/megaSchema";
import { formatPatientCardNumber } from "lib/tools/formatPatientCardNumber";
import { avatarConfig } from "lib/tools/httpConfigs";
import { primaryMedicalServiceContentMap } from "views/Visits/Archive/tools";
import { ArchivedVisit } from "views/Visits/Archive/types";

export const visitsArchiveTableColumns: ColumnDef<ArchivedVisit>[] = [
  {
    accessorKey: "patient.patientCardNumber",
    header: "Numer karty",
    cell: ({ getValue }) => (
      <div className="flex h-6 min-w-28 items-center">
        <Tag text={formatPatientCardNumber(getValue<string>())} />
      </div>
    ),
  },
  {
    accessorKey: "plannedStart.iso",
    header: "Data wizyty",
    cell: ({ getValue }) => (
      <div className="font-paragraph-1 min-w-32">
        {dayjs(getValue<string>()).format("D MMM YYYY")}
      </div>
    ),
  },
  {
    accessorKey: "updatedAt.iso",
    header: "Data edycji",
    cell: ({ getValue }) => (
      <div className="font-paragraph-1 min-w-32">
        {dayjs(getValue<string>()).format("D MMM YYYY")}
      </div>
    ),
  },
  {
    accessorKey: "patient.pesel",
    header: "Wiek",
    cell: ({ getValue }) => (
      <div className="font-paragraph-1 min-w-24">
        {getAgeLabelsFromPesel(getValue<string>()).years}
      </div>
    ),
  },
  {
    id: "doctor",
    accessorKey: "doctor",
    header: "Personel",
    cell: ({ getValue }) => {
      const { id, firstName, lastName } = getValue<DoctorMSDoctor>();

      return (
        <div className="flex w-fit min-w-48 gap-3">
          <div className="h-6 w-6">
            <Avatar
              type="photo"
              imgSrc={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${id}`}
            />
          </div>
          <span className="font-label">
            {firstName} {lastName}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ getValue }) => {
      const isInProgress = getValue<ArchivedVisit["status"]>() === "INPROGRESS";
      return (
        <div className="flex h-6 min-w-24 items-center">
          <Tag
            text={isInProgress ? "W trakcie" : "Zamknięta"}
            color={isInProgress ? "green" : "blue"}
          />
        </div>
      );
    },
  },
  {
    accessorKey: "primaryMedicalService",
    header: "Świadczenie główne",
    cell: ({ getValue }) => {
      const primaryMedicalService =
        getValue<ArchivedVisit["primaryMedicalService"]>();

      const primaryMedicalServiceContent = primaryMedicalService
        ? primaryMedicalServiceContentMap[primaryMedicalService]
        : null;

      return (
        <div className="flex min-h-6 min-w-44 items-center">
          {primaryMedicalServiceContent && (
            <Tooltip
              position="top"
              triggerAsChild={false}
              trigger={<Tag text={primaryMedicalServiceContent.label} />}
              disabled={!primaryMedicalServiceContent.tooltip}
              content={primaryMedicalServiceContent.tooltip}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "doctorDiagnosis",
    header: "Rozpoznania ICD10",
    cell: ({ row }) => {
      const doctorDiagnosis = row.original.doctorDiagnosis ?? [];

      const transformDoctorDiagnosis = doctorDiagnosis
        .map((value) => {
          if (!value?.code) {
            return null;
          }

          const [code, text] = value.code.split(/(?<=^\S+)\s/);

          return { code, text };
        })
        .filter(
          (item): item is { code: string; text: string } => item !== null,
        );

      return (
        <div className="flex h-6 min-w-56 flex-wrap items-center gap-2.5">
          {transformDoctorDiagnosis.map(({ code, text }, index) => (
            <Tooltip
              key={code + index}
              position="top"
              triggerAsChild={false}
              trigger={<Tag text={code} />}
              content={`${code} ${text}`}
            />
          ))}
        </div>
      );
    },
  },
  {
    id: "additionalMedicalService",
    header: "Świadczenia dodatkowe ICD9",
    cell: ({ row }) => {
      const additionalMedicalService =
        row.original.additionalMedicalService ?? [];

      const transformDoctorDiagnosis = additionalMedicalService.map((value) => {
        const [code, text] = value.split(";");

        return { code, text };
      });

      return (
        <div className="flex h-6 min-w-60 items-center gap-2.5">
          {transformDoctorDiagnosis.map(({ code, text }, index) => (
            <Tooltip
              key={code + index}
              position="top"
              triggerAsChild={false}
              trigger={<Tag text={code} />}
              content={`${code} ${text}`}
            />
          ))}
        </div>
      );
    },
  },
];

import { Tag } from "@jutro/ui";
import { getPrescriptionStatusTagConfig } from "components/PatientDocs/Prescriptions/tools";
import { Prescription } from "components/PatientDocs/Prescriptions/types";

type DrugProps = {
  drug: Prescription["drugs"][number];
};

export const PrescriptionDrug = ({ drug }: DrugProps) => {
  const {
    drug: drugName,
    dose,
    boxAmount,
    cost,
    interval,
    form,
    package: quantity,
    status,
  } = drug;

  return (
    <div key={drugName} className="flex justify-between">
      <div className={`flex flex-col gap-2`}>
        <div className="font-heading-4">{drugName}</div>
        {status && <Tag {...getPrescriptionStatusTagConfig({ status })} />}
        <div className="font-paragraph-2 flex flex-row gap-2">
          {dose ? <div>{dose}</div> : null}

          {boxAmount ? <div>|</div> : null}
          {boxAmount ? <div>{boxAmount} op.</div> : null}

          {form ? <div>|</div> : null}
          {form ? <div>{form}</div> : null}

          {quantity ? <div>|</div> : null}
          {quantity ? <div>{quantity}</div> : null}
        </div>
        {interval ? (
          <div className="font-paragraph-2">Dawkowanie: {interval}</div>
        ) : null}
        <div className="font-paragraph-2">Odpłatność: {cost}</div>
      </div>
    </div>
  );
};

type RawDoctorData = {
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
}[];

export const transformToSelectOptions = (rawDoctorData: RawDoctorData) => {
  return rawDoctorData.map(({ id, firstName, lastName }) => ({
    label: firstName + " " + lastName,
    value: id,
  }));
};

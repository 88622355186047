import ReactSelect from "react-select";
import { useOrderedTests } from "components/PatientDocs/Ordered/hooks/useOrderedTests";
import { ExaminationTypeSelect, PaymentTypeSelect } from "./types";
import {
  filtersCustomStyles,
  orderedTestTypeFilterOptions,
  paymentStatusOptions,
} from "./utils";

type Props = {
  patientId: string;
  visitId?: string;
};

export const Filters = ({ patientId, visitId }: Props) => {
  const {
    examinationTypeFilterValue,
    setExaminationTypeFilterValue,
    paymentStatusFilter,
    setPaymentStatusFilter,
  } = useOrderedTests({ patientId, visitId });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-2">
        <div className="flex flex-col items-start gap-2 2xl:flex-row">
          <div className="flex items-center rounded-lg border-[1px] border-jutro-new-warm-gray-200">
            <span className="font-paragraph-2 cursor-default whitespace-nowrap border-r-[1px] border-jutro-new-warm-gray-200 px-2">
              Typ zlecenia
            </span>
            <ReactSelect
              defaultValue={orderedTestTypeFilterOptions[0]}
              isSearchable={false}
              styles={filtersCustomStyles}
              options={orderedTestTypeFilterOptions}
              value={examinationTypeFilterValue}
              onChange={(option) =>
                setExaminationTypeFilterValue(option as ExaminationTypeSelect)
              }
            />
          </div>

          <div className="flex items-center rounded-lg border-[1px] border-jutro-new-warm-gray-200">
            <span className="font-paragraph-2 cursor-default whitespace-nowrap border-r-[1px] border-jutro-new-warm-gray-200 px-2">
              Status
            </span>
            <ReactSelect
              defaultValue={paymentStatusOptions[0]}
              isSearchable={false}
              styles={filtersCustomStyles}
              options={paymentStatusOptions}
              value={paymentStatusFilter}
              onChange={(option) =>
                setPaymentStatusFilter(option as PaymentTypeSelect)
              }
            />
          </div>
        </div>

        <div className="flex items-center">
          <span
            className={`font-paragraph-2 cursor-pointer whitespace-nowrap hover:text-red-500 ${
              examinationTypeFilterValue.value !== "ALL" ||
              paymentStatusFilter.value !== "ALL"
                ? "text-jutro-new-blue-800"
                : "text-jutro-new-warm-gray-300"
            }`}
            onClick={() => {
              setExaminationTypeFilterValue(orderedTestTypeFilterOptions[0]);
              setPaymentStatusFilter(paymentStatusOptions[0]);
            }}
          >
            wyczyść filtry
          </span>
        </div>
      </div>
    </div>
  );
};

import { AddCircleIcon, BinIcon, Button } from "@jutro/ui";
import { nanoid } from "nanoid";
import { Controller, useFormContext } from "react-hook-form";
import { IpomList } from "components/IpomForm/IpomList";
import { IpomListItem } from "components/IpomForm/IpomList/IpomListItem";
import { IpomFormSchema } from "components/IpomForm/schema";
import { ControlVisitElement } from "./ControlVisitElement";

type Props = {
  disabled?: boolean;
};

export const ControlVisitsSection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();

  return (
    <Controller
      control={control}
      name="controlVisits"
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <div
          className="flex flex-col gap-4 rounded-lg bg-white p-4"
          ref={ref}
          tabIndex={0}
        >
          <div className="flex justify-between">
            <span className="font-label text-jutro-new-warm-gray-800">
              Wizyty kontrolne u lekarza POZ
            </span>

            <Button
              variant="secondary"
              size="condensed"
              icon={<AddCircleIcon />}
              text="Dodaj wizytę"
              type="button"
              full={false}
              disabled={disabled}
              onClick={() => {
                const id = nanoid();
                const defaultControlVisit: IpomFormSchema["controlVisits"][number] =
                  {
                    id,
                    time: "POWYKZLECZADAN",
                    timeUnit: null,
                    timeValue: null,
                  };

                onChange([...value, defaultControlVisit]);
              }}
            />
          </div>

          <IpomList emptyListText="Brak zaplanowanych wizyt" error={error}>
            {value.map((visit) => (
              <IpomListItem
                key={visit.id}
                leftElement={
                  <ControlVisitElement
                    visit={visit}
                    disabled={disabled}
                    onExecutionChange={(visit) => {
                      onChange(
                        value.map((currentItem) =>
                          currentItem.id === visit.id ? visit : currentItem,
                        ),
                      );
                    }}
                  />
                }
                active
                rightElement={
                  <Button
                    variant="negativeGhost"
                    size="condensed"
                    icon={<BinIcon />}
                    label="Usuń"
                    tooltipPosition="top"
                    type="button"
                    full={false}
                    disabled={disabled}
                    onClick={() =>
                      onChange(value.filter(({ id }) => id !== visit.id))
                    }
                  />
                }
              />
            ))}
          </IpomList>
        </div>
      )}
    />
  );
};

import { useRef } from "react";

export const useOnce = (
  effect: () => void,
  options?: {
    when: boolean;
  },
) => {
  const hasStarted = useRef(false);

  const isReadyToRun = options?.when ?? true;

  if (!hasStarted.current && isReadyToRun) {
    effect();
    hasStarted.current = true;
  }
};

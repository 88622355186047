import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { nanoid } from "nanoid";
import { currentUserAtom } from "lib/atoms/auth";
import { usePatientData } from "views/Patient/hooks";
import { useVisitData } from "views/Visit/hooks";

export const getSessionId = () => {
  const sessionId = sessionStorage.getItem("sessionId");

  if (sessionId) return sessionId;

  const newSessionId = nanoid();

  sessionStorage.setItem("sessionId", newSessionId);

  return newSessionId;
};

export type EventName =
  | "SNIPPET_USED"
  | "SNIPPET_CREATED"
  | "SNIPPET_UPDATED"
  | "SNIPPET_DELETED";

export const emitV3Event = async (
  name: EventName,
  userId: string,
  payload?: Record<string, unknown>,
) => {
  try {
    const response = await fetch(
      `${envVars.REACT_APP_EVENT_SERVICE_URL}/add_event`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${envVars.REACT_APP_EVENT_SERVICE_SECRET_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          user_id: userId,
          analytics_id: userId,
          created_at: dayjs().toISOString(),
          params: {
            session_id: getSessionId(),
            env: import.meta.env.REACT_APP_VERCEL_TARGET_ENV ?? "development",
            payload,
          },
        }),
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.error("Error while emitting event:", e);
  }
};

export const useEvent = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const { data: visitData } = useVisitData({ from: "cache-only" });
  const { data: patientData } = usePatientData({ from: "cache-only" });

  return (name: EventName, payload: Record<string, unknown>) => {
    if (!currentUser) return;
    emitV3Event(name, currentUser.id, {
      visit_id: visitData?.id,
      patient_id: patientData?.id,
      ...payload,
    });
  };
};

import { Option } from "@jutro/types";
import { ItemsArea, Select } from "@jutro/ui";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DoctorVisitQuery } from "lib/graphql/megaSchema";
import { getIcd10Option } from "views/Visit/RightPanel/Visit/components/Icd10Select/tools";
import { useSearchIcd10Options } from "views/Visit/RightPanel/Visit/components/Icd10Select/useSearchIcd10Options";

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

type Props = {
  label: string;
  disabled: boolean;
  tags?: Patient["tags"] | undefined;
};

export const CoexistsDiagnosisSelect = ({ label, disabled, tags }: Props) => {
  const { control, watch } = useFormContext<{
    doctorDiagnosis: Visit["doctorDiagnosis"];
  }>();

  const { doctorDiagnosis } = watch();

  const { append, remove } = useFieldArray({
    name: "doctorDiagnosis",
    control,
  });

  const supplementaryDiagnosis = doctorDiagnosis?.filter((e) => {
    if (!e?.code) {
      return false;
    }

    return e.grade === "SUPPLEMENTARY";
  });

  const { searchIcd10Options } = useSearchIcd10Options(
    tags,
    supplementaryDiagnosis,
  );

  const handleSupplementaryDiagnosisChange = (e: Option<string> | null) => {
    if (e === null) {
      return;
    }

    const diagnosis = {
      code: e.value,
      grade: "SUPPLEMENTARY" as const,
    };

    append(diagnosis);
  };

  const value = (supplementaryDiagnosis ?? []).map((diagnosis) =>
    getIcd10Option(diagnosis?.code ?? ""),
  );

  const handleRemove = ({ value }: Option<string>) => {
    const valueFieldIndex = doctorDiagnosis?.findIndex(
      (d) => d?.grade === "SUPPLEMENTARY" && d.code === value,
    );

    if (valueFieldIndex === undefined || valueFieldIndex === -1) {
      return;
    }

    remove(valueFieldIndex);
  };

  const isListDisplayable =
    supplementaryDiagnosis && supplementaryDiagnosis.length > 0;

  return (
    <>
      {isListDisplayable && (
        <ItemsArea
          label="Wybrane rozpoznania współistniejące"
          selectedItems={value}
          disabled={disabled}
          onRemove={handleRemove}
        />
      )}

      <Select
        label={label}
        disabled={disabled}
        onChange={handleSupplementaryDiagnosisChange}
        placeholder="Wpisz nazwę choroby lub kod ICD10"
        noOptionsMessage="Brak rozpoznań do wyboru"
        isSearchable
        isAsync
        loadOptions={searchIcd10Options}
        value={value}
        onRemove={handleRemove}
        clearAfterSelect
      />
    </>
  );
};

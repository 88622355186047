import { outDuplicates } from "@jutro/tools";
import { DoctorGetSlotsQuery } from "lib/graphql/megaSchema";

type Slots = DoctorGetSlotsQuery["doctorGetSlots"];

export const getUniqueDoctors = (slots: Slots) => {
  const doctors = slots.map(({ doctor }) => doctor);

  return doctors.filter(outDuplicates({ by: ["id"] }));
};

import { DiamondCheckIcon } from "@jutro/ui";
import { DoctorMSDeclarationAcceptedEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../NfzEvent";
import { EventHandler } from "../NfzEvent/infer";

export const DeclarationAcceptedEventHandler: EventHandler<
  DoctorMSDeclarationAcceptedEvent
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_CHECK}
      statusText="Zaakceptowana deklaracja"
      color="green"
      icon={<DiamondCheckIcon size="sm" />}
    />
  );
};

import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import { FLAGSMITH_URL } from "lib/hooks/flags";

export const useIdentifyFlagsmith = () => {
  const [currentUser] = useAtom(currentUserAtom);

  useEffect(() => {
    if (!currentUser) return;

    fetch(`${FLAGSMITH_URL}/identities/?identifier=${currentUser.id}`, {
      headers: {
        "x-environment-key": envVars.REACT_APP_FLAGSMITH_CLIENT_ENV_KEY,
      },
    });
  }, [currentUser]);
};

import { Button, NotesIcon } from "@jutro/ui";
import { SnippetCategoryFilter } from "components/Snippet/types";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

export type Props = {
  type: "CHAT" | "DOCS" | "USG";
  category?: SnippetCategoryFilter | undefined;
  disabled?: boolean;
  size: "regular" | "condensed";
};

export const OpenSnippetListButton = ({
  disabled,
  type,
  size,
  category = "ALL",
}: Props) => {
  const { addTile } = useTileSystem();

  return (
    <Button
      type="button"
      label="Użyj szablonu"
      tooltipPosition="top"
      icon={<NotesIcon />}
      disabled={Boolean(disabled)}
      onClick={() => {
        addTile(
          {
            id: "snippet-settings",
            name: "snippet-settings",
            data: { type, category },
          },
          TileColumn.Left,
        );
      }}
      variant="ghost"
      size={size}
      full={false}
    />
  );
};

export const OverTheTopInfo = ({ text }: { text: string }) => (
  <div
    style={{
      position: "fixed",
      zIndex: 1000000,
      top: 48,
      right: "calc(50vw - 36px - 373px)",
      fontSize: 24,
      fontWeight: 900,
      color: "#161541",
      border: "2px solid #161541",
      borderRadius: "8px",
      padding: "2px 48.48px",
      backdropFilter: "blur(3px)",
    }}
  >
    {text}
  </div>
);

import { Defined } from "@jutro/types";
import { AddCircleIcon, Button, MessageBox, PDFIcon } from "@jutro/ui";
import { SetStateAction, useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { Dispatch } from "react";
import { useFormContext } from "react-hook-form";
import { ipomFormDataAtom, ipomFormModeAtom } from "components/IpomForm/atoms";
import { IpomFormSchema } from "components/IpomForm/schema";
import { transformIpomDataToForm } from "components/IpomForm/tools";
import { getIpomDates } from "components/mdx/BannerIpom/tools";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorGetIpomQuery,
  useDoctorGenerateIpomPdfDocumentMutation,
} from "lib/graphql/megaSchema";
import { useGetPatientId } from "lib/hooks/useGetPatientId";
import { useVisitData } from "views/Visit/hooks";

type Props = {
  ipomData: Defined<Defined<DoctorGetIpomQuery>["doctorGetIpom"]>;
  setPdfPreviewUrl: Dispatch<SetStateAction<string>>;
};

export const PreviewModeHeader = ({ ipomData, setPdfPreviewUrl }: Props) => {
  const ipomDate = ipomData.date;
  const [currentUser] = useAtom(currentUserAtom);

  const { setValue, reset } = useFormContext<IpomFormSchema>();
  const { data: visitData } = useVisitData({ from: "cache-only" });
  const patientId = useGetPatientId();

  const isVisitView = Boolean(visitData);
  const isStandardVisit = visitData?.type === "STANDARD";
  const isVisitInProgress = visitData?.status === "INPROGRESS";
  const isUserDoctor = currentUser?.genre === "DOCTOR";

  const { ipomExpiredDate, isIpomExpired, isNewIpomAvailable } =
    getIpomDates(ipomDate);

  const [, setIpomFormData] = useAtom(ipomFormDataAtom);
  const [, setIpomFormMode] = useAtom(ipomFormModeAtom);

  const [generateIpomPdf, { loading }] =
    useDoctorGenerateIpomPdfDocumentMutation();

  const handleCreateIpom = () => {
    reset();
    setIpomFormData(RESET);
    setIpomFormMode("create");
  };

  const handleUpdateIpom = () => {
    const formData = transformIpomDataToForm(ipomData);

    setIpomFormData({
      [visitData!.id]: formData,
    });

    Object.entries(formData).forEach(([key, value]) => {
      setValue(key as keyof typeof formData, value, {
        shouldDirty: false,
      });
    });
    setIpomFormMode("create");
  };

  const handleGenerateIpomPdf = async () => {
    if (!patientId) {
      return;
    }

    const { data } = await generateIpomPdf({
      variables: {
        patientId,
      },
    });

    setPdfPreviewUrl(data?.doctorGenerateIpomPdfDocument.fileUrl ?? "");
  };

  if (isIpomExpired) {
    return (
      <div className="flex flex-col gap-4">
        <MessageBox
          text={`IPOM stracił ważność ${ipomExpiredDate.format("DD.MM.YYYY")}r. Wystaw nowy IPOM, żeby kontynuować opiekę nad Pacjentem w ramach Opieki Koordynowanej`}
          color="rose"
        />

        {isVisitView && (
          <Button
            text="Wystaw nowy IPOM"
            disabled={!isVisitInProgress || !isUserDoctor || !isStandardVisit}
            type="button"
            onClick={handleCreateIpom}
          />
        )}
      </div>
    );
  }

  if (isNewIpomAvailable) {
    return (
      <div className="flex flex-col gap-4">
        <MessageBox text="Pacjentowi przysługuje nowy IPOM" color="orange" />

        <div className="flex gap-1">
          <Button
            variant="secondary"
            icon={<PDFIcon />}
            text="Otwórz PDF"
            type="button"
            loading={loading}
            onClick={handleGenerateIpomPdf}
          />

          {isVisitView && (
            <>
              <Button
                text="Wystaw nowy"
                icon={<AddCircleIcon />}
                disabled={
                  !isVisitInProgress || !isUserDoctor || !isStandardVisit
                }
                type="button"
                onClick={handleCreateIpom}
              />

              <Button
                text="Aktualizuj obecny"
                disabled={!isVisitInProgress || !isUserDoctor}
                type="button"
                onClick={handleUpdateIpom}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <MessageBox
        text={`IPOM jest ważny do ${ipomExpiredDate?.format("DD.MM.YYYY")}r`}
        color="green"
      />

      <div className="flex gap-1">
        <Button
          variant="secondary"
          icon={<PDFIcon />}
          text="Otwórz PDF"
          type="button"
          loading={loading}
          onClick={handleGenerateIpomPdf}
        />

        {isVisitView && (
          <Button
            text="Aktualizuj IPOM"
            disabled={!isVisitInProgress || !isUserDoctor}
            type="button"
            onClick={handleUpdateIpom}
          />
        )}
      </div>
    </div>
  );
};

import { Option } from "@jutro/types";
import { z } from "zod";
import {
  DoctorMSIpomTimeType,
  DoctorMSIpomTimeUnit,
  DoctorMSJmIpomVisitType,
  DoctorMSLevelStratification,
} from "lib/graphql/megaSchema";

const levelStratification: DoctorMSLevelStratification[] = ["S", "P", "Z"];

export const levelStratificationOptions: Option<DoctorMSLevelStratification>[] =
  [
    { label: "Stabilny", value: "S" },
    { label: "Pośredni", value: "P" },
    { label: "Zagrożony niestabilnością", value: "Z" },
  ];

const doctorDiagnosisSchema = z.object({
  value: z.string(),
  name: z.string(),
  active: z.boolean(),
});

const medicinesSchema = z.object({
  ean: z.string(),
  name: z.string(),
  dosage: z.string(),
  form: z.string(),
  quantity: z.string().nullable().optional(),
  time: z.string().nullable().optional(),
  dose: z.string(),
  active: z.boolean(),
});

type DiagnosticTestTime = Extract<
  DoctorMSIpomTimeType,
  "NAJSZYB" | "PRZEDNASTWIZ" | "INTERWAL"
>;

const diagnosticTestTime: DiagnosticTestTime[] = [
  "NAJSZYB",
  "PRZEDNASTWIZ",
  "INTERWAL",
];

export const diagnosticTestTimeOptions: Option<DiagnosticTestTime>[] = [
  { label: "W najbliższym czasie", value: "NAJSZYB" },
  { label: "Przed kolejną wizytą", value: "PRZEDNASTWIZ" },
  { label: "Cyklicznie", value: "INTERWAL" },
];

type TimeUnit = Extract<DoctorMSIpomTimeUnit, "WEEKS" | "MONTHS">;

type Time = { timeUnit: TimeUnit; timeValue: string };

const timeUnit: TimeUnit[] = ["WEEKS", "MONTHS"];

export const diagnosticTestTimeUnitOptions: Option<Time>[] = [
  { label: "tydzień", value: { timeUnit: "WEEKS", timeValue: "1" } },
  { label: "2 tygodnie", value: { timeUnit: "WEEKS", timeValue: "2" } },
  { label: "miesiąc", value: { timeUnit: "MONTHS", timeValue: "1" } },
  { label: "2 miesiące", value: { timeUnit: "MONTHS", timeValue: "2" } },
  { label: "3 miesiące", value: { timeUnit: "MONTHS", timeValue: "3" } },
  { label: "4 miesiące", value: { timeUnit: "MONTHS", timeValue: "4" } },
  { label: "6 miesięcy", value: { timeUnit: "MONTHS", timeValue: "6" } },
];

const scheduledDiagnosticTestsSchema = z.object({
  name: z.string(),
  type: z.string(),
  time: z.enum(diagnosticTestTime as [DiagnosticTestTime]),
  timeUnit: z.enum(timeUnit as [TimeUnit]).nullable(),
  timeValue: z.string().nullable(),
  icd9: z.string().nullable(),
  active: z.boolean(),
});

type ControlVisitTime = Extract<
  DoctorMSIpomTimeType,
  "POWYKZLECZADAN" | "POOKRCZASIE"
>;

const controlVisitTime: ControlVisitTime[] = ["POWYKZLECZADAN", "POOKRCZASIE"];

export const controlVisitTimeOptions: Option<ControlVisitTime>[] = [
  { label: "Po wykonaniu badań", value: "POWYKZLECZADAN" },
  { label: "Po czasie", value: "POOKRCZASIE" },
];

export const controlVisitTimeUnitOptions: Option<Time>[] = [
  ...diagnosticTestTimeUnitOptions,
  { label: "9 miesięcy", value: { timeUnit: "MONTHS", timeValue: "9" } },
];

const controlVisitsSchema = z.object({
  id: z.string(),
  time: z.enum(controlVisitTime as [ControlVisitTime]),
  timeUnit: z.enum(timeUnit as [TimeUnit]).nullable(),
  timeValue: z.string().nullable(),
});

type SpecialistVisit = Extract<
  DoctorMSJmIpomVisitType,
  "ALERGOLOG" | "PULMONOLOG"
>;

const specialistVisitType: SpecialistVisit[] = ["ALERGOLOG", "PULMONOLOG"];

export const ipomFormSchema = z
  .object({
    levelStratification: z.enum(
      levelStratification as [DoctorMSLevelStratification],
    ),
    observations: z
      .array(doctorDiagnosisSchema)
      .min(1, "To pole nie może być puste")
      .refine(
        (fields) => fields.some((field) => field.active),
        "To pole nie może być puste",
      ),
    medicines: z
      .array(medicinesSchema)
      .min(1, "To pole nie może być puste")
      .refine(
        (fields) => fields.some((field) => field.active),
        "To pole nie może być puste",
      ),
    diet: z.number(),
    nurse: z.number(),
    scheduledDiagnosticTests: z.array(scheduledDiagnosticTestsSchema),
    summary: z.string().min(1, "To pole nie może być puste"),
    controlVisits: z.array(controlVisitsSchema),
    specialistVisits: z.array(z.enum(specialistVisitType as [SpecialistVisit])),
  })
  .refine(
    ({ controlVisits, scheduledDiagnosticTests }) =>
      !controlVisits.some(({ time }) => time === "POWYKZLECZADAN") ||
      scheduledDiagnosticTests.filter(({ active }) => active).length > 0,
    { message: "Pacjent nie ma zleconych badań.", path: ["controlVisits"] },
  )
  .refine(
    ({ controlVisits, scheduledDiagnosticTests }) =>
      scheduledDiagnosticTests.some((test) => test.active)
        ? controlVisits.length > 0
        : true,
    {
      message:
        "Zaplanuj wizyty, żeby omówić z pacjentem wyniki zleconych badań",
      path: ["controlVisits"],
    },
  )
  .refine(({ controlVisits }) => controlVisits.length > 0, {
    message: "To pole nie może być puste",
    path: ["controlVisits"],
  });

export type IpomFormSchema = z.infer<typeof ipomFormSchema>;

export const defaultIpomFormValues: IpomFormSchema = {
  levelStratification: levelStratificationOptions[0].value,
  observations: [],
  medicines: [],
  diet: 3,
  nurse: 6,
  scheduledDiagnosticTests: [],
  summary: "",
  controlVisits: [],
  specialistVisits: [],
};

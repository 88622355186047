import { BinIcon, EyeOffIcon, EyeOnIcon, OcrIcon, RotateIcon } from "@jutro/ui";
import { useEffect, useRef, useState } from "react";
import DefaultImage from "assets/default-imag.svg?react";
import { ImageCropBlock as ImageCrop } from "components/components/Doctor/ImageCrop";
import { useDeleteMessageMutation } from "components/new/Chat/hooks";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { useDoctorGetContentFromImageMutation } from "lib/graphql/megaSchema";
import { roundCoordinates, scaleCoordinates } from "lib/tools/ocr";
import { toaster } from "lib/tools/toaster";
import { CropImage } from "lib/types";
import {
  AuthorImage,
  AuthorSection,
  MessageContainer,
  MessageFooter,
} from "./components";

type DoctorImageMessageProps = {
  id?: string;
  mine: boolean;
  authorImage: string;
  loader?: boolean;
  image: string;
  messageTime: string;
  authorName: string;
  onClickStandard: boolean;
  footerContent?: string;
  footerBackground?: string;
  optionalTextMessage?: string | null;
};

// Image message in doctor app
export const DoctorImageMessage = ({
  id,
  mine,
  authorImage,
  loader,
  image,
  messageTime,
  authorName,
  onClickStandard,
  footerBackground,
  optionalTextMessage,
}: DoctorImageMessageProps) => {
  const [show, setShow] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageRotation, setIconRotation] = useState(90);
  const [imageHeight, setImageHeight] = useState("auto");
  const [loading, setLoading] = useState(false);
  const [imageVisible, setImageVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [removing, setRemoving] = useState(false);

  const { removeMessage } = useDeleteMessageMutation();

  // Crop
  const [openDialog, setOpenDialog] = useState(false);
  const [crop, setCrop] = useState<CropImage>({
    unit: "px",
    width: 80,
    height: 50,
    x: 30,
    y: 30,
  });

  // Fromat coordinates from crop

  useEffect(() => {
    if (!imageRef.current) {
      return;
    }

    if (imageRef.current.height > 0) {
      if (imageRotation === 90 || imageRotation === 270) {
        setImageHeight("auto");
      } else {
        setImageHeight(`${imageRef.current.width}px`);
      }
    }
  }, [imageRotation]);
  const imageSrcModified =
    imageRotation === 90
      ? image
      : `https://imgproxy.jutromedical.com/any/rotate:${
          (imageRotation + 270) % 360
        }/${btoa(encodeURI(image))}`;

  const [getContentFromImageMutation] = useDoctorGetContentFromImageMutation();
  const imageSplit = image.split(".");
  const isHeic = imageSplit[imageSplit.length - 1] === "HEIC";

  // Save translation to clipboard
  const handleOCR = async () => {
    const scaledCoordinates = scaleCoordinates(crop, imageRef);
    const roundedCoordinates = roundCoordinates(scaledCoordinates);
    const imageId = new URL(imageSrcModified).searchParams.get("id") ?? "";
    const response = await getContentFromImageMutation({
      variables: { input: { imageId, ...roundedCoordinates } },
    });

    navigator.clipboard.writeText(
      response?.data?.doctorGetContentFromImage || "",
    );
    return response?.data?.doctorGetContentFromImage;
  };

  return (
    <>
      <MessageContainer data-hj-suppress mine={mine}>
        <AuthorSection mine={mine}>
          <AuthorImage src={authorImage} />
          {authorName}
        </AuthorSection>
        {optionalTextMessage && (
          <div className="my-2">{optionalTextMessage}</div>
        )}
        {imageVisible ? (
          <div
            className={`flex flex-col overflow-hidden rounded-lg p-3 ${
              mine
                ? "gap-2 bg-jutro-new-blue-500"
                : "gap-0 bg-jutro-new-warm-gray-50"
            } ${loader && "w-20"}`}
          >
            <div
              className="relative w-full overflow-hidden"
              style={{
                height: imageHeight,
              }}
            >
              {!show && (
                <div className="absolute inset-0 z-[3] flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-tl-[17px] bg-jutro-new-warm-gray-950/50 p-[45px] text-white">
                  <EyeOffIcon size="lg" />
                  <div className="mb-2 text-center text-[13px] font-bold">
                    Pacjent przesłał zdjęcie
                  </div>
                  <div className="mb-2 text-center text-[13px]">
                    Zadbaj o to by nikt nieupoważniony nie mógł zobaczyć
                    przesłanego zdjęcia.
                  </div>
                </div>
              )}

              <img
                className={`block h-full w-full rounded-lg object-contain ${
                  !show && "blur-[1.2rem]"
                } ${!mine && "rounded-bl-none rounded-br-none"} ${
                  imageRotation === 270 && "rounded-tl-none rounded-tr-none"
                }`}
                ref={imageRef}
                src={imageSrcModified}
                onClick={() => {
                  if (!onClickStandard) return;
                  const newTab = window.open();
                  setTimeout(() => {
                    const img = document.createElement("img");
                    img.src = imageSrcModified;
                    newTab?.document.body.appendChild(img);
                  }, 500);
                  return false;
                }}
                onError={() => setImageVisible(false)}
              />
            </div>

            <MessageFooter footerBackground={footerBackground} mine={mine}>
              <div>
                <div
                  className={`text-xs text-white ${
                    mine ? "text-left" : "text-right"
                  }`}
                >
                  {messageTime}
                </div>
              </div>

              <div className="flex gap-4 text-white">
                <div
                  className="h-4 w-4 cursor-pointer hover:opacity-80"
                  onClick={() => setOpenDialog(true)}
                >
                  <OcrIcon size="sm" />
                </div>

                <div
                  className="h-4 w-4 cursor-pointer hover:opacity-80"
                  onClick={() => {
                    setIconRotation((prev) => (prev % 360) + 90);
                  }}
                >
                  <RotateIcon size="sm" />
                </div>

                <div
                  className="z-10 h-4 w-4 cursor-pointer hover:opacity-80"
                  onClick={() => setShow(!show)}
                >
                  {show ? <EyeOffIcon size="sm" /> : <EyeOnIcon size="sm" />}
                </div>

                {mine && (
                  <div
                    className="h-4 w-4 cursor-pointer hover:opacity-80"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (removing) return;

                      if (!id) {
                        toaster.notify(
                          "Zaczekaj aż wiadomość zostanie wysłana!",
                        );
                        return;
                      }
                      setOpen(true);
                    }}
                  >
                    <BinIcon size="sm" />
                  </div>
                )}
              </div>
            </MessageFooter>
          </div>
        ) : (
          <div className="h-full w-full rounded-lg border-0 bg-jutro-new-blue-500 p-4">
            <div className="grid justify-items-center">
              <DefaultImage className="w-1/4 stroke-current py-4 text-white" />
              <p className="font-paragraph-2 whitespace-pre-line pt-2 text-center text-white">
                {isHeic
                  ? "Obecnie nie obsługujemy zdjęć w formacie .heic.\n Poproś pacjenta, żeby zmienił format na .jpg bądż .png albo żeby przesłał je na kontakt@jutromedical.com."
                  : "Wystąpił błąd. Poproś pacjenta, żeby przesłał zdjęcie na kontakt@jutromedical.com"}
              </p>
            </div>
          </div>
        )}
      </MessageContainer>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="Kopiuj tekst ze zdjęcia"
        onConfirm={async () => {
          setLoading(true);
          const ocrValue = await handleOCR();
          setLoading(false);
          return ocrValue
            ? toaster.notify("Zapisano do schowka")
            : toaster.warning("Nie udało się rozpoznać tekstu!");
        }}
        loading={loading}
        confirmText="Kopiuj"
        submitVariant="primary"
      >
        <ImageCrop
          imageUrl={imageSrcModified}
          crop={crop}
          setCrop={setCrop}
          dimensions={{
            width: imageRef.current?.width || 0,
            height: imageRef.current?.height || 0,
          }}
        />
      </ConfirmDialog>
      <ConfirmDialog
        open={open}
        title="Usuwanie wiadomości"
        text="Czy na pewno chcesz usunąć wiadomość?"
        onConfirm={async () => {
          if (!id) {
            return;
          }

          try {
            setRemoving(true);
            toaster.notify("Usuwam wiadomość!");
            await removeMessage(id);
            toaster.success("Wiadomość usunięta!");
          } catch (e) {
            toaster.error("Nie udało się usunąć wiadomości");
          } finally {
            setRemoving(false);
          }
        }}
        setOpen={setOpen}
      />
    </>
  );
};

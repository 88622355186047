import { BinIcon, Button, PencilIcon } from "@jutro/ui";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { Dispatch, SetStateAction } from "react";
import { typeMap } from "components/ExaminationDrawer/utils";
import { imagePreviewAtom } from "lib/atoms";
import { fileConfig } from "lib/tools/httpConfigs";
import { Maybe, PatientVitality } from "lib/types";
import { fileParser } from "./utils";

type Props = {
  examination: PatientVitality;
  setExamination: Dispatch<SetStateAction<Maybe<PatientVitality>>>;
  setEditVitalModeOpen: Dispatch<SetStateAction<boolean>>;
  setDeleteVitalModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const SpecificExamination = ({
  examination,
  setExamination,
  setEditVitalModeOpen,
  setDeleteVitalModalOpen,
}: Props) => {
  const [, setImagePreview] = useAtom(imagePreviewAtom);

  const parsedFiles = fileParser(examination);

  const renderExaminationSort = () => {
    if (examination.type === "OTHER") return examination.customName;
    if (examination.type === "ANALYSIS") return examination.analysisType;
    return typeMap[examination.type as keyof typeof typeMap];
  };

  return (
    <div className="font-paragraph-2 flex flex-col space-y-2 p-4 text-jutro-new-warm-gray-800">
      <div className="flex justify-between">
        <div className="flex items-center align-middle">
          <div className="max-w-md break-words">
            <span className="font-semibold">Rodzaj badania: </span>
            {renderExaminationSort()}
          </div>
        </div>
        <div className="flex gap-x-2">
          <Button
            full={false}
            id="edit-side-sheet-button"
            onClick={() => {
              setExamination(examination);
              setEditVitalModeOpen(true);
            }}
            icon={<PencilIcon />}
            label="Edytuj"
            tooltipPosition="top"
            variant="ghost"
            size="condensed"
          />

          <Button
            full={false}
            id="remove-side-sheet-button"
            onClick={() => {
              setExamination(examination);
              setDeleteVitalModalOpen(true);
            }}
            icon={<BinIcon />}
            label="Usuń"
            tooltipPosition="top"
            size="condensed"
            variant="negativeGhost"
          />
        </div>
      </div>
      <div>
        <span className="font-semibold">Data badania: </span>
        {examination.type === "OTHER"
          ? dayjs(examination.customDate.iso).format("DD MMMM YYYY HH:mm")
          : "brak"}
      </div>
      {examination.type === "OTHER" && examination.customComment && (
        <div className="break-words">
          <span className="font-semibold">Komentarz: </span>
          {examination.customComment}
        </div>
      )}
      <span className="font-semibold">Zdjęcia i pliki dodane do badania:</span>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          {parsedFiles.files.map((file) => (
            <div key={file}>
              -{" "}
              <a
                key={file}
                href={`${fileConfig[envVars.REACT_APP_CONFIG]}${file}`}
                download={file}
              >
                {file.substring(36)}
              </a>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap gap-2">
          {parsedFiles.images.map((file) => (
            <img
              onClick={() =>
                setImagePreview(
                  `${fileConfig[envVars.REACT_APP_CONFIG]}${file}`,
                )
              }
              className="transform-opacity max-h-40 cursor-pointer duration-200 ease-in-out hover:opacity-80"
              src={`${fileConfig[envVars.REACT_APP_CONFIG]}${file}`}
              alt="file-img"
              key={file}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

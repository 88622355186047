import { PropsWithChildren } from "react";
import { Scrollbars } from "react-custom-scrollbars";

export type BaseScrollbarProps = {
  background?: string;
};
export type ScrollbarProps = PropsWithChildren<BaseScrollbarProps>;

export const Scrollbar = ({
  children,
  background = "transparent",
}: ScrollbarProps) => (
  // @ts-ignore
  <Scrollbars
    style={{
      width: "100%",
      padding: 10,
      background,
      borderRadius: "8px",
    }}
    autoHide
  >
    {children}
  </Scrollbars>
);

import { determineBirthday } from "@jutro/tools";
import { Input, Toggle } from "@jutro/ui";
import DateTimePicker from "react-datetime-picker";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";
import { getUserGender } from "components/PatientDocs/tools";
import { InfoBox } from "components/new/InfoBox";
import { SelectWrapper } from "components/new/SelectWrapper";
import { DoctorPatientGuardiansFragment } from "lib/graphql/megaSchema";
import { GenderSelect } from "views/Patients/components/GenderSelect";

type Props = {
  associatedAccount: DoctorPatientGuardiansFragment;
  header: string;
  guardian: boolean;
};

export const AssociatedAccount = ({
  associatedAccount,
  header,
  guardian,
}: Props) => {
  const navigate = useNavigate();

  const handleProfileNavigate = () => {
    navigate(`/patients/${associatedAccount.id}/profile`);
  };

  return (
    <div className="rounded-lg bg-white">
      <div className="font-paragraph-2 flex flex-col gap-4 p-4 text-jutro-new-warm-gray-800">
        <div>
          <h3 className="font-semibold">{header}</h3>
        </div>

        <div className="flex gap-4">
          <div className="w-full">
            <Input
              label="Imię"
              value={associatedAccount.firstName ?? ""}
              disabled
            />
          </div>
          <div className="w-full">
            <Input
              label="Nazwisko"
              value={associatedAccount.lastName ?? ""}
              disabled
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="w-[calc(50%-8px)]">
            <Input
              label="Numer PESEL"
              maxLength={11}
              type="number"
              value={
                associatedAccount.pesel?.length === 12
                  ? ""
                  : (associatedAccount.pesel ?? "")
              }
              disabled
            />
          </div>

          <Toggle
            label="Brak nadanego numeru PESEL"
            disabled
            isEnabled={associatedAccount.pesel?.length === 12}
            setIsEnabled={() => null}
          />
        </div>

        <div className="flex w-full gap-4">
          <SelectWrapper label="Data urodzenia">
            <DateTimePicker
              format="yyyy-MM-dd"
              disableClock
              clearIcon={null}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="RRRR"
              calendarIcon={false}
              disabled
              value={determineBirthday(associatedAccount.pesel ?? "")}
              className="slots-creator-date-time-picker font-paragraph-1 h-[42px] w-full rounded-lg !border-[1px] !border-solid !border-jutro-new-warm-gray-300 px-[10px] py-[6px] text-jutro-new-warm-gray-800 focus-within:border-jutro-new-blue-800 hover:border-jutro-new-blue-800"
            />
          </SelectWrapper>

          <SelectWrapper label="Płeć">
            <GenderSelect
              disabled
              pickedGender={getUserGender(associatedAccount.pesel)}
            />
          </SelectWrapper>
        </div>

        <div className="flex gap-4">
          <SelectWrapper label="Adres zamieszkania">
            <AsyncSelect
              value={{
                value: null,
                label: `${associatedAccount.address?.city}, ${associatedAccount.address?.street} ${associatedAccount.address?.houseNumber}, ${associatedAccount.address?.zipCode}`,
              }}
              isDisabled
              styles={adjustedToInputSelectStyles<string | unknown>()}
            />
          </SelectWrapper>

          <Input
            label="Numer mieszkania"
            value={associatedAccount.address?.flatNumber ?? ""}
            placeholder="np. 12"
            disabled
          />
        </div>

        <div className="flex gap-4">
          <div className="w-full">
            <Input
              label="Numer telefonu"
              maxLength={9}
              disabled
              type="number"
              value={guardian ? associatedAccount.phone : "000000000"}
            />
          </div>
          <div className="w-full">
            <Input
              label="Adres e-mail"
              placeholder="np. jan.kowalski@poczta.pl"
              disabled
              value={associatedAccount.email ?? ""}
            />
          </div>
        </div>

        <InfoBox
          infos={[
            <>
              W celu edycji danych
              <span className="font-semibold">
                {guardian ? " konta opiekuna " : " przypisanego konta "}
              </span>
              przejdź do profilu klikając&nbsp;
              <span
                className="cursor-pointer font-semibold text-jutro-new-blue-800"
                onClick={handleProfileNavigate}
              >
                tutaj
              </span>
            </>,
          ]}
        />
      </div>
    </div>
  );
};

import dayjs from "dayjs";
import { Maybe } from "lib/types";
import {
  DrugDetails as RegularDrug,
  SourceTag,
  StatusColor,
} from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/types";
import { RecentDrug } from "views/Visit/RightPanel/NewPrescription/types";

export const getStatusInfo = (
  dayDiff: number | null,
): { status: string; info: string } => {
  if (dayDiff === null) {
    return {
      status: "",
      info: "Brak informacji o dacie ważności",
    };
  }

  if (dayDiff < 0) {
    return {
      status: "Nieaktualne",
      info: `od ${dayDiff * -1} dni`,
    };
  }

  if (dayDiff === 0) {
    return {
      status: "Aktualne",
      info: "do dzisiaj",
    };
  }

  if (dayDiff <= 7) {
    return {
      status: "Uwaga",
      info: `jeszcze ${dayDiff} dni ważności`,
    };
  }

  return {
    status: "Aktualne",
    info: `jeszcze ${dayDiff} dni`,
  };
};

export const getDayDiff = (expirationAt: Maybe<Date>): number | null => {
  if (!expirationAt) {
    return null;
  }

  return dayjs(expirationAt).diff(dayjs(), "day");
};

export const getColor = (
  dayDiff: ReturnType<typeof getDayDiff>,
  confirmationType: SourceTag["confirmationType"],
  type: SourceTag["type"],
): StatusColor => {
  if (type === "REGULAR_DRUG") {
    if (!dayDiff) {
      return "warning";
    }

    if (dayDiff > 7) {
      return "success";
    }

    return "warning";
  }

  if (type === "CHRONIC_DISEASE") {
    return "success";
  }

  return "warning";
};

export const isDrugRegularOrOrdered = (
  e: RegularDrug | RecentDrug,
): e is RegularDrug => {
  if (!e) {
    return false;
  }

  return "name" in e;
};

import { useAtom } from "jotai";
import { Dispatch, SetStateAction } from "react";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { selectedVisitTabAtom } from "lib/atoms";
import {
  DoctorVisitDocument,
  useDoctorUpdateVisitMutation,
} from "lib/graphql/megaSchema";
import { useVisitData } from "views/Visit/hooks";

type Props = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const MedicalServiceConfirmModal = ({ isOpen, setOpen }: Props) => {
  const { data: visitData } = useVisitData({ from: "cache-only" });

  const [updateVisitMutation] = useDoctorUpdateVisitMutation({
    refetchQueries: [DoctorVisitDocument],
  });
  const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);

  const onConfirm = async () => {
    if (!visitData?.id) {
      return;
    }

    await updateVisitMutation({
      variables: {
        id: visitData.id,
        input: {
          primaryMedicalService: "COMPLEX",
        },
      },
    });

    setSelectedVisitTab("medicalServices");
  };

  return (
    <ConfirmDialog
      title="Poprawnie wystawiono IPOM"
      open={isOpen}
      onConfirm={onConfirm}
      setOpen={setOpen}
      submitVariant="primary"
      confirmText="Kontynuuj"
      width="xxl"
    >
      <div className="font-label flex flex-col gap-4">
        <span>
          Pacjent został włączony w program Opieki Koordynowanej. Wizyta powinna
          zostać zaraportowana jako <br />
          <span className="font-semibold">
            porada kompleksowa w opiece koordynowanej.
          </span>
        </span>

        <span>Kontynuuj aby wybrać świadczenie.</span>
      </div>
    </ConfirmDialog>
  );
};

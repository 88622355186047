import { Tag, Tooltip } from "@jutro/ui";
import { DoctorUpcomingVisitsQuery } from "lib/graphql/megaSchema";
import { procedureTypeOptions } from "lib/tools/procedureTitleMap";

type Props = {
  visit: DoctorUpcomingVisitsQuery["doctorUpcomingVisits"][0];
};

export const VisitTags = ({ visit }: Props) => {
  if (visit.type === "PROCEDURE") {
    return (
      <div className="flex items-center gap-2">
        <div>Procedury:</div>

        <div className="flex gap-1">
          {visit.procedures.length === 0
            ? "Brak"
            : visit.procedures.map((procedure, index) => {
                const procedureTypeLabel = procedureTypeOptions.find(
                  (p) => p.value === procedure.type,
                )?.label;

                return (
                  <Tag
                    key={`${procedure.id}-${index}`}
                    text={procedureTypeLabel ?? "Nieznana procedura"}
                    color="teal"
                  />
                );
              })}
        </div>
      </div>
    );
  }

  const sortedDoctorDiagnosis = visit.doctorDiagnosis?.sort((a, b) => {
    if (a?.grade === "MAIN") return -1;
    if (b?.grade === "MAIN") return 1;
    return 0;
  });

  return (
    <div className="flex flex-wrap gap-2 gap-y-1">
      <div>Rozpoznania:</div>

      {sortedDoctorDiagnosis?.map((diagnosis, index) => (
        <Tooltip
          key={`${diagnosis?.code}-${index}`}
          triggerAsChild={false}
          trigger={
            <Tag
              text={diagnosis?.code?.split(" ")[0] ?? ""}
              color="teal"
              variant={diagnosis?.grade === "MAIN" ? "inverted" : "default"}
            />
          }
          content={diagnosis?.code}
        />
      ))}
    </div>
  );
};

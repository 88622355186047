import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CloseIcon } from "@jutro/ui";
import { PropsWithChildren, useEffect, useState } from "react";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { z } from "zod";
import { Checkbox } from "components/new";
import {
  DoctorGetPatientDocument,
  DoctorNfzHistoryDocument,
  DoctorPatientInsuranceStatusDocument,
  DoctorPatientProfileVisitDocument,
  useDoctorGetPatientsLazyQuery,
  useDoctorSaveHealthInsuranceStatementMutation,
} from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";
import { toaster } from "lib/tools/toaster";
import { StatementType } from "./types";
import {
  groupedRepresentativeOptions,
  groupedSelfOptions,
  statementTypeOptions,
} from "./utils";

const schema = z
  .object({
    statementType: z.nativeEnum(StatementType),
    data: z.object({
      insuranceStatement: z.object({
        patientStatement: z
          .object({
            reason: z.union([
              z.literal("ART_2_UST_1_PKT_4_LIT_A"),
              z.literal("ART_2_UST_1_PKT_4_LIT_B"),
              z.literal("ART_54_UST_1"),
              z.literal("ART_67_UST_4_7"),
              z.literal(
                "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
              ),
              z.literal("UA"),
            ]),
          })
          .nullish(),
        representativeStatement: z
          .object({
            reason: z.union([
              z.literal("ART_2_UST_1_PKT_3_LIT_A"),
              z.literal("ART_2_UST_1_PKT_3_LIT_B"),
              z.literal("ART_2_UST_1_PKT_3_LIT_C"),
              z.literal("ART_2_UST_1_PKT_4_LIT_A"),
              z.literal("ART_2_UST_1_PKT_4_LIT_B"),
              z.literal("ART_54_UST_1"),
              z.literal("ART_67_UST_4_7"),
              z.literal(
                "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
              ),
              z.literal("UA"),
            ]),
            representativePatientId: z.string(),
          })
          .nullish(),
      }),
    }),
    organizationId: z
      .union([
        z.literal("PATIENT"),
        z.literal("PB"),
        z.literal("PC"),
        z.literal("PD"),
        z.literal("PE"),
        z.literal("PF"),
        z.literal("PG"),
        z.literal("PK"),
        z.literal("PL"),
        z.literal("PM"),
        z.literal("PN"),
        z.literal("PO"),
        z.literal("PP"),
        z.literal("PR"),
        z.literal("PS"),
        z.literal("PT"),
        z.literal("PZ"),
      ])
      .nullable()
      .refine((organizationId) => organizationId, {
        message: "To pole jest wymagane",
        path: ["organizationId"],
      }),
  })
  .refine(
    (val) =>
      val.statementType === StatementType.SELF
        ? val.data.insuranceStatement.patientStatement?.reason
        : true,
    {
      message: "To pole jest wymagane",
      path: ["data.insuranceStatement.patientStatement.reason"],
    },
  )
  .refine(
    (val) =>
      val.statementType === StatementType.REPRESENTATIVE
        ? val.data.insuranceStatement.representativeStatement?.reason
        : true,
    {
      message: "To pole jest wymagane",
      path: ["data.insuranceStatement.representativeStatement.reason"],
    },
  );

type SchemaType = z.infer<typeof schema>;

const defaultValues: DefaultValues<SchemaType> = {
  statementType: StatementType.SELF,
  data: {
    insuranceStatement: {
      patientStatement: {
        reason: "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
      },
    },
  },
};

export const EwusStatement = () => {
  const { patientId } = useParams<{ patientId: string }>();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid },
  } = useForm<SchemaType>({
    defaultValues,
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const [getPatients] = useDoctorGetPatientsLazyQuery();

  const loadPatientOptions = async (name: string) => {
    const { data } = await getPatients({
      variables: { fullText: name, take: 50 },
    });

    if (!data || data.doctorPatients.length === 0) {
      return [];
    }

    return data.doctorPatients.map((patient) => {
      const { id, firstName, lastName, pesel, patientCardNumber, phone } =
        patient;
      return {
        value: id,
        label: `${getDisplayDoctor(
          firstName,
          lastName,
          "Brak danych",
        )} / ${patientCardNumber} / ${phone} / ${pesel}`,
      };
    });
  };

  const statementType = watch("statementType");

  const [isStatementSigned, setIsStatementSigned] = useState(false);

  const { removeTile } = useTileSystem();

  const [
    saveHealthInsuranceStatementMutation,
    { loading: isSavingHealthInsurance },
  ] = useDoctorSaveHealthInsuranceStatementMutation({
    refetchQueries: [
      DoctorGetPatientDocument,
      DoctorPatientProfileVisitDocument,
      DoctorNfzHistoryDocument,
      DoctorPatientInsuranceStatusDocument,
    ],
    awaitRefetchQueries: true,
  });

  const { vojevodshipsWithCities } = useVojevodshipOptions();

  const onSubmit = handleSubmit((data) => {
    if (!data.organizationId || !patientId) {
      return;
    }

    saveHealthInsuranceStatementMutation({
      variables: { ...data, patientId, organizationId: data.organizationId },
    }).catch(() => {
      toaster.error("Wystąpił błąd");
    });
  });

  useEffect(() => {
    setIsStatementSigned(false);
    if (statementType === StatementType.SELF) {
      reset(defaultValues);
      return;
    }

    reset({ statementType });
  }, [statementType]);

  return (
    <form onSubmit={onSubmit} className="flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <div className="font-display-3">Oświadczenie pacjenta</div>
        <Button
          full={false}
          variant="ghost"
          size="condensed"
          icon={<CloseIcon />}
          label="Zamknij"
          tooltipPosition="bottom"
          onClick={() => removeTile("patient-ewus-statement")}
        />
      </div>

      <div className="flex w-full flex-col justify-center space-y-6">
        <Controller
          name="statementType"
          control={control}
          render={({ field: { value: fieldValue, onChange, ...rest } }) => {
            const currentOption = statementTypeOptions.find(
              ({ value }) => value === fieldValue,
            );

            return (
              <InputWrapper header="Rodzaj oświadczenia" hasLink>
                <Select
                  className="w-full"
                  value={currentOption ? currentOption : null}
                  placeholder="Wybierz rodzaj oświadczenia z listy"
                  options={statementTypeOptions}
                  onChange={(option) =>
                    onChange(option?.value ?? StatementType.SELF)
                  }
                  {...rest}
                />
              </InputWrapper>
            );
          }}
        />
        {statementType === StatementType.REPRESENTATIVE && (
          <Controller
            name="data.insuranceStatement.representativeStatement.representativePatientId"
            control={control}
            render={({ field: { value: fieldValue, onChange, ...rest } }) => {
              return (
                <InputWrapper
                  header={
                    statementTypeOptions.find(
                      (option) => option.value === StatementType.REPRESENTATIVE,
                    )?.label ?? ""
                  }
                >
                  <AsyncSelect
                    className="w-full"
                    placeholder="Wpisz nazwisko / numer id / telefon / PESEL"
                    noOptionsMessage={() => "Brak pacjentów"}
                    loadOptions={loadPatientOptions}
                    onChange={(option) => onChange(option?.value ?? "")}
                    {...rest}
                  />
                </InputWrapper>
              );
            }}
          />
        )}

        {statementType === StatementType.SELF && (
          <Controller
            name={"data.insuranceStatement.patientStatement.reason"}
            control={control}
            render={({ field: { value: fieldValue, onChange, ...rest } }) => {
              const [polishGroup, ukrainianGroup] = groupedSelfOptions;

              const currentOption = [
                ...polishGroup.options,
                ...ukrainianGroup.options,
              ].find(({ value }) => value === fieldValue);

              return (
                <InputWrapper header="Podstawa prawna">
                  <Select
                    className="w-full"
                    placeholder="Wybierz podstawę prawną z listy"
                    value={currentOption}
                    options={groupedSelfOptions}
                    onChange={(option) =>
                      onChange(
                        option?.value ??
                          "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
                      )
                    }
                    {...rest}
                  />
                </InputWrapper>
              );
            }}
          />
        )}

        {statementType === StatementType.REPRESENTATIVE && (
          <Controller
            name={"data.insuranceStatement.representativeStatement.reason"}
            control={control}
            render={({ field: { value: fieldValue, onChange, ...rest } }) => {
              const [polishGroup, ukrainianGroup] =
                groupedRepresentativeOptions;

              const currentOption = [
                ...polishGroup.options,
                ...ukrainianGroup.options,
              ].find(({ value }) => value === fieldValue);
              return (
                <InputWrapper header="Podstawa prawna">
                  <Select
                    className="w-full"
                    value={currentOption}
                    placeholder="Wybierz podstawę prawną z listy"
                    options={groupedRepresentativeOptions}
                    onChange={(option) =>
                      onChange(
                        option?.value ??
                          "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
                      )
                    }
                    {...rest}
                  />
                </InputWrapper>
              );
            }}
          />
        )}

        <Controller
          name="organizationId"
          control={control}
          render={({ field: { value: fieldValue, onChange, ...rest } }) => {
            const currentOption = vojevodshipsWithCities.find(
              ({ value }) => value === fieldValue,
            );

            return (
              <InputWrapper header="Województwo">
                <Select
                  className="w-full"
                  value={currentOption ? currentOption : null}
                  placeholder="Wybierz województwo z listy"
                  options={vojevodshipsWithCities}
                  onChange={(option) => onChange(option?.value ?? "PM")}
                  {...rest}
                />
              </InputWrapper>
            );
          }}
        />
        <Checkbox
          label="Oświadczenie zostało podpisane"
          value={isStatementSigned}
          onChange={() => setIsStatementSigned((prev) => !prev)}
        />

        <div className="flex justify-center">
          <Button
            full={false}
            text="Zapisz oświadczenie"
            disabled={!isValid || !isStatementSigned}
            loading={isSavingHealthInsurance}
            type="submit"
          />
        </div>

        <span className="font-geologica font-semibold text-jutro-new-rose-500">
          Ważne: Pamiętaj by zeskanować oświadczenie i dodać je do modułu
          Informacje dodatkowe w profilu.
        </span>
      </div>
    </form>
  );
};

type InputWrapperProps = {
  header: string;
  hasLink?: boolean;
};

export const InputWrapper = ({
  header,
  hasLink = false,
  children,
}: PropsWithChildren<InputWrapperProps>) => {
  return (
    <div className="flex flex-col gap-2">
      <header className="font-paragraph-2 flex items-center justify-between text-jutro-new-warm-gray-900">
        <div>{header}</div>
        {hasLink && (
          <a
            href="https://drive.google.com/drive/u/1/folders/16NScdXThQgsZ0CXVslK8ncdDVKKgQ1oo"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Zobacz wzory
          </a>
        )}
      </header>
      {children}
    </div>
  );
};

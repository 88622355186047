import data from "@emoji-mart/data/sets/14/native.json";

const isEmoji = (text: string): text is keyof typeof data.emojis => {
  return text in data.emojis;
};

export const handleEmoji = (content: string) => {
  const emojiShortNameRegex = /:\w*?:/g;

  return content.replaceAll(emojiShortNameRegex, (emojiShortName) => {
    const parsedEmojiShortName = emojiShortName.slice(
      1,
      emojiShortName.length - 1
    );

    return isEmoji(parsedEmojiShortName)
      ? data.emojis[parsedEmojiShortName].skins[0].native
      : "";
  });
};

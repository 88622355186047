import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  DoctorDraftVisitsDocument,
  DoctorGetPatientDocument,
  DoctorGetPatientsDocument,
  DoctorMSVisitInput,
  DoctorPatientProfileVisitDocument,
  useDoctorDeletePatientMutation,
  useDoctorGetPatientsLazyQuery,
  useDoctorUpdateVisitMutation,
} from "lib/graphql/megaSchema";

export const usePatients = () => {
  const [searchText, setSearchText] = useState("");
  const [getData, { data, error, loading, networkStatus }] =
    useDoctorGetPatientsLazyQuery({ notifyOnNetworkStatusChange: true });

  if (error) console.error("error fetching patients", error);

  const [updateVisitMutation] = useDoctorUpdateVisitMutation({
    refetchQueries: [
      DoctorPatientProfileVisitDocument,
      DoctorGetPatientDocument,
      DoctorDraftVisitsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const updateVisit = async (
    id: string,
    input: Partial<DoctorMSVisitInput>,
  ) => {
    await updateVisitMutation({ variables: { id, input } });
  };

  const [deletePatientMutation] = useDoctorDeletePatientMutation({
    refetchQueries: [
      DoctorGetPatientDocument,
      DoctorPatientProfileVisitDocument,
    ],
    awaitRefetchQueries: true,
  });

  const deletePatient = async (phone: string) => {
    await deletePatientMutation({
      variables: { phone },
      refetchQueries: [DoctorGetPatientsDocument],
    });
  };

  const debouncedGetData = useDebouncedCallback(
    () =>
      getData({
        variables: {
          take: 50,
          fullText: searchText,
        },
      }),
    500,
  );

  useEffect(() => {
    debouncedGetData();
  }, [searchText]);

  return {
    patients: data ? data.doctorPatients : [],
    loading: loading || networkStatus === NetworkStatus.refetch,
    deletePatient,
    updateVisit,
    searchText,
    setSearchText,
  };
};

import { DiamondAlertIcon } from "@jutro/ui";
import { DoctorMSDeclarationRejectedEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../NfzEvent";
import { EventHandler } from "../NfzEvent/infer";

export const DeclarationRejectedEventHandler: EventHandler<
  DoctorMSDeclarationRejectedEvent
> = ({ event }) => {
  const { exactRejectionReason, nfzComment, rejectionReason } = event;
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_CHECK}
      statusText="Pacjent nieubezpieczony"
      color="rose"
      icon={<DiamondAlertIcon size="sm" />}
      tooltip={
        <TooltipConent
          exactRejectionReason={exactRejectionReason}
          nfzComment={nfzComment}
          rejectionReason={rejectionReason}
        />
      }
    />
  );
};

type TooltipConentProps = {
  exactRejectionReason: string;
  rejectionReason: string;
  nfzComment: string;
};

const TooltipConent = ({
  nfzComment,
  rejectionReason,
  exactRejectionReason,
}: TooltipConentProps) => {
  return (
    <div className="flex flex-col gap-1">
      <span>{nfzComment}</span>
      <span>{`${rejectionReason}: ${exactRejectionReason}`}</span>
    </div>
  );
};

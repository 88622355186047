import { DoctorMSDeclarationNoLongerValidEvent } from "lib/graphql/megaSchema";
import { EventHandler, NfzEventToComponent } from "../../NfzEvent/infer";
import { FacillityChangeHandler } from "./FacilityChange";
import { IncorrectDeclarationEventHandler } from "./IncorrectDeclaration";
import { PatientDiedEventHandler } from "./PatientDied";

const mapping = {
  DoctorMSIncorrectDeclarationEvent: IncorrectDeclarationEventHandler,
  DoctorMSPatientDiedEvent: PatientDiedEventHandler,
  DoctorMSFacilityChangeEvent: FacillityChangeHandler,
};

export const DeclarationNoLongerValidHandler: EventHandler<
  DoctorMSDeclarationNoLongerValidEvent
> = ({ event }) => {
  return <NfzEventToComponent mapping={mapping} event={event.reason} />;
};

import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useLogout } from "lib/hooks/auth";
import { useFlag } from "lib/hooks/flags";

export const IdleTimer = () => {
  const idleTimerEnabled = useFlag("idle-timer");
  const logout = useLogout();

  const { start } = useIdleTimer({
    startManually: true,
    crossTab: true,
    timeout: 15 * 60 * 1000,
    onIdle: () => {
      logout();
    },
  });

  useEffect(() => {
    if (!idleTimerEnabled) return;

    start();
  }, [idleTimerEnabled]);

  return null;
};

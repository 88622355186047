import { useDoctorPatientInsuranceStatusQuery } from "lib/graphql/megaSchema";
import {
  getInsuranceStatus,
  wasPatientInsuranceStatementEventEmittedToday,
} from "lib/hooks/usePatientInsuranceStatus/tools";
import { Maybe } from "lib/types";

export const usePatientInsuranceStatus = (patientId: Maybe<string>) => {
  const { data: patientInsuranceStatusData, loading } =
    useDoctorPatientInsuranceStatusQuery({
      variables: {
        patientId: patientId!,
      },
      skip: !patientId,
      fetchPolicy: "no-cache",
    });

  const didPatientSignInsuranceStatementToday =
    wasPatientInsuranceStatementEventEmittedToday(
      patientInsuranceStatusData?.doctorPatientInsuranceStatus,
    );

  const insuranceStatus = getInsuranceStatus(
    patientInsuranceStatusData?.doctorPatientInsuranceStatus,
  );

  return {
    latestCreatedAt:
      patientInsuranceStatusData?.doctorPatientInsuranceStatus.current
        ?.createdAt ?? null,
    loading,
    didPatientSignInsuranceStatementToday,
    insuranceStatus,
  };
};

import { Option } from "@jutro/types";
import { Button, Tabs } from "@jutro/ui";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Scrollbar } from "components/new";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorNextPatientsDocument,
  DoctorPatientsDocument,
  DoctorVisitDocument,
  useDoctorUnpinAllPatientsMutation,
  useDoctorUnpinPatientMutation,
} from "lib/graphql/megaSchema";
import { useNextPatients } from "lib/hooks/useNextPatients";
import { Maybe } from "lib/types";

export type VisitsTab = "active" | "per-day" | "archive";

export const VisitsLayout = () => (
  <div className="flex h-full w-full flex-col">
    <VisitsTabs />

    <Scrollbar>
      <Outlet />
    </Scrollbar>
  </div>
);

export const VisitsTabs = () => {
  const { patients, refetchPatients } = useNextPatients();
  const [currentUser] = useAtom(currentUserAtom);
  const [isUnpinning, setIsUnpinning] = useState<Maybe<string>>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useMemo(
    () => location.pathname.split("/").filter(Boolean).pop(),
    [location],
  );

  const visitTabs = useMemo(() => {
    const baseTabs: Option<VisitsTab>[] = [
      {
        label: "Wizyty",
        value: "per-day",
      },
      {
        label: `Pacjenci (${patients.mine.length}/${patients.others.length})`,
        value: "active",
      },
      {
        label: "Archiwum",
        value: "archive",
      },
    ];

    if (currentUser?.genre === "DOCTOR" || currentUser?.genre === "NURSE") {
      return baseTabs.filter(({ value }) => value !== "active");
    }

    return baseTabs;
  }, [currentUser]);

  const currentVisitTab = visitTabs.find(({ value }) => value === activeTab);

  const [unpinPatientMutation] = useDoctorUnpinPatientMutation({
    refetchQueries: [
      DoctorVisitDocument,
      DoctorPatientsDocument,
      DoctorNextPatientsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const [unpinAllPatientsMutation] = useDoctorUnpinAllPatientsMutation({
    refetchQueries: [
      DoctorVisitDocument,
      DoctorPatientsDocument,
      DoctorNextPatientsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const enhancedUnpin = async (patientId: string | "all") => {
    if (isUnpinning) {
      return;
    }

    setIsUnpinning(patientId);

    if (patientId === "all") {
      await unpinAllPatientsMutation();
    } else {
      await unpinPatientMutation({ variables: { id: patientId } });
    }

    await refetchPatients();

    setIsUnpinning(null);
  };

  return (
    <div className="mb-2 flex flex-row justify-between rounded-lg">
      {currentVisitTab && (
        <Tabs
          options={visitTabs}
          value={currentVisitTab}
          onChange={({ value }) => navigate(value)}
          width="hug"
        />
      )}

      {location.pathname.endsWith("/active") && (
        <Button
          full={false}
          size="condensed"
          variant="secondary"
          onClick={() => {
            enhancedUnpin("all");
          }}
          text="Kończę pracę"
        />
      )}
    </div>
  );
};

import {
  determineBirthday,
  determineSex,
  getPolishNumberConjugation,
} from "@jutro/tools";
import dayjs from "dayjs";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { isProcedure } from "components/ExaminationPanel/utils/isProcedure";
import { OrderedTestListItem } from "components/PatientDocs/Ordered/types";
import { ImmunizationCardStatus } from "components/PatientDocs/types";

export const analysisTypeMap: Record<string, string> = {
  USG: "USG",
  RTG: "RTG",
  SPIRO: "Spirometria",
  EKG: "EKG",
  URINE: "Mocz",
  BLOOD: "Krew",
  BANDAGE: "Zmiana opatrunku",
  OBJECT: "Ciało obce",
  STITCHES: "Szwy",
  VACCINE: "Szczepienie",
  MEDICINE: "Iniekcja",
  DRUGPASS: "Podanie leku",
  INJECTION: "Iniekcja",
  HEARTRATE: "Tętno",
  PRESSURE: "Ciśnienie",
};

export const corruptedAnalysisTypeMap: Record<string, string> = {
  "Morfologia krwi (pe�na)": "Morfologia krwi (pełna)",
  "Mocz - badanie og�lne": "Mocz - badanie ogólne",
  "Pr�by w�trobowe (ALT, AST, ALP, BIL, GGTP)":
    "Próby wątrobowe (ALT, AST, ALP, BIL, GGTP)",
  "P/c. odporno�ciowe(dawniej t. Coombsa)":
    "P/c. odpornościowe(dawniej t. Coombsa)",
  "Wap� ca�kowity": "Wapń całkowity",
  "S�d": "Sód",
  "�elazo": "Żelazo",
  "CRP, ilo�ciowo": "CRP, ilościowo",
  "PSA ca�kowity": "PSA całkowity",
  "Osad moczu met. cytometrii przep�ywowej":
    "Osad moczu met. cytometrii przepływowej",
};

const isCito = (item: OrderedTestListItem) => {
  if (isLaboratoryExamination(item)) {
    return item.cito;
  }

  if (isProcedure(item)) {
    return (item.ekg?.cito || item.medication?.cito) ?? false;
  }

  throw new Error("item is not a procedure or lab exam");
};

const isNotCito = (item: OrderedTestListItem) => {
  return !isCito(item);
};

export const sortLabExaminationsAndProceduresByCitoAndDate = (
  orderedTests: OrderedTestListItem[],
) => {
  orderedTests.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const cito = orderedTests.filter(isCito);
  const normal = orderedTests.filter(isNotCito);

  return [...cito, ...normal];
};

const getAgeDataFromPesel = (pesel: string) => {
  const birthDate = dayjs(determineBirthday(pesel));
  const now = dayjs();

  const years = now.diff(birthDate, "year");
  const months = now.diff(birthDate, "month") % 12;
  const weeks = now.diff(birthDate, "week");

  const lastMonthDays = now.subtract(1, "month").daysInMonth();
  const daysSinceMonthStart = now.date() - birthDate.date();
  const daysInMonth = (lastMonthDays + daysSinceMonthStart) % lastMonthDays;
  const daysInWeek = now.diff(birthDate, "day") % 7;

  return { years, months, weeks, daysInMonth, daysInWeek };
};

export const getAgeLabelsFromPesel = (pesel: string) => {
  const { years, months, weeks, daysInMonth, daysInWeek } =
    getAgeDataFromPesel(pesel);

  return {
    years: `${years} ${getPolishNumberConjugation(years, ["rok", "lata", "lat"])}`,
    months: `${months} ${getPolishNumberConjugation(months, ["miesiąc", "miesiące", "miesięcy"])}`,
    weeks: `${weeks} ${getPolishNumberConjugation(weeks, ["tydzień", "tygodnie", "tygodni"])}`,
    daysInMonth: `${daysInMonth} ${getPolishNumberConjugation(daysInMonth, ["dzień", "dni", "dni"])}`,
    daysInWeek: `${daysInWeek} ${getPolishNumberConjugation(daysInWeek, ["dzień", "dni", "dni"])}`,
  };
};

export const formatPatientCardNumber = (patientCardNumber: string) => {
  return `${patientCardNumber.slice(0, 3)}-${patientCardNumber.slice(3, 6)}`;
};

export const getUserGender = (pesel: string | undefined | null) => {
  if (!pesel) {
    return "";
  }

  if (pesel.length === 11) {
    return determineSex(pesel) === "K" ? "Żeńska" : "Męska";
  }

  const genderNumberInPesel = pesel.slice(10);

  if (genderNumberInPesel === "10") {
    return "Męska";
  }

  return "Żeńska";
};

export const immunizationCardStatusOptions: ImmunizationCardStatus[] = [
  {
    label: "Czekamy na kartę",
    value: "SYSTEM",
  },
  {
    label: "Na stanie",
    value: "CLINIC",
  },
  {
    label: "Poza POZ",
    value: "OUTSIDE_SYSTEM",
  },
];

export const calculateHaversineDistance = (
  lat1: number | undefined | null,
  lng1: number | undefined | null,
  lat2: number | undefined | null,
  lng2: number | undefined | null,
) => {
  if (!lat1 || !lng1 || !lat2 || !lng2) {
    return 0;
  }

  const earthRadiusKm = 6371;

  // Convert degrees to radians
  const degToRad = (deg: number) => deg * (Math.PI / 180);

  // Calculate differences between latitudes and longitudes
  const deltaLat = degToRad(lat2 - lat1);
  const deltaLng = degToRad(lng2 - lng1);

  // Calculate Haversine formula
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKm * c;

  return distance; // Distance in kilometers
};

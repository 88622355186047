import { ChannelName, Thread } from "components/Outercom/types";

const channelIdMap: Record<ChannelName, string> = {
  "outercom-helpdesk": "C044CTYJS85",
  "outercom-hr": "C044JAPJRBN",
  "outercom-legal": "C044811PTFY",
  "outercom-medical": "C044UFT1C0H",
  "outercom-technology": "C044CU26NJH",
};

export const getThreadSlackLink = (thread: Thread) => {
  return `https://jutromedical.slack.com/archives/${
    channelIdMap[thread.channelName]
  }/p${thread.id.split(".").join("")}`;
};

import { BinIcon, Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { Draggable, DroppableProvided } from "react-beautiful-dnd";
import {
  DoctorLaboratoryExaminationsDocument,
  DoctorMSLaboratoryExaminationEntity,
  useDoctorUpdateLaboratoryExaminationMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { isVisitClosedAtom } from "../atoms";
import { returnCorrespondingColors } from "./SampleElement";

export const LaboratoryExaminationsList = ({
  labExaminations,
  provided,
  isSampleColorValid,
}: {
  labExaminations: DoctorMSLaboratoryExaminationEntity[];
  provided: DroppableProvided;
  isSampleColorValid: boolean;
}) => {
  const [isVisitClosed] = useAtom(isVisitClosedAtom);

  const [removeLabExaminationFromAssigned] =
    useDoctorUpdateLaboratoryExaminationMutation({
      awaitRefetchQueries: true,
      refetchQueries: [DoctorLaboratoryExaminationsDocument],
    });

  return (
    <div
      className="flex flex-col gap-4"
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      {labExaminations.map((labExamination, index) => {
        const { id, assignment } = labExamination;

        const trimmedColor = assignment?.color?.trim();

        return (
          // @ts-ignore
          <Draggable draggableId={id} key={id} index={index}>
            {(provided) => (
              <div
                className={`flex cursor-pointer items-center justify-between rounded-lg border p-2 hover:bg-jutro-new-warm-gray-100`}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <span className="font-paragraph-2">{assignment?.name}</span>
                <div className="flex items-center gap-2">
                  <span
                    className={`font-paragraph-2 ${
                      returnCorrespondingColors(trimmedColor).fallbackText
                    }`}
                  >
                    {!isSampleColorValid && trimmedColor}
                  </span>

                  <Button
                    disabled={isVisitClosed}
                    icon={<BinIcon />}
                    size="condensed"
                    full={false}
                    variant="negativeGhost"
                    label="Usuń"
                    tooltipPosition="top"
                    onClick={async () => {
                      await removeLabExaminationFromAssigned({
                        variables: {
                          data: {
                            sampleId: null,
                            groupId: null,
                            visitId: null,
                          },
                          ids: id,
                        },
                      }).catch(() => {
                        toaster.warning(
                          "Nie udało się odpiąć badania laboratoryjnego.",
                        );
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </Draggable>
        );
      })}
      {provided.placeholder}
    </div>
  );
};

import { Tag } from "@jutro/ui";
import { ComponentProps } from "react";
import { InsuranceStatus } from "lib/hooks/usePatientInsuranceStatus/types";

export const insuranceStatusTagColorMap: Record<
  InsuranceStatus,
  ComponentProps<typeof Tag>["color"]
> = {
  green: "green",
  greenOrStatement: "green",
  statement: "green",
  contributionCertificate: "green",
  check: "gray",
  error: "rose",
  red: "rose",
};

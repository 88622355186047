import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "components/new/Checkbox";

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string | boolean;
};

export const ControlledCheckbox = ({
  label,
  name,
  disabled,
  defaultValue,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <Checkbox
          {...field}
          color="text-jutro-new-green-400"
          xs
          error={error}
          disabled={disabled}
          label={label}
        />
      )}
    />
  );
};

import { replacePolishDiacritics } from "@jutro/tools";
import { Option } from "@jutro/types";
import { z } from "zod";
import {
  SnippetCategoryFilter,
  SnippetTagFilter,
  SnippetTypeFilter,
  SnippetUsageFilter,
  SnippetVisiblityFilter,
} from "components/Snippet/types";
import {
  EdmSnippet,
  EdmSnippetType,
  EdmSnippetVisibility,
} from "lib/graphql/megaSchema";

export const snippetCategoryLabelMap = {
  start: "Początek",
  interview: "Wywiad",
  forward: "Przekierowanie",
  unpin: "Odpięcie",
  finish: "Zakończenie",
  physicalExamination: "Badania fizykalne",
  recommendation: "Zalecenia",
  doctorInterview: "Wywiad lekarski",
  usg: "Badanie USG",
  memo: "Wewnętrzne informacje o wizycie",
} as const;

export const getSnippetCategoryOptionsByType = (type: EdmSnippetType) => {
  if (type === "CHAT") {
    return chatCategoryOptions;
  }

  if (type === "DOCS") {
    return docsCategoryOptions;
  }

  return usgCategoryOptions;
};

export const getSnippetTypeLabel = (type: EdmSnippetType) => {
  if (type === "CHAT") {
    return "Czat";
  }

  if (type === "DOCS") {
    return "Dokumentacja";
  }

  return "USG";
};

export const snippetTypeOptions: Option<EdmSnippetType>[] = [
  { label: "Czat", value: "CHAT" },
  { label: "Dokumentacja", value: "DOCS" },
  { label: "USG", value: "USG" },
];

export const snippetVisibilityOptions: Option<EdmSnippetVisibility>[] = [
  { label: "Prywatne", value: "PRIVATE" },
  { label: "Publiczne", value: "PUBLIC" },
];

export const snippetFormSchema = z.object({
  name: z.string().min(1, { message: "To pole jest wymagane." }).max(40),
  type: z
    .union(
      [
        z.literal("CHAT", {
          message: "To pole jest wymagane.",
        }),
        z.literal("DOCS", {
          message: "To pole jest wymagane.",
        }),
        z.literal("USG", {
          message: "To pole jest wymagane.",
        }),
      ],
      {
        message: "To pole jest wymagane.",
      },
    )
    .refine((val) => val, { message: "To pole jest wymagane." }),
  visibility: z.union([z.literal("PRIVATE"), z.literal("PUBLIC")]),
  content: z
    .string({
      message: "To pole jest wymagane.",
    })
    .min(1, { message: "To pole jest wymagane." })
    .max(4000),
  category: z
    .string({
      message: "To pole jest wymagane.",
    })
    .min(1, { message: "To pole jest wymagane." }),
  tags: z.array(z.object({ value: z.string(), label: z.string() })).default([]),
});

export const chatCategoryOptions = [
  { label: "Początek", value: "start" },
  { label: "Wywiad", value: "interview" },
  { label: "Przekierowanie", value: "forward" },
  { label: "Odpięcie", value: "unpin" },
  { label: "Zakończenie", value: "finish" },
] satisfies Option<SnippetCategoryFilter>[];

export const docsCategoryOptions = [
  { label: "Wewnętrzne informacje o wizycie", value: "memo" },
  { label: "Badania fizykalne", value: "physicalExamination" },
  { label: "Zalecenia", value: "recommendation" },
  { label: "Wywiad lekarski", value: "doctorInterview" },
] satisfies Option<SnippetCategoryFilter>[];

export const usgCategoryOptions = [
  { label: "Badanie USG", value: "usg" },
] satisfies Option<SnippetCategoryFilter>[];

export const snippetCategoryOptions: Option<SnippetCategoryFilter>[] = [
  ...chatCategoryOptions,
  ...docsCategoryOptions,
  ...usgCategoryOptions,
];

export const snippetUsageOptions: Option<SnippetUsageFilter>[] = [
  { label: "Wszystkie", value: "ALL" },
  { label: "Tylko użyte chociaż raz", value: "USED_ONLY" },
  { label: "Tylko nieużyte w ogóle", value: "NOT_USED_ONLY" },
];

export const visibilityFilter = (
  snippet: EdmSnippet,
  selectedVisibility: SnippetVisiblityFilter,
) => selectedVisibility === "ALL" || snippet.visibility === selectedVisibility;

export const typeFilter = (
  snippet: EdmSnippet,
  selectedType: SnippetTypeFilter,
) => selectedType === "ALL" || snippet.type === selectedType;

export const categoryFilter = (
  snippet: EdmSnippet,
  selectedCategory: SnippetCategoryFilter,
) => selectedCategory === "ALL" || snippet.category === selectedCategory;

export const ownerFilter = (
  snippet: EdmSnippet,
  selectedOwner: string | null,
) => !selectedOwner || snippet.createdBy === selectedOwner;

export const usageFilter = (
  snippet: EdmSnippet,
  selectedUsage: SnippetUsageFilter,
) => {
  if (selectedUsage === "ALL") return true;
  return selectedUsage === "USED_ONLY"
    ? snippet.counter > 0
    : snippet.counter === 0;
};

const normalize = (text: string) => replacePolishDiacritics(text.toLowerCase());

export const searchFilter = (snippet: EdmSnippet, searchText: string) => {
  if (searchText.length < 3) return true;
  const normalizedSnippet = normalize(JSON.stringify(snippet));
  const normalizedSearchText = normalize(searchText);
  return normalizedSnippet.includes(normalizedSearchText);
};

export const tagFilter = (
  snippet: EdmSnippet,
  selectedTag: SnippetTagFilter,
) => {
  if (selectedTag === "ALL") return true;
  return snippet.tags.find((tag) => tag.id === selectedTag);
};

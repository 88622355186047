import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import z from "zod";
import { TagForm } from "components/TagFormNew/types";
import { DoctorMSPatientTagType } from "lib/graphql/megaSchema";

const defaultError = "To pole jest wymagane.";

const zodSchema = z
  .object({
    type: z
      .union([
        z.literal("ADDITIONAL"),
        z.literal("ALLERGY"),
        z.literal("CHRONIC_DISEASE"),
        z.literal("EDM_VACCINE"),
        z.literal("HOSPITALIZATION"),
        z.literal("REGULAR_DRUG"),
        z.literal("SPEC_CONSULTATIONS"),
        z.literal("SURGICAL_PROCEDURES"),
        z.literal("VACCINES"),
      ])
      .nullable(),
    confirmationType: z
      .union([
        z.literal("DOCUMENTATION"),
        z.literal("EXAMINATION"),
        z.literal("INTERVIEW"),
      ])
      .nullish(),
    expirationAt: z.date().nullable(),
    patientId: z.string(),
    data: z.object({
      interval: z.string(),
      medicineList: z
        .array(
          z.object({
            date: z.date().optional(),
            subtitle: z.string().optional(),
          }),
        )
        .optional(),
      description: z.string().optional(),
      ean: z.string().optional(),
      name: z.string().optional(),
      drug: z.any().optional(), // this is not sent to backend
    }),
  })
  .refine((val) => (val.type === "REGULAR_DRUG" ? val.expirationAt : true), {
    message: defaultError,
    path: ["expirationAt"],
  })
  .refine((val) => val.type, {
    message: defaultError,
    path: ["type"],
  })
  .refine((val) => val.confirmationType, {
    message: defaultError,
    path: ["confirmationType"],
  })
  .refine((val) => (val.type === "REGULAR_DRUG" ? val.data.interval : true), {
    message: defaultError,
    path: ["data.interval"],
  })
  .refine((val) => (val.type === "REGULAR_DRUG" ? val.data.ean : true), {
    message:
      "To pole jest wymagane lub wybrany lek nie znajduje się na liście dostępnych leków.",
    path: ["data.ean"],
  })
  .refine((val) => (val.type !== "REGULAR_DRUG" ? val.data.name : true), {
    message: defaultError,
    path: ["data.name"],
  });

export type TagFormSchema = z.TypeOf<typeof zodSchema>;

type Props = {
  patientId: string | null;
  tag: TagForm["tag"];
};

export const useTagFormNew = ({ patientId, tag }: Props) => {
  const methods = useForm<TagFormSchema>({
    mode: "onSubmit",
    resolver: zodResolver(zodSchema),
  });

  const { reset } = methods;
  useEffect(() => {
    if (!tag || !patientId) {
      return;
    }

    const { data, type, expirationAt, confirmationType } = tag;

    data?.medicineList?.map((medicine: any) => {
      const date = medicine?.date;

      return {
        ...medicine,
        date: dayjs(date).toDate(),
      };
    });

    reset({
      type: type as DoctorMSPatientTagType,
      expirationAt: expirationAt ? dayjs(expirationAt).toDate() : null,
      confirmationType: confirmationType,
      data: {
        ean: data?.ean ?? "",
        description: data?.description ?? "",
        medicineList: data?.medicineList?.map((medicine: any) => {
          const date = medicine?.date;

          return {
            ...medicine,
            date: dayjs(date).toDate(),
          };
        }),
        name: data?.name ?? "",
        interval: data?.interval ?? "",
        drug: data?.drug ?? null,
      },
      patientId,
    });
  }, [tag]);

  return {
    methods,
  };
};

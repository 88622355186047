import { omit } from "lodash";
import { DoctorVisitQuery } from "lib/graphql/megaSchema";
import { InterviewData, MedicAdvice } from "views/Visit/RightPanel/Visit/types";

type MedicAdviceProcedureData = Omit<InterviewData, "recommendation"> &
  MedicAdvice;

export const handleMainDiagnosis = (
  doctorDiagnosis: Exclude<
    DoctorVisitQuery["doctorVisit"],
    null
  >["doctorDiagnosis"],
) => {
  const hasMainInDoctorDiagnosis = doctorDiagnosis?.find(
    (d) => d?.grade === "MAIN",
  );

  if (!doctorDiagnosis) {
    return [
      { code: "", grade: "MAIN" as const },
      { code: "", grade: "SUPPLEMENTARY" as const },
    ];
  }

  if (!hasMainInDoctorDiagnosis) {
    return [{ code: "", grade: "MAIN" as const }, ...doctorDiagnosis];
  }

  return doctorDiagnosis;
};

export const getInterviewDataFromProcedure = (
  data: MedicAdviceProcedureData | null,
) => {
  const recommendation =
    data?.doctorAdvice?.recommendation ??
    data?.nurseAdvice?.recommendation ??
    // TODO: remove after BE fix upsertSingleProcedure
    data?.recommendation ??
    "";

  const procedureWithoutAdvice = omit(data, ["doctorAdvice", "nurseAdvice"]);

  return {
    ...procedureWithoutAdvice,
    recommendation,
  };
};

export const getInterviewDataFromVisit = (
  visit: Exclude<DoctorVisitQuery["doctorVisit"], null>,
) => {
  const {
    doctorInterview,
    physicalExamination,
    doctorDiagnosis,
    recommendation,
    comment,
    memo,
    height,
    weight,
    smokeLevel,
  } = visit;

  return {
    doctorInterview: doctorInterview ?? "",
    physicalExamination: physicalExamination ?? "",
    doctorDiagnosis: handleMainDiagnosis(doctorDiagnosis),
    recommendation: recommendation ?? "",
    comment: comment ?? "",
    memo: memo ?? "",
    height: height,
    weight: weight,
    smokeLevel: smokeLevel,
  };
};
export const issuedReferralUrgentRecommendationText =
  "Proszę umówić się pilnie na konsultację specjalistyczną - lista placówek znajduje się w otrzymanym linku (chat). W razie wystąpienia nowych dolegliwości lub zaostrzenia obecnie występujących proszę o niezwłoczne zgłoszenie się do lekarza.";
export const issuedReferralNoUrgentRecommendationText =
  "Proszę umówić się na konsultację specjalistyczną - lista placówek znajduje się w otrzymanym linku (chat). W razie wystąpienia nowych dolegliwości lub zaostrzenia obecnie występujących proszę o niezwłoczne zgłoszenie się do lekarza.";

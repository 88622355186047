import {
  AddIcon,
  AddListIcon,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Input,
  SearchIcon,
  Tooltip,
} from "@jutro/ui";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import ReactSelect from "react-select";
import { customSelectStyles } from "components/PatientDocs/Ordered/utils";
import { Avatar, Loader } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { DoctorMSShiftOutputDynamic } from "lib/graphql/megaSchema";
import { organizationClinicOption } from "lib/hooks/useVojevodshipOptions";
import { DoctorDetailsTooltip } from "views/StaffScheduleCreator/components/DoctorDetailsTooltip/DoctorDetailsTooltip";
import { EmptyDoctorsList } from "views/StaffScheduleCreator/components/EmptyDoctorsList/EmptyDoctorsList";
import { Shift } from "views/StaffScheduleCreator/components/Shift/Shift";
import { SlotActionModal } from "views/StaffScheduleCreator/components/SlotActionModal/SlotActionModal";
import { SlotDetailsTooltip } from "views/StaffScheduleCreator/components/SlotDetailsTooltip/SlotDetailsTooltip";
import { useScheduleCreator } from "views/StaffScheduleCreator/hooks/useScheduleCreator";
import {
  SlotActionModalMode,
  generateDaysInCurrentMonthRow,
  getColumnBackgroundColor,
  handleDateChange,
} from "views/StaffScheduleCreator/utils/index";
import "./utils/StaffSchedulerDatePicker.css";

const FIRST_COLUMN_WIDTH = 32;

export const StaffScheduleCreator = () => {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf("month"));
  const [isShiftModalOpen, setIsShiftModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [selectedSlotDoctorId, setSelectedSlotDoctorId] = useState<string>("");
  const [selectedDay, setSelectedDay] = useState<Dayjs | undefined>(undefined);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [modalMode, setModalMode] = useState<SlotActionModalMode>("create");
  const [selectedShift, setSelectedShift] = useState<
    DoctorMSShiftOutputDynamic | undefined
  >(undefined);

  const days = useMemo(
    () => generateDaysInCurrentMonthRow(startDate, endDate),
    [startDate, endDate],
  );

  const {
    filteredMedicalStaff,
    medicalStaffSelectOptions,
    organizationsClinicsOptions,
    shifts,
    selectedOrganization,
    publishShiftsLoading,
    handleOrganizationChange,
    handlePublishShifts,
  } = useScheduleCreator({
    from: startDate,
    to: endDate,
    searchInput: searchInputValue,
  });

  const { data: shiftsData, loading: shiftsLoading } = shifts;

  const isShiftDataDirty = shiftsData?.some((o) => o.draftData);

  if (shiftsLoading) {
    return <Loader screen />;
  }

  const handleModalOpen = (
    mode: SlotActionModalMode,
    day: Dayjs | undefined,
  ) => {
    setIsShiftModalOpen(true);
    setSelectedDay(day);
    setModalMode(mode);
  };

  const handleCloseModal = () => {
    setIsShiftModalOpen(false);
    setSelectedSlotDoctorId("");
    setSelectedDay(undefined);
    setSelectedShift(undefined);
  };

  return (
    <>
      <div className="font-paragraph-2 flex h-full flex-col gap-2 overflow-auto">
        <div className="flex min-w-[1814px] items-center justify-between rounded-lg bg-white p-4">
          <div className="flex w-1/2 gap-4">
            <div className="staff-schedule-date-picker-wrapper flex h-11 flex-1 items-center rounded-lg border border-jutro-new-warm-gray-300">
              <div
                className="cursor-pointer rounded-l-lg p-3"
                onClick={() =>
                  handleDateChange("prev", setStartDate, setEndDate)
                }
              >
                <ChevronLeftIcon size="sm" />
              </div>
              <DatePicker
                value={startDate.toDate()}
                onChange={(date) => {
                  const stringDate = date?.toString();
                  setStartDate(dayjs(stringDate).startOf("month"));
                  setEndDate(dayjs(stringDate).endOf("month"));
                }}
                locale="pl"
                calendarIcon={false}
                clearIcon={false}
                format="y-MM"
                maxDetail="year"
                className="!z-30 !h-[44px] cursor-pointer"
              />
              <div
                className="cursor-pointer rounded-r-lg p-3"
                onClick={() =>
                  handleDateChange("next", setStartDate, setEndDate)
                }
              >
                <ChevronRightIcon size="sm" />
              </div>
            </div>
            <div className="flex h-11 flex-1 items-center justify-center">
              <ReactSelect
                value={organizationsClinicsOptions.find(
                  (o) => o.value === selectedOrganization,
                )}
                options={organizationsClinicsOptions}
                placeholder="Wybierz placówkę"
                styles={customSelectStyles<string>()}
                onChange={(org) =>
                  handleOrganizationChange(org as organizationClinicOption)
                }
              />
            </div>
            <div className="flex-1">
              <Input
                value={searchInputValue}
                height="sm"
                maxLength={30}
                placeholder="Szukaj..."
                sideElement={<SearchIcon size="sm" />}
                sideElementPosition="end"
                onChange={(e) => setSearchInputValue(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-1/2 justify-end gap-4">
            <div>
              <Button
                text="Utwórz zmianę"
                variant="secondary"
                iconPosition="left"
                icon={<AddListIcon />}
                onClick={() => handleModalOpen("create", undefined)}
              />
            </div>
            <div>
              <Button
                text="Opublikuj zmiany"
                onClick={() => setIsConfirmationModalOpen(true)}
                loading={publishShiftsLoading}
                disabled={!shiftsData?.length || !isShiftDataDirty}
              />
            </div>
          </div>
        </div>

        {filteredMedicalStaff?.length ? (
          <div className="relative flex h-full min-w-[1814px] flex-1 flex-col rounded-lg border border-jutro-new-warm-gray-300 bg-white">
            <div className="sticky top-0 z-10 flex w-full bg-white text-sm">
              <div style={{ width: FIRST_COLUMN_WIDTH }}></div>
              {days.map((day, index) => (
                <div
                  key={index}
                  className={`flex flex-1 flex-col border-l border-jutro-new-warm-gray-300 text-center ${getColumnBackgroundColor(
                    day,
                  )}`}
                >
                  <span>{day.format("dd")}</span>
                  <span>{day.format("D")}</span>
                </div>
              ))}
            </div>

            {filteredMedicalStaff.map((doctor) => (
              <div
                key={doctor.value}
                className="flex border-t border-jutro-new-warm-gray-300 bg-white last-of-type:border-b"
              >
                <Tooltip
                  content={
                    <DoctorDetailsTooltip
                      doctorId={doctor.value}
                      doctorFullName={doctor.label}
                      genre={doctor.genre}
                      npwz={doctor.npwz}
                    />
                  }
                  trigger={
                    <div
                      style={{
                        width: FIRST_COLUMN_WIDTH,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "sticky",
                        left: 0,
                        height: "100%",
                        backgroundColor: "white",
                        zIndex: 10,
                      }}
                    >
                      <Avatar userId={doctor.value} />
                    </div>
                  }
                />
                {days.map((day, index) => (
                  <div
                    key={index}
                    className={`group flex min-h-[76px] flex-1 cursor-pointer flex-col gap-1 border-l border-jutro-new-warm-gray-300 p-1 ${getColumnBackgroundColor(
                      day,
                    )}`}
                  >
                    {shiftsData?.map((shift) => {
                      const isDeletable = Boolean(
                        shift.draftData && !shift.draftData.active,
                      );

                      return (
                        <>
                          {dayjs(shift.day).get("D") === day.get("D") &&
                            shift.doctorId === doctor.value && (
                              <Tooltip
                                position="top"
                                trigger={
                                  <div>
                                    <Shift
                                      key={shift.id}
                                      shift={shift}
                                      selectedOrganization={
                                        selectedOrganization
                                      }
                                      isDeletable={isDeletable}
                                      openModal={(mode) => {
                                        handleModalOpen(mode, day);
                                        setSelectedSlotDoctorId(doctor.value);

                                        if (mode === "edit") {
                                          setSelectedShift(shift);
                                        }
                                      }}
                                    />
                                  </div>
                                }
                                content={
                                  <SlotDetailsTooltip
                                    shift={shift}
                                    staffFullName={doctor.label}
                                    shiftOrganizationId={
                                      organizationsClinicsOptions.find(
                                        (clinic) =>
                                          clinic.value === shift.placeId,
                                      )?.label
                                    }
                                  />
                                }
                              />
                            )}
                        </>
                      );
                    })}

                    {!shiftsData?.some(
                      (shift) =>
                        dayjs(shift.day).get("D") === day.get("D") &&
                        shift.doctorId === doctor.value,
                    ) && (
                      <div
                        className="m-auto h-full w-full items-center justify-center rounded-lg group-hover:flex group-hover:bg-jutro-new-blue-50"
                        onClick={() => {
                          handleModalOpen("create", day);
                          setSelectedSlotDoctorId(doctor.value);
                        }}
                      >
                        <div className="hidden h-[68px] items-center justify-center group-hover:flex">
                          <Button
                            label="addSlot"
                            icon={<AddIcon size="sm" />}
                            variant="ghost"
                            size="condensed"
                            withTooltip={false}
                            onClick={() => {
                              handleModalOpen("create", day);
                              setSelectedSlotDoctorId(doctor.value);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <EmptyDoctorsList
            searchInputValue={searchInputValue}
            onSearchInputValueReset={() => setSearchInputValue("")}
            onModalOpen={() => handleModalOpen("create", undefined)}
          />
        )}
      </div>

      {isShiftModalOpen && (
        <SlotActionModal
          isModalOpen={isShiftModalOpen}
          doctorsOptions={medicalStaffSelectOptions}
          selectedSlotDoctorId={selectedSlotDoctorId}
          selectedDay={selectedDay}
          startDate={startDate}
          endDate={endDate}
          modalMode={modalMode}
          selectedShift={selectedShift}
          organizationsClinicsOptions={organizationsClinicsOptions}
          selectedOrganization={selectedOrganization}
          onCloseModal={handleCloseModal}
        />
      )}

      <ConfirmDialog
        open={isConfirmationModalOpen}
        setOpen={setIsConfirmationModalOpen}
        onConfirm={handlePublishShifts}
        width="lg"
        title="Czy chcesz opublikować zmiany?"
        text="Wprowadzone zmiany będą widoczne natychmiastowo w kalendarzach pacjentów."
        confirmText="Opublikuj"
      />
    </>
  );
};

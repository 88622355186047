import { DoctorMSMessageType } from "lib/graphql/megaSchema";
import { referralTypesMap } from "../utils/maps";

export const getReferralTypeDescription = (
  referralType: DoctorMSMessageType,
) => {
  const referralItem = referralTypesMap.find(
    (item) => item.key === referralType,
  );

  if (!referralItem) {
    return null;
  }

  return referralItem.name;
};

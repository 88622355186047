/* List of actual Google Forms surveys */
export const surveysAndRecommendationsBasic = [
  {
    id: "PRESURVEY_FIRST",
    name: "Ankieta pierwszorazowa",
    recommendations: false,
  },
  {
    id: "PRESURVEY",
    name: "Ankieta stan zdrowia",
    recommendations: false,
  },
  {
    id: "1JubMFTCLEOYQBkqolzJhCXX3uWJwvcN9atNL-a0JVWg",
    name: "Stan zdrowia",
    recommendations: false,
  },
  {
    id: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    name: "Podejrzewam COVID-19",
    recommendations: false,
  },
  {
    id: "109q1WhfbD2imr8N6hHoeImDTscO4q5HtxuH3bKs47vo",
    name: "Ankieta infekcyjna",
    recommendations: false,
  },
  {
    id: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    name: "Mam wynik POZYTYWNY (+)",
    recommendations: false,
  },
  {
    id: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    name: "Mam wynik NEGATYWNY (-)",
    recommendations: false,
  },
  {
    id: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    name: "Zakończenie izolacji",
    recommendations: false,
  },
  {
    id: "18lhjEJvHxg-g_c5Wl54v2L6kfm4DeXdHJiUid3O0PCY",
    name: "Recepta na leki stałe",
    recommendations: false,
  },
  {
    id: "1gMcszpbvbKl7blpLqFF4YFfY5K9dxjfIvXGJjRRYbuI",
    name: "Opieka specjalisty — skierowanie",
    recommendations: false,
  },
  {
    id: "17e0eDbHmzCvnsTBKlSpNND_C-14Fy9SMhUSbNwvsxcM",
    name: "Badania kontrolne",
    recommendations: false,
  },
  {
    id: "1MpKOP_nBfd44SeekSjiGF67Y4KiZEpClWVhzlJwl2FQ",
    name: "Dokumentacja medyczna",
    recommendations: false,
  },
  {
    id: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    name: "Szczepienia ochronne (Dorośli)",
    recommendations: false,
  },
  {
    id: "1GFtiOpzrNV_fQ-aDHes3bSUbKv8xBSdIFInil7-eoxg",
    name: "Zatrucie pokarmowe (Biegunka / Wymioty)",
    recommendations: false,
  },
  {
    id: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    name: "Ból (ogólna)",
    recommendations: false,
  },
  {
    id: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    name: "Ból brzucha",
    recommendations: false,
  },
  {
    id: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    name: "Ból głowy",
    recommendations: false,
  },
  {
    id: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    name: "Ból w klatce piersiowej",
    recommendations: false,
  },
  {
    id: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    name: "Ból kręgosłupa",
    recommendations: false,
  },
  {
    id: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    name: "Krwawienie z dziąseł",
    recommendations: false,
  },
  {
    id: "1xdYkv8BC3SvSJdNlOyH8yuPwpHtK8fPyzqe_JamLp3U",
    name: "Kwalifikacja do szczepienia",
    recommendations: false,
  },
  {
    id: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    name: "Objawy dyzuryczne (trudności w oddawaniu moczu)",
    recommendations: false,
  },
  {
    id: "1GRr8FPc4943BiU-2MIh7s8-Na9yeWHydkvoJbwIl6Ic",
    name: "Poparzenie",
    recommendations: false,
  },
  {
    id: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    name: "Wizyta awaryjna (tabletka dzień po)",
    recommendations: false,
  },
  {
    id: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    name: "Ankieta dla pacjentów z chorobami psychicznymi",
    recommendations: false,
  },
  {
    id: "1L7DSu-4rUqR7Mnoa_t6o9YqvGY62Zt5bZ9Z2Utkv9xo",
    name: "Chcę sprawdzić wynik",
    recommendations: false,
  },
  {
    id: "covid",
    name: "Instrukcja zgłoszenia się na test COVID-19",
    recommendations: true,
  },
  {
    id: "ikp-share-prescriptions-and-referrals",
    name: "Instrukcja udostępniania medycznej dokumentacji — recepty i skierowania",
    recommendations: true,
  },
  {
    id: "ikp-share-prev-clinic",
    name: "Zgoda na udostępnianie dokumentacji medycznej z innej przychodni",
    recommendations: true,
  },
];

import { Button } from "@jutro/ui";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { SideSheet } from "components/new/Drawer";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSDoctorInput,
  DoctorMeDocument,
  useDoctorUpdateMeMutation,
} from "lib/graphql/megaSchema";
import { uppyConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";

type Props = {
  open: boolean;
  setClose: () => void;
};

const CANVAS_HEIGHT = 208;

export const DoctorProfileSignature = ({ open, setClose }: Props) => {
  const [saveUserMutation, { loading, called }] = useDoctorUpdateMeMutation();
  const [currentUser] = useAtom(currentUserAtom);
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const canvasWrapperRef = useRef<HTMLDivElement | null>(null);
  const [signatureEdit, setSignatureEdit] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageCorrect, setIsImageCorrect] = useState(true);

  const saveData = (input: DoctorMSDoctorInput) => {
    if (!currentUser?.id) return;
    saveUserMutation({
      variables: {
        id: currentUser.id,
        input,
      },
      refetchQueries: [DoctorMeDocument],
    });
    toaster.notify("Zapisano!");
  };

  const handleResetState = () => {
    setIsUploadingFile(false);
    setSignatureEdit(false);
    setIsImageCorrect(true);
  };

  const uploadSignature = () => {
    if (!canvasRef.current) return;
    const signatureCanvas = canvasRef.current.getCanvas();

    signatureCanvas.toBlob(async (blob) => {
      if (!blob) return;
      setIsUploadingFile(true);
      const response = await uploadFile(blob);
      saveData({
        signature: response,
      });
      handleResetState();
      setIsImageLoaded(false);
    });
  };

  const handleOnClose = () => {
    handleResetState();
    setClose();
  };

  const canvasWidth = canvasWrapperRef.current?.offsetWidth;

  return (
    <SideSheet
      isShown={open}
      onCloseComplete={handleOnClose}
      width={window.innerWidth / 3 - 32}
    >
      <section className="m-8 text-jutro-new-warm-gray-700">
        <div
          className="flex flex-col gap-6 text-jutro-new-warm-gray-500"
          ref={canvasWrapperRef}
        >
          <h2 className="font-display-3 text-jutro-purple-monochrome">
            Podpis
          </h2>
          {!signatureEdit ? (
            <>
              <div className="h-52 shadow-lg">
                <img
                  className={`${
                    currentUser?.signature && isImageLoaded && isImageCorrect
                      ? "block"
                      : "hidden"
                  }`}
                  src={`${
                    uppyConfig[envVars.REACT_APP_CONFIG]
                  }${currentUser?.signature}`}
                  width={canvasWidth}
                  height={CANVAS_HEIGHT}
                  onLoad={() => {
                    setIsImageLoaded(true);
                  }}
                  onError={() => {
                    setIsImageLoaded(true);
                    setIsImageCorrect(false);
                  }}
                />
              </div>
              <Button
                full={false}
                loading={!isImageLoaded}
                onClick={() => setSignatureEdit(true)}
                text="Edytuj"
              />
            </>
          ) : (
            <>
              <div className="w-full">
                {/* @ts-ignore */}
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    width: canvasWidth,
                    height: CANVAS_HEIGHT,
                    style: {
                      border: "1px solid #C9C6C5",
                    },
                  }}
                  minWidth={0.1}
                  maxWidth={1.5}
                  ref={canvasRef}
                />
              </div>
              <Button
                onClick={() => canvasRef.current?.clear()}
                text="Wyczyść"
                variant="negative"
                disabled={(loading && called) || isUploadingFile}
              />
              <Button
                onClick={uploadSignature}
                text="Zapisz"
                loading={(loading && called) || isUploadingFile}
              />
            </>
          )}
        </div>
      </section>
    </SideSheet>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, EyeOffIcon, EyeOnIcon, Input } from "@jutro/ui";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SideSheet } from "components/new/Drawer";
import { useDoctorChangePasswordMutation } from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { ConfirmDialog } from "./ConfirmDialog";

type Props = {
  setClose: () => void;
  open: boolean;
};

const schema = z.object({
  currentPassword: z
    .string({
      invalid_type_error: "Pole wymagane",
    })
    .min(1, { message: "Pole wymagane" }),
  newPassword: z
    .string({
      invalid_type_error: "Pole wymagane",
    })
    .min(1, { message: "Pole wymagane" })
    .min(8, { message: "Wymagane minimum 8 znaków" }),
  repeatPassword: z
    .string({
      invalid_type_error: "Pole wymagane",
    })
    .min(1, { message: "Pole wymagane" })
    .min(8, { message: "Wymagane minimum 8 znaków" }),
});

type SchemaType = z.infer<typeof schema> & { PasswordIsTheSame?: string };

export const ChangePasswordSideSheet = ({ setClose, open }: Props) => {
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [repeatCurrentPasswordShown, setRepeatCurrentPasswordShown] =
    useState(false);

  const navigate = useNavigate();

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const [changePassword, { loading }] = useDoctorChangePasswordMutation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = useForm<SchemaType>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
  });

  const currentFormState = watch();

  const handleSubmitChangePassword = (data: SchemaType) => {
    toaster.notify("Zmieniam hasło...");
    changePassword({
      variables: {
        input: {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      },
    })
      .then(() => {
        Cookies.remove("JWT");
        setClose();
        navigate("/", { replace: true });
        window.location.reload();
        return toaster.success("Hasło zostało zmienione!");
      })
      .catch((e) => {
        const mapError: { [key: string]: string } = {
          "GraphQL error: currentPassword and newPassword must not be the same":
            "Hasło musi być inne niż aktualne..",
          "GraphQL error: invalid currentPassword":
            "Niepoprawne aktualne hasło..",
        };
        return toaster.warning(mapError[e.message] || e.message);
      });
  };

  useEffect(() => {
    if (currentFormState.newPassword !== currentFormState.repeatPassword) {
      setError("PasswordIsTheSame", {
        type: "manual",
        message: "Hasła muszą być takie same!",
      });
    } else {
      clearErrors("PasswordIsTheSame");
    }
  }, [currentFormState.newPassword, currentFormState.repeatPassword]);

  return (
    <SideSheet
      isShown={open}
      onCloseComplete={setClose}
      width={window.innerWidth / 3 - 32}
    >
      <form onSubmit={handleSubmit(() => setChangePasswordModalOpen(true))}>
        <div className="flex items-center justify-center">
          <div className="mt-36 flex w-3/4 flex-col gap-16">
            <Controller
              name="currentPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  label="Aktualne hasło:"
                  type={oldPasswordShown ? "text" : "password"}
                  sideElement={
                    <Button
                      size="condensed"
                      variant="ghost"
                      label={oldPasswordShown ? "Ukryj" : "Pokaż"}
                      onClick={() => setOldPasswordShown((prev) => !prev)}
                      icon={oldPasswordShown ? <EyeOnIcon /> : <EyeOffIcon />}
                      withTooltip={false}
                    />
                  }
                  sideElementPosition="end"
                  {...field}
                  error={error?.message}
                />
              )}
            />

            <div className="flex flex-col gap-4">
              <Controller
                name="newPassword"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Nowe hasło"
                    type={currentPasswordShown ? "text" : "password"}
                    sideElement={
                      <Button
                        size="condensed"
                        variant="ghost"
                        label={currentPasswordShown ? "Ukryj" : "Pokaż"}
                        onClick={() => setCurrentPasswordShown((prev) => !prev)}
                        icon={
                          currentPasswordShown ? <EyeOnIcon /> : <EyeOffIcon />
                        }
                        withTooltip={false}
                      />
                    }
                    sideElementPosition="end"
                    {...field}
                    error={error?.message}
                  />
                )}
              />

              <Controller
                name="repeatPassword"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Powtórz nowe hasło"
                    type={repeatCurrentPasswordShown ? "text" : "password"}
                    sideElement={
                      <Button
                        size="condensed"
                        variant="ghost"
                        label={repeatCurrentPasswordShown ? "Ukryj" : "Pokaż"}
                        onClick={() =>
                          setRepeatCurrentPasswordShown((prev) => !prev)
                        }
                        icon={
                          repeatCurrentPasswordShown ? (
                            <EyeOnIcon />
                          ) : (
                            <EyeOffIcon />
                          )
                        }
                        withTooltip={false}
                      />
                    }
                    sideElementPosition="end"
                    {...field}
                    error={error?.message}
                  />
                )}
              />
              {errors.PasswordIsTheSame && (
                <p className="font-geologica font-paragraph-2 mt-8 text-jutro-new-rose-500">
                  Hasła muszą być takie same
                </p>
              )}
            </div>

            <p className="font-geologica font-paragraph-2 flex w-full gap-1 text-jutro-new-warm-gray-800">
              Ważne:{" "}
              <p className="font-medium">
                Ze względów bezpieczeństwa wylogujemy Cię automatycznie.
              </p>
            </p>

            <Button
              id="change-password-submit-button"
              type="submit"
              disabled={!isValid || !(Object.keys(errors).length === 0)}
              text="Zmień hasło"
            />
          </div>
        </div>
      </form>

      <ConfirmDialog
        title="Zmień hasło"
        text="Czy na pewno chcesz zmienić hasło?"
        confirmText="Zmień"
        open={changePasswordModalOpen}
        setOpen={setChangePasswordModalOpen}
        loading={loading}
        onConfirm={() => handleSubmitChangePassword(currentFormState)}
      />
    </SideSheet>
  );
};

import { DiamondDeadIcon } from "@jutro/ui";
import { DoctorMSPatientDiedEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../NfzEvent";
import { EventHandler } from "../../NfzEvent/infer";

export const PatientDiedEventHandler: EventHandler<
  DoctorMSPatientDiedEvent
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_CHECK}
      statusText="Pacjent zmarł"
      icon={<DiamondDeadIcon size="sm" />}
      color="black"
    />
  );
};

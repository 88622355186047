import dayjs from "dayjs";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Badge } from "components/new";
import { StrapiPostEntity } from "lib/graphql/megaSchema";
import { MaybeArray } from "lib/types";

type Props = {
  data: StrapiPostEntity;
  seenPostsIds: MaybeArray<string>;
};

export const NewsPostOverview = ({ data, seenPostsIds }: Props) => {
  const isPostNew = useMemo(() => {
    return !seenPostsIds?.includes(data.id);
  }, [seenPostsIds, data]);

  return (
    <Link
      id={`"news-post-${data.id}-menu`}
      to={`news/${data.attributes?.slug}`}
      className={`cursor-pointer rounded-lg border p-8 transition duration-200 ease-in-out ${
        isPostNew
          ? "bg-jutro-new-rose-50 hover:bg-jutro-new-rose-100"
          : "hover:bg-jutro-new-warm-gray-50"
      }`}
    >
      <div className="flex flex-col gap-2">
        {isPostNew && <Badge color="red" text="Nowe" />}
        <div className="font-display-3">{data.attributes?.title}</div>
        <div>
          publikacja:{" "}
          {dayjs(data.attributes?.createdAt).format("YYYY/MM/DD@HH:mm")}
        </div>
        <hr className="my-2" />
        {data.attributes?.excerpt}
      </div>
    </Link>
  );
};

import { useAtom } from "jotai";
import { useMemo } from "react";
import { isPathMatching } from "lib/tools";
import { tileConfig } from "lib/tools/createWorkspace";
import {
  defaultWorkspaceTilesMap,
  workspaceTilesAtom,
} from "lib/tools/createWorkspace/atoms";
import {
  isBasicTile,
  isDataTile,
  isWorkspace,
} from "lib/tools/createWorkspace/tools";
import {
  AddHandler,
  BasicTile,
  DataTile,
  ExtractDataTile,
  SwapHandler,
  Tile,
  TileColumn,
  Workspace,
} from "lib/tools/createWorkspace/types";

const workspaceMap: Record<Workspace, string> = {
  "visit-advice": "/visits/*/advice",
  "visit-procedure": "/visits/*/procedure",
  "patient-chat": "/patients/*/chat",
  "patient-profile": "/patients/*/profile",
  "snippets-admin": "/snippets-admin",
  settings: "/settings",
};

export const getCurrentWorkspace = () => {
  const { pathname } = location;

  const workspaceEntry = Object.entries(workspaceMap).find(([, route]) =>
    isPathMatching(pathname, route),
  );

  const workspace = workspaceEntry ? workspaceEntry[0] : "";

  if (!isWorkspace(workspace)) {
    throw new Error("Workspace not found");
  }

  return workspace;
};

export const getVisibleTiles = (tiles: Tile[][]) => {
  return tiles.map((tileList) => tileList[tileList.length - 1]);
};

export const useTileSystem = () => {
  const [tiles, setTiles] = useAtom(workspaceTilesAtom);
  const visibleTiles = useMemo(() => getVisibleTiles(tiles), [tiles]);

  const getTileInfo = (basicTileOrId: BasicTile | string) => {
    const position = visibleTiles.findIndex((tile) => {
      if (!tile) {
        return false;
      }

      if (isBasicTile(basicTileOrId)) {
        return basicTileOrId === tile;
      }

      return (tile as DataTile).id === basicTileOrId;
    });

    if (position === -1) {
      return { position: null, tile: null };
    }

    const tile = visibleTiles[position];

    return { position, tile };
  };

  const removeTile = (basicTileOrId: BasicTile | string) => {
    const { position: currentPosition } = getTileInfo(basicTileOrId);
    const currentWorkspace = getCurrentWorkspace();

    if (currentPosition === null) {
      return;
    }

    const currentPositionDefaultTile =
      defaultWorkspaceTilesMap[currentWorkspace][currentPosition];

    const currentPositionPreviousTile =
      tiles[currentPosition][tiles[currentPosition].length - 2] ??
      currentPositionDefaultTile;

    setTiles((draft) => {
      if (!currentPositionPreviousTile) {
        draft.splice(currentPosition, 1);
        return;
      }
      if (visibleTiles.includes(currentPositionPreviousTile)) {
        if (
          isBasicTile(basicTileOrId) &&
          basicTileOrId === currentPositionPreviousTile
        ) {
          // toaster.notify(
          //   "Bez sensu! Po usunięciu wskoczyłby ten sam kafel, bo jest na defaultcie tutaj",
          // );
        } else {
          // toaster.notify("Ten kafel jest już otwarty");
        }

        return;
      }

      draft[currentPosition].pop();
    });
  };

  const addTile = (
    newTile: Tile,
    handler: TileColumn | AddHandler,
    swapMode = false,
  ) => {
    setTiles((draft) => {
      const visibleTiles = getVisibleTiles(draft);

      const position =
        typeof handler === "function" ? handler({ visibleTiles }) : handler;

      if (!swapMode && !isDataTile(newTile) && visibleTiles.includes(newTile)) {
        // toast("Nie mozna duplikować kafli typu basic! add");
        return;
      }

      // if (position > tiles.length - 1) {
      //   setWidth((p) => {
      //     return p < 5 ? ((p + 1) as HandledTileNumber) : p;
      //   });
      // }
      if (!draft[position]) {
        draft[position] = [newTile];
        return;
      }

      const tileInPosition = visibleTiles[position];

      if (
        isDataTile(newTile) &&
        isDataTile(tileInPosition) &&
        tileInPosition.id === newTile.id
      ) {
        draft[position].pop();
      }

      draft[position].push(newTile);

      const tileName = typeof newTile === "string" ? newTile : newTile.name;

      if (tileConfig[tileName].width === 2) {
        draft.splice(position + 1);
      }
    });
  };

  const moveTile = (
    basicTileOrId: BasicTile | string,
    handler: TileColumn | SwapHandler,
  ) => {
    const { position: currentPosition, tile: currentTile } =
      getTileInfo(basicTileOrId);

    if (currentPosition === null) {
      return;
    }

    const newPosition =
      typeof handler === "function"
        ? handler({ currentPosition, visibleTiles })
        : handler;

    removeTile(basicTileOrId);
    addTile(currentTile, newPosition);
  };

  const swapTile = (
    basicTileOrId: BasicTile | string,
    handler: TileColumn | SwapHandler,
  ) => {
    const { position: currentPosition, tile: currentTile } =
      getTileInfo(basicTileOrId);

    if (currentPosition === null) {
      return;
    }

    const newPosition =
      typeof handler === "function"
        ? handler({ currentPosition, visibleTiles })
        : handler;

    const prevTile = tiles[newPosition][tiles.length - 1];

    addTile(currentTile, newPosition, true);
    addTile(prevTile, currentPosition, true);
  };

  const updateTileData = <T extends DataTile["name"]>({
    tileId,
    data,
  }: {
    tileId: string;
    data: Partial<ExtractDataTile<T>["data"]>;
  }) => {
    const { position: currentPosition, tile } = getTileInfo(tileId);

    if (currentPosition === null) {
      return;
    }

    const currentTile = tile as ExtractDataTile<T>;

    const updatedTile: ExtractDataTile<T> = {
      ...currentTile,
      data: { ...currentTile.data, ...data },
    };

    setTiles((draft) => {
      draft[currentPosition][draft[currentPosition].length - 1] = updatedTile;
    });
  };

  return {
    visibleTiles,
    getTileInfo,
    addTile,
    moveTile,
    removeTile,
    swapTile,
    updateTileData,
  };
};

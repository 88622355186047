import { Input } from "@jutro/ui";
import { useMemo } from "react";
import { Column, useBlockLayout, useFlexLayout, useTable } from "react-table";
import { Loader, ScreenLoader } from "components/new";

type PropsIsSearch = {
  setSearchText: (e?: any) => any;
  searchText: string;
};

type RowOriginal = [string, any[]];

type Props<T extends object> = {
  columns: Column<T>[];
  data: any;
  loading?: boolean;
  addSearchList?: PropsIsSearch | null;
  header?: any;
  screen?: boolean;
  rowsId?: string;
  searchInputId?: string;
  onRowClick?: (row: RowOriginal, data: RowOriginal[]) => any;
  isFullWidth?: boolean;
  flexLayoutMode: boolean;
};

export const Table = <T extends object>({
  columns,
  data,
  searchInputId,
  loading = false,
  addSearchList,
  header,
  screen = false,
  rowsId,
  isFullWidth,
  flexLayoutMode,
  onRowClick,
}: Props<T>) => {
  const layoutMode = flexLayoutMode ? useFlexLayout : useBlockLayout;

  const defaultColumn = useMemo(
    () => ({
      minWidth: 10,
      width: 150,
      maxWidth: 900,
    }),
    [],
  );
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    layoutMode,
  );

  return (
    <>
      <div className="flex gap-4">
        {addSearchList && (
          <div className="grow" key={searchInputId}>
            <Input
              id={searchInputId}
              type="text"
              value={addSearchList.searchText}
              onChange={(e) => addSearchList.setSearchText(e.target.value)}
            />
          </div>
        )}

        {header}
      </div>

      <table
        {...getTableProps()}
        className={`overflow-x-hidden overflow-y-scroll rounded-lg shadow-md ${
          isFullWidth ? "w-full" : ""
        }`}
      >
        <thead
          className={`bg-jutro-new-warm-gray-50 ${
            loading ? "h-9 animate-pulse bg-jutro-new-warm-gray-100" : ""
          }`}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({})}
              key={headerGroup.id}
              className="group font-caption rounded-t-lg border-x border-t border-jutro-new-warm-gray-200 px-2 py-3 text-left text-jutro-new-warm-gray-700"
            >
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} key={column.id}>
                  {column.render("Header")}
                </div>
              ))}
            </tr>
          ))}
        </thead>
        {loading ? (
          screen ? (
            <ScreenLoader />
          ) : (
            <div className="fixed left-9 top-0 grid h-screen w-screen place-content-center">
              <Loader screen />
            </div>
          )
        ) : (
          <>
            {rows.length > 0 ? (
              <div
                id={rowsId}
                className="divide-y divide-jutro-new-warm-gray-200 rounded-b-lg border-x border-b border-jutro-new-warm-gray-200 bg-white"
              >
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      key={row.id}
                      className={`${
                        onRowClick instanceof Function &&
                        "cursor-pointer hover:bg-slate-50"
                      }`}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <div
                            className="flex items-center overflow-hidden whitespace-nowrap px-2 py-3"
                            role="cell"
                            {...cell.getCellProps()}
                            key={index}
                            onClick={() => {
                              if (onRowClick instanceof Function)
                                onRowClick(row.original as RowOriginal, data);
                            }}
                          >
                            <div className="font-paragraph-2 w-full text-jutro-new-warm-gray-500">
                              {cell.render("Cell")}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center pl-4">
                <div className="font-paragraph-2 my-5 text-jutro-new-warm-gray-700">
                  Brak wyników
                </div>
              </div>
            )}
          </>
        )}
      </table>
    </>
  );
};

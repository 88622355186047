export const LeftEventTriangle = () => {
  return (
    <svg
      width="16"
      height="28"
      viewBox="0 0 16 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.29053 3.9932C6.72062 1.52182 9.35949 0 12.2148 0H15.5V28H12.2148C9.35949 28 6.72062 26.4782 5.29053 24.0068L1.81857 18.0068C0.38427 15.5281 0.384271 12.4719 1.81857 9.9932L5.29053 3.9932Z"
        fill="#F9F6F6"
      />
    </svg>
  );
};

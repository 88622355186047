import { BinIcon, DocumentIcon } from "@jutro/ui";
import { useState } from "react";
import { useDeleteMessageMutation } from "components/new/Chat/hooks";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { toaster } from "lib/tools/toaster";
import { AuthorImage, AuthorSection, MessageContainer } from "./components";

type FileMessageProps = {
  id?: string;
  mine: boolean;
  authorImage: string;
  authorName: string;
  messageTime: string;
  filename: string;
  extension: string;
  href: string;
  onClick?: (href: string) => void | any;
};

export const FileMessage = ({
  id,
  mine,
  authorImage,
  authorName,
  messageTime,
  filename,
  extension,
  href,
  onClick,
}: FileMessageProps) => {
  const [open, setOpen] = useState(false);
  const [removing, setRemoving] = useState(false);

  const { removeMessage } = useDeleteMessageMutation();

  return (
    <MessageContainer mine={mine}>
      <AuthorSection mine={mine}>
        <AuthorImage src={authorImage} />
        {authorName}
      </AuthorSection>
      <div className="rounded-lg bg-jutro-new-blue-500">
        <a
          className={`block rounded-lg no-underline ${
            mine
              ? "rounded-tr-none bg-jutro-new-blue-500"
              : "rounded-tl-none bg-jutro-new-warm-gray-50"
          }`}
          href={onClick ? undefined : href}
          download={filename}
          onClick={onClick ? () => onClick(href) : () => {}}
        >
          <div className="flex px-[10px] pb-1 pt-4">
            <div className={mine ? "text-white" : "text-jutro-new-blue-800"}>
              <DocumentIcon size="lg" />
            </div>
            <div className="flex flex-col overflow-hidden pl-3 text-left">
              <div
                className={`font-caption box-border w-full text-left ${
                  mine ? "text-white" : "text-jutro-new-blue-950"
                }`}
              >
                {filename}
              </div>
              <div
                className={`font-caption box-border w-full text-left ${
                  mine ? "text-white" : "text-jutro-new-blue-950"
                }`}
              >
                <span className="font-caption">{extension}</span>
              </div>
            </div>
          </div>
        </a>
        <div className="flex justify-between p-4 pr-2">
          <div
            className={`font-paragraph-2 text-white ${
              mine ? "text-left" : "text-right"
            }`}
          >
            {messageTime}
          </div>
          {mine && (
            <div
              className="h-4 w-4 cursor-pointer text-white hover:opacity-80"
              onClick={() => {
                if (removing) return;

                if (!id) {
                  toaster.notify("Zaczekaj aż wiadomość zostanie wysłana!");
                  return;
                }
                setOpen(true);
              }}
            >
              <BinIcon size="sm" />
            </div>
          )}
        </div>
      </div>
      <ConfirmDialog
        open={open}
        title="Usuwanie wiadomości"
        text="Czy na pewno chcesz usunąć wiadomość?"
        onConfirm={async () => {
          if (!id) {
            return;
          }

          try {
            setRemoving(true);
            toaster.notify("Usuwam wiadomość!");
            await removeMessage(id);
            toaster.success("Wiadomość usunięta!");
          } catch (e) {
            toaster.error("Nie udało się usunąć wiadomości");
          } finally {
            setRemoving(false);
          }
        }}
        setOpen={setOpen}
      />
    </MessageContainer>
  );
};

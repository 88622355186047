import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type Props = {
  imageUrl: string;
  crop: Crop;
  setCrop: any;
  dimensions: any;
};

export const ImageCropBlock = ({
  imageUrl,
  crop,
  setCrop,
  dimensions,
}: Props) => {
  const ratio = dimensions.width / dimensions.height;
  const getImageHeight = (): number => {
    if (dimensions.height < 600) return dimensions.height;
    return 600;
  };
  const getImageWidth = (): number => {
    if (dimensions.height < 600) return dimensions.width;
    return ratio * 600;
  };

  return (
    <div
      style={{
        display: "flex",
        maxWidth: `${getImageWidth()}px`,
        maxHeight: `${getImageHeight()}px`,
        margin: "15px auto",
        border: "1px solid #000",
      }}
    >
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCrop(c)}
        maxWidth={getImageWidth()}
        maxHeight={getImageHeight()}
      >
        <img src={imageUrl} />
      </ReactCrop>
    </div>
  );
};

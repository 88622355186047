import { useDoctorsSelectLazyQuery } from "lib/graphql/megaSchema";

export const useAsyncDoctorSelectOptions = () => {
  const [getDoctors] = useDoctorsSelectLazyQuery();

  const loadDoctorsOptions = async (search: string) => {
    const response = await getDoctors();

    if (!response.data || response.data.doctorDoctors.length === 0) {
      return [];
    }

    const { data } = response;
    const { doctorDoctors } = data;

    const doctorsOnly = doctorDoctors.filter(
      (doctor) => doctor.genre === "DOCTOR",
    );

    const xx = doctorsOnly.filter(
      (doctor) =>
        doctor.firstName?.toLowerCase().includes(search.trim().toLowerCase()) ||
        doctor.lastName?.toLowerCase().includes(search.trim().toLowerCase()),
    );

    const formattedDoctorsOption = xx.map((doctor) => {
      return {
        label: `${doctor.firstName} ${doctor.lastName}`,
        value: doctor.id,
      };
    });

    return formattedDoctorsOption;
  };

  return {
    loadDoctorsOptions,
  };
};

import {
  AddCircleIcon,
  Button,
  CircleLoaderIcon,
  RefreshIcon,
} from "@jutro/ui";
import dayjs from "dayjs";
import { PopupMenu } from "components/new/PopupMenu";
import { insuranceStatusTextMap } from "lib/hooks/usePatientInsuranceStatus/tools";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { getInsuranceColorAndIcon } from "views/Patient/Profile/EwusStatus/tools";
import { useEwusStatus } from "./useEwusStatus";

export const EwusStatus = () => {
  const {
    insuranceStatus,
    isEwusLoading,
    isVojevodShipMenuOpen,
    setIsVojevodShipMenuOpen,
    vojevodShipRef,
    vojevodShipMenuItems,
    latestCreatedAt,
    ewusStatusLoading,
  } = useEwusStatus();

  const text = insuranceStatusTextMap[insuranceStatus];
  const { color, icon, bgColor } = getInsuranceColorAndIcon(insuranceStatus);

  const { addTile } = useTileSystem();

  return (
    <div className="grid gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between gap-2">
        <h3 className="font-label">Status ubezpieczenia</h3>

        <div className="flex justify-end gap-1">
          <div className="relative" ref={vojevodShipRef}>
            <Button
              full={false}
              onClick={() => {
                setIsVojevodShipMenuOpen(true);
              }}
              text="Sprawdź"
              icon={<RefreshIcon />}
              size="condensed"
              variant="secondary"
              loading={isEwusLoading}
            />

            <PopupMenu
              position="bottom-right"
              items={vojevodShipMenuItems}
              active={isVojevodShipMenuOpen}
            />
          </div>

          <Button
            full={false}
            onClick={() => addTile("patient-ewus-statement", TileColumn.Right)}
            text="Oświadczenie"
            icon={<AddCircleIcon />}
            variant="secondary"
            size="condensed"
          />
        </div>
      </div>

      {ewusStatusLoading ? (
        <div className="mx-auto w-fit animate-spin">
          <CircleLoaderIcon />
        </div>
      ) : (
        <div
          className={`font-paragraph-2 flex items-center justify-between rounded-md p-3 ${bgColor}`}
        >
          <div className={`flex items-center gap-1 ${color}`}>
            <div>{icon}</div>
            <div>{text}</div>
          </div>

          <p>
            Sprawdzono:{" "}
            {latestCreatedAt
              ? dayjs(latestCreatedAt).format("DD.MM.YYYY HH:mm")
              : "-"}
          </p>
        </div>
      )}
    </div>
  );
};

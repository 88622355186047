import {
  Avatar,
  BinIcon,
  Button,
  CalendarIcon,
  CircleCheckIcon,
  LocationIcon,
  PencilIcon,
  Tag,
} from "@jutro/ui";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useState } from "react";
import { VisitCreatedByInfo } from "components/VisitCard/VisitCreatedByInfo";
import { VisitTags } from "components/VisitCard/VisitTags";
import {
  getDate,
  getDoctorRole,
  getPlaceName,
  getVisitTags,
} from "components/VisitCard/tools";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorHistoryVisitsDocument,
  DoctorUpcomingVisitsDocument,
  DoctorUpcomingVisitsQuery,
  useDoctorDeleteVisitMutation,
  useDoctorPatientShowedUpForAVisitMutation,
} from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { avatarConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import { isVisitScheduled } from "lib/tools/visit";

type Props = {
  visit: DoctorUpcomingVisitsQuery["doctorUpcomingVisits"][0];
  withActions?: boolean;
};

export const VisitCard = ({ visit, withActions = true }: Props) => {
  const {
    id,
    doctor,
    organizationId,
    type,
    plannedStart,
    showOn,
    status,
    snapshotAt,
  } = visit;

  const [user] = useAtom(currentUserAtom);

  const [deleteVisitModalOpen, setDeleteVisitModalOpen] = useState(false);

  const { addTile } = useTileSystem();

  const { organizations } = useVojevodshipOptions();

  const [deleteVisitMutation] = useDoctorDeleteVisitMutation({
    variables: { id },
    refetchQueries: [DoctorUpcomingVisitsDocument, DoctorHistoryVisitsDocument],
  });

  const [patientShowedUpForAVisitMutation] =
    useDoctorPatientShowedUpForAVisitMutation({
      variables: { id },
      refetchQueries: [
        DoctorUpcomingVisitsDocument,
        DoctorHistoryVisitsDocument,
      ],
    });

  const tags = getVisitTags({ visit });

  const placeName = getPlaceName({
    organizations,
    organizationId,
  });

  const date = getDate(plannedStart?.iso);

  const doctorRole = getDoctorRole({
    genre: doctor?.genre,
    npwz: doctor?.npwz,
  });

  const patientShowedUpForAVisit = async () => {
    await toaster.promise(patientShowedUpForAVisitMutation(), {
      loading: "Oznaczam, że pacjent przyszedł",
      success: "Oznaczono, że pacjent przyszedł",
      error: "Wystąpił błąd",
    });
  };

  const handleDeleteVisit = async () => {
    await toaster.promise(deleteVisitMutation(), {
      loading: "Usuwam wizytę",
      success: "Poprawnie usunięto wizytę",
      error: "Wystąpił błąd",
    });

    setDeleteVisitModalOpen(false);
  };

  const isCurrentGenreAllowedToEdit =
    user?.genre === "ASSISTANT" || user?.genre === "NURSE";

  const shouldShowActionButtons =
    withActions &&
    status === "DRAFT" &&
    isVisitScheduled(plannedStart?.iso) &&
    isCurrentGenreAllowedToEdit;

  const shouldShowConfirmVisitButton = !showOn && shouldShowActionButtons;

  return (
    <div
      className={`mx-auto grid w-full gap-2 rounded-lg p-4 ${status !== "DRAFT" || !isVisitScheduled(plannedStart?.iso) ? "bg-jutro-new-warm-gray-50" : "bg-jutro-new-blue-50"}`}
    >
      <div className="flex gap-2">
        <div className="flex grow gap-2">
          <div>
            <Avatar
              type="photo"
              imgSrc={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${doctor?.id}`}
              size="sm"
            />
          </div>

          <span className="font-heading-4 shrink-0">{`${doctor?.firstName} ${doctor?.lastName}`}</span>
          <span className="font-paragraph-1 line-clamp-1 overflow-hidden text-ellipsis">
            {doctorRole}
          </span>
        </div>

        {shouldShowActionButtons && (
          <div
            className="flex justify-end gap-1 text-nowrap"
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              label="Edytuj wizytę"
              tooltipPosition="top"
              icon={<PencilIcon />}
              size="condensed"
              variant="ghost"
              onClick={() => {
                addTile(
                  {
                    id: visit.id,
                    name: "schedule-visit-form",
                    data: {
                      id: visit.id,
                      mode: "edit",
                      patientId: visit.patient?.id ?? "",
                      visitData: visit,
                    },
                  },
                  TileColumn.Right,
                );
              }}
            />

            <Button
              label="Usuń wizytę"
              tooltipPosition="top"
              icon={<BinIcon />}
              size="condensed"
              variant="negativeGhost"
              onClick={() => setDeleteVisitModalOpen(true)}
            />
          </div>
        )}

        {status === "SUCCESS" && snapshotAt && (
          <div className="font-paragraph-2">
            {dayjs.unix(Number(snapshotAt) / 1000).format("D MMM YYYY")}
          </div>
        )}
      </div>

      <div className="flex gap-1">
        {tags.map((tag, index) => (
          <Tag key={tag.text + index} {...tag} />
        ))}
      </div>

      {date && (
        <div className="font-paragraph-1 flex items-center gap-2">
          <CalendarIcon size="sm" />
          <span>{date}</span>
        </div>
      )}

      {placeName && (
        <div className="font-paragraph-1 flex items-center gap-2">
          <LocationIcon size="sm" />
          <span>{placeName}</span>
        </div>
      )}

      {status === "SUCCESS" && <VisitTags visit={visit} />}

      <VisitCreatedByInfo
        medicId={visit.createdBy}
        createdAt={visit.createdAt.iso}
      />

      {type !== "VIRTUAL" && shouldShowConfirmVisitButton && (
        <Button
          text="Potwierdź obecność"
          icon={<CircleCheckIcon />}
          variant="secondary"
          onClick={async () => await patientShowedUpForAVisit()}
        />
      )}

      <ConfirmDialog
        open={deleteVisitModalOpen}
        title="Usuwanie wizyty"
        text="Czy na pewno chcesz usunąć wizytę?"
        setOpen={setDeleteVisitModalOpen}
        onConfirm={handleDeleteVisit}
      />
    </div>
  );
};

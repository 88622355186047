import { BinIcon, Button, EyeOnIcon } from "@jutro/ui";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import ReactModal from "react-modal";
import { Loader } from "components/new";
import { PdfPreview } from "components/new/PdfPreview/PdfPreview";
import { imagePreviewAtom } from "lib/atoms";
import {
  DoctorProceduresDocument,
  useDoctorUpdateProcedureMutation,
} from "lib/graphql/megaSchema";
import { fileConfig } from "lib/tools/httpConfigs";
import { uploadFile } from "lib/tools/uploadFile";

type Props = {
  procedureFiles: string[];
  procedureId: string;
  disabled?: boolean;
};

export const ProcedureElementFiles = ({
  procedureFiles,
  procedureId,
  disabled,
}: Props) => {
  const methods = useFormContext();

  const fileError = methods.formState.errors["file"];

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ disabled });
  const [updateProcedure] = useDoctorUpdateProcedureMutation();
  const [fileLoading, setFileLoading] = useState(false);
  const [, setImagePreview] = useAtom(imagePreviewAtom);

  const [pdfPreviewFile, setPdfPreviewFile] = useState("");

  const addFiles = async () => {
    setFileLoading(true);

    const temp = () => {
      return Promise.all(
        acceptedFiles.map(async (file) => await uploadFile(file)),
      );
    };

    const file = [...procedureFiles, ...((await temp()) as string[])];

    await updateProcedure({
      variables: {
        procedureId,
        dataInput: {
          file,
        },
      },
      refetchQueries: [DoctorProceduresDocument],
    });

    methods.setValue("file", file);
    methods.clearErrors("file");
    setFileLoading(false);
  };

  const isPdf = (name: string) =>
    name.slice(name.length - 3).toLowerCase() === "pdf";

  useEffect(() => {
    if (acceptedFiles.length) {
      addFiles();
    }
  }, [acceptedFiles]);

  return (
    <>
      <div className="flex flex-col gap-2 break-all">
        {procedureFiles.length
          ? procedureFiles.map((name, index) => (
              <div
                className="flex h-10 items-center justify-between rounded-lg border border-jutro-new-warm-gray-300 px-2"
                key={name + index}
              >
                <span className="font-paragraph-2 py-2 text-jutro-new-warm-gray-800">
                  {name}
                </span>
                <div className="flex gap-2">
                  <Button
                    full={false}
                    onClick={() => {
                      if (isPdf(name)) {
                        setPdfPreviewFile(name);
                        return;
                      }

                      setImagePreview(
                        `${fileConfig[envVars.REACT_APP_CONFIG]}${name}`,
                      );
                    }}
                    icon={<EyeOnIcon />}
                    label="Podgląd"
                    tooltipPosition="top"
                    size="condensed"
                    variant="ghost"
                  />

                  <Button
                    full={false}
                    onClick={async () => {
                      if (disabled) {
                        return;
                      }

                      const file = procedureFiles.filter(
                        (file) => file !== name,
                      );

                      await updateProcedure({
                        variables: {
                          procedureId,
                          dataInput: {
                            file,
                          },
                        },
                        refetchQueries: [DoctorProceduresDocument],
                      });

                      methods.setValue("file", file);
                    }}
                    icon={<BinIcon />}
                    label="Usuń"
                    tooltipPosition="top"
                    size="condensed"
                    variant="negativeGhost"
                  />
                </div>
              </div>
            ))
          : null}

        <div {...getRootProps()}>
          <div
            className={`rounded-lg border ${
              fileError
                ? "border-jutro-new-rose-600"
                : "border-jutro-new-warm-gray-500"
            } `}
          >
            <div className="flex h-10 items-center justify-center gap-1">
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="font-paragraph-2">Upuść plik ...</p>
              ) : (
                <>
                  {fileLoading ? (
                    <Loader size="xs" />
                  ) : (
                    <div className="flex items-center justify-center gap-1">
                      <span className="font-paragraph-2">
                        Przeciągnij i upuść lub{" "}
                      </span>
                      <input
                        type="button"
                        className={`text-xs ${
                          disabled
                            ? "text-jutro-new-warm-gray-300"
                            : "text-jutro-new-green-400"
                        } inline cursor-pointer bg-white`}
                        value="wybierz z dysku"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={Boolean(pdfPreviewFile)}
        ariaHideApp={false}
        className="flex h-[80%] w-[600px] flex-col items-center justify-center gap-4 rounded-lg bg-white p-4"
        overlayClassName={
          "fixed w-full h-screen top-0 left-0 z-[1000] bg-black bg-opacity-60 flex justify-center items-center"
        }
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={() => {
          setPdfPreviewFile("");
        }}
      >
        <PdfPreview
          file={`${fileConfig[envVars.REACT_APP_CONFIG]}${pdfPreviewFile}`}
        />
      </ReactModal>
    </>
  );
};

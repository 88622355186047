import { Button, Input } from "@jutro/ui";
import { useAtom } from "jotai";
import { useState } from "react";
import * as yup from "yup";
import { FileInput } from "components/components/Doctor/FileInput";
import { SideSheet } from "components/new/Drawer";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSDoctorInput,
  DoctorMeDocument,
  useDoctorUpdateMeMutation,
} from "lib/graphql/megaSchema";
import { useForm } from "lib/hooks/useForm";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";

type Props = {
  open: boolean;
  setClose: () => void;
};

const schema = yup.object({
  zusPass: yup.string().required(),
  zusCert: yup.string().required(),
});

export const DoctorProfileCertificate = ({ open, setClose }: Props) => {
  const { register, data, utils } = useForm({ schema });
  const zusCertRegister = register("zusCert");
  const [zusCertFile, setZusCertFile] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [saveUserMutation, { loading, called }] = useDoctorUpdateMeMutation();
  const [currentUser] = useAtom(currentUserAtom);

  const saveData = (input: DoctorMSDoctorInput) => {
    if (!currentUser?.id) return;
    saveUserMutation({
      variables: {
        id: currentUser.id,
        input,
      },
      refetchQueries: [DoctorMeDocument],
    });
    toaster.notify("Zapisano!");
  };

  const uploadCert = async (zusCert: string | Blob) => {
    if (!zusCert) return;
    setIsUploadingFile(true);
    const response = await uploadFile(zusCert);
    setIsUploadingFile(false);
    setZusCertFile(response);
  };

  const uploadZusCert = () => {
    if (!zusCertFile) return;
    saveData({
      zusCert: zusCertFile,
      zusPass: data.zusPass,
    });
  };

  const handleOnClose = () => {
    utils({ reset: true });
    setClose();
  };

  const { value, onChange, errors } = register("zusPass");

  return (
    <SideSheet
      isShown={open}
      onCloseComplete={handleOnClose}
      width={window.innerWidth / 3 - 32}
    >
      <section className="m-8 text-jutro-new-warm-gray-700">
        <div className="flex flex-col gap-6 text-jutro-new-warm-gray-500">
          <h2 className="font-display-3 text-jutro-purple-monochrome">
            Certyfikacja profilu ZUS PUE
          </h2>
          <p className="font-paragraph-2">
            Dzięki wgraniu do systemu Jutro Medical certyfikatu ZUS nie musisz
            autoryzować się przy wystawianiu każdego kolejnego zwolnienia
            lekarskiego. Certyfikat oraz hasło są szyfrowane, a więc tym samym
            bezpieczne.
          </p>
          <div className="flex flex-col gap-1">
            <label htmlFor="zusCert">Twój certyfikat ZUS PUE</label>
            <FileInput
              id="zusCert"
              fileRegister={{ ...zusCertRegister, onChange: uploadCert }}
              plainFile
            />
          </div>
          <div className="mb-3 flex flex-col gap-1">
            <Input
              label="Twoje hasło ZUS PUE"
              placeholder="Kliknij, aby dodać..."
              type="password"
              value={value}
              onChange={onChange}
              error={errors}
            />
          </div>
          <Button
            disabled={!zusCertFile || !data.zusPass}
            onClick={uploadZusCert}
            loading={isUploadingFile || (loading && called)}
            text="Zapisz"
          />
        </div>
      </section>
    </SideSheet>
  );
};

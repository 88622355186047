import { MessageElement } from "components/new/Chat/Message/Message";
import { Maybe } from "lib/types";
import { getReferralTypeDescription } from "./getTypeDescription";

export const getReferralItems = (
  referral: Exclude<MessageElement["referral"], null | undefined>
): { title: string; description: Maybe<string> }[] => {
  const {
    type,
    clinic,
    referralDetails,
    icd10,
    icd9,
    comment,
    examinationType,
    adviceGoal,
  } = referral;

  const typeDescription = getReferralTypeDescription(type);

  return [
    {
      title: "Rodzaj skierowania",
      description: typeDescription,
    },
    {
      title: "Skierowanie do",
      description: clinic?.name,
    },
    {
      title: "Typ badania",
      description: examinationType,
    },
    {
      title: "Kod eSkierowania",
      description: referralDetails.code,
    },
    {
      title: "Rozpoznanie główne",
      description: `${icd10?.icd10 ?? "-"} ${icd10?.name ?? "-"}`,
    },
    {
      title: "Procedura",
      description: `${icd9?.icd9 ?? "-"} ${icd9?.name ?? "-"}`,
    },
    {
      title: "Komentarz",
      description: comment,
    },
    {
      title: "Cel badania",
      description: adviceGoal,
    },
  ];
};

import { Button, ChatIcon } from "@jutro/ui";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { useVisitData } from "views/Visit/hooks";

export const ShowVirtualAssistantChatButton = () => {
  const { addTile, getTileInfo } = useTileSystem();
  const { data: visit } = useVisitData({ from: "cache-only" });

  const isVirtualAssistantChatTileCenter =
    getTileInfo("virtual-assistant-chat").position === TileColumn.Center;

  if (!visit?.preInterview || visit.preInterview.length === 0) {
    return null;
  }

  return (
    <Button
      full={false}
      variant="secondary"
      type="button"
      onClick={() => {
        addTile("virtual-assistant-chat", TileColumn.Center);
      }}
      disabled={isVirtualAssistantChatTileCenter}
      size="condensed"
      icon={<ChatIcon />}
      text="Pokaż rozmowę z wirtualnym asystentem"
    />
  );
};

import { Option } from "@jutro/types";
import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";
import { atomWithLocation } from "jotai-location";
import {
  VisitArchiveFilters,
  visitsArchiveFiltersOptions,
} from "views/Visits/Archive/tools";

const locationAtom = atomWithLocation();
export const visitsArchiveTotalPagesAtom = atom(0);

type Action<T> = T | ((prev: T) => T);

type SearchParamsAtomArgs<T> = {
  key: string;
  defaultValue: T;
  parse?: (value: string) => T;
  serialize?: (value: T) => string;
  clearPagination?: boolean;
};

const searchParamAtom = <T>({
  key,
  defaultValue,
  parse = (value) => value as T,
  serialize = (value) => value as string,
  clearPagination = true,
}: SearchParamsAtomArgs<T>) =>
  atom(
    (get) => {
      const searchParams = get(locationAtom).searchParams;
      const searchParamValue =
        searchParams?.get(key) ?? serialize(defaultValue);

      return parse(searchParamValue);
    },
    (get, set, action: Action<T>) => {
      const location = get(locationAtom);
      const searchParams = new URLSearchParams(location.searchParams);

      const currentValue = get(
        searchParamAtom({
          key,
          defaultValue,
          parse,
          serialize,
          clearPagination,
        }),
      );

      const newValue =
        typeof action === "function"
          ? (action as (prev: T) => T)(currentValue)
          : action;

      const serializedNewValue = serialize(newValue);

      if (serializedNewValue) {
        searchParams.set(key, serialize(newValue));
      } else {
        searchParams.delete(key);
      }

      if (clearPagination) {
        searchParams.delete("currentPage");
        set(visitsArchiveTotalPagesAtom, 0);
      }

      set(locationAtom, { ...location, searchParams });
    },
  );

export const visitsArchiveDateAtom = searchParamAtom<Dayjs>({
  key: "date",
  defaultValue: dayjs().startOf("month"),
  parse: (value) => dayjs(value),
  serialize: (value) => value.format("YYYY-MM-DD"),
});

export const visitsArchiveVojevodshipIdAtom = searchParamAtom<string>({
  key: "vojevodshipId",
  defaultValue: "P",
});

export const visitsArchiveSearchAtom = searchParamAtom<string>({
  key: "search",
  defaultValue: "",
});

export const visitsArchiveFiltersAtom = searchParamAtom<
  Option<VisitArchiveFilters>[]
>({
  key: "filters",
  defaultValue: [],
  parse: (value) =>
    value
      .split(",")
      .map((el) =>
        visitsArchiveFiltersOptions.find(({ value }) => value === el),
      )
      .filter(Boolean) as Option<VisitArchiveFilters>[],
  serialize: (value) => value.map(({ value }) => value).join(","),
});

export const visitsArchiveCurrentPageAtom = searchParamAtom<number>({
  key: "page",
  defaultValue: 1,
  parse: (value) => Number(value),
  serialize: (value) => value.toString(),
  clearPagination: false,
});

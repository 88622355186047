import { handleEmoji } from "./tools";

type Props = {
  content: string;
  isPreview?: boolean;
  fromSlack?: boolean;
};

const IMAGE_EXTENSIONS = [".jpeg", ".jpg", ".png"];

export const MessageContent = ({
  content,
  isPreview = false,
  fromSlack = false,
}: Props) => {
  const isLinkFromSlack =
    content[0] === "<" && content[content.length - 1] === ">";

  content = isLinkFromSlack ? content.slice(1, content.length - 1) : content;

  const isUrl = content.startsWith("http");
  const isImage = IMAGE_EXTENSIONS.some((e) => content.endsWith(e));

  if (isUrl) {
    if (isImage) {
      if (isPreview) {
        return (
          <>
            [{isLinkFromSlack ? "zdjęcie ze slacka" : "zdjęcie od użytkownika"}]
          </>
        );
      }

      return <img className="w-full rounded-lg object-contain" src={content} />;
    }

    if (isPreview) {
      return (
        <>[{isLinkFromSlack ? "link ze slacka" : "link od użytkownika"}]</>
      );
    }

    return (
      <a
        href={handleWWW(content)}
        className="underline transition-opacity duration-200 ease-in-out hover:opacity-60"
      >
        {fromSlack ? "Slack: " : ""}
        {handleWWW(content)}
      </a>
    );
  }

  return (
    <span className={isPreview ? "inline-block max-w-[220px] truncate" : ""}>
      {handleEmoji(content)}
    </span>
  );
};

const handleWWW = (content: string) => {
  const hasWWW = content.match("www.");
  if (!hasWWW) return content;
  return content.split("|")[0];
};

import { SolidCircleAlertIcon } from "@jutro/ui";
import { DoctorMSAddress } from "lib/graphql/megaSchema";

type Props = {
  legalGuardianData:
    | {
        address: DoctorMSAddress | null;
        email?: string | null;
        patientCardNumber: string;
        phone: string;
        firstName: string;
        lastName: string;
      }
    | null
    | undefined;
};

export const LegalGuardianDetails = ({ legalGuardianData }: Props) => {
  if (!legalGuardianData) {
    return null;
  }

  if (!legalGuardianData.address) {
    return (
      <div className="flex items-center gap-1 text-jutro-new-rose-600">
        <SolidCircleAlertIcon size="sm" />
        <p className="font-label text-jutro-new-rose-800">
          Brak adresu pacjenta
        </p>
      </div>
    );
  }

  return (
    <div className="font-paragraph-2 flex w-fit flex-col rounded-lg bg-jutro-new-warm-gray-50 p-4">
      <span>{`${legalGuardianData.firstName} ${legalGuardianData.lastName} (${
        legalGuardianData.patientCardNumber.slice(0, 3) +
        "-" +
        legalGuardianData.patientCardNumber.slice(3)
      })`}</span>
      <span>{`${legalGuardianData.address.street} ${
        legalGuardianData.address.houseNumber
      } ${
        legalGuardianData.address.flatNumber
          ? ` / ${legalGuardianData.address.flatNumber}`
          : ""
      }`}</span>
      <span>{`${legalGuardianData.address.zipCode} ${legalGuardianData.address.city}`}</span>
      <span>{legalGuardianData.phone}</span>
      {legalGuardianData.email && <span>{legalGuardianData.email}</span>}
    </div>
  );
};

export const extractChatMessageComponents = (
  content: string | null | undefined
) => {
  if (!content) {
    return { files: [], message: undefined };
  }
  let m = content;

  if (!m.startsWith("[file-")) {
    return { message: m };
  }
  m = m.substring(6, m.length - 1);

  if (!m.startsWith("<")) {
    return {
      files: [m],
    };
  }

  const files: string[] = [];

  while (true) {
    const fileStartBracket = m.indexOf("<");
    const fileEndBracket = m.indexOf(">");
    if (fileStartBracket === -1 || fileEndBracket === -1) {
      break;
    }
    const quoteIndex = m.indexOf('"');
    if (quoteIndex !== -1 && quoteIndex < fileEndBracket) {
      break;
    }

    files.push(m.substring(fileStartBracket + 1, fileEndBracket));
    m = m.substring(fileEndBracket + 1, m.length);
  }

  const message = m.substring(1, m.length - 1);

  return {
    files,
    message: message.length > 0 ? message : undefined,
  };
};

import { Button, EyeOffIcon, EyeOnIcon } from "@jutro/ui";
import { ReactNode } from "react";
import { VariantProps, tv } from "tailwind-variants";

const getStyles = tv({
  base: "flex items-center gap-4 rounded-lg bg-white p-2 pr-4 text-jutro-new-warm-gray-500",
  variants: {
    active: {
      true: "bg-jutro-new-warm-gray-50 text-jutro-new-warm-gray-800",
    },
  },
  defaultVariants: {
    active: true,
  },
});

type RightElementProps =
  | { rightElement: ReactNode }
  | { toggleActive: () => void; disabled?: boolean };

type Variants = VariantProps<typeof getStyles>;

type Props = {
  leftElement: ReactNode;
} & RightElementProps &
  Variants;

export const IpomListItem = (props: Props) => {
  const { leftElement, active } = props;

  const withCustomRightElement = "rightElement" in props;

  return (
    <div className={getStyles({ active })}>
      <div className="font-paragraph-1 grow">{leftElement}</div>

      {withCustomRightElement ? (
        props.rightElement
      ) : (
        <Button
          variant="ghost"
          size="condensed"
          icon={active ? <EyeOnIcon /> : <EyeOffIcon />}
          label={active ? "Załączone w IPOMie" : "Ukryte w IPOMie"}
          tooltipPosition="top"
          type="button"
          full={false}
          disabled={props.disabled}
          onClick={() => props.toggleActive()}
        />
      )}
    </div>
  );
};

import { ChannelName } from "./types";

export const OUTERCOM_URL = "https://outercom.jutromedical.com/outercom";

export const channelNameMap: Record<ChannelName, string> = {
  "outercom-legal": "Prawo",
  "outercom-technology": "Technologia",
  "outercom-helpdesk": "Sprzęt",
  "outercom-medical": "Medyczne",
  "outercom-hr": "Kadrowe",
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDoctorNextPatientLazyQuery } from "lib/graphql/megaSchema";
import { Patients, useNextPatients } from "lib/hooks/useNextPatients";

const countPendingVisits = (patients: Patients) => {
  return patients.mineAwaitingResponse + patients.othersAwaitingResponse;
};

export const useHome = () => {
  const navigate = useNavigate();
  const [none, setNone] = useState(false);
  const [start, setStart] = useState(false);

  const [fetchNextPatientData, { data: nextPatientData }] =
    useDoctorNextPatientLazyQuery({
      fetchPolicy: "no-cache",
      pollInterval: 30 * 1000,
    });

  const { patients, loading } = useNextPatients();

  useEffect(() => {
    if (start) fetchNextPatientData();
  }, [start]);

  useEffect(() => {
    if (!nextPatientData || !start) {
      return;
    }

    if (nextPatientData.doctorNextPatient === "none") {
      if (!none) setNone(true);
      return;
    }

    navigate(`/patients/${nextPatientData.doctorNextPatient}/chat`, {
      replace: true,
    });
  }, [none, nextPatientData, start, navigate]);

  return {
    pendingVisitsCount: loading ? "..." : countPendingVisits(patients),
    none,
    start,
    setStart,
  };
};

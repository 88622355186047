import { MessageHeader } from "components/NewMessageUtils/MessageHeader";
import { procedureTitleMap } from "lib/tools/procedureTitleMap";
import { useExaminationPanelMessage } from "./useExaminationPanelMessage";

export const ProcedureMessage = ({ content }: { content: string }) => {
  const { procedure } = useExaminationPanelMessage(content);

  if (!procedure || procedure.type === "QUALIFICATION_FOR_VACCINATION")
    return null;

  return (
    <div className="calcContainer font-geologica relative mb-5 ml-0">
      <MessageHeader mine={false} />
      <div className="rounded-lg rounded-tl-none bg-jutro-new-warm-gray-50">
        <div className="font-paragraph-2 whitespace-pre-line break-words px-2 py-3 text-jutro-new-blue-950">
          Wysłano zlecenie na:{" "}
          <span className="font-bold">{procedureTitleMap[procedure.type]}</span>
        </div>
      </div>
    </div>
  );
};

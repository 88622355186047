import { Button } from "@jutro/ui";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactSelect from "react-select";
import { customSelectStyles } from "components/PatientDocs/Ordered/utils";
import {
  calculateHaversineDistance,
  immunizationCardStatusOptions,
} from "components/PatientDocs/tools";
import {
  ImmunizationCardStatus,
  ImmunizationCardStatusValue,
} from "components/PatientDocs/types";
import {
  DoctorMSFullAddress,
  DoctorPatientProfileVisitDocument,
  useDoctorUpdatePatientMutation,
} from "lib/graphql/megaSchema";
import { organizationClinicOption } from "lib/hooks/useVojevodshipOptions";
import { toaster } from "lib/tools/toaster";

const getImmunizationStatus = (
  immunizationStatus: ImmunizationCardStatusValue | undefined,
  organizationId: string | undefined,
) => {
  if (!immunizationStatus) {
    return;
  }

  if (
    immunizationStatus === "SYSTEM" ||
    immunizationStatus === "OUTSIDE_SYSTEM"
  ) {
    return immunizationStatus;
  }

  return organizationId;
};

type Props = {
  isModalOpen: boolean;
  immunizationCardStatus: string;
  immunizationCardStatusComment?: string | null;
  organizationsClinicsOptions: organizationClinicOption[];
  patientAddress?: DoctorMSFullAddress | null;
  patientId: string;
  onClose: () => void;
};

export const ImmunizationCardStatusModal = ({
  isModalOpen,
  immunizationCardStatus,
  immunizationCardStatusComment,
  organizationsClinicsOptions,
  patientAddress,
  patientId,
  onClose,
}: Props) => {
  const immunizationCardStatusDefaultValue = immunizationCardStatusOptions.find(
    (o) => o.value === immunizationCardStatus,
  );

  const organizationIdDefaultValue = organizationsClinicsOptions.find(
    (o) => o.value === immunizationCardStatus,
  );

  const [immunizationCardStatusOption, setImmunizationCardStatusOption] =
    useState<ImmunizationCardStatus | null>(
      immunizationCardStatusDefaultValue ?? {
        label: "Na stanie",
        value: "CLINIC",
      },
    );
  const [organizationId, setOrganizationId] =
    useState<organizationClinicOption | null>(
      organizationIdDefaultValue ?? null,
    );
  const [comment, setComment] = useState<string | null | undefined>(
    immunizationCardStatusComment,
  );

  const [updatePatient, { loading: updatePatientLoading }] =
    useDoctorUpdatePatientMutation({
      variables: {
        id: patientId,
        input: {
          immunizationCardStatus: getImmunizationStatus(
            immunizationCardStatusOption?.value,
            organizationId?.value,
          ),
          immunizationCardComment:
            immunizationCardStatusOption?.value === "OUTSIDE_SYSTEM"
              ? comment
              : null,
        },
      },
      refetchQueries: [DoctorPatientProfileVisitDocument],
    });

  const updateStatus = async () => {
    await toaster.promise(
      updatePatient(),
      {
        error: "Nie udało się zaktualizować statusu karty uodpornienia",
        loading: "Aktualizuję status karty uodpornienia",
        success: "Zaktualizowano status karty uodpornienia!",
      },
      { style: { maxWidth: 370 } },
    );

    onClose();
    resetModal();
  };

  useEffect(() => {
    resetModal();
  }, [immunizationCardStatus]);

  const patientLat = patientAddress?.latitude ?? 0;
  const patientLong = patientAddress?.longitude ?? 0;

  const distancesToClinics = organizationsClinicsOptions.map((clinic) => {
    const distance = calculateHaversineDistance(
      patientLat,
      patientLong,
      clinic.latitude,
      clinic.longitude,
    );

    return { ...clinic, distance };
  });

  const lowestDistanceOption = distancesToClinics.reduce(
    (min, current) => (current.distance < min.distance ? current : min),
    distancesToClinics[0],
  );

  lowestDistanceOption.label += " - najbliższa";

  const organizationsSortedByDistance = distancesToClinics.sort((a, b) => {
    const x = a.distance;
    const y = b.distance;
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  });

  const resetModal = () => {
    setImmunizationCardStatusOption(
      immunizationCardStatusDefaultValue ?? {
        label: "Na stanie",
        value: "CLINIC",
      },
    );
    setOrganizationId(organizationIdDefaultValue ?? null);
    setComment(comment);
  };

  return (
    <ReactModal
      isOpen={isModalOpen}
      shouldCloseOnEsc
      className="font-paragraph-2 !z-50 flex h-full w-full flex-col items-center justify-center gap-2 text-jutro-new-warm-gray-800"
      ariaHideApp={false}
      style={{
        overlay: { zIndex: 50, backgroundColor: "rgba(24, 24, 26, 0.5)" },
      }}
    >
      <div className="flex w-[420px] flex-col gap-[10px] rounded-lg bg-white p-4">
        <div className="font-geologica font-heading-4">
          <h5>Karta uodpornienia</h5>
        </div>

        <div>
          <ReactSelect
            placeholder="Wybierz status"
            options={immunizationCardStatusOptions}
            styles={customSelectStyles<ImmunizationCardStatusValue>()}
            value={immunizationCardStatusOption}
            onChange={(option) => {
              if (!option) {
                return;
              }
              setImmunizationCardStatusOption(option);
            }}
            isSearchable={false}
          />
        </div>

        {immunizationCardStatusOption?.value === "CLINIC" && (
          <div>
            <ReactSelect
              placeholder="Wybierz placówkę Jutro Medical"
              options={organizationsSortedByDistance}
              styles={customSelectStyles<string>()}
              value={organizationId}
              isSearchable={false}
              onChange={(option) => {
                if (!option) {
                  return;
                }
                setOrganizationId(option);
              }}
            />
          </div>
        )}

        {immunizationCardStatusOption?.value === "OUTSIDE_SYSTEM" && (
          <div>
            <div>
              <textarea
                cols={30}
                rows={3}
                placeholder="Komentarz"
                value={comment ?? ""}
                onChange={(event) => setComment(event.target.value)}
                className="font-geologica font-paragraph-2 w-full rounded-lg border-[1px] border-jutro-new-warm-gray-100 p-2 text-jutro-new-warm-gray-800 outline-none placeholder:text-xs placeholder:font-normal placeholder:text-jutro-new-warm-gray-300 hover:border-jutro-new-blue-800 focus:border-[1px] focus:border-jutro-new-blue-800"
              />
            </div>
          </div>
        )}

        <div className="flex justify-end gap-2">
          <Button
            full={false}
            onClick={() => {
              onClose();
              resetModal();
            }}
            text="Wróć"
            size="condensed"
            variant="ghost"
          />
          <Button
            full={false}
            onClick={updateStatus}
            text="Zmień"
            size="condensed"
            loading={updatePatientLoading}
            disabled={
              immunizationCardStatusOption?.value === "CLINIC" &&
              !organizationId
            }
          />
        </div>
      </div>
    </ReactModal>
  );
};

import { MessageBox } from "@jutro/ui";
import { Dispatch, SetStateAction } from "react";
import { languageToEmojiMap } from "components/ScheduleVisitForm/tools/languageToEmojiMap";
import { returnDisplayTime } from "components/ScheduleVisitForm/tools/returnDisplayTime";
import { Loader } from "components/new";
import { DoctorGetSlotsQuery } from "lib/graphql/megaSchema";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";

type SlotsListType = {
  slots: DoctorGetSlotsQuery["doctorGetSlots"];
  loading: boolean;
  chosenSlotId: number | null;
  setChosenSlotId: Dispatch<SetStateAction<number | null>>;
};

export const SlotsList = ({
  slots,
  loading,
  chosenSlotId,
  setChosenSlotId,
}: SlotsListType) => {
  if (loading) {
    return <Loader />;
  }

  if (!slots.length) {
    return <MessageBox color="rose" text="Brak wolnych terminów" />;
  }

  return (
    <div className="flex w-full grow flex-col gap-2 overflow-y-scroll">
      {slots.map(
        ({ location, slot_id, time, doctor, place, doctorLanguages }) => (
          <div
            key={slot_id}
            className={`font-paragraph-1 flex cursor-pointer flex-col rounded-lg bg-jutro-new-warm-gray-50 p-4 hover:bg-jutro-new-warm-gray-100 ${
              chosenSlotId === slot_id &&
              "outline outline-1 -outline-offset-1 outline-jutro-new-blue-600"
            }`}
            onClick={() => {
              setChosenSlotId(slot_id);
            }}
          >
            <div>
              {`${doctor.genre === "DOCTOR" ? "Lekarz: " : "Pielęgniarka: "} ${getDisplayDoctor(
                doctor.firstName,
                doctor.lastName,
                "Brak",
              )} 
                  ${doctorLanguages.length > 0 && `(${doctorLanguages.map((lang) => languageToEmojiMap[lang]).join(" ")})`}`}
            </div>
            <div>{`czas: ${returnDisplayTime(time)}`}</div>
            <div>{`miejsce: ${location} (${place})`}</div>
          </div>
        ),
      )}
    </div>
  );
};

import { FieldErrors } from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";
import { z } from "zod";

export type SlotsCreatorSchemaType = z.infer<typeof slotsCreatorSchema>;

export const slotsCreatorSchema = z.object({
  organizationId: z.string().nonempty(),
  slotType: z.string().nonempty(),
  staffId: z.string().nonempty(),
  dateFrom: z.string().nonempty(),
  dateTo: z.string().nonempty(),
  duration: z.string().nonempty(),
  childrenOnly: z.boolean(),
});

export const slotTypeOptions = [
  {
    value: "TELEMEDICAL",
    label: "Telemedyczna",
  },
  {
    value: "TELEMEDICAL_PRIVATE",
    label: "Telemedyczna priv",
  },
  {
    value: "TELEMEDICAL_NEW_CITIES",
    label: "Telemedyczna nowe miasta",
  },
  {
    value: "EXPRESS",
    label: "Ekspresowa",
  },
  {
    value: "PERSONAL",
    label: "Osobista",
  },
  {
    value: "COLLECTION",
    label: "Pobraniowa",
  },
  {
    value: "PROCEDURES",
    label: "Zabiegowa",
  },
  {
    value: "INFECTIONS",
    label: "Infekcyjna",
  },
  {
    value: "NURSE_VISIT",
    label: "Infekcyjna pielęgniarska",
  },
  {
    value: "INFECTIONS_PUBLIC",
    label: "Infekcyjna publiczna",
  },
  {
    value: "INFECTIONS_PRIVATE",
    label: "Infekcyjna priv",
  },
  {
    value: "USG",
    label: "Usg",
  },
  {
    value: "SPECIALIST",
    label: "Specjalistyczna",
  },
];

export const staffListOptions = [
  {
    value: "611bdc95d81db00008c7b88c",
    label: "Aleksandra Maciążka-Malec (DOCTOR)",
  },
  {
    value: "615d3b4805eb9f66f0d83082",
    label: "Daniel Kwaczyński (DOCTOR)",
  },
  {
    value: "5f8bfaf339c21800096cb7cf",
    label: "Dominika Gawra (DOCTOR)",
  },
  {
    value: "6058b05fbebe5caa7f2c52d4",
    label: "Justyna Antkowska (DOCTOR)",
  },
  {
    value: "1111111111111111111111a",
    label: "Agnieszka Nosek (NURSE)",
  },
  {
    value: "60b747e4aba2007705f1c3df",
    label: "Natalia Buczkowska (NURSE)",
  },
  {
    value: "616e6253cc369e7618c0cd13",
    label: "Karolina Synal (ASSISTANT)",
  },
  {
    value: "616e602b9001c9761846fdca",
    label: "Jakub Smęt (ASSISTANT)",
  },
  {
    value: "619b6e7c2b4ef26a6ac2b267",
    label: "STAFF Grzegorski (STAFF)",
  },
];

export const organizationIdOptions = [
  {
    value: "P",
    label: "Polska",
  },
  {
    value: "PM",
    label: "Woj. Mazowieckie",
  },
  {
    value: "PMW",
    label: "Warszawa",
  },
  {
    value: "PMWP0",
    label: "Warszawa (Powązkowska) - Gabinet lekarski",
  },
  {
    value: "PMWP1",
    label: "Warszawa (Powązkowska) - Gabinet zabiegowy 1",
  },
  {
    value: "PMWP2",
    label: "Warszawa (Powązkowska) - Gabinet zabiegowy 2",
  },
  {
    value: "PMWP3",
    label: "Warszawa (Powązkowska) - Gabinet specjalistyczny",
  },
  {
    value: "PMWP4",
    label: "Warszawa (Powązkowska) - Gabinet usg",
  },
  {
    value: "PMWG0",
    label: "Warszawa (Gagarina) - Gabinet lekarski",
  },
  {
    value: "PMWG1",
    label: "Warszawa (Gagarina) - Gabinet zabiegowy",
  },
  {
    value: "PMWU0",
    label: "Warszawa (Umińskiego) - Gabinet lekarski",
  },
  {
    value: "PMWU1",
    label: "Warszawa (Umińskiego) - Gabinet zabiegowy",
  },
  {
    value: "PB",
    label: "Woj. Podlaskie",
  },
  {
    value: "PBB",
    label: "Białystok",
  },
  {
    value: "PBBL0",
    label: "Białystok (Łąkowa) - Gabinet lekarski",
  },
  {
    value: "PBBL1",
    label: "Białystok (Łąkowa) - Gabinet zabiegowy",
  },
  {
    value: "PBBC0",
    label: "Białystok (Choroszczańska) - Gabinet lekarski",
  },
  {
    value: "PBBC1",
    label: "Białystok (Choroszczańska) - Gabinet zabiegowy",
  },
  {
    value: "PG",
    label: "Woj. Pomorskie",
  },
  {
    value: "PGG",
    label: "Gdańsk",
  },
  {
    value: "PGGG0",
    label: "Gdańsk (Aleja Grunwaldzka) - Gabinet lekarski",
  },
  {
    value: "PGGG1",
    label: "Gdańsk (Aleja Grunwaldzka) - Gabinet zabiegowy",
  },
  {
    value: "PK",
    label: "Woj. Małopolskie",
  },
  {
    value: "PKK",
    label: "Kraków",
  },
  {
    value: "PKKC0",
    label: "Kraków (Czyżówka) - Gabinet lekarski",
  },
  {
    value: "PKKC1",
    label: "Kraków (Czyżówka) - Gabinet zabiegowy",
  },
  {
    value: "PP",
    label: "Woj. Wielkopolskie",
  },
  {
    value: "PPP",
    label: "Poznań",
  },
  {
    value: "PPPS0",
    label: "Poznań (Świętego Leonarda) - Gabinet lekarski",
  },
  {
    value: "PPPS1",
    label: "Poznań (Świętego Leonarda) - Gabinet zabiegowy",
  },
  {
    value: "PD",
    label: "Woj. Dolnośląskie",
  },
  {
    value: "PDW",
    label: "Wrocław",
  },
  {
    value: "PDWP0",
    label: "Wrocław (Pułaskiego) - Gabinet lekarski",
  },
  {
    value: "PDWP1",
    label: "Wrocław (Pułaskiego) - Gabinet zabiegowy",
  },
  {
    value: "PS",
    label: "Woj. Śląskie",
  },
  {
    value: "PSK",
    label: "Katowice",
  },
  {
    value: "PSKG0",
    label: "Katowice (Gliwicka) - Gabinet lekarski",
  },
  {
    value: "PSKG1",
    label: "Katowice (Gliwicka) - Gabinet zabiegowy",
  },
  {
    value: "PE",
    label: "Woj. Łódzkie",
  },
  {
    value: "PEL",
    label: "Łódź",
  },
  {
    value: "PELZ0",
    label: "Łódź (Żeligowskiego) - Gabinet lekarski",
  },
  {
    value: "PELZ1",
    label: "Łódź (Żeligowskiego) - Gabinet zabiegowy",
  },
];

export const getCustomSlotsCreatorSelectStyles = (
  selectName: "organizationId" | "slotType" | "staffId",
  errors: FieldErrors<SlotsCreatorSchemaType>,
) => {
  return {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      height: 38,
      borderColor: errors[selectName] ? "red" : "#cccccc",
      boxShadow: "none",
      "&:hover": {
        borderColor: errors[selectName] ? "red" : "",
      },
    }),
    placeholder: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      opacity: 0.6,
    }),
  };
};

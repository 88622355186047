import { Avatar } from "components/new";
import { DoctorMSShiftOutputDynamic } from "lib/graphql/megaSchema";

type Props = {
  shift: DoctorMSShiftOutputDynamic;
  staffFullName: string;
  shiftOrganizationId: string | undefined;
};

export const SlotDetailsTooltip = ({
  shift,
  staffFullName,
  shiftOrganizationId,
}: Props) => {
  const { doctorId, hourStart, hourEnd, shiftType, slotDuration, comment } =
    shift;

  return (
    <div className="w-[420px] text-base text-jutro-new-warm-gray-700">
      <div className="rounded-t-lg bg-jutro-new-warm-gray-100 px-2 py-1">
        <h5>Szczegóły slotu</h5>
      </div>

      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-start justify-between">
          <FieldWrapper title={staffFullName} content="Lekarz rodzinny" />
          <Avatar userId={doctorId} size="large" />
        </div>

        <FieldWrapper title="Typ" content={shiftType} />

        <FieldWrapper
          title="Zakres godzinowy"
          content={`${hourStart} - ${hourEnd}`}
        />

        <FieldWrapper title="Czas slotu" content={`${slotDuration} minut`} />

        <FieldWrapper title="Lokalizacja" content={shiftOrganizationId ?? ""} />

        {comment && <FieldWrapper title="Komentarz" content={comment} />}
      </div>
    </div>
  );
};

const FieldWrapper = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <div className="flex flex-col">
      <span className="font-bold text-jutro-new-warm-gray-700">{title}</span>
      <span>{content}</span>
    </div>
  );
};

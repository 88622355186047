import { useMemo } from "react";
import { CurrentPatient } from "components/PatientDocs/types";
import { handlePOChP, parseAnswers } from "./tools";

type Answer = {
  value: string | number;
  title: string;
};

export const useAnswers = (patient: CurrentPatient) => {
  const parsedAnswers = useMemo(() => parseAnswers(patient), [patient]);

  const finalAnswers = useMemo(
    () => handlePOChP(parsedAnswers as Record<string, Answer[]>),
    [parsedAnswers],
  );
  return finalAnswers;
};

import { useAtom } from "jotai";
import { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { ScreenLoader } from "components/new";
import { selectedDocsOfPatientTabAtom } from "lib/atoms";
import { useOnDragEnd } from "lib/hooks/useDragEnd.hooks";
import { useWorkspace } from "lib/tools/createWorkspace/useWorkspace";
import { useVisitData } from "views/Visit/hooks";

export const VisitProcedureWorkspace = () => {
  const { data, loading } = useVisitData({ from: "cache-first" });
  const { onDragEnd } = useOnDragEnd(data);
  const workspace = useWorkspace({ name: "visit-procedure", width: 3 });
  const [, setSelectedDocsOfPatientTab] = useAtom(selectedDocsOfPatientTabAtom);

  useEffect(() => {
    setSelectedDocsOfPatientTab("Procedures");

    return () => {
      setSelectedDocsOfPatientTab("Overview");
    };
  }, []);

  if (!data || loading) return <ScreenLoader />;

  return <DragDropContext onDragEnd={onDragEnd}>{workspace}</DragDropContext>;
};

import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { usePatientActivityActions } from "components/new/Chat/ChatMenu/usePatientActivityActions";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorGetProcedureRenderDocument,
  DoctorMSVisitStatus,
  DoctorMSVisitType,
  DoctorProceduresDocument,
  DoctorVisitDocument,
  DoctorVisitQuery,
  PerDayVisitsDocument,
  useDoctorCloseVisitMutation,
  useDoctorCreateChatMessageMutation,
  useDoctorCreateVisitMutation,
  useDoctorPatientLeftVisitMutation,
  useDoctorReopenVisitMutation,
  useDoctorSendInvitationForAVisitMutation,
  useDoctorStartVisitMutation,
  useDoctorUpsertSingleProcedureMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { isInterviewSectionFilledAtom } from "views/Visit/RightPanel/Visit/atoms";
import { InterviewData } from "views/Visit/RightPanel/Visit/types";

export const useVisitTab = (
  visit: Exclude<DoctorVisitQuery["doctorVisit"], null>,
) => {
  const [isInterviewSectionFilled] = useAtom(isInterviewSectionFilledAtom);
  const [currentUser] = useAtom(currentUserAtom);

  const procedureType =
    currentUser?.genre === "DOCTOR" ? "DOCTOR_ADVICE" : "NURSE_ADVICE";

  const onChange = useDebouncedCallback<SubmitHandler<InterviewData>>(
    (data) => {
      try {
        upsertSingleProcedureMutation({
          variables: {
            visitId: visit.id,
            data: JSON.stringify(data),
            procedureType,
          },
          refetchQueries: [DoctorVisitDocument],
        });
      } catch {
        console.error(`Nie udało się zapisać danych wizyty (ID: ${visit.id})`);
      }
    },
    1000,
  );

  const onChangeWithoutDebounce: SubmitHandler<InterviewData> = (data) => {
    try {
      upsertSingleProcedureMutation({
        variables: {
          visitId: visit.id,
          data: JSON.stringify(data),
          procedureType,
        },
        refetchQueries: [DoctorVisitDocument],
      });
    } catch {
      console.error(`Nie udało się zapisać danych wizyty (ID: ${visit.id})`);
    }
  };

  const navigate = useNavigate();

  const [upsertSingleProcedureMutation, { loading }] =
    useDoctorUpsertSingleProcedureMutation({
      refetchQueries: [DoctorVisitDocument],
    });

  const [sendInvitation] = useDoctorSendInvitationForAVisitMutation({
    refetchQueries: [DoctorVisitDocument, PerDayVisitsDocument],
  });

  const [startVisitMutation] = useDoctorStartVisitMutation({
    variables: { id: visit.id },
    refetchQueries: [DoctorVisitDocument, PerDayVisitsDocument],
  });

  const { closePatient } = usePatientActivityActions(visit.patient?.id ?? "");

  const [createChatMessage] = useDoctorCreateChatMessageMutation();

  const [createVisit] = useDoctorCreateVisitMutation();

  const [patientLeftMutation] = useDoctorPatientLeftVisitMutation({
    variables: { id: visit.id },
    refetchQueries: [DoctorVisitDocument, PerDayVisitsDocument],
  });

  const [closeVisitMutation] = useDoctorCloseVisitMutation({
    variables: { id: visit.id },
    refetchQueries: [
      DoctorVisitDocument,
      DoctorProceduresDocument,
      PerDayVisitsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const [reopenVisitMutation] = useDoctorReopenVisitMutation({
    variables: { id: visit.id },
    refetchQueries: [DoctorVisitDocument, PerDayVisitsDocument],
  });

  const startVisit = async () => {
    toaster.notify("Rozpoczynam wizytę...");

    await startVisitMutation({
      refetchQueries: [DoctorGetProcedureRenderDocument, DoctorVisitDocument],
    })
      .then(() => {
        toaster.success("Rozpoczęto wizytę!");
      })
      .catch(() => {
        toaster.warning("Wystąpił błądz...");
      });

    await upsertSingleProcedureMutation({
      variables: {
        visitId: visit.id,
        data: JSON.stringify({
          height: visit.height ?? null,
          weight: visit.weight ?? null,
          doctorInterview: visit.doctorInterview ?? "",
          physicalExamination: visit.physicalExamination ?? "",
          doctorDiagnosis: visit.doctorDiagnosis ?? [],
          recommendation: visit.recommendation ?? "",
          comment: visit.comment ?? "",
          memo: visit.memo ?? "",
          smokeLevel: visit.smokeLevel ?? null,
        }),
        procedureType,
      },
      refetchQueries: [DoctorVisitDocument, DoctorProceduresDocument],
    });

    closePatient();
  };

  const closeDocumentation = async () => {
    toaster.notify("Zamykam dokumentację...");

    await closeVisitMutation()
      .then(() => {
        toaster.success("Zamknięto dokumentację!");
      })
      .then(() => {
        navigate("/visits", { replace: true });
      })
      .catch(() => {
        toaster.warning("Wystąpił błąd w zamykaniu dokumentacji...");
      });
  };

  const endPreVisit = (type: DoctorMSVisitType) => async () => {
    const postFix = type === "VIRTUAL" ? "telemedyczną" : "osobistą";

    toaster.notify(`Wysyłam zaproszenie na wizytę ${postFix}`);

    await sendInvitation({
      variables: {
        id: visit.id,
        type,
      },
    })
      .then(() => {
        toaster.success("Poprawnie wysłano zaproszenie");
      })
      .catch(() => {
        toaster.error("Wystąpił błąd w wysłaniu zaproszenia");
      });
  };

  const inviteForStandard = async () => {
    toaster.notify("Wysyłam zaproszenie do pacjenta na wizytę w przychodni...");
    await createVisit({
      variables: {
        input: {
          type: "STANDARD",
          patientId: visit.patient?.id ?? "",
        },
      },
    })
      .then((res) => {
        createChatMessage({
          variables: {
            id: visit.patient?.id ?? "",
            input: {
              content: `[visit-${res.data?.doctorCreateVisit}]`,
            },
            isSystem: true,
          },
        });
      })
      .then(() => {
        toaster.success("Poprawnie wysłano zaproszenie");
      })
      .catch(() => {
        toaster.error("Wystąpił błąd w wysłaniu zaproszenia");
      });
  };

  const closeVisit = async () => {
    toaster.notify("Zamykam wizytę...");

    await patientLeftMutation()
      .then(() => {
        toaster.success("Zamknięto wizytę!");
      })
      .catch(() => {
        toaster.warning("Wystąpił błąd w zamykaniu wizyty...");
      });
  };

  const reopenVisit = async () => {
    toaster.notify("Otwieram wizytę ponownie...");

    await reopenVisitMutation()
      .then(() => {
        toaster.success("Otwarto wizytę ponownie!");
      })
      .catch(() => {
        toaster.warning("Wystąpił błąd podczas ponownego otwierania wizyty...");
      });
  };

  const isUserAssignedDoctor = currentUser?.id === visit.doctorId;

  const isEndVisitDisabled = useMemo(
    () => !isInterviewSectionFilled || !isUserAssignedDoctor || loading,
    [isInterviewSectionFilled, isUserAssignedDoctor, loading],
  );

  const isCloseDocumentationDisabled = useMemo(() => {
    const { type } = visit;

    if (!isUserAssignedDoctor || loading || !type) {
      return true;
    }

    return false;
  }, [visit, isUserAssignedDoctor, loading]);

  const isVisitAssignedToCurrentUser = useMemo(
    () => currentUser?.id === visit.doctorId,
    [currentUser?.id, visit.doctorId],
  );

  const isInputDisabled = useMemo(() => {
    if (!currentUser || !isVisitAssignedToCurrentUser) {
      return true;
    }

    const { genre } = currentUser;

    const { status } = visit;

    if (genre === "ASSISTANT") {
      return status !== "DRAFT";
    }

    return ["DRAFT", "SUCCESS"].includes(status as DoctorMSVisitStatus);
  }, [currentUser, visit]);

  const isReopenVisitDisabled = useMemo(() => {
    const now = dayjs();

    if (!visit.startOn) {
      return true;
    }

    return (
      dayjs(parseInt(visit.startOn)).endOf("day").isBefore(now) ||
      visit.doctorId !== currentUser?.id
    );
  }, [visit.startOn, visit.doctorId, currentUser?.id]);

  const endedContactWithPatient = Boolean(visit.leftOn);

  return {
    visit,
    currentUser,
    endPreVisit,
    loading,
    startVisit,
    closeDocumentation,
    inviteForStandard,
    closeVisit,
    reopenVisit,
    onChange,
    isEndVisitDisabled,
    isCloseDocumentationDisabled,
    isInputDisabled,
    isVisitAssignedToCurrentUser,
    endedContactWithPatient,
    onChangeWithoutDebounce,
    isReopenVisitDisabled,
  };
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "@jutro/ui";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { SideSheet } from "components/new/Drawer";
import { toaster } from "lib/tools/toaster";
import { PatientData } from "views/Patients/types";

export enum TestId {
  Drawer = "DeletePatientDrawer",
}

type FormState = {
  peselConfirm: string;
};

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  setShowDeletePanel: Dispatch<SetStateAction<boolean>>;
  patientData: PatientData;
  setPatientData: Dispatch<SetStateAction<PatientData | null>>;
  deletePatient: (phone: string) => void;
};

export const DeletePatientDrawer = ({
  isShown,
  setIsShown,
  setShowDeletePanel,
  patientData,
  setPatientData,
  deletePatient,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const schema = z.object({
    peselConfirm: z
      .string()
      .min(1, { message: "To pole jest wymagane" })
      .refine((val) => val === patientData.pesel, {
        message: "Sprawdź poprawność numeru PESEL",
      }),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormState>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const deleteAccountAndClose = async () => {
    try {
      toaster.notify("Usuwam pacjenta...");
      setLoading(true);

      if (!patientData.phone) {
        toaster.error("Niepoprawny numer telefonu!");
        setLoading(false);
        return;
      }

      await deletePatient(patientData.phone);
      setLoading(false);
      toaster.success("Pomyślnie usunięto pacjenta!");
      setPatientData(null);
      setShowDeletePanel(false);
      setIsShown(false);
    } catch {
      toaster.error("Nie udało się usunąć pacjenta!");
      setLoading(false);
    }
  };

  const isButtonDisabled = () => {
    if (!isValid || loading) return true;
    return false;
  };

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => {
        setShowDeletePanel(false);
        setIsShown(false);
        setPatientData(null);
      }}
    >
      <form
        onSubmit={handleSubmit(deleteAccountAndClose)}
        data-testid={TestId.Drawer}
        className="flex flex-col space-y-2 p-4"
      >
        <div className="font-display-3 mb-4">Usuń pacjenta</div>
        <div className="mb-5">
          Jeśli chcesz usunąć dane uzytkownika{" "}
          <b>
            {patientData.firstName} {patientData.lastName}
          </b>
          , musisz wpisać poniżej numer PESEL pacjenta.
          <br />
          Numer PESEL: <b>{patientData.pesel}</b>
        </div>

        <div className="mb-5">
          <Controller
            name="peselConfirm"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label="PESEL"
                {...field}
                maxLength={11}
                type="number"
                onPaste={(e) => e.preventDefault()}
                error={error?.message}
              />
            )}
          />
        </div>

        <Button
          variant="negative"
          disabled={isButtonDisabled()}
          text="Usuń pacjenta"
          loading={loading}
          type="submit"
        />
      </form>
    </SideSheet>
  );
};

import { Select } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import { DiagnosisItemArea } from "components/IpomForm/DiagnosisSection/DiagnosisItemArea";
import {
  IpomFormSchema,
  levelStratificationOptions,
} from "components/IpomForm/schema";

type Props = {
  disabled?: boolean;
};

export const DiagnosisSection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();

  return (
    <>
      <Controller
        control={control}
        name="levelStratification"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            label="Poziom stratyfikacji*"
            options={levelStratificationOptions}
            onChange={(option) => onChange(option?.value ?? "")}
            value={
              levelStratificationOptions.find(
                (option) => option.value === value,
              ) ?? null
            }
            error={error?.message}
            disabled={disabled}
          />
        )}
      />

      <Controller
        control={control}
        name="observations"
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <DiagnosisItemArea
            ref={ref}
            doctorDiagnosis={value}
            disabled={disabled}
            error={error?.message}
            onItemClick={(item) => {
              onChange(
                value.map((currentItem) =>
                  currentItem.value === item.value
                    ? { ...currentItem, active: !currentItem.active }
                    : currentItem,
                ),
              );
            }}
          />
        )}
      />
    </>
  );
};

import { useDoctorGetPatientsLazyQuery } from "lib/graphql/megaSchema";
import { formatPatientCardNumber } from "lib/tools/formatPatientCardNumber";

export const useGetAsyncPatientsData = () => {
  const [getPatients] = useDoctorGetPatientsLazyQuery();

  const loadPatientsData = async (input: string) => {
    const isInputDigitsOnly = isNaN(parseInt(input));
    if (isInputDigitsOnly) {
      return [];
    }

    const { data } = await getPatients({
      variables: {
        take: 5,
        fullText: input,
      },
    });

    if (!data || data.doctorPatients.length === 0) {
      return [];
    }

    const { doctorPatients } = data;

    const fromattedData = doctorPatients.map((patient) => {
      const {
        id,
        patientCardNumber,
        firstName,
        lastName,
        address,
        phone,
        email,
      } = patient;

      return {
        value: {
          id,
          patientCardNumber,
          address: address ?? null,
          phone,
          email: email ?? null,
          firstName: firstName!,
          lastName: lastName!,
        },
        label: `${firstName} ${lastName} (${formatPatientCardNumber(patientCardNumber)})`,
      };
    });

    return fromattedData;
  };

  return {
    loadPatientsData,
  };
};

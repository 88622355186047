const contentMap = {
  "[recommendation-": "Wysłano rekomendację pacjentowi",
  "[survey-sent": "Wysłano ankietę pacjentowi",
  "[survey-filled": "Wypełniono ankietę przez pacjenta",
  "[referral-": "Wysłano skierowanie pacjentowi",
  "[leave-": "Wysłano zwolnienie pacjentowi",
  "[prescription-": "Wysłano receptę pacjentowi",
  "[message-": "Wiadomość systemowa",
  "[visit-": "Wysłano wizytę pacjentowi",
  "[file-": "Został wysłany plik",
  "[@@@TELEVISIT@@@]": "Wiadomość systemowa",
  "[@@@STANDARDVISIT@@@]": "Wiadomość systemowa",
};

export const lastMessageMap = (content: string) => {
  for (const [k, v] of Object.entries(contentMap)) {
    if (content.startsWith(k)) return v;
  }

  return content;
};

import { Option } from "@jutro/types";

type SmokingInfo = {
  smokingCurrently: string | null;
  smokingInThePast: string | null;
  smokingType: string | null;
  ciggaretesPerDay: string | null;
};

export const smokeLevels: Record<string, number> = {
  doesNotSmoke_neverSmoked_null_null: 1,
  doesNotSmoke_smokedInThePast_null_null: 2,
  "smokesEveryday_null_cigarettes_1-9": 3,
  "smokesEveryday_null_cigarettes_10-24": 4,
  "smokesEveryday_null_cigarettes_>24": 5,
  "smokesEveryday_null_e-cigarettes_null": 6,
  smokesEveryday_null_iqos_null: 7,
  smokesEveryday_null_other_null: 8,
  smokesEveryday_null_both_null: 9,
  smokesSometimes_null_cigarettes_null: 10,
  "smokesSometimes_null_e-cigarettes_null": 11,
  smokesSometimes_null_iqos_null: 12,
  smokesSometimes_null_other_null: 13,
  smokesSometimes_null_both_null: 14,
};

export const calculateSmokeLevel = ({
  smokingCurrently,
  smokingInThePast,
  smokingType,
  ciggaretesPerDay,
}: SmokingInfo) => {
  const key = `${smokingCurrently}_${smokingInThePast}_${smokingType}_${ciggaretesPerDay}`;

  if (!(key in smokeLevels)) return null;

  return smokeLevels[key];
};

export const smokingCurrentlyOptions = [
  { label: "Osoba niepaląca", value: "doesNotSmoke" },
  { label: "Palący codziennie", value: "smokesEveryday" },
  {
    label: "Palący okazjonalnie (tj. raz w tygodniu lub rzadziej)",
    value: "smokesSometimes",
  },
];

export const smokingInThePastOptions: Option<string>[] = [
  { label: "Nigdy niepaląca", value: "neverSmoked" },
  {
    label:
      "Paląca w przeszłości (osoba, która wypaliła w swoim życiu co najmniej 100 papierosów)",
    value: "smokedInThePast",
  },
];

export const smokingTypeOptions: Option<string>[] = [
  { label: "Papierosy tradycyjne", value: "cigarettes" },
  { label: "E-papierosy", value: "e-cigarettes" },
  { label: "Papierosy z podgrzewanym tytoniem (np. iqos, glo)", value: "iqos" },
  {
    label:
      "Inne wyroby tytoniowe (tytoń fajkowy, tytoń do fajki wodnej, cygaro, cygaretka, tytoń do żucia, tytoń do nosa lub tytoń do stosowania doustnego)",
    value: "other",
  },
  {
    label: "Zarówno papierosy tradycyjne, jak i inne wyroby tytoniowe",
    value: "both",
  },
];

export const numberOfCigarettesPerDayOptions: Option<string>[] = [
  { label: "Do 9 sztuk na dobę", value: "1-9" },
  { label: "Od 10 do 24 sztuk na dobę", value: "10-24" },
  { label: "Powyżej 24 sztuk na dobę", value: ">24" },
];

import { useAtom } from "jotai";
import { PropsWithChildren } from "react";
import { Toaster } from "react-hot-toast";
import { Lightbox } from "react-modal-image";
import backgroundSignet from "assets/background-signet.svg";
import { CurrentLocaleSelect } from "components/new/CurrentLocaleSelect";
import { imagePreviewAtom, isScreenLoadingAtom } from "lib/atoms";
import { useFlag } from "lib/hooks/flags";
import { useZendesk } from "lib/hooks/useZendesk";
import { Menu } from "./Menu";

type Props = PropsWithChildren<unknown>;

export const Layout = ({ children }: Props) => {
  const [imagePreview, setImagePreview] = useAtom(imagePreviewAtom);
  const [isScreenLoading] = useAtom(isScreenLoadingAtom);
  const currentLocaleSelectEnabled = useFlag("current-locale-select");
  useZendesk();

  return (
    <div className="font-geologica flex h-screen w-screen gap-4 overflow-hidden bg-jutro-new-warm-gray-100 p-4 text-jutro-new-warm-gray-800 antialiased">
      <Toaster />
      <Menu />
      {currentLocaleSelectEnabled && <CurrentLocaleSelect />}

      <div className="fixed left-9 top-0 z-0 grid h-screen w-screen place-items-center">
        <img
          className={isScreenLoading ? "animate-deep-fast-pulse" : ""}
          src={backgroundSignet}
        />
      </div>

      <div className="relative z-1 h-full w-full overflow-hidden">
        {children}
      </div>

      {imagePreview && (
        <Lightbox
          showRotate
          hideZoom
          medium={imagePreview}
          large={imagePreview}
          onClose={() => setImagePreview(null)}
        />
      )}
    </div>
  );
};

import { AddCircleIcon, Button } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { nanoid } from "nanoid";
import { PropsWithChildren } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { ArrayElement, PatientDocsTag } from "lib/types";
import { Tag } from "./Tag";
import { tagTypes } from "./utils";

type TagsBlockProps = {
  tags: PatientDocsTag[];
};

export const TagsBlock = ({ tags }: TagsBlockProps) => {
  return (
    <div className="grid auto-cols-max grid-cols-2 gap-2 pb-4">
      {tagTypes.map((tagType) => {
        const { type } = tagType;

        return (
          <TagTileWrapper key={type} {...tagType}>
            <TagTileContent tags={tags.filter((tag) => tag.type === type)} />
          </TagTileWrapper>
        );
      })}
    </div>
  );
};

type TagTileWrapperProps = ArrayElement<typeof tagTypes>;

const TagTileWrapper = ({
  name,
  type,
  children,
}: PropsWithChildren<TagTileWrapperProps>) => {
  const { addTile } = useTileSystem();

  const createNewTag = () => {
    const id = nanoid();

    addTile(
      {
        id,
        name: "tag-form",
        data: {
          id,
          type: "create",
          tag: { type, expirationAt: dayjs().add(1, "year") },
        },
      },
      TileColumn.Center,
    );
  };

  const [currentUser] = useAtom(currentUserAtom);

  const isDoctor = currentUser?.genre === "DOCTOR";

  const onlyDoctorAllowedTags = ["CHRONIC_DISEASE", "REGULAR_DRUG"];

  return (
    <div key={type} className="flex flex-col rounded-lg bg-white p-4 pt-2">
      <div className="flex justify-between pb-1 align-middle">
        <h2 className="font-paragraph-2 py-2 text-jutro-new-warm-gray-700">
          {name}
        </h2>
        {(isDoctor || !onlyDoctorAllowedTags.includes(type)) && (
          <Button
            onClick={createNewTag}
            size="condensed"
            variant="ghost"
            icon={<AddCircleIcon />}
            label="Dodaj"
            tooltipPosition="top"
            full={false}
          />
        )}
      </div>
      <div className="flex h-full min-h-14 flex-wrap content-start gap-2 rounded-lg border border-jutro-new-warm-gray-100 p-2">
        {children}
      </div>
    </div>
  );
};

const TagTileContent = ({ tags }: TagsBlockProps) => {
  if (tags.length === 0) {
    return (
      <div className="text-xs text-jutro-new-warm-gray-500">Brak tagów</div>
    );
  }

  return (
    <>
      {tags.map((tag) => (
        <Tag key={tag.id} {...tag} />
      ))}
    </>
  );
};

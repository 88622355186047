import { OrderedTestListItem } from "components/PatientDocs/Ordered/types";
import {
  fixedPriceValue,
  priceListSum,
} from "components/PatientDocs/Ordered/utils";
import { DoctorMSLaboratoryExaminationEntity } from "lib/graphql/megaSchema";

type Props = {
  data: OrderedTestListItem[];
};

export const LabExamsSummary = ({ data }: Props) => {
  const labExams = data as DoctorMSLaboratoryExaminationEntity[];

  const priceList: number[] = labExams.map(
    (labExam) => labExam.assignment.original,
  );

  return (
    <>
      <div>
        <ul className="flex flex-col gap-1 border-b-[1px] border-black pb-2">
          {labExams.map((test) => (
            <li key={test.id} className="flex justify-between">
              <span className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
                {test.assignment.name}
              </span>
              <span className="font-normal">{`${fixedPriceValue(
                test.assignment.original,
              )}`}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex justify-between">
        <span>Podsumowanie</span>
        <span className="font-normal">{`${priceListSum(priceList).toFixed(
          2,
        )} zł`}</span>
      </div>
    </>
  );
};

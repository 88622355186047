import { UtilType } from "../types";

export const woundClassificationMap: Record<string, string> = {
  big: "Duża",
  small: "Mała",
  flat: "Płytka",
  deep: "Głęboka",
  afterOperation: "Pooperacyjna",
  cut: "Cięta",
  laceration: "Szarpana",
  abrasion: "Otarcie",
  bite: "Ugryzienie",
  burn: "Oparzenie",
};

export const headers: Record<UtilType, any> = {
  disinfectant: {
    name: "Środek dezynfekcyjny",
    productSeries: "Seria produkcyjna",
    expirationDate: "Data ważności",
    used: "Wykorzystano (np. 1 szt.)",
  },
  tool: {
    name: "Narzędzie",
    productSeries: "Seria produkcyjna",
    expirationDate: "Data ważności",
    used: "Wykorzystano (np. 1 szt.)",
  },
  drug: {
    name: "Nazwa leku",
    productSeries: "Seria produkcyjna",
    expirationDate: "Data ważności",
    used: "Wykorzystano (np. 1 szt.)",
  },
  bandage: {
    name: "Materiał opatrunkowy",
    productSeries: "Seria produkcyjna",
    expirationDate: "Data ważności",
    used: "Wykorzystano (np. 1 szt.)",
  },
  measurement: {
    systolicPressure: "Ciśnienie skurczowe (mmHg)",
    diastolicPressure: "Ciśnienie rozkurczowe (mmHg)",
    pulse: "Tętno (ud./min.)",
    resultType: "Rodzaj wyniku (normalny / alarmujący)",
  },
  vaccine: {
    name: "Nazwa szczepionki",
    productSeries: "Seria produkcyjna",
    expirationDate: "Data ważności",
    dose: "Dawka (np. 1/1)",
  },
  file: {},
};

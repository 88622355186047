import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import {
  SnippetCategoryFilter,
  SnippetSortOrder,
  SnippetTagFilter,
  SnippetTypeFilter,
  SnippetUsageFilter,
  SnippetVisiblityFilter,
} from "components/Snippet/types";

export const orderAtom = atom<SnippetSortOrder>("DESC");
export const visibilityAtom = atom<SnippetVisiblityFilter>("ALL");
export const typeAtom = atom<SnippetTypeFilter>("ALL");
export const categoryAtom = atom<SnippetCategoryFilter>("ALL");
export const tagAtom = atom<SnippetTagFilter>("ALL");
export const selectedUsageAtom = atom<SnippetUsageFilter>("ALL");
export const searchAtom = atom("");
export const selectedSnippetsAtom = atom<string[]>([]);
export const selectedOwnerAtom = atom<string | null>(null);
export const keepCurrentControlsAtom = atom(false);
export const snippetListShowContentAtom = atomWithStorage(
  "snippet-list-show-content",
  true,
);

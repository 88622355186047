import { replacePolishDiacritics } from "@jutro/tools";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { Dispatch, SetStateAction, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { CalendarIcon } from "components/components/CalendarIcon";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { useDoctorLaboratoryExaminationReportLazyQuery } from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { fileConfig } from "lib/tools/httpConfigs";
import { Loader } from "../Loader";
import { InputWrapper } from "./InputWrapper";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const RaportDialog = ({ open, setOpen }: Props) => {
  const [organization, setOrganization] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(
    dayjs().startOf("day").toDate(),
  );
  const [endDate, setEndDate] = useState<Date | null>(dayjs().toDate());

  const { organizationsClinicsOptions } = useVojevodshipOptions();

  const [getReportFileId, { loading }] =
    useDoctorLaboratoryExaminationReportLazyQuery();

  const handleOnConfirm = async () => {
    const { data } = await getReportFileId({
      variables: {
        start: startDate?.toUTCString() ?? "",
        end: endDate?.toUTCString() ?? "",
        organizationCode: organization ?? "",
      },
    });

    const reportFileId = data?.doctorLaboratoryExaminationReport;

    const selectedOrganizationName = replacePolishDiacritics(
      organizationsClinicsOptions.find(({ value }) => value === organization)
        ?.label ?? "",
    );

    window.open(`
        ${fileConfig[envVars.REACT_APP_CONFIG]}${reportFileId}&filename=${[
          "raport-diagnostyka",
          selectedOrganizationName.split(" ").join("-"),
          dayjs().format("DD-MM-YYYY"),
        ].join("_")}.pdf`);
  };

  return (
    <ConfirmDialog
      width="lg"
      confirmDisabled={!organization}
      shouldHideOverflow={false}
      setOpen={setOpen}
      title="Wygeneruj raport do diagnostyki"
      onConfirm={handleOnConfirm}
      confirmText="Wygeneruj"
      submitVariant="primary"
      open={open}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-5 flex flex-col gap-4">
          <InputWrapper header="Wybierz placówkę" bold>
            <Select
              noOptionsMessage={() => "Brak dostępnych placówek"}
              placeholder="Wybierz placówkę"
              isSearchable={false}
              value={organizationsClinicsOptions.find(
                ({ value }) => value === organization,
              )}
              onChange={(clinic) => {
                if (!clinic) {
                  return;
                }

                setOrganization(clinic.value);
              }}
              options={organizationsClinicsOptions}
            />
          </InputWrapper>
          <InputWrapper header="Wybierz zakres daty" bold>
            <div className="flex justify-between">
              <InputWrapper header="Data od">
                <div className="date-time-wrapper">
                  <DateTimePicker
                    disableClock
                    format="yyyy-MM-dd HH:mm"
                    calendarIcon={<CalendarIcon size={32} />}
                    monthPlaceholder="MM"
                    yearPlaceholder="RRRR"
                    dayPlaceholder="DD"
                    hourPlaceholder="HH"
                    minutePlaceholder="mm"
                    clearIcon={null}
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                  />
                </div>
              </InputWrapper>
              <InputWrapper header="Data do">
                <div className="date-time-wrapper">
                  <DateTimePicker
                    disableClock
                    minDate={startDate ?? undefined}
                    format="yyyy-MM-dd HH:mm"
                    calendarIcon={<CalendarIcon size={32} />}
                    monthPlaceholder="MM"
                    yearPlaceholder="RRRR"
                    dayPlaceholder="DD"
                    hourPlaceholder="HH"
                    minutePlaceholder="mm"
                    clearIcon={null}
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                  />
                </div>
              </InputWrapper>
            </div>
          </InputWrapper>
        </div>
      )}
    </ConfirmDialog>
  );
};

type ColorType = "#FF453A" | "#319AD5";

type Props = {
  color: ColorType;
  down?: boolean;
};

export const ArrowUpIcon = ({ color, down = false }: Props) => {
  const colorMap: Record<ColorType, string> = {
    "#FF453A": "text-jutro-new-rose-600",
    "#319AD5": "text-jutro-new-blue-500",
  };

  return (
    <div
      className={`block ${colorMap[color]} stroke-current ${
        down && "rotate-180 transform"
      } h-4 w-4`}
    >
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 15.5V0.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 7.5L8 0.5L1 7.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

import { Option } from "@jutro/types";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string | boolean;
  isSearchable?: boolean;
  options: Option<string>[];
  isFullWidth?: boolean;
};

export const ControlledSelect = ({
  label,
  name,
  options,
  disabled,
  defaultValue,
  isSearchable,
  isFullWidth = true,
}: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <div
          className={`flex flex-col gap-2 ${isFullWidth ? "w-full" : "w-64"}`}
        >
          <span className="font-heading-4">{label}</span>
          <ReactSelect
            {...field}
            placeholder="Kliknij aby wybrać..."
            options={options}
            menuPosition="fixed"
            styles={adjustedToInputSelectStyles(error)}
            value={
              options.find(({ value: optionValue }) => value === optionValue) ??
              null
            }
            isDisabled={disabled}
            isSearchable={isSearchable}
            onChange={(option) => onChange(option?.value)}
          />
        </div>
      )}
    />
  );
};

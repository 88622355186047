import { atom } from "jotai";
import { atomWithReset, atomWithStorage } from "jotai/utils";
import { DocsOfPatientTab } from "components/PatientDocs";
import { DoctorGetDocumentsQuery } from "lib/graphql/megaSchema";
import { ArrayElement } from "lib/types";
import { VisitTab } from "views/Visit/RightPanel/TabsSection/types";

export const selectedVisitTabAtom = atomWithReset<VisitTab>("visit");
export const showRecentDrugsAtom = atom(false);
export const showRegularDrugsAtom = atom(false);

export const visitDataAtom = atom({
  doctorInterview: "",
  recommendation: "",
  icd10Main: [],
  nurseIcd10Main: [],
});

export const examinationDrawerPositionAtom = atom("right");
export const examinationDrawerWideAtom = atom(false);

export type MedicalDocument = ArrayElement<
  Exclude<
    DoctorGetDocumentsQuery["doctorPatient"],
    null | undefined
  >["documents"]
>;

export const isScreenLoadingAtom = atom(false);

export const numPagesAtom = atom(0);
export const pageNumberAtom = atom(1);
export const selectedDocsOfPatientTabAtom = atom<DocsOfPatientTab>("Overview");

export const imagePreviewAtom = atom<string | null>(null);

export const locales = ["pl", "en", "ua"] as const;
export type Locale = (typeof locales)[number];

export const currentLocaleAtom = atomWithStorage<Locale>(
  "current-locale",
  "pl",
);

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { PostView } from "components/new/PostView";
import { RouteWrapper } from "components/new/RouteWrapper";
import { DiagSimulator } from "views/DiagSimulator/index";
import { HomeView } from "views/Home";
import { MdxPlayground } from "views/MdxPlayground";
import { PatientChatWorkspace } from "views/Patient/Chat";
import { PatientProfileWorkspace } from "views/Patient/Profile";
import { PatientsView } from "views/Patients";
import { PrescriptionRequest } from "views/PrescriptionRequest/index";
import { ScheduleView } from "views/Schedule";
import { SettingsWorkspace } from "views/Settings";
import { SlotsCreator } from "views/SlotsCreator/index";
import { SnippetsAdminWorkspace } from "views/SnippetsAdmin";
import { StaffScheduleCreator } from "views/StaffScheduleCreator/index";
import { VisitProcedureWorkspace } from "views/Visit/procedureTiles";
import { VisitAdviceWorkspace } from "views/Visit/tiles";
import { VisitWorkspaceHandler } from "views/Visit/workspaceHandler";
import { VisitsLayout } from "views/Visits";
import { ActiveVisitsTab } from "views/Visits/Active";
import { VisitsArchiveTab } from "views/Visits/Archive";
import { VisitsPerDayTab } from "views/Visits/PerDay";
import { VisitsConfirmation } from "views/VisitsConfirmation/index";
import { NFZReports } from "./NFZReports";

export const AppRoutes = () => (
  <BrowserRouter>
    <RouteWrapper>
      <Routes>
        <Route index element={<HomeView />} />
        <Route path="settings" element={<SettingsWorkspace />} />
        <Route path="schedule" element={<ScheduleView />} />
        <Route path="news/:slug" element={<PostView />} />
        <Route path="staff-scheduler" element={<StaffScheduleCreator />} />
        <Route path="prescription-request" element={<PrescriptionRequest />} />

        <Route path="visits/:visitId" element={<Outlet />}>
          <Route index element={<VisitWorkspaceHandler />} />
          <Route path="advice" element={<VisitAdviceWorkspace />} />
          <Route path="procedure" element={<VisitProcedureWorkspace />} />
        </Route>

        <Route path="visits" element={<VisitsLayout />}>
          <Route index element={<Navigate replace to="per-day" />} />
          <Route path="per-day" element={<VisitsPerDayTab />} />
          <Route path="active" element={<ActiveVisitsTab />} />
          <Route path="archive" element={<VisitsArchiveTab />} />
        </Route>

        <Route
          path="patients/:patientId/chat"
          element={<PatientChatWorkspace />}
        />
        <Route
          path="patients/:patientId/profile"
          element={<PatientProfileWorkspace />}
        />
        <Route path="patients" element={<PatientsView />} />

        <Route path="diag-simulator" element={<DiagSimulator />} />
        <Route path="slots-creator" element={<SlotsCreator />} />
        <Route path="visits-confirmation" element={<VisitsConfirmation />} />

        <Route path="mdx-playground" element={<MdxPlayground />} />
        <Route path="nfz-reporting" element={<NFZReports />} />
        <Route path="snippets-admin" element={<SnippetsAdminWorkspace />} />
      </Routes>
    </RouteWrapper>
  </BrowserRouter>
);

import { determineAge } from "@jutro/tools";
import { Tooltip } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { Avatar } from "components/new";
import { currentUserAtom } from "lib/atoms/auth";
import { formatPatientCardNumber } from "lib/tools/formatPatientCardNumber";
import { isPastVisit } from "lib/tools/isPastVisit";
import { AdditionalVisitInfo } from "views/Visits/PerDay/components/SlotList/VisitGroup/AdditionalVisitInfo";
import { MakeCallButton } from "views/Visits/PerDay/components/SlotList/VisitGroup/MakeCallButton/index";
import { NotificationViewBlock } from "views/Visits/PerDay/components/SlotList/VisitGroup/NotificationViewBlock/index";
import { VisitStatusTag } from "views/Visits/PerDay/components/SlotList/VisitGroup/VisitStatusTag/index";
import { VisitsItem } from "views/Visits/PerDay/components/SlotList/types";
import {
  getProcedureVisitTypeLabel,
  getVisitType,
} from "views/Visits/PerDay/tools";
import { isTestUserPhone } from "lib/tools/auth";

type Props = {
  title: string;
  visits: VisitsItem[];
};

export const VisitGroup = ({ title, visits }: Props) => {
  const [currentUser] = useAtom(currentUserAtom);
  const navigate = useNavigate();

  const hasVirtual = visits.some((o) => o.type === "VIRTUAL");
  const hasProcedure = visits.some((o) => o.type === "PROCEDURE");

  return (
    <div className="z-0 flex w-full flex-col gap-y-4 rounded-lg bg-white p-4 text-jutro-new-warm-gray-800">
      <div className="flex items-center gap-x-2">
        <span className="font-label">{title}</span>
      </div>

      {visits.length > 0 && (
        <div className="w-full rounded-lg border">
          <div className="flex-col">
            {/* HEADER */}
            <div className="flex w-full justify-start gap-8 px-8 py-4 2xl:gap-14">
              <div className="font-label flex w-16">Czas</div>

              <div className="font-label flex w-64">Imię i nazwisko</div>

              <div className="font-label flex w-20">ID</div>

              <div className="font-label flex w-24">Typ wizyty</div>

              <div className="font-label flex w-24">Tryb wizyty</div>

              <div className="font-label flex w-48">Status wizyty</div>

              {hasVirtual && (
                <div className="font-label flex w-28">Telefon</div>
              )}

              {hasProcedure && (
                <div className="font-label flex w-64">Rodzaj wizyty</div>
              )}

              <div className="font-label flex min-w-24 flex-1">
                Dodatkowe informacje
              </div>
            </div>

            {/* CONTENT */}
            {visits.map((visit) => {
              const patientIdNumber = visit.patient?.patientCardNumber
                ? formatPatientCardNumber(visit.patient.patientCardNumber)
                : "-";

              const userAge = determineAge(visit.patient?.pesel ?? "");
              const isChild =
                userAge < 18 || visit.patient?.pesel?.length === 12;
              const isPrescriptionVisit = Boolean(visit.prescriptionRequestId);

              return (
                <Tooltip
                  key={visit.id}
                  position="top-start"
                  disabled={
                    visit.doctor?.id === currentUser?.id ||
                    isTestUserPhone(currentUser?.phone)
                  }
                  content={
                    "Pacjent przypisany do innego lekarza. Wybierz swoją listę pacjentów."
                  }
                  trigger={
                    <div
                      className={`flex w-full items-center gap-8 border-t px-8 py-4 last:rounded-b-lg 2xl:gap-14 ${
                        (isPrescriptionVisit
                          ? visit.snapshotBy
                          : visit.snapshotBy || isPastVisit(visit)) &&
                        "opacity-50"
                      } ${
                        visit.doctor?.id === currentUser?.id ||
                        isTestUserPhone(currentUser?.phone)
                          ? `cursor-pointer transition-colors duration-300 ease-in-out hover:bg-jutro-new-warm-gray-50`
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        if (
                          visit.doctor?.id === currentUser?.id ||
                          isTestUserPhone(currentUser?.phone)
                        ) {
                          navigate("/visits/" + visit.id);
                        }
                      }}
                    >
                      <div className="flex w-16">
                        {dayjs(visit.plannedStart?.iso).format("HH:mm")}
                      </div>

                      <div
                        data-hj-suppress
                        className="font-paragraph-1 flex w-64 items-center gap-x-2"
                      >
                        <Avatar userId={visit.patient?.id || ""} size="s" />

                        {`${visit.patient?.firstName ?? "Brak imienia"} ${
                          visit.patient?.lastName ?? "Brak nazwiska"
                        }`}
                      </div>

                      <div className="font-paragraph-1 flex w-20">
                        {patientIdNumber}
                      </div>

                      <div className="flex w-24">{getVisitType(visit)}</div>

                      <div className="font-paragraph-1 flex w-24">
                        {visit.prescriptionRequestId
                          ? "-"
                          : visit.isPublicVisit
                            ? "NFZ"
                            : "Poza NFZ"}
                      </div>

                      <div className="flex w-48">
                        <VisitStatusTag visitElement={visit} />
                      </div>

                      {hasVirtual && (
                        <div className="w-28">
                          {visit.type === "VIRTUAL" && (
                            <MakeCallButton
                              disabled={
                                isPrescriptionVisit
                                  ? Boolean(visit.snapshotBy) ||
                                    visit.doctor?.id !== currentUser?.id
                                  : Boolean(visit.snapshotBy) ||
                                    isPastVisit(visit) ||
                                    visit.doctor?.id !== currentUser?.id
                              }
                              patientId={visit.patient?.id}
                              phone={
                                isChild && visit.patient?.guardians[0]
                                  ? visit.patient.guardians[0].phone
                                  : visit.patient?.phone
                              }
                              visitRecordingAndAnalysisAgreement={
                                visit.patient
                                  ?.visitRecordingAndAnalysisAgreement ?? false
                              }
                            />
                          )}
                        </div>
                      )}

                      {hasProcedure && (
                        <div className="font-paragraph-1 flex w-64">
                          {getProcedureVisitTypeLabel(
                            visit.procedures,
                            visit.laboratoryExaminations,
                          )}
                        </div>
                      )}

                      <div className="font-paragraph-1 flex min-w-24 flex-1">
                        <AdditionalVisitInfo visit={visit} />
                      </div>

                      {hasVirtual && !visit.prescriptionRequestId && (
                        <div className="flex">
                          <NotificationViewBlock visitElement={visit} />
                        </div>
                      )}
                    </div>
                  }
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

import { AddIcon, Button, Input } from "@jutro/ui";
import { Dispatch, SetStateAction } from "react";
import {
  AppliedDiscounts,
  VoucherCodeError,
} from "components/PatientDocs/Ordered/types";

type Props = {
  isVoucherCodeLoading: boolean;
  voucherCodeError: VoucherCodeError;
  voucherCodeInput: string;
  discounts?: AppliedDiscounts;
  onVoucherCodeApply: () => void;
  setVoucherCodeError: Dispatch<SetStateAction<VoucherCodeError | null>>;
  setVoucherCodeInput: Dispatch<SetStateAction<string>>;
};

export const VoucherCodeInput = ({
  voucherCodeError,
  voucherCodeInput,
  isVoucherCodeLoading,
  discounts,
  onVoucherCodeApply,
  setVoucherCodeError,
  setVoucherCodeInput,
}: Props) => (
  <Input
    placeholder={
      discounts
        ? discounts.voucher
          ? "Bon prezentowy"
          : "Kod rabatowy"
        : "Kod rabatowy / Bon prezentowy"
    }
    value={voucherCodeInput}
    onChange={(e) => {
      if (
        e.target.value.trim().length < voucherCodeInput.length ||
        e.target.value.trim().length > voucherCodeInput.length
      ) {
        setVoucherCodeError(null);
      }
      setVoucherCodeInput(e.target.value.trim().toUpperCase());
    }}
    error={voucherCodeError?.message}
    sideElementPosition="end"
    sideElement={
      <Button
        size="condensed"
        type="button"
        icon={<AddIcon />}
        loading={isVoucherCodeLoading}
        onClick={onVoucherCodeApply}
        withTooltip={false}
        variant="ghost"
        label="Dodaj Voucher"
      />
    }
  />
);

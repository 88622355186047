import { DragDropContext } from "react-beautiful-dnd";
import { ScreenLoader } from "components/new";
import { useOnDragEnd } from "lib/hooks/useDragEnd.hooks";
import { useWorkspace } from "lib/tools/createWorkspace/useWorkspace";
import { useVisitData } from "views/Visit/hooks";

export const VisitAdviceWorkspace = () => {
  const { data, loading } = useVisitData({ from: "cache-first" });
  const { onDragEnd } = useOnDragEnd(data);
  const workspace = useWorkspace({ name: "visit-advice", width: 3 });

  if (loading) return <ScreenLoader />;

  return <DragDropContext onDragEnd={onDragEnd}>{workspace}</DragDropContext>;
};

import { capitalize } from "@jutro/tools";
import { Column } from "react-table";
import examinationBlood from "assets/examinationBlood.svg";
import examinationUrine from "assets/examinationUrine.svg";
import { ArrowUpIcon } from "components/components/ArrowUpIcon";
import {
  ArrayElement,
  PatientVitality,
  PatientVitalityAnalysis,
} from "lib/types";

const corruptedParams = {
  "stosunek limfocyt�w CD4+/CD8+": "stosunek limfocytów CD4+/CD8+",
  "�elazo": "Żelazo",
  "Przeciwcia�a anty HCV": "Przeciwciała anty HCV",
  "Ci�ar w�a�ciwy": "Ciężar właściwy",
  "Kryszta�y": "Kryształy",
  "Bilirubina ca�kowita": "Bilirubina całkowita",
  "Bia�ko": "Białko",
  "Bilirubina zwi�zana (bezpo�rednia)": "Bilirubina związana (bezpośrednia)",
  "Przeciwcia�a w kl. IgM": "Przeciwciała w kl. IgM",
  "P�ytki krwi": "Płytki krwi", // tutaj
  "Uk�ad AB0": "Układ AB0",
  "Wska�nik protrombiny": "Wskaźnik protrombiny",
  "Przeciwcia�a w surowicy w kl. IgM": "Przeciwciała w surowicy w kl. IgM",
  "Kom�rki grzyb�w": "Komórki grzybów",
  "W��kna mi�sne �le strawione": "Włókna mięsne źle strawione",
  "IgE ca�kowite": "IgE całkowite",
  "Przeciwcia�a ca�kowite": "Przeciwciała całkowite",
  "Wo�": "Woń",
  "Nab�onki okr�g�e": "Nabłonki okrągłe",
  "S�d": "Sód",
  "Wa�eczki": "Wałeczki",
  "Pasma �luzu": "Pasma śluzu",
  "Bia�ko ca�kowite": "Białko całkowite",
  "Wap� ca�kowity": "Wapń całkowity",
  "W��kna mi�sne dobrze strawione": "Włókna mięsne dobrze strawione",
  "Przeciwcia�a anty HBc ca�kowite": "Przeciwciała anty HBc całkowite",
  "Wska�nik HOMA-IR": "Wskaźnik HOMA-IR",
  "Cholesterol ca�kowity": "Cholesterol całkowity",
  "PSA ca�kowity": "PSA całkowity",
  "Niedojrza�e granulocyty IG": "Niedojrzałe granulocyty IG",
  "Kom�rki NKT CD3+CD16+": "Komórki NKT CD3+CD16+",
  "Przejrzysto��": "Przejrzystość",
  "Przeciwcia�a w surowicy w kl. IgG": "Przeciwciała w surowicy w kl. IgG",
  "Kom�rki NK CD 3-16+": "Komórki NK CD 3-16+",
  "Krople t�uszczu": "Krople tłuszczu",
  "Nab�onki p�askie": "Nabłonki płaskie",
  "Limfocyty T podw�jnie dodatnie CD3+CD4+CD8+":
    "Limfocyty T podwójnie dodatnie CD3+CD4+CD8+",
};

export const typeMap = {
  USG: "USG",
  RTG: "RTG",
  SPIRO: "Spirometria",
  EKG: "EKG",
  URINE: "Mocz",
  BLOOD: "Krew",
  BANDAGE: "Zmiana opatrunku",
  OBJECT: "Ciało obce",
  STITCHES: "Szwy",
  VACCINE: "Szczepienie",
  MEDICINE: "Iniekcja",
  DRUGPASS: "Podanie leku",
  INJECTION: "Iniekcja",
  HEARTRATE: "Tętno",
  PRESSURE: "Ciśnienie",
};

export const corruptedAnalysisTypeMap = {
  "Morfologia krwi (pe�na)": "Morfologia krwi (pełna)",
  "Mocz - badanie og�lne": "Mocz - badanie ogólne",
  "Pr�by w�trobowe (ALT, AST, ALP, BIL, GGTP)":
    "Próby wątrobowe (ALT, AST, ALP, BIL, GGTP)",
  "P/c. odporno�ciowe(dawniej t. Coombsa)":
    "P/c. odpornościowe(dawniej t. Coombsa)",
  "Wap� ca�kowity": "Wapń całkowity",
  "S�d": "Sód",
  "�elazo": "Żelazo",
  "CRP, ilo�ciowo": "CRP, ilościowo",
  "PSA ca�kowity": "PSA całkowity",
  "Osad moczu met. cytometrii przep�ywowej":
    "Osad moczu met. cytometrii przepływowej",
};

export const corruptedUnits = {
  "�IU/ml": "µIU/ml",
  "�U/ml": "µU/ml",
  "�g/dl": "µg/dl",
  "/�l": "/μl",
  "tys/�l": "tys/μl",
  "�mol/l": "�mol/l",
  "kom/�L": "kom/μL",
  "mln/�l": "mln/μl",
  "�g/ml": "�g/ml",
};

const corruptedValues = {
  "s�omkowy": "słomkowa",
  "s�omkowa": "słomkowa",
  "���ty": "żółta",
  "���ta": "żółta",
};

const fixFloat = (str: string) => str.split("\\")[0];

export const getIconByType = (
  type: PatientVitalityAnalysis["analysisType"],
) => {
  if (type === "Mocz - badanie og�lne") return examinationUrine;
  return examinationBlood;
};

const normalizeNumber = (num: string) => {
  if (num.includes("\\")) num = num.split("\\")[0];
  if (typeof num === "number") return parseFloat(num);
  return parseFloat(num.replace(",", ".").replace(/[^0-9.]/g, ""));
};

const toNormRelation = (
  param: ArrayElement<PatientVitalityAnalysis["params"]>,
) => {
  const normalized = normalizeNumber(param.value);
  if (!(!isNaN(normalized) && (param.min || param.max))) return "normal";
  if (normalized > parseFloat(param.max)) return "more";
  if (normalized < parseFloat(param.min)) return "less";
  return "normal";
};

const getNormIcon = (
  param: ArrayElement<PatientVitalityAnalysis["params"]>,
) => {
  const normalized = normalizeNumber(param.value);
  if ((!param.min && !param.max) || isNaN(normalized)) return;

  if (normalized > parseFloat(param.max))
    return <ArrowUpIcon color="#FF453A" />;
  if (normalized < parseFloat(param.min))
    return <ArrowUpIcon color="#319AD5" down />;
  return null;
};

const fromTextParams = ["Kryszta�y"];

export const parseParamName = (
  param: ArrayElement<PatientVitalityAnalysis["params"]>,
  examination: PatientVitality,
) => {
  if (
    param.name === "Wynik badania:" &&
    examination.type === "ANALYSIS" &&
    examination.params.length === 1
  )
    return `${examination.analysisType} `;
  if (fromTextParams.includes(param.name) && param.text !== "")
    return capitalize(param.text.replace("\\", ""));
  if (param.name in corruptedParams)
    return `${corruptedParams[param.name as keyof typeof corruptedParams]} `;
  return `${param.name} `;
};
const generateClassNameForEachRow = (
  row: ArrayElement<PatientVitalityAnalysis["params"]>,
) => {
  const normRelation = toNormRelation(row);
  if (normRelation === "more") return "text-jutro-new-rose-600";
  if (normRelation === "less") return "text-jutro-new-blue-500";
  return "text-jutro-new-blue-950";
};

export const examinationParamsColumns = ({
  examination,
}: {
  examination: PatientVitality;
  everyExaminationTypeSame: boolean;
  everyParamSame: boolean;
}): Column<ArrayElement<PatientVitalityAnalysis["params"]>>[] => [
  {
    Header: "Parametr",
    width: 200,
    accessor: (row) => (
      <span className={generateClassNameForEachRow(row)}>
        {parseParamName(row, examination)}
      </span>
    ),
  },
  {
    Header: " ",
    width: 40,
    accessor: (row) => (
      <>
        {examination.type === "ANALYSIS" && examination.params.length && (
          <span className="basis-10">{getNormIcon(row)}</span>
        )}
      </>
    ),
  },
  {
    Header: "Wynik",
    width: 70,
    accessor: (row) => (
      <span className={generateClassNameForEachRow(row)}>
        {row.value
          ? row.value in corruptedValues
            ? corruptedValues[row.value as keyof typeof corruptedValues]
            : fixFloat(row.value)
          : ""}
      </span>
    ),
  },
  {
    Header: "Jedn.",
    width: 70,
    accessor: (row) => (
      <span className={generateClassNameForEachRow(row)}>
        {row.unit
          ? row.unit in corruptedUnits
            ? corruptedUnits[row.unit]
            : row.unit
          : "-"}
      </span>
    ),
  },
  {
    Header: "Min",
    width: 70,
    accessor: (row) => (
      <span className={generateClassNameForEachRow(row)}>
        {row.min ? row.min.toString().replace(".", ",") : "-"}
      </span>
    ),
  },
  {
    Header: "Max",
    width: 70,
    accessor: (row) => (
      <span className={generateClassNameForEachRow(row)}>
        {row.max ? row.max.toString().replace(".", ",") : "-"}
      </span>
    ),
  },
  {
    Header: "Komentarz",
    width: "100%",
    accessor: (row) => (
      <span className={generateClassNameForEachRow(row)}>
        {row.text ? row.text : "-"}
      </span>
    ),
  },
];

import { MessageHeader } from "components/NewMessageUtils/MessageHeader";
import { DoctorLaboratoryExaminationsQuery } from "lib/graphql/megaSchema";
import { useExaminationPanelMessage } from "./useExaminationPanelMessage";

export const LaboratoryExaminationsMessage = ({
  content,
}: {
  content: string;
}) => {
  const { laboratoryExaminations } = useExaminationPanelMessage(content);

  if (!laboratoryExaminations.length) return null;

  const calculatedPrice = laboratoryExaminations.reduce((a, b) => {
    if (b.payment === "FREE") {
      return a;
    }

    return a + b.assignment.original;
  }, 0);

  const summedPrice = laboratoryExaminations.reduce((a, b) => {
    return a + b.assignment.original;
  }, 0);

  const finalPrice = summedPrice - calculatedPrice;

  const isFree = (
    e: DoctorLaboratoryExaminationsQuery["doctorLaboratoryExaminations"][0],
  ) => e.payment === "FREE";

  return (
    <div className="calcContainer font-geologica relative my-9 ml-0">
      <MessageHeader mine={false} />
      <div className="rounded-lg rounded-tl-none bg-jutro-new-warm-gray-50">
        <div className="font-paragraph-2 whitespace-pre-line break-words px-2 py-3 text-jutro-new-blue-950">
          Badania, które zostały zlecone pacjentowi
        </div>
        <div className="flex w-full flex-col overflow-hidden rounded-bl-lg rounded-br-lg bg-white">
          <div className="flex w-full flex-col border border-jutro-new-warm-gray-50 pb-3 pr-3 pt-4">
            <div className="flex flex-col gap-y-4 p-3 py-0">
              <div className="flex flex-col justify-center gap-3">
                {laboratoryExaminations.map((e) => {
                  return (
                    <div
                      key={e.id}
                      className="flex items-center justify-between"
                    >
                      <div className="font-paragraph-2 w-4/5">
                        {e.assignment.name}
                      </div>
                      <div
                        className={`font-paragraph-2 ${
                          isFree(e) ? "text-jutro-new-green-400" : "text-black"
                        }`}
                      >
                        {e.assignment.original
                          ? `${e.assignment.original.toFixed(2)} zł`
                          : "0.00 zł"}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="font-paragraph-2 flex items-center justify-between">
                  <span>Całkowity koszt</span>
                  <span>{summedPrice.toFixed(2)} zł</span>
                </div>
                <div className="font-paragraph-2 flex items-center justify-between">
                  <span>Opłaca Jutro Medical</span>
                  <span className="text-jutro-new-green-400">
                    -{finalPrice.toFixed(2)} zł
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <span className="font-paragraph-2 font-bold">Podsumowanie</span>
                <span className="font-heading-3">
                  {calculatedPrice.toFixed(2)} zł
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

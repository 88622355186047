import dayjs from "dayjs";
import { envVars } from "envvars";
import { DoctorImageMessage } from "components/Messages/DoctorImageMessage";
import { FileMessage } from "components/Messages/FileMessage";
import { MultipleFilesMessage } from "components/Messages/MultipleFilesMessage";
import { extractChatMessageComponents } from "lib/tools/extractChatMessageComponents";
import { fileConfig, httpConfig } from "lib/tools/httpConfigs";

type FileHandlerMessageProps = {
  id: string;
  content: string;
  isStaff: boolean;
  iso: any;
  authorId: string | undefined;
  authorName: string;
};

export const FileHandlerMessage = ({
  id,
  content,
  authorId,
  isStaff,
  authorName,
  iso,
}: FileHandlerMessageProps) => {
  const { files, message } = extractChatMessageComponents(content);

  if (files?.length === 0 || !files) {
    return null;
  }

  const [fileId] = files;
  const lastDotIndex = fileId.lastIndexOf(".");
  const extension = fileId.slice(lastDotIndex + 1);

  if (
    !(
      extension &&
      ["jpg", "png", "jpeg", "heic"].includes(extension.toLowerCase())
    )
  ) {
    return (
      <FileMessage
        id={id}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={isStaff}
        filename={fileId.substring(36, fileId.length)}
        extension={extension}
        href={`${fileConfig[envVars.REACT_APP_CONFIG]}` + fileId}
      />
    );
  }

  if (files.length < 2) {
    return (
      <DoctorImageMessage
        id={id}
        optionalTextMessage={message ? message : null}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={isStaff}
        image={`${fileConfig[envVars.REACT_APP_CONFIG]}` + fileId}
        onClickStandard
      />
    );
  }

  return (
    <div data-hj-suppress>
      <MultipleFilesMessage
        optionalTextMessage={message ? message : null}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        mine={isStaff}
        files={files}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
      />
    </div>
  );
};

import { BinIcon, Button, FolderDocumentIcon, Input } from "@jutro/ui";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ChatImageInput } from "components/ChatImageInput/ChatImageInput";
import { useChatImageInput } from "components/ChatImageInput/ChatImageInput.hooks";
import { TextareaBlock as Textarea } from "components/Textarea";
import { CustomDatePicker as DatePicker } from "components/components/Doctor/CustomDatePicker";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { SpecificInput } from "components/types";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";
import { Maybe, PatientVitality } from "lib/types";
import { AddVital } from "../types";

const formatBytes = (a: number, b = 2) => {
  if (a === 0) return "0 Bytes";
  const c = b < 0 ? 0 : b;
  const d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

type GeneralProps = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  patientId: string;
};

type PropsUpdate = GeneralProps & {
  data: Maybe<PatientVitality>;
  submit: (id: string, specificInput: SpecificInput) => void;
  setCloseSlideSheet: () => void;
  mode: "update";
};

type PropsCreate = GeneralProps & {
  submit: (args: AddVital) => void;
  mode: "create";
};

type Props = PropsUpdate | PropsCreate;

type FormData = {
  customComment: string;
};

export const VitalDialog = (props: Props) => {
  const [customName, setCustomName] = useState("");
  const [customDate, setCustomDate] = useState(new Date());
  const [files, setFiles] = useState<string[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const [filesAdded, setFilesAdded] = useState<(string | File)[]>([]);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const { control, getValues, setValue } = useForm<FormData>();

  const { imagesData } = useChatImageInput(props.patientId);

  const isUpdateMode = props.mode === "update";

  const uploadFileFn = async (file: File) => {
    const res = await uploadFile(file);
    setFileIds([...fileIds, res]);
  };

  useEffect(() => {
    if (!isUpdateMode) return;
    if (!props.data) {
      setCustomName("");
      setCustomDate(new Date());
      setValue("customComment", "");
      setFiles([]);
      setFilesAdded([]);

      return;
    }
    if (props.data.type === "OTHER" && props.data.customName)
      setCustomName(props.data.customName);
    if (props.data.type === "OTHER" && props.data.customDate.iso)
      setCustomDate(new Date(props.data.customDate.iso));
    if (props.data.type === "OTHER" && props.data.customComment)
      setValue("customComment", props.data.customComment);
    if (props.data.overrideFiles) {
      setFiles(
        props.data.overrideFiles.filter((e) =>
          e ? imagesData.includes(e) : false,
        ),
      );
      setFilesAdded(
        props.data.overrideFiles.filter((e) =>
          e ? !imagesData.includes(e) : false,
        ),
      );
    }
  }, [isUpdateMode && props.data, props.isOpen]);

  const handleOnConfirm = () => {
    if (!(files.length || fileIds.length || filesAdded.length))
      return toaster.warning(
        isUpdateMode && props.data
          ? "Nie można zapisać badania bez żadnych plików"
          : "Nie można stworzyć badania bez żadnych plików",
      );

    if (isUpdateMode) {
      if (!props.data?.id) return;

      props.submit(props.data.id, {
        overrideFiles: [...files, ...fileIds, ...filesAdded].filter(
          (item) => Object.keys(item).length,
        ),
        customName,
        customComment: getValues("customComment"),
        customDate,
      });
      props.setOpen(false);
      props.setCloseSlideSheet();
      return;
    }

    props.submit({
      files: [...files, ...fileIds].filter((item) => Object.keys(item).length),
      customName,
      customComment: getValues("customComment"),
      customDate,
    });

    setCustomName("");
    setCustomDate(new Date());
    setValue("customComment", "");
    setFileIds([]);
    setFiles([]);
    setFilesAdded([]);
    props.setOpen(false);
  };

  return (
    <ConfirmDialog
      open={props.isOpen}
      setOpen={props.setOpen}
      title={isUpdateMode && props.data ? "Edytuj badanie" : "Dodaj badanie"}
      confirmText={isUpdateMode && props.data ? "Zapisz" : "Dodaj"}
      submitVariant="primary"
      onConfirm={handleOnConfirm}
      shouldHideOverflow={false}
      isResponsive
    >
      <div className="flex flex-wrap justify-between">
        <div className="mb-3 min-w-[400px]">
          <div className="mb-3">
            <Input
              value={customName}
              onChange={(e) => setCustomName(e.target.value)}
              placeholder="Typ badania"
              label="Typ badania"
            />
          </div>

          <div className="mb-3">
            <Controller
              name="customComment"
              control={control}
              render={({ field: { onChange, value }, ...rest }) => (
                <Textarea
                  title=" Komentarz / rodzaj badania"
                  placeholder="Komentarz / rodzaj badania"
                  value={value}
                  noPadding
                  resize={false}
                  big
                  onChange={(option) => onChange(option ?? "")}
                  {...rest}
                />
              )}
            />
          </div>

          <div className="mb-3">
            <div className="flex flex-col">
              <span className="font-paragraph-2 text-jutro-new-warm-gray-800">
                Data badania
              </span>

              <DatePicker
                format="dd/MM/yyy"
                locale="pl"
                onChange={(value: null | string) => {
                  if (!value) return;
                  const currentDate = new Date();
                  const date = new Date(value);
                  date.setHours(currentDate.getHours());
                  date.setMinutes(currentDate.getMinutes());
                  setCustomDate(date);
                }}
                value={customDate}
                big
              />
            </div>
          </div>

          <ChatImageInput
            onSelect={setFiles}
            selected={files}
            patientId={props.patientId}
          />

          <div>
            <div className="mb-2 mt-4 flex items-center gap-2">
              <Button
                full={false}
                onClick={() => {
                  fileRef.current?.click();
                }}
                variant="secondary"
                icon={<FolderDocumentIcon />}
                text="Wybierz z dysku"
                size="condensed"
              />
            </div>

            <div>
              {filesAdded.map((file, index) => (
                <div
                  className="text-md flex items-center justify-between text-jutro-new-warm-gray-700"
                  key={file instanceof File ? file.name : file}
                >
                  {file ? (
                    <>
                      {file instanceof File ? (
                        <>
                          {file.name}
                          {file.size && (
                            <span className="ml-1 text-jutro-new-warm-gray-300">
                              {formatBytes(file.size)}
                            </span>
                          )}
                        </>
                      ) : (
                        <span>{file.substring(36)}</span>
                      )}
                    </>
                  ) : (
                    "Wadliwy plik"
                  )}

                  <Button
                    icon={<BinIcon />}
                    size="condensed"
                    variant="negativeGhost"
                    label="Usuń"
                    tooltipPosition="top"
                    full={false}
                    onClick={() =>
                      setFilesAdded(
                        filesAdded.filter(
                          (_, fileAddedIndex) => index !== fileAddedIndex,
                        ),
                      )
                    }
                  />
                </div>
              ))}
            </div>

            <input
              ref={fileRef}
              type="file"
              className="hidden"
              onChange={(e) => {
                if (!e.target.files?.length) return;

                const [file] = e.target.files;
                setFilesAdded([...filesAdded, file]);
                uploadFileFn(file);
              }}
            />
          </div>
        </div>
      </div>
    </ConfirmDialog>
  );
};

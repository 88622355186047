import { useState } from "react";
import { WelcomeTexts } from "./components/WelcomeTexts";
import medic from "assets/medic.svg";
import leftPlant from "assets/plant-left.svg";
import rightPlant from "assets/plant-right.svg";
import { SendMfa } from "./Steps/SendMfa";
import { SendSms } from "./Steps/SendSms";
import { Aside, LeftPlant, Medic, RightPlant, Section } from "./components";

export const LoginScreen = () => {
  const [phone, setPhone] = useState("");

  return (
    <div className="font-geologica flex h-screen w-screen">
      <Section>
        <div className="mx-auto my-48 flex w-[540px] flex-col justify-center gap-2 2xl:gap-3">
          <WelcomeTexts phone={phone} />
          {phone ? <SendMfa phone={phone} /> : <SendSms setPhone={setPhone} />}
        </div>
      </Section>

      <Aside />
      <Medic src={medic} alt="medic" />
      <LeftPlant src={leftPlant} alt="left-plant" />
      <RightPlant src={rightPlant} alt="right-plant" />
    </div>
  );
};

import { BinIcon } from "@jutro/ui";
import { ReactElement, useState } from "react";
import { MessageHeader } from "components/NewMessageUtils/MessageHeader";
import { MessageWrapper } from "components/NewMessageUtils/MessageWrapper";
import { Loader } from "components/new";
import { useDeleteMessageMutation } from "components/new/Chat/hooks";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { toaster } from "lib/tools/toaster";

export type Props = {
  id: string;
  authorImage: string;
  authorName: string;
  messageContent: ReactElement | string;
  messageTime: string;
  mine?: boolean;
  starred?: boolean;
  loader?: boolean;
  flex?: boolean;
  optimistic?: boolean;
  isPrescription?: boolean;
  isRemovable?: boolean;
};

export const ChatMessage = ({
  id,
  authorImage,
  authorName,
  messageContent,
  messageTime,
  mine,
  optimistic,
  isPrescription = false,
  isRemovable = true,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [removing, setRemoving] = useState(false);

  const { removeMessage } = useDeleteMessageMutation();

  const renderDeleteOption = () => {
    if (removing) return "usuwam...";

    if (isPrescription) return "usuń receptę";

    return (
      <div className="h-4 w-4 cursor-pointer text-white hover:opacity-80">
        <BinIcon size="sm" />
      </div>
    );
  };

  return (
    <MessageWrapper mine={mine}>
      <MessageHeader
        authorImage={authorImage}
        authorName={authorName}
        mine={mine}
      />

      <div className="whitespace-pre-line break-words">{messageContent}</div>
      <div className="flex justify-between">
        <div className="font-paragraph-2">{messageTime}</div>
        {optimistic ? (
          <div className="-my-1 flex justify-end">
            <Loader size="xs" />
          </div>
        ) : (mine && authorName !== "Jutro Medical") ||
          (isRemovable && isPrescription) ? (
          <div
            onClick={() => {
              if (removing) return;

              if (!id) {
                toaster.notify(
                  isPrescription
                    ? "Zaczekaj aż recepta zostanie wystawiona!"
                    : "Zaczekaj aż wiadomość zostanie wysłana!",
                );
                return;
              }
              setOpen(true);
            }}
            className={`cursor-pointer text-xs ${
              isPrescription
                ? "text-jutro-new-warm-gray-600"
                : "text-jutro-new-warm-gray-300"
            }`}
          >
            {renderDeleteOption()}
          </div>
        ) : null}
      </div>
      <ConfirmDialog
        open={open}
        title={isPrescription ? "Usuwanie recepty" : "Usuwanie wiadomości"}
        text={
          isPrescription
            ? "Czy na pewno chcesz usunąć receptę?"
            : "Czy na pewno chcesz usunąć wiadomość?"
        }
        onConfirm={async () => {
          try {
            setRemoving(true);
            toaster.notify(
              isPrescription ? "Usuwam receptę!" : "Usuwam wiadomość!",
            );
            await removeMessage(id);
            toaster.success(
              isPrescription ? "Recepta usunięta!" : "Wiadomość usunięta!",
            );
            setRemoving(false);
          } catch (e) {
            toaster.error(
              isPrescription
                ? "Nie udało się usunąć recepty!"
                : "Nie udało się usunąć wiadomości!",
            );
          } finally {
            setRemoving(false);
          }
        }}
        setOpen={setOpen}
      />
    </MessageWrapper>
  );
};

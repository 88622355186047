import { DiamondQuestionIcon } from "@jutro/ui";
import { DoctorDeclarationSubmittedFragment } from "lib/graphql/megaSchema";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";
import { declarationTypeMap } from "views/Patient/Profile/DeclarationHistory";
import { NfzEvent, NfzEventPrefix } from "../NfzEvent";
import { EventHandler } from "../NfzEvent/infer";

export const DeclarationSubmittedEventHandler: EventHandler<
  DoctorDeclarationSubmittedFragment
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_SUBMIT}
      statusText="Status nieznany"
      color="gray"
      icon={<DiamondQuestionIcon size="sm" />}
      tooltip={<TooltipConent event={event} />}
    />
  );
};

type TooltipConentProps = {
  event: DoctorDeclarationSubmittedFragment;
};

const TooltipConent = ({ event }: TooltipConentProps) => {
  const { comment, doctor, source } = event;

  return (
    <div className="flex flex-col gap-1">
      <span>{`Typ deklaracji: ${declarationTypeMap[source]}`}</span>
      <span>{`${
        doctor.genre === "DOCTOR" ? "Lekarz: " : "Pielęgniarka: "
      } ${getDisplayDoctor(
        doctor.firstName,
        doctor.lastName,
        "Brak informacji",
      )}`}</span>
      <span>{`Komentarz: ${comment || "-"}`}</span>
    </div>
  );
};

import * as R from "ramda";
import { useEffect, useState } from "react";
import { ObjectSchema } from "yup";

export type RegisterData = {
  [x: string]: any;
  onBlur: any;
  id: string;
  name: string;
  errors: string;
  checked: boolean;
};

export type Options = {
  get?: string;
  set?: string;
  value?: any;
  id?: string;
  name?: string;
};

export type DefaultData = {
  [key: string]: any;
};

export type HookParams = {
  schema?: ObjectSchema<{ [key: string]: any }>;
  object?: DefaultData;
  context?: any;
  cast?: boolean;
  onChange?: any;
  debounce?: any;
};

export type ArrPath = (string | number)[];

export type ErrorParams =
  | { for: string | ArrPath }
  | { has: string | ArrPath }
  | Record<string, never>;

const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

const useForm = (params?: HookParams) => {
  const [obj, setObj] = useState<any>(params?.object || {});
  const [touchObj, setTouchObj] = useState({});
  const [submitMode, setSubmitMode] = useState(false);
  const isArray = (v: any): v is any[] => R.is(Array, v);
  const arraify = (v: string): ArrPath => [v];

  const debouncedObj = useDebounce(obj, params?.debounce ?? 0);
  useEffect(() => {
    if (debouncedObj && params?.onChange) {
      params.onChange(debouncedObj);
    }
  }, [debouncedObj]);

  const setPath =
    (setObj: (...args: any[]) => any) => (path: ArrPath) => (val: any) => {
      const isJSON = val?.target?.value?.[0] === "{";
      const lens = R.lensPath(path);

      if (isJSON && !val?.target?.checked) {
        setObj((prev: DefaultData) => R.set(lens, null, prev));
        return null;
      }

      const value =
        R.is(Object, val) && "target" in val
          ? val.target?.type === "checkbox"
            ? isJSON
              ? val.target?.value
              : val.target.checked
            : val.target?.type === "radio" &&
              ["true", "false"].includes(val.target?.value)
            ? val.target?.value === "true"
            : ["radio", "checkbox", "number"].includes(val.target?.type) &&
              !isNaN(Number(val.target?.value))
            ? Number(val.target?.value)
            : val.target?.value
          : val;

      setObj((prev: DefaultData) =>
        R.set(lens, isJSON ? JSON.parse(value) : value, prev)
      );
    };

  const getPath = (obj: Record<string, unknown>) => (path: ArrPath) => {
    const lens = R.lensPath(path);
    return R.view(lens, obj);
  };

  const register = (
    path: string | ArrPath,
    options?: Options,
    errors?: boolean
  ): RegisterData => {
    const get = options?.get || "value";
    const set = options?.set
      ? options.set
      : options?.get
      ? `set${options.get.slice(0, 1).toUpperCase() + options.get.slice(1)}`
      : "onChange";

    const isJSON = R.is(Object, options?.value);
    const value = isJSON ? JSON.stringify(options?.value) : options?.value;
    const arrPath = isArray(path) ? path : arraify(path);
    const pathValue: any = getPath(obj)(arrPath);

    return {
      [get]:
        value && R.is(Boolean, value)
          ? value.toString()
          : value ?? pathValue ?? "",
      [set]: setPath(setObj)(arrPath),
      onBlur: () => setPath(setTouchObj)(arrPath)(true),
      id:
        options?.id ??
        arrPath.join("-") + (value != null ? "-" + value.toString() : ""),
      name: options?.id || arrPath.join("-"),
      errors: errors ? "exist" : "",
      checked:
        value !== undefined
          ? value === (isJSON ? JSON.stringify(pathValue) : pathValue)
          : pathValue === R.is(Boolean, pathValue),
    };
  };

  const stringifyArrPath = (arrPath: ArrPath): string =>
    arrPath
      .map((el) => (R.is(Number, el) ? `[${el}]` : el))
      .join(".")
      .replace(/\.\[/g, "[")
      .replace(/\.\]/g, "]");

  const getError = (v: ErrorParams, errors: any) => {
    if ("for" in v) {
      const arrPath = isArray(v.for) ? v.for : arraify(v.for);
      if (!getPath(touchObj)(arrPath) && !submitMode) return [];
      return errors[stringifyArrPath(arrPath)] || [];
    }

    if ("has" in v) {
      const arrPath = isArray(v.has) ? v.has : arraify(v.has);
      const sub = params?.schema?.fields as any;

      if (sub?.[`${v.has}`]?.type === "object") {
        if (Object.keys(sub?.[`${v.has}`].fields).length === 0) {
          if (!getPath(touchObj)(arrPath) && !submitMode) return false;
          return errors[stringifyArrPath(arrPath)]?.length > 0 || false;
        }
        if (!getPath(touchObj)(arrPath) && !submitMode) return false;
        const fields = Object.keys(sub?.[`${v.has}`].fields);
        return fields.some((f) => errors[`${v.has}.${f}`]?.length > 0);
      }

      if (!getPath(touchObj)(arrPath) && !submitMode) return false;
      return (
        errors[stringifyArrPath(arrPath)]?.length > 0 ||
        errors[arrPath[0]]?.length > 0 ||
        false
      );
    }

    return errors;
  };

  const exported = {
    data: params?.schema && params.cast ? params.schema.cast(obj) : obj,
    register,
    valid: true,
    utils: (v: { submitMode?: boolean; reset?: boolean; info?: string }) => {
      if (v.reset) {
        setObj(R.clone(params?.object) || {});
        setTouchObj(R.clone({}));
      }
      if (v.submitMode != null) setSubmitMode(v.submitMode);
      if (v.info === "resetable" && params && params.object)
        return JSON.stringify(params.object) !== JSON.stringify(obj);
    },
    errors: () => "",
  };

  try {
    if (params?.schema)
      params.schema.validateSync(obj, {
        abortEarly: false,
        context: obj,
      });
    return exported;
  } catch (errorsArr) {
    const errors = R.zipObj(
      (errorsArr as any)?.inner?.map((err: any) => err.path) || [],
      (errorsArr as any)?.inner?.map((err: any) =>
        err.errors ? err.errors[0] : false
      ) || []
    );

    return {
      ...exported,
      register: (path: string | ArrPath, options?: Options) =>
        register(path, options, getError({ has: path }, errors)),
      errors: (v: ErrorParams) => getError(v, errors),
      valid: false,
    };
  }
};

export { useForm };

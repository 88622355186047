import { GlobalHotKeys } from "react-hotkeys";
import { useFlag } from "lib/hooks/flags";

export const HotkeysProvider = () => {
  const keyMap = { SECRET: "j u t r o" };
  const handlers = { SECRET: () => alert("Znaleziono sekret! 🎉") };
  const shortcutsEnabled = useFlag("shortcuts");
  if (!shortcutsEnabled) return null;
  // @ts-ignore
  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};

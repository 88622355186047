import { Input } from "@jutro/ui";
import { DoctorTemplateParamsFragment } from "lib/graphql/megaSchema";

type Props = {
  param: DoctorTemplateParamsFragment;
  onValueChange: (newValue: string) => void;
};

export const LabExamParameter = ({ param, onValueChange }: Props) => {
  return (
    <li key={param.name} className="my-2 flex">
      <div className="ml-2 flex w-full flex-col items-center space-x-2">
        <Input
          label={param.name}
          onChange={(e) => onValueChange(e.target.value)}
          value={param.value}
        />

        <div className="flex space-x-1">
          <span>Unit: {`${param.unit ? param.unit : "N/A"}`}</span>
          <span>Min: {`${param.min ? param.min : "N/A"}`}</span>
          <span>Max: {`${param.max ? param.max : "N/A"}`}</span>
        </div>
      </div>
    </li>
  );
};

import { PropsWithChildren } from "react";
import { TileWidth } from "lib/tools/createWorkspace/types";

type Props = {
  width: TileWidth;
};

export const TileElement = ({ width, children }: PropsWithChildren<Props>) => (
  <div
    className={`${
      { 1: "col-span-1", 2: "col-span-2" }[width]
    } no-scrollbar flex h-full flex-col overflow-y-auto overflow-x-hidden`}
  >
    {children}
  </div>
);

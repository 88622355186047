import { useDoctorNfzHistoryQuery } from "lib/graphql/megaSchema";
import { usePatientData } from "views/Patient/hooks";

export const useChangeHistory = () => {
  const { data: patientData } = usePatientData({ from: "cache-only" });

  const { data, loading, error } = useDoctorNfzHistoryQuery({
    variables: {
      patientId: patientData?.id ?? "",
    },
    skip: !patientData?.id,
  });

  return {
    data,
    loading,
    error,
  };
};

import { ProcedureElementFiles } from "components/ExaminationPanel/ProcedurePanel/ProcedureElementFiles";

type Props = {
  label: string;
  procedureFiles: string[];
  procedureId: string;
  disabled?: boolean;
  labelWeight?: "medium" | "bold";
};

export const FileInput = ({
  label,
  labelWeight = "medium",
  ...props
}: Props) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <span
        className={`text-xs ${
          labelWeight === "medium" ? "font-medium" : "font-bold"
        }`}
      >
        {label}
      </span>
      <ProcedureElementFiles {...props} />
    </div>
  );
};

import dayjs from "dayjs";
import { Maybe } from "lib/types";

export const isVisitScheduled = (plannedStart: Maybe<string>) => {
  if (!plannedStart) {
    return false;
  }

  const isScheduled = dayjs().subtract(2, "hour").isBefore(plannedStart);

  if (!isScheduled) {
    return false;
  }

  return true;
};

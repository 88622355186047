import dayjs from "dayjs";
import { ReactNode } from "react";
import { MessageElement } from "components/new/Chat/Message/Message";

type PrescriptionElement = Exclude<
  MessageElement["prescription"],
  null | undefined
>;

const getEndsToWorkDate = (
  startsToWorkIso: string,
  endsToWork?: PrescriptionElement["endsToWork"],
  in365days?: boolean,
) => {
  const start = dayjs(startsToWorkIso);

  if (in365days) {
    return start.add(1, "year");
  }

  if (!endsToWork) {
    return start.add(1, "month");
  }

  return dayjs(endsToWork.iso);
};

export const getRealizationTime = (
  startsToWork?: PrescriptionElement["startsToWork"],
  endsToWork?: PrescriptionElement["endsToWork"],
  in365days?: boolean,
) => {
  if (!startsToWork) {
    return "-";
  }

  const start = dayjs(startsToWork.iso);

  const end = getEndsToWorkDate(startsToWork.iso, endsToWork, in365days);

  return `od ${start.format("D MMMM YYYY")} do ${end.format("D MMMM YYYY")}`;
};

type PrescriptionContentProps = {
  prescription: MessageElement["prescription"];
};

export const PrescriptionContent = ({
  prescription,
}: PrescriptionContentProps) => {
  if (!prescription) {
    return <span>Dokument został anulowany przez lekarza.</span>;
  }

  const { startsToWork, endsToWork, doctor, code, patient } = prescription;

  const realizationTime = getRealizationTime(startsToWork, endsToWork);

  return (
    <div className="flex flex-col divide-y divide-black">
      <div className="pb-3">
        <span>
          eRecepta została wystawiona przez
          <span className="font-semibold">
            {` ${doctor.firstName} ${doctor.lastName} `}
          </span>
          poprawnie. Pacjent otrzymał informację o sposobie realizacji recepty w
          aptece.
        </span>
      </div>
      <SectionWrapper>
        <div className="flex flex-col">
          <span>
            <span className="font-semibold">Kod eRecepty </span>
            {code}
          </span>
          <span>
            <span className="font-semibold">PESEL pacjenta </span>
            {patient.pesel}
          </span>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <header className="font-semibold">Możliwość realizacji</header>
        <span>{realizationTime}</span>
      </SectionWrapper>
      <SectionWrapper>
        <header className="font-semibold">Lista leków</header>
        <div className="flex flex-col gap-1.5">
          {prescription.drugs.map(
            ({
              drug,
              dose,
              boxAmount,
              cost,
              interval,
              form,
              package: quantity,
            }) => {
              const formAndQuantity = quantity ? `${form}, ${quantity}` : form;

              return (
                <div key={drug}>
                  <header className="font-semibold">{`${boxAmount} x ${drug} ${dose} (${formAndQuantity}) / ${cost}`}</header>
                  <span>{`- ${interval}`}</span>
                </div>
              );
            },
          )}
        </div>
      </SectionWrapper>
      {prescription.resourceIsCompatibleWithCez === false ? (
        <SectionWrapper>
          <header className="font-semibold text-jutro-new-rose-500">
            Uwaga
          </header>
          <span className="text-jutro-new-rose-500">
            Recepta nie przeszła pomyślnie weryfikacji bezpieczeństwa. Usuń
            receptę i skontaktuj się z zespołem testerskim.
          </span>
        </SectionWrapper>
      ) : null}
    </div>
  );
};

type SectionWrapperProps = {
  children: ReactNode;
};

const SectionWrapper = ({ children }: SectionWrapperProps) => {
  return <div className="py-3">{children}</div>;
};

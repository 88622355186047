import { PropsWithChildren } from "react";
import defaultAvatar from "assets/default-avatar.png";

type MessageContainerProps = {
  flex?: boolean;
  mine?: boolean;
};

export const MessageContainer = ({
  children,
  mine,
}: PropsWithChildren<MessageContainerProps>) => {
  return (
    <div
      className={`font-geologica relative mb-5 w-[calc(100%-70px)] ${
        mine && "ml-[70px]"
      }`}
    >
      {children}
    </div>
  );
};

export const AuthorImage = ({ src }: { src: string }) => (
  <img
    className="ml-2 h-6 w-6 rounded-[50%] bg-white"
    src={src}
    onError={(e: any) => {
      e.target.onerror = null;
      e.target.src = defaultAvatar;
    }}
  />
);

type AuthorSectionProps = {
  mine?: boolean;
};

export const AuthorSection = ({
  children,
  mine,
}: PropsWithChildren<AuthorSectionProps>) => {
  return (
    <div
      className={`mb-1 flex flex-row-reverse justify-start text-xs capitalize text-jutro-new-warm-gray-300 ${
        mine ? "text-right" : "text-left"
      }`}
    >
      {children}
    </div>
  );
};

type MessageFooterProps = {
  footerBackground?: string;
  mine?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const MessageFooter = ({
  children,
  footerBackground = "bg-jutro-new-blue-500",
  mine,
  disabled,
  onClick,
}: PropsWithChildren<MessageFooterProps>) => {
  return (
    <div
      className={`font-paragraph-2 relative flex w-full justify-between whitespace-pre text-white ${
        mine
          ? "rounded-bl-none rounded-br-none p-0"
          : "rounded-bl-lg rounded-br-lg p-[10px]"
      } ${disabled && "cursor-not-allowed"} ${footerBackground}`}
      onClick={() => {
        if (disabled || !onClick) {
          return;
        }
        onClick();
      }}
    >
      {children}
    </div>
  );
};

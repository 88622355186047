import { find, flatten, without } from "ramda";
import { useDoctorChatMessagesContentQuery } from "lib/graphql/megaSchema";
import { extractChatMessageComponents } from "lib/tools/extractChatMessageComponents";

type ReturnedDataType = "files" | "images";

export const useChatImageInput = (
  patientId: string,
  selected?: any,
  onSelect?: any,
) => {
  const {
    data: chatMessages,
    loading,
    error,
  } = useDoctorChatMessagesContentQuery({
    variables: {
      patientId: patientId,
    },
  });

  if (error) {
    console.error("errror fetching chat images", error);
  }

  const outImages = (e: string, type: ReturnedDataType) => {
    if (!e) {
      return false;
    }
    const lastDotIndex = e.lastIndexOf(".");
    const extension = e.slice(lastDotIndex + 1).toLowerCase();

    const isImage = ["jpg", "png", "jpeg", "heic"].includes(extension);

    if (type === "images") {
      return isImage;
    }

    if (type === "files") {
      return !isImage;
    }
  };

  const isFile = (content: string | null | undefined) => {
    if (!content) {
      return [];
    }

    return content.match(/\[file-([^\]]+.{2,})\]/);
  };

  const returnFilesData = (type: ReturnedDataType) => {
    if (!chatMessages || !chatMessages.doctorChatMessages.length) {
      return [];
    }

    return flatten(
      chatMessages.doctorChatMessages.map(({ content }) => {
        if (!isFile(content)) {
          return [];
        }
        const { files } = extractChatMessageComponents(content);

        if (!files || !files.length) {
          return [];
        }

        return files;
      }),
    )
      .filter((e) => e)
      .filter((e) => outImages(e, type))
      .reverse();
  };

  return {
    imagesData: returnFilesData("images"),
    filesData: returnFilesData("files"),
    loading,
    setImagesSelected: (fileId: any) => {
      onSelect(
        find((e) => e === fileId)(selected)
          ? without([fileId], selected)
          : selected.concat(fileId),
      );
    },
  };
};

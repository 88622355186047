import { useFormContext } from "react-hook-form";
import { TagForm } from "components/TagFormNew/types";
import { InputWrapper } from "../InputWrapper";
import { TagFormSchema } from "../hooks";
import { DocumentationSection } from "./DocumentationSection";
import { HistorySection } from "./HistorySection";
import { MedicineListSection } from "./MedicineListSection";
import { NameSection } from "./NameSection";
import { RadioSection } from "./RadioSection";
import { SubmitSection } from "./SubmitSection";

type SectionsProps = {
  patientId: string | null;
  tagForm: TagForm;
};

export const Sections = ({ patientId, tagForm }: SectionsProps) => {
  const { register, watch } = useFormContext<TagFormSchema>();

  const [currentType, confirmedBy] = watch(["type", "confirmationType"]);

  if (!currentType || !patientId) {
    return null;
  }

  return (
    <>
      <RadioSection tagForm={tagForm} />
      {confirmedBy === "DOCUMENTATION" && (
        <DocumentationSection tagForm={tagForm} patientId={patientId} />
      )}
      <NameSection />
      <MedicineListSection />
      <InputWrapper header="Komentarz">
        <textarea
          {...register("data.description")}
          className="form-textarea min-h-28 w-full rounded-lg border border-jutro-new-warm-gray-300 p-2 focus:border-jutro-new-blue-500 focus:outline-none focus:ring-jutro-new-blue-500"
          placeholder="Kliknij, aby uzupełnić"
        />
      </InputWrapper>
      <HistorySection tagForm={tagForm} />
      <SubmitSection tagForm={tagForm} />
    </>
  );
};

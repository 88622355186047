import { Input } from "@jutro/ui";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { BankTransferPaymentModal } from "components/PatientDocs/Ordered/PaymentModal/PaymentOptions/BankTransferPaymentModal";
import { CustomerComplaintPaymentModal } from "components/PatientDocs/Ordered/PaymentModal/PaymentOptions/CustomerComplaintPaymentModal";
import { EmployeeBenefitPaymentModal } from "components/PatientDocs/Ordered/PaymentModal/PaymentOptions/EmployeeBenefitPaymentModal";
import { OtherPaymentModal } from "components/PatientDocs/Ordered/PaymentModal/PaymentOptions/OtherPaymentModal";
import { PaymentModalSchema } from "components/PatientDocs/Ordered/types";

type Props = {
  control: Control<PaymentModalSchema> | undefined;
  errors: FieldErrors<PaymentModalSchema>;
  paymentSelectValue: string;
};

export const PaymentModalBody = ({
  control,
  errors,
  paymentSelectValue,
}: Props) => {
  if (paymentSelectValue === "OTHER") {
    return <OtherPaymentModal control={control} errors={errors} />;
  }

  if (paymentSelectValue === "BANK-TRANSFER") {
    return <BankTransferPaymentModal control={control} errors={errors} />;
  }

  if (paymentSelectValue === "CUSTOMER-COMPLAINT") {
    return <CustomerComplaintPaymentModal control={control} errors={errors} />;
  }

  if (paymentSelectValue === "EMPLOYEE-BENFIT") {
    return <EmployeeBenefitPaymentModal control={control} />;
  }

  if (paymentSelectValue === "POS") {
    return (
      <Controller
        control={control}
        name="posPayment.paymentNumber"
        render={({ field: { onChange, value } }) => (
          <Input
            placeholder="Wprowadź numer przyjętej płatności"
            maxLength={25}
            value={value}
            onChange={onChange}
            error={errors.posPayment?.paymentNumber?.message}
          />
        )}
      />
    );
  }

  return null;
};

import md from "markdown-it";
import { ReactNode } from "react";
import { MessageHeader } from "components/NewMessageUtils/MessageHeader";
import { MessageWrapper } from "components/NewMessageUtils/MessageWrapper";

type NewSystemMessageProps = {
  content?: string;
  date: string;
  jsxContent?: ReactNode;
};

export const NewSystemMessage = ({
  date,
  content,
  jsxContent,
}: NewSystemMessageProps) => {
  const formattedContent = () => {
    if (!content) return null;

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: md({ linkify: true, html: true, breaks: true }).render(
            content,
          ),
        }}
      />
    );
  };

  return (
    <MessageWrapper mine>
      <MessageHeader />
      {jsxContent ? <div>{jsxContent}</div> : formattedContent()}
      <div className="font-paragraph-2">{date}</div>
    </MessageWrapper>
  );
};

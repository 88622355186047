import { QuestionIcon, Tooltip } from "@jutro/ui";
import dayjs from "dayjs";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { OrderedTestListItem } from "components/PatientDocs/Ordered/types";
import {
  fixedPriceValue,
  getPaymentMethod,
} from "components/PatientDocs/Ordered/utils";
import { Avatar } from "components/new";
import { DoctorMSLaboratoryExaminationEntity } from "lib/graphql/megaSchema";

type Props = {
  data: OrderedTestListItem;
};

export const OrderedTestTooltip = ({ data }: Props) => {
  const labExam = data as DoctorMSLaboratoryExaminationEntity;

  const { createdAt, createdBy, creator } = data;
  const { sourceInfo } = labExam;

  const formattedCreationDate = dayjs(createdAt).format("YYYY-MM-DD HH:mm");
  const formattedPaymentLogDate = dayjs(labExam.paymentLog?.createdAt).format(
    "YYYY-MM-DD HH:mm",
  );

  return (
    <Tooltip
      position="right"
      trigger={
        <div className="flex h-5 w-5 cursor-pointer items-center">
          <QuestionIcon size="sm" />
        </div>
      }
      content={
        <>
          <div>
            <h4 className="font-paragraph-2 rounded-t-lg bg-jutro-new-warm-gray-100 px-2 py-1 text-jutro-new-warm-gray-800">
              Szczegóły
            </h4>
          </div>
          <div className="font-paragraph-2 flex flex-col gap-2 p-2 text-jutro-new-warm-gray-800">
            {sourceInfo && (
              <>
                <div className="flex flex-col">
                  <span>Nazwa pakietu</span>
                  <span className="font-bold text-black">
                    {sourceInfo.ecommerce?.packetInfo?.name}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span>Kwota zamówienia po rabacie</span>
                  <span className="font-bold text-black">
                    {fixedPriceValue(
                      sourceInfo.ecommerce?.packetInfo?.totalPrice,
                    )}
                  </span>
                </div>
              </>
            )}

            {isLaboratoryExamination(data) && data.payment === "UNPAID" && (
              <div className="flex flex-col">
                <span>Koszt badania wg cennika</span>
                <span className="font-bold text-black">
                  {fixedPriceValue(data.assignment.original)}
                </span>
              </div>
            )}

            {isLaboratoryExamination(data) && data.payment === "FREE" && (
              <div className="flex flex-col">
                <span>Badanie w ramach NFZ</span>
                <div className="flex gap-1">
                  <span className="font-bold text-black line-through">
                    {fixedPriceValue(data.assignment.original)}
                  </span>
                  <span className="font-bold text-black">0.00 zł</span>
                </div>
              </div>
            )}

            {isLaboratoryExamination(data) && data.payment === "PAID" && (
              <>
                <div className="flex flex-col">
                  <span>Metoda płatności </span>
                  <span className="font-bold text-black">
                    {`${getPaymentMethod(data)} ${
                      sourceInfo
                        ? ""
                        : fixedPriceValue(data.assignment.original)
                    } `}
                  </span>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex gap-1">
                    <span>Przyjęto płatność dnia</span>
                    <span className="font-bold text-black">
                      {formattedPaymentLogDate}
                    </span>
                    <span>przez </span>
                  </div>

                  <div className="flex items-center gap-1">
                    <Avatar userId={data.paymentLog?.createdBy} />
                    <span>{`${data.paymentLog?.creator.firstName ?? "Brak"} ${
                      data.paymentLog?.creator.lastName ?? "danych"
                    }`}</span>
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-col gap-1">
              <div className="flex gap-1">
                <span>Utworzono dnia</span>
                <span className="font-bold text-black">
                  {formattedCreationDate}
                </span>
                <span>przez </span>
              </div>

              <div className="flex items-center gap-1">
                <Avatar userId={createdBy} />
                <span>{`${creator.firstName} ${creator.lastName}`}</span>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

import { DiamondAlertIcon } from "@jutro/ui";
import { DoctorMSEwusRedEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../../NfzEvent";
import { EventHandler } from "../../../NfzEvent/infer";

export const EwusRedEventHandler: EventHandler<DoctorMSEwusRedEvent> = ({
  event,
}) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.INSURANCE_STATUS}
      statusText="Pacjent czerwony"
      icon={<DiamondAlertIcon size="sm" />}
      color="rose"
    />
  );
};

import dayjs from "dayjs";
import { useAtom } from "jotai";
import { clone, groupBy } from "ramda";
import { Suspense, useEffect, useState } from "react";
import { ExaminationDrawer } from "components/ExaminationDrawer";
import { PatientDocsDetails } from "components/PatientDocs/Details/index";
import { OrderedTests } from "components/PatientDocs/Ordered/index";
import {
  PatientDocsHeader,
  PatientDocsHeaderTop,
} from "components/PatientDocs/Overview/header";
import { PatientDocsOverview } from "components/PatientDocs/Overview/index";
import { PatientDocsPrescriptions } from "components/PatientDocs/Prescriptions/index";
import { PatientTabsNavbar } from "components/PatientDocs/TabsNavbar/index";
import { VitalDialog } from "components/PatientDocs/VitalDialog/index";
import { usePatientDocs } from "components/PatientDocs/hooks";
import { corruptedAnalysisTypeMap } from "components/PatientDocs/tools";
import { Loader, Scrollbar } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { selectedDocsOfPatientTabAtom } from "lib/atoms";
import { useGetPatientId } from "lib/hooks/useGetPatientId";
import { lazyImport } from "lib/tools/lazyImport";
import { mapAnalysisType } from "lib/tools/mapAnalysisType";
import { toaster } from "lib/tools/toaster";

export type DocsOfPatientTab =
  | "Overview"
  | "Details"
  | "Visits"
  | "Prescriptions"
  | "Procedures";

const VisitHistory = lazyImport(
  () => import("components/PatientDocs/Visits"),
  "VisitHistory",
);

const vitalSearchFilter = (vital: any, search: string) => {
  const parsedVitalData = (
    corruptedAnalysisTypeMap[mapAnalysisType(vital)] ||
    vital?.analysisType ||
    vital?.operationType ||
    vital?.customName ||
    vital?.type
  )?.toLowerCase();

  return search.endsWith(" ")
    ? parsedVitalData === search.trim().toLowerCase()
    : parsedVitalData?.includes(search.toLowerCase());
};

const groupVitalsByDate = (vitalList: any): any => {
  return Object.entries(
    groupBy(
      (v: any) =>
        v.measured
          ? v.measured.iso?.split("T")[0] || ""
          : v.customDate
            ? v.customDate.iso?.split("T")[0] || ""
            : "brak",
      clone(vitalList),
    ),
  );
};

export const PatientDocs = () => {
  const patientId = useGetPatientId();

  const {
    patientData,
    patientLoading,
    patientError,
    currentExamination,
    patientFormData,
    updatePatientLoading,
    setCurrentExamination,
    savePatientDetails,
    addVital,
    revokeAssignment,
    savePatientData,
  } = usePatientDocs(patientId);

  const patient = patientData?.doctorPatient;

  const [selectedDocsOfPatientTab] = useAtom(selectedDocsOfPatientTabAtom);
  const [open, setOpen] = useState(false);
  const [examinationId, setExaminationId] = useState("");
  const [addVitalDialog, setAddVitalDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [everyExaminationTypeSame, setEveryParamSame] = useState(false);

  const [examinationsToShow, setExaminationsToShow] = useState([]);

  useEffect(() => {
    if (!patient) return;

    setExaminationsToShow(
      groupVitalsByDate(
        patient.vitals
          .filter((a) => vitalSearchFilter(a, search))
          .sort(
            (a, b) =>
              dayjs(
                b.measured?.iso || (b as any).customDate?.iso || -1,
              ).unix() -
              dayjs(a.measured?.iso || (a as any).customDate?.iso || -1).unix(),
          ),
      ),
    );
  }, [search, patient && patient.vitals]);

  useEffect(() => {
    const exams = examinationsToShow.flatMap((e) => e[1]);

    const examsToCompare = search.endsWith(" ")
      ? exams.filter((exam: any) => {
          const compareValue =
            exam.type === "OTHER" ? "customName" : "analysisType";
          return (
            exam[compareValue]?.toLowerCase() === search.trim().toLowerCase()
          );
        })
      : exams;

    const isEveryParamSame =
      examsToCompare.length > 1 &&
      examsToCompare.every((exam: any, _, exams: any) => {
        const compareValue =
          exam.type === "OTHER" ? "customName" : "analysisType";
        return (
          exam[compareValue]?.toLowerCase() ===
          exams[0][compareValue]?.toLowerCase()
        );
      });

    setEveryParamSame(isEveryParamSame);
  }, [examinationsToShow]);

  const [dialogShown, setDialogShown] = useState(false);

  if (!patientId || !patient || patientLoading) return <Loader screen />;
  if (patientError) return <div>Error</div>;

  const parsedAnswers = patient.oneTimeSurvey
    ? (groupBy((e: { title: any; value: any }) => {
        const surveyTitle = e.title.match(/(\[\[(.*)\]\])/);
        return surveyTitle?.length
          ? surveyTitle[0].replaceAll("[", "").replaceAll("]", "")
          : "Ankieta wstępna";
      })(
        Object.keys(patient.oneTimeSurvey).map((key) => ({
          title: key,
          value: patient.oneTimeSurvey[key],
        })),
      ) as Record<string, { title: string; value: string | string[] }[]>)
    : false;

  const sumPoints = () => {
    if (!parsedAnswers || !("POChP" in parsedAnswers)) return parsedAnswers;
    const sumOfPoints = parsedAnswers["POChP"].reduce((agg, obj) => {
      if (typeof obj.value !== "number") return agg;
      if (obj.title.indexOf("(0 =") === -1) return agg;
      return agg + obj.value;
    }, 0);
    return {
      ...parsedAnswers,
      POChP: [
        ...parsedAnswers.POChP,
        { title: "[[POChP]] Suma punktów:", value: sumOfPoints },
      ],
    } as Record<string, { title: string; value: string | string[] }[]>;
  };

  const finalAnswers = sumPoints();

  return (
    <div className="flex h-full flex-col gap-2">
      <PatientDocsHeaderTop patient={patient} />

      <PatientTabsNavbar />

      <div className="h-full">
        {selectedDocsOfPatientTab === "Overview" && (
          <Scrollbar>
            <div className="flex h-full flex-col gap-2">
              <PatientDocsHeader
                patient={patient}
                savePatientData={savePatientData}
              />

              <PatientDocsOverview
                setSearch={setSearch}
                setAddVitalDialog={setAddVitalDialog}
                examinationsToShow={examinationsToShow}
                search={search}
                patient={patient}
                setCurrentExamination={setCurrentExamination}
                everyExaminationTypeSame={everyExaminationTypeSame}
                setOpen={setOpen}
                finalAnswers={finalAnswers}
                setExaminationId={setExaminationId}
              />
            </div>
          </Scrollbar>
        )}

        {selectedDocsOfPatientTab === "Details" && (
          <div className="h-full">
            <PatientDocsDetails
              patient={patient}
              patientFormData={patientFormData}
              dialogShown={dialogShown}
              setDialogShown={setDialogShown}
              savePatientDetails={savePatientDetails}
              updatePatientLoading={updatePatientLoading}
            />
          </div>
        )}

        {selectedDocsOfPatientTab === "Visits" && (
          <div className="h-full">
            <Scrollbar>
              <Suspense fallback={<div>Czekaj...</div>}>
                <VisitHistory patientId={patientId} />
              </Suspense>
            </Scrollbar>
          </div>
        )}

        {selectedDocsOfPatientTab === "Prescriptions" && (
          <div className="h-full">
            <PatientDocsPrescriptions
              patientLoading={patientLoading}
              patient={patient}
            />
          </div>
        )}

        <div
          className={`${
            selectedDocsOfPatientTab === "Procedures" ? "h-full" : "hidden"
          }`}
        >
          <OrderedTests patientId={patientId} patientEmail={patient.email} />
        </div>
      </div>

      {currentExamination ? (
        <ExaminationDrawer
          patientId={patientId}
          examinationList={currentExamination}
          onClose={() => setCurrentExamination(null)}
          everyExaminationTypeSame={everyExaminationTypeSame}
        />
      ) : null}

      <VitalDialog
        mode="create"
        isOpen={addVitalDialog}
        setOpen={setAddVitalDialog}
        submit={addVital}
        patientId={patientId}
      />

      <ConfirmDialog
        title={"Usuwanie badania"}
        text={"Czy na pewno chcesz usunąć badanie?"}
        open={open}
        onConfirm={() => {
          revokeAssignment(examinationId);
          toaster.notify("Badanie usuniętę!");
        }}
        setOpen={setOpen}
      />
    </div>
  );
};

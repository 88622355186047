import { BinIcon, Button, EyeOnIcon } from "@jutro/ui";
import { Dispatch, SetStateAction } from "react";
import { FileWithPreview } from "components/new/Dropzone/Dropzone";
import { toaster } from "lib/tools/toaster";

type Props = {
  selectedFiles: FileWithPreview[];
  size: string;
  onFilesListUpdate: (files: FileWithPreview[]) => void;
  setIsFilePreviewModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const DropzoneFilesList = ({
  selectedFiles,
  size,
  onFilesListUpdate,
  setIsFilePreviewModalOpen,
}: Props) => {
  const removeFile = (index: number) => {
    onFilesListUpdate(selectedFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col items-stretch justify-center rounded-lg border border-jutro-new-warm-gray-100 hover:border-jutro-new-blue-800">
      {selectedFiles.map(({ name }, index) => (
        <div
          className="flex h-10 items-center justify-between border-t-[1px] px-2 first:border-t-0"
          key={name}
        >
          <span
            className={`font-paragraph-2 py-2 text-jutro-new-warm-gray-800 ${size}`}
          >
            {name}
          </span>
          <div className="flex gap-2">
            <Button
              icon={<EyeOnIcon />}
              label="Podgląd"
              tooltipPosition="top"
              onClick={() => {
                setIsFilePreviewModalOpen(true);
              }}
              full={false}
              variant="ghost"
              size="condensed"
              type="button"
            />

            <Button
              icon={<BinIcon />}
              label="Usuń"
              tooltipPosition="top"
              onClick={() => {
                removeFile(index);
                toaster.success("Usunięto plik");
              }}
              full={false}
              variant="negativeGhost"
              size="condensed"
              type="button"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

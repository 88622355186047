import { envVars } from "envvars";
import { useDoctorGenerateUsgResultsDocumentMutation } from "lib/graphql/megaSchema";
import { httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";

export const useGenerateUsgFile = () => {
  const [generateUsgResults] = useDoctorGenerateUsgResultsDocumentMutation();

  const getUsgResultsFile = async (procedureId: string) => {
    const usgResultFileUrlPromise = generateUsgResults({
      variables: { procedureId },
    });

    const result = await toaster.promise(usgResultFileUrlPromise, {
      error: "Nie udało się wygenerować wyniku USG",
      loading: "Generuje wynik badania USG",
      success: "Wygenerowano wynik badania USG",
    });

    if (!result) {
      return null;
    }

    const fileUrl = result.data?.doctorGenerateUsgResultsDocument.fileUrl;

    window.open(
      `${httpConfig[envVars.REACT_APP_CONFIG]}${fileUrl}&filename=USG.pdf`,
    );
  };

  return getUsgResultsFile;
};

import { useAtom } from "jotai";
import { useEffect } from "react";
import { Loader } from "components/new";
import { useDoctorArchivedVisitListQuery } from "lib/graphql/megaSchema";
import {
  visitsArchiveCurrentPageAtom,
  visitsArchiveDateAtom,
  visitsArchiveFiltersAtom,
  visitsArchiveSearchAtom,
  visitsArchiveTotalPagesAtom,
  visitsArchiveVojevodshipIdAtom,
} from "views/Visits/Archive/atoms";
import { VisitsArchiveFilters } from "views/Visits/Archive/components/VisitsArchiveFilters";
import { VisitsArchiveTable } from "views/Visits/Archive/components/VisitsArchiveTable";
import { buildVisitsArchiveFiltersInput } from "views/Visits/Archive/tools";

export const VisitsArchiveTab = () => {
  const [vojevodshipId] = useAtom(visitsArchiveVojevodshipIdAtom);
  const [startDate] = useAtom(visitsArchiveDateAtom);
  const [filters] = useAtom(visitsArchiveFiltersAtom);
  const [search] = useAtom(visitsArchiveSearchAtom);

  const [totalPages, setTotalPages] = useAtom(visitsArchiveTotalPagesAtom);
  const [currentPage] = useAtom(visitsArchiveCurrentPageAtom);

  const { data, loading: isDataLoading } = useDoctorArchivedVisitListQuery({
    fetchPolicy: "network-only",
    variables: {
      pageSize: 50,
      page: currentPage,
      filters: {
        organizationId: vojevodshipId,
        startDate: startDate.toISOString(),
        endDate: startDate.endOf("month").toISOString(),
        patientSearch: search,
        ...buildVisitsArchiveFiltersInput(filters),
      },
    },
  });

  useEffect(() => {
    if (
      data?.doctorArchivedVisitList.totalPages === undefined ||
      data.doctorArchivedVisitList.totalPages === totalPages
    ) {
      return;
    }

    setTotalPages(data.doctorArchivedVisitList.totalPages);
  }, [data]);

  return (
    <div className="flex h-full flex-col gap-2">
      <VisitsArchiveFilters />

      {isDataLoading ? (
        <div className="flex-1 rounded-lg bg-white">
          <Loader />
        </div>
      ) : (
        <VisitsArchiveTable data={data?.doctorArchivedVisitList.visits ?? []} />
      )}
    </div>
  );
};

import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import md from "markdown-it";
import logo from "assets/logoLight.svg";
import { ChatMessage } from "components/Messages/ChatMessage";
import { PrescriptionContent } from "components/Messages/ChatMessage/PrescriptionMessageContent";
import { LaboratoryExaminationsMessage } from "components/Messages/ExaminationPanelMessage/LaboratoryExaminationsMessage";
import { ProcedureMessage } from "components/Messages/ExaminationPanelMessage/ProcedureMessage";
import { FileHandlerMessage } from "components/Messages/FileHandlerMessage";
import { NewSystemMessage } from "components/Messages/NewSystemMessage";
import { ReferralMessage } from "components/Messages/ReferralMessage";
import { SurveyFilled, SurveySent } from "components/Messages/SurveyMessage";
import { VisitMessage } from "components/Messages/VisitMessage";
import { getMedicalLeaveContent } from "components/Messages/utils/getMedicalLeaveContent";
import {
  assignmentTypesMap,
  recommendationTitleMap,
  vaccinePairs,
} from "components/Messages/utils/maps";
import { ImageExamPaymentMessage } from "components/PaymentMessage";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorChatMessagesQuery,
  DoctorMSPatient,
} from "lib/graphql/megaSchema";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";
import { httpConfig } from "lib/tools/httpConfigs";
import { ArrayElement } from "lib/types";

const isPatient = (e: any): e is DoctorMSPatient => {
  if (!e) return false;
  return !("author" in e);
};

export type MessageElement = ArrayElement<
  DoctorChatMessagesQuery["doctorChatMessages"]
>;

type MessageProps = {
  message: MessageElement;
};

export const Message = ({ message }: MessageProps) => {
  const {
    id,
    content,
    author,
    createdAt: iso,
    prescription,
    visit,
    assignment,
    leave,
    referral,
    survey,
  } = message;

  const [currentUser] = useAtom(currentUserAtom);

  const isPatientWithGuardian =
    isPatient(author) && author.genre === "PATIENT" && author.guardians.length;

  if (!currentUser) return null;

  const isContentNullable = [
    "[rateVisit",
    "[procedureVisit",
    "[invoice",
    "[answers-",
    "[CHOICE_MESSAGE]",
    "[navigate@@@",
    "[@@@TELEVISIT@@@]",
  ].some((unwantedPrefix) => content.startsWith(unwantedPrefix));

  if (isContentNullable) {
    return null;
  }

  if (content.startsWith("[procedure-")) {
    return <ProcedureMessage content={content} />;
  }

  if (content.startsWith("[laboratoryExaminations-")) {
    return <LaboratoryExaminationsMessage content={content} />;
  }

  if (content === "[@@@STANDARDVISIT@@@]") {
    return (
      <NewSystemMessage
        content={`Pacjent podczas rejestracji wybrał opcję wizyty pierwszorazowej w formie wizyty osobistej w przychodni oraz automatycznie otrzymał możliwość wyboru terminu wizyty. W związku z tym komunikatem nie musisz wykonywać żadnych dodatkowych działań. `}
        date={dayjs(iso).format("D MMMM YYYY H:mm")}
      />
    );
  }

  if (content === "[@@@TELEVISITJUTRO@@@]") {
    return (
      <NewSystemMessage
        content="Opisz w najbardziej szczegółowy sposób swoje objawy lub sprawę którą chcesz załatwić. Jeśli posiadasz jakąś medyczną dokumentację, załącz ją. Poczekaj na dołączenie do czatu asystenta medycznego."
        date={dayjs(iso).format("D MMMM, HH:mm")}
      />
    );
  }

  if (
    !author &&
    !prescription &&
    !visit &&
    !assignment &&
    !survey &&
    !leave &&
    !referral
  ) {
    return (
      <NewSystemMessage
        date={dayjs(iso).format("D MMMM YYYY H:mm")}
        content={content}
      />
    );
  }

  const authorId = author?.id;

  const authorName = getDisplayDoctor(
    author?.firstName,
    author?.lastName,
    "Brak osoby",
  );

  const isStaff = author?.genre !== "PATIENT";

  if (content === "[@@@TELEAGREEMENTACCEPTED@@@]") {
    return (
      <ChatMessage
        id={id}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        messageContent="Telemedycznie w formie czatu tekstowego"
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={isStaff}
      />
    );
  }

  if (content.startsWith("[URGENT=")) {
    return (
      <ChatMessage
        id={id}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        messageContent={`Tryb postępowania: ${
          content === "[URGENT=true]" ? "Pilny❗️" : "Normalny"
        }`}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={isStaff}
      />
    );
  }

  if (content.startsWith("[survey-sent")) {
    return <SurveySent id={id} iso={iso} survey={survey} />;
  }

  if (content.startsWith("[survey-filled")) {
    return (
      <SurveyFilled
        authorId={authorId}
        authorName={authorName}
        visit={visit}
        isStaff={isStaff}
        iso={iso}
        survey={survey}
      />
    );
  }

  if (content.startsWith("[message-")) {
    if (!assignment) {
      return null;
    }

    const { positions, assignmentType, data } = assignment;

    if (
      assignmentType === "ANALYSIS" ||
      assignmentType === "USG" ||
      assignmentType === "RTG" ||
      assignmentType === "CT"
    ) {
      return <ImageExamPaymentMessage positions={positions ?? []} />;
    }

    if (
      assignmentType === "EKG" ||
      assignmentType === "MEDICINE" ||
      assignmentType === "SPIRO" ||
      assignmentType === "VACCINE" ||
      assignmentType === "BANDAGE" ||
      assignmentType === "STITCHES" ||
      assignmentType === "OBJECT" ||
      assignmentType === "DRUGPASS"
    )
      return (
        <ChatMessage
          id={id}
          authorImage={logo}
          authorName={"Jutro Medical"}
          messageContent={
            <span>
              Wysłano zlecenie na{" "}
              {data?.cito ? (
                <span className="font-bold text-red-700"> CITO </span>
              ) : (
                ""
              )}
              <b>
                {assignmentTypesMap[assignmentType]}
                {assignmentType === "SPIRO" && data?.type === "SpiroRozkurcz"
                  ? " rozkurczową"
                  : ""}
                {assignmentType === "MEDICINE" && data?.name
                  ? ` lekiem ${data.name}`
                  : ""}
                {assignmentType === "DRUGPASS" && data?.name
                  ? ` ${data.name}`
                  : ""}
              </b>
              {data?.place && (
                <>
                  <br />
                  <span>Miejsce: {data.place}</span>
                </>
              )}
              {data?.healing && (
                <>
                  <br />
                  <span>Gojenie: {data.healing}</span>
                </>
              )}
              {data?.type && (
                <>
                  <br />
                  <span>Typ: {data.type}</span>
                </>
              )}
              {data?.secretion && (
                <>
                  <br />
                  <span>Wydzielina: {data.secretion}</span>
                </>
              )}
              {data?.afterExertions && (
                <>
                  <br />
                  <span>Po zabiegu: {data.afterExertions ? "Tak" : "Nie"}</span>
                </>
              )}
              {data?.wet && (
                <>
                  <br />
                  <span>mokry: {data.wet ? "Tak" : "Nie"}</span>
                </>
              )}
              {data?.stitches && (
                <>
                  <br />
                  <span>szwy: {data.stitches ? "Tak" : "Nie"}</span>
                </>
              )}
              {data?.dosage && (
                <>
                  <br />
                  Podawanie: {data.dosage}
                </>
              )}
              {data?.status && (
                <>
                  <br />
                  Status: {vaccinePairs[data.status]}
                </>
              )}
              {data?.date && (
                <>
                  <br />
                  Data: {dayjs(data.date).format("YYYY-MM-DD")}
                </>
              )}
              {data?.vaccine && (
                <>
                  <br />
                  Szczepionka: {data.vaccine}
                </>
              )}
              {data?.message && (
                <>
                  <br />
                  <span>Uwagi: {data.message}</span>
                </>
              )}
            </span>
          }
          messageTime={dayjs(iso).format("D MMMM, HH:mm")}
          mine={false}
        />
      );
  }

  if (content.startsWith("[recommendation-")) {
    const key = content.match(/\[recommendation-([^\]]+)\]/)?.[1];
    return (
      <ChatMessage
        id={id}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        messageContent={`Wysłano zalecenia: ${
          key ? recommendationTitleMap[key] : ""
        }`}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine
      />
    );
  }

  if (content.match(/\[file-([^\]]+.{2,})\]/)) {
    return (
      <FileHandlerMessage
        id={id}
        authorId={authorId}
        authorName={authorName}
        content={content}
        iso={iso}
        isStaff={isStaff}
      />
    );
  }

  if (visit) {
    return <VisitMessage visit={visit} />;
  }

  /**
   * e-prescription message:
   */
  if (prescription) {
    return (
      <ChatMessage
        id={id}
        authorImage={logo}
        authorName={"Jutro medical"}
        messageContent={
          <PrescriptionContent
            prescription={
              prescription.resourceCancelationInCezDate ? null : prescription
            }
          />
        }
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={false}
        isPrescription
        isRemovable={
          prescription.doctor.id === currentUser.id &&
          !prescription.resourceCancelationInCezDate
        }
      />
    );
  }

  /* e-Skierowanie */
  if (content.startsWith("[referral-") && referral) {
    if (referral.referralType === "covid19") {
      return (
        <>
          <ChatMessage
            id={id}
            authorImage={logo}
            authorName={"Jutro Medical"}
            messageContent={
              <div className="flex flex-col gap-4">
                <div>
                  Pomyślnie utworzono zlecenie testu SARS-CoV-2 (COVID-19).
                </div>
                <div>
                  <div className="font-bold">Numer zlecenia</div>
                  <div>{referral.id}</div>
                </div>
              </div>
            }
            messageTime={dayjs(iso).format("D MMMM, HH:mm")}
            mine={false}
          />

          <ChatMessage
            id={id}
            authorImage={logo}
            authorName={authorName}
            messageContent={
              <div className="flex flex-col gap-4">
                <div>
                  Test jest bezpłatny, można go wykonać jeszcze dzisiaj w jednym
                  z punktów dostępnych pod tym linkiem:
                  <br />
                  <a
                    className="underline"
                    href="https://pacjent.gov.pl/aktualnosc/test-w-mobilnym-punkcie-pobran"
                  >
                    https://pacjent.gov.pl/aktualnosc/test-w-mobilnym-punkcie-pobran
                  </a>
                </div>

                <div>
                  Od tej pory rządowy system nakłada automatycznie kwarantannę
                  do momentu otrzymania negatywnego wyniku testu.
                </div>

                <div>
                  Podczas kwarantanny świadczenai są wypłacane w taki sam
                  sposób, jak podczas zwolnienia lekarskiego, jednak konieczne
                  jest poinformowanie o pobycie na kwarantannie pracodawcę.
                </div>

                <div>
                  W przypadku wyniku pozytywnego, następuje jej przekszałcenie w
                  7. dniową izolację.
                </div>
              </div>
            }
            messageTime={dayjs(iso).format("D MMMM, HH:mm")}
            mine={false}
          />
        </>
      );
    }

    return (
      <ChatMessage
        id={id}
        authorImage={logo}
        authorName={"Jutro Medical"}
        messageContent={<ReferralMessage referral={referral} />}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={false}
      />
    );
  }

  /* e-Zwolnienie */
  if (content.startsWith("[leave-")) {
    return (
      <ChatMessage
        id={id}
        authorImage={logo}
        authorName={"Jutro Medical"}
        messageContent={getMedicalLeaveContent(leave)}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={false}
      />
    );
  }

  return (
    <ChatMessage
      id={id}
      authorImage={`${httpConfig[envVars.REACT_APP_CONFIG]}/avatar/${
        isPatientWithGuardian ? author.guardians[0].id : authorId
      }`}
      authorName={
        isPatientWithGuardian
          ? `${author.guardians[0].firstName} ${author.guardians[0].lastName} (w imieniu ${author.firstName} ${author.lastName})`
          : authorName
      }
      messageContent={
        <div
          dangerouslySetInnerHTML={{
            __html: md({ linkify: true, html: true }).render(content),
          }}
        />
      }
      messageTime={dayjs(iso?.iso ?? iso).format("D MMMM, HH:mm")}
      mine={isStaff}
    />
  );
};

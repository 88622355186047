import { forwardRef } from "react";
import {
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from "react-hook-form";

type Option = {
  label: string;
  optionValue: string | number | boolean | null;
};

type Position = "column" | "row";

type Size = "xs" | "sm" | "lg";

type Color = "blue" | "green";

const sizeMap: Record<Size, { text: string; size: string }> = {
  xs: { text: "font-paragraph-2", size: "w-4 h-4" },
  sm: { text: "font-paragraph-2", size: "w-5 h-5" },
  lg: { text: "text-lg", size: "w-6 h-6" },
};

const colorMap: Record<Color, string> = {
  blue: "text-jutro-new-blue-800 cursor-pointer",
  green: "text-jutro-new-green-400 cursor-pointer",
};

type Props = {
  position?: Position;
  options: Option[];
  disabled?: boolean;
  size?: Size;
  color?: Color;
  error?: FieldError;
} & ControllerRenderProps<FieldValues, string>;

export const Radio = forwardRef<HTMLInputElement, Props>(
  (
    {
      position = "column",
      options,
      value,
      onChange,
      disabled = false,
      size = "sm",
      color = "blue",
      error,
    },
    ref,
  ) => {
    const checked = (optionValue: Option["optionValue"]) => {
      return value === null && !optionValue ? true : value === optionValue;
    };

    return (
      <div
        className={`flex ${
          position === "column" ? "flex-col gap-2" : "flex-row gap-8"
        }`}
      >
        {options.map(({ optionValue, label }) => (
          <label key={label} className="inline-flex items-center">
            <input
              ref={ref}
              type="radio"
              onChange={() => {
                onChange(optionValue);
              }}
              disabled={disabled}
              checked={checked(optionValue)}
              className={`${sizeMap[size].size} form-radio ${
                error
                  ? "border-jutro-new-rose-600"
                  : "border-jutro-new-warm-gray-300"
              } ${
                disabled
                  ? "bg-jutro-new-warm-gray-100 text-jutro-new-warm-gray-300"
                  : colorMap[color]
              } focus:shadow-none focus:outline-none focus:ring-0 focus:ring-white`}
            />

            <span
              className={`ml-2 cursor-pointer select-none font-medium text-jutro-new-warm-gray-800 ${sizeMap[size].text}`}
            >
              {label}
            </span>
          </label>
        ))}
      </div>
    );
  },
);

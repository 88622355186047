import {
  CircleLoaderIcon,
  NavigationButton,
  SolidAlertIcon,
  SolidCircleCheckIcon,
  SolidCircleInfoIcon,
  SolidCircleRemoveIcon,
} from "@jutro/ui";
import { ReactNode } from "react";
import {
  Renderable,
  ValueOrFunction,
  resolveValue,
  toast,
} from "react-hot-toast";

type ToastOptions = Parameters<typeof toast>["1"] & {
  description: ReactNode;
  withCloseButton: boolean;
};

type Config = Partial<ToastOptions>;

const DEFAULT_CLASSES =
  "min-w-max !p-4 gap-4 outline outline-2 -outline-offset-2";

const renderToastContent = ({
  content,
  description = null,
  withCloseButton = true,
}: {
  content: ReactNode;
  description?: ReactNode;
  withCloseButton?: boolean;
}) => (
  <div className={`flex flex-col ${description && "gap-3"}`}>
    <section className="flex items-center gap-4">
      <span className="font-paragraph-1"> {content}</span>

      {withCloseButton && <NavigationButton onClick={() => toast.dismiss()} />}
    </section>

    {description && <section>{description}</section>}
  </div>
);

export const toaster = {
  notify: (content: ReactNode, options: Config = {}) =>
    toast(
      renderToastContent({
        content,
        description: options.description,
        withCloseButton: options.withCloseButton,
      }),
      {
        icon: (
          <div className="text-jutro-new-blue-600">
            <SolidCircleInfoIcon size="sm" />
          </div>
        ),
        ...options,
        className: `${DEFAULT_CLASSES} !bg-jutro-new-blue-50 !text-jutro-new-blue-900 outline-jutro-new-blue-300`,
      },
    ),

  success: (content: ReactNode, options: Config = {}) =>
    toast.success(
      renderToastContent({
        content,
        description: options.description,
        withCloseButton: options.withCloseButton,
      }),
      {
        icon: (
          <div className="text-jutro-new-green-400">
            <SolidCircleCheckIcon size="sm" />
          </div>
        ),
        ...options,
        className: `${DEFAULT_CLASSES} !bg-jutro-new-green-50 !text-jutro-new-green-700 outline-jutro-new-green-300`,
      },
    ),

  warning: (content: ReactNode, options: Config = {}) =>
    toast(
      renderToastContent({
        content,
        description: options.description,
        withCloseButton: options.withCloseButton,
      }),
      {
        icon: (
          <div className="text-jutro-new-orange-500">
            <SolidAlertIcon size="sm" />
          </div>
        ),
        ...options,
        className: `${DEFAULT_CLASSES} !bg-jutro-new-orange-50 !text-jutro-new-orange-900 outline-jutro-new-orange-300`,
      },
    ),

  error: (content: ReactNode, options: Config = {}) =>
    toast.error(
      renderToastContent({
        content,
        description: options.description,
        withCloseButton: options.withCloseButton,
      }),
      {
        icon: (
          <div className="text-jutro-new-rose-500">
            <SolidCircleRemoveIcon size="sm" />
          </div>
        ),
        ...options,
        className: `${DEFAULT_CLASSES} !bg-jutro-new-rose-50 !text-jutro-new-rose-900 outline-jutro-new-rose-300`,
      },
    ),

  loading: (content: ReactNode, options: Config = {}) =>
    toast.loading(
      renderToastContent({
        content,
        description: options.description,
        withCloseButton: options.withCloseButton || false,
      }),
      {
        icon: (
          <div className="animate-spin text-jutro-new-warm-gray-800">
            <CircleLoaderIcon size="sm" />
          </div>
        ),
        ...options,
        className: `${DEFAULT_CLASSES} !bg-white !text-jutro-warm-gray-800 outline-jutro-new-warm-gray-300`,
      },
    ),

  promise: async <T,>(
    promise: Promise<T>,
    msgs: {
      loading: Renderable;
      success: ValueOrFunction<Renderable, T>;
      error: ValueOrFunction<Renderable, any>;
    },
    options: Config = {},
  ) => {
    const id = toaster.loading(msgs.loading, {
      ...options,
      withCloseButton: false,
    });

    try {
      const p = await promise;

      toaster.success(resolveValue(msgs.success, p), {
        id,
        ...options,
      });

      return p;
    } catch (e) {
      toaster.error(resolveValue(msgs.error, e), {
        id,
        ...options,
      });

      console.error("Error: ", e);

      return null;
    }
  },

  dismiss: (toastId?: string) => toast.dismiss(toastId),
};

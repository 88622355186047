import { z } from "zod";

export type visitsConfirmationSchemaType = z.infer<
  typeof visitsConfirmationSchema
>;

export const visitsConfirmationSchema = z.object({
  phone: z.string().optional(),
  text: z.string().optional(),
});

import { Accordion, Button, CloseIcon } from "@jutro/ui";
import { isValidElement } from "react";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { TabItem } from "views/Visit/RightPanel/NewPrescription/components/Drugs/ListContent/Item";
import { Drug } from "views/Visit/RightPanel/NewPrescription/types";
import { PaymentTable } from "./PaymentTable";
import { NO_DATA_MESSAGE, unescapeHTMLString } from "./tools";

type Props = {
  drug: Drug;
};

export const DrugInfo = ({ drug }: Props) => {
  const { removeTile } = useTileSystem();

  const tabs: TabItem[] = [
    { label: "Wskazania", content: drug.indications || NO_DATA_MESSAGE },
    { label: "Dawkowanie", content: drug.dosageDescription || NO_DATA_MESSAGE },
    {
      label: "Przeciwskazania",
      content: drug.contraindications || NO_DATA_MESSAGE,
    },
    {
      label: "Interakcje",
      content: drug.interactions || NO_DATA_MESSAGE,
    },
    {
      label: "Refundacje/cena",
      content: <PaymentTable {...drug} />,
    },
    {
      label: "Ciąża",
      content: drug.pregnantWomenInstructions || NO_DATA_MESSAGE,
    },
    { label: "Skład", content: drug.composition || NO_DATA_MESSAGE },
    {
      label: "Działanie",
      content: drug.effect || NO_DATA_MESSAGE,
    },
  ];

  return (
    <div className="h-full w-full rounded-lg">
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
        <div className="flex justify-between">
          <div>
            <div className="font-heading-3">{drug.name}</div>
            <div className="font-paragraph-1">{`${drug.dosage} | ${drug.form}`}</div>
          </div>

          <Button
            full={false}
            variant="ghost"
            size="condensed"
            icon={<CloseIcon />}
            label="Zamknij"
            tooltipPosition="bottom"
            onClick={() => removeTile(drug.ean)}
          />
        </div>

        <div className="font-paragraph-1 flex flex-col gap-4">
          {tabs.map((tab) => (
            <Accordion
              key={tab.label}
              title={tab.label}
              content={
                isValidElement(tab.content) ? (
                  tab.content
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: unescapeHTMLString(tab.content as string),
                    }}
                  />
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

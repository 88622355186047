import { DragDropContext } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { ScreenLoader } from "components/new";
import { useOnDragEnd } from "lib/hooks/useDragEnd.hooks";
import { useWorkspace } from "lib/tools/createWorkspace/useWorkspace";
import { usePatientData } from "views/Patient/hooks";

export const PatientProfileWorkspace = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { loading } = usePatientData({ from: "network-only" });
  const workspace = useWorkspace({ name: "patient-profile", width: 3 });
  const { onDragEnd } = useOnDragEnd();

  if (!patientId || loading) return <ScreenLoader />;

  return <DragDropContext onDragEnd={onDragEnd}>{workspace}</DragDropContext>;
};

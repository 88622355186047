import { Button, CloseIcon } from "@jutro/ui";
import React, { ReactNode } from "react";
import { Controller, FormProvider } from "react-hook-form";
import Select from "react-select";
import { TagForm } from "components/TagFormNew/types";
import { DoctorMSPatientTagType } from "lib/graphql/megaSchema";
import { useGetPatientId } from "lib/hooks/useGetPatientId";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { InputWrapper } from "./InputWrapper";
import { Sections } from "./Sections";
import { useTagFormNew } from "./hooks";
import { typeOptions } from "./utils";

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Coś poszło nie tak</h1>;
    }

    return this.props.children;
  }
}

export type TagFormNewProps = {
  tagForm: TagForm;
};

export const TagFormNew = ({ tagForm }: TagFormNewProps) => {
  const patientId = useGetPatientId();

  const { methods } = useTagFormNew({ patientId, tag: tagForm.tag });
  const { removeTile } = useTileSystem();
  const { type, tag, id } = tagForm;

  const { control } = methods;

  const closeTagForm = () => {
    removeTile(id);
  };

  const isSelectDisabled =
    type === "update" &&
    ["REGULAR_DRUG", "CHRONIC_DISEASE"].includes(
      tag?.type as DoctorMSPatientTagType,
    );

  return (
    <div className="no-scrollbar relative h-full">
      <ErrorBoundary>
        <div className="flex flex-col">
          <FormProvider {...methods}>
            <form className="relative flex flex-col gap-3 rounded-lg bg-white p-4">
              <InputWrapper header="Kategoria informacji">
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value, ref, onBlur } }) => {
                    const currentOption = typeOptions.find(
                      (option) => option.value === value,
                    );

                    return (
                      <Select
                        isDisabled={isSelectDisabled}
                        placeholder={"Wybierz z listy"}
                        options={typeOptions}
                        onChange={(option) =>
                          onChange(option?.value ?? "ADDITIONAL")
                        }
                        ref={ref}
                        onBlur={onBlur}
                        value={currentOption}
                        isClearable
                      />
                    );
                  }}
                />
              </InputWrapper>
              <div className="absolute right-4 top-2">
                <Button
                  full={false}
                  variant="ghost"
                  size="condensed"
                  icon={<CloseIcon />}
                  type="button"
                  label="Zamknij"
                  tooltipPosition="bottom"
                  onClick={closeTagForm}
                />
              </div>
              <Sections patientId={patientId} tagForm={tagForm} />
            </form>
          </FormProvider>
        </div>
      </ErrorBoundary>
    </div>
  );
};

import { useAtom } from "jotai";
import { groupBy } from "ramda";
import { Loader } from "components/new";
import {
  DoctorMSLaboratoryExaminationEntity,
  DoctorMSProcedureEntity,
} from "lib/graphql/megaSchema";
import { isAdviceProcedure } from "lib/tools";
import { testsLoadingAtom, visitTestsAtom } from "../atoms";
import { isLaboratoryExamination } from "../utils/isLaboratoryExamination";
import { ProcedureElement } from "./ProcedureElement/ProcedureElement";
import { SampleElement } from "./SampleElement";

export const ActionsList = () => {
  const [visitTests] = useAtom(visitTestsAtom);
  const [testsLoading] = useAtom(testsLoadingAtom);

  const getLabExaminationsAndProcedures = () => {
    const labExaminations = visitTests.filter((e) =>
      isLaboratoryExamination(e),
    );

    const procedures = visitTests.filter(
      (e) => !isLaboratoryExamination(e) && !isAdviceProcedure(e.type),
    );
    return [labExaminations, procedures];
  };

  const [labExaminations, procedures] = getLabExaminationsAndProcedures();

  const byVialId = groupBy((e: any) => {
    return e.groupId;
  });

  const vials = byVialId(
    labExaminations as DoctorMSLaboratoryExaminationEntity[],
  );

  if (testsLoading) return <Loader />;

  return (
    <div className="flex flex-col gap-4">
      {Object.entries(vials).map(([id, labExaminations]) => (
        <SampleElement
          key={id}
          groupId={id}
          labExaminations={labExaminations ?? []}
        />
      ))}
      {(procedures as DoctorMSProcedureEntity[])
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((procedure) => (
          <ProcedureElement key={procedure.id} procedure={procedure} />
        ))}
    </div>
  );
};

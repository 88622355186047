import dayjs from "dayjs";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { is } from "ramda";
import { useState } from "react";
import logo from "assets/logoLight.svg";
import { ChatMessage } from "components/Messages/ChatMessage";
import { DoctorImageMessage } from "components/Messages/DoctorImageMessage";
import { FileMessage } from "components/Messages/FileMessage";
import { MessageWithFooter } from "components/Messages/MessageWithFooter";
import { MessageElement } from "components/new/Chat/Message/Message";
import { visitDataAtom } from "lib/atoms";
import {
  DoctorVisitDocument,
  useDoctorUpdateVisitMutation,
} from "lib/graphql/megaSchema";
import { copyset } from "lib/tools/copyset";
import { fileConfig, httpConfig } from "lib/tools/httpConfigs";
import { surveyMap } from "lib/tools/surveyMap";
import { surveysAndRecommendationsBasic } from "lib/tools/surveyUtils";

type SurveySentProps = {
  survey: MessageElement["survey"];
  id: string;
  iso: any;
};

export const SurveySent = ({ survey, id, iso }: SurveySentProps) => {
  if (!survey) return null;

  const { formId } = survey;

  const { name: surveyName } = surveysAndRecommendationsBasic.find(
    (el) => el.id === formId,
  ) ?? {
    name: "",
  };

  return (
    <ChatMessage
      id={id}
      authorImage={logo}
      authorName={"Jutro Medical"}
      messageContent={
        <span>
          Ankieta <b>{surveyName}</b> została pomyślnie wysłana do pacjenta.
        </span>
      }
      messageTime={dayjs(iso).format("D MMMM, HH:mm")}
      mine={false}
    />
  );
};

type SurveyFilledProps = {
  survey: MessageElement["survey"];
  authorId: string | undefined;
  authorName: string;
  visit: MessageElement["visit"];
  isStaff: boolean;
  iso: any;
};

export const SurveyFilled = ({
  survey,
  iso,
  authorId,
  authorName,
  visit,
  isStaff,
}: SurveyFilledProps) => {
  const [updateVisitMutation] = useDoctorUpdateVisitMutation({
    refetchQueries: [DoctorVisitDocument],
  });

  const [surveyAddedToInterview, setSurveyAddedToInterview] = useState(false);

  const [, setVisitData] = useAtom(visitDataAtom);

  const addToInterview = async (survey: string) => {
    if (!visit?.id) {
      return;
    }

    const { id: visitId, doctorInterview } = visit;

    await updateVisitMutation({
      variables: {
        id: visitId,
        input: {
          doctorInterview:
            (doctorInterview ? doctorInterview + `\n\n` : "") + survey,
        },
      },
    });
  };

  if (!survey) return null;

  const { formId, data: rawSurveyData } = survey;
  const surveyData =
    typeof rawSurveyData === "string"
      ? JSON.parse(rawSurveyData)
      : rawSurveyData;
  const { id: surveyId, name: surveyName } =
    surveysAndRecommendationsBasic.find((el) => el.id === formId) ?? {
      name: "",
    };

  const answers = surveyData.reduce(
    (agg: any[], section: { fields: any[] }) => {
      const allAnsweredFromSection = section.fields.filter(
        (el) => el.selectedValue !== null,
      );
      return agg.concat(allAnsweredFromSection);
    },
    [],
  );

  const files = surveyData.reduce((agg: any[], section: { fields: any[] }) => {
    const filesFromSection = section.fields.reduce((subAgg, question) => {
      if (question.type === "FILE" && question.selectedValue)
        return agg.concat(question.selectedValue);
      return subAgg;
    }, []);
    return agg.concat(filesFromSection);
  }, []);

  const fileOutput = (file: any, ind: number) => {
    const extension = file.original.split(/\.(?=[^.]+$)/)[1];
    if (["jpg", "png", "jpeg"].includes(extension)) {
      return (
        <DoctorImageMessage
          key={ind}
          authorImage={`${
            httpConfig[envVars.REACT_APP_CONFIG]
          }/avatar/${authorId}`}
          authorName={authorName}
          messageTime={dayjs(iso).format("D MMMM, HH:mm")}
          mine={isStaff}
          image={`${fileConfig[envVars.REACT_APP_CONFIG]}` + file.fullName}
          onClickStandard
        />
      );
    }

    return (
      <FileMessage
        key={ind}
        authorImage={`${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/avatar/${authorId}`}
        authorName={authorName}
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={isStaff}
        filename={file.original}
        extension={extension}
        href={`${fileConfig[envVars.REACT_APP_CONFIG]}` + file.fullName}
      />
    );
  };

  return (
    <>
      <MessageWithFooter
        authorImage={
          authorId
            ? `${httpConfig[envVars.REACT_APP_CONFIG]}/avatar/${authorId}`
            : logo
        }
        authorName={authorName}
        messageContent={
          <>
            <p>
              <b>{surveyName}</b> odpowiedzi:
            </p>
            <br />
            {answers.map((answer: any, i: any) => (
              <p className="mb-5" key={i}>
                {answer.title}
                <br />
                <b>
                  {is(Array, answer.selectedValue)
                    ? answer.type === "FILE"
                      ? "Pliki zostały zamieszczone poniżej"
                      : answer.selectedValue.map((el: any, j: any) => (
                          <>
                            <p key={j}>
                              {el.toString().replace(/(\[\[(.*)\]\])/g, "")}
                            </p>
                            <br />
                          </>
                        ))
                    : answer?.selectedValue
                        .toString()
                        .replace(/(\[\[(.*)\]\])/g, "")}
                </b>
              </p>
            ))}
          </>
        }
        messageTime={dayjs(iso).format("D MMMM, HH:mm")}
        mine={isStaff}
        footerContent={
          surveyAddedToInterview
            ? "Dodano pomyślnie 🎉"
            : "Dodaj do wywiadu lekarskiego"
        }
        footerBackground={
          surveyId && !copyset.map((c) => c.ID).includes(surveyId)
            ? "bg-jutro-new-warm-gray-300"
            : surveyAddedToInterview
              ? "bg-jutro-new-green-400"
              : "bg-jutro-new-blue-500"
        }
        footerClick={async () => {
          if (surveyAddedToInterview) return;
          if (surveyId && !copyset.map((c) => c.ID).includes(surveyId)) return;

          await addToInterview(
            dayjs(iso).format("D MMMM, HH:mm") +
              "\n" +
              surveyMap({ formId, data: answers }),
          );

          setVisitData((prev) => ({
            ...prev,
            doctorInterview:
              dayjs(iso).format("D MMMM, HH:mm") +
              "\n" +
              surveyMap({ data: answers, formId }),
          }));

          setSurveyAddedToInterview(true);
        }}
        footerDisabled={
          (surveyId && !copyset.map((c) => c.ID).includes(surveyId)) ||
          surveyAddedToInterview
        }
        noArrow={surveyAddedToInterview}
      />
      {files && files.map((el: any, i: any) => fileOutput(el, i))}
    </>
  );
};

import { useAtom } from "jotai";
import { currentUserAtom } from "lib/atoms/auth";
import { useStrapiGetPostsQuery } from "lib/graphql/megaSchema";

export const usePosts = () => {
  const [currentUser] = useAtom(currentUserAtom);

  const response = useStrapiGetPostsQuery({
    skip: !currentUser?.genre,
    variables: {
      filters: { post_tags: { genre: { eq: currentUser?.genre } } },
      sort: "createdAt:desc",
    },
  });

  return { ...response, data: response.data?.strapiPosts?.data };
};

export const usePost = (slug: string) => {
  const response = useStrapiGetPostsQuery({
    variables: {
      filters: { slug: { eq: slug } },
    },
  });

  return { ...response, data: response.data?.strapiPosts?.data[0] };
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "@jutro/ui";
import { envVars } from "envvars";
import { Controller, useForm } from "react-hook-form";
import { httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import {
  visitsConfirmationSchema,
  visitsConfirmationSchemaType,
} from "views/VisitsConfirmation/tools";

export const VisitConfirmationForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<visitsConfirmationSchemaType>({
    mode: "onChange",
    resolver: zodResolver(visitsConfirmationSchema),
  });

  const sendVisitConfirmationRequest = async (
    data: visitsConfirmationSchemaType,
  ) => {
    const URL = `${
      httpConfig[envVars.REACT_APP_CONFIG]
    }/no-show-mitigation/response`;

    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        sms_from: data.phone ?? "",
        sms_text: data.text ?? "",
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    reset({ phone: "", text: "" });
    return response;
  };

  const submitVisitsConfirmationForm = handleSubmit((data) => {
    toaster.promise(sendVisitConfirmationRequest(data), {
      error: "Nie udało się wysłać wiadomosci SMS",
      loading: "Wysyłam wiadomość SMS",
      success: "Poprawnie wysłano wiadomość SMS",
    });
  });

  const sendScanVisitsRequest = async () => {
    const URL = `${
      httpConfig[envVars.REACT_APP_CONFIG]
    }/no-show-mitigation/scan`;

    const response = await fetch(URL, {
      method: "POST",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response;
  };

  const onScanVisitsClick = () => {
    toaster.promise(sendScanVisitsRequest(), {
      error: "Nie udało się zeskanować wizyt",
      loading: "Skanuję wizyty",
      success: "Pomyślnie zeskanowano wizyty",
    });
  };

  return (
    <div className="flex w-96 flex-col gap-4 rounded-lg border-[2px] border-jutro-new-blue-800 p-4 shadow-lg">
      <form
        className="flex flex-col gap-4"
        onSubmit={submitVisitsConfirmationForm}
      >
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label=" Wpisz numer telefonu"
              id="phone"
              type="tel"
              value={value ?? ""}
              maxLength={11}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length > 11) {
                  return;
                }
                onChange(inputValue);
              }}
              placeholder="48xxxxxxxxx"
            />
          )}
        />

        <Controller
          name="text"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-col gap-2">
              <label
                htmlFor="text"
                className="font-paragraph-2 text-jutro-new-warm-gray-800"
              >
                Wpisz treść wiadomości SMS
              </label>
              <textarea
                id="text"
                disabled={false}
                value={value}
                onChange={onChange}
                placeholder="Wpisz treść wiadomosci..."
                className={`font-paragraph-1 max-h-44 w-full rounded-lg border p-4`}
              />
            </div>
          )}
        />

        <Button
          size="condensed"
          type="submit"
          text="Wyślij sms"
          loading={isSubmitting}
        />
      </form>

      <Button
        size="condensed"
        type="button"
        variant="secondary"
        text="Skanuj wizyty"
        onClick={onScanVisitsClick}
      />
    </div>
  );
};

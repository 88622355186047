import { useAtom } from "jotai";
import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIdentifyFlagsmith } from "components/new/RouteWrapper/hooks/useIdentifyFlagsmith";
import { useIdentifySentry } from "components/new/RouteWrapper/hooks/useIdentifySentry";
import { useLoadCurrentUserData } from "components/new/RouteWrapper/hooks/useLoadCurrentUserData";
import { currentUserAtom } from "lib/atoms/auth";
import { DoctorMSUserGenre } from "lib/graphql/megaSchema";
import { isRouteNotAllowedForUserGenre } from "lib/tools/isRouteNotAllowedForUserGenre";

const defaultRouteMap: Record<DoctorMSUserGenre, string> = {
  STAFF: "/patients",
  DOCTOR: "/visits/per-day",
  NURSE: "/visits/per-day",
  ASSISTANT: "/visits/active",
  PATIENT: "/patients",
  MODERATOR: "/patients",
  CLINIC_MANAGER: "/patients",
};

export const HandleUser = ({ children }: PropsWithChildren) => {
  const [currentUser] = useAtom(currentUserAtom);
  const location = useLocation();

  useLoadCurrentUserData();
  useIdentifyFlagsmith();
  useIdentifySentry();

  if (!currentUser?.genre) return null;

  if (isRouteNotAllowedForUserGenre(location.pathname, currentUser.genre)) {
    return <Navigate replace to={defaultRouteMap[currentUser.genre]} />;
  }

  return children;
};

import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const isInterviewSectionFilledAtom = atom(false);
export const wasDoctorInterviewFocusedAtom = atomWithStorage<
  Record<string, boolean>
>("was-doctor-interview-focused", {});
export const mainDiagnosisInterviewAtom = atom<string | null>(null);
export const isMedicalServiceConfirmModalOpenAtom = atom(false);
export const issuedReferralAtom = atom<{
  urgent: boolean;
} | null>(null);

import { Button, MessageBox, Tooltip } from "@jutro/ui";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorVisitQuery,
  useDoctorTodayComboTestQuery,
} from "lib/graphql/megaSchema";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { isVisitScheduled } from "lib/tools/visit";
import { VisitTagTypeHelper } from "views/Patient/Chat/DraftList/DraftElement/DraftElement";
import { wasDoctorInterviewFocusedAtom } from "views/Visit/RightPanel/Visit/atoms";
import { VisitStatusTag } from "views/Visits/PerDay/components/SlotList/VisitGroup/VisitStatusTag";
import { useVisitTab } from "../hooks";

type Props = {
  visit: Exclude<DoctorVisitQuery["doctorVisit"], null>;
};

export const StatusSection = ({ visit }: Props) => {
  const {
    closeVisit,
    currentUser,
    closeDocumentation,
    endPreVisit,
    inviteForStandard,
    loading,
    startVisit,
    isCloseDocumentationDisabled,
    isEndVisitDisabled,
    isReopenVisitDisabled,
    reopenVisit,
    isVisitAssignedToCurrentUser,
  } = useVisitTab(visit);

  const [isCloseDocumentationModalOpen, setIsCloseDocumentationModalOpen] =
    useState(false);
  const [isIpomWarningModalOpen, setIsIpomWarningModalOpen] = useState(false);
  const { visibleTiles } = useTileSystem();
  const [wasDoctorInterviewFocused] = useAtom(wasDoctorInterviewFocusedAtom);

  const { data } = useDoctorTodayComboTestQuery({
    variables: { patientId: visit.patient?.id ?? "" },
    skip: !visit.patient?.id,
  });

  const getStartVisitTooltipContent = () => {
    if (currentUser?.id !== visit.doctorId) {
      return "Pacjent umówił się do innego lekarza.";
    }

    if (
      !isVisitScheduled(visit.plannedStart?.iso) &&
      !visit.prescriptionRequest
    ) {
      return "Nie można rozpocząć wizyty no show.";
    }

    return "";
  };

  const startVisitTooltipContent = useMemo(() => {
    const content = getStartVisitTooltipContent();

    return content;
  }, [currentUser?.id, visit.doctorId, visit.plannedStart?.iso]);

  const handleClickCloseVisit = () => {
    if (visibleTiles.includes("ipom-form")) {
      setIsIpomWarningModalOpen(true);
      return;
    }

    closeVisit();
  };

  return (
    <>
      <div className="flex w-full flex-col gap-4 rounded-md bg-white p-4">
        <div className="flex items-center gap-4">
          <span className="font-label text-jutro-new-warm-gray-800">
            Status wizyty
          </span>

          <VisitTagTypeHelper
            type={visit.prescriptionRequestId ? "PRESCRIPTION" : visit.type}
          />

          {visit.type && <VisitStatusTag visitElement={visit} />}
        </div>

        {data?.doctorTodayComboTest && (
          <MessageBox
            color="orange"
            text="Sprawdź wyniki testu Combo Antygen Na Grypę A/B + Covid-19/RSV"
          />
        )}

        {visit.status === "ARCHIVE" && (
          <div className="font-label">Wizyta usunięta</div>
        )}

        {visit.type ? (
          <div>
            {!visit.startOn && (
              <Tooltip
                disabled={
                  currentUser?.id === visit.doctorId &&
                  isVisitScheduled(visit.plannedStart?.iso)
                }
                position="bottom"
                content={startVisitTooltipContent}
                triggerAsChild={false}
                trigger={
                  <Button
                    onClick={startVisit}
                    text="Rozpocznij wizytę"
                    loading={loading}
                    disabled={
                      visit.type === "VIRTUAL" && visit.prescriptionRequest
                        ? loading || !isVisitAssignedToCurrentUser
                        : loading ||
                          !isVisitAssignedToCurrentUser ||
                          !isVisitScheduled(visit.plannedStart?.iso)
                    }
                  />
                }
              />
            )}

            {visit.startOn && !visit.leftOn && (
              <div className="flex gap-2">
                <div className="flex-1">
                  <Button
                    onClick={handleClickCloseVisit}
                    text="Zakończ wizytę"
                    loading={loading}
                    disabled={
                      isEndVisitDisabled || !isVisitAssignedToCurrentUser
                    }
                  />
                </div>

                {visit.type === "VIRTUAL" && (
                  <div className="flex-1">
                    <Button
                      variant="secondary"
                      onClick={inviteForStandard}
                      text="Zaproś na wizytę osobistą"
                      disabled={loading}
                    />
                  </div>
                )}
              </div>
            )}

            {visit.leftOn && visit.status !== "SUCCESS" && (
              <div>
                <Button
                  onClick={() => {
                    if (
                      visit.preInterview &&
                      visit.preInterview.length > 0 &&
                      !wasDoctorInterviewFocused[visit.id]
                    ) {
                      setIsCloseDocumentationModalOpen(true);
                    } else {
                      closeDocumentation();
                    }
                  }}
                  text="Zamknij dokumentację"
                  loading={loading}
                  disabled={
                    isCloseDocumentationDisabled ||
                    !isVisitAssignedToCurrentUser
                  }
                />
              </div>
            )}

            {visit.status === "SUCCESS" && (
              <div>
                <Button
                  onClick={reopenVisit}
                  text="Otwórz wizytę ponownie"
                  loading={loading}
                  disabled={
                    isReopenVisitDisabled || !isVisitAssignedToCurrentUser
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <div className="flex gap-2">
            <div className="w-full">
              <Button
                onClick={endPreVisit("STANDARD")}
                text="Zaproś na wizytę osobistą"
                disabled={loading}
              />
            </div>
          </div>
        )}
      </div>

      <ConfirmDialog
        title="IPOM w trakcie wystawiania"
        text="Jeśli zakończysz wizytę teraz IPOM nie zostanie wystawiony."
        confirmText="Zakończ mimo to"
        open={isIpomWarningModalOpen}
        setOpen={setIsIpomWarningModalOpen}
        onConfirm={() => closeVisit()}
      />

      <ConfirmDialog
        title="Czy chcesz zamknąć dokumentację?"
        text="Zamykając dokumentację medyczną potwierdzasz przeprowadzenie wywiadu medycznego z pacjentem podczas teleporady."
        confirmText="Tak, zamknij dokumentację"
        open={isCloseDocumentationModalOpen}
        setOpen={setIsCloseDocumentationModalOpen}
        onConfirm={() => closeDocumentation()}
      />
    </>
  );
};

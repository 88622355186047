import { useMemo } from "react";
import { InterviewSection } from "./components/InterviewSection";
import { NoPESELSection } from "./components/NoPESELSection";
import { StatusSection } from "./components/StatusSection";
import { Loader } from "components/new";
import {
  DoctorVisitQuery,
  useDoctorProceduresQuery,
} from "lib/graphql/megaSchema";
import {
  getInterviewDataFromProcedure,
  getInterviewDataFromVisit,
} from "views/Visit/RightPanel/Visit/tools";

type Props = {
  visit: Exclude<DoctorVisitQuery["doctorVisit"], null>;
};

export const VisitTab = ({ visit }: Props) => {
  const procedureType =
    visit.doctor?.genre === "DOCTOR" ? "DOCTOR_ADVICE" : "NURSE_ADVICE";

  const procedureId = useMemo(
    () =>
      visit.procedures.find((procedure) => procedure.type === procedureType)
        ?.id ?? "",
    [visit.procedures],
  );

  const { data: procedureData, loading } = useDoctorProceduresQuery({
    variables: {
      id: procedureId,
    },
    fetchPolicy: "network-only",
    skip: !procedureId,
  });

  if (loading) {
    return <Loader screen />;
  }

  const hasDataInProcedure =
    procedureData?.doctorProcedures &&
    procedureData.doctorProcedures.length > 0;

  const interviewData = hasDataInProcedure
    ? getInterviewDataFromProcedure(procedureData.doctorProcedures[0].data)
    : getInterviewDataFromVisit(visit);

  return (
    <div
      key={JSON.stringify(interviewData)}
      id="visit-block"
      className="flex flex-col gap-2 bg-jutro-new-warm-gray-100"
    >
      {!visit.patient?.pesel && <NoPESELSection />}
      <StatusSection visit={visit} />
      <InterviewSection visit={visit} interviewData={interviewData} />
    </div>
  );
};

import { useMemo } from "react";
import { useDoctorGetDoctorsQuery } from "lib/graphql/megaSchema";
import { usePatientData } from "views/Patient/hooks";
import { getDeclaredMedic } from "./tools";

export const useNfzStatus = () => {
  const { data: patientData } = usePatientData({ from: "cache-only" });

  const { data: doctorsData } = useDoctorGetDoctorsQuery({
    variables: {
      showBlocked: true,
    },
  });

  const doctor = useMemo(
    () => getDeclaredMedic(doctorsData, patientData?.nfzStatus.chosenDoctor),
    [patientData?.nfzStatus.chosenDoctor, doctorsData],
  );

  const nurse = useMemo(
    () => getDeclaredMedic(doctorsData, patientData?.nfzStatus.chosenNurse),
    [patientData?.nfzStatus.chosenNurse, doctorsData],
  );

  const midwife = useMemo(
    () => getDeclaredMedic(doctorsData, patientData?.nfzStatus.chosenMidwife),
    [patientData?.nfzStatus.chosenMidwife, doctorsData],
  );

  return {
    doctor,
    nurse,
    midwife,
    isDeclared: Boolean(patientData?.nfzStatus.declaredIn),
  };
};

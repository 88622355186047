import Editor from "@akord/rich-markdown-editor";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { VariantProps, tv } from "tailwind-variants";
import { imagePreviewAtom } from "lib/atoms";
import { fileConfig } from "lib/tools/httpConfigs";
import { Maybe } from "lib/types";

const getStyles = tv({
  base: "flex flex-col",
  variants: {
    gap: {
      none: "",
      md: "gap-2",
    },
  },
});

type ContentType = "normal" | "richText" | "fileList";

type Props = {
  label: string;
  data: Maybe<string | string[]>;
  type?: ContentType;
} & VariantProps<typeof getStyles>;

export const ExaminationHeaderWithContent = ({
  data,
  label,
  type = "normal",
  ...props
}: Props) => {
  const styles = getStyles(props);

  if (!data || !data.length) {
    return null;
  }

  return (
    <div className={styles}>
      <div className="font-heading-4">{label}</div>
      <ContentBlockManager type={type} data={data} />
    </div>
  );
};

const ContentBlockManager = ({
  type,
  data,
}: {
  type: ContentType;
  data: string | string[];
}) => {
  const [, setImagePreview] = useAtom(imagePreviewAtom);

  if (type === "richText") {
    return (
      <div className="prose">
        <Editor readOnly value={data as string} />
      </div>
    );
  }

  if (type === "fileList" && Array.isArray(data)) {
    return data.map((file, index) => (
      <div
        className="cursor-pointer text-jutro-new-blue-800"
        key={file + index}
        onClick={() =>
          setImagePreview(`${fileConfig[envVars.REACT_APP_CONFIG]}${file}`)
        }
      >
        {file}
      </div>
    ));
  }

  if (Array.isArray(data)) {
    return data.map((element, index) => (
      <div className="font-paragraph-2" key={element + index}>
        {element}
      </div>
    ));
  }

  return <div className="font-paragraph-2">{data}</div>;
};

import {
  BasicTile,
  DataTile,
  Tile,
  Workspace,
  basicTiles,
  workspaces,
} from "lib/tools/createWorkspace/types";

export const isDataTile = (tile: Tile): tile is DataTile => {
  return (
    typeof tile === "object" && "id" in tile && "name" in tile && "data" in tile
  );
};

export const isBasicTile = (str: string): str is BasicTile => {
  return basicTiles.includes(str);
};

export const isWorkspace = (workspace: string): workspace is Workspace =>
  workspaces.includes(workspace);

import { PropsWithChildren } from "react";
import { VariantProps, tv } from "tailwind-variants";

const getStyles = tv({
  base: "",
  variants: {
    text: {
      sm: "font-paragraph-2",
    },
    weight: {
      base: "font-normal",
      md: "font-medium",
    },
  },
  defaultVariants: {
    text: "sm",
    weight: "md",
  },
});

type Props = VariantProps<typeof getStyles>;

export const LabelText = ({ children, ...props }: PropsWithChildren<Props>) => {
  const styles = getStyles(props);

  return <p className={styles}>{children}</p>;
};

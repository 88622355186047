import { useAtom } from "jotai";
import { ElementRef, PropsWithChildren, useEffect, useRef } from "react";
import { focusOnSectionAtom } from "components/FocusableWrapper/atom";
import { FocusSection } from "components/FocusableWrapper/types";

type Props = {
  section: FocusSection;
};

export const FocusableWrapper = ({
  children,
  section,
}: PropsWithChildren<Props>) => {
  const [focusOnSection, setFocusOnSection] = useAtom(focusOnSectionAtom);
  const ref = useRef<ElementRef<"div">>(null);

  const active = focusOnSection === section;

  const observer = new IntersectionObserver(
    ([{ isIntersecting }]) => {
      if (active && !isIntersecting) {
        setFocusOnSection(null);
      }
    },
    {
      threshold: 0.1,
    },
  );

  useEffect(() => {
    if (ref.current && active) {
      ref.current.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      }, 300);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [focusOnSection]);

  return (
    <div
      ref={ref}
      className={
        active
          ? "z-1 rounded-lg outline outline-2 -outline-offset-2 outline-jutro-new-blue-400"
          : ""
      }
      onClick={() => setFocusOnSection(null)}
    >
      {children}
    </div>
  );
};

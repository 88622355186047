import {
  Button,
  Checkbox,
  DateInput,
  NavigationButton,
  Select,
  TimeInput,
} from "@jutro/ui";
import dayjs from "dayjs";
import { Controller, SubmitHandler } from "react-hook-form";
import { SlotsList } from "components/ScheduleVisitForm/SlotsList";
import { useScheduleVisitForm } from "components/ScheduleVisitForm/hooks/useScheduleVisitForm";
import { useScheduleVisitMutations } from "components/ScheduleVisitForm/hooks/useScheduleVisitMutations";
import { useTileModeConfig } from "components/ScheduleVisitForm/hooks/useTileModeConfig";
import { ScheduleFormSchema } from "components/ScheduleVisitForm/tools/form";
import {
  durationOptions,
  slotTypeOptions,
  telemedicalSlotsTypesWithVojevodship,
} from "components/ScheduleVisitForm/tools/staticOptions";
import { ScheduleVisitTileData } from "components/ScheduleVisitForm/types";
import { VisitCard } from "components/VisitCard";
import { DoctorMSSlotType } from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

type Props = {
  scheduleVisitTileData: ScheduleVisitTileData;
};

export const ScheduleVisitForm = ({ scheduleVisitTileData }: Props) => {
  const { id, mode, visitData } = scheduleVisitTileData;

  const { loading, handleConfirm } = useScheduleVisitMutations({
    scheduleVisitTileData,
  });

  const {
    methods,
    organizationsOptions,
    doctorsOptions,
    officeOptions,
    filteredSlots,
    isLoadingSlots,
    isChosenSlotTypeTelemedical,
    isViewForceable,
    isPatientChild,
  } = useScheduleVisitForm({ scheduleVisitTileData });

  const { watch, control, setValue, handleSubmit } = methods;

  const {
    slotType,
    organizationId,
    doctorId,
    chosenSlotId,
    plannedDate,
    plannedTime,
  } = watch();

  const { vojevodshipsWithCities } = useVojevodshipOptions();

  const { removeTile } = useTileSystem();

  const { title, toggleText, switchMode } = useTileModeConfig({
    tileId: id,
    mode,
  });

  const isSubmitButtonVisible =
    slotType && (chosenSlotId || (plannedDate && plannedTime));

  const onSubmit: SubmitHandler<ScheduleFormSchema> = async (data) => {
    await handleConfirm(data);

    removeTile(id);
  };

  return (
    <form
      className="flex h-full flex-col items-center gap-4 rounded-lg bg-white p-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex w-full items-center justify-between">
        <h4 className="font-heading-4 text-jutro-new-warm-gray-800">{title}</h4>
        <NavigationButton onClick={() => removeTile(id)} />
      </div>

      {(mode === "edit" || mode === "forceEdit") && visitData && (
        <VisitCard visit={visitData} withActions={false} />
      )}

      <Controller
        name="slotType"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            label="Typ wizyty*"
            placeholder="Wybierz typ wizyty"
            value={slotTypeOptions.find((o) => o.value === value) ?? null}
            onChange={(o) => onChange(o?.value)}
            options={slotTypeOptions}
          />
        )}
      />

      {slotType ? (
        <>
          {!isChosenSlotTypeTelemedical && (
            <Controller
              name="organizationId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  noOptionsMessage="Brak dostępnych przychodni"
                  label="Przychodnia*"
                  placeholder="Wybierz przychodnie"
                  isSearchable
                  value={
                    organizationsOptions.find((o) => o.value === value) ?? null
                  }
                  onChange={(o) => setTimeout(()=>onChange(o?.value), 100)}
                  options={organizationsOptions.filter(
                    ({ value }) => value.length === 4,
                  )}
                />
              )}
            />
          )}

          {isViewForceable && !isChosenSlotTypeTelemedical && (
            <Controller
              name="officeId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  noOptionsMessage="Brak dostępnych gabinetów"
                  label="Gabinet*"
                  disabled={!organizationId}
                  placeholder="Wybierz gabinet"
                  value={officeOptions.find((o) => o.value === value) ?? null}
                  onChange={(o) => onChange(o?.value)}
                  options={officeOptions}
                />
              )}
            />
          )}

          {telemedicalSlotsTypesWithVojevodship.includes(
            slotType as DoctorMSSlotType,
          ) && (
            <Controller
              name="organizationId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Województwo*"
                  placeholder="Wybierz województwo"
                  isSearchable
                  value={
                    value === "P"
                      ? { value: "P", label: "Polska" }
                      : (vojevodshipsWithCities.find(
                          (o) => o.value === value,
                        ) ?? null)
                  }
                  onChange={(o) => onChange(o?.value)}
                  options={[
                    ...(isViewForceable
                      ? [{ value: "P", label: "Polska" }]
                      : []),
                    ...vojevodshipsWithCities,
                  ]}
                />
              )}
            />
          )}

          {isViewForceable && (
            <Controller
              name="slotDuration"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Długość wizyty*"
                  placeholder="Wybierz długość wizyty"
                  value={durationOptions.find((o) => o.value === value) ?? null}
                  onChange={(o) => onChange(o?.value)}
                  options={durationOptions}
                />
              )}
            />
          )}

          {(organizationId || isChosenSlotTypeTelemedical) && (
            <div className="flex w-full flex-col gap-2">
              <Controller
                name="doctorId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    noOptionsMessage="Brak dostępnych osób realizujących"
                    label="Osoba realizująca*"
                    placeholder="Wybierz osobę realizującą"
                    isSearchable
                    value={
                      doctorsOptions.find((o) => o.value === value) ?? null
                    }
                    onChange={(o) => onChange(o?.value)}
                    options={doctorsOptions}
                  />
                )}
              />

              {isPatientChild && (
                <Controller
                  name="areAdultDoctorsVisible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      label="Pokaż personel nieobsługujący dzieci"
                      name="AdultDoctorsCheckbox"
                      checked={value}
                      onChange={() => {
                        onChange(!value);
                        setValue("doctorId", null);
                      }}
                    />
                  )}
                />
              )}
            </div>
          )}
        </>
      ) : null}

      <Button
        type="button"
        variant="ghost"
        onClick={() => {
          switchMode();
          setValue("chosenSlotId", null);
          setValue("plannedDate", null);
          setValue("plannedTime", null);
        }}
        text={toggleText}
      />

      {!isViewForceable &&
        (organizationId || slotType === "TELEMEDICAL_PRIVATE") && (
          <Controller
            name="chosenSlotId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SlotsList
                slots={filteredSlots}
                loading={isLoadingSlots}
                chosenSlotId={value}
                setChosenSlotId={onChange}
              />
            )}
          />
        )}

      {isViewForceable && doctorId && (
        <div className="grow">
          <div className="flex justify-center gap-4">
            <Controller
              name="plannedDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  value={value ? dayjs(value).toDate() : null}
                  onChange={(value) =>
                    onChange(
                      value
                        ? dayjs(value as Date).format("YYYY-MM-DD")
                        : dayjs().format("YYYY-MM-DD"),
                    )
                  }
                  minDate={dayjs().toDate()}
                  maxDate={dayjs().add(1, "year").toDate()}
                />
              )}
            />

            <Controller
              name="plannedTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="z-1">
                  <TimeInput value={value ?? ""} onChange={onChange} />
                </div>
              )}
            />
          </div>
        </div>
      )}

      {isSubmitButtonVisible && (
        <Button type="submit" text="Umów wizytę" loading={loading} />
      )}
    </form>
  );
};

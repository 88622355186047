import { Maybe } from "@jutro/types";

type Props = {
  name: string;
  form: string;
  dose: string;
  box: Maybe<string>;
  boxAmount?: Maybe<number>;
  issueDate?: string;
};

export const DrugDetails = ({
  name,
  box,
  boxAmount,
  dose,
  form,
  issueDate,
}: Props) => {
  const boxString = box ? `/${box}` : "";
  const boxAmountString = boxAmount ? `(${boxAmount} op.)` : "";

  return (
    <>
      <div className="flex flex-wrap gap-x-4">
        <h4 className="font-heading-4">{name}</h4>
        {issueDate && <p className="font-paragraph-1">{issueDate}</p>}
      </div>
      <p className="font-paragraph-1">{`${form} ${dose} ${boxString} ${boxAmountString}`}</p>
    </>
  );
};

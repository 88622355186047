import { useAtom } from "jotai";
import { AppRoutes } from "views";
import { Loader } from "components/new";
import { useInitSentry } from "components/new/RouteWrapper/hooks/useInitSentry";
import { authStatusAtom } from "lib/atoms/auth";
import { useInitCheckAuthStatus } from "lib/hooks/auth";
import { useMaintenanceMode } from "lib/hooks/flags";
import { getParams } from "lib/tools/getParams";
import { isPage, pageMap } from "lib/tools/pages";
import { LoginScreen } from "views/Login";
import { MaintenanceModeView } from "views/MaintenanceMode";

const params = getParams();

export const App = () => {
  const [authStatus] = useAtom(authStatusAtom);
  const [maintenanceModeEnabled, maintenanceModeLoading] = useMaintenanceMode();

  useInitCheckAuthStatus(); // seeds  authStatusAtom
  useInitSentry();

  if (!authStatus || maintenanceModeLoading) {
    return <Loader screen />;
  }

  if (maintenanceModeEnabled) {
    return <MaintenanceModeView />;
  }

  if (isPage(params.page)) {
    return pageMap[params.page];
  }

  if (authStatus === "UNAUTHORIZED") {
    return <LoginScreen />;
  }

  return <AppRoutes />;
};

import { Loader } from "components/new";
import { Header } from "views/Visit/RightPanel/NewPrescription/components/Overlays/Header";
import { OrderedDrug } from "views/Visit/RightPanel/NewPrescription/components/Overlays/OrderedDrugs/Item/index";
import { useVisitData } from "views/Visit/hooks";

export const Content = () => {
  const { data: currentVisit, loading } = useVisitData({ from: "cache-only" });

  const orderedDrugs = currentVisit?.prescriptionRequest?.requestedDrugs.filter(
    (drug) => (drug.resolvedInPrescriptions?.length ?? 0) < 1,
  );

  if (loading) {
    return <Loader />;
  }

  if (!currentVisit || orderedDrugs?.length === 0) {
    return <span>Wszystkie zamówione leki zostały wystawione</span>;
  }

  return (
    <div className="flex items-center justify-between gap-4 pt-2 first-of-type:pt-0">
      <div className="flex w-full flex-col gap-2 divide-y">
        {orderedDrugs?.map((drug) => {
          return (
            <OrderedDrug
              key={drug.ean}
              drug={drug}
              sourceTag={drug.regularDrugSourceTag}
            />
          );
        })}
      </div>
    </div>
  );
};

export const OrderedDrugs = () => {
  return (
    <div className="h-full w-full rounded-lg">
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
        <Header variant="ordered" />
        <Content />
      </div>
    </div>
  );
};

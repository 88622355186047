import { DiamondCheckIcon } from "@jutro/ui";
import { DoctorMSEwusGreenEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../../NfzEvent";
import { EventHandler } from "../../../NfzEvent/infer";

export const EwusGreenEventHandler: EventHandler<DoctorMSEwusGreenEvent> = ({
  event,
}) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.INSURANCE_STATUS}
      statusText="Pacjent zielony"
      icon={<DiamondCheckIcon size="sm" />}
      color="green"
    />
  );
};

import { useEffect, useRef, useState } from "react";
import { Block } from "components/components/Doctor/Block";
import { useDoctorCreateChatMessageMutation } from "lib/graphql/megaSchema";
import { ConfirmDialog } from "./new/ConfirmDialog";

const renderAction = ({
  title,
  altCondition,
  altTitle,
  onClick,
  color,
  cursor,
}) => (
  <div
    className="inline-block"
    key={title}
    onClick={onClick}
    style={{ color, cursor }}
  >
    {altCondition ? altTitle : title}
  </div>
);

const autosize = (area) => {
  if (!area) return;
  area.style.cssText = "height: auto;";
  area.style.cssText = `height:${area?.scrollHeight}px`;
};

const Wklejki = ({ value, field, onChange, snippets }) => {
  const [open, setOpen] = useState(false);
  return (
    <div key={value}>
      <span
        className="cursor-pointer text-jutro-new-blue-950"
        onClick={() => setOpen((prev) => !prev)}
      >
        Wklejki
      </span>

      {open && (
        <select
          className="ml-2 cursor-pointer border-none bg-white text-[11px] text-jutro-new-blue-950"
          onChange={(e) => {
            onChange(value ? value + "\n" + e.target.value : e.target.value);
            setTimeout(() => autosize(field.current), 500);
            setOpen(false);
          }}
        >
          <option disabled selected>
            Wybierz
          </option>

          {snippets
            .filter((e) => e && e.name[0] !== "[")
            .map((e) => (
              <option key={e.content} value={e.content}>
                {e.name}
              </option>
            ))}
        </select>
      )}
    </div>
  );
};

export const TextareaBlock = ({
  value = "",
  onChange = (e) => e,
  title = "",
  disabled = false,
  modalTitle = "Czy na pewno chcesz wyczyścić pole tekstowe?",
  modalContent = title,
  actions: actionsList = [],
  errors = "",
  onBlur = () => {},
  noPadding = false,
  placeholder = "",
  autoFocus = false,
  snippets = false,
  patientId = undefined,
  resize = true,
  big = false,
}) => {
  const [open, setOpen] = useState(false);
  const field = useRef(null);
  const [rolledUp, setRolledUp] = useState(true);

  const [createMessage] = useDoctorCreateChatMessageMutation({
    variables: {
      id: patientId,
    },
  });

  useEffect(() => {
    if (!field.current?.style) {
      return;
    }

    rolledUp
      ? (field.current.style.cssText = "height: 78px;")
      : (field.current.style.cssText = "");
  }, [rolledUp, field.current]);

  const actions = [
    {
      name: "Wklejki",
      title: (
        <Wklejki
          value={value}
          field={field}
          autosize={autosize}
          onChange={onChange}
          snippets={snippets}
        />
      ),
    },
    {
      name: "Dyktuj",
      title: (
        <>
          <span className="recordingstarted"> Zakończ dyktowanie</span>
          <span className="recordingstopped"> Rozpocznij dyktowanie</span>
        </>
      ),
      onClick: () => {
        if (window.recognitionStarted) {
          document.body.className = "";
          window.recognition.onend = () => {};

          window.recognition.stop();
          window.recognitionStarted = false;
        } else {
          document.body.className = "recstart";
          window.transcript = field.current;

          window.recognition.onend = () => {
            window.recognition.start();
          };

          window.recognition.onresult = (event) => {
            if (event.results[0].isFinal) {
              const content =
                field.current.value + " " + event.results[0][0].transcript;
              onChange(content);
              setTimeout(() => {
                autosize(field.current);
              }, 500);
            }
          };

          window.recognition.start();
          window.recognitionStarted = true;
        }
      },
      color: "rgb(49, 154, 213)",
      cursor: "pointer",
    },
    {
      title: "Wyślij pacjentowi",
      onClick: () =>
        createMessage({ variables: { input: { content: value } } }),
      color: value ? "#26C299" : "#C9C6C5",
      cursor: value ? "pointer" : "not-allowed",
    },
    {
      title: "Wyczyść",
      onClick: value ? () => setOpen(true) : null,
      color: value ? "#FF453A" : "#C9C6C5",
      cursor: value ? "pointer" : "not-allowed",
    },
    {
      title: "Zwiń",
      altTitle: "Rozwiń",
      altCondition: rolledUp,
      onClick: () => {
        setRolledUp((prev) => !prev);
      },
      color: "#161541",
      cursor: "pointer",
    },
  ];

  const chosenActions = actions.filter(
    (action) =>
      actionsList.includes(action.title) || actionsList.includes(action.name),
  );

  const Title = ({ big = false }) => {
    return (
      <div
        className={`mb-2 flex flex-row justify-between gap-2 ${
          big && "font-paragraph-2"
        }`}
      >
        {title}
        {disabled ? (
          <div className="mb-2 flex flex-row gap-2">
            {chosenActions.filter((a) => a.title === "Zwiń").map(renderAction)}
          </div>
        ) : (
          <div className="mb-2 flex flex-row gap-2">
            {chosenActions.map(renderAction)}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ConfirmDialog
        open={open}
        title={modalTitle}
        setOpen={setOpen}
        onConfirm={onChange.bind(null, "")}
        text={modalContent}
      />
      <Block noPadding={noPadding} title={<Title big={big} />}>
        <div className="relative w-full">
          <textarea
            className={`scrolling-touch font-paragraph-2 relative min-h-[78px] w-full overflow-auto overflow-y-hidden rounded-lg border border-solid p-2 text-jutro-new-warm-gray-800 placeholder-jutro-new-warm-gray-500 outline-none ${
              resize ? "resize-y" : "resize-none"
            } ${big && "!font-paragraph-2"} ${disabled ? "bg-jutro-new-warm-gray-100" : "bg-white"} ${
              errors
                ? "border-jutro-new-rose-600"
                : "border-jutro-new-warm-gray-300"
            }`}
            style={
              field.innerRef?.current
                ? { height: field.innerRef.current?.scrollHeight }
                : {}
            }
            onFocus={(e) => {
              const textarea = e.target;
              textarea.style.cssText = `height: ${textarea.scrollHeight}px;`;
            }}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              autosize(e.target);
              onChange(e.target.value);
            }}
            ref={field.innerRef}
          />
        </div>
      </Block>
    </>
  );
};

import { Input } from "@jutro/ui";
import { ComponentProps } from "react";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  label: string;
  name: string;
  placeholder?: string;
  type?: "number" | "text";
  parseValueInt?: boolean;
  disabled?: boolean;
  sideElement?: React.ReactNode;
  sideElementPosition?: ComponentProps<typeof Input>["sideElementPosition"];
};

export const ControlledInput = ({
  name,
  label,
  type = "text",
  placeholder = "Kliknij, aby uzupełnić",
  parseValueInt,
  disabled,
  sideElement,
  sideElementPosition = "start",
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error },
      }) => (
        <Input
          sideElement={sideElement ? sideElement : undefined}
          sideElementPosition={sideElementPosition}
          label={label}
          disabled={disabled}
          isFloat
          type={type}
          placeholder={placeholder}
          value={value}
          error={error?.message && "To pole jest wymagane."}
          onChange={(e) => {
            const value = e.target.value;
            if (type === "number" && parseValueInt) {
              return onChange(value ? parseInt(value) : 0);
            }

            return onChange(value);
          }}
          {...rest}
        />
      )}
    />
  );
};

import RichMarkdownEditor from "@akord/rich-markdown-editor";
import light from "@akord/rich-markdown-editor/dist/styles/theme";
import { Button, CircleRemoveIcon } from "@jutro/ui";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { OpenSnippetListButton } from "components/Snippet/OpenSnippetListButton";
import { SnippetCategoryFilter } from "components/Snippet/types";

type Props = {
  disabled: boolean;
  name: string;
  label: string;
  snippetType?: SnippetCategoryFilter;
  withClear?: boolean;
  onFocus?: () => void;
};

export const MdTextarea = ({
  disabled,
  name,
  label,
  snippetType = "recommendation",
  withClear = false,
  onFocus,
}: Props) => {
  const { control } = useFormContext();

  const [showEditor, setShowEditor] = useState(true);

  const rerenderEditor = () => {
    setShowEditor(() => false);

    setTimeout(() => {
      setShowEditor(() => true);
    }, 50);
  };

  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className="flex flex-col gap-1">
          <div className="flex w-full justify-between">
            <div className="font-label text-jutro-new-warm-gray-800">
              {label}
            </div>

            <div className="flex items-center">
              <OpenSnippetListButton
                size="condensed"
                category={snippetType}
                type="DOCS"
                disabled={disabled}
              />

              {withClear && (
                <Button
                  onClick={() => {
                    onChange("");
                    rerenderEditor();
                  }}
                  label="Wyczyść"
                  tooltipPosition="top"
                  icon={<CircleRemoveIcon />}
                  disabled={disabled}
                  type="button"
                  variant="negativeGhost"
                  size="condensed"
                />
              )}
            </div>
          </div>

          {showEditor && (
            <div className={`prose ${disabled && "prose-disabled"}`}>
              <RichMarkdownEditor
                defaultValue={value ?? ""}
                placeholder="Kliknij, aby uzupełnić"
                style={{ backgroundColor: "white" }}
                onFocus={onFocus}
                dictionary={{
                  bulletList: "Lista punktowana",
                  checkboxList: "Lista rzeczy do zrobienia",
                  codeBlock: "Blok kodu",
                  h1: "Duży nagłówek",
                  h2: "Średni nagłówek",
                  h3: "Mały nagłówek",
                  heading: "Nagłowek",
                  hr: "Rozdzielacz",
                  image: "Zdjęcie",
                  imageUploadError: "Błąd wysyłania zdjęcia",
                  imageCaptionPlaceholder: "Opis zdjęcia",
                  info: "Informacja",
                  infoNotice: "Informacja",
                  link: "Link",
                  linkCopied: "Link skopiowany",
                  mark: "Zaznacz",
                  newLineEmpty: "Kliknij aby uzupełnić",
                  newLineWithSlash: "Użyj strzałek ⬆⬇ i naciśnij ENTER",
                  noResults: "Brak wyników",
                  openLink: "Otwórz link",
                  orderedList: "Lista numeryczna",
                  pageBreak: "Rozdzielacz przerywany",
                  pasteLink: "Wklej link",
                  pasteLinkWithTitle: () => "Wklej link z tytułem",
                  placeholder: "Placeholder",
                  quote: "Cytat",
                  removeLink: "Usuń link",
                  searchOrPasteLink: "Wklej link",
                  strikethrough: "Przekreślenie",
                  strong: "Wyboldowanie",
                  subheading: "Podtytuł",
                  table: "Tabela",
                  tip: "Wskazówka",
                  tipNotice: "Wskazówka",
                  warning: "Ostrzeżenie",
                  warningNotice: "Ostrzeżenie",
                }}
                theme={{ ...light, placeholder: "#92929B" }}
                readOnly={disabled}
                onChange={(get) => {
                  const currentValue = get();

                  if (currentValue === "\\\n") {
                    onChange("");
                    return;
                  }

                  if (currentValue.endsWith("\\\n")) {
                    onChange(currentValue.slice(0, -3));
                    return;
                  }

                  onChange(currentValue);
                }}
              />
            </div>
          )}
        </div>
      )}
    />
  );
};

import { CheckableElementProps } from "components/TagFormNew/types";
import { Checkbox } from "components/new";

export const FileElement = ({
  document,
  isChecked,
  handleChecked,
}: CheckableElementProps) => {
  const { name } = document;

  const handleChange = () => {
    if (isChecked) {
      handleChecked((prev) => prev.filter(({ id }) => id !== document.id));
      return;
    }

    handleChecked((prev) => [...prev, document]);
  };

  return <Checkbox value={isChecked} label={name} onChange={handleChange} />;
};

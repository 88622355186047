import { MessageIcon } from "@jutro/ui";
import { VisitsItem } from "views/Visits/PerDay/components/SlotList/types";

type Props = {
  visitElement: VisitsItem;
};

export const NotificationViewBlock = ({ visitElement }: Props) => {
  const isLastChatMessageSentByPatient =
    visitElement.patient?.lastChatMessage?.author?.__typename ===
    "DoctorMSPatient";

  const isVisitInProgress = visitElement.status === "INPROGRESS";

  if (isVisitInProgress && isLastChatMessageSentByPatient) {
    return (
      <div className="flex gap-1">
        <div className="relative">
          <figure className="absolute right-0 top-0 h-1.5 w-1.5 rounded-full bg-red-500" />

          <MessageIcon size="sm" />
        </div>

        <div>Oczekuje na odpowiedź</div>
      </div>
    );
  }

  return null;
};

import { EnvVars, envVars } from "envvars";

export type ConfigMap = Record<EnvVars["REACT_APP_CONFIG"], string>;

const STAGING = envVars.REACT_APP_ALT_STAGING || "https://api.jutromedical.dev";

export const httpConfig: ConfigMap = {
  staging: STAGING,
  server: `https://api.jutromedical.com`,
  local: `http://localhost:6001`,
  dev: `https://gql.jworker.krzysztofsikorski.pl`,
};

export const uppyConfig: ConfigMap = {
  staging: `${STAGING}/file?id=`,
  server: `https://api.jutromedical.com/file?id=`,
  local: `http://localhost:6001/file?id=`,
  dev: `https://api.jutromedical.com/file?id=`,
};

export const avatarConfig: ConfigMap = {
  staging: `${STAGING}/avatar`,
  server: `https://api.jutromedical.com/avatar`,
  local: `http://localhost:6001/avatar`,
  dev: `https://gql.jworker.krzysztofsikorski.pl/avatar`,
};

export const doctorConfig: ConfigMap = {
  staging: `${envVars.REACT_APP_MEGA_SCHEMA_URL}/graphql`,
  server: `https://mega.jutromedical.com/graphql`,
  local: `http://localhost:6001/doctor`,
  dev: `https://gql.jworker.krzysztofsikorski.pl/doctor`,
};

export const fileConfig: ConfigMap = {
  staging: `${STAGING}/file?id=`,
  server: `https://api.jutromedical.com/file?id=`,
  local: `http://localhost:6001/file?id=`,
  dev: `${STAGING}/file?id=`,
};

export const ourUploadConfig: ConfigMap = {
  staging: `${STAGING}/ourUpload`,
  server: `https://api.jutromedical.com/ourUpload`,
  local: `http://localhost:6001/ourUpload`,
  dev: `https://gql.jworker.krzysztofsikorski.pl/ourUpload`,
};

export const fileModyfierConfig: ConfigMap = {
  staging: `${STAGING}/fileModyfier`,
  server: `https://api.jutromedical.com/fileModyfier`,
  local: `http://localhost:6001/fileModyfier`,
  dev: `https://api.jutromedical.com/fileModyfier`,
};

export const websocketConfig: ConfigMap = {
  staging: `wss://${STAGING.split("//")[1]}/doctor/subscriptions`,
  server: `wss://api.jutromedical.com/doctor/subscriptions`,
  local: `ws://localhost:6001/doctor/subscriptions`,
  dev: `ws://gql.jworker.krzysztofsikorski.pl/doctor/subscriptions`,
};

export const ecommercePosConfig: ConfigMap = {
  server: "https://int.sklep.jutromedical.com",
  staging: "https://saleor-integrator.st.jutromedical.com",
  local: "https://saleor-integrator.st.jutromedical.com",
  dev: "https://saleor-integrator.st.jutromedical.com",
};

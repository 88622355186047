import { Button, Input } from "@jutro/ui";
import { useState } from "react";

type Props = {
  onGetExaminationsTemplate: (visitId: string) => void;
};

export const VisitIdInput = ({ onGetExaminationsTemplate }: Props) => {
  const [visitIdInput, setVisitIdInput] = useState("");

  const handleClick = () => {
    onGetExaminationsTemplate(visitIdInput);
  };

  return (
    <div className="w-1/3">
      <Input
        maxLength={24}
        name="ID"
        label="ID wizyty pobraniowej"
        placeholder="Podaj ID wizyty"
        onChange={(e) => setVisitIdInput(e.target.value)}
        value={visitIdInput}
      />
      <div className="mx-auto w-1/2">
        <Button
          disabled={visitIdInput.length !== 24}
          variant="secondary"
          text="Pobierz wyniki"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

import {
  AddCircleIcon,
  BinIcon,
  Button,
  ChatIcon,
  ProfileIcon,
} from "@jutro/ui";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { Table } from "components/new";
import { useUserGenre } from "lib/hooks/useUserGenre";
import { CreatePatientDrawer } from "views/Patients/components/CreatePatientDrawer";
import { DeletePatientDrawer } from "views/Patients/components/DeletePatientDrawer";
import { usePatients } from "views/Patients/hooks";
import { PatientData } from "views/Patients/types";

export enum TestId {
  CreatePatientButton = "PatientViewTableButton",
}

export const PatientsView = () => {
  const [isShown, setIsShown] = useState(false);
  const [showDeletePanel, setShowDeletePanel] = useState(false);
  const [showCreatePanel, setShowCreatePanel] = useState(false);
  const [patientData, setPatientData] = useState<PatientData | null>(null);
  const navigate = useNavigate();

  const { isNurse, isAssistant, isDoctor, isAdministration } = useUserGenre();

  const { patients, loading, deletePatient, searchText, setSearchText } =
    usePatients();

  const isUserAllowed = isNurse || isAssistant || isAdministration;

  const columns = useMemo<Column<PatientData>[]>(
    () => [
      {
        Header: "Pacjent",
        width: 200,
        accessor: (row) => {
          return (
            <div>
              <h1 className="whitespace-pre-wrap font-medium text-jutro-new-warm-gray-900">
                <span
                  data-hj-suppress
                >{`${row.firstName} ${row.lastName}`}</span>
              </h1>
              <span className="font-light">{row.patientCardNumber}</span>
            </div>
          );
        },
      },
      {
        Header: "Rejestracja",
        accessor: (row) => {
          return (
            <>
              <div className="font-light text-jutro-new-warm-gray-900">
                {dayjs(row.createdAt.iso).format("YYYY-MM-DD")}
              </div>
              <div className="font-paragraph-2">
                {dayjs(row.createdAt.iso).format("hh:mm")}
              </div>
            </>
          );
        },
      },
      {
        Header: "Telefon",
        width: 150,
        accessor: (row) => {
          return (
            <div>
              <span className="font-light">
                {row.phone.startsWith("000") ? "brak" : row.phone}
              </span>
            </div>
          );
        },
      },
      {
        Header: "PESEL",
        accessor: (row) => {
          return (
            <div>
              <span className="font-light">
                {row.pesel?.length === 12 ? "brak" : row.pesel}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Adres",
        accessor: (row) => {
          return (
            <div data-hj-suppress>
              {row.address ? (
                <>
                  <div className="font-paragraph-2 whitespace-pre-wrap text-jutro-new-warm-gray-900">
                    {row.address.street} {row.address.houseNumber}/
                    {row.address.flatNumber}
                  </div>
                  <div className="font-paragraph-2 text-jutro-new-warm-gray-500">
                    {row.address.city} {row.address.zipCode}
                  </div>
                </>
              ) : (
                <span className="text-md text-jutro-new-warm-gray-800">
                  Brak
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: "Actions",
        accessor: (row) => (
          <div className="flex w-full justify-end gap-2 pr-4">
            {isDoctor ? (
              <Button
                icon={<ChatIcon />}
                size="condensed"
                variant="ghost"
                full={false}
                label="Przejdź do czatu"
                tooltipPosition="top"
                onClick={() => {
                  navigate(`/patients/${row.id}/chat`);
                }}
              />
            ) : null}

            {isUserAllowed ? (
              <Button
                icon={<ProfileIcon />}
                size="condensed"
                variant="ghost"
                full={false}
                label="Przejdź do profilu"
                tooltipPosition="top"
                onClick={() => {
                  navigate(`/patients/${row.id}/profile`);
                }}
              />
            ) : null}

            {isAdministration || isAssistant ? (
              <Button
                icon={<BinIcon />}
                size="condensed"
                variant="negativeGhost"
                full={false}
                label="Usuń pacjenta"
                tooltipPosition="top"
                onClick={() => {
                  setPatientData(row);
                  setIsShown(true);
                  setShowDeletePanel(true);
                }}
              />
            ) : null}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Scrollbars className="w-full" autoHide>
      <div className="flex flex-col gap-4">
        <Table
          flexLayoutMode
          columns={columns}
          data={patients}
          loading={loading}
          addSearchList={{ searchText, setSearchText }}
          header={
            isUserAllowed ? (
              <div data-testid={TestId.CreatePatientButton}>
                <Button
                  onClick={() => setShowCreatePanel(true)}
                  icon={<AddCircleIcon />}
                  text="Konto pacjenta"
                />
              </div>
            ) : null
          }
        />

        {patientData && showDeletePanel && (
          <DeletePatientDrawer
            isShown={isShown}
            setIsShown={setIsShown}
            setShowDeletePanel={setShowDeletePanel}
            patientData={patientData}
            setPatientData={setPatientData}
            deletePatient={deletePatient}
          />
        )}

        <CreatePatientDrawer
          isShown={showCreatePanel}
          setIsShown={setIsShown}
          setShowCreatePanel={setShowCreatePanel}
        />
      </div>
    </Scrollbars>
  );
};

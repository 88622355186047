import { Option } from "@jutro/types";
import { AddCircleIcon, BinIcon, Button } from "@jutro/ui";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  addButtonLabel?: string;
  options: Option<string>[];
};

export const DynamicSelect = ({
  label,
  options,
  disabled,
  name,
  placeholder = "Wybierz z listy",
  addButtonLabel = "Dodaj kolejny temat",
}: Props) => {
  const { control, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    if (fields.length > 0) {
      return;
    }

    append("");
  }, []);

  return (
    <div className="flex w-full flex-col gap-2">
      <span className="font-paragraph-2">{label}</span>

      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={`${name}.${index}`}
          control={control}
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <div className="flex w-full items-center justify-between gap-2">
              <ReactSelect
                {...field}
                placeholder={placeholder}
                options={options.filter(
                  (option) => !watch(name).includes(option.value),
                )}
                className="w-full"
                styles={adjustedToInputSelectStyles(error)}
                value={
                  options.find(
                    ({ value: optionValue }) => value === optionValue,
                  ) ?? null
                }
                isDisabled={disabled}
                onChange={(option) => onChange(option?.value)}
              />

              {index !== 0 && (
                <Button
                  full={false}
                  type="button"
                  size="condensed"
                  variant="negativeGhost"
                  onClick={() => {
                    remove(index);
                  }}
                  disabled={disabled}
                  icon={<BinIcon />}
                  label="Usuń"
                  tooltipPosition="top"
                />
              )}
            </div>
          )}
        />
      ))}

      <Button
        full={false}
        variant="ghost"
        type="button"
        size="condensed"
        disabled={disabled}
        onClick={() => {
          append("");
        }}
        text={addButtonLabel}
        icon={<AddCircleIcon />}
      />
    </div>
  );
};

import { Transition } from "@headlessui/react";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import moment from "moment";
import "moment/locale/pl";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  Calendar,
  NavigateAction,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import { tv } from "tailwind-variants";
import {
  EventWrapper,
  Loader,
  ResourceHeader,
  TimeSlotWrapper,
} from "components/new";
import { DialogModal } from "components/new/DialogModal";
import { Toolbar } from "components/new/calendar/Toolbar";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSAppointmentStatus,
  useDoctorScheduledVisitsQuery,
} from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { WORK_HOURS } from "lib/tools/workHours";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { selectedOrganizationIdAtom } from "views/Schedule/atoms";
import "views/Schedule/bigCalendarStyles.css";
import { useCalculateStatus } from "views/Schedule/hooks";
import { prepareEvents } from "views/Schedule/tools";
import { Resource } from "views/Schedule/types";
import {
  getEventColor,
  getResources,
  visitTypeToResourceId,
} from "views/Schedule/utils";
import "./bigCalendarStyles.css";

moment.locale("pl");

const localizer = momentLocalizer(moment);

export const ScheduleView = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const [day, setDay] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(
    null,
  );
  const [selectedOrganizationId, setSelectedOrganizationId] = useAtom(
    selectedOrganizationIdAtom,
  );

  const calculateStatus = useCalculateStatus();

  const { organizations, vojevodshipsAndClinicsOptions } =
    useVojevodshipOptions();

  const { data, loading, refetch } = useDoctorScheduledVisitsQuery({
    pollInterval: 30 * 1000,
    fetchPolicy: "network-only",
    variables: {
      forDay: day,
      organizationId: selectedOrganizationId,
    },
  });

  const events = useMemo(
    () =>
      prepareEvents({
        data,
        visitTypeToResourceId,
      }),
    [data, selectedOrganizationId],
  );

  useEffect(() => {
    if (loading || selectedOrganizationId.length <= 2) return;

    const columnBody = document.querySelector(
      ".rbc-day-slot.rbc-time-column",
    ) as HTMLElement | null;

    const columnHeader = document.querySelector(
      ".rbc-time-header-content",
    ) as HTMLElement | null;

    if (!columnBody || !columnHeader) return;

    columnBody.style.minWidth = "720px";
    columnHeader.style.minWidth = "720px";
  }, [selectedOrganizationId, loading]);

  if (loading) {
    return <Loader size="xl" color="gray" />;
  }

  if (!currentUser) return null;

  const { id: staffId } = currentUser;

  const { card } = getStyles();

  const resources = getResources(data, selectedOrganizationId, staffId);

  const selectedResource = events.find(
    (event) => event.id === selectedResourceId,
  );

  const handleNavigate = (action: NavigateAction) => {
    if (action === "PREV") {
      const prevDay = dayjs(day).subtract(1, "day").toDate();
      return setDay(prevDay);
    }

    if (action === "NEXT") {
      const nextDay = dayjs(day).add(1, "day").toDate();
      return setDay(nextDay);
    }

    setDay(new Date());
  };

  return (
    <div className="relative flex h-full flex-col gap-2 overflow-hidden rounded-xl bg-white p-3">
      <Toolbar
        onNavigate={handleNavigate}
        day={day}
        vojevodshipsAndClinicsOptions={vojevodshipsAndClinicsOptions}
        selectedOrganizationId={selectedOrganizationId}
        setDay={setDay}
        setSelectedOrganizationId={setSelectedOrganizationId}
      />

      <div className="relative h-screen overflow-auto">
        <Calendar
          events={events}
          localizer={localizer}
          defaultView={Views.DAY}
          views={["day"]}
          step={15}
          toolbar={false}
          formats={{
            eventTimeRangeFormat: (range) => {
              const from = dayjs(range.start);
              const to = dayjs(range.end);
              return `${from.format("HH:mm")} - ${to.format(
                "HH:mm",
              )} (${to.diff(from, "minutes")} min)`;
            },
            timeGutterFormat: "HH:mm",
          }}
          enableAutoScroll
          scrollToTime={new Date()}
          timeslots={1}
          date={day}
          dayLayoutAlgorithm="no-overlap"
          onNavigate={setDay}
          resources={resources}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          eventPropGetter={(e: Resource) => {
            return {
              className: `${card({ colors: getEventColor(e) })}`,
            };
          }}
          min={dayjs().hour(WORK_HOURS.START).minute(0).toDate()}
          max={dayjs().hour(WORK_HOURS.END).minute(0).toDate()}
          onSelectEvent={(resource) => {
            setIsModalOpen(true);
            setSelectedResourceId(resource.id);
          }}
          components={{
            event: ({ event, title }) => (
              <EventWrapper event={event} title={title} />
            ),
            timeSlotWrapper: ({ children }: PropsWithChildren) => (
              <TimeSlotWrapper> {children} </TimeSlotWrapper>
            ),
            resourceHeader: ({ label, resource }) => (
              <ResourceHeader label={label} resource={resource} />
            ),
          }}
        />
      </div>

      <Transition
        show={isModalOpen}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute">
          {selectedResource && (
            <DialogModal
              isOpen={isModalOpen}
              refetch={refetch}
              currentUserGenre={currentUser?.genre}
              status={calculateStatus(selectedResource)}
              setIsModalOpen={setIsModalOpen}
              doctor={selectedResource.doctor}
              patient={selectedResource.patient}
              isEcommerce={selectedResource.ecommerceOrderIds.length > 0}
              time={
                dayjs(selectedResource.plannedStart?.iso)
                  .format("YYYY-MM-DD HH:mm")
                  .toString() || ""
              }
              tags={selectedResource.tags}
              patientId={selectedResource.patientId!}
              pesel={selectedResource.pesel}
              visitId={selectedResource.visitId}
              snapshotBy={selectedResource.snapshotBy ?? ""}
              duration={selectedResource.duration ?? 15}
              notificationServiceStatus={
                selectedResource.sentNoShowNotifications
              }
              organization={organizations.find(
                (org) => org.id === selectedResource.organizationId,
              )}
              type={selectedResource.type}
              phoneNumber={selectedResource.phone}
              appointmentStatus={
                selectedResource.appointmentStatus as DoctorMSAppointmentStatus
              }
            />
          )}
        </div>
      </Transition>
    </div>
  );
};

const getStyles = tv({
  slots: {
    card: "flex gap-1 text-sm font-extra-bold rounded-md !border-none translate-y-[5px] !min-h-[25px] p-1",
  },
  variants: {
    colors: {
      gray: {
        card: "bg-jutro-new-warm-gray-200 text-jutro-new-warm-gray-800 hover:bg-jutro-new-warm-gray-300",
      },
      rose: {
        card: "bg-jutro-new-rose-200 text-jutro-new-rose-900 hover:bg-jutro-new-rose-300",
      },
      blue: {
        card: "bg-jutro-new-blue-300 text-jutro-new-blue-950 hover:bg-jutro-new-blue-400",
      },
      green: {
        card: "bg-jutro-new-green-300 text-jutro-new-green-800 hover:bg-jutro-new-green-300",
      },
      teal: {
        card: "bg-jutro-new-teal-200 text-jutro-new-teal-950 hover:bg-jutro-new-teal-300",
      },
    },
  },
  defaultVariants: {
    colors: "gray",
  },
});

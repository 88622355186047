import { useAtom } from "jotai";
import { Suspense } from "react";
import { Scrollbar } from "components/new";
import { selectedVisitTabAtom } from "lib/atoms";
import { lazyImport } from "lib/tools/lazyImport";
import { isMedicalServiceConfirmModalOpenAtom } from "views/Visit/RightPanel/Visit/atoms";
import { MedicalServiceConfirmModal } from "views/Visit/RightPanel/Visit/components/MedicalServiceConfirmModal";
import { useVisitData } from "views/Visit/hooks";
import { TabsSection } from "./TabsSection";
import { VisitTab } from "./Visit";

const PrescriptionTab = lazyImport(
  () => import("views/Visit/RightPanel/NewPrescription"),
  "PrescriptionTab",
);

const LeaveBlock = lazyImport(
  () => import("views/Visit/RightPanel/Leave/LeaveBlock"),
  "LeaveBlock",
);

const TestimonialBlock = lazyImport(
  () => import("views/Visit/RightPanel/Testimonial/TestimonialBlock"),
  "TestimonialBlock",
);

const ReferralBlock = lazyImport(
  () => import("views/Visit/RightPanel/Referral"),
  "ReferralBlock",
);

const MedicalServices = lazyImport(
  () => import("views/Visit/RightPanel/MedicalServices"),
  "MedicalServices",
);

export const RightSection = () => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });
  const [selectedVisitTab] = useAtom(selectedVisitTabAtom);
  const [
    isMedicalServiceConfirmModalOpen,
    setIsMedicalServiceConfirmModalOpen,
  ] = useAtom(isMedicalServiceConfirmModalOpenAtom);

  if (!currentVisit) return null;

  return (
    <>
      <div className="flex h-full w-full flex-col gap-2">
        <TabsSection currentVisit={currentVisit} />

        <div
          className={`h-full ${
            selectedVisitTab === "visit" ? "block" : "hidden"
          }`}
        >
          <Scrollbar>
            <VisitTab visit={currentVisit} />
          </Scrollbar>
        </div>

        <div
          className={`h-full ${
            selectedVisitTab === "prescription" ? "block" : "hidden"
          }`}
        >
          <Suspense fallback={<div>Czekaj...</div>}>
            <PrescriptionTab />
          </Suspense>
        </div>

        <div
          className={`h-full ${
            selectedVisitTab === "leave" ? "block" : "hidden"
          }`}
        >
          <Scrollbar>
            <Suspense fallback={<div>Czekaj...</div>}>
              <LeaveBlock />
            </Suspense>
          </Scrollbar>
        </div>

        <div
          className={`h-full ${
            selectedVisitTab === "referral" ? "block" : "hidden"
          }`}
        >
          <Suspense fallback={<div>Czekaj...</div>}>
            <ReferralBlock />
          </Suspense>
        </div>

        <div
          className={`h-full ${
            selectedVisitTab === "testimonial" ? "block" : "hidden"
          }`}
        >
          <Scrollbar>
            <Suspense fallback={<div>Czekaj...</div>}>
              <TestimonialBlock />
            </Suspense>
          </Scrollbar>
        </div>

        <div
          className={`h-full ${
            selectedVisitTab === "medicalServices" ? "block" : "hidden"
          }`}
        >
          <Scrollbar>
            <Suspense fallback={<div>Czekaj...</div>}>
              <MedicalServices />
            </Suspense>
          </Scrollbar>
        </div>
      </div>

      <MedicalServiceConfirmModal
        isOpen={isMedicalServiceConfirmModalOpen}
        setOpen={setIsMedicalServiceConfirmModalOpen}
      />
    </>
  );
};

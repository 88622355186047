import dayjs from "dayjs";
import { CurrentPatient } from "components/PatientDocs/types";
import { useAnswers } from "./hooks";
import { answerPriorityMap } from "./tools";

type Props = {
  patient: CurrentPatient;
};

export const PatientDocsSurvey = ({ patient }: Props) => {
  const answers = useAnswers(patient);

  return (
    <div className="flex h-full w-full flex-col gap-3">
      {Object.keys(answers).map((survey) => (
        <div className="rounded-lg bg-white p-4" key={survey}>
          <div className="mb-4 flex justify-between">
            <div className="font-paragraph-2 text-jutro-new-warm-gray-800">
              {survey}
            </div>

            {survey === "Ankieta wstępna" && (
              <div className="font-paragraph-2 space-x-1 text-jutro-new-warm-gray-800">
                <span className="text-jutro-new-blue-500">
                  Data ostatniej modyfikacji:
                </span>

                <span>
                  {dayjs(patient.oneTimeSurveyUpdatedAt?.local).format(
                    "DD MMMM YYYY HH:mm",
                  )}
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-5">
            {answers[survey]
              .filter((e) => e.value)
              .sort(
                (a, b) =>
                  (answerPriorityMap[b.title] ?? 0) -
                  (answerPriorityMap[a.title] ?? 0),
              )
              .map(({ title, value }) => (
                <div
                  key={value}
                  className="font-paragraph-2 flex flex-col gap-2 text-black"
                >
                  <span className="rounded-lg bg-jutro-new-warm-gray-100 px-1 py-0.5 font-medium">
                    {title}
                  </span>

                  <div className="flex flex-col gap-2 p-1">{value}</div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

import { Dispatch, SetStateAction } from "react";
import { AssociatedAccount } from "components/PatientDocs/Details/AssociatedAccount/AssociatedAccount";
import { PatientDetails } from "components/PatientDocs/Details/PatientDetails/PatientDetails";
import { CurrentPatient } from "components/PatientDocs/types";
import { DoctorMSPatient } from "lib/graphql/megaSchema";

type Props = {
  patient: CurrentPatient;
  patientFormData: DoctorMSPatient | any;
  dialogShown: boolean;
  updatePatientLoading: boolean;
  setDialogShown: Dispatch<SetStateAction<boolean>>;
  savePatientDetails: (arg: any) => Promise<any>;
};

export const PatientDocsDetails = ({
  patient,
  patientFormData,
  updatePatientLoading,
  savePatientDetails,
}: Props) => {
  return (
    <div className="flex h-full flex-col gap-4">
      <PatientDetails
        patient={patient}
        patientFormData={patientFormData}
        savePatientDetails={savePatientDetails}
        updatePatientLoading={updatePatientLoading}
      />
      {patient.guardians.length > 0 && (
        <AssociatedAccount
          header="Dane opiekuna"
          associatedAccount={patient.guardians[0]}
          guardian
        />
      )}
      {patient.wards.length > 0 &&
        patient.wards.map((ward) => (
          <AssociatedAccount
            key={ward.id}
            header="Przypisane konto"
            associatedAccount={ward}
            guardian={false}
          />
        ))}
    </div>
  );
};

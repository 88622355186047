import { NfzHistoryEventHandler } from "./Events";
import { useChangeHistory } from "./useChangeHistory";

export const NfzEventList = () => {
  return (
    <div className="flex min-h-96 flex-col overflow-auto rounded-lg bg-white p-4">
      <div className="font-label">Historia zmian</div>
      <NfzEventListContent />
    </div>
  );
};

const NfzEventListContent = () => {
  const { data, loading, error } = useChangeHistory();

  if (error) {
    return <NfzEventListPlaceholder text="Wystąpił błąd" />;
  }

  if (loading) {
    return <NfzEventListPlaceholder text="Ładuję historię pacjenta..." />;
  }

  if (data?.doctorNfzHistory.length === 0) {
    return <NfzEventListPlaceholder text="Brak historii zmian" />;
  }

  return (
    <div className="flex grow-0 basis-full flex-col gap-4 overflow-auto pt-5">
      <ol className="relative ml-2 border-l border-jutro-new-warm-gray-300">
        {/* Ucinanie borderu w skrajnych elementach */}
        <div className="absolute -left-0.5 top-0 h-3.5 w-1 bg-white" />
        <div className="absolute -left-0.5 bottom-0 h-[33px] w-1 bg-white" />

        {data?.doctorNfzHistory.map((historyItem) => (
          <NfzHistoryEventHandler key={historyItem.id} event={historyItem} />
        ))}
      </ol>
    </div>
  );
};

type NfzEventListPlaceholderProps = {
  text: string;
};

const NfzEventListPlaceholder = ({ text }: NfzEventListPlaceholderProps) => {
  return (
    <span className="font-label text-jutro-new-warm-gray-500">{text}</span>
  );
};

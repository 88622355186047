import { AddCircleIcon, Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";
import { focusOnSectionAtom } from "components/FocusableWrapper/atom";
import { IpomList } from "components/IpomForm/IpomList";
import { IpomListItem } from "components/IpomForm/IpomList/IpomListItem";
import { IpomFormSchema } from "components/IpomForm/schema";
import { selectedVisitTabAtom } from "lib/atoms";
import { DiagnosticTestElement } from "./DiagnosticTestElement";

type Props = {
  disabled?: boolean;
};

export const DiagnosticTestsSection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();
  const [, setFocusOnSection] = useAtom(focusOnSectionAtom);
  const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <span className="font-label text-jutro-new-warm-gray-800">
          Zlecone badania
        </span>

        <Button
          variant="secondary"
          size="condensed"
          icon={<AddCircleIcon />}
          text="Dodaj badania"
          type="button"
          full={false}
          disabled={disabled}
          onClick={() => {
            setFocusOnSection("referral-select");
            setSelectedVisitTab("referral");
          }}
        />
      </div>

      <Controller
        control={control}
        name="scheduledDiagnosticTests"
        render={({
          field: { value, onChange, ref },
          fieldState: { error },
        }) => (
          <IpomList
            ref={ref}
            emptyListText="Brak zleconych badań do wyświetlenia"
            error={error}
          >
            {value.map((diagnosticTest, index) => (
              <IpomListItem
                key={`${diagnosticTest.name}${index}`}
                active={diagnosticTest.active}
                disabled={disabled}
                leftElement={
                  <DiagnosticTestElement
                    diagnosticTest={diagnosticTest}
                    disabled={disabled}
                    onTimeChange={(timeData) => {
                      onChange(
                        value.map((currentItem) =>
                          currentItem.icd9 === diagnosticTest.icd9 &&
                          currentItem.name === diagnosticTest.name
                            ? { ...currentItem, ...timeData }
                            : currentItem,
                        ),
                      );
                    }}
                  />
                }
                toggleActive={() =>
                  onChange(
                    value.map((currentItem) =>
                      currentItem.icd9 === diagnosticTest.icd9 &&
                      currentItem.name === diagnosticTest.name
                        ? { ...currentItem, active: !currentItem.active }
                        : currentItem,
                    ),
                  )
                }
              />
            ))}
          </IpomList>
        )}
      />
    </div>
  );
};

import { CalendarAddIcon, VisitIcon } from "@jutro/ui";
import dayjs from "dayjs";
import { ChatSystem } from "components/components/ChatSystem";
import { MessageElement } from "components/new/Chat/Message/Message";

type VisitMessageProps = {
  visit: Exclude<MessageElement["visit"], null | undefined>;
};

export const VisitMessage = ({ visit }: VisitMessageProps) => {
  const plannedStart = visit.plannedStart ? visit.plannedStart.iso : false;
  return (
    <ChatSystem
      icon={
        plannedStart ? <CalendarAddIcon size="lg" /> : <VisitIcon size="lg" />
      }
      title={plannedStart ? "Termin wizyty" : "Zaproszenie na wizytę"}
      subtitle={
        visit.doctor && `${visit.doctor.firstName} ${visit.doctor.lastName}`
      }
    >
      {plannedStart ? (
        <>
          <div className="font-geologica box-border w-full text-center text-jutro-new-warm-gray-800">
            {visit.status === "ARCHIVE"
              ? "Wizyta zamknięta"
              : `${dayjs(plannedStart).format("ddd")}. ${dayjs(
                  plannedStart,
                ).format("D MMMM YYYY")} ${dayjs(plannedStart).format(
                  "HH:mm",
                )}`}
          </div>
        </>
      ) : null}

      {visit.duration ? (
        <div className="flex flex-row justify-end">
          <div className="font-geologica box-border w-full text-center text-jutro-new-blue-950">
            {visit.duration} minut
          </div>
        </div>
      ) : null}
    </ChatSystem>
  );
};

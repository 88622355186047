import {
  DoctorMSUserGenre,
  DoctorPatientProfileDiagQuery,
} from "lib/graphql/megaSchema";
import { ItemOf } from "lib/types";
import { groszToFixedZloty } from "./groszToFixedZloty";

type DiagOptionLabelProps = {
  name?: string;
  isNfz: boolean;
  price: number;
};

export const DiagOptionLabel = ({
  isNfz,
  name,
  price,
}: DiagOptionLabelProps) => {
  return (
    <div className="font-caption flex items-center gap-2">
      {name ? <span className="font-caption flex-1 pr-2">{name}</span> : null}

      <span
        className={`grid h-5 place-items-center rounded-lg px-2 text-white ${
          isNfz ? "bg-jutro-new-green-400" : "bg-jutro-new-blue-800"
        }`}
      >
        {isNfz ? "NFZ" : "PŁATNE"}
      </span>
      <span className="flex w-16 justify-end">
        {`${groszToFixedZloty(price)} zł`}
      </span>
    </div>
  );
};

const returnDiagOption = (
  item: ItemOf<DoctorPatientProfileDiagQuery["doctorDiag"]>,
  isNfz: boolean,
) => {
  const {
    id,
    name,
    price,
    isAvailableAsNFZ,
    isAvailableAsNFZForNurse,
    orgIds,
    type,
    commission,
  } = item;

  return {
    value: id + isNfz,
    label: <DiagOptionLabel isNfz={isNfz} name={name} price={price} />,
    id,
    serviceId: id,
    name,
    price,
    isAvailableAsNFZ,
    isAvailableAsNFZForNurse,
    cito: false,
    orgIds,
    isNfz,
    type,
    commission,
  };
};

export const returnFormattedDiagData = (
  data: DoctorPatientProfileDiagQuery,
  userGenre: DoctorMSUserGenre,
) => {
  const formattedDiagItems = data.doctorDiag.map((item) => {
    const { isAvailableAsNFZ, isAvailableAsNFZForNurse, type } = item;

    if (userGenre === "NURSE" && isAvailableAsNFZForNurse && isAvailableAsNFZ) {
      return [returnDiagOption(item, true), returnDiagOption(item, false)];
    }

    if (isAvailableAsNFZ && type === "IMAGE") {
      return [returnDiagOption(item, true), returnDiagOption(item, false)];
    }

    return returnDiagOption(item, false);
  });

  return formattedDiagItems.flat();
};

import { Button } from "@jutro/ui";
import { envVars } from "envvars";
import { fileConfig } from "lib/tools/httpConfigs";

type Props = {
  addFileText?: string;
  file: string;
  isModal: boolean;
  handleAbort?: () => void;
  handleClose?: () => void;
  handleRotateImage: (file: string) => void;
};

export const ImagePreviewActionButtons = ({
  addFileText,
  file,
  isModal,
  handleAbort,
  handleClose,
  handleRotateImage,
}: Props) => {
  if (!isModal) {
    return (
      <div className="flex w-full items-start justify-start gap-2">
        <Button
          full={false}
          size="condensed"
          onClick={() => handleRotateImage(file)}
          text="Obrót 90°"
          variant="secondary"
        />

        <a href={`${fileConfig[envVars.REACT_APP_CONFIG]}${file}`}>
          <Button full={false} size="condensed" text="Pobierz plik" />
        </a>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full justify-end gap-4 rounded-b-lg bg-white p-2">
        <Button
          full={false}
          size="condensed"
          variant="ghost"
          onClick={handleAbort}
          text="Anuluj"
        />

        <Button
          full={false}
          size="condensed"
          onClick={handleClose}
          text={addFileText ?? ""}
        />
      </div>
    </>
  );
};

import { useState } from "react";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { Block } from "components/Block";
import { NewOrderedTestForm } from "components/PatientDocs/Ordered/NewOrderedTestForm";
import { OrderedCategoryType } from "components/PatientDocs/Ordered/types";
import {
  customSelectStyles,
  orderCategoryOptions,
} from "components/PatientDocs/Ordered/utils";
import { PacketsResponseData } from "lib/hooks/useAsyncOptions";

type Props = {
  patientId: string;
  onPacketClick?: (input: PacketsResponseData) => void;
};

export const NewOrderedTestSection = ({ patientId, onPacketClick }: Props) => {
  const [orderCategoryOption, setOrderCategoryOption] = useState<
    OrderedCategoryType | undefined
  >(undefined);

  const { visitId } = useParams<{ visitId: string }>();

  return (
    <Block>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <span className="text-xs text-jutro-new-warm-gray-800">
            Nowe zlecenie
          </span>
          <ReactSelect
            placeholder="Kliknij i wybierz z listy"
            options={orderCategoryOptions}
            isSearchable={false}
            styles={customSelectStyles<OrderedCategoryType | undefined>()}
            onChange={(e) => setOrderCategoryOption(e?.value)}
          />
        </div>

        <NewOrderedTestForm
          type={orderCategoryOption}
          patientId={patientId}
          visitId={visitId!}
          onPacketClick={onPacketClick}
        />
      </div>
    </Block>
  );
};

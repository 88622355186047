import logo from "assets/logoLight.svg";

type MessageHeaderProps = {
  authorName?: string;
  authorImage?: string;
  mine?: boolean;
};

export const MessageHeader = ({
  authorName = "Jutro Medical",
  authorImage = logo,
  mine = true,
}: MessageHeaderProps) => {
  return (
    <div
      className={`absolute ${
        mine ? "right-0" : "left-0 flex-row-reverse"
      } -top-5 flex items-center justify-center gap-1`}
    >
      <span className="text-xs text-jutro-new-warm-gray-800">{authorName}</span>
      <img className="h-4 w-4 rounded-full" src={authorImage} />
    </div>
  );
};

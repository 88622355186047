import { useAtom } from "jotai";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import { authStatusAtom, currentUserAtom } from "lib/atoms/auth";
import { useDoctorCheckAuthStatusLazyQuery } from "lib/graphql/megaSchema";

const useCheckAuthStatus = () => {
  const [, setAuthStatus] = useAtom(authStatusAtom);

  const [checkAuthStatus] = useDoctorCheckAuthStatusLazyQuery();

  return async () => {
    const { data } = await checkAuthStatus();

    setAuthStatus(
      data?.doctorValidateToken !== "UNAUTHORIZED"
        ? "AUTHORIZED"
        : data.doctorValidateToken,
    );
  };
};

export const useInitCheckAuthStatus = () => {
  const checkAuthStatus = useCheckAuthStatus();

  useEffect(() => {
    checkAuthStatus();
  }, []);
};

export const useLogout = () => {
  const [, setCurrentUser] = useAtom(currentUserAtom);
  const [, setLoggedType] = useAtom(authStatusAtom);

  return useCallback(() => {
    Cookies.remove("JWT");
    setCurrentUser(null);
    setLoggedType("UNAUTHORIZED");
    window.history.replaceState(null, "", "/");
  }, [setLoggedType, setCurrentUser]);
};

import { AddCircleIcon, Button } from "@jutro/ui";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  ExaminationFieldValues,
  UtilType,
} from "components/ExaminationPanel/types";
import { DoctorMSProcedureType } from "lib/graphql/megaSchema";
import { UtilElement } from "./UtilElement";

type Props = {
  procedureType?: DoctorMSProcedureType;
  type: UtilType;
  disabled?: boolean;
};

type UtilsWithAddButton = Exclude<UtilType, "vaccine" | "measurement" | "file">;

export const textLabelMap: Record<UtilsWithAddButton, string> = {
  disinfectant: "Dodaj środek dezynfekcyjny",
  tool: "Dodaj narzędzie",
  drug: "Dodaj lek",
  bandage: "Dodaj materiał",
};

const utilsWithButton = Object.keys(textLabelMap);

export const DynamicInputsGroup = ({
  type,
  disabled,
  procedureType,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ExaminationFieldValues>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: type,
  });

  const isButtonVisible = useMemo(() => utilsWithButton.includes(type), [type]);

  const baseFields = {
    name: "",
    productSeries: "",
    expirationDate: "",
    used: "",
  };

  const appendBaseFields = () => {
    if (type === "measurement") {
      return;
    }

    if (
      JSON.stringify(Object.keys(fields[0])) ===
      JSON.stringify(Object.keys({ ...baseFields, id: "" }))
    ) {
      return;
    }

    update(0, { ...baseFields, ...fields[0] });
  };

  useEffect(() => {
    if (fields.length > 0) {
      appendBaseFields();
      return;
    }

    if (type === "measurement") {
      append({
        systolicPressure: "",
        diastolicPressure: "",
        pulse: "",
        resultType: "",
      });
      return;
    }

    append(baseFields);
  }, []);

  const filedErrors = errors[type] as any;

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <div className="flex flex-col gap-4">
        <div className="grid gap-4">
          {fields.map((d, index) => (
            <UtilElement
              isDrugpass={procedureType === "DRUGPASS"}
              disabled={Boolean(disabled)}
              key={index}
              index={index}
              type={type}
              util={d}
              remove={remove}
              errors={filedErrors?.[index]}
            />
          ))}
          {isButtonVisible && (
            <div>
              <Button
                full={false}
                variant="ghost"
                size="condensed"
                icon={<AddCircleIcon />}
                disabled={disabled}
                text={textLabelMap[type as UtilsWithAddButton]}
                onClick={() => {
                  append({
                    name: "",
                    productSeries: "",
                    expirationDate: "",
                    used: "",
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import {
  DiamondAlertIcon,
  DiamondCheckIcon,
  DiamondCrossIcon,
  DiamondQuestionIcon,
} from "@jutro/ui";
import { InsuranceStatus } from "lib/hooks/usePatientInsuranceStatus/types";

export const getInsuranceColorAndIcon = (insuranceStatus: InsuranceStatus) => {
  const greenStatuses: InsuranceStatus[] = [
    "green",
    "greenOrStatement",
    "statement",
    "contributionCertificate",
  ];

  const roseStatuses: InsuranceStatus[] = ["red", "error"];

  if (greenStatuses.includes(insuranceStatus)) {
    return {
      color: "text-jutro-new-green-700",
      icon: <DiamondCheckIcon size="sm" />,
      bgColor: "bg-jutro-new-green-50",
    };
  }

  if (roseStatuses.includes(insuranceStatus)) {
    const Icon =
      insuranceStatus === "red" ? DiamondAlertIcon : DiamondCrossIcon;

    return {
      color: "text-jutro-new-rose-700",
      icon: <Icon size="sm" />,
      bgColor: "bg-jutro-new-rose-100",
    };
  }

  return {
    color: "text-jutro-new-warm-gray-600",
    icon: <DiamondQuestionIcon size="sm" />,
    bgColor: "bg-jutro-new-warm-gray-50",
  };
};

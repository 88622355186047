import { useAtom } from "jotai";
import { orderedTestsAtom } from "components/ExaminationPanel/atoms";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { isProcedure } from "components/ExaminationPanel/utils/isProcedure";
import { OrderedTestListItem } from "components/PatientDocs/Ordered/types";
import { isAdviceProcedure } from "lib/tools";

export const useHandleTestsCheckboxes = () => {
  const [orderedTests, setOrderedTests] = useAtom(orderedTestsAtom);

  const notPaidTestsList = orderedTests.filter((test) => {
    if (isLaboratoryExamination(test) && !isProcedure(test)) {
      return test.payment === "UNPAID";
    }
  });

  const paidTestsList = orderedTests.filter((test) => {
    if (isProcedure(test)) {
      return !isAdviceProcedure(test.type);
    }

    return test.payment !== "UNPAID";
  });

  const selectedTestsList = orderedTests.filter((test) => test.checked);

  const selectedLabExamIdsList = selectedTestsList
    .filter(isLaboratoryExamination)
    .map((test) => test.id);

  const selectedProceduresIdsList = selectedTestsList
    .filter(isProcedure)
    .map((test) => test.id);

  const selectedNotPaidTestsList = notPaidTestsList.filter(
    (test) => test.checked,
  );

  const selectedPaidTestsList = paidTestsList.filter((test) => test.checked);

  const toggleCheckState = (
    targets: OrderedTestListItem[],
    checked: boolean,
  ) => {
    const toggledItems = orderedTests.map((item) =>
      targets.includes(item) ? { ...item, checked } : item,
    );

    setOrderedTests(toggledItems);
  };

  const toggleAllItemsSelectionHandler = (targets: OrderedTestListItem[]) => {
    const allChecked = targets.every((item) => item.checked);
    toggleCheckState(targets, !allChecked);
  };

  const handleChange = (itemId: string) => {
    setOrderedTests((prevTests) =>
      prevTests.map((test) =>
        test.id === itemId ? { ...test, checked: !test.checked } : test,
      ),
    );
  };

  return {
    handleChange,
    toggleAllItemsSelectionHandler,
    selectedPaidTestsList,
    selectedTestsList,
    selectedLabExamIdsList,
    selectedProceduresIdsList,
    selectedNotPaidTestsList,
  };
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input, Select, TextArea } from "@jutro/ui";
import { useAtom } from "jotai";
import { omit } from "ramda";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { currentUserAtom } from "lib/atoms/auth";
import {
  EdmSnippet,
  useEdmGetAllSnippetTagsSuspenseQuery,
} from "lib/graphql/megaSchema";
import { isStaffManagerId } from "lib/tools/auth";
import { toaster } from "lib/tools/toaster";
import {
  getSnippetCategoryOptionsByType,
  snippetFormSchema,
  snippetTypeOptions,
  snippetVisibilityOptions,
} from "../tools";
import {
  SnippetCategoryFilter,
  SnippetFormInput,
  SnippetFormSchema,
  SnippetTypeFilter,
} from "../types";

type CreateProps = {
  mode: "create";
  onSubmit: (input: SnippetFormInput) => Promise<void>;
  type?: SnippetTypeFilter;
  category?: SnippetCategoryFilter;
};

type UpdateProps = {
  mode: "update";
  onSubmit: (id: string, input: SnippetFormInput) => Promise<void>;
  snippetData: EdmSnippet;
};

type Props = {
  handleCloseForm: () => void;
} & (CreateProps | UpdateProps);

export const SnippetForm = ({ handleCloseForm, ...props }: Props) => {
  const [currentUser] = useAtom(currentUserAtom);
  const isCreateMode = props.mode === "create";

  const { watch, control, handleSubmit } = useForm<SnippetFormSchema>({
    resolver: zodResolver(snippetFormSchema),
    defaultValues: isCreateMode
      ? {
          name: "",
          visibility: "PRIVATE",
          type: props.type === "ALL" ? undefined : props.type,
          category:
            props.category === "ALL"
              ? props.type === "CHAT"
                ? "interview"
                : undefined
              : (props.category ?? undefined),
        }
      : {
          ...omit(["id", "tags", "category"], props.snippetData),
          category: props.snippetData.category ?? undefined,
          tags: props.snippetData.tags.map((tag) => ({
            label: tag.name,
            value: tag.id,
          })),
        },
  });

  const { data: snippetTagsData } = useEdmGetAllSnippetTagsSuspenseQuery({
    fetchPolicy: "cache-and-network",
  });

  const type = watch("type");

  const onSubmit: SubmitHandler<SnippetFormSchema> = (snippet) => {
    const tags = snippet.tags.map((tag) => tag.label);

    if (isCreateMode) {
      toaster.promise(props.onSubmit({ ...snippet, tags }), {
        loading: "Dodaję szablon...",
        success: "Szablon dodany!",
        error: "Coś poszło nie tak!",
      });
    } else {
      toaster.promise(
        props.onSubmit(props.snippetData.id, { ...snippet, tags }),
        {
          loading: "Aktualizuję szablon...",
          success: "Szablon zaktualizowany!",
          error: "Coś poszło nie tak!",
        },
      );
    }

    handleCloseForm();
  };

  return (
    <form
      className="my-3 grid grid-cols-6 gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-span-6">
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              label="Nazwa"
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>

      {isStaffManagerId(currentUser?.id) && (
        <div className="col-span-6">
          <Controller
            name="visibility"
            control={control}
            defaultValue="PRIVATE"
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Select
                label="Widoczność"
                value={
                  snippetVisibilityOptions.find((s) => s.value === value) ??
                  null
                }
                onChange={(option) => {
                  onChange(option?.value ?? "PUBLIC");
                }}
                options={snippetVisibilityOptions}
                error={error?.message}
                {...rest}
              />
            )}
          />
        </div>
      )}

      <div className="col-span-3">
        <Controller
          name="type"
          control={control}
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error },
          }) => (
            <Select
              label="Typ"
              value={snippetTypeOptions.find((s) => s.value === value) ?? null}
              onChange={(option) => onChange(option?.value ?? "CHAT")}
              options={snippetTypeOptions}
              error={error?.message}
              {...rest}
            />
          )}
        />
      </div>

      <div className="col-span-3">
        <Controller
          name="category"
          control={control}
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error },
          }) => (
            <Select
              value={
                getSnippetCategoryOptionsByType(type).find(
                  (t) => t.value === value,
                ) ?? null
              }
              disabled={!type}
              label="Kategoria"
              onChange={(option) => onChange(option?.value ?? "")}
              options={getSnippetCategoryOptionsByType(type)}
              error={error?.message}
              {...rest}
            />
          )}
        />
      </div>

      <div className="col-span-6">
        <Controller
          name="tags"
          control={control}
          render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
            <div className="flex flex-col gap-1">
              <div className="font-label">Tagi</div>

              <CreatableSelect
                isMulti
                formatCreateLabel={() => "+ Dodaj nowy tag"}
                options={
                  snippetTagsData?.edmGetAllSnippetTags.map((tag) => ({
                    label: tag.name,
                    value: tag.id,
                  })) || []
                }
                placeholder=""
                onChange={(option) => onChange(option)}
                className={`${error && "rounded-lg border border-red-700 border-x-jutro-new-warm-gray-500"}`}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    height: "44px",
                    borderColor: "rgb(140 138 138)",
                    borderRadius: "8px",
                  }),
                }}
                {...rest}
              />
            </div>
          )}
        />
      </div>

      <div className="col-span-6">
        <Controller
          name="content"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextArea
              {...field}
              label="Zawartość"
              error={error?.message}
              rows={5}
            />
          )}
        />
      </div>

      <div className="col-span-6 flex gap-4">
        <Button
          variant="secondary"
          type="button"
          onClick={handleCloseForm}
          text="Anuluj"
        />

        <Button
          variant="primary"
          type="submit"
          text={isCreateMode ? "Dodaj szablon" : "Zmień szablon"}
        />
      </div>
    </form>
  );
};

import { Avatar } from "@jutro/ui";
import { envVars } from "envvars";
import { ReactNode } from "react";
import { DoctorsSelectQuery } from "lib/graphql/megaSchema";
import { avatarConfig } from "lib/tools/httpConfigs";
import { ResourceType } from "views/Schedule/types";

type Props = {
  label: ReactNode;
  resource: {
    resourceId: ResourceType | string | undefined;
    resourceTitle: string;
    staff: DoctorsSelectQuery["doctorDoctors"][number] | null | undefined;
  };
};

export const ResourceHeader = ({ label, resource }: Props) => {
  return (
    <div className="font-label flex items-center justify-center gap-2 p-4">
      {resource.staff && (
        <Avatar
          type="photo"
          imgSrc={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${resource.staff.id}`}
          size="sm"
        />
      )}
      {label}
    </div>
  );
};

import { Checkbox } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import { IpomFormSchema } from "components/IpomForm/schema";

type Props = {
  disabled?: boolean;
};

export const SpecialistVisitsSection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();

  return (
    <>
      <span className="font-label text-jutro-new-warm-gray-800">
        Konsultacje pacjenta ze specjalistą
      </span>

      <div className="flex flex-col rounded-lg bg-jutro-new-warm-gray-50 p-4">
        <Controller
          control={control}
          name="specialistVisits"
          render={({ field: { onChange, value, ...restField } }) => (
            <Checkbox
              {...restField}
              disabled={disabled}
              checked={value.some((v) => v === "ALERGOLOG")}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...value, "ALERGOLOG"]);
                  return;
                }

                onChange(value.filter((v) => v !== "ALERGOLOG"));
              }}
              label="Alergolog"
            />
          )}
        />

        <Controller
          control={control}
          name="specialistVisits"
          render={({ field: { onChange, value, ...restField } }) => (
            <Checkbox
              {...restField}
              checked={value.some((v) => v === "PULMONOLOG")}
              disabled={disabled}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...value, "PULMONOLOG"]);
                  return;
                }

                onChange(value.filter((v) => v !== "PULMONOLOG"));
              }}
              label="Pulmunolog"
            />
          )}
        />
      </div>
    </>
  );
};

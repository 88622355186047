import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDoctorPatientDeclarationHistoryQuery } from "lib/graphql/megaSchema";

export const useDeclarationHistory = () => {
  const { patientId } = useParams<{ patientId: string }>();

  const { data, loading, error } = useDoctorPatientDeclarationHistoryQuery({
    variables: { patientId: patientId ?? "" },
    skip: !patientId,
    fetchPolicy: "no-cache",
  });

  const declarations = useMemo(() => {
    if (!data || data.doctorPatientDeclarationHistory.length === 0) {
      return [];
    }

    return data.doctorPatientDeclarationHistory;
  }, [data]);

  return {
    declarations,
    loading,
    error,
  };
};

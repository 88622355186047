import { EyeOffIcon, EyeOnIcon, OcrIcon } from "@jutro/ui";
import { envVars } from "envvars";
import { useRef, useState } from "react";
import { ImageCropBlock as ImageCrop } from "components/components/Doctor/ImageCrop";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { useDoctorGetContentFromImageMutation } from "lib/graphql/megaSchema";
import { fileConfig } from "lib/tools/httpConfigs";
import { roundCoordinates, scaleCoordinates } from "lib/tools/ocr";
import { toaster } from "lib/tools/toaster";
import { CropImage } from "lib/types";
import {
  AuthorImage,
  AuthorSection,
  MessageContainer,
  MessageFooter,
} from "./components";

type MultipleFilesMessageFileProps = {
  mine: any;
  file: any;
  messageTime: any;
};

const MultipleFilesMessageFile = ({
  mine,
  file,
  messageTime,
}: MultipleFilesMessageFileProps) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [crop, setCrop] = useState<CropImage>({
    unit: "px",
    width: 80,
    height: 50,
    x: 30,
    y: 30,
  });
  const imageRef = useRef<HTMLImageElement>(null);

  const [getContentFromImageMutation] = useDoctorGetContentFromImageMutation();

  const handleOCR = async () => {
    const scaledCoordinates = scaleCoordinates(crop, imageRef);
    const roundedCoordinates = roundCoordinates(scaledCoordinates);
    const imageId =
      new URL(
        `${fileConfig[envVars.REACT_APP_CONFIG]}` + file,
      ).searchParams.get("id") ?? "";
    const response = await getContentFromImageMutation({
      variables: { input: { imageId, ...roundedCoordinates } },
    });

    navigator.clipboard.writeText(
      response?.data?.doctorGetContentFromImage || "",
    );
    return response?.data?.doctorGetContentFromImage;
  };

  return (
    <>
      <div
        className={`mt-[5px] flex flex-col overflow-hidden rounded-lg p-3 ${
          mine
            ? "gap-2 bg-jutro-new-blue-500"
            : "gap-0 bg-jutro-new-warm-gray-50"
        }`}
      >
        <div className="relative h-auto w-full overflow-hidden">
          {!show && (
            <div className="absolute inset-0 z-[3] flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-tl-[17px] bg-jutro-new-warm-gray-950/50 p-[45px] text-white">
              <EyeOffIcon size="lg" />

              <div className="mb-2 text-center text-xs font-bold text-white">
                Pacjent przesłał zdjęcie
              </div>
              <div className="mb-2 text-center text-xs font-bold text-white">
                Zadbaj o to by nikt nieupoważniony nie mógł zobaczyć przesłanego
                zdjęcia.
              </div>
            </div>
          )}

          <img
            className={`block h-full w-full rounded-lg rounded-bl-none rounded-br-none object-contain ${
              !show && "blur-[1.2rem]"
            }`}
            src={`${fileConfig[envVars.REACT_APP_CONFIG]}` + file}
            ref={imageRef}
            onClick={() => {
              const newTab = window.open();
              setTimeout(() => {
                const img = document.createElement("img");
                img.src = `${fileConfig[envVars.REACT_APP_CONFIG]}` + file;
                newTab?.document.body.appendChild(img);
              }, 500);
              return false;
            }}
          />
        </div>

        <MessageFooter mine={mine}>
          <div>
            <div
              className={`text-xs text-white ${
                mine ? "text-left" : "text-right"
              }`}
            >
              {messageTime}
            </div>
          </div>

          <div className="flex gap-4 text-white">
            <div
              className="h-4 w-4 cursor-pointer hover:opacity-80"
              onClick={() => setOpenDialog(true)}
            >
              <OcrIcon size="sm" />
            </div>
            <div
              className="z-10 h-4 w-4 cursor-pointer hover:opacity-80"
              onClick={() => setShow(!show)}
            >
              {show ? <EyeOffIcon size="sm" /> : <EyeOnIcon size="sm" />}
            </div>
          </div>
        </MessageFooter>
      </div>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="Kopiuj tekst ze zdjęcia"
        onConfirm={async () => {
          setLoading(true);
          const ocrValue = await handleOCR();
          setLoading(false);
          return ocrValue
            ? toaster.notify("Zapisano do schowka")
            : toaster.warning("Nie udało się rozpoznać tekstu!");
        }}
        loading={loading}
        confirmText="Kopiuj"
        submitVariant="primary"
      >
        <ImageCrop
          imageUrl={`${fileConfig[envVars.REACT_APP_CONFIG]}` + file}
          crop={crop}
          setCrop={setCrop}
          dimensions={{
            width: imageRef.current?.width || 0,
            height: imageRef.current?.height || 0,
          }}
        />
      </ConfirmDialog>
    </>
  );
};

type MultipleFilesMessageProps = {
  files: any;
  mine: any;
  authorImage: any;
  authorName: any;
  messageTime: any;
  optionalTextMessage: any;
};

export const MultipleFilesMessage = ({
  files,
  mine,
  authorImage,
  authorName,
  messageTime,
  optionalTextMessage,
}: MultipleFilesMessageProps) => {
  return (
    <>
      <MessageContainer>
        <AuthorSection mine={mine}>
          <AuthorImage src={authorImage} />
          {authorName}
        </AuthorSection>
        {optionalTextMessage && (
          <div className="my-2">{optionalTextMessage}</div>
        )}

        {files.map((file: string) => (
          <MultipleFilesMessageFile
            key={file}
            mine={mine}
            file={file}
            messageTime={messageTime}
          />
        ))}
      </MessageContainer>
    </>
  );
};

import { Button, Checkbox, DateInput, OtherIcon } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { Loader, ScreenLoader } from "components/new";
import { currentUserAtom } from "lib/atoms/auth";
import { useTranslation } from "lib/hooks/i18n";
import {
  selectedDateAtom,
  selectedDoctorAtom,
  showTodoOnlyAtom,
} from "views/Visits/PerDay/atoms";
import { getSelectedDoctorFromCurrentUser } from "views/Visits/PerDay/tools";
import { DoctorSelect } from "./DoctorSelect";
import { useResetControlsOnUnmount } from "./hooks";

export const Controls = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
  const [selectedDoctor, setSelectedDoctor] = useAtom(selectedDoctorAtom);
  const [showTodoOnly, setShowTodoOnly] = useAtom(showTodoOnlyAtom);
  const { t } = useTranslation();

  useResetControlsOnUnmount();

  if (!currentUser) return <ScreenLoader />;

  if (!selectedDoctor) return <Loader />;

  return (
    <>
      <div className="flex w-full gap-2">
        <div className="flex flex-col gap-1 rounded-lg bg-white p-4">
          <div className="font-label">Widok listy pacjentów z dnia</div>

          <div className="flex w-max gap-4">
            <Button
              full={false}
              variant="secondary"
              onClick={() => {
                setSelectedDate((prev) => dayjs(prev).subtract(1, "days"));
              }}
              text={t("previousDay")}
            />

            <Button
              full={false}
              variant="secondary"
              onClick={() => {
                setSelectedDate((prev) => dayjs(prev).add(1, "days"));
              }}
              text="Następny dzień"
            />

            <DateInput
              value={selectedDate.toISOString()}
              onChange={(val) =>
                setSelectedDate(val ? dayjs(val as Date) : dayjs())
              }
            />

            <Button
              full={false}
              variant="secondary"
              disabled={dayjs(selectedDate).isToday()}
              onClick={() => {
                if (dayjs(selectedDate).isToday()) return;
                setSelectedDate(dayjs());
              }}
              text="Dzisiaj"
            />
          </div>
        </div>

        <div className="flex flex-col gap-1 rounded-lg bg-white p-4">
          <div className="font-label">Inne listy pacjentów</div>

          <div className="flex w-[572px] gap-[10px]">
            <DoctorSelect />

            <Button
              full={false}
              icon={<OtherIcon />}
              text="Moja lista pacjentów"
              disabled={selectedDoctor.id === currentUser.id}
              onClick={() => {
                if (selectedDoctor.id === currentUser.id) return;
                setSelectedDoctor(
                  getSelectedDoctorFromCurrentUser(currentUser),
                );
              }}
            />
          </div>
        </div>

        <div className="w-full rounded-lg bg-white p-4" />
      </div>

      <div className="w-full rounded-lg bg-white p-4">
        <Checkbox
          name="showTodoVisitsOnly"
          label="Pokaż wizyty pacjentów do realizacji"
          onChange={(e) => setShowTodoOnly(e.target.checked)}
          checked={showTodoOnly}
        />
      </div>
    </>
  );
};

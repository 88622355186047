import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  DoctorMSShiftOutputDynamic,
  GetDoctorShiftsDocument,
  useDoctorUpsertShiftMutation,
  useGetShiftsTypesLazyQuery,
} from "lib/graphql/megaSchema";
import { organizationClinicOption } from "lib/hooks/useVojevodshipOptions";
import { toaster } from "lib/tools/toaster";
import {
  CreateShiftFormSchemaType,
  SlotActionModalMode,
  StaffOptions,
  createShiftFormSchema,
  getFormDefaultValues,
} from "views/StaffScheduleCreator/utils/index";

const getGenreQueryVariable = (selectedStaff: StaffOptions | undefined) => {
  if (!selectedStaff) {
    return "";
  }

  const isDoctor = selectedStaff.genre === "DOCTOR";
  const isNurse =
    selectedStaff.genre === "NURSE" && selectedStaff.npwz?.endsWith("P");

  if (isDoctor) {
    return "Lekarz";
  }

  if (isNurse) {
    return "Pielęgniarka";
  }

  return "Położna";
};

type Props = {
  doctorsOptions?: StaffOptions[];
  selectedSlotDoctorId?: string;
  organizationsClinicsOptions: organizationClinicOption[];
  selectedOrganization?: string | undefined;
  selectedShift: DoctorMSShiftOutputDynamic | undefined;
  selectedDay?: Dayjs;
  startDate: Dayjs;
  onCloseModal: () => void;
};

export const useActionModalForm = ({
  organizationsClinicsOptions,
  selectedShift,
  startDate,
  doctorsOptions,
  selectedDay,
  selectedOrganization,
  selectedSlotDoctorId,
  onCloseModal,
}: Props) => {
  const [ShiftUpsertMutation, { loading: shiftUpsertMutationLoading }] =
    useDoctorUpsertShiftMutation({
      refetchQueries: [GetDoctorShiftsDocument],
    });

  const [getShiftTypes, { data: shiftTypesData, error: shiftTypesError }] =
    useGetShiftsTypesLazyQuery();

  const shiftTypesOptions = shiftTypesData?.doctorShiftTypes.map(
    (shiftType) => {
      return {
        label: shiftType,
        value: shiftType,
      };
    },
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<CreateShiftFormSchemaType>({
    mode: "onChange",
    defaultValues: getFormDefaultValues(
      doctorsOptions,
      selectedSlotDoctorId,
      organizationsClinicsOptions,
      selectedOrganization,
      selectedShift,
      selectedDay,
      startDate,
    ),
    resolver: zodResolver(createShiftFormSchema),
  });

  const { slotDurationEdit: slotDurationFieldValue, doctor: doctorFieldValue } =
    watch();

  const selectedStaff = doctorsOptions?.find(
    (o) => o.value === doctorFieldValue?.value,
  );

  const onSubmit = async (
    formData: CreateShiftFormSchemaType,
    mode: SlotActionModalMode,
  ) => {
    if (mode === "delete") {
      if (!selectedShift) {
        return;
      }

      const createUpsertShiftPromise = ShiftUpsertMutation({
        variables: {
          ...selectedShift,
          slotDurationString: selectedShift.slotDuration
            ? selectedShift.slotDuration.toString()
            : "D",
          active: false,
          id: selectedShift.id,
        },
      });

      await toaster.promise(createUpsertShiftPromise, {
        success: "Poprawnie usunięto zmianę",
        loading: "Usuwam zmianę...",
        error: "Nie udało się usunąć zmiany",
      });

      onCloseModal();
      return;
    }

    if (mode === "cancelDelete") {
      if (!selectedShift) {
        return;
      }
      const createUpsertShiftPromise = ShiftUpsertMutation({
        variables: {
          ...selectedShift,
          slotDurationString: selectedShift.slotDuration
            ? selectedShift.slotDuration.toString()
            : "D",
          id: selectedShift.id,
        },
      });

      await toaster.promise(createUpsertShiftPromise, {
        success: "Poprawnie przywrócono zmianę",
        loading: "Przywracam zmianę...",
        error: "Nie udało się przywrócić zmiany",
      });

      onCloseModal();
      return;
    }

    const {
      day,
      doctor,
      organizationId,
      slotDuration,
      slotType,
      placement,
      timeFrom,
      timeTo,
      comment,
    } = formData;

    const createUpsertShiftPromise = ShiftUpsertMutation({
      variables: {
        active: true,
        comment: comment ?? "",
        day: dayjs(day).format("YYYY-MM-DD"),
        doctorId: doctor.value,
        hourEnd: timeTo,
        hourStart: timeFrom,
        placeId: organizationId.value,
        shiftType: slotType.value,
        slotDurationString: slotDuration ? slotDuration : "D",
        placement: placement.value,
      },
    });

    await toaster.promise(createUpsertShiftPromise, {
      success: "Poprawnie utworzono zmianę",
      loading: "Tworzę zmianę...",
      error: "Nie udało się utworzyć zmiany",
    });

    onCloseModal();
  };

  useEffect(() => {
    setValue("slotDuration", undefined);
  }, [slotDurationFieldValue]);

  useEffect(() => {
    getShiftTypes({
      variables: { shiftGenre: getGenreQueryVariable(selectedStaff) },
    });
  }, [doctorFieldValue]);

  if (shiftTypesError || !shiftTypesData) {
    return {
      shiftUpsertMutationLoading,
      slotDurationFieldValue,
      doctorFieldValue,
      shiftTypesOptions: [],
      control,
      errors,
      handleSubmit,
      onSubmit,
    };
  }

  return {
    shiftUpsertMutationLoading,
    slotDurationFieldValue,
    doctorFieldValue,
    shiftTypesOptions,
    control,
    errors,
    handleSubmit,
    onSubmit,
  };
};

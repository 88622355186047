import { zodResolver } from "@hookform/resolvers/zod";
import { determineAge } from "@jutro/tools";
import { useAtom } from "jotai";
import { mapValues } from "radash";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { FocusableWrapper } from "components/FocusableWrapper";
import { BannerIpom } from "components/mdx/BannerIpom";
import { DoctorInterviewVirtualAssistantInsightBanner } from "components/mdx/VirtualAssistant/DoctorInterviewVirtualAssistantInsightBanner";
import { ShowVirtualAssistantChatButton } from "components/mdx/VirtualAssistant/ShowVirtualAssistantChatButton";
import { MdTextarea } from "components/new/MdTextarea";
import { DoctorVisitQuery } from "lib/graphql/megaSchema";
import {
  isInterviewSectionFilledAtom,
  issuedReferralAtom,
  wasDoctorInterviewFocusedAtom,
} from "views/Visit/RightPanel/Visit/atoms";
import { ChronicDiseasesMessageBox } from "views/Visit/RightPanel/Visit/components/ChronicDiseasesMessageBox";
import { CoexistsDiagnosisSelect } from "views/Visit/RightPanel/Visit/components/Icd10Select/CoexistsDiagnosisSelect";
import { MainDiagnosisSelect } from "views/Visit/RightPanel/Visit/components/Icd10Select/MainDiagnosisSelect";
import { PatientStatistics } from "views/Visit/RightPanel/Visit/components/PatientStatistics";
import { SendRecommendationButton } from "views/Visit/RightPanel/Visit/components/SendRecommendationButton";
import { TextArea } from "views/Visit/RightPanel/Visit/components/TextArea";
import { useVisitTab } from "views/Visit/RightPanel/Visit/hooks";
import { useCheckFormWritingStatus } from "views/Visit/RightPanel/Visit/hooks/useCheckFormWritingStatus";
import {
  handleMainDiagnosis,
  issuedReferralNoUrgentRecommendationText,
  issuedReferralUrgentRecommendationText,
} from "views/Visit/RightPanel/Visit/tools";
import { InterviewData } from "views/Visit/RightPanel/Visit/types";

type Props = {
  visit: NonNullable<DoctorVisitQuery["doctorVisit"]>;
  interviewData: InterviewData;
};

export const InterviewSection = ({ visit, interviewData }: Props) => {
  const [rerender, setRerender] = useState("");
  const [, setIsInterviewSectionFilled] = useAtom(isInterviewSectionFilledAtom);
  const [, setWasDoctorInterviewFocusedAtom] = useAtom(
    wasDoctorInterviewFocusedAtom,
  );
  const [issuedReferral, setIssuedReferral] = useAtom(issuedReferralAtom);

  const procedureType =
    visit.doctor?.genre === "DOCTOR" ? "DOCTOR_ADVICE" : "NURSE_ADVICE";

  const form = useForm<InterviewData>({
    defaultValues: {
      doctorInterview: interviewData.doctorInterview,
      physicalExamination: interviewData.physicalExamination,
      doctorDiagnosis: handleMainDiagnosis(interviewData.doctorDiagnosis),
      recommendation: interviewData.recommendation,
      comment: interviewData.comment,
      memo: interviewData.memo,
      height: interviewData.height,
      weight: interviewData.weight,
    },
    mode: "onChange",
    resolver: zodResolver(
      z
        .object({
          height: z
            .number({ message: "To pole jest wymagane" })
            .min(1, "Podaj liczbę z zakresu 1-300")
            .max(300, "Podaj liczbę z zakresu 1-300")
            .nullable(),
          weight: z.preprocess(
            (_weight) => {
              if (!_weight) return null;
              return parseFloat(String(_weight).replace(",", "."));
            },
            z
              .number({ message: "To pole jest wymagane" })
              .min(0.1, "Podaj liczbę z zakresu 1-500")
              .max(500, "Podaj liczbę z zakresu 1-500")
              .nullable(),
          ),
        })
        .passthrough(),
    ),
  });

  const { handleSubmit, setValue, watch, formState, trigger, getValues } = form;

  const {
    onChange,
    onChangeWithoutDebounce,
    isInputDisabled,
    endedContactWithPatient,
  } = useVisitTab(visit);

  const {
    comment,
    memo,
    physicalExamination,
    recommendation: _recommendation,
    doctorInterview,
    doctorDiagnosis,
    height,
    weight,
    smokeLevel,
  } = watch();

  const recommendation = _recommendation ?? "";

  const { isWriting } = useCheckFormWritingStatus(watch());

  const patientAge = determineAge(visit.patient?.pesel ?? "");

  const arePatientStatisticsValid =
    procedureType === "NURSE_ADVICE" ||
    visit.slotType !== "PERSONAL" ||
    (visit.patient &&
      visit.patient.height &&
      visit.patient.weight &&
      (patientAge < 15 || visit.patient.smokeLevel)) ||
    (height &&
      height > 0 &&
      height <= 300 &&
      weight &&
      weight > 0 &&
      weight <= 500 &&
      (patientAge < 15 || smokeLevel));

  useEffect(() => {
    // Fill recommendation with referral text after issuedReferral
    // TODO: remove after implement in backend
    if (
      !issuedReferral ||
      recommendation.includes(issuedReferralUrgentRecommendationText) ||
      recommendation.includes(issuedReferralNoUrgentRecommendationText)
    ) {
      return;
    }

    const text = issuedReferral.urgent
      ? issuedReferralUrgentRecommendationText
      : issuedReferralNoUrgentRecommendationText;

    setValue("recommendation", `${recommendation} \n\n ${text}`.trim(), {
      shouldDirty: true,
      shouldTouch: true,
    });

    // Rerender for MdTextarea properly setting value
    setRerender(String(Math.random()));

    setIssuedReferral(null);
  }, [issuedReferral]);

  const checkIfAllInputsFilled = () => {
    const mainDiagnosis = doctorDiagnosis?.find((d) => d?.grade === "MAIN");

    if (
      !recommendation ||
      !doctorInterview ||
      !physicalExamination ||
      !mainDiagnosis?.code ||
      !arePatientStatisticsValid
    ) {
      setIsInterviewSectionFilled(false);
      return;
    }

    setIsInterviewSectionFilled(true);
  };

  const handleInvalid = (errors: Record<string, unknown>) => {
    const nullifiedInvalidFields = mapValues(errors, () => null);
    const data = getValues();
    onChange({ ...data, ...nullifiedInvalidFields });
  };

  useEffect(() => {
    checkIfAllInputsFilled();
  }, [visit]);

  useEffect(() => {
    checkIfAllInputsFilled();

    if (!formState.isDirty) {
      return;
    }

    trigger().then(() => {
      handleSubmit((data) => {
        onChange({
          ...data,
          doctorDiagnosis: (data.doctorDiagnosis ?? []).filter((d) => d?.code),
          weight: data.weight ? data.weight : null,
          height: data.height ? data.height : null,
        });
      }, handleInvalid)();
    });
  }, [
    comment,
    memo,
    physicalExamination,
    recommendation,
    doctorInterview,
    height,
    weight,
  ]);

  useEffect(() => {
    checkIfAllInputsFilled();

    if (!formState.isDirty) {
      return;
    }

    trigger().then(() => {
      handleSubmit((data) => {
        onChangeWithoutDebounce({
          ...data,
          doctorDiagnosis: data.doctorDiagnosis?.filter((d) => d?.code),
        });
      }, handleInvalid)();
    });
  }, [doctorDiagnosis, smokeLevel]);

  const isInputDisabledOrEndedContactWithPatient =
    isInputDisabled || endedContactWithPatient;

  const tags = useMemo(
    () =>
      visit.patient?.tags.filter(({ type }) => type === "CHRONIC_DISEASE") ??
      [],
    [visit.patient?.tags],
  );

  return (
    <div key={rerender}>
      <FormProvider {...form}>
        <div className="flex flex-col gap-2">
          {procedureType === "DOCTOR_ADVICE" && (
            <PatientStatistics disabled={isInputDisabled} />
          )}

          <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
            <TextArea
              name="memo"
              label="Wewnętrzne informacje o wizycie"
              snippetType="memo"
              disabled={isInputDisabled}
              rows={2}
            />

            {procedureType === "DOCTOR_ADVICE" && (
              <DoctorInterviewVirtualAssistantInsightBanner />
            )}

            <MdTextarea
              label="Wywiad medyczny*"
              name="doctorInterview"
              snippetType="doctorInterview"
              disabled={isInputDisabled}
              onFocus={() =>
                setWasDoctorInterviewFocusedAtom((prev) => ({
                  ...prev,
                  [visit.id]: true,
                }))
              }
            />

            {procedureType === "DOCTOR_ADVICE" && (
              <ShowVirtualAssistantChatButton />
            )}

            <TextArea
              name="physicalExamination"
              label="Badanie fizykalne*"
              disabled={isInputDisabled}
              snippetType="physicalExamination"
            />
          </div>

          {procedureType === "DOCTOR_ADVICE" && <BannerIpom />}

          <FocusableWrapper section="visit-diagnosis">
            <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
              <MainDiagnosisSelect
                label="Rozpoznanie główne*"
                ctaCopy={
                  visit.prescriptionRequest
                    ? "Pokaż listę zamówionych leków"
                    : ""
                }
                disabled={isInputDisabledOrEndedContactWithPatient}
                tags={tags}
              />

              <ChronicDiseasesMessageBox tags={tags} />

              <CoexistsDiagnosisSelect
                label="Rozpoznanie współistniejące"
                tags={tags}
                disabled={isInputDisabledOrEndedContactWithPatient}
              />
            </div>
          </FocusableWrapper>

          <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
            <MdTextarea
              name="recommendation"
              label="Zalecenia*"
              disabled={isInputDisabledOrEndedContactWithPatient}
              snippetType="recommendation"
              withClear
            />

            {!isInputDisabledOrEndedContactWithPatient && (
              <SendRecommendationButton
                isLoading={isWriting}
                recommendationsMessageId={visit.recommendationsMessageId}
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

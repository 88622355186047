import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorNextPatientsQuery,
  useDoctorNextPatientsQuery,
} from "lib/graphql/megaSchema";
import { ItemOf } from "lib/types";

export type Patient = ItemOf<DoctorNextPatientsQuery["doctorNextPatients"]> & {
  awaitingResponse: boolean;
};

export type Patients = {
  mine: Patient[];
  mineAwaitingResponse: number;
  others: Patient[];
  othersAwaitingResponse: number;
};

const addAwaitingResponseToPatient = (
  el: ItemOf<DoctorNextPatientsQuery["doctorNextPatients"]>,
): Patient => {
  return {
    ...el,
    awaitingResponse: dayjs(getLastPatientMessageBlock(el)).unix() > 0,
  };
};

const getLastPatientMessageBlock = (
  el: ItemOf<DoctorNextPatientsQuery["doctorNextPatients"]> | undefined,
) => {
  return el?.lastPatientMessageBlock?.iso;
};

const countPatientsAwaitingResponse = (els: Patients["mine"]): number => {
  let cnt = 0;

  els.forEach((el: any) => {
    if (!el.awaitingResponse) {
      return;
    }

    cnt = cnt + 1;
  });

  return cnt;
};

const sortPatients = (a: Patient, b: Patient) => {
  if (!a.awaitingResponse && b.awaitingResponse) {
    return 1;
  }
  if (a.awaitingResponse && !b.awaitingResponse) {
    return -1;
  }
  const aPriority = a.currentVirtualVisit?.innatePriority ?? -1;
  const bPriority = b.currentVirtualVisit?.innatePriority ?? -1;
  if (aPriority < bPriority) {
    return 1;
  }
  if (aPriority > bPriority) {
    return -1;
  }

  const aLastMessageAt = new Date(getLastPatientMessageBlock(a) ?? 0).getTime();
  const bLastMessageAt = new Date(getLastPatientMessageBlock(b) ?? 0).getTime();
  return aLastMessageAt > bLastMessageAt
    ? 1
    : aLastMessageAt === bLastMessageAt
      ? 0
      : -1;
};

export const useNextPatients = () => {
  const [currentUser] = useAtom(currentUserAtom);

  const {
    data: nextPatientsData,
    loading: nextPatientsLoading,
    refetch,
  } = useDoctorNextPatientsQuery({
    fetchPolicy: "no-cache",
    pollInterval: 60 * 1000,
  });

  const patients: Patients = useMemo(() => {
    const arr = nextPatientsData?.doctorNextPatients ?? [];

    const mine = arr
      .filter((el: any) => el.assistantId === currentUser?.id)
      .map(addAwaitingResponseToPatient)
      .sort(sortPatients);

    const others = arr
      .filter((el: any) => !el.assistantId)
      .map(addAwaitingResponseToPatient)
      .sort(sortPatients);

    return {
      mine,
      others,
      mineAwaitingResponse: countPatientsAwaitingResponse(mine),
      othersAwaitingResponse: countPatientsAwaitingResponse(others),
    };
  }, [nextPatientsData, currentUser?.id, currentUser?.genre]);

  const refetchPatients = async () => {
    await refetch();
  };

  return {
    patients,
    loading: nextPatientsLoading,
    refetchPatients,
  };
};

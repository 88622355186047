import { useMemo } from "react";
import { DoctorMSProcedureType } from "lib/graphql/megaSchema";

type Props = {
  height: string;
  weight: string;
  type: DoctorMSProcedureType;
};

export const useCalculateChukMeasurementsBmi = ({
  height,
  weight,
  type,
}: Props) => {
  const getBmi = useMemo(() => {
    if (type !== "CHUK_MEASUREMENTS") {
      return null;
    }

    if (!weight || !height) {
      return null;
    }

    return (Number(weight) / Math.pow(Number(height) / 100, 2)).toFixed(1);
  }, [height, weight]);

  return getBmi;
};

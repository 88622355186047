import { useDoctorGetDoctorFullNameQuery } from "lib/graphql/megaSchema";
import { StaffAvatar } from "./StaffAvatar";

type Props = {
  snapshotBy: string;
  duration: number;
};

export const SnapshotBy = ({ snapshotBy, duration }: Props) => {
  const { data: closedBy } = useDoctorGetDoctorFullNameQuery({
    variables: {
      id: snapshotBy,
    },
  });

  return (
    <>
      <div>{`Zamknięto przez ${closedBy?.doctorDoctor?.firstName} ${closedBy?.doctorDoctor?.lastName} `}</div>{" "}
      <StaffAvatar duration={duration} userId={snapshotBy} modal />
    </>
  );
};

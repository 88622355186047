import { useWindowSize } from "@jutro/hooks";
import { determineAge } from "@jutro/tools";
import { useAtom } from "jotai";
import { useState } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import { isPastVisit } from "lib/tools/isPastVisit";
import { useVisitData } from "views/Visit/hooks";

type MakeSipCallParams = {
  phone: string;
  sipUserId: string;
  sipUserPassword: string;
  windowSizeToCenter?: number[];
};

export const useCallToPatient = (
  visitRecordingAndAnalysisAgreement = false,
) => {
  const [isCalling, setIsCalling] = useState(false);

  const [currentUser] = useAtom(currentUserAtom);
  const windowSize = useWindowSize();

  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  const recordingAndAnalysisAgreement = currentVisit
    ? currentVisit.patient?.visitRecordingAndAnalysisAgreement
    : visitRecordingAndAnalysisAgreement;

  const WIDTH = 500;
  const HEIGHT = 300;
  const userAge = determineAge(currentVisit?.patient?.pesel ?? "");
  const isChild = userAge < 18 || currentVisit?.patient?.pesel?.length === 12;

  const isCurrentUserSipDataFilled =
    !currentUser?.sipNumber ||
    !currentUser.sipUserId ||
    !currentUser.sipUserPassword ||
    !currentUser.sipNumberRecording ||
    !currentUser.sipUserIdRecording ||
    !currentUser.sipUserPasswordRecording;

  const isNewMakeCallMode = Boolean(
    currentUser &&
      currentUser.sipUserId &&
      currentUser.sipUserPassword &&
      !recordingAndAnalysisAgreement,
  );

  const isRecordingCallMode = Boolean(
    currentUser &&
      currentUser.sipUserIdRecording &&
      currentUser.sipUserPasswordRecording &&
      recordingAndAnalysisAgreement,
  );

  const isVisitViewCallButtonDisabled = Boolean(
    currentVisit?.snapshotBy ||
      isPastVisit(currentVisit ?? {}) ||
      isCurrentUserSipDataFilled,
  );

  const makeNewSipCall = (params: MakeSipCallParams) => {
    setIsCalling(true);

    setTimeout(() => {
      setIsCalling(false);
    }, 2000);

    localStorage.setItem("siplogin", params.sipUserId);
    localStorage.setItem("sippass", params.sipUserPassword);

    const [left, top] = params.windowSizeToCenter
      ? [
          params.windowSizeToCenter[0] / 2 - WIDTH / 2,
          params.windowSizeToCenter[1] / 2 - HEIGHT / 2,
        ]
      : [90, 92];

    window.open(
      `/phone/?phone=${params.phone}`,
      "_blank",
      `popup,width=${WIDTH},height=${HEIGHT},left=${left},top=${top}`,
    );
  };

  return {
    isCurrentUserSipDataFilled,
    isVisitViewCallButtonDisabled,
    isNewMakeCallMode,
    isRecordingCallMode,
    phone:
      isChild && currentVisit?.patient?.guardians[0]
        ? currentVisit.patient.guardians[0].phone
        : currentVisit?.patient?.phone,
    sipUserId: currentUser?.sipUserId,
    sipUserPassword: currentUser?.sipUserPassword,
    sipUserIdRecording: currentUser?.sipUserIdRecording,
    sipUserPasswordRecording: currentUser?.sipUserPasswordRecording,
    windowSize,
    isCalling,
    makeNewSipCall,
  };
};

import { useOnClickOutside } from "@jutro/hooks";
import { AddCircleIcon, Button, ProfileIcon } from "@jutro/ui";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useCreateProcedureVisit } from "components/PatientDocs/Visits/hooks/useCreateProcedureVisit";
import { Badge as NewBadge } from "components/new";
import { SnapshotBy } from "components/new/SnapshotBy";
import { StaffAvatar } from "components/new/StaffAvatar";
import {
  DoctorMSAppointmentStatus,
  DoctorMSSentNoShowNotifications,
  DoctorMSVisitType,
  DoctorOrganizationsQuery,
} from "lib/graphql/megaSchema";
import {
  ConfirmationStatus,
  useAppointmentStatus,
} from "lib/hooks/useAppointmentStatus";
import { Maybe } from "lib/types";
import { VisitCallDissmissedIcon } from "views/Schedule/Icons/VisitCallDissmissedIcon";
import { VisitConfirmedBySmsIcon } from "views/Schedule/Icons/VisitConfirmedBySmsIcon";
import { VisitConfirmedIcon } from "views/Schedule/Icons/VisitConfirmedIcon";
import { VisitDefaultStatusIcon } from "views/Schedule/Icons/VisitDefaultStatusIcon";
import { VisitMessageSentIcon } from "views/Schedule/Icons/VisitMessageSentIcon";
import { visitTypeLabelMap } from "views/Schedule/utils";

const statusMap: Record<VisitStatus, string> = {
  scheduled: "Zaplanowana",
  reception: "Na recepcji",
  inProgress: "W trakcie",
  completed: "Zakończona",
  patientDidNotCome: "Pacjent nie przyszedł",
  patientLeft: "Zakończono kontakt",
};

export type VisitStatus =
  | "scheduled"
  | "reception"
  | "inProgress"
  | "completed"
  | "patientDidNotCome"
  | "patientLeft";

type DialogModalProps = {
  doctor: any;
  currentUserGenre: string | null | undefined;
  patient: string;
  pesel: string;
  phoneNumber: Maybe<string>;
  snapshotBy: string;
  duration: number;
  time: string;
  organization: Maybe<DoctorOrganizationsQuery["doctorOrganizations"][number]>;
  type: Maybe<DoctorMSVisitType>;
  tags?: any[];
  status: VisitStatus;
  patientId: string;
  visitId: string;
  isOpen?: boolean;
  appointmentStatus: DoctorMSAppointmentStatus;
  setIsModalOpen: (state: boolean) => void;
  refetch: () => void;
  isEcommerce?: boolean;
  notificationServiceStatus?: DoctorMSSentNoShowNotifications | null;
};

type ContentTileProps = {
  header: string;
  content?: string;
  isInHeader: boolean;
  status?: VisitStatus;
};

type BasicContentTileProps = {
  header: string;
  content?: string;
  isInHeader: boolean;
};

type StatusTileProps = {
  status: VisitStatus;
};

type ScheduledVisitAppointmentStatusProps = {
  appointmentStatus: ConfirmationStatus;
  preset: "EVENT" | "MODAL";
  onClick?: () => Promise<void>;
};

const StatusTile = ({ status }: StatusTileProps) => (
  <div className="flex min-w-max flex-col">
    <span>Status</span>
    <span className="font-medium">{statusMap[status]}</span>
  </div>
);

const ContentTile = ({
  header,
  content = "-",
  isInHeader,
  status,
}: ContentTileProps) => (
  <div className="flex min-w-max flex-col">
    <span className={`${isInHeader && "font-medium"}`}>{header}</span>
    <span className={`${!isInHeader && "font-medium"}`}>
      {status === "patientDidNotCome" ||
      status === "scheduled" ||
      status === "reception"
        ? "-"
        : content}
    </span>
  </div>
);

const BasicContentTile = ({
  header,
  content = "-",
  isInHeader,
}: BasicContentTileProps) => (
  <div className="flex min-w-max flex-col">
    <span className={`${isInHeader && "font-medium"}`}>{header}</span>
    <span className={`${!isInHeader && "font-medium"}`}>{content}</span>
  </div>
);

export const ScheduledVisitAppointmentStatus = ({
  appointmentStatus,
  preset,
  onClick,
}: ScheduledVisitAppointmentStatusProps) => {
  const handleIconDisplay = (appointmentStatus: ConfirmationStatus) => {
    const isEvent = preset === "EVENT";

    if (appointmentStatus === "WAITING_FOR_SMS") {
      return <VisitMessageSentIcon size={isEvent ? "sm" : "md"} />;
    }

    if (appointmentStatus === "SMS_CONFIRMED") {
      return <VisitConfirmedBySmsIcon size={isEvent ? "sm" : "md"} />;
    }

    if (appointmentStatus === "INDETERMINATE") {
      return <VisitCallDissmissedIcon size={isEvent ? "sm" : "md"} />;
    }

    if (appointmentStatus === "CONFIRMED") {
      return <VisitConfirmedIcon size={isEvent ? "sm" : "md"} />;
    }

    return <VisitDefaultStatusIcon size={isEvent ? "sm" : "md"} />;
  };

  return (
    <div
      className={`flex items-center justify-center rounded-full`}
      onClick={onClick}
    >
      {handleIconDisplay(appointmentStatus)}
    </div>
  );
};

export const DialogModal = ({
  doctor,
  currentUserGenre,
  patient,
  pesel,
  phoneNumber,
  time,
  organization,
  status,
  duration,
  type,
  patientId,
  snapshotBy,
  visitId,
  isOpen = false,
  appointmentStatus,
  tags = undefined,
  setIsModalOpen,
  isEcommerce = false,
  notificationServiceStatus,
}: DialogModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const place = organization?.displayName;

  const { createProcedureVisitAndRedirect } = useCreateProcedureVisit();

  const handleCreateProcedure = () => {
    if (!organization) {
      return;
    }

    const organizationId = organization.id.slice(0, 4) + 1;

    createProcedureVisitAndRedirect({ patientId, organizationId });
  };

  const {
    appointmentStatus: calculatedAppointmentStatus,
    handleAppointmentStatusChange,
  } = useAppointmentStatus(
    visitId,
    appointmentStatus,
    notificationServiceStatus,
  );

  useOnClickOutside(ref, () => {
    setIsModalOpen(false);
  });

  if (!isOpen) return null;

  return (
    <div className="fixed left-0 top-0 z-50 grid h-screen w-screen place-items-center">
      <div
        ref={ref}
        className="flex max-w-min flex-col gap-5 rounded-lg bg-white p-10 shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div className="flex items-center justify-between space-x-3 border-b-2 border-jutro-new-warm-gray-100 pb-3">
          <div className="flex w-96 items-center gap-1">
            {type !== "PROCEDURE" && (
              <ScheduledVisitAppointmentStatus
                appointmentStatus={calculatedAppointmentStatus}
                preset="MODAL"
                onClick={handleAppointmentStatusChange}
              />
            )}
            <h1
              data-hj-suppress
              className="font-display-3 whitespace-pre-wrap font-medium"
            >
              {patient}
            </h1>
          </div>
          <div className="flex space-x-5">
            <ContentTile
              header="Numer PESEL"
              content={
                !pesel || pesel.length === 12 ? "brak numeru PESEL" : pesel
              }
              isInHeader
            />
            <ContentTile
              header="Numer telefonu"
              content={
                !phoneNumber || phoneNumber.startsWith("000")
                  ? "Brak numeru telefonu"
                  : phoneNumber
              }
              isInHeader
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            {snapshotBy ? (
              <SnapshotBy snapshotBy={snapshotBy} duration={duration} />
            ) : (
              <>
                <div>{`Przypisana do ${doctor.firstName} ${doctor.lastName}`}</div>{" "}
                <StaffAvatar duration={duration} userId={doctor.id} modal />
              </>
            )}
          </div>
          {isEcommerce && (
            <NewBadge
              text="eCommerce"
              color="pink"
              textColor="gray"
              upperCase={false}
            />
          )}
        </div>

        <div className="flex justify-between">
          <ContentTile header="Czas wizyty" content={time} isInHeader={false} />

          <ContentTile
            header="Miejsce"
            content={place || "brak"}
            isInHeader={false}
            status={status}
          />

          <BasicContentTile
            header="Typ"
            content={type ? visitTypeLabelMap[type] : "brak"}
            isInHeader={false}
          />

          <StatusTile status={status} />
        </div>
        {tags && (
          <div className="flex max-w-full flex-1 flex-wrap gap-2">
            {tags.map((tag, idx) => {
              return (
                <div
                  key={idx}
                  className={`text-md font-caption flex max-w-max flex-row items-center rounded-lg bg-jutro-new-warm-gray-100 px-4 py-1 text-black`}
                >
                  {tag}
                </div>
              );
            })}
          </div>
        )}
        <div className="flex justify-end gap-x-1">
          <Link to={`/patients/${patientId}/profile`}>
            <Button
              full={false}
              icon={<ProfileIcon />}
              variant="secondary"
              text="Profil pacjenta"
            />
          </Link>

          {type === "STANDARD" && currentUserGenre === "NURSE" && (
            <Button
              full={false}
              variant="secondary"
              icon={<AddCircleIcon />}
              onClick={handleCreateProcedure}
              text="Wizyta zabiegowa"
            />
          )}

          {status !== "patientDidNotCome" && currentUserGenre !== "STAFF" && (
            <Link to={`/visits/${visitId}`}>
              <Button full={false} text="Przejdź do wizyty" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

import { fixedPriceValue } from "components/PatientDocs/Ordered/utils";
import { PacketsResponseData } from "lib/hooks/useAsyncOptions";

type Props = {
  item: PacketsResponseData;
};

const PacketOptionLabel = ({ item }: Props) => {
  const { name, price, promotion, totalPrice } = item;

  return (
    <div className="flex justify-between">
      <div>
        <span>{name}</span>
      </div>

      <div className="flex gap-2">
        {promotion && (
          <span className="line-through">{`${fixedPriceValue(price)}`}</span>
        )}
        <span>{`${fixedPriceValue(totalPrice)}`}</span>
      </div>
    </div>
  );
};

export const returnFormattedPacketsData = (data: PacketsResponseData[]) => {
  const formattedData = data.map((packet) => {
    return [returnPacketOption(packet)];
  });
  return formattedData.flat();
};

const returnPacketOption = (item: PacketsResponseData) => {
  const { id, name, price, promotion, totalPrice } = item;

  return {
    value: id,
    label: <PacketOptionLabel item={item} />,
    promotion,
    price,
    totalPrice,
    name,
    id,
    type: "ECOMMERCE",
  };
};

import { CloseIcon } from "@jutro/ui";
import "@mdxeditor/editor/style.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { SetStateAction } from "jotai";
import { Dispatch, useEffect, useState } from "react";
import ReactModal from "react-modal";
import mdxDocs from "views/MdxPlayground/docs/mdxDocs.md?url";

type Props = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const modalOverlayStyles = {
  overlay: {
    zIndex: 50,
    backgroundColor: "rgba(0,0,0, .6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const DocsModal = ({ isOpen, setOpen }: Props) => {
  const [docs, setDocs] = useState("");

  useEffect(() => {
    fetch(`${mdxDocs}`)
      .then((res) => res.text())
      .then((text) => setDocs(text));
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalOverlayStyles}
      contentElement={() => (
        <div className="max-h-[calc(100vh-20px)] overflow-y-scroll rounded-lg">
          <div
            className="fixed right-4 top-4 flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border bg-white"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </div>
          <MarkdownPreview source={docs} className="p-6" />
        </div>
      )}
    />
  );
};

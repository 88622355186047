import { EyeOffIcon, EyeOnIcon, Tag, Tooltip } from "@jutro/ui";
import { tv } from "tailwind-variants";
import { IpomFormSchema } from "components/IpomForm/schema";

const getStyles = tv({
  slots: {
    wrapper:
      "group flex w-full cursor-pointer select-none items-center text-wrap rounded-md border-none bg-blue-50 p-2 text-left outline-none hover:bg-jutro-new-blue-100 focus-visible:outline focus-visible:outline-4 focus-visible:-outline-offset-4 focus-visible:outline-jutro-new-blue-500 active:bg-jutro-new-blue-300 disabled:pointer-events-none disabled:cursor-default disabled:bg-jutro-new-warm-gray-50",
    iconWrapper:
      "grid max-h-6 w-fit place-items-center text-jutro-new-blue-800 group-disabled:text-jutro-new-warm-gray-400",
  },
  variants: {
    active: {
      false: {
        wrapper:
          "bg-jutro-new-warm-gray-50 hover:bg-jutro-new-warm-gray-100 active:bg-jutro-new-warm-gray-200",
        iconWrapper: "text-jutro-new-warm-gray-400",
      },
    },
  },
});

type Props = {
  item: IpomFormSchema["observations"][number];
  disabled?: boolean;
  onClick: () => void;
};

export const DiagnosisItem = ({ item, onClick, disabled }: Props) => {
  const { wrapper, iconWrapper } = getStyles({ active: item.active });

  return (
    <Tooltip
      position="right"
      disabled={disabled}
      trigger={
        <button
          className={wrapper()}
          onClick={() => onClick()}
          disabled={disabled}
          type="button"
        >
          <div className="flex w-full gap-2 text-jutro-new-warm-gray-800">
            <span className="font-paragraph-1 line-clamp-2 w-full">
              {item.name}
            </span>

            <span className="grid max-h-6 w-fit place-items-center">
              <Tag
                color={item.active && !disabled ? "green" : "gray"}
                text={item.value}
              />
            </span>

            <div className={iconWrapper()}>
              {item.active ? <EyeOnIcon size="sm" /> : <EyeOffIcon size="sm" />}
            </div>
          </div>
        </button>
      }
      content={item.active ? "Załączone w IPOMie" : "Ukryte w IPOMie"}
    />
  );
};

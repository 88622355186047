import { useOnClickOutside } from "@jutro/hooks";
import { useRef, useState } from "react";

type Props = {
  text: string;
  icon: JSX.Element;
  colorType?: "red" | "green" | "gray" | "blue";
  onClick?: () => void;
};

export const ChatButtonPopover = ({
  text,
  colorType = "gray",
  icon,
  onClick = () => {},
}: Props) => {
  const [active, setAvtive] = useState(false);

  const iconStyleMap = {
    red: { bg: "bg-jutro-new-rose-500", text: "text-jutro-new-rose-500" },
    green: { bg: "bg-jutro-new-green-400", text: "text-jutro-new-green-400" },
    gray: {
      bg: "bg-jutro-new-warm-gray-800",
      text: "text-jutro-new-warm-gray-800",
    },
    blue: { bg: "bg-jutro-new-blue-800", text: "text-jutro-new-blue-800" },
  };

  const ref = useRef<HTMLButtonElement>(null);
  useOnClickOutside(ref, () => setAvtive(false));

  return (
    <div className="relative h-full w-full">
      <button
        className={`flex flex-col justify-center ${iconStyleMap[colorType].bg} h-20 w-full cursor-pointer items-center rounded-lg bg-opacity-10 py-4 text-center transition-all hover:bg-opacity-20`}
        onClick={() => {
          onClick();
          setAvtive(!active);
        }}
        ref={ref}
      >
        <div className="space-y-2">
          <div
            className={`flex justify-center ${iconStyleMap[colorType].text}`}
          >
            {icon}
          </div>
          <span
            className={`font-paragraph-2 inline-block ${iconStyleMap[colorType].text}`}
          >
            {text}
          </span>
        </div>
      </button>
    </div>
  );
};

import { Banner } from "@jutro/ui";
import { useIpomBanner } from "components/mdx/BannerIpom/useIpomBanner";
import { useFlag } from "lib/hooks/flags";

export const BannerIpom = () => {
  const isIpomEnabled = useFlag("ipom");

  const { bannerContent, handleClick, show, setShow, isStandardVisit } =
    useIpomBanner();

  if (!isIpomEnabled || !bannerContent || !show || !isStandardVisit) {
    return null;
  }

  const { buttonText, description, type, icon: Icon } = bannerContent;

  return (
    <Banner
      type={type}
      description={description}
      actionButton={{
        text: buttonText,
        icon: <Icon />,
        onClick: handleClick,
      }}
      handleClose={() => setShow(false)}
    />
  );
};

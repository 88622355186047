import { Button, CloseIcon, LayoutLeftIcon, LayoutRightIcon } from "@jutro/ui";
import { envVars } from "envvars";
import { pdfjs } from "react-pdf";
import { ImagePreview } from "components/new/ImagePreview/ImagePreview";
import { PdfPreview } from "components/new/PdfPreview/PdfPreview";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { fileConfig } from "lib/tools/httpConfigs";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type Props = {
  fileId: string;
};

export const FilePreview = ({ fileId }: Props) => {
  const isPdf = () => fileId.slice(fileId.length - 3).toLowerCase() === "pdf";

  const { moveTile, removeTile, getTileInfo, visibleTiles } = useTileSystem();

  const handleSwitchDrawerSides = () => {
    moveTile(fileId, ({ currentPosition }) =>
      currentPosition === TileColumn.Right ? TileColumn.Left : TileColumn.Right,
    );
  };

  const handleDrawerClose = () => {
    removeTile(fileId);
  };

  return (
    <div className="flex flex-1 flex-col justify-between gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <h2 className="font-paragraph-2 pb-2 text-jutro-new-warm-gray-700">
          Podgląd dokumentu
        </h2>

        <div className="flex items-center gap-2 align-middle">
          <Button
            icon={
              getTileInfo(fileId).position === TileColumn.Right ? (
                <LayoutLeftIcon />
              ) : (
                <LayoutRightIcon />
              )
            }
            variant="ghost"
            size="condensed"
            disabled={visibleTiles.includes("visit-procedure-panel")}
            onClick={handleSwitchDrawerSides}
            label={`Przesuń w ${getTileInfo(fileId).position === TileColumn.Right ? "lewo" : "prawo"}`}
            tooltipPosition="bottom"
            full={false}
          />

          <Button
            full={false}
            variant="ghost"
            size="condensed"
            onClick={handleDrawerClose}
            icon={<CloseIcon />}
            label="Zamknij"
            tooltipPosition="bottom"
          />
        </div>
      </div>

      {isPdf() ? (
        <PdfPreview file={`${fileConfig[envVars.REACT_APP_CONFIG]}${fileId}`} />
      ) : (
        <ImagePreview file={fileId ?? ""} />
      )}
    </div>
  );
};

import { useMemo } from "react";
import { useDoctorGetPatientQuery } from "lib/graphql/megaSchema";

export const usePatient = (id: string | undefined) => {
  const { data: patientData, loading: patientLoading } =
    useDoctorGetPatientQuery({
      skip: !id,
      variables: {
        id,
      },
    });

  const patient = useMemo(() => {
    if (!patientData) {
      return null;
    }

    const { doctorPatient } = patientData;

    return doctorPatient;
  }, [patientData]);

  return {
    patient,
    patientLoading,
  };
};

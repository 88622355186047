import { determineAge, determineBirthday } from "@jutro/tools";
import { Tag, Tooltip } from "@jutro/ui";
import dayjs from "dayjs";
import { useMemo } from "react";
import { getAgeLabelsFromPesel } from "components/PatientDocs/tools";

type Props = {
  pesel: string;
};

export const PatientAgeTag = ({ pesel }: Props) => {
  const patientAge = determineAge(pesel);

  const { years, months, weeks, daysInMonth, daysInWeek } = useMemo(
    () => getAgeLabelsFromPesel(pesel),
    [pesel],
  );

  const tagText = useMemo(() => {
    if (patientAge < 1) return `${months} ${daysInMonth}`;
    if (patientAge <= 6) return `${years} ${months}`;
    return `${years}`;
  }, [patientAge, years, months, daysInMonth]);

  const tooltipText = useMemo(() => {
    return patientAge < 1
      ? `Wiek: ${weeks} + ${daysInWeek}`
      : `Wiek: ${years} + ${months}`;
  }, [patientAge, weeks, daysInWeek, years, months]);

  return (
    <Tooltip
      position="bottom"
      triggerAsChild={false}
      content={
        <div className="font-paragraph-2 flex flex-col gap-1 p-2">
          <span>{`Data urodzenia: ${dayjs(determineBirthday(pesel)).format("D MMMM YYYY")}`}</span>
          <span>{tooltipText}</span>
        </div>
      }
      trigger={<Tag text={tagText} color="gray" />}
    />
  );
};

import { Button, CloseIcon } from "@jutro/ui";
import { BasicTile } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

export const Tile = ({
  children,
  title,
  basicTileOrId,
}: {
  children: React.ReactNode;
  title: string;
  basicTileOrId: BasicTile | string;
}) => {
  const { removeTile } = useTileSystem();

  return (
    <div className="no-scrollbar flex h-full flex-col gap-4 overflow-y-auto rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <div className="font-heading-4">{title}</div>

        <Button
          full={false}
          size="condensed"
          variant="ghost"
          icon={<CloseIcon />}
          label="close tile"
          withTooltip={false}
          onClick={() => removeTile(basicTileOrId)}
        />
      </div>

      <div className="p-4">{children}</div>
    </div>
  );
};

import { atomWithImmer } from "jotai-immer";
import { atomWithReset } from "jotai/utils";
import { HandledTileNumber } from "lib/tools/createWorkspace";
import { Tile, TileColumn, Workspace } from "lib/tools/createWorkspace/types";

export const defaultWorkspaceTilesMap: Record<Workspace, Tile[]> = {
  "visit-advice": ["docs-of-patient", "chat", "visit-docs"],
  "visit-procedure": ["docs-of-patient", "visit-procedure-panel"],
  "patient-profile": ["docs-of-patient", "patient-events"],
  "patient-chat": ["docs-of-patient", "chat"],
  "snippets-admin": ["snippets-admin"],
  settings: [{ name: "snippet-settings", id: "snippet-settings", data: {} }],
};

export const workspaceTilesAtom = atomWithImmer<Tile[][]>([]);
export const workspaceTilesInjectAtom = atomWithImmer<
  { tile: Tile; column: TileColumn }[]
>([]);
export const workspaceWidthAtom = atomWithReset<HandledTileNumber>(3);

import { VariantProps, tv } from "tailwind-variants";

export const getScheduledVisitAppointmentIconStyles = tv({
  base: "",
  variants: {
    size: {
      sm: "w-4 h-4",
      md: "w-6 h-6",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export type ScheduledVisitAppointmentIconStylesProps = VariantProps<
  typeof getScheduledVisitAppointmentIconStyles
>;

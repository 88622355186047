import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "components/TagFormNew/ErrorMessage";
import { TagFormSchema } from "components/TagFormNew/hooks";
import { TagForm } from "components/TagFormNew/types";
import { DoctorMSConfirmationType } from "lib/graphql/megaSchema";

const radioInputMap: Record<DoctorMSConfirmationType, string> = {
  DOCUMENTATION: "Z dokumentacji",
  EXAMINATION: "Z rozpoznania",
  INTERVIEW: "Z wywiadu",
};

type RadioInputProps = {
  value: DoctorMSConfirmationType;
  tagForm: TagForm;
};

const RadioInput = ({ value, tagForm }: RadioInputProps) => {
  const { register } = useFormContext<TagFormSchema>();

  const { tag, type } = tagForm;

  const label = radioInputMap[value];

  const isInputDisabled =
    tag?.confirmationType === "EXAMINATION" ||
    value === "EXAMINATION" ||
    (type === "update" && tag?.confirmationType);

  return (
    <label
      className={`flex items-center gap-2 ${
        isInputDisabled && "pointer-events-none text-jutro-new-warm-gray-500"
      }`}
    >
      <input type="radio" value={value} {...register("confirmationType")} />
      {label}
    </label>
  );
};

type RadioSectionProps = {
  tagForm: TagForm;
};

export const RadioSection = ({ tagForm }: RadioSectionProps) => {
  const {
    formState: {
      errors: { confirmationType },
    },
  } = useFormContext<TagFormSchema>();

  return (
    <div>
      <div className="font-paragraph-2 flex items-center gap-6 bg-jutro-new-warm-gray-100 p-3">
        <RadioInput tagForm={tagForm} value={"INTERVIEW"} />
        <RadioInput tagForm={tagForm} value={"DOCUMENTATION"} />
        <RadioInput tagForm={tagForm} value={"EXAMINATION"} />
      </div>
      {confirmationType?.message && (
        <ErrorMessage text={confirmationType.message} />
      )}
    </div>
  );
};

import {
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from "react-hook-form";

type Props = {
  color?: string;
  xs?: boolean;
  label?: string;
  disabled?: boolean;
  filters?: boolean;
  error?: FieldError;
} & Partial<ControllerRenderProps<FieldValues, string>>;

export const Checkbox = ({
  label = undefined,
  xs = false,
  color = "text-jutro-new-blue-800",
  value,
  filters = false,
  disabled = false,
  error,
  onChange = () => {},
}: Props) => (
  <div className="flex align-middle">
    <label className="font-paragraph-2 inline-flex min-w-0 items-center text-jutro-new-warm-gray-800">
      <input
        disabled={disabled}
        type="checkbox"
        onChange={() => {
          onChange(!value);
        }}
        checked={value}
        className={`cursor-pointer focus:outline-none focus:ring-0 ${
          xs ? "h-4 w-4" : "h-5 w-5"
        } ${
          filters ? "rounded-full" : "rounded-md"
        } form-checkbox focus:shadow-none focus:ring-white ${
          disabled
            ? "bg-jutro-new-warm-gray-100 text-jutro-new-warm-gray-300"
            : color
        } ${error ? "border-jutro-new-rose-600" : "border-jutro-new-warm-gray-200"} `}
      />
      {label && (
        <span
          className={`ml-2 overflow-hidden text-ellipsis whitespace-nowrap ${
            xs ? "font-paragraph-2" : "font-paragraph-2"
          } font-geologica cursor-pointer select-none font-medium text-jutro-new-warm-gray-800`}
        >
          {label}
        </span>
      )}
    </label>
  </div>
);

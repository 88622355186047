import { Loader } from "components/new";
import { useDoctorRegularDrugsQuery } from "lib/graphql/megaSchema";
import { RegularDrug } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/Item";
import { useVisitData } from "views/Visit/hooks";

export const RegularDrugs = () => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  const { data, loading, error } = useDoctorRegularDrugsQuery({
    variables: {
      patientId: currentVisit!.patient!.id,
    },
    fetchPolicy: "no-cache",
    skip: !currentVisit?.patient?.id,
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <span>Błąd wczytywania leków</span>;
  }

  if (!data || data.doctorRegularDrugs.length === 0) {
    return <span>Brak danych</span>;
  }

  const { doctorRegularDrugs } = data;

  return (
    <div className="flex flex-col gap-4 divide-y">
      {doctorRegularDrugs.map((drug) => {
        return <RegularDrug key={drug.sourceTag.id} drug={drug} />;
      })}
    </div>
  );
};

import { determineAge, isValidPesel } from "@jutro/tools";
import { z } from "zod";
import { PatientCreationSchema } from "views/Patients/types";

export const defaultAddressValues = {
  city: "",
  houseNumber: "",
  latitude: 0,
  longitude: 0,
  municipality: "",
  state: "",
  street: "",
  teryt: "",
  zipCode: "",
};

export const patientCreationSchemaDefaults: PatientCreationSchema = {
  firstName: "",
  lastName: "",
  pesel: "",
  peselNotGivenYet: false,
  birthDate: "",
  gender: "",
  phone: "",
  address: defaultAddressValues,
  flatNumber: "",
  email: "",
};

export const patientCreationSchema = z
  .object({
    phone: z
      .string()
      .min(1, { message: "To pole jest wymagane" })
      .regex(/^\d+$/, {
        message: "Numer telefonu musi składać się wyłącznie z cyfr",
      })
      .length(9, { message: "Numer telefonu musi mieć dokładnie 9 cyfr" }),
    pesel: z
      .string()
      .min(1, { message: "To pole jest wymagane" })
      .regex(/^\d+$/, {
        message: "Numer pesel musi składać się wyłącznie z cyfr",
      })
      .refine((val) => val.length === 11 || val.length === 12, {
        message: "Numer pesel musi mieć dokładnie 11 cyfr",
      })
      .refine((val) => (determineAge(val) >= 18 ? isValidPesel(val) : true), {
        message: "Niepoprawny pesel - błędna suma kontrolna",
      }),
    peselNotGivenYet: z.boolean(),
    birthDate: z.string().optional(),
    gender: z.string().optional(),
    firstName: z.string().min(1, { message: "To pole jest wymagane" }),
    lastName: z.string().min(1, { message: "To pole jest wymagane" }),
    flatNumber: z.string().nullable().optional(),
    address: z.object({
      city: z.string().min(1, { message: "To pole jest wymagane" }),
      houseNumber: z.string(),
      latitude: z.number(),
      longitude: z.number(),
      municipality: z.string(),
      state: z.string(),
      street: z.string(),
      teryt: z.string(),
      zipCode: z.string(),
    }),
    email: z
      .string()
      .email({ message: "Nieprawidłowy format adresu e-mail" })
      .optional()
      .or(z.literal("")),
  })
  .refine(
    (fields) => {
      if (fields.peselNotGivenYet === false && !fields.birthDate) {
        return true;
      }
      if (fields.peselNotGivenYet === true && fields.birthDate) {
        return true;
      }

      return fields.birthDate;
    },
    {
      path: ["birthDate"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (fields) => {
      if (fields.peselNotGivenYet === false && !fields.gender) {
        return true;
      }
      if (fields.peselNotGivenYet === true && fields.gender) {
        return true;
      }

      return fields.gender;
    },
    {
      path: ["gender"],
      message: "To pole jest wymagane",
    },
  );

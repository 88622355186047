import { envVars } from "envvars";
import { useEffect } from "react";
import { isProductionEnvironment } from "lib/tools";

const configData = {
  url: `https://static.zdassets.com/ekr/snippet.js?key=${envVars.REACT_APP_ZENDESK_ENV_KEY}`,
  id: "ze-snippet",
};

type Zendesk = (target: string, event: string, callback?: () => void) => void;

type ExtendedWindow = {
  zE?: Zendesk;
} & Window;

declare const window: ExtendedWindow;

const zendesk: Zendesk = (target, event, callback) => {
  if (!window.zE) {
    return;
  }

  window.zE(target, event, callback);
};

export const useZendesk = () => {
  useEffect(() => {
    if (!isProductionEnvironment()) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("src", configData.url);
    script.setAttribute("id", configData.id);

    const body = document.querySelector("body");

    if (!body) {
      console.error("Body element not found!");
      return;
    }

    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    zendesk("messenger", "show");

    return () => {
      zendesk("messenger", "hide");
    };
  }, [window.zE]);
};

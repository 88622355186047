import { useAtom } from "jotai";
import { PropsWithChildren } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { orderedTestsAtom } from "components/ExaminationPanel/atoms";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { useHandleTestsCheckboxes } from "components/PatientDocs/Ordered/hooks/useHandleTestsCheckboxes";
import { useOrderedTests } from "components/PatientDocs/Ordered/hooks/useOrderedTests";
import { Checkbox, Loader } from "components/new";
import { OrderedTestElement } from "./Element/OrderedTestElement";

type Props = { patientId: string };

export const OrderedTestsList = ({ patientId }: Props) => {
  const [orderedTests] = useAtom(orderedTestsAtom);
  const { notPaidTestsList, paidTestsList, loading, error } = useOrderedTests({
    patientId,
  });

  const {
    selectedNotPaidTestsList,
    selectedPaidTestsList,
    toggleAllItemsSelectionHandler,
  } = useHandleTestsCheckboxes();

  const { visitId } = useParams<{ visitId: string }>();

  if (loading) return <Loader />;

  if (error) return <span className="font-paragraph-2">Wystąpił błąd.</span>;

  if (orderedTests.length === 0)
    return <div className="font-paragraph-2">Brak pozycji do wyświetlenia</div>;

  const OrderedTestWrapper = ({
    children,
    label,
    onChange,
    value,
  }: PropsWithChildren<{
    label: string;
    value: boolean;
    onChange: () => void;
  }>) => {
    return (
      <div className="flex flex-col gap-2">
        <div className="sticky top-0 bg-white py-2">
          <h4 className="font-paragraph-2">{label}</h4>
        </div>

        <div className="flex flex-col gap-2">
          <div className="w-full rounded-lg bg-jutro-new-warm-gray-50 p-2">
            <Checkbox
              label="zaznacz wszystkie"
              value={value}
              onChange={onChange}
            />
          </div>

          <div className="flex flex-col gap-2">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {notPaidTestsList.length > 0 && (
        <OrderedTestWrapper
          label="Do opłacenia"
          onChange={() => toggleAllItemsSelectionHandler(notPaidTestsList)}
          value={notPaidTestsList.length === selectedNotPaidTestsList.length}
        >
          {notPaidTestsList.map((item, index) => (
            <OrderedTestElement
              key={item.id}
              data={item}
              index={index}
              mode={
                isLaboratoryExamination(item)
                  ? "laboratoryExamination"
                  : "procedure"
              }
            />
          ))}
        </OrderedTestWrapper>
      )}

      {paidTestsList.length > 0 && (
        <OrderedTestWrapper
          label="Do wykonania"
          onChange={() => toggleAllItemsSelectionHandler(paidTestsList)}
          value={
            selectedPaidTestsList.length === paidTestsList.length &&
            selectedPaidTestsList.length > 0
          }
        >
          <Droppable droppableId="proceduresAndExaminations">
            {(provided) => (
              <div
                className="flex flex-col gap-2"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {paidTestsList.map((item, index) => (
                  <OrderedTestElement
                    key={item.id}
                    data={item}
                    index={index}
                    mode={
                      isLaboratoryExamination(item)
                        ? "laboratoryExamination"
                        : "procedure"
                    }
                    draggable={Boolean(visitId)}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </OrderedTestWrapper>
      )}
    </>
  );
};

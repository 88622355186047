import { NetworkStatus } from "@apollo/client";
import {
  AddCircleIcon,
  BinIcon,
  Button,
  Input,
  MailSendIcon,
  SearchIcon,
} from "@jutro/ui";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Block as BlockWithIcon } from "components/Block";
import { PatientDocsSurvey } from "components/PatientDocs/Overview/Survey";
import { corruptedAnalysisTypeMap } from "components/PatientDocs/tools";
import { CurrentPatient } from "components/PatientDocs/types";
import { TagsBlock } from "components/TagsBlock";
import { Badge, Table } from "components/new";
import { MedicalDocumentationList } from "components/new/MedicalDocumentation/List";
import { useDoctorGetDocumentsQuery } from "lib/graphql/megaSchema";
import { mapAnalysisType } from "lib/tools/mapAnalysisType";
import { procedureTitleMap } from "lib/tools/procedureTitleMap";
import { toaster } from "lib/tools/toaster";
import { ArrayElement, PatientVitality } from "lib/types";

type RecommendedExamination = {
  assignmentType: string;
  createdAt: {
    iso: string;
  };
  paid: null | boolean;
  id: string;
};

const sendAssignmentAgain = (id: string): void => {
  fetch(`https://api.jutromedical.com/rentgenAny?id=${id}`)
    .then((res) => {
      if (!res.ok) {
        toaster.error(
          "Coś poszło nie tak - zgłoś to do działu IT z linkiem do wizyty",
        );

        return;
      }

      toaster.success("Skierowanie zostało wysłane poprawnie");
    })
    .catch(() => {
      toaster.error(
        "Coś poszło nie tak - zgłoś to do działu IT z linkiem do wizyty",
      );
    });
};

const returnTypeText = (i: PatientVitality) => {
  const type = mapAnalysisType(i);

  if (!(type in corruptedAnalysisTypeMap)) {
    return type;
  }

  return corruptedAnalysisTypeMap[type];
};

const assignmentsFilter = (
  m: ArrayElement<CurrentPatient["messages"]> | undefined,
) => {
  if (!m) return false;
  if (!("status" in m)) return false;
  return m.type === "ASSIGNMENT";
};

const examinationsColumns = [
  {
    Header: "Rodzaj",
    width: 90,
    accessor: (row: [string, PatientVitality[]]) => (
      <div className="no-scrollbar font-paragraph-2 max-w-[400px] overflow-x-scroll">
        <div className={`flex gap-2`}>
          {row[1].map((i: PatientVitality) => (
            <Badge key={i.id} text={returnTypeText(i)} color="gray" upperCase />
          ))}
        </div>
      </div>
    ),
  },
  {
    Header: "Data pobrania",
    width: 30,
    accessor: (row: [string, PatientVitality[]]) => (
      <span>{dayjs(row[0]).format("DD/MM/YYYY")}</span>
    ),
  },
];

type Props = {
  setAddVitalDialog: Dispatch<SetStateAction<boolean>>;
  patient: CurrentPatient;
  setCurrentExamination: Dispatch<SetStateAction<PatientVitality[] | null>>;
  everyExaminationTypeSame: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setExaminationId: Dispatch<SetStateAction<string>>;
  examinationsToShow: CurrentPatient["vitals"];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  finalAnswers:
    | false
    | Record<
        string,
        {
          title: string | number;
          value: string | string[];
        }[]
      >;
};

export const PatientDocsOverview = ({
  setAddVitalDialog,
  examinationsToShow,
  search,
  patient,
  setCurrentExamination,
  everyExaminationTypeSame,
  setOpen,
  setExaminationId,
  setSearch,
  finalAnswers,
}: Props) => {
  const filteredAssignments = patient.messages
    .filter(assignmentsFilter)
    .reverse();

  const {
    data: documentsData,
    loading: documentsLoading,
    networkStatus: documentsNetworkStatus,
  } = useDoctorGetDocumentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: patient.id,
    },
    fetchPolicy: "no-cache",
  });

  const documents = useMemo(() => {
    const docs = documentsData?.doctorPatient?.documents;

    if (!docs) {
      return [];
    }

    return docs
      .filter(({ tagId }) => !tagId)
      .sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1));
  }, [documentsData]);

  const columns = useMemo(
    () => [
      {
        Header: "Typ",
        width: 90,
        accessor: (row: RecommendedExamination) => (
          <span className="whitespace-pre-wrap">
            {procedureTitleMap[row.assignmentType]}
          </span>
        ),
      },
      {
        Header: "Dzień zlecenia",
        width: 100,
        accessor: (row: RecommendedExamination) => (
          <span>{dayjs(row.createdAt.iso).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        Header: "Opłacone",
        width: 50,
        accessor: (row: RecommendedExamination) => (row.paid ? "Tak" : "Nie"),
      },
      {
        id: "Buttons",
        width: 85,
        accessor: (row: RecommendedExamination) => (
          <div className="no-scrollbar flex w-full justify-end gap-2 overflow-x-scroll">
            {["RTG", "USG", "CT"].includes(row.assignmentType) && (
              <Button
                full={false}
                id="send-again-commissioned-research-button-left-panel"
                icon={<MailSendIcon />}
                label="Wyślij ponownie"
                tooltipPosition="top"
                variant="secondary"
                size="condensed"
                onClick={() => sendAssignmentAgain(row.id)}
              />
            )}

            {!row.paid ? (
              <Button
                full={false}
                id="delete-commissioned-research-button-left-panel"
                icon={<BinIcon />}
                label="Usuń"
                tooltipPosition="top"
                variant="negativeGhost"
                size="condensed"
                onClick={() => {
                  setExaminationId(row.id);
                  setOpen(true);
                }}
              />
            ) : (
              <div className="h-8 w-8" />
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const changeExamination = (
    vital: [string, PatientVitality[]],
    vitals: [string, PatientVitality[]][],
  ) => {
    const [, info] = vital;

    const returnVitals = () => {
      if (!everyExaminationTypeSame) {
        return info;
      }

      if (!search.endsWith(" ")) {
        return vitals.flatMap(([, info]) => [...info]);
      }

      return vitals
        .flatMap(([, info]) => [...info])
        .filter((exam) => {
          if (exam.type === "OTHER")
            return (
              exam["customName"].toLowerCase() === search.trim().toLowerCase()
            );
          if (exam.type === "ANALYSIS")
            return (
              exam["analysisType"].toLowerCase() === search.trim().toLowerCase()
            );
          return false;
        });
    };

    const returnedVitals = returnVitals();

    setCurrentExamination(returnedVitals);
  };

  return (
    <div className="relative flex flex-col">
      <TagsBlock tags={patient.tags} />

      <MedicalDocumentationList
        title="Dokumentacja medyczna"
        withUpload
        patientId={patient.id}
        documents={documents}
        loading={
          documentsLoading || documentsNetworkStatus === NetworkStatus.refetch
        }
      />

      <div className="mt-4">
        <BlockWithIcon
          header="Badania"
          icon={
            <div className="absolute right-3 top-1">
              <Button
                onClick={() => setAddVitalDialog(true)}
                size="condensed"
                variant="ghost"
                icon={<AddCircleIcon />}
                label="Dodaj"
                tooltipPosition="top"
                full={false}
              />
            </div>
          }
        >
          {patient.vitals.length > 0 ? (
            <>
              <Input
                placeholder="Rodzaj badania"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sideElement={<SearchIcon size="sm" />}
                sideElementPosition="start"
              />
              {everyExaminationTypeSame && (
                <div className="mb-2">
                  <Badge
                    text="tryb: na przestrzeni czasu"
                    color="green"
                    upperCase
                  />
                </div>
              )}
              <div className="my-4 flex flex-col space-y-3">
                <Table
                  flexLayoutMode
                  columns={examinationsColumns}
                  data={examinationsToShow}
                  onRowClick={(vital, vitals) => {
                    changeExamination(vital, vitals);
                  }}
                />
              </div>
            </>
          ) : (
            <span className="text-warm-gray-400 block text-xs">
              Ten pacjent nie ma żadnych badań w systemie
            </span>
          )}
        </BlockWithIcon>
      </div>
      <div className="mt-4">
        <BlockWithIcon header="Badania zlecone">
          {filteredAssignments.length > 0 ? (
            <div className="my-4 flex flex-col space-y-3">
              <Table
                columns={columns}
                data={filteredAssignments}
                flexLayoutMode
              />
            </div>
          ) : (
            <span className="text-warm-gray-400 block text-xs">
              Ten pacjent nie ma żadnych zleconych badań w systemie
            </span>
          )}
        </BlockWithIcon>
      </div>

      {finalAnswers && (
        <div className="mt-4 h-full w-full">
          <PatientDocsSurvey patient={patient} />
        </div>
      )}
    </div>
  );
};

import { useCallback } from "react";
import { VisitStatus } from "components/new/DialogModal";
import { EventCategoryType, Resource } from "views/Schedule/types";

export const useCalculateStatus = () =>
  useCallback((resource: Resource): VisitStatus => {
    if (resource.categoryId === EventCategoryType.DEFAULT) return "scheduled";
    if (resource.categoryId === EventCategoryType.SHOW_ON) return "reception";
    if (resource.categoryId === EventCategoryType.START_ON) return "inProgress";
    if (resource.categoryId === EventCategoryType.CLOSED) return "completed";
    if (resource.categoryId === EventCategoryType.LEFT_ON) return "patientLeft";
    if (resource.categoryId === EventCategoryType.NO_SHOW)
      return "patientDidNotCome";
    return "scheduled";
  }, []);

import { useAtom } from "jotai";
import { useEffect } from "react";
import { UseFormWatch } from "react-hook-form";
import { ipomFormDataAtom, ipomFormModeAtom } from "components/IpomForm/atoms";
import { IpomFormSchema } from "components/IpomForm/schema";
import { useVisitData } from "views/Visit/hooks";

export const useSyncIpomFormData = (watch: UseFormWatch<IpomFormSchema>) => {
  const [ipomFormMode] = useAtom(ipomFormModeAtom);
  const [, setIpomFormData] = useAtom(ipomFormDataAtom);

  const { data: visitData } = useVisitData({ from: "cache-only" });

  useEffect(() => {
    if (!visitData || ipomFormMode === "preview") {
      return;
    }

    const subscription = watch((value) => {
      setIpomFormData({
        [visitData.id]: value as IpomFormSchema,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, ipomFormMode]);
};

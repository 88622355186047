import { VisitConfirmationForm } from "./components/VisitConfirmationForm";
import { isProductionEnvironment } from "lib/tools";

export const VisitsConfirmation = () => {
  if (isProductionEnvironment()) {
    return null;
  }

  return (
    <div className="mx-auto flex h-full flex-col items-center justify-center gap-16 bg-white px-5 py-10">
      <h1 className="font-display-1">Visits Confirmation</h1>
      <VisitConfirmationForm />
    </div>
  );
};

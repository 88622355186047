import { QueryResult } from "@apollo/client";
import { MessageBox } from "@jutro/ui";
import { VisitCard } from "components/VisitCard";
import { Loader } from "components/new";
import { DoctorUpcomingVisitsQuery, Exact } from "lib/graphql/megaSchema";
import { isVisitScheduled } from "lib/tools/visit";

export const UpcomingVisitsList = ({
  error,
  loading,
  data,
}: Pick<
  QueryResult<
    DoctorUpcomingVisitsQuery,
    Exact<{
      patientId: string;
    }>
  >,
  "error" | "data" | "loading"
>) => {
  if (error) {
    return (
      <div className="text-jutro-new-warm-gray-600 *:font-label *:!text-left">
        <MessageBox
          color="warmGray"
          text="Wystąpił błąd. Nie udało się załadować wizyt."
        />
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  const visits = data?.doctorUpcomingVisits.filter((visit) =>
    isVisitScheduled(visit.plannedStart?.iso),
  );

  if (!visits || visits.length === 0) {
    return (
      <div className="text-jutro-new-warm-gray-600 *:font-label *:!text-left">
        <MessageBox color="warmGray" text="Brak nadchodzących wizyt" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white py-0 pr-2">
      {visits.map((upcomingVisit) => (
        <VisitCard key={upcomingVisit.id} visit={upcomingVisit} />
      ))}
    </div>
  );
};

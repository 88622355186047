import { Actions } from "./Actions";
import { useCallToPatient } from "./useCallToPatient";

export const CallToPatient = () => {
  const {
    isNewMakeCallMode,
    isRecordingCallMode,
    isVisitViewCallButtonDisabled,
    phone,
    sipUserId,
    sipUserPassword,
    sipUserIdRecording,
    sipUserPasswordRecording,
    windowSize,
    isCalling,
    makeNewSipCall,
  } = useCallToPatient();

  const handleMakeCall = () => {
    if (!phone) return;

    if (isNewMakeCallMode) {
      makeNewSipCall({
        phone,
        sipUserId: sipUserId!,
        sipUserPassword: sipUserPassword!,
        windowSizeToCenter: windowSize,
      });
      return;
    }

    if (isRecordingCallMode) {
      makeNewSipCall({
        phone,
        sipUserId: sipUserIdRecording!,
        sipUserPassword: sipUserPasswordRecording!,
        windowSizeToCenter: windowSize,
      });
    }
  };

  return (
    <Actions
      isLoading={isCalling}
      isDisabled={isVisitViewCallButtonDisabled}
      onClick={handleMakeCall}
    />
  );
};

import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { FormBox } from "components/mdx/FormBox";
import { LabelText } from "components/mdx/LabelText";
import { ControlledAsyncSelect } from "components/mdx/MdxInputs/ControlledAsyncSelect";
import { ControlledCheckbox } from "components/mdx/MdxInputs/ControlledCheckbox";
import { ControlledCheckboxGroup } from "components/mdx/MdxInputs/ControlledCheckboxGroup";
import { ControlledInput } from "components/mdx/MdxInputs/ControlledInput";
import { ControlledRadio } from "components/mdx/MdxInputs/ControlledRadio";
import { ControlledTextarea } from "components/mdx/MdxInputs/ControlledTextarea";
import { RoundedWrapper } from "components/new/RoundedWrapper";
import {
  DoctorMSProcedureType,
  DoctorProceduresDocument,
  useDoctorCreateProcedureMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { referralLoadingAtom } from "views/Visit/RightPanel/Referral/atoms";
import { useRenderMdxForm } from "views/Visit/RightPanel/Visit/hooks/useRenderMdxForm";
import { useVisitData } from "views/Visit/hooks";

type Props = {
  type: DoctorMSProcedureType;
  visitId: string;
  patientId: string;
  isValidationRequired?: boolean;
  resetProcedureType: () => void;
};

export const ProcedureSubForm = ({
  type,
  visitId,
  patientId,
  isValidationRequired,
  resetProcedureType,
}: Props) => {
  const [referralLoading, setReferralLoading] = useAtom(referralLoadingAtom);

  const { data: visit } = useVisitData({ from: "cache-only" });

  const { Component, loading, schema, formData } = useRenderMdxForm({
    visitId: visit?.id,
    procedureType: type,
    formType: "create",
  });

  const methods = useForm({
    mode: "onChange",
    resolver: isValidationRequired ? ajvResolver(schema) : undefined,
  });

  const { formState, reset, handleSubmit } = methods;

  useEffect(() => {
    reset({});
  }, [type, Component]);

  useEffect(() => {
    if (!formData) {
      return;
    }

    Object.entries(formData).forEach(([key, value]) => {
      //@ts-ignore
      setValue(key, value, { shouldDirty: false });
    });
  }, [type, formData]);

  const [createProcedureMutation] = useDoctorCreateProcedureMutation({
    refetchQueries: [DoctorProceduresDocument],
    awaitRefetchQueries: true,
  });

  const createProcedure = (dataInput: FieldValues) => {
    const data = {
      createdOnVisitId: visitId,
      type,
      patientId,
    };

    if (type === "TUBERCULOSIS_EDUCATION") {
      return createProcedureMutation({
        variables: {
          data,
          dataInput: {
            tuberculosisEducation: {
              score: 0,
              riskGroup: "NO_RISK",
              telemedicalConsultation: false,
              patientEducated: false,
            },
          },
        },
      });
    }

    return createProcedureMutation({
      variables: {
        data,
        dataInput,
      },
    });
  };

  const onSubmit = handleSubmit(async (input) => {
    setReferralLoading(true);
    const createProcedurePromise = createProcedure(input);

    await toaster.promise(createProcedurePromise, {
      success: "Poprawnie dodano nową procedurę",
      loading: "Dodaję procedurę",
      error: () => {
        setReferralLoading(false);

        return "Nie udało się dodać procedury";
      },
    });

    resetProcedureType();
    reset({});

    setReferralLoading(false);
  });

  if (loading) {
    return <div className="font-paragraph-2">Ładowanie...</div>;
  }

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-2" onSubmit={onSubmit}>
        {Component && (
          <Component.Content
            components={{
              FormBox,
              RoundedWrapper,
              LabelText,
              ControlledAsyncSelect,
              ControlledInput,
              ControlledRadio,
              ControlledCheckbox,
              ControlledCheckboxGroup,
              ControlledTextarea,
            }}
            disabled={referralLoading}
          />
        )}
        <div className="flex justify-end">
          <Button
            full={false}
            disabled={
              Component
                ? !formState.isValid || formState.isSubmitted || referralLoading
                : referralLoading
            }
            text="Utwórz procedurę"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
};

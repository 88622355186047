import {
  DoctorMSAppointmentStatus,
  DoctorMSSentNoShowNotifications,
  DoctorScheduledVisitsDocument,
  useDoctorUpdateVisitMutation,
} from "lib/graphql/megaSchema";

type SmsVisitConfirmation = "WAITING_FOR_SMS" | "SMS_CONFIRMED";

export type ConfirmationStatus =
  | DoctorMSAppointmentStatus
  | SmsVisitConfirmation;

const calculateCurrentAppointmentStatus = (
  currentAppointmentStatus: DoctorMSAppointmentStatus,
  sentNoShowNotifications?: DoctorMSSentNoShowNotifications | null,
): ConfirmationStatus => {
  if (sentNoShowNotifications) {
    if (sentNoShowNotifications.answer?.value === true) {
      return "SMS_CONFIRMED";
    }

    if (currentAppointmentStatus === "CONFIRMED") {
      return "CONFIRMED";
    }

    return "WAITING_FOR_SMS";
  }

  return currentAppointmentStatus as ConfirmationStatus;
};

export const useAppointmentStatus = (
  visitId: string,
  currentAppointmentStatus: DoctorMSAppointmentStatus,
  sentNoShowNotifications?: DoctorMSSentNoShowNotifications | null,
) => {
  const calculatedAppointmentStatus = calculateCurrentAppointmentStatus(
    currentAppointmentStatus,
    sentNoShowNotifications,
  );

  const [updateVisit] = useDoctorUpdateVisitMutation({
    refetchQueries: [DoctorScheduledVisitsDocument],
  });

  const updateVisitAppointmentStatus = async (
    appointmentStatus: DoctorMSAppointmentStatus,
  ) => {
    await updateVisit({
      variables: {
        id: visitId,
        input: { appointmentStatus },
      },
      refetchQueries: [DoctorScheduledVisitsDocument],
    });
  };

  const handleAppointmentStatusChange = async () => {
    if (sentNoShowNotifications?.answer?.value === true) {
      return;
    }

    if (sentNoShowNotifications) {
      if (currentAppointmentStatus === "CONFIRMED") {
        await updateVisitAppointmentStatus("EMPTY");
        return;
      }

      await updateVisitAppointmentStatus("CONFIRMED");
      return;
    }

    if (currentAppointmentStatus === "CONFIRMED") {
      await updateVisitAppointmentStatus("EMPTY");
      return;
    }

    if (currentAppointmentStatus === "INDETERMINATE") {
      await updateVisitAppointmentStatus("CONFIRMED");
      return;
    }

    await updateVisitAppointmentStatus("INDETERMINATE");
  };

  return {
    appointmentStatus: calculatedAppointmentStatus,
    handleAppointmentStatusChange,
  };
};

import { useAtom } from "jotai";
import { FileWithPath } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  DoctorMSDeclarationGenre,
  DoctorMSDeclarationMarketingSource,
  DoctorMSDeclarationSubmittedEvent,
  DoctorMSDeclarationType,
  DoctorMSOrganizationIdVojevodship,
  useDoctorAddDeclarationScanToNFZEventMutation,
} from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";
import { usePatient } from "./Patient.hooks";
import { patientDeclarationAtom } from "./atoms";

type DeclarationEditFormFields = {
  genre: DoctorMSDeclarationGenre;
  organizationId: DoctorMSOrganizationIdVojevodship;
  type: {
    form: DoctorMSDeclarationType;
    source: DoctorMSDeclarationMarketingSource;
  };
  npwz: string;
  files: FileWithPath[];
  howManyProviderChanges: DoctorMSDeclarationSubmittedEvent;
  facilityChangeReason: DoctorMSDeclarationSubmittedEvent;
  date: Date;
  fileId: string;
  comment?: string;
};

export const useDeclarationEditForm = () => {
  const { patientId } = useParams<{ patientId: string }>();

  if (!patientId) {
    throw Error("Brak id pacjenta");
  }

  const { patient } = usePatient(patientId);

  const { addTile } = useTileSystem();

  const [patientDeclaration] = useAtom(patientDeclarationAtom);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<DeclarationEditFormFields>();

  const [addDeclarationFile] = useDoctorAddDeclarationScanToNFZEventMutation();

  const addNewDeclarationFile = handleSubmit(async ({ comment, files }) => {
    if (!patientId) {
      toaster.error("Wystąpił błąd");
      return;
    }

    if (!patientDeclaration) {
      toaster.error("Wystąpił błąd");
      return;
    }

    const fileIds = await Promise.all(
      files.map(async (file) => {
        return await uploadFile(file);
      }),
    );

    const createAddDeclarationFilePromise = addDeclarationFile({
      variables: {
        declarationScanFileId: fileIds[0],
        eventId: patientDeclaration.id,
        comment: comment ?? "",
      },
    });

    await toaster.promise(createAddDeclarationFilePromise, {
      success: "Dodano plik z deklaracją",
      loading: "Dodaję plik z deklaracją",
      error: "Wystąpił błąd",
    });

    reset();
    addTile("patient-declaration-history", TileColumn.Right);
  });

  return {
    control,
    isSubmitting,
    isValid,
    addNewDeclarationFile,
    patientFullName: `${patient?.firstName} ${patient?.lastName}`,
  };
};

import { useParams } from "react-router-dom";
import { useVisitData } from "views/Visit/hooks";

export const useGetPatientId = () => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });
  const { patientId: patientIdParam } = useParams<{ patientId: string }>();

  const patientId = patientIdParam ?? currentVisit?.patient?.id;

  if (!patientId) return null;

  return patientId;
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "@jutro/ui";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { ErrorMessage } from "components/TagFormNew/ErrorMessage";
import { useDoctorSendMfaSmsCodeLazyQuery } from "lib/graphql/megaSchema";

const sendSmsSchema = z.object({
  phone: z
    .string()
    .length(9, { message: "Numer telefonu musi mieć dokładnie 9 cyfr." }),
  password: z.string().min(1, { message: "Hasło jest wymagane." }),
});

type LoginSchemaType = z.infer<typeof sendSmsSchema>;

type Props = {
  setPhone: Dispatch<SetStateAction<string>>;
};

export const SendSms = ({ setPhone }: Props) => {
  const [showUnableToLoginError, setShowUnableToLoginError] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const { control, handleSubmit } = useForm<LoginSchemaType>({
    resolver: zodResolver(sendSmsSchema),
    defaultValues: {
      phone: "",
      password: "",
    },
  });

  const [sendMfaSmsCode, { loading }] = useDoctorSendMfaSmsCodeLazyQuery({
    fetchPolicy: "network-only",
  });

  const submitSms = handleSubmit(async (formData) => {
    const { data, error } = await sendMfaSmsCode({
      variables: formData,
    });

    if (!data || error) {
      setShowUnableToLoginError(true);
      return;
    }

    const { phone } = formData;

    setPhone(phone);
  });

  useEffect(() => {
    if (!showUnableToLoginError) {
      return;
    }

    const timeout = setTimeout(() => setShowUnableToLoginError(false), 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showUnableToLoginError]);

  return (
    <form onSubmit={submitSms}>
      <div className="my-4 flex flex-col gap-4 rounded-lg bg-white p-8">
        <Controller
          control={control}
          name="phone"
          render={({ field, fieldState: { error } }) => (
            <Input
              maxLength={9}
              {...field}
              height="md"
              error={error?.message}
              placeholder="Twój numer telefonu"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              error={error?.message}
              placeholder="Hasło"
              height="md"
              type={isPasswordShown ? "text" : "password"}
              sideElement={
                <Button
                  full={false}
                  variant="ghost"
                  size="condensed"
                  type="button"
                  text={isPasswordShown ? "UKRYJ" : "POKAŻ"}
                  onClick={() => setIsPasswordShown((prev) => !prev)}
                />
              }
              sideElementPosition="end"
            />
          )}
        />
        {showUnableToLoginError && (
          <ErrorMessage text="Nie udało się zalogować, sprawdź poprawność danych" />
        )}
      </div>

      <Button type="submit" text="Zaloguj" loading={loading} />
    </form>
  );
};

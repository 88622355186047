import * as Sentry from "@sentry/react";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { currentUserAtom } from "lib/atoms/auth";

export const useIdentifySentry = () => {
  const [currentUser] = useAtom(currentUserAtom);

  useEffect(() => {
    if (!currentUser) return;
    if (envVars.REACT_APP_ENVIRONMENT !== "PROD") return;

    Sentry.setTag("user.id", currentUser.id);
  }, [currentUser]);
};

import { PropsWithChildren } from "react";

type InputWrapperProps = {
  header: string;
};

export const InputWrapper = ({
  header,
  children,
}: PropsWithChildren<InputWrapperProps>) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <header className="font-paragraph-2">{header}</header>
      {children}
    </div>
  );
};

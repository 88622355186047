import { Block } from "components/components/Doctor/Block";

export const NoPESELSection = () => {
  return (
    <Block title="Brak PESEL">
      <div className="font-display-3 text-red-600">
        PACJENT BEZ NUMERU PESEL!
        <br />
        <span className="font-heading-1">
          E-DOKUMENTY WYSTAWIAMY W GABINET.GOV
        </span>
      </div>
    </Block>
  );
};

import { useAtom } from "jotai";
import { equals } from "ramda";
import { useEffect, useRef, useState } from "react";
import { ipomFormModeAtom } from "components/IpomForm/atoms";
import {
  getContentByStatus,
  getIpomDates,
  getIpomStatus,
} from "components/mdx/BannerIpom/tools";
import { useDoctorGetIpomQuery } from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { useVisitData } from "views/Visit/hooks";

export const useIpomBanner = () => {
  const [show, setShow] = useState(true);
  const [, setIpomFormMode] = useAtom(ipomFormModeAtom);

  const { addTile, getTileInfo } = useTileSystem();

  const { data: visit } = useVisitData({ from: "cache-only" });

  const isStandardVisit = visit?.type === "STANDARD";

  const patientId = visit?.patient?.id ?? "";
  const isVisitQualifiedToIpom = Boolean(visit?.determineIpomEligibility);

  const doctorDiagnosisRef = useRef(visit?.doctorDiagnosis);

  const ipomData = useDoctorGetIpomQuery({
    skip: !patientId,
    variables: {
      patientId,
    },
  });

  const ipom = ipomData.data?.doctorGetIpom ?? null;
  const ipomDate = ipom?.date;

  const status = getIpomStatus({
    isVisitQualifiedToIpom,
    ipomDate,
  });

  const { ipomExpiredDate } = getIpomDates(ipomDate);

  const bannerContent = getContentByStatus({
    status,
    expiredDate: ipomExpiredDate?.format("DD-MM-YYYY"),
    visitStatus: visit?.status,
  });

  const handleClick = () => {
    if (getTileInfo("ipom-form").position) return;

    if (status === "available") {
      setIpomFormMode("create");
    } else {
      setIpomFormMode("preview");
    }

    addTile("ipom-form", TileColumn.Center);
  };

  useEffect(() => {
    if (equals(doctorDiagnosisRef.current, visit?.doctorDiagnosis)) {
      return;
    }

    doctorDiagnosisRef.current = visit?.doctorDiagnosis;
    setShow(true);
  }, [visit]);

  return { bannerContent, handleClick, show, setShow, isStandardVisit };
};

import {
  useDoctorClosePatientMutation,
  useDoctorUnpinPatientMutation,
  useDoctorWaitingForPatientMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";

export const usePatientActivityActions = (patientId: string) => {
  const defaultOnError = () => {
    toaster.warning("Coś poszło nie tak");
  };

  const patientActionsVariables = { variables: { id: patientId } };

  const [unpinPatientMutation] = useDoctorUnpinPatientMutation(
    patientActionsVariables,
  );

  const [closePatientMutation] = useDoctorClosePatientMutation(
    patientActionsVariables,
  );

  const [waitingForPatientMutation] = useDoctorWaitingForPatientMutation(
    patientActionsVariables,
  );

  const unpinPatient = (onCompleted = () => {}, onError = defaultOnError) => {
    unpinPatientMutation()
      .then(() => {
        onCompleted();
      })
      .catch(() => {
        onError();
      });
  };

  const closePatient = (onCompleted = () => {}, onError = defaultOnError) => {
    closePatientMutation()
      .then(() => {
        onCompleted();
      })
      .catch(() => {
        onError();
      });
  };

  const waitingForPatient = (
    onCompleted = () => {},
    onError = defaultOnError,
  ) => {
    waitingForPatientMutation()
      .then(() => {
        onCompleted();
      })
      .catch(() => {
        onError();
      });
  };

  return {
    closePatient,
    waitingForPatient,
    unpinPatient,
  };
};

import { useAtom } from "jotai";
import { useEffect } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import { useDoctorMeQuery } from "lib/graphql/megaSchema";

export const useLoadCurrentUserData = () => {
  const [, setCurrentUser] = useAtom(currentUserAtom);
  const { data, error } = useDoctorMeQuery({ fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!data?.doctorMe.id) return;
    setCurrentUser(data.doctorMe);
  }, [data, setCurrentUser]);

  if (error) {
    console.error("error fetching current user: ", error);
  }
};

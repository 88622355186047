import { PropsWithChildren } from "react";

type Props = {
  isLightBorderColor?: boolean;
};

export const RoundedWrapper = ({
  children,
  isLightBorderColor,
}: PropsWithChildren<Props>) => {
  const borderColor = isLightBorderColor
    ? "border-jutro-new-warm-gray-100"
    : "border-jutro-new-warm-gray-300";

  return (
    <div className={`flex flex-col gap-4 rounded-lg border p-2 ${borderColor}`}>
      {children}
    </div>
  );
};

import {
  DoctorMSProcedureEntity,
  DoctorMSProcedureUsg,
  Maybe,
} from "lib/graphql/megaSchema";

type Props = { data: DoctorMSProcedureEntity | undefined };

export const UsgHeaderSection = ({ data }: Props) => {
  const getUsgProcedureComment = (
    data: Maybe<DoctorMSProcedureUsg> | undefined,
  ) => {
    if (data?.referral) {
      if (data.referral.comment === "") {
        return "Brak komentarza";
      }

      return data.referral.comment;
    }

    return data?.comment ?? "Brak komentarza";
  };

  return (
    <div className="flex flex-col gap-2">
      <h4 className="font-paragraph-2 text-jutro-new-warm-gray-800">
        Skierowanie
      </h4>
      <div className="border-jutro-warm-gray-200 flex flex-col gap-2 rounded-lg border-[1px] p-4">
        <div>
          <h6 className="font-paragraph-1">
            Rozpoznanie główne: {data?.usg?.referral?.mainDiagnosis}
          </h6>
        </div>
        <div>
          <h6 className="font-paragraph-1">
            Rodzaj badania:{" "}
            {data?.usg?.referral
              ? data.usg.referral.procedureName
              : data?.usg?.service?.name}
          </h6>
        </div>
        <div>
          <h6 className="font-paragraph-1">
            Komentarz: {getUsgProcedureComment(data?.usg)}
          </h6>
        </div>
      </div>
    </div>
  );
};

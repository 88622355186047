import { ApolloProvider } from "@apollo/client";
import { createRoot } from "react-dom/client";
import { createApolloClient } from "lib/tools/createApolloClient";
import { App } from "./App";
import "./index.css";

const container = document.getElementById("root");

if (!container) {
  throw new Error("The root element was not found!");
}

const client = createApolloClient();

const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
);

import { Button, CallIcon } from "@jutro/ui";
import { useCallToPatient } from "components/components/CallToPatient/useCallToPatient";
import { Loader } from "components/new";

type Props = {
  patientId: string | undefined;
  phone: string | undefined;
  visitRecordingAndAnalysisAgreement: boolean;
  disabled?: boolean;
};

export const MakeCallButton = ({
  patientId,
  phone,
  visitRecordingAndAnalysisAgreement,
  disabled = false,
}: Props) => {
  const {
    isCurrentUserSipDataFilled,
    isNewMakeCallMode,
    isRecordingCallMode,
    sipUserId,
    sipUserPassword,
    sipUserIdRecording,
    sipUserPasswordRecording,
    windowSize,
    isCalling,
    makeNewSipCall,
  } = useCallToPatient(visitRecordingAndAnalysisAgreement);

  const callButtonIsDisabled =
    isCurrentUserSipDataFilled || disabled || !patientId;

  if (!patientId) {
    return null;
  }

  if (isCalling) {
    return (
      <div className="flex items-center justify-center">
        <Loader size="xs" />
      </div>
    );
  }

  return (
    <Button
      full={false}
      icon={<CallIcon />}
      text={phone?.match(/.{1,3}/g)?.join(" ") ?? ""}
      size="condensed"
      variant="secondary"
      disabled={callButtonIsDisabled}
      onClick={(e) => {
        e.stopPropagation();

        if (isNewMakeCallMode) {
          makeNewSipCall({
            phone: phone ?? "",
            sipUserId: sipUserId!,
            sipUserPassword: sipUserPassword!,
            windowSizeToCenter: windowSize,
          });

          return;
        }

        if (isRecordingCallMode) {
          makeNewSipCall({
            phone: phone ?? "",
            sipUserId: sipUserIdRecording!,
            sipUserPassword: sipUserPasswordRecording!,
            windowSizeToCenter: windowSize,
          });
        }
      }}
    />
  );
};

import dayjs from "dayjs";
import { TagForm } from "components/TagFormNew/types";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";
import { Maybe } from "lib/types";

export type Props = {
  tagForm: TagForm;
};

export const HistorySection = ({ tagForm }: Props) => {
  const { tag } = tagForm;

  if (!tag || !tag.history) {
    return null;
  }

  const { history, createdBy, createdAt } = tag;

  const mostRecentEdit = history.at(-1);

  return (
    <div className="flex flex-col gap-2">
      <HistoryElement
        text="Utworzone przez"
        firstName={createdBy?.firstName}
        lastName={createdBy?.lastName}
        iso={createdAt?.iso}
      />
      {history.length > 1 && (
        <HistoryElement
          text="Ostatnio edytowane przez"
          firstName={mostRecentEdit?.user.firstName}
          lastName={mostRecentEdit?.user.lastName}
          iso={mostRecentEdit?.when.iso}
        />
      )}
    </div>
  );
};

type HistoryElementProps = {
  text: string;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  iso: Maybe<string>;
};

export const HistoryElement = ({
  text,
  firstName,
  lastName,
  iso,
}: HistoryElementProps) => {
  return (
    <div className="flex justify-between">
      <div className="font-paragraph-2">
        <span className="font-medium">{text}: </span>
        <span className="font-bold">
          {getDisplayDoctor(firstName, lastName, "-")}
        </span>
      </div>
      {iso && (
        <span className="font-paragraph-2 ml-auto text-jutro-new-warm-gray-400">
          {dayjs(iso).format("YYYY-MM-DD HH:mm")}
        </span>
      )}
    </div>
  );
};

import { clone, equals } from "ramda";
import { useEffect, useMemo, useRef } from "react";
import { FieldValues } from "react-hook-form";
import { InterviewData } from "views/Visit/RightPanel/Visit/types";

export const useCheckFormWritingStatus = (formData: InterviewData) => {
  const recentlySynchronizedDataRef = useRef<FieldValues | null>(null);

  const isSynchronized = useMemo(
    () =>
      recentlySynchronizedDataRef.current
        ? equals(formData, recentlySynchronizedDataRef.current)
        : true,
    [formData, recentlySynchronizedDataRef.current],
  );

  useEffect(() => {
    if (equals(formData, recentlySynchronizedDataRef.current)) {
      return;
    }

    recentlySynchronizedDataRef.current = clone(formData);
  }, [formData]);

  return { isWriting: !isSynchronized };
};

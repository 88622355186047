import {
  BinIcon,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  CircleCheckIcon,
  DuplicateIcon,
  PencilIcon,
  SolidCircleCheckIcon,
  Tag,
  Tooltip,
} from "@jutro/ui";
import { useAtom } from "jotai";
import { throttle } from "radash";
import { useEffect, useMemo, useState } from "react";
import {
  keepCurrentControlsAtom,
  selectedSnippetsAtom,
  snippetListShowContentAtom,
} from "components/Snippet/SnippetListTile/atoms";
import {
  getSnippetTypeLabel,
  snippetCategoryLabelMap,
} from "components/Snippet/tools";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { currentUserAtom } from "lib/atoms/auth";
import {
  EdmGetAllSnippetsDocument,
  EdmSnippet,
  useDoctorGetDoctorsQuery,
  useEdmDeleteSnippetByIdMutation,
  useEdmIncreaseSnippetCounterMutation,
} from "lib/graphql/megaSchema";
import { isDataTile } from "lib/tools/createWorkspace/tools";
import { TileColumn } from "lib/tools/createWorkspace/types";
import {
  getCurrentWorkspace,
  useTileSystem,
} from "lib/tools/createWorkspace/useTileSystem";
import { useEvent } from "lib/tools/events";
import { toaster } from "lib/tools/toaster";

type Props = {
  snippet: EdmSnippet;
  mode: "admin" | "settings";
};

export const SnippetCard = ({ snippet, mode }: Props) => {
  const { data: doctorsData } = useDoctorGetDoctorsQuery({
    fetchPolicy: "cache-only",
  });
  const [currentUser] = useAtom(currentUserAtom);
  const [, setKeepCurrentControls] = useAtom(keepCurrentControlsAtom);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deleteSnippet, { loading }] = useEdmDeleteSnippetByIdMutation({
    variables: { id: snippet.id },
    refetchQueries: [EdmGetAllSnippetsDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      sendEvent("SNIPPET_DELETED", { snippet_id: snippet.id });
    },
  });
  const [incrementCounter] = useEdmIncreaseSnippetCounterMutation({
    refetchQueries: [EdmGetAllSnippetsDocument],
    variables: { id: snippet.id },
  });
  const doctor = doctorsData?.doctorDoctors.find(
    (d) => d.id === snippet.createdBy,
  );
  const { addTile, visibleTiles, getTileInfo } = useTileSystem();
  const [snippetListShowContent] = useAtom(snippetListShowContentAtom);

  const [selectedSnippets, setSelectedSnippets] = useAtom(selectedSnippetsAtom);
  const isSelected = selectedSnippets.includes(snippet.id);
  const isInEdit = visibleTiles.find((tile) =>
    isDataTile(tile) ? tile.id === snippet.id : false,
  );

  const sendEvent = useEvent();

  const onCopy = useMemo(
    () =>
      throttle({ interval: 2000 }, () => {
        navigator.clipboard.writeText(snippet.content);
        toaster.notify("Skopiowano szablon do schowka");
        sendEvent("SNIPPET_USED", { snippet_id: snippet.id });
        incrementCounter();
      }),
    [snippet.id],
  );

  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    setShowContent(snippetListShowContent);
  }, [snippetListShowContent]);

  return (
    <>
      <div
        onClick={onCopy}
        className={`flex cursor-pointer justify-between rounded-lg transition-colors duration-200 ${isSelected ? "bg-jutro-new-rose-100 hover:bg-jutro-new-rose-200" : isInEdit ? "bg-jutro-new-blue-100 hover:bg-jutro-new-blue-200" : "bg-jutro-new-warm-gray-50 hover:bg-jutro-new-warm-gray-100"} p-4`}
      >
        <div className="flex w-full flex-col gap-3">
          <div className="flex justify-between">
            <div className="font-label">{snippet.name}</div>

            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="condensed"
                icon={<DuplicateIcon />}
                label="Kopiuj szablon"
                withTooltip
                onClick={(event) => {
                  event.stopPropagation();
                  onCopy();
                }}
                full={false}
                type="button"
                loading={loading}
                tooltipPosition="bottom"
              />

              {(snippet.createdBy === currentUser?.id || mode === "admin") && (
                <Button
                  variant="ghost"
                  size="condensed"
                  icon={<PencilIcon />}
                  label="Edytuj szablon"
                  withTooltip
                  full={false}
                  loading={loading}
                  tooltipPosition="bottom"
                  onClick={(event) => {
                    event.stopPropagation();

                    setKeepCurrentControls(true);

                    const currentPosition =
                      getTileInfo("snippet-settings").position;

                    const currentWorkspace = getCurrentWorkspace();

                    addTile(
                      {
                        id: snippet.id,
                        name: "snippet-form",
                        data: {
                          id: snippet.id,
                          mode: "update",
                          snippetData: snippet,
                        },
                      },
                      mode === "settings" &&
                        currentWorkspace !== "settings" &&
                        currentPosition !== null
                        ? currentPosition
                        : TileColumn.Right,
                    );
                  }}
                />
              )}

              {(snippet.createdBy === currentUser?.id || mode === "admin") && (
                <Button
                  variant="negativeGhost"
                  size="condensed"
                  icon={<BinIcon />}
                  label="Usuń szablon"
                  withTooltip
                  full={false}
                  loading={loading}
                  tooltipPosition="bottom"
                  onClick={(event) => {
                    event.stopPropagation();

                    setConfirmDeleteDialogOpen(true);
                  }}
                />
              )}

              {mode === "admin" && (
                <Button
                  variant="negativeGhost"
                  size="condensed"
                  icon={
                    isSelected ? <SolidCircleCheckIcon /> : <CircleCheckIcon />
                  }
                  label={
                    isSelected
                      ? "Cofnij zaznaczenie szablonu"
                      : "Zaznacz szablon"
                  }
                  withTooltip
                  full={false}
                  loading={loading}
                  tooltipPosition="bottom"
                  onClick={(event) => {
                    event.stopPropagation();

                    if (isSelected) {
                      setSelectedSnippets((prev) =>
                        prev.filter((id) => id !== snippet.id),
                      );
                      return;
                    }
                    setSelectedSnippets((prev) => [...prev, snippet.id]);
                  }}
                />
              )}

              {!snippetListShowContent && (
                <Button
                  variant="ghost"
                  size="condensed"
                  icon={showContent ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  label={
                    showContent
                      ? "Schowaj treść szablonu"
                      : "Pokaż treść szablonu"
                  }
                  withTooltip
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowContent((p) => !p);
                  }}
                  full={false}
                  type="button"
                  loading={loading}
                  tooltipPosition="bottom"
                />
              )}

              <Tooltip
                content="Liczba użyć"
                position="bottom"
                trigger={
                  <span className="font-paragraph-2 flex flex-col justify-center">
                    <Tag text={snippet.counter.toString()} color="blue" />
                  </span>
                }
              />
            </div>
          </div>

          {mode === "admin" && (
            <div className="font-caption flex gap-4">
              <div>
                <b>Id</b>: {snippet.id}
              </div>
              <div>
                <b>Właściciel</b>:{" "}
                {doctor ? (
                  <>
                    {doctor.firstName} {doctor.lastName}
                  </>
                ) : (
                  "brak"
                )}
              </div>
              <div>
                <b>Widoczność</b>:{" "}
                {snippet.visibility === "PUBLIC" ? "Publiczny" : "Prywatny"}
              </div>
              <div>
                <b>Typ</b>: {getSnippetTypeLabel(snippet.type)}
              </div>
              {snippet.category && (
                <div>
                  <b>Kategoria</b>:{" "}
                  {
                    snippetCategoryLabelMap[
                      snippet.category as keyof typeof snippetCategoryLabelMap
                    ]
                  }
                </div>
              )}
            </div>
          )}

          {showContent && (
            <>
              {snippet.tags.length > 0 && (
                <div className="flex flex-wrap gap-1.5">
                  {snippet.tags.map((tag) => (
                    <Tag key={tag.id} color="blue" text={tag.name} />
                  ))}
                </div>
              )}

              <div className="font-paragraph-1 whitespace-pre-wrap">
                {snippet.content}
              </div>
            </>
          )}
        </div>
      </div>

      <ConfirmDialog
        open={confirmDeleteDialogOpen}
        setOpen={setConfirmDeleteDialogOpen}
        onConfirm={deleteSnippet}
        loading={loading}
        confirmText="Usuń"
        text="Czy na pewno chcesz usunąć ten szablon?"
      />
    </>
  );
};

import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { OrderedTestTooltip } from "components/PatientDocs/Ordered/Element/OrderedTestTooltip";
import { useHandleTestsCheckboxes } from "components/PatientDocs/Ordered/hooks/useHandleTestsCheckboxes";
import { OrderedTestListItem } from "components/PatientDocs/Ordered/types";
import { getLabelText } from "components/PatientDocs/Ordered/utils";
import { Checkbox } from "components/new";

type BaseProps = {
  index: number;
  mode: "laboratoryExamination" | "procedure";
  draggable?: boolean;
};

type Props = BaseProps & { data: OrderedTestListItem };

export const OrderedTestElement = ({
  data,
  index,
  mode,
  draggable = false,
}: Props) => {
  const { handleChange } = useHandleTestsCheckboxes();
  const label = getLabelText(data);

  const badgeLabel =
    mode === "laboratoryExamination"
      ? "Badanie laboratoryjne"
      : "Procedura medyczna";

  const badgeColorStyles =
    mode === "laboratoryExamination"
      ? "bg-jutro-new-warm-gray-100"
      : "bg-jutro-new-warm-gray-800 text-jutro-new-warm-gray-100";

  const Badge = () => (
    <div
      className={`flex min-w-0 items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-sm px-2 ${badgeColorStyles}`}
    >
      <span className="font-paragraph-2">{badgeLabel}</span>
    </div>
  );

  const TestContent = ({ provided }: { provided?: DraggableProvided }) => (
    <div
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      ref={provided?.innerRef}
      className="checkbox-checked flex select-none flex-col gap-2 rounded-lg border-[1px] border-jutro-new-warm-gray-200 p-2 hover:bg-jutro-new-warm-gray-50 active:bg-white"
    >
      <div className="flex items-center justify-between">
        <div className="mr-2 min-w-0 overflow-hidden">
          <Checkbox
            label={label}
            value={data.checked}
            onChange={() => handleChange(data.id)}
          />
        </div>

        <div className="flex items-center gap-2">
          <Badge />
          <OrderedTestTooltip data={data} />
        </div>
      </div>
    </div>
  );

  return draggable ? (
    <Draggable draggableId={data.id.toString()} index={index}>
      {(provided) => <TestContent provided={provided} />}
    </Draggable>
  ) : (
    <TestContent />
  );
};

import { Button } from "@jutro/ui";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { EmptyPrescriptionList } from "components/PatientDocs/Prescriptions/EmptyPrescriptionList";
import { PrescriptionDrug } from "components/PatientDocs/Prescriptions/PrescriptionDrug";
import { PrescriptionHeader } from "components/PatientDocs/Prescriptions/PrescriptionHeader";
import { PrescriptionInfoAlert } from "components/PatientDocs/Prescriptions/PrescriptionInfoAlert";
import {
  getDrugBoxAmount,
  getDrugDose,
  getDrugForm,
  getDrugName,
} from "components/PatientDocs/Prescriptions/tools";
import { Loader, Scrollbar } from "components/new";
import {
  DoctorGetP1PrescriptionsQuery,
  useDoctorCheckPrescriptionsCEZLazyQuery,
  useDoctorCreateChatMessageMutation,
  useDoctorGetP1PrescriptionsQuery,
} from "lib/graphql/megaSchema";
import { useVisitData } from "views/Visit/hooks";

type Props = {
  searchText: string;
};

export const GabinetGovPrescriptionsTab = ({ searchText }: Props) => {
  const { data: visit, loading: visitLoading } = useVisitData({
    from: "cache-only",
  });

  const { patientId } = useParams();

  const variables = visit ? { visitId: visit.id } : { patientId };

  const {
    data,
    error,
    refetch: refetchPrescriptions,
    loading,
  } = useDoctorGetP1PrescriptionsQuery({
    variables,
    skip: !variables.patientId && !variables.visitId,
  });

  const [checkPrescriptionsCEZ, { loading: checkPrescriptionsCEZLoading }] =
    useDoctorCheckPrescriptionsCEZLazyQuery({
      onCompleted: () => refetchPrescriptions(),
      nextFetchPolicy: "no-cache",
    });

  const [sendRecommendation] = useDoctorCreateChatMessageMutation({
    variables: {
      id: visit?.patient?.id ?? "",
      input: {
        content:
          "Link do instrukcji: <b>Jak udostępnić dane o receptach przychodni Jutro Medical?</b><br/><br/><u>https://jutromedical.com/faq#46</u>",
      },
    },
  });

  if (visitLoading || loading) return <Loader />;

  const lastDownload = data?.doctorGetP1Prescriptions.lastChecked
    ? dayjs(data.doctorGetP1Prescriptions.lastChecked).format(
        "YYYY-MM-DD HH:mm",
      )
    : "brak";

  const prescriptionsData = data?.doctorGetP1Prescriptions;

  const shouldDisplayPrescriptionAlert =
    error ||
    (prescriptionsData && prescriptionsData.prescriptions.length === 0);

  const searchPrescriptions = (
    prescription: DoctorGetP1PrescriptionsQuery["doctorGetP1Prescriptions"]["prescriptions"][number],
  ) =>
    (prescription.drugName ?? "")
      .toLowerCase()
      .includes(searchText.toLowerCase());

  const prescriptions =
    prescriptionsData?.prescriptions.filter(searchPrescriptions);

  return (
    <Scrollbar>
      <div className="flex h-full flex-col justify-between gap-4 overflow-scroll">
        {prescriptions && prescriptions.length > 0 ? (
          prescriptions.map((prescription) => (
            <div
              key={prescription.prescriptionKey}
              className="flex flex-col gap-2 rounded-lg bg-jutro-new-warm-gray-50 p-4"
            >
              <div className="flex flex-col gap-2">
                <PrescriptionHeader
                  visitId={visit?.id}
                  content={{
                    issueDate: prescription.issueDate,
                  }}
                />
                <PrescriptionDrug
                  drug={{
                    cost: prescription.prescriptionRepaymentLevel ?? "",
                    drug: getDrugName(prescription.drugName ?? ""),
                    package: prescription.packageSize,
                    status: prescription.prescriptionStatus,
                    dose: getDrugDose(prescription.drugName ?? ""),
                    form: getDrugForm(prescription.drugName ?? ""),
                    boxAmount: getDrugBoxAmount(prescription.drugName ?? ""),
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <EmptyPrescriptionList />
        )}

        {shouldDisplayPrescriptionAlert && (
          <PrescriptionInfoAlert
            isVisitInprogress={visit?.status === "INPROGRESS"}
            sendRecommendation={
              data?.doctorGetP1Prescriptions.lastChecked
                ? sendRecommendation
                : null
            }
          />
        )}

        <div className="sticky bottom-0 flex flex-col gap-2 rounded-lg bg-white p-4">
          <Button
            text="Pobierz listę recept"
            disabled={visit?.status !== "INPROGRESS"}
            loading={checkPrescriptionsCEZLoading}
            onClick={() =>
              checkPrescriptionsCEZ({
                variables: { visitId: visit!.id },
              })
            }
          />

          <div className="font-paragraph-2 text-center">
            Data ostatniego pobrania:{" "}
            <span className="font-bold">{lastDownload}</span>
          </div>
        </div>
      </div>
    </Scrollbar>
  );
};

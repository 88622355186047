import { Option } from "@jutro/types";
import { Input, SearchIcon, Tabs } from "@jutro/ui";
import { useState } from "react";
import { GabinetGovPrescriptionsTab } from "components/PatientDocs/Prescriptions/GabinetGovPrescriptionTab";
import { Source } from "components/PatientDocs/Prescriptions/types";
import { Loader } from "components/new";
import { DoctorPatientProfileVisitQuery } from "lib/graphql/megaSchema";
import { MedicalAppPrescriptionsTab } from "./MedicalAppPrescriptionsTab";

type Props = {
  patientLoading: boolean;
  patient: DoctorPatientProfileVisitQuery["doctorPatient"];
};

const toggleButtonOptions: Option<Source>[] = [
  {
    value: "medical-app",
    label: "Jutro Medical",
  },
  {
    value: "gabinet-gov",
    label: "Gabinet.gov",
  },
];

export const PatientDocsPrescriptions = ({
  patientLoading,
  patient,
}: Props) => {
  const [currentSource, setCurrentSource] = useState<Option<Source>>(
    toggleButtonOptions[0],
  );
  const [searchText, setSearchText] = useState("");

  return (
    <div className="flex h-full flex-col gap-4 rounded-lg bg-white p-4">
      <Tabs
        size="condensed"
        options={toggleButtonOptions}
        value={currentSource}
        backgroundColor="gray"
        onChange={(option) => {
          setCurrentSource(option);
        }}
      />

      <Input
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Wyszukaj lek"
        sideElement={<SearchIcon size="sm" />}
        sideElementPosition="start"
      />

      {patientLoading ? (
        <Loader />
      ) : currentSource.value === "gabinet-gov" ? (
        <GabinetGovPrescriptionsTab searchText={searchText} />
      ) : (
        <MedicalAppPrescriptionsTab patient={patient} searchText={searchText} />
      )}
    </div>
  );
};

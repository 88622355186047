import { DoctorMSRegularDrug as RegularDrugType } from "lib/graphql/megaSchema";
import { Confirmation } from "views/Visit/RightPanel/NewPrescription/components/Overlays/Confirmation";
import { CopyDrugToPrescription } from "views/Visit/RightPanel/NewPrescription/components/Overlays/CopyButton";
import { DrugDetails } from "views/Visit/RightPanel/NewPrescription/components/Overlays/DrugDetails";

type Props = {
  drug: RegularDrugType;
};

export const RegularDrug = ({ drug }: Props) => {
  if (!drug.drugDetails) {
    return null;
  }

  const { drugDetails } = drug;

  return (
    <div className="flex gap-4 pt-4 first-of-type:pt-0">
      <div className="flex flex-1 flex-col">
        <DrugDetails
          name={drugDetails.name}
          form={drugDetails.form}
          dose={drugDetails.dosage}
          box={drugDetails.box}
        />
        <Confirmation sourceTag={drug.sourceTag} />
      </div>

      <div>
        <CopyDrugToPrescription drugDetails={drug.drugDetails} />
      </div>
    </div>
  );
};

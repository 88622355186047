import { is } from "ramda";
import { copyset } from "lib/tools/copyset";
import { Maybe } from "lib/types";

const stringForce = (text: any) => String(text).toString();
const sanitizeText = (text: string) => text.replace(/[^a-zA-Z0-9]/g, "");

type Props = {
  formId: Maybe<string>;
  data: any[];
};

export const surveyMap = ({ formId, data }: Props) => {
  const resolveField = (field: any) => {
    if (!field.selectedValue) return false;
    const selectedValue = field.selectedValue;
    const selectedArray = is(Array, selectedValue)
      ? selectedValue
      : [selectedValue];

    const selectedSanitized = selectedArray.map(stringForce).map(sanitizeText);

    const fixedFormId =
      formId !== "109q1WhfbD2imr8N6hHoeImDTscO4q5HtxuH3bKs47vo"
        ? formId
        : "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ";

    const matchedEntries = copyset.filter(
      (el) =>
        el.ID === fixedFormId &&
        sanitizeText(el.question) === sanitizeText(field.title) &&
        (selectedSanitized.includes(sanitizeText(el.answer)) ||
          el.answer === "ALL")
    );

    const allEntry = matchedEntries.find((el) => el.answer === "ALL");
    const concreteEntries = matchedEntries.filter((el) => el.answer !== "ALL");

    if (concreteEntries.length)
      return concreteEntries
        .map((el) => el.entry.toString().replace(/(\[\[(.*)\]\])/g, ""))
        .join("\n");
    if (allEntry)
      return allEntry.entry.replace(
        "[ANSWER]",
        selectedArray
          .map(stringForce)
          .filter((el) => el !== "Inne: [[other]]")
          .map((el) => el.toString().replace(/(\[\[(.*)\]\])/g, ""))
          .join(", ")
      );
    return false;
  };

  return data
    .map(resolveField)
    .filter((el) => el)
    .join("\n");
};

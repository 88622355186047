import { outDuplicates } from "@jutro/tools";
import dayjs from "dayjs";
import {
  DoctorMSAppointmentStatus,
  DoctorMSSentNoShowNotifications,
  DoctorMSVisitType,
  DoctorScheduledVisitsQuery,
} from "lib/graphql/megaSchema";
import { isPastVisit } from "lib/tools/isPastVisit";
import { procedureTitleMap } from "lib/tools/procedureTitleMap";
import { ItemOf } from "lib/types";
import {
  EventCategoryType,
  MappedEvent,
  Resource,
  ResourceType,
  TileColor,
} from "views/Schedule/types";

export const backgroundColorMap = {
  [EventCategoryType.DEFAULT]: TileColor.JUTRO_GRAY_DARK,
  [EventCategoryType.SHOW_ON]: TileColor.JUTRO_BLUE,
  [EventCategoryType.START_ON]: TileColor.JUTRO_BLUE,
  [EventCategoryType.CLOSED]: TileColor.JUTRO_GREEN,
  [EventCategoryType.LEFT_ON]: TileColor.JUTRO_GREEN_DIM,
  [EventCategoryType.NO_SHOW]: TileColor.JUTRO_GRAY_LIGHT,
};

export const visitTypeLabelMap: Record<DoctorMSVisitType, string> = {
  PROCEDURE: "Zabiegowa",
  STANDARD: "Osobista",
  VIRTUAL: "Telemedyczna",
};

export const visitTypeToResourceId = (
  visit: DoctorScheduledVisitsQuery["doctorScheduledVisits"][0],
) => {
  if (
    visit.slotType === "PROCEDURES" ||
    visit.slotType === "COLLECTION" ||
    visit.slotType === "NURSE_VISIT" ||
    visit.slotType === "MIDWIFE"
  ) {
    return ResourceType.Zabiegowe;
  }

  return visit.doctor?.id;
};

export const getResources = (
  data: DoctorScheduledVisitsQuery | undefined,
  organizationId?: string,
  staffId?: string,
) => {
  const resources = [
    {
      resourceId: ResourceType.Zabiegowe,
      resourceTitle: "Gabinet zabiegowy",
      staff: null,
    },
  ];

  if (!data || !data.doctorScheduledVisits.length) {
    return [
      {
        resourceId: 5,
        resourceTitle: "Brak wizyt",
        staff: null,
      },
    ];
  }

  const { doctorScheduledVisits } = data;

  const staffInfoFromVisits = doctorScheduledVisits.map(
    (visit) => visit.doctor,
  );

  const filteredStaff = staffInfoFromVisits
    .filter((staff) => staff?.genre !== "NURSE")
    .filter(outDuplicates({ by: ["id"] }));

  const staffToResourcesMap = filteredStaff.map((staff) => {
    return {
      resourceId: staff?.id,
      resourceTitle: `${staff?.firstName} ${staff?.lastName}`,
      staff,
    };
  });

  const sortedResources = staffToResourcesMap.sort((a, b) => {
    if (a.resourceId === staffId) {
      return -1;
    }
    if (b.resourceId === staffId) {
      return 1;
    }

    return 0;
  });

  if (organizationId && organizationId.length <= 2) {
    return sortedResources;
  }

  return [...resources, ...sortedResources];
};

export const getEventColor = (visit: Resource) => {
  if (visit.snapshotAt) {
    return "green";
  }

  if (isPastVisit(visit)) {
    return "rose";
  }

  if (visit.startOn && !visit.snapshotAt) {
    return "teal";
  }

  if (visit.showOn && !visit.startOn && !isPastVisit(visit)) {
    return "blue";
  }

  return "gray";
};

export const renderTags = (event: Resource) => {
  if (event.laboratoryExaminations.length > 0) {
    return `badania lab x ${event.tags.length}`;
  }

  if (event.procedures.length > 0) {
    return event.tags[0];
  }
};

export const getAssignmentTypes = (
  laboratoryExaminations: Exclude<
    ItemOf<DoctorScheduledVisitsQuery["doctorScheduledVisits"]>,
    null
  >["laboratoryExaminations"],
  procedures: Exclude<
    ItemOf<DoctorScheduledVisitsQuery["doctorScheduledVisits"]>,
    null
  >["procedures"],
) => {
  if (procedures.find((p) => p.type === "DOCTOR_ADVICE")) {
    return [];
  }
  const procedureNames = procedures.map((e) => {
    if (!e || e.type === "DOCTOR_ADVICE") {
      return;
    }

    return procedureTitleMap[e.type];
  });

  const laboratoryExaminationsNames = laboratoryExaminations.map(
    (e) => e.assignment.name,
  );

  return laboratoryExaminationsNames.concat(procedureNames);
};

export const mapEventToCategoryId = (event: MappedEvent): EventCategoryType => {
  const { showOn, startOn, snapshotAt, leftOn } = event;

  const now = dayjs();

  if (snapshotAt) {
    return EventCategoryType.CLOSED;
  }

  if (leftOn) {
    return EventCategoryType.LEFT_ON;
  }

  if (startOn) {
    return EventCategoryType.START_ON;
  }

  if (showOn) {
    return EventCategoryType.SHOW_ON;
  }

  const isPastTwoHours = dayjs(startOn).add(120, "minute").isAfter(now);

  if (isPastTwoHours) {
    return EventCategoryType.NO_SHOW;
  }

  return EventCategoryType.DEFAULT;
};

export const handleAppointmentStatusAutoConfirmation = (
  notificationServiceStatus: DoctorMSSentNoShowNotifications,
): DoctorMSAppointmentStatus => {
  if (!notificationServiceStatus.answer) {
    return "INDETERMINATE";
  }

  return "CONFIRMED";
};

import { Button } from "@jutro/ui";
import {
  MDXEditor,
  codeBlockPlugin,
  codeMirrorPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ErrorBoundary } from "views/MdxPlayground/ErrorBoundary";
import {
  ErrorContextProvider,
  useErrorContext,
} from "views/MdxPlayground/ErrorContextProvider";
import { MdxPlaygroundCompile } from "views/MdxPlayground/MdxPlaygroundCompile";
import { DocsModal } from "views/MdxPlayground/docs/DocsModal";

const mdxBaseContent = `
\`\`\`js
<FormBox></FormBox>
\`\`\``;

const MdxPlaygroundContent = () => {
  const { updateError } = useErrorContext();
  const [mdx, setMdx] = useState(mdxBaseContent);
  const [context, setContext] = useState("");
  const [code, setCode] = useState({
    context: "",
    mdx: "",
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCode({ mdx: mdx.slice(6).slice(0, -3), context });
      updateError({ isError: false, message: "" });
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [mdx, context]);

  const [showDocs, setShowDocs] = useState(false);

  return (
    <>
      <div className="grid h-full w-full grid-cols-3 divide-x-2 overflow-scroll bg-white">
        <div className="flex flex-col justify-between">
          <div className="flex flex-col">
            <div className="px-4 py-2">
              <Button
                full={false}
                text="Dokumentacja"
                onClick={() => {
                  setShowDocs(true);
                }}
              />
            </div>
            <div className="flex flex-col gap-1 overflow-y-scroll p-4">
              <p>Context</p>
              <TextareaAutosize
                className="font-paragraph-1 min-h-16 rounded-lg border p-4"
                onChange={(e) => setContext(e.target.value)}
                value={context}
              />
            </div>

            <div className="flex flex-col gap-1">
              <p className="px-4">MDX</p>
              <MDXEditor
                contentEditableClassName="border-none p-0 overflow-y-scroll"
                markdown={mdx}
                onChange={setMdx}
                plugins={[
                  codeBlockPlugin({ defaultCodeBlockLanguage: "js" }),
                  codeMirrorPlugin({
                    codeBlockLanguages: { js: "JavaScript", css: "CSS" },
                  }),
                ]}
              />
            </div>
          </div>
        </div>

        <div className="col-span-2 p-4">
          <ErrorBoundary>
            <MdxPlaygroundCompile {...code} />
          </ErrorBoundary>
        </div>
      </div>

      <DocsModal isOpen={showDocs} setOpen={setShowDocs} />
    </>
  );
};

export const MdxPlayground = () => (
  <ErrorContextProvider>
    <MdxPlaygroundContent />
  </ErrorContextProvider>
);

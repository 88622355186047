import {
  AddCircleIcon,
  BinIcon,
  Button,
  MessageBox,
  NavigationButton,
} from "@jutro/ui";
import { useAtom } from "jotai";
import { nanoid } from "nanoid";
import { unique } from "radash";
import { useMemo, useState, useTransition } from "react";
import { useDebounce } from "use-debounce";
import { SnippetCard } from "components/Snippet/SnippetListTile/Card";
import { SnippetControls } from "components/Snippet/SnippetListTile/Controls";
import {
  categoryAtom,
  keepCurrentControlsAtom,
  orderAtom,
  searchAtom,
  selectedOwnerAtom,
  selectedSnippetsAtom,
  selectedUsageAtom,
  tagAtom,
  typeAtom,
  visibilityAtom,
} from "components/Snippet/SnippetListTile/atoms";
import {
  categoryFilter,
  ownerFilter,
  searchFilter,
  tagFilter,
  typeFilter,
  usageFilter,
  visibilityFilter,
} from "components/Snippet/tools";
import { SnippetCategoryFilter } from "components/Snippet/types";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { SnippetType } from "lib/graphql/doctor";
import {
  useEdmDeleteSnippetByIdMutation,
  useEdmGetAllSnippetsSuspenseQuery,
} from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import {
  getCurrentWorkspace,
  useTileSystem,
} from "lib/tools/createWorkspace/useTileSystem";
import { toaster } from "lib/tools/toaster";

type Props = {
  mode: "admin" | "settings";
  type?: SnippetType;
  category?: SnippetCategoryFilter;
};

export const SnippetListTile = ({ mode, type, category }: Props) => {
  const { data: snippetsData, refetch } = useEdmGetAllSnippetsSuspenseQuery({
    variables: { showAllFilter: mode === "admin" },
    fetchPolicy: "network-only",
  });
  const [selectedVisibility] = useAtom(visibilityAtom);
  const [selectedType] = useAtom(typeAtom);
  const [selectedCategory] = useAtom(categoryAtom);
  const [selectedOwner] = useAtom(selectedOwnerAtom);
  const [selectedUsage] = useAtom(selectedUsageAtom);
  const [selectedTag] = useAtom(tagAtom);
  const [order] = useAtom(orderAtom);
  const [_searchText] = useAtom(searchAtom);
  const [searchText] = useDebounce(_searchText, 300);
  const [selectedSnippets, setSelectedSnippets] = useAtom(selectedSnippetsAtom);
  const [, setKeepCurrentControls] = useAtom(keepCurrentControlsAtom);
  const [page, setPage] = useState(1);
  const [isPageTransitionPending, startPageTransition] = useTransition();

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deleteSnippet, { loading: deleteSnippetLoading }] =
    useEdmDeleteSnippetByIdMutation();

  const deleteSnippets = () => {
    const deleteSelectedSnippetsPromise = Promise.all(
      selectedSnippets.map((snippet) =>
        deleteSnippet({ variables: { id: snippet } }),
      ),
    ).then(() => {
      refetch();
    });

    toaster.promise(deleteSelectedSnippetsPromise, {
      loading: "Usuwanie...",
      success: "Usunięto szablony",
      error: "Nie udało się usunąć szablonów",
    });

    setSelectedSnippets([]);
  };

  const availableSnippets = snippetsData?.edmGetAllSnippets ?? [];

  const filteredSnippetsBeforeTagFilter = useMemo(() => {
    return availableSnippets.filter(
      (snippet) =>
        visibilityFilter(snippet, selectedVisibility) &&
        usageFilter(snippet, selectedUsage) &&
        typeFilter(snippet, selectedType) &&
        categoryFilter(snippet, selectedCategory) &&
        ownerFilter(snippet, selectedOwner) &&
        searchFilter(snippet, searchText),
    );
  }, [
    selectedVisibility,
    selectedType,
    selectedCategory,
    selectedOwner,
    searchText,
    selectedUsage,
    availableSnippets,
  ]);

  const tagOptions = useMemo(() => {
    return [
      { value: "ALL", label: "Wszystkie" },
      ...unique(
        filteredSnippetsBeforeTagFilter.flatMap((snippet) => snippet.tags),
        (snippet) => snippet.id,
      ).map((tag) => ({ value: tag.id, label: tag.name })),
    ];
  }, [filteredSnippetsBeforeTagFilter]);

  const filteredSnippets = useMemo(() => {
    return filteredSnippetsBeforeTagFilter.filter((snippet) =>
      tagFilter(snippet, selectedTag),
    );
  }, [filteredSnippetsBeforeTagFilter, selectedTag]);

  const sortedSnippetsByPage = useMemo(() => {
    return filteredSnippets
      .sort((snippetA, snippetB) => {
        const comparison = snippetA.counter - snippetB.counter;
        return order === "ASC" ? comparison : -comparison;
      })
      .slice(0, page * 50);
  }, [order, filteredSnippets, page]);

  const { removeTile, addTile, getTileInfo } = useTileSystem();

  return (
    <>
      <div className="flex h-full flex-col gap-4 rounded-lg bg-white p-4">
        <div className="flex justify-between">
          <div className="font-heading-3">Szablony</div>

          <div className="flex gap-4">
            <Button
              icon={<AddCircleIcon />}
              iconPosition="left"
              text="Szablon"
              variant="secondary"
              size="condensed"
              onClick={() => {
                setKeepCurrentControls(true);

                const id = nanoid();

                const currentPosition =
                  getTileInfo("snippet-settings").position;

                const currentWorkspace = getCurrentWorkspace();

                addTile(
                  {
                    id,
                    name: "snippet-form",
                    data: {
                      id,
                      mode: "create",
                      type: selectedType,
                      category: selectedCategory,
                    },
                  },
                  mode === "settings" &&
                    currentWorkspace !== "settings" &&
                    currentPosition !== null
                    ? currentPosition
                    : TileColumn.Right,
                );
              }}
            />

            {selectedSnippets.length > 0 && (
              <Button
                variant="negative"
                icon={<BinIcon />}
                text="Usuń"
                loading={false}
                size="condensed"
                onClick={() => {
                  setConfirmDeleteDialogOpen(true);
                }}
              />
            )}

            {mode === "settings" && getCurrentWorkspace() !== "settings" && (
              <NavigationButton
                onClick={() => {
                  removeTile("snippet-settings");
                }}
              />
            )}
          </div>
        </div>

        <SnippetControls
          visibleCounter={filteredSnippets.length}
          totalCounter={availableSnippets.length}
          mode={mode}
          type={type}
          category={category}
          tagOptions={tagOptions}
        />

        <div className="no-scrollbar flex h-full flex-col gap-4 overflow-y-scroll">
          {sortedSnippetsByPage.length ? (
            sortedSnippetsByPage.map((snippet) => {
              return (
                <SnippetCard key={snippet.id} snippet={snippet} mode={mode} />
              );
            })
          ) : (
            <div className="flex justify-center py-4">
              <MessageBox text="Brak szablonów" full={false} color="warmGray" />
            </div>
          )}

          {filteredSnippets.length / 50 > page && (
            <div className="flex justify-center">
              <Button
                text="Pokaż więcej"
                variant="ghost"
                onClick={() => {
                  startPageTransition(() => {
                    setPage((p) => p + 1);
                  });
                }}
                full={false}
                loading={isPageTransitionPending}
                disabled={isPageTransitionPending}
              />
            </div>
          )}
        </div>
      </div>

      <ConfirmDialog
        open={confirmDeleteDialogOpen}
        setOpen={setConfirmDeleteDialogOpen}
        onConfirm={deleteSnippets}
        loading={deleteSnippetLoading}
        confirmText="Usuń"
        text={`Czy na pewno chcesz usunąć szablony o id: ${selectedSnippets.join(", ")}?`}
      />
    </>
  );
};

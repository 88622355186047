import { AddCircleIcon, DocumentIcon } from "@jutro/ui";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { BannerContent, BannerStatus } from "components/mdx/BannerIpom/types";
import { DoctorMSVisitStatus } from "lib/graphql/megaSchema";

dayjs.extend(isBetween);

export const getIpomDates = (ipomDate: string | undefined) => {
  if (!ipomDate) {
    return {
      ipomExpiredDate: null,
      isIpomExpired: null,
      isNewIpomAvailable: null,
    };
  }

  const currentYear = dayjs().get("year");
  const ipomCreatedYear = dayjs(ipomDate).get("year");

  const ipomExpiredDate = dayjs(ipomDate).add(12, "months");
  const isIpomExpired = dayjs().isAfter(ipomExpiredDate);

  const newIpomAvailableDate = dayjs(ipomDate).add(10, "months");
  const isNewIpomAvailable =
    dayjs().isBetween(newIpomAvailableDate, ipomExpiredDate) ||
    currentYear !== ipomCreatedYear;

  return {
    ipomExpiredDate,
    isIpomExpired,
    isNewIpomAvailable,
  };
};

export const getIpomStatus = ({
  isVisitQualifiedToIpom,
  ipomDate,
}: {
  isVisitQualifiedToIpom: boolean;
  ipomDate: string | undefined;
}): BannerStatus => {
  const { isIpomExpired, isNewIpomAvailable } = getIpomDates(ipomDate);

  // available | "expired" | "active" | "availableNew"
  if (!ipomDate && isVisitQualifiedToIpom) {
    return "available";
  }

  if (ipomDate && isIpomExpired) {
    return "expired";
  }

  if (ipomDate && isNewIpomAvailable) {
    return "availableNew";
  }

  if (ipomDate) {
    return "active";
  }

  return "notAvailable";
};

export const getContentByStatus = ({
  status,
  expiredDate,
  visitStatus,
}: {
  status: BannerStatus;
  expiredDate?: string | null;
  visitStatus?: DoctorMSVisitStatus;
}): BannerContent | null => {
  if (status === "expired" && visitStatus === "DRAFT") {
    return {
      description: "IPOM pacjenta stracił ważność",
      buttonText: "Otwórz IPOM",
      type: "negative",
      icon: DocumentIcon,
    };
  }

  if (visitStatus !== "INPROGRESS") {
    return null;
  }

  if (status === "available") {
    return {
      description: "Rozpoznanie kwalifikuje się do opieki koordynowanej.",
      buttonText: "Wystaw IPOM",
      type: "positive",
      icon: AddCircleIcon,
    };
  }

  if (status === "availableNew") {
    return {
      description:
        "Pacjentowi przysługuje nowy IPOM w ramach opieki koordynowanej",
      buttonText: "Otwórz IPOM",
      type: "warning",
      icon: DocumentIcon,
    };
  }

  if (status === "expired") {
    return {
      description: `IPOM stracił ważność ${expiredDate}. Wystaw nowy IPOM,
        żeby kontynuować opiekę nad Pacjentem w ramach Opieki Koordynowanej`,
      buttonText: "Wystaw nowy IPOM",
      type: "warning",
      icon: AddCircleIcon,
    };
  }

  return null;
};

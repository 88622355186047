import { Option } from "@jutro/types";
import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { selectedVisitTabAtom } from "lib/atoms";
import { DoctorMSDiagnosis, DoctorVisitQuery } from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { mainDiagnosisInterviewAtom } from "views/Visit/RightPanel/Visit/atoms";
import { Icd10Select } from "views/Visit/RightPanel/Visit/components/Icd10Select";

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

type Props = {
  label: string;
  disabled: boolean;
  tags?: Patient["tags"];
  ctaCopy?: string;
};

export const MainDiagnosisSelect = ({
  label,
  disabled,
  tags,
  ctaCopy = "",
}: Props) => {
  const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);
  const { addTile } = useTileSystem();
  const handleClickCtaCopy = () => {
    setSelectedVisitTab("prescription");
    addTile("visit-ordered-drugs", TileColumn.Center);
  };

  const { watch, control } = useFormContext<{
    doctorDiagnosis: Visit["doctorDiagnosis"];
  }>();

  const [, setMainDiagnosis] = useAtom(mainDiagnosisInterviewAtom);

  const doctorDiagnosis = watch("doctorDiagnosis") as
    | DoctorMSDiagnosis[]
    | undefined;

  const value = useMemo(
    () => doctorDiagnosis?.find((d) => d.grade === "MAIN")?.code,
    [doctorDiagnosis],
  );

  const valueFieldIndex = useMemo(
    () => doctorDiagnosis?.findIndex((d) => d.grade === "MAIN"),
    [doctorDiagnosis],
  );

  const { append, update } = useFieldArray({
    name: "doctorDiagnosis",
    control,
  });

  const handleMainDiagnosisChange = (e: Option<string> | null) => {
    if (valueFieldIndex !== undefined && valueFieldIndex !== -1) {
      update(valueFieldIndex, { code: e?.value, grade: "MAIN" });
      return;
    }

    append({ code: e?.value, grade: "MAIN" });
  };

  useEffect(() => {
    setMainDiagnosis(value || null);

    return () => {
      setMainDiagnosis(null);
    };
  }, [value]);

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <div className="flex justify-between">
          <div className="font-label flex w-full justify-between text-jutro-new-warm-gray-800">
            <span>{label}</span>
            {ctaCopy && (
              <span
                className="font-paragraph-2 cursor-pointer text-jutro-new-blue-800"
                onClick={handleClickCtaCopy}
              >
                {ctaCopy}
              </span>
            )}
          </div>
        </div>
      )}

      <Icd10Select
        disabled={disabled}
        value={value}
        onChange={handleMainDiagnosisChange}
        tags={tags}
      />
    </div>
  );
};

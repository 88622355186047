import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import { currentUserAtom } from "lib/atoms/auth";
import { useHandleMessagesUpdatesSubscription } from "lib/graphql/doctor";
import {
  DoctorChatMessagesQuery,
  DoctorChatMessagesQueryVariables,
  DoctorVisitDocument,
  useDoctorChatMessagesContentQuery,
  useDoctorChatMessagesLazyQuery,
  useDoctorDeleteChatMessageMutation,
  useDoctorGetDocumentsQuery,
  useDoctorNextPatientLazyQuery,
} from "lib/graphql/megaSchema";
import { ArrayElement } from "lib/types";
import { useVisitData } from "views/Visit/hooks";
import { usePatientActivityActions } from "./ChatMenu/usePatientActivityActions";

const MESSAGE_LIMIT = 25;

export const useChat = (patientId: string) => {
  const navigate = useNavigate();
  const [currentUser] = useAtom(currentUserAtom);
  const [messages, setMessages] = useState<
    DoctorChatMessagesQuery["doctorChatMessages"]
  >([]);
  const [scrollbar, setScrollbar] = useState<Scrollbars | null>(null);

  const hasMoreOlderMessages = useRef(true);

  const [isLoadingOldMessages, setIsLoadingOldMessages] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);

  const [latestMessage, setLatestMessage] = useState<ArrayElement<
    DoctorChatMessagesQuery["doctorChatMessages"]
  > | null>(null);

  const [
    isConfirmationToGoToNextPatientOpen,
    setIsConfirmationToGoToNextPatientOpen,
  ] = useState(false);

  const loadingLockRef = useRef(false);

  const [getMessagesLazy] = useDoctorChatMessagesLazyQuery();

  const getMessages = async (
    variables?: Partial<DoctorChatMessagesQueryVariables>,
  ) => {
    const { data, error } = await getMessagesLazy({
      fetchPolicy: "no-cache",
      variables: {
        patientId,
        ...(variables ?? {}),
      },
    });

    if (error) {
      throw error;
    }

    const filteredChatMessagesFromPmfSurvey = data?.doctorChatMessages.filter(
      (message) => message.survey?.formId !== "PMF_SURVEY",
    );

    return filteredChatMessagesFromPmfSurvey ?? [];
  };

  const isFile = (content: string) => content.match(/\[file-([^\]]+.{2,})\]/);

  useHandleMessagesUpdatesSubscription({
    variables: { patientId },
    onData: ({ data }) => {
      const { data: messageData, error } = data;

      if (error) {
        console.error(error);
        return;
      }

      if (!messageData) {
        return;
      }

      const { __typename: messageType, message: chatMessage } =
        messageData.handleMessagesUpdates;

      const message =
        chatMessage as DoctorChatMessagesQuery["doctorChatMessages"][number];

      if (messageType === "ChatMessageDeletedEvent") {
        setMessages((messages) =>
          messages.filter(({ id }) => id !== message.id),
        );
        return;
      }

      if (messageType === "PrescriptionChatMessageDeletedEvent") {
        setMessages((messages) =>
          messages.map((originalMessage) => {
            if (originalMessage.id !== message.id) {
              return originalMessage;
            }

            return {
              ...originalMessage,
              prescription: message.prescription,
            };
          }),
        );
        return;
      }

      if (isFile(message.content)) {
        refetchDocuments();
        refetchImageContent();
      }

      setMessages((prev) => [...prev, message]);

      setLatestMessage(message);
    },
  });

  const [nextPatient, { data: nextPatientData, error: nextPatientError }] =
    useDoctorNextPatientLazyQuery({
      fetchPolicy: "no-cache",
    });

  if (nextPatientError) {
    console.error("error fetching next one: ", nextPatientError);
  }

  useEffect(() => {
    if (!nextPatientData) {
      return;
    }

    if (nextPatientData.doctorNextPatient === "none") {
      navigate("/", { replace: true });
      return;
    }

    if (nextPatientData.doctorNextPatient === patientId) {
      setIsConfirmationToGoToNextPatientOpen(true);
      return;
    }

    navigate(`/patients/${nextPatientData.doctorNextPatient}/chat`, {
      replace: true,
    });
  }, [nextPatientData]);

  const { refetch: refetchDocuments } = useDoctorGetDocumentsQuery({
    variables: {
      id: patientId,
    },
    fetchPolicy: "no-cache",
  });

  const { refetch: refetchImageContent } = useDoctorChatMessagesContentQuery({
    variables: {
      patientId,
    },
  });

  useEffect(() => {
    getMessages({
      take: MESSAGE_LIMIT,
      patientId,
    })
      .then((chatMessages) => {
        hasMoreOlderMessages.current = chatMessages.length === MESSAGE_LIMIT;
        setMessages([...chatMessages].reverse());
      })
      .catch((err) => {
        console.error("couldn't load messages", err);
      })
      .finally(() => {
        setIsLoadingMessages(false);
      });
  }, [setMessages, patientId]);

  const loadOlder = () => {
    if (
      loadingLockRef.current === true ||
      isLoadingMessages ||
      isLoadingOldMessages ||
      !hasMoreOlderMessages.current ||
      messages.length === 0
    ) {
      return false;
    }

    loadingLockRef.current = true;
    setIsLoadingOldMessages(true);

    const [firstMessage] = messages;

    getMessages({
      take: MESSAGE_LIMIT,
      createdAtLt: messages.length > 0 ? firstMessage.createdAt : undefined,
    })
      .then((chatMessages) => {
        chatMessages.reverse();
        hasMoreOlderMessages.current = chatMessages.length === MESSAGE_LIMIT;
        if (chatMessages.length) {
          setMessages((prev) => [...chatMessages, ...prev]);
        }
      })
      .catch((err) => {
        console.error("couldn't load old messages", err);
      })
      .finally(() => {
        loadingLockRef.current = false;
        setIsLoadingOldMessages(false);
      });

    return true;
  };

  const { waitingForPatient } = usePatientActivityActions(patientId);
  const pausePatient = () => waitingForPatient(() => {});

  return {
    loading: isLoadingMessages,
    chatMessages: messages,
    nextPatient,
    user: currentUser,
    pausePatient,
    isLoadingOldMessages,
    loadOlder,
    isConfirmationToGoToNextPatientOpen,
    setIsConfirmationToGoToNextPatientOpen,
    scrollbar,
    setScrollbar,
    latestMessage,
  };
};

export const useDeleteMessageMutation = () => {
  const [deleteChatMessageMutation] = useDoctorDeleteChatMessageMutation({
    refetchQueries: [DoctorVisitDocument],
    fetchPolicy: "no-cache",
    awaitRefetchQueries: true,
  });

  const removeMessage = async (id: string) => {
    await deleteChatMessageMutation({ variables: { id } });
  };

  return { removeMessage };
};

export const useDropzoneHiddenState = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  if (!currentVisit) {
    return false;
  }

  const { startOn, leftOn, snapshotAt } = currentVisit;

  const isVisitBeforeStart = currentUser?.genre === "DOCTOR" && !startOn;

  const isVisitOver = currentUser?.genre === "DOCTOR" && (leftOn || snapshotAt);

  const disabled = Boolean(
    currentVisit.snapshotBy || isVisitBeforeStart || isVisitOver,
  );

  return disabled;
};

import dayjs from "dayjs";
import { useMemo } from "react";
import { Column, useFlexLayout, useResizeColumns, useTable } from "react-table";
import { Loader } from "components/new";
import {
  DoctorGetDocumentsDocument,
  DoctorMSMedicalRecordEntity,
  useDoctorUpdateDocumentMutation,
} from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { toaster } from "lib/tools/toaster";
import { ActionButton, ActionButtonProps } from "./ActionButton";
import { MedicalDocumentationItem } from "./Item";
import { Star } from "./Star";
import { StatusPill } from "./StatusPill";

type Props = {
  data: DoctorMSMedicalRecordEntity[];
  loading: boolean;
  isDisplayOnly?: boolean;
};

export const MedicalDocumentationTable = ({
  data,
  loading,
  isDisplayOnly,
}: Props) => {
  const [updateDocument] = useDoctorUpdateDocumentMutation({
    refetchQueries: [DoctorGetDocumentsDocument],
    awaitRefetchQueries: true,
  });
  const { addTile } = useTileSystem();

  const columns = useMemo<Column<DoctorMSMedicalRecordEntity>[]>(
    () =>
      isDisplayOnly
        ? [
            {
              Header: "Nazwa pliku",

              accessor: ({ fileId, name }) => {
                return (
                  <span
                    onClick={() => {
                      addTile(
                        {
                          name: "file-preview",
                          data: { fileId },
                          id: fileId,
                        },
                        ({ visibleTiles }) =>
                          visibleTiles.includes("visit-procedure-panel")
                            ? TileColumn.Left
                            : TileColumn.Right,
                      );
                    }}
                    className={`font-paragraph-2 inline-block h-full w-full cursor-pointer truncate align-middle hover:text-jutro-new-green-400`}
                  >
                    {name}
                  </span>
                );
              },
            },
            {
              Header: "Żródło",
              accessor: "source",
              Cell: ({ value }) => <StatusPill value={value} />,
            },
            {
              Header: "Przesłano",
              accessor: "createdAt",
              Cell: ({ value }) => (
                <span className="font-paragraph-2">
                  {dayjs(value).format("YYYY-MM-DD")}{" "}
                  <span className="text-jutro-new-warm-gray-400">
                    {dayjs(value).format("HH:mm")}
                  </span>
                </span>
              ),
            },
          ]
        : [
            {
              Header: "",
              accessor: "starred",
              Cell: ({ value, row }) => (
                <Star
                  starred={value}
                  onClick={async () => {
                    toaster.notify("Zmieniam status gwazdki!");
                    try {
                      await updateDocument({
                        variables: {
                          id: row.original.id,
                          input: {
                            starred: !value,
                          },
                        },
                      });
                      toaster.success("Zmieniono status gwiazdki!");
                    } catch {
                      toaster.error("Nie udało się zmienić statusu gwiazdki!");
                    }
                  }}
                />
              ),
              maxWidth: 20,
            },
            {
              Header: "Nazwa pliku",
              width: 150,
              accessor: ({ fileId, name }) => {
                return (
                  <span
                    onClick={() => {
                      addTile(
                        {
                          name: "file-preview",
                          data: { fileId },
                          id: fileId,
                        },
                        ({ visibleTiles }) =>
                          visibleTiles.includes("visit-procedure-panel")
                            ? TileColumn.Left
                            : TileColumn.Right,
                      );
                    }}
                    className={`font-paragraph-2 inline-block h-full w-full cursor-pointer truncate align-middle hover:text-jutro-new-green-400`}
                  >
                    {name}
                  </span>
                );
              },
            },
            {
              Header: "Żródło",
              accessor: "source",
              Cell: ({ value }) => <StatusPill value={value} />,
              width: 90,
            },
            {
              Header: "Przesłano",
              accessor: "createdAt",
              Cell: ({ value }) => (
                <span className="font-paragraph-2">
                  {dayjs(value).format("YYYY-MM-DD")}{" "}
                  <span className="text-jutro-new-warm-gray-400">
                    {dayjs(value).format("HH:mm")}
                  </span>
                </span>
              ),
              width: 120,
            },
            {
              Header: "Usuń",
              Cell: ({ row }: ActionButtonProps) => {
                return <ActionButton row={row} />;
              },
              id: "4",
              maxWidth: 70,
            },
          ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<DoctorMSMedicalRecordEntity>(
      {
        data,
        columns,
      },
      useFlexLayout,
      useResizeColumns,
    );

  if (!loading && data.length === 0) return null;

  return (
    <div
      {...getTableProps()}
      className="no-scrollbar mb-2 max-h-96 divide-y divide-gray-200 overflow-x-auto overflow-y-scroll rounded-lg border border-jutro-new-warm-gray-300"
    >
      <div>
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            key={headerGroup.getHeaderGroupProps().key}
          >
            {headerGroup.headers.map((column) => (
              <div
                className="font-paragraph-2 px-4 py-2 text-left text-jutro-new-warm-gray-600"
                {...column.getHeaderProps()}
                key={column.getHeaderProps().key}
              >
                {column.render("Header")}
              </div>
            ))}
          </div>
        ))}
      </div>
      {!loading ? (
        <div {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => (
                  <MedicalDocumentationItem
                    key={cell.getCellProps().key}
                    cell={cell}
                  />
                ))}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="py-3">
          <Loader size="xs" />
        </div>
      )}
    </div>
  );
};

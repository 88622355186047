import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DoctorLaboratoryExaminationsDocument,
  DoctorLaboratoryExaminationsQuery,
  DoctorLaboratoryExaminationsQueryVariables,
  DoctorProceduresDocument,
  DoctorProceduresQuery,
  DoctorProceduresQueryVariables,
} from "lib/graphql/megaSchema";

export const useExaminationPanelMessage = (content: string) => {
  const [laboratoryExaminations, setLaboratoryExaminations] = useState<
    DoctorLaboratoryExaminationsQuery["doctorLaboratoryExaminations"]
  >([]);

  const [procedure, setProcedure] = useState<
    DoctorProceduresQuery["doctorProcedures"][0] | null
  >(null);
  const client = useApolloClient();

  const getLaboratoryExaminations = (ids: string[]) => {
    return client.query<
      DoctorLaboratoryExaminationsQuery,
      DoctorLaboratoryExaminationsQueryVariables
    >({
      query: DoctorLaboratoryExaminationsDocument,
      fetchPolicy: "no-cache",
      variables: {
        ids,
      },
    });
  };

  const getProcedure = (id: string) => {
    return client.query<DoctorProceduresQuery, DoctorProceduresQueryVariables>({
      query: DoctorProceduresDocument,
      fetchPolicy: "no-cache",
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    if (content.startsWith("[laboratoryExaminations-")) {
      const ids = content
        .replace("[laboratoryExaminations-", "")
        .replace("]", "")
        .split("-");

      getLaboratoryExaminations(ids).then((res) =>
        setLaboratoryExaminations(res.data.doctorLaboratoryExaminations),
      );
    }
    if (content.startsWith("[procedure-")) {
      const id = content.replace("[procedure-", "").replace("]", "");

      getProcedure(id).then((res) =>
        setProcedure(res?.data?.doctorProcedures[0]),
      );
    }
  }, []);

  return { laboratoryExaminations, procedure };
};

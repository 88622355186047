import { CircleAlertIcon } from "@jutro/ui";

export const ChukQualification = () => {
  return (
    <div className="font-paragraph-2 flex items-center gap-2 rounded-lg bg-jutro-new-orange-400 p-4 text-white">
      <div>
        <CircleAlertIcon size="sm" />
      </div>
      <div>
        <span className="font-semibold">Uwaga: </span>
        <span className="font-medium">
          Pacjent z kwalifikacją do badania - pomiary CHUK
        </span>
      </div>
    </div>
  );
};

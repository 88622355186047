import { Input } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  defaultValue?: number;
};

export const ControlledScoreInput = ({
  name,
  label,
  disabled,
  defaultValue,
}: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <Input
          label={label}
          type="number"
          min={0}
          max={24}
          disabled={disabled}
          value={value}
          onChange={(e) => {
            const inputValue = e.target.value;

            if (inputValue && parseInt(inputValue) > 24) {
              return;
            }

            onChange(inputValue ? parseInt(inputValue) : 0);
          }}
          placeholder="Wpisz wynik..."
          {...field}
          error={error?.message && "To pole jest wymagane."}
        />
      )}
    />
  );
};

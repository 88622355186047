import { Maybe } from "@jutro/types";
import { Button, VerticalMenuIcon } from "@jutro/ui";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrescriptionDetailsTooltip } from "components/PatientDocs/Prescriptions/PrescriptionDetailsTooltip";
import { Prescription } from "components/PatientDocs/Prescriptions/types";
import { PopupMenu } from "components/new/PopupMenu";

type Props = {
  visitId?: Maybe<string>;
  content: Omit<Prescription, "drugs">;
};

export const PrescriptionHeader = ({ visitId, content }: Props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  if (!content.issueDate) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center justify-between gap-2">
        <div className="font-paragraph-2">
          Recepta z dnia: {dayjs(content.issueDate).format("YYYY-MM-DD")}
        </div>

        <div className="flex gap-2">
          <PrescriptionDetailsTooltip content={content} />

          {visitId && (
            <div
              className="relative flex flex-col justify-center"
              onClick={() => setOpenMenu((prev) => !prev)}
              id="user-menu"
            >
              <Button
                size="condensed"
                variant="ghost"
                icon={<VerticalMenuIcon size="sm" />}
                label="Vertical menu"
                withTooltip={false}
              />

              <PopupMenu
                active={openMenu}
                position="bottom-left"
                items={[
                  {
                    id: "navigate-visit",
                    action: () => {
                      navigate(`/visits/${visitId}`);
                    },
                    label: "Przejdź do wizyty",
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

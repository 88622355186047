import dayjs from "dayjs";
import {
  corruptedAnalysisTypeMap,
  examinationParamsColumns,
  getIconByType,
  parseParamName,
  typeMap,
} from "components/ExaminationDrawer/utils";
import { ExaminationItem } from "components/components/ExaminationItem";
import { Table } from "components/new/Table";
import { PatientVitalityAnalysis, PatientVitalityGeneral } from "lib/types";

type Props = {
  examination: PatientVitalityGeneral & PatientVitalityAnalysis;
  paramFilter: string;
  everyExaminationTypeSame: boolean;
  everyParamSame: boolean;
};

export const ParamsAnalysis = ({
  examination,
  paramFilter,
  everyExaminationTypeSame,
  everyParamSame,
}: Props) => {
  return (
    <>
      {examination.params.filter((p) =>
        parseParamName(p, examination)
          .toLowerCase()
          .includes(paramFilter.toLowerCase()),
      ).length > 0 && (
        <div className="divide-4 flex flex-col gap-2 px-4 pb-4">
          {(!everyExaminationTypeSame || !everyParamSame) && (
            <ExaminationItem
              icon={getIconByType(examination.analysisType)}
              examination={
                corruptedAnalysisTypeMap[examination.analysisType] ||
                typeMap[examination.type as keyof typeof typeMap] ||
                examination.analysisType
              }
              date={
                examination.measured?.iso
                  ? dayjs(examination.measured.iso).format("D.MM.YYYY H:mm")
                  : ""
              }
            />
          )}
          {examination.params.filter((p) =>
            parseParamName(p, examination)
              .toLowerCase()
              .includes(paramFilter.toLowerCase()),
          ).length > 0 && (
            <div className="w-full overflow-auto rounded-lg shadow-lg">
              <Table
                flexLayoutMode={false}
                isFullWidth
                columns={examinationParamsColumns({
                  everyExaminationTypeSame,
                  everyParamSame,
                  examination,
                })}
                data={examination.params.filter((p) =>
                  parseParamName(p, examination)
                    .toLowerCase()
                    .includes(paramFilter.toLowerCase()),
                )}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

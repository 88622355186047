// decodeURIComponent does not work somehow, see: https://www.geeksforgeeks.org/how-to-escape-unescape-html-characters-in-string-in-javascript/
export const unescapeHTMLString = (htmlString: string) =>
  htmlString
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");

export const NO_DATA_MESSAGE = "Brak danych";

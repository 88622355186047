import { atom, useAtom } from "jotai";
import { useEffect } from "react";

const triggerAtom = atom(false);
const procedureFormValidationAtom = atom<Record<string, boolean>>({});
const shouldRunActionAtom = atom(false);
const isProcedureLoadingAtom = atom(false);

export const useProcedureFormValidation = () => {
  const [validation, setValidation] = useAtom(procedureFormValidationAtom);
  const [shouldRunAction, setRunAction] = useAtom(shouldRunActionAtom);
  const [shouldTrigger, setShouldTrigger] = useAtom(triggerAtom);
  const [isProcedureLoading, setIsProcedureLoading] = useAtom(
    isProcedureLoadingAtom,
  );

  const handleSetShouldTrigger = () => {
    if (isProcedureLoading) {
      return;
    }

    setShouldTrigger(true);
  };

  const handleSetIsValid = (procedureId: string, isValid: boolean) => {
    setValidation((prev) => ({ ...prev, [procedureId]: isValid }));
  };

  const unregisterForm = (procedureId: string) => {
    setValidation((prev) =>
      Object.fromEntries(
        Object.entries(prev).filter(([key]) => key !== procedureId),
      ),
    );
  };

  const resetActionStatus = () => {
    setRunAction(false);
  };

  useEffect(() => {
    if (!shouldTrigger) {
      setRunAction(false);
      return;
    }

    setShouldTrigger(false);
    setRunAction(Object.values(validation).every((isValid) => isValid));
  }, [validation]);

  return {
    shouldTrigger,
    shouldRunAction,
    unregisterForm,
    handleSetShouldTrigger,
    handleSetIsValid,
    resetActionStatus,
    setIsProcedureLoading,
  };
};

import { EmptyPrescriptionList } from "components/PatientDocs/Prescriptions/EmptyPrescriptionList";
import { PrescriptionDrug } from "components/PatientDocs/Prescriptions/PrescriptionDrug";
import { PrescriptionHeader } from "components/PatientDocs/Prescriptions/PrescriptionHeader";
import { Loader, Scrollbar } from "components/new";
import {
  DoctorPatientProfileVisitQuery,
  DoctorPrescriptionsHistoryQuery,
  useDoctorPrescriptionsHistoryQuery,
} from "lib/graphql/megaSchema";

type Props = {
  patient: DoctorPatientProfileVisitQuery["doctorPatient"];
  searchText: string;
};

export const MedicalAppPrescriptionsTab = ({ patient, searchText }: Props) => {
  const outSearchedPrescriptions = (
    prescription: DoctorPrescriptionsHistoryQuery["doctorGetPrescriptionsForPatient"][number],
  ) => {
    return prescription.drugs.some((drug) =>
      drug.drug.toLowerCase().includes(searchText.toLowerCase()),
    );
  };

  const {
    data,
    loading: prescriptionsLoading,
    error,
  } = useDoctorPrescriptionsHistoryQuery({
    variables: { patientId: patient?.id ?? "" },
    skip: !patient?.id,
  });

  if (prescriptionsLoading) {
    return <Loader />;
  }

  if (error) {
    return <span>Wystąpił błąd</span>;
  }

  const searchedPrescriptions = data?.doctorGetPrescriptionsForPatient.filter(
    outSearchedPrescriptions,
  );

  if (searchedPrescriptions && searchedPrescriptions.length === 0) {
    return <EmptyPrescriptionList />;
  }

  return (
    <Scrollbar>
      <div className="flex flex-col gap-4">
        {searchedPrescriptions?.map(
          ({ id, doctor, writeup, drugs, startsToWork, visit }) => (
            <div key={id} className="rounded-lg bg-jutro-new-warm-gray-50 p-4">
              <PrescriptionHeader
                content={{
                  issueDate: startsToWork?.iso,
                  issuedBy: `${doctor.firstName} ${doctor.lastName}`,
                  place: visit.plannedPlace ?? "",
                  writeup: writeup.iso,
                }}
              />

              <div className="flex flex-col divide-y">
                {drugs.map((drug) => (
                  <PrescriptionDrug key={drug.drug} drug={drug} />
                ))}
              </div>
            </div>
          ),
        )}
      </div>
    </Scrollbar>
  );
};

import {
  Control,
  Controller,
  FieldErrors,
  UseFormReset,
} from "react-hook-form";
import ReactSelect from "react-select";
import { PaymentModalSchema } from "components/PatientDocs/Ordered/types";
import {
  customSelectStyles,
  paymentMethodOptions,
} from "components/PatientDocs/Ordered/utils";

type Props = {
  control: Control<PaymentModalSchema> | undefined;
  ecommercePacket: boolean;
  errors: FieldErrors<PaymentModalSchema>;
  reset: UseFormReset<PaymentModalSchema>;
};
export const PaymentOptionSelect = ({
  control,
  ecommercePacket,
  errors,
  reset,
}: Props) => {
  return (
    <Controller
      control={control}
      name="paymentMethod"
      defaultValue="POS"
      render={({ field: { onChange, value, ...field } }) => (
        <div
          className={`flex w-full flex-col gap-2 ${
            ecommercePacket ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <ReactSelect
            defaultValue={{ value: "POS", label: "Terminal POS" }}
            placeholder="Kliknij i wybierz z listy"
            isSearchable={false}
            options={paymentMethodOptions}
            styles={customSelectStyles<string>()}
            isDisabled={ecommercePacket}
            value={
              ecommercePacket
                ? { value: "POS", label: "Terminal POS" }
                : (paymentMethodOptions.find(
                    ({ value: optionValue }) => value === optionValue,
                  ) ?? null)
            }
            onChange={(option) => {
              if (!option) {
                return;
              }

              reset({ paymentMethod: option.value });
            }}
            {...field}
          />
          {errors.paymentMethod?.message && (
            <span className="text-jutro-new-rose-600">
              {errors.paymentMethod.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

import { z } from "zod";

const envVarsSchema = z.object({
  REACT_APP_CONFIG: z.enum(["local", "dev", "staging", "server"]),
  REACT_APP_ENVIRONMENT: z.enum(["DEV", "PROD"]),
  REACT_APP_SENTRY_DSN: z.string().min(1),
  REACT_APP_GHOST_API_URI: z.string().min(1),
  REACT_APP_GHOST_ENV_KEY: z.string().min(1),
  REACT_APP_PAPERCUPS_TOKEN: z.string().min(1),
  REACT_APP_FLAGSMITH_CLIENT_ENV_KEY: z.string().min(1),
  REACT_APP_STRAPI_URL: z.string().url(),
  REACT_APP_STRAPI_KEY: z.string(),
  REACT_APP_ALT_STAGING: z.string().optional(),
  REACT_APP_MEGA_SCHEMA_URL: z.string().optional(),
  REACT_APP_ZENDESK_ENV_KEY: z.string().min(1),
  REACT_APP_EVENT_SERVICE_URL: z.string().min(1),
  REACT_APP_EVENT_SERVICE_SECRET_KEY: z.string().min(1),
  SKIP_PREFLIGHT_CHECK: z.string().optional(),
  REACT_APP_PATHWAYS_APPLY_SIMULATION_TOKEN: z.string().optional(),
  TOKEN: z.string().optional(), // jwt for codegen
});

export type EnvVars = z.infer<typeof envVarsSchema>;

export const getEnvVars = () =>
  envVarsSchema.parse((window as any)._env || import.meta.env);

export const envVars = getEnvVars();

import { determineAge } from "@jutro/tools";
import { Option } from "@jutro/types";
import { Banner, Input, Select } from "@jutro/ui";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useOnce } from "lib/hooks/useOnce";
import {
  calculateSmokeLevel,
  numberOfCigarettesPerDayOptions,
  smokeLevels,
  smokingCurrentlyOptions,
  smokingInThePastOptions,
  smokingTypeOptions,
} from "views/Visit/RightPanel/Visit/components/PatientStatistics/tools";
import { useVisitData } from "views/Visit/hooks";

type Props = {
  disabled: boolean;
};

export const PatientStatistics = ({ disabled }: Props) => {
  const { setValue, control } = useFormContext();
  const { data: visit } = useVisitData({ from: "cache-only" });
  const [smokingCurrently, setSmokingCurrently] =
    useState<Option<string> | null>(null);
  const [smokingInThePast, setSmokingInThePast] =
    useState<Option<string> | null>(null);
  const [smokingType, setSmokingType] = useState<Option<string> | null>(null);
  const [ciggaretesPerDay, setCiggaretesPerDay] =
    useState<Option<string> | null>(null);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const smokeLevel = calculateSmokeLevel({
      smokingCurrently: smokingCurrently?.value ?? null,
      smokingInThePast: smokingInThePast?.value ?? null,
      smokingType: smokingType?.value ?? null,
      ciggaretesPerDay: ciggaretesPerDay?.value ?? null,
    });

    setValue("smokeLevel", smokeLevel, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [smokingCurrently, smokingInThePast, smokingType, ciggaretesPerDay]);

  const patientAge = determineAge(visit?.patient?.pesel ?? "");

  useOnce(
    () => {
      if (visit?.slotType !== "PERSONAL") return;

      if (
        !visit.patient?.weight ||
        !visit.patient.height ||
        !visit.patient.smokeLevel
      ) {
        setShow(true);
      }

      if (visit.weight && visit.height && visit.smokeLevel) {
        setShow(true);

        const smokeCode = Object.entries(smokeLevels).find(([, value]) => {
          return value === visit.smokeLevel;
        })?.[0];

        // set smokeLevel default value
        setValue("smokeLevel", visit.smokeLevel);

        const [_smoking, _smokingInThePast, _smokingType, _ciggaretesPerDay] =
          smokeCode?.split("_").map((v) => (v === "null" ? null : v)) as (
            | string
            | null
          )[];

        setSmokingCurrently(
          smokingCurrentlyOptions.find((o) => o.value === _smoking) ?? null,
        );

        setSmokingInThePast(
          smokingInThePastOptions.find((o) => o.value === _smokingInThePast) ??
            null,
        );

        setSmokingType(
          smokingTypeOptions.find((o) => o.value === _smokingType) ?? null,
        );

        setCiggaretesPerDay(
          numberOfCigarettesPerDayOptions.find(
            (o) => o.value === _ciggaretesPerDay,
          ) ?? null,
        );
      }
    },
    {
      when: Boolean(visit),
    },
  );

  if (!show) return null;

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="font-heading-4">Dane statystyczne pacjenta</div>

      <Banner description="Na każdej pierwszej wizycie pacjenta w roku kalendarzowym musimy zaraportować do NFZ dane statystyczne." />

      <div className="flex gap-4">
        <Controller
          name="height"
          control={control}
          render={({
            field: { value, onChange, ref: _ref, ...rest },
            fieldState: { error },
          }) => (
            <Input
              sideElement={<span>cm</span>}
              sideElementPosition="end"
              label="Wzrost*"
              type="number"
              placeholder="Kliknij, aby uzupełnić"
              disabled={disabled}
              value={value}
              maxLength={3}
              error={error?.message}
              onChange={(e) => {
                const value = e.target.value;
                if (value.includes(",")) return;
                return onChange(value ? parseInt(value) : null);
              }}
              {...rest}
            />
          )}
        />

        <Controller
          name="weight"
          control={control}
          render={({
            field: { value, onChange, ref: _ref, ...rest },
            fieldState: { error },
          }) => (
            <Input
              sideElement={<span>kg</span>}
              sideElementPosition="end"
              label="Waga*"
              type="text"
              placeholder="Kliknij, aby uzupełnić"
              disabled={disabled}
              value={value}
              maxLength={7}
              error={error?.message}
              onChange={(e) => {
                const value = e.target.value
                  .replace(/[^0-9.,]/g, "")
                  .replace(".", ",");

                const valueSeparatedByComa = value.split(",");
                const moreThanOneComa = valueSeparatedByComa.length > 2;
                const valueStartsWithComa = value === ",";
                const moreThanThreeDecimalPlaces =
                  valueSeparatedByComa[1] && valueSeparatedByComa[1].length > 3;

                if (
                  valueStartsWithComa ||
                  moreThanOneComa ||
                  moreThanThreeDecimalPlaces
                ) {
                  return;
                }

                return onChange(value);
              }}
              {...rest}
            />
          )}
        />
      </div>

      {patientAge >= 15 && (
        <Select
          label="Palenie tytoniu*"
          value={smokingCurrently}
          onChange={(option) => {
            setSmokingCurrently(option);
            setSmokingInThePast(null);
            setSmokingType(null);
            setCiggaretesPerDay(null);
          }}
          placeholder="Wybierz z listy"
          options={smokingCurrentlyOptions}
          disabled={disabled}
        />
      )}

      {smokingCurrently?.value === "doesNotSmoke" && (
        <Select
          label="Palenie tytoniu w przeszłości*"
          value={smokingInThePast}
          onChange={setSmokingInThePast}
          placeholder="Wybierz z listy"
          options={smokingInThePastOptions}
          disabled={disabled}
        />
      )}

      {(smokingCurrently?.value === "smokesEveryday" ||
        smokingCurrently?.value === "smokesSometimes") && (
        <Select
          label="Rodzaj wyrobu tytoniowego*"
          value={smokingType}
          onChange={(option) => {
            setSmokingType(option);
            setCiggaretesPerDay(null);
          }}
          placeholder="Wybierz z listy"
          options={smokingTypeOptions}
          disabled={disabled}
        />
      )}

      {smokingType?.value === "cigarettes" &&
        smokingCurrently?.value === "smokesEveryday" && (
          <Select
            label="Liczba papierosów wypalanych w ciągu doby*"
            value={ciggaretesPerDay}
            onChange={setCiggaretesPerDay}
            placeholder="Wybierz z listy"
            options={numberOfCigarettesPerDayOptions}
            disabled={disabled}
          />
        )}
    </div>
  );
};

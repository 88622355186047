import dayjs from "dayjs";
import { MessageElement } from "components/new/Chat/Message/Message";
import { insuredInMap } from "lib/tools/insuredInMap";

export const getMedicalLeaveContent = (leave?: MessageElement["leave"]) => {
  if (!leave) {
    return <span>Ładowanie...</span>;
  }

  return (
    <span>
      e-Zwolnienie zostało wystawione poprawnie.
      <br />
      <br />
      <b>Ubezpieczeony w</b>
      <br />
      {/* @ts-ignore */}
      {leave.insuredIn ? insuredInMap[leave.insuredIn] : leave.insuredIn}
      <br />
      <br />
      <b>Typ</b>
      <br />
      {leave?.leaveType === "sickLeave" ? "chorobowe" : "na opiekę"}
      <br />
      <br />
      <b>Rozpoznanie główne</b>
      <br />
      {leave?.icd10Main && (
        <>
          {leave?.icd10Main.icd10} {leave?.icd10Main.name}
        </>
      )}
      <br />
      <br />
      <b>Zakres zwolnienia</b>
      <br />
      od{" "}
      {leave?.leaveType === "sickLeave"
        ? dayjs(leave?.inabilitySince).format("D MMMM YYYY")
        : dayjs(leave?.careSince).format("D MMMM YYYY")}{" "}
      do{" "}
      {leave?.leaveType === "sickLeave"
        ? dayjs(leave?.inabilityUntil).format("D MMMM YYYY")
        : dayjs(leave?.careUntil).format("D MMMM YYYY")}
      <br />
      <br />
      {leave?.reason && (
        <>
          <b>Dlaczego zwolnienie wystawione na więcej niż 3 dni wstecz?</b>
          <br />
          {leave?.reason}
          <br />
          <br />
        </>
      )}
      <b>Przekazać e-ZLA płatnikowi (po ustaniu zatrudnienia)?</b>
      <br />
      {leave?.doNotSendPayerInfo ? "Nie, nie przekazuj" : "Tak, przekaż"}
      <br />
      <br />
      {leave?.codes &&
        Object.entries(leave?.codes).filter(([k, v]) => v && k !== "__typename")
          .length > 0 && (
          <>
            <b>Kody literowe</b>
            <br />
            <div style={{ display: "flex", gap: "6px" }}>
              {leave?.codes &&
                Object.entries(leave?.codes)
                  .filter(([k, v]) => v && k !== "__typename")
                  .map(([k]) => <span key={k}>{k}</span>)}
            </div>
            <br />
          </>
        )}
      <b>Zalecenia</b>
      <br />
      {leave.recommendation === "canWalk" ? "Może chodzić" : "Powinien leżeć"}
    </span>
  );
};

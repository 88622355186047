import { envVars } from "envvars";
import Cookies from "js-cookie";
import ky from "ky";
import { ourUploadConfig } from "lib/tools/httpConfigs";

export const uploadFile = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response: any[] = await ky
      .post(ourUploadConfig[envVars.REACT_APP_CONFIG], {
        headers: {
          Authorization: Cookies.get("JWT")
            ? "Bearer " + Cookies.get("JWT")
            : "",
          // "Content-Type": "multipart/form-data",
        },
        body: formData,
      })
      .json();
    return response[0];
  } catch (e) {
    console.error("error uploading file", e);
  }
};

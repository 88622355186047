import { useApolloClient } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { determineAge, determineBirthday } from "@jutro/tools";
import { Button, Input, Toggle } from "@jutro/ui";
import dayjs from "dayjs";
import { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
  FormSchema,
  useFormSchema,
} from "components/PatientDocs/Details/useFormSchema";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";
import { getUserGender } from "components/PatientDocs/tools";
import { CurrentPatient } from "components/PatientDocs/types";
import { Loader } from "components/new";
import { SelectWrapper } from "components/new/SelectWrapper";
import {
  DoctorMSPatient,
  DoctorMSPlacematicAddressInput,
  useDoctorCheckPatientExistsLazyQuery,
  useDoctorUpdateUserAddressMutation,
} from "lib/graphql/megaSchema";
import { createChildPesel } from "lib/tools/createChildPesel";
import { fetchPlaces } from "lib/tools/fetchPlaces";
import { toaster } from "lib/tools/toaster";
import { GenderSelect } from "views/Patients/components/GenderSelect";

type Props = {
  patient: CurrentPatient;
  patientFormData: DoctorMSPatient | any;
  updatePatientLoading: boolean;
  savePatientDetails: (arg: any) => Promise<any>;
};

export const PatientDetails = ({
  patient,
  patientFormData,
  updatePatientLoading,
  savePatientDetails,
}: Props) => {
  const [validatePatientPeselAndPhone] = useDoctorCheckPatientExistsLazyQuery();
  const [updateUserAddressMutation] = useDoctorUpdateUserAddressMutation();

  const schema = useFormSchema(patient.pesel ?? "");

  const client = useApolloClient();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty, isValid },
  } = useForm<FormSchema>({
    resolver: zodResolver(schema, {}, { mode: "async" }),
    reValidateMode: "onSubmit",
    mode: "onChange",
    defaultValues: {
      ...patientFormData,
      flatNumber: patientFormData.address.flatNumber ?? "",
      birthDate: determineBirthday(patient.pesel ?? ""),
      gender: getUserGender(patient.pesel),
      peselNotGivenYet: patient.pesel?.length === 12,
      pesel: patient.pesel,
    },
  });

  const { gender, birthDate, pesel, peselNotGivenYet } = watch();
  const isChild = peselNotGivenYet || determineAge(patient.pesel ?? "") < 18;

  const savePatient = async (data: FormSchema) => {
    const { address, flatNumber, pesel, phone, ...rest } = data;

    if (patient.pesel?.length === 11) {
      const result = await validatePatientPeselAndPhone({
        variables: {
          pesel: pesel ?? "",
          phone: phone ?? "",
        },
        fetchPolicy: "no-cache",
      });

      if (patient.pesel !== pesel && result.data?.isPeselUsed)
        throw new Error("Pacjent o podanym numerze PESEL już istnieje");

      if (patient.phone !== phone && result.data?.isPhoneUsed) {
        throw new Error("Pacjent o podanym numerze telefonu już istnieje");
      }
    }

    if ((address && "teryt" in address) || flatNumber) {
      try {
        await updateUserAddressMutation({
          variables: {
            id: patient.id,
            flatNumber,
            address: address as DoctorMSPlacematicAddressInput,
          },
        });
      } catch (err) {
        throw new Error("Coś poszło nie tak! Spróbuj nadpisać adres pacjenta.");
      }
    }
    await savePatientDetails({ pesel, phone, ...rest });
  };

  useEffect(() => {
    if (!peselNotGivenYet && pesel.length === 12) {
      setValue("pesel", "");
      return;
    }
    if (peselNotGivenYet) {
      setValue("pesel", createChildPesel(birthDate, gender) ?? "");
    }
    if (pesel.length === 11) {
      setValue("birthDate", determineBirthday(pesel));
      setValue("gender", getUserGender(pesel));
    }
  }, [peselNotGivenYet, birthDate, gender, pesel]);

  useEffect(() => {
    reset(patientFormData);
  }, [patientFormData]);

  if (updatePatientLoading) {
    return (
      <div className="min-h-[572px]">
        <Loader size="sm" />
      </div>
    );
  }

  return (
    <form
      className="rounded-lg bg-white"
      onSubmit={handleSubmit((data) => {
        toaster.promise(savePatient(data), {
          loading: "Zapisuje...",
          success: "Zapisano zmiany!",
          error: (error: Error) => error.message,
        });
      })}
    >
      <div className="font-paragraph-2 flex flex-col gap-4 p-4 text-jutro-new-warm-gray-800">
        <div>
          <h3 className="font-semibold">Dane pacjenta</h3>
        </div>

        <div className="flex gap-4 [&>div]:w-full">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { value, onChange } }) => (
              <Input
                label="Imię"
                placeholder="np. Jan"
                value={value}
                onChange={(e) => onChange(e.target.value.trim())}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ field: { value, onChange } }) => (
              <Input
                label="Nazwisko"
                placeholder="np. Kowalski"
                value={value}
                onChange={(e) => onChange(e.target.value.trim())}
              />
            )}
          />
        </div>

        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="pesel"
            render={({ field: { value, onChange } }) => (
              <div className="w-[calc(50%-8px)]">
                <Input
                  label="Numer PESEL"
                  maxLength={11}
                  type="number"
                  disabled={peselNotGivenYet}
                  placeholder={peselNotGivenYet ? "" : "np. 12345678901"}
                  value={pesel.length === 12 ? "" : value}
                  onChange={onChange}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="peselNotGivenYet"
            render={({ field: { value, onChange } }) => (
              <Toggle
                label="Brak nadanego numeru PESEL"
                disabled={patient.pesel?.length === 11}
                isEnabled={value}
                setIsEnabled={onChange}
              />
            )}
          />
        </div>

        <div className="flex w-full gap-4">
          <Controller
            control={control}
            name="birthDate"
            render={({ field: { value, onChange } }) => (
              <SelectWrapper label="Data urodzenia">
                <DateTimePicker
                  format="yyyy-MM-dd"
                  minDate={
                    peselNotGivenYet
                      ? dayjs(determineBirthday(patient.pesel ?? ""))
                          .subtract(3, "month")
                          .toDate()
                      : undefined
                  }
                  maxDate={dayjs(determineBirthday(patient.pesel ?? ""))
                    .add(3, "month")
                    .toDate()}
                  disableClock
                  clearIcon={null}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="RRRR"
                  calendarIcon={false}
                  disabled={!peselNotGivenYet}
                  onChange={(date) => {
                    onChange(dayjs(date).format("YYYY-MM-DD"));
                  }}
                  className={`slots-creator-date-time-picker font-paragraph-1 h-[42px] w-full rounded-lg !border-[1px] !border-solid !border-jutro-new-warm-gray-300 px-[10px] py-[6px] text-jutro-new-warm-gray-800 focus-within:border-jutro-new-blue-800 hover:border-jutro-new-blue-800 ${
                    peselNotGivenYet
                      ? "cursor-pointer bg-white"
                      : "cursor-not-allowed !bg-jutro-new-warm-gray-100"
                  }`}
                  value={value}
                />
              </SelectWrapper>
            )}
          />

          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange } }) => (
              <SelectWrapper label="Płeć">
                <GenderSelect
                  disabled={!peselNotGivenYet}
                  pickedGender={gender}
                  onChange={onChange}
                />
              </SelectWrapper>
            )}
          />
        </div>

        <div className="flex gap-4">
          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <SelectWrapper label="Adres zamieszkania">
                <AsyncSelect
                  isDisabled={false}
                  defaultValue={{
                    value,
                    label: `${value?.city}, ${value?.street} ${value?.houseNumber}, ${value?.zipCode}`,
                  }}
                  styles={adjustedToInputSelectStyles<string | unknown>()}
                  placeholder="Kliknij aby wyszukać"
                  loadingMessage={() => "Szukam..."}
                  noOptionsMessage={() => "Brak wyników."}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  loadOptions={(query: string) => fetchPlaces(client, query)}
                  onChange={(option) => onChange(option?.value)}
                  {...field}
                />
              </SelectWrapper>
            )}
          />

          <div>
            <Controller
              control={control}
              name="flatNumber"
              render={({ field: { value, onChange } }) => (
                <SelectWrapper label="Numer mieszkania">
                  <Input
                    placeholder="np. 12"
                    value={value ?? ""}
                    onChange={(e) => onChange(e.target.value.trim())}
                  />
                </SelectWrapper>
              )}
            />
          </div>
        </div>

        <div className="flex gap-4 [&>div]:w-full">
          <Controller
            control={control}
            name="phone"
            render={({ field: { value, onChange } }) => (
              <Input
                label="Numer telefonu"
                maxLength={9}
                placeholder="np. 900100200"
                type="number"
                onChange={onChange}
                disabled={isChild}
                value={isChild ? "000000000" : value}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ field: { value, onChange } }) => (
              <Input
                label="Adres e-mail"
                placeholder="np. jan.kowalski@poczta.pl"
                onChange={(e) => onChange(e.target.value.trim())}
                value={value ?? ""}
              />
            )}
          />
        </div>
        {isDirty && (
          <div className="grid">
            <Button
              disabled={!isValid}
              type="submit"
              text="Zapisz zmiany"
              loading={updatePatientLoading}
            />
          </div>
        )}
      </div>
    </form>
  );
};

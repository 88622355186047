import { ScheduleVisitTileData } from "components/ScheduleVisitForm/types";
import {
  SnippetCategoryFilter,
  SnippetFormData,
} from "components/Snippet/types";
import { TagForm } from "components/TagFormNew/types";
import { SnippetType } from "lib/graphql/doctor";
import { Drug } from "views/Visit/RightPanel/NewPrescription/types";

export type TileWidth = 1 | 2;

type DataTileRecord<T, C> = { name: T; data: C; id: string };

export type DataTile =
  | DataTileRecord<"file-preview", { fileId: string }>
  | DataTileRecord<"visit-drug-info", Drug>
  | DataTileRecord<"tag-form", TagForm>
  | DataTileRecord<"snippet-form", SnippetFormData>
  | DataTileRecord<
      "snippet-settings",
      { type?: SnippetType; category?: SnippetCategoryFilter }
    >
  | DataTileRecord<"schedule-visit-form", ScheduleVisitTileData>;

export const basicTiles = [
  "chat",
  "docs-of-patient",
  "ipom-form",
  "patient-events",
  "patient-declaration-history",
  "patient-declaration-creator",
  "patient-declaration-edit",
  "patient-ewus-statement",
  "visit-procedure-panel",
  "visit-docs",
  "visit-previous-drugs",
  "visit-ordered-drugs",
  "ipom-form",
  "virtual-assistant-chat",
  "snippets-admin",
] as const;

export type BasicTile = (typeof basicTiles)[number];

export type Tile = BasicTile | DataTile;

export type ExtractDataTile<T extends DataTile["name"]> = Extract<
  DataTile,
  { name: T }
>;

type ExtractedNames<T> = T extends DataTileRecord<infer Name, any> ? Name : T;

export type TileName = ExtractedNames<Tile>;

type TileElement<T> =
  T extends DataTileRecord<any, infer Data>
    ? (args: Data) => JSX.Element
    : JSX.Element;

export type TileConfig = {
  [K in Tile as ExtractedNames<K>]: {
    element: TileElement<K>;
    width: TileWidth;
    label?: string;
  };
};

export const workspaces = [
  "visit-advice",
  "visit-procedure",
  "patient-profile",
  "patient-chat",
  "snippets-admin",
  "settings",
] as const;

export type Workspace = (typeof workspaces)[number];

export enum TileColumn {
  Left,
  Center,
  Right,
}

export type SwapHandler = ({
  currentPosition,
  visibleTiles,
}: {
  currentPosition: TileColumn;
  visibleTiles: Tile[];
}) => TileColumn;

export type AddHandler = ({
  visibleTiles,
}: {
  visibleTiles: Tile[];
}) => TileColumn;

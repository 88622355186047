import { ReactNode } from "react";

export type Color =
  | "teal"
  | "blue"
  | "darkblue"
  | "lightblue"
  | "purple"
  | "gray"
  | "salmon"
  | "red"
  | "lighteal"
  | "green"
  | "defaultBlue"
  | "pink"
  | "lightGray"
  | "white"
  | "transparent"
  | "orange"
  | "orangeBadge"
  | "border";

type textColor = "white" | "black" | "gray" | "orange";

type roundedSize = "sm" | "md" | "lg";

type Props = {
  textColor?: textColor;
  text?: string;
  label?: ReactNode;
  color?: Color;
  mono?: boolean;
  upperCase?: boolean;
  pointer?: boolean;
  big?: boolean;
  customColor?: string;
  rounded?: roundedSize;
  onClick?: () => void;
};

const textMap = {
  white: "text-white",
  black: "text-black",
  gray: "text-jutro-new-warm-gray-800",
  orange: "text-[#FB9600]",
};

const roundedMap: Record<roundedSize, string> = {
  sm: "rounded-sm",
  md: "rounded-md",
  lg: "rounded-lg",
};

const colorMap = (
  mono: boolean,
  textColor: textColor | undefined,
): Record<Color, string> =>
  mono
    ? {
        red: `${
          textColor ? textMap[textColor] : "text-jutro-new-rose-500"
        } bg-jutro-new-rose-200`,
        transparent: `${
          textColor ? textMap[textColor] : "text-black"
        } bg-none font-paragraph-1 whitespace-pre-line	`,
        green: `bg-jutro-new-green-400 bg-opacity-25 ${
          textColor ? textMap[textColor] : "text-jutro-new-green-400"
        }`,
        purple: `${
          textColor ? textMap[textColor] : "text-jutro-purple-monochrome"
        } bg-jutro-purple-light`,
        blue: `bg-jutro-new-blue-500 bg-opacity-25 ${
          textColor ? textMap[textColor] : "text-jutro-new-blue-500"
        }`,
        lightblue: `${
          textColor ? textMap[textColor] : "text-jutro-new-teal-600"
        } bg-blue-100`,
        defaultBlue: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-blue-800`,
        darkblue: `bg-jutro-new-blue-950 bg-opacity-25 ${
          textColor ? textMap[textColor] : "text-jutro-new-blue-950"
        }`,
        gray: `${
          textColor ? textMap[textColor] : "text-jutro-new-warm-gray-700"
        } bg-jutro-new-warm-gray-100`,
        salmon: `${
          textColor ? textMap[textColor] : "text-jutro-salmon-monochrome"
        } bg-jutro-salmon-light`,
        teal: `bg-jutro-new-green-400 bg-opacity-25  ${
          textColor ? textMap[textColor] : "text-jutro-new-green-400"
        }`,
        lighteal: `${
          textColor ? textMap[textColor] : "text-jutro-new-teal-600"
        } bg-jutro-new-teal-100`,
        pink: `${
          textColor ? textMap[textColor] : "text-black"
        } bg-jutro-pink-light`,
        lightGray: `bg-jutro-new-warm-gray-800 ${
          textColor ? textMap[textColor] : "text-white"
        }`,
        white: `border bg-white`,
        orange: `bg-orange-100 ${
          textColor ? textMap[textColor] : "text-white"
        }`,
        border: `border-jutro-new-warm-gray-800 border text-jutro-new-warm-gray-800`,
        orangeBadge: `bg-[#FB9600]/10 ${
          textColor ? textMap[textColor] : "text-[#FB9600]"
        } `,
      }
    : {
        red: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-rose-500`,
        purple: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-purple-monochrome`,
        green: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-green-400`,
        blue: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-blue-500`,
        lightblue: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-teal-600`,
        defaultBlue: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-blue-800`,
        darkblue: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-blue-950`,
        gray: `${
          textColor ? textMap[textColor] : "text-black"
        } bg-jutro-new-warm-gray-100`,
        salmon: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-salmon-monochrome`,
        teal: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-green-400`,
        lighteal: `${
          textColor ? textMap[textColor] : "text-white"
        } bg-jutro-new-teal-600`,
        transparent: `${
          textColor ? textMap[textColor] : "text-black"
        } bg-none font-paragraph-1 whitespace-pre-line	`,
        pink: `${
          textColor ? textMap[textColor] : "text-black"
        } bg-jutro-pink-light`,
        lightGray: `bg-jutro-new-warm-gray-800 ${
          textColor ? textMap[textColor] : "text-white"
        }`,
        white: `border bg-white border-black`,
        orange: `bg-jutro-new-orange-400 ${textColor ? textMap[textColor] : "text-white"}`,
        orangeBadge: `bg-[#FB9600]/10 ${
          textColor ? textMap[textColor] : "text-[#FB9600]"
        } `,
        border: `border-jutro-new-warm-gray-800 border text-jutro-new-warm-gray-800`,
      };

export const Badge = ({
  text,
  color = "defaultBlue",
  mono = false,
  onClick,
  textColor,
  upperCase = true,
  pointer = false,
  rounded = "sm",
  label = undefined,
  big = false,
  customColor,
}: Props) => (
  <div
    className={`${
      customColor
        ? `${customColor} text-white`
        : colorMap(mono, textColor)[color]
    } font-geologica font-paragraph-2 h-max w-max max-w-max items-center px-2 ${
      upperCase && "$ uppercase"
    } ${pointer && "cursor-pointer"} ${roundedMap[rounded]} ${big && "py-1"}`}
    onClick={onClick}
  >
    {text ?? label}
  </div>
);

export const ExaminationItem = ({ icon, examination, date }) => (
  <div className="flex items-center rounded-lg bg-white px-4 py-3 shadow-[0_4px_26px_rgba(0,0,0,0.06)]">
    <img className="mr-5 h-10 w-10 object-contain" src={icon} />
    <div className="flex w-full justify-between">
      <div>
        <div className="mb-[3px] text-[13px] text-jutro-new-warm-gray-800">
          Rodzaj badania
        </div>
        <div className="text-[15px] text-jutro-new-blue-950">{examination}</div>
      </div>
      {date && (
        <div className="min-w-[100px]">
          <div className="mb-[3px] text-[13px] text-jutro-new-warm-gray-800">
            Data pobrania
          </div>
          <div className="text-[15px] text-jutro-new-blue-950">{date}</div>
        </div>
      )}
    </div>
  </div>
);

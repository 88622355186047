import { BinIcon, Button, Tag } from "@jutro/ui";

export const getIcd10Option = (value: string, handleClear?: () => void) => {
  const [code, name] = value.split(/(?<=^\S+)\s/);

  const RightElement = () => {
    if (handleClear) {
      return (
        <div className="flex items-center gap-1">
          <Tag color="green" text={code} />

          <Button
            withTooltip
            tooltipPosition="top"
            label="Usuń"
            size="condensed"
            variant="negativeGhost"
            icon={<BinIcon />}
            onClick={handleClear}
          />
        </div>
      );
    }

    return <Tag color="green" text={code} />;
  };

  return { value, label: name, rightElement: <RightElement /> };
};

import { Avatar } from "@jutro/ui";
import dayjs from "dayjs";
import { ScheduledVisitAppointmentStatus } from "components/new/DialogModal";
import { DoctorMSAppointmentStatus } from "lib/graphql/megaSchema";
import { useAppointmentStatus } from "lib/hooks/useAppointmentStatus";
import { Resource } from "views/Schedule/types";
import { renderTags } from "views/Schedule/utils";

type EventTagsHelperProps = {
  event: Resource;
};

const EventTagsHelper = ({ event }: EventTagsHelperProps) => {
  if (event.tags.length === 0) {
    return null;
  }

  return (
    <div className="font-caption flex items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-jutro-new-warm-gray-100 px-1.5 text-black">
      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
        {renderTags(event)}
      </span>
    </div>
  );
};

type EventProps = {
  event: Resource;
  title: string;
};

export const EventWrapper = ({ event, title }: EventProps) => {
  const visitAppointmentStatus = useAppointmentStatus(
    event.id,
    event.appointmentStatus as DoctorMSAppointmentStatus,
    event.sentNoShowNotifications,
  );
  if (!event.duration) return null;

  const { laboratoryExaminations, doctor } = event;

  return (
    <div className="flex items-center gap-2 text-ellipsis whitespace-nowrap">
      <span>{dayjs(event.start).format("H:mm")}</span>

      <div className="flex gap-2">
        {event.type !== "PROCEDURE" && (
          <ScheduledVisitAppointmentStatus
            preset="EVENT"
            appointmentStatus={visitAppointmentStatus.appointmentStatus}
          />
        )}
        <span>{title}</span>
        <EventTagsHelper event={event} />
        {laboratoryExaminations.length > 0 && (
          <Avatar
            size="xs"
            initials={`${doctor?.firstName?.[0]}${doctor?.lastName?.[0]}`}
          />
        )}
      </div>
    </div>
  );
};

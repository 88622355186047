import { AlertIcon, Button, Input, Toggle } from "@jutro/ui";
import autosize from "autosize";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useDebouncedCallback } from "use-debounce";
import { Block } from "components/Block";
import { useProcedureFormValidation } from "components/ExaminationPanel/ProcedurePanel/hooks/useProcedureFormValidation";
import { Badge } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorLaboratoryExaminationsDocument,
  DoctorMSLaboratoryExaminationEntity,
  useDoctorUpdateLaboratoryExaminationMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { isVisitClosedAtom } from "../atoms";
import { LaboratoryExaminationsList } from "./LaboratoryExaminationsList";

const returnValidNound = (length: number) => {
  if (length === 1) {
    return "pozycję";
  }
  if (length < 5) {
    return "pozycje";
  }

  return "pozycji";
};

const colorMap: Record<string, { bg: string; fallbackText: string }> = {
  czerwony: {
    bg: "bg-jutro-new-rose-500",
    fallbackText: "text-jutro-new-rose-500",
  },
  fioletowy: {
    bg: "bg-jutro-purple-dark",
    fallbackText: "text-jutro-purple-dark",
  },
  niebieski: {
    bg: "bg-jutro-new-blue-500",
    fallbackText: "text-jutro-new-blue-500",
  },
  szary: {
    bg: "bg-jutro-new-warm-gray-600",
    fallbackText: "text-jutro-new-warm-gray-600",
  },
  granatowy: {
    bg: "bg-jutro-new-blue-600",
    fallbackText: "text-jutro-new-blue-600",
  },
  zielony: {
    bg: "bg-jutro-new-green-400",
    fallbackText: "text-jutro-new-green-400",
  },
  różowy: {
    bg: "bg-jutro-pink-light",
    fallbackText: "text-jutro-pink-light",
  },
  żółty: {
    bg: "bg-yellow-200",
    fallbackText: "text-black",
  },
};

const yellowContainers = ["pojemnik/mocz", "pojemnik/mocz10ml", "pojemnik/dzm"];

const brownContainers = ["pojemnik/kał"];

export const returnCorrespondingColors = (colorValue: string) => {
  if (Object.keys(colorMap).includes(colorValue)) {
    return {
      bg: colorMap[colorValue].bg,
      text: "text-black/80",
      fallbackText: colorMap[colorValue].fallbackText,
    };
  }

  if (yellowContainers.includes(colorValue)) {
    return {
      bg: "bg-jutro-yellow",
      text: "text-jutro-new-warm-gray-800",
      fallbackText: "text-jutro-yellow",
    };
  }

  if (brownContainers.includes(colorValue)) {
    return {
      bg: "bg-jutro-brown",
      text: "text-white",
      fallbackText: "text-jutro-brown",
    };
  }

  return {
    bg: "white",
    text: "text-jutro-new-warm-gray-800",
    border: "border",
    fallbackText: "text-black",
  };
};

const notAttachedToReportCode = "000000";

export const SampleElement = ({
  groupId,
  labExaminations,
}: {
  groupId: string;
  labExaminations: DoctorMSLaboratoryExaminationEntity[];
}) => {
  autosize(document.querySelectorAll("textarea"));

  const [isVisitClosed] = useAtom(isVisitClosedAtom);

  const [isSampleCito, setIsSampleCito] = useState(false);

  const [code, setCode] = useState("");

  const [isAttachedToReport, setIsAttachedToReport] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);

  const [firstElementSampleId] = labExaminations.map((e) => {
    return { sampleId: e.sampleId, comment: e.comment };
  });

  const { sampleId, comment } = firstElementSampleId;

  const [updateLaboratoryExaminations] =
    useDoctorUpdateLaboratoryExaminationMutation({
      awaitRefetchQueries: true,
      refetchQueries: [DoctorLaboratoryExaminationsDocument],
    });

  const numberOfItems = labExaminations.length;

  useEffect(() => {
    setIsSampleCito(
      labExaminations.filter((e) => e.cito).length === numberOfItems,
    );
  }, [numberOfItems]);

  const { shouldTrigger, handleSetIsValid, unregisterForm } =
    useProcedureFormValidation();

  useEffect(() => {
    if (shouldTrigger) {
      const isValid = Boolean(code);
      handleSetIsValid(groupId, isValid);
    }
  }, [shouldTrigger]);

  useEffect(() => {
    if (sampleId === notAttachedToReportCode) {
      setIsAttachedToReport(true);
    }
    setCode(sampleId ?? "");

    return () => {
      unregisterForm(groupId);
    };
  }, []);

  const removeAllLabExaminations = async () => {
    try {
      await Promise.all(
        labExaminations.map(async (labExamination) => {
          return await updateLaboratoryExaminations({
            variables: {
              data: {
                sampleId: null,
                groupId: null,
                visitId: null,
              },
              ids: labExamination.id,
            },
            refetchQueries: [DoctorLaboratoryExaminationsDocument],
          });
        }),
      );
    } catch (err) {
      toaster.warning("Nie udało się usunąć probówki");
    }
  };

  const addCitos = async () => {
    try {
      await Promise.all(
        labExaminations.map(async ({ id, sampleId }) => {
          return await updateLaboratoryExaminations({
            variables: {
              data: {
                sampleId,
                cito: !isSampleCito,
              },
              ids: id,
            },
          });
        }),
      );
    } catch (err) {
      toaster.warning("Nie udało się zmienić statusu CITO");
    }
  };

  const assignSampleIds = useDebouncedCallback(async (sampleId: string) => {
    try {
      await Promise.all(
        labExaminations.map(async (labExamination) => {
          return await updateLaboratoryExaminations({
            variables: {
              data: {
                sampleId,
              },
              ids: labExamination.id,
            },
          });
        }),
      );
    } catch (err) {
      toaster.warning("Nie udało się zapisać kodu z naklejki");
    }
  }, 1000);

  const setComment = useDebouncedCallback(async (comment: string) => {
    try {
      await Promise.all(
        labExaminations.map(async ({ sampleId, id }) => {
          return await updateLaboratoryExaminations({
            variables: {
              data: {
                sampleId,
                comment,
              },
              ids: id,
            },
          });
        }),
      );
    } catch (err) {
      toaster.warning("Nie udało się zapisać komentarza");
    }
  }, 1000);

  const checkIfSampleColorValid = () => {
    const arrOfColors = labExaminations.map((labExamination) =>
      labExamination.assignment.color.trim(),
    );
    const allEqual = (arr: string[]) => arr.every((v) => v === arr[0]);

    return allEqual(arrOfColors);
  };

  const sampleColor = labExaminations[0]?.assignment?.color.trim();

  const isSampleColorValid = checkIfSampleColorValid();

  const { bg, text, border } = returnCorrespondingColors(sampleColor);

  return (
    <Block key={groupId}>
      <div className="flex flex-col gap-2 p-2">
        <div className="flex justify-between">
          <Badge
            color="gray"
            text="Zestaw badań laboratoryjnych"
            upperCase={false}
          />
          <Button
            full={false}
            variant="negative"
            size="condensed"
            onClick={() => setConfirmModal(true)}
            text="Usuń z wizyty"
            disabled={isVisitClosed}
          />
        </div>
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col justify-between gap-4">
            <div className="flex gap-4 rounded-lg border p-4">
              <div className="flex w-full flex-col gap-y-2">
                <div className="flex w-full gap-4">
                  <div className="flex-1">
                    <Input
                      value={code}
                      label="Kod z naklejki"
                      onChange={(e) => {
                        const code = e.target.value;
                        setCode(code);
                        assignSampleIds(code);
                      }}
                      placeholder="Kliknij, aby uzupełnić"
                      disabled={isVisitClosed || Boolean(isAttachedToReport)}
                    />
                  </div>
                  <div className="flex flex-1 flex-col gap-2">
                    <div className="font-paragraph-2">
                      Kolor probówki / Rodzaj pojemnika
                    </div>
                    {isSampleColorValid ? (
                      <div
                        className={`h-11 rounded-lg p-2 px-3 ${bg} ${text} ${border}`}
                      >
                        {sampleColor}
                      </div>
                    ) : (
                      <div className="flex h-11 items-center justify-between rounded-lg border border-jutro-new-rose-500 p-2 text-xs text-jutro-new-rose-500">
                        <div>Różne kolory lub rodzaje pojemników</div>
                        <AlertIcon size="sm" />
                      </div>
                    )}
                  </div>
                </div>
                <span className="font-heading-4">Komentarz</span>
                <textarea
                  defaultValue={comment?.toString()}
                  className={`max-h-full resize-none overflow-hidden rounded-lg border p-2`}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Kliknij, aby uzupełnić"
                  maxLength={400}
                  disabled={isVisitClosed}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="rounded-lg border p-2">
                <Toggle
                  disabled={isVisitClosed}
                  isEnabled={isSampleCito}
                  setIsEnabled={async (val) => {
                    await addCitos();
                    setIsSampleCito(val);
                  }}
                  label="CITO"
                />
              </div>

              <div className="rounded-lg border p-2">
                <Toggle
                  disabled={isVisitClosed}
                  isEnabled={isAttachedToReport}
                  setIsEnabled={async (val) => {
                    setCode(notAttachedToReportCode);
                    await assignSampleIds(notAttachedToReportCode);
                    setIsAttachedToReport(val);
                  }}
                  label="Nie załączaj do raportu"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-4 rounded-lg border p-4">
            {/* @ts-ignore */}
            <Droppable droppableId={groupId} isDropDisabled>
              {(provided) => (
                <LaboratoryExaminationsList
                  labExaminations={labExaminations}
                  provided={provided}
                  isSampleColorValid={isSampleColorValid}
                />
              )}
            </Droppable>

            {/* @ts-ignore */}
            <Droppable droppableId={groupId}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`flex h-full items-center justify-center rounded-lg border border-dashed text-center`}
                >
                  <div
                    className={`${
                      snapshot.isDraggingOver && "bg-blue-50"
                    } flex h-full w-full items-center justify-center p-8 text-xs`}
                  >
                    Przeciągnij i upuść badanie laboratoryjne
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
      <ConfirmDialog
        title={`Czy na pewno chcesz usunąć z wizyty ${
          labExaminations.length
        } ${returnValidNound(labExaminations.length)}?`}
        text="Usunięte pozycje pojawią się w zakładce Zlecone z możliwością utworzenia ponownie nowej wizyty zabiegowej."
        confirmText="Usuń z listy"
        open={confirmModal}
        setOpen={setConfirmModal}
        onConfirm={async () => {
          await removeAllLabExaminations();
        }}
      />
    </Block>
  );
};

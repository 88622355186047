import { IpomFormSchema } from "components/IpomForm/schema";

type Props = {
  drug: IpomFormSchema["medicines"][number];
};

export const DrugElement = ({
  drug: { name, dosage, dose, form, quantity },
}: Props) => (
  <div className="flex flex-col gap-2">
    <div className="font-paragraph-1 flex gap-2.5">
      <p>{name}</p>
      <p>{dosage}</p>
    </div>

    <span className="font-paragraph-2">
      {dose ? `${dose}, ` : ""}
      {form}
      {quantity ? `, ${quantity}` : ""}
    </span>
  </div>
);

import { uploadFile } from "lib/tools/uploadFile";

export const uploadPdf = async (
  blob: Blob | null | undefined,
  name: string,
) => {
  if (!blob) {
    return;
  }

  const file = new File([blob], name);

  const res = await uploadFile(file);

  return res;
};

import { useOnClickOutside } from "@jutro/hooks";
import { Button, ReplyArrowIcon, TemplateIcon } from "@jutro/ui";
import Cookies from "js-cookie";
import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { FileWithPath } from "react-dropzone";
import TextareaAutosize from "react-textarea-autosize";
import { OpenSnippetListButton } from "components/Snippet/OpenSnippetListButton";
import { CallToPatient } from "components/components/CallToPatient/index";
import { Dropzone, FileWithPreview } from "components/new/Dropzone/Dropzone";
import { PopupMenu } from "components/new/PopupMenu";
import {
  DoctorPatientsDocument,
  DoctorVisitDocument,
  useDoctorCreateChatMessageMutation,
  useDoctorCreateSurveyMutation,
} from "lib/graphql/megaSchema";
import { useUserGenre } from "lib/hooks/useUserGenre";
import { surveysAndRecommendationsBasic } from "lib/tools/surveyUtils";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";
import { Maybe } from "lib/types";

type NewChatInputProps = {
  patientId: string;
  disabledMessage: Maybe<string>;
};

export const NewChatInput = ({
  patientId,
  disabledMessage,
}: NewChatInputProps) => {
  const [inputValue, setInputValue] = useState("");

  const { isAssistant, isDoctor } = useUserGenre();
  const surveysRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [isSurveyMenuOpen, setIsSurveyMenuOpen] = useState(false);
  const [areChatOptionsVisible, setAreChatOptionsVisible] = useState(true);

  const [filesToUpload, setFilesToUpload] = useState<FileWithPreview[]>([]);

  const [createChatMessageMutation] = useDoctorCreateChatMessageMutation();
  const [createSurvey] = useDoctorCreateSurveyMutation();

  const sendSurvey = (surveyId: string) => {
    try {
      createSurvey({
        variables: {
          input: {
            patientId,
            formId: surveyId,
          },
        },
      });
    } catch (e) {
      console.error("error creating survey: ", e);
    }
  };

  const createChatMessage = async (content: string) => {
    if (!content.trim() && filesToUpload.length === 0) {
      return;
    }

    if (!content.trim() && filesToUpload.length > 0) {
      const file = await createChatFile(filesToUpload);
      setFilesToUpload([]);
      return file;
    }

    const { data } = await createChatMessageMutation({
      variables: {
        id: patientId,
        input: { content },
        isSystem: false,
      },
      refetchQueries: [DoctorVisitDocument, DoctorPatientsDocument],
    });

    if (filesToUpload.length > 0) {
      await createChatFile(filesToUpload);
      setFilesToUpload([]);
    }

    const messageId = data?.doctorCreateChatMessage;

    return messageId;
  };

  const createChatFile = async (files: FileWithPath[]) => {
    const [file] = files;

    toaster.loading("Dodawanie pliku...", { duration: 3000 });

    const fileId = await uploadFile(file);

    if (!fileId) {
      return;
    }

    const { data } = await createChatMessageMutation({
      variables: {
        id: patientId,
        input: { content: `[file-${fileId}]` },
        isSystem: false,
      },
    });

    return data?.doctorCreateChatMessage;
  };

  const showChatOptions = () => setAreChatOptionsVisible(true);
  const hideChatOptions = () => setAreChatOptionsVisible(false);

  useOnClickOutside(surveysRef, () => setIsSurveyMenuOpen(false));

  useEffect(() => {
    const messageObject = Cookies.get("message");

    if (!messageObject) {
      return;
    }

    const { message, patientId: cookiePatientId } = JSON.parse(messageObject);

    if (patientId !== cookiePatientId) {
      setInputValue("");
      return;
    }

    setInputValue(message);
  }, [patientId]);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement | HTMLTextAreaElement>,
  ) => {
    event.preventDefault();

    const messageId = await createChatMessage(inputValue);

    if (!messageId) {
      toaster.notify("Wystąpił błąd przy tworzeniu wiadomości");
      return;
    }

    Cookies.set(
      "message",
      JSON.stringify({
        message: "",
        patientId,
      }),
    );

    setInputValue("");
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (disabledMessage) {
      toaster.error(disabledMessage);
      return;
    }

    const currentValue = event.target.value;

    setInputValue(currentValue);

    Cookies.set(
      "message",
      JSON.stringify({
        message: currentValue,
        patientId,
      }),
    );
  };

  const handleEnterPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== "Enter" || e.shiftKey) {
      return;
    }

    e.preventDefault();
    formRef.current?.requestSubmit();
  };

  const handleOnIconClick = () => {
    if (disabledMessage) {
      toaster.error(disabledMessage);
      return;
    }

    setIsSurveyMenuOpen((prev) => !prev);
  };

  return (
    <>
      <div className="flex gap-2">
        {areChatOptionsVisible && (
          <div className="flex gap-2">
            <OpenSnippetListButton type="CHAT" size="regular" />

            <div ref={surveysRef} className="relative">
              <Button
                full={false}
                icon={<TemplateIcon />}
                label="Wyślij ankietę"
                tooltipPosition="top"
                onClick={handleOnIconClick}
                variant="ghost"
              />

              <PopupMenu
                position="top-right"
                items={surveysAndRecommendationsBasic
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ id, name, recommendations }) => ({
                    id,
                    label: name,
                    action: recommendations
                      ? () => createChatMessage(`[recommendation-${id}]`)
                      : () => sendSurvey(id),
                  }))}
                active={isSurveyMenuOpen}
              />
            </div>
            {(isAssistant || isDoctor) && <CallToPatient />}
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          className="flex min-w-0 flex-1 items-center justify-between rounded-lg border px-2"
          ref={formRef}
        >
          <TextareaAutosize
            className="no-scrollbar font-paragraph-1 max-h-40 min-h-20 flex-1 resize-none py-4 outline-none"
            placeholder="Napisz wiadomość..."
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleEnterPress}
            onSubmit={handleSubmit}
            onFocus={hideChatOptions}
            onBlur={showChatOptions}
          />
          <button
            type="submit"
            className="h-6 w-6 cursor-pointer text-jutro-new-warm-gray-100 transition-all hover:text-jutro-new-blue-800"
          >
            <ReplyArrowIcon />
          </button>
        </form>
      </div>

      <Dropzone
        addFileText="Dodaj do wiadomości"
        maxFiles={1}
        isModal
        selectedFiles={filesToUpload}
        onFilesListUpdate={setFilesToUpload}
      />
    </>
  );
};

import { ToggleButton } from "@jutro/ui";
import { SetStateAction } from "jotai";
import { Dispatch, useEffect, useState } from "react";
import {
  IpomFormSchema,
  diagnosticTestTimeOptions,
  diagnosticTestTimeUnitOptions,
} from "components/IpomForm/schema";
import { ConfirmDialog } from "components/new/ConfirmDialog";

type DiagnosticTest = IpomFormSchema["scheduledDiagnosticTests"][number];

type Props = {
  diagnosticTest: DiagnosticTest;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onTimeChange: (
    diagnosticTest: Pick<DiagnosticTest, "time" | "timeUnit" | "timeValue">,
  ) => void;
};

export const DiagnosticTestModal = ({
  isOpen,
  setIsOpen,
  diagnosticTest,
  onTimeChange: onExecutionChange,
}: Props) => {
  const { name, time, timeUnit, timeValue } = diagnosticTest;

  const [timeData, setTimeData] = useState({ time, timeUnit, timeValue });

  useEffect(() => {
    const { time, timeUnit, timeValue } = diagnosticTest;

    setTimeData({ time, timeUnit, timeValue });
  }, [diagnosticTest]);

  return (
    <ConfirmDialog
      open={isOpen}
      setOpen={(open) => {
        setIsOpen(open);
      }}
      confirmText="Zapisz"
      submitVariant="primary"
      onConfirm={() => onExecutionChange(timeData)}
      width="xl"
    >
      <div className="flex flex-col gap-4">
        <span className="font-label text-jutro-new-warm-gray-800">
          Moment wykonania: {name}
        </span>

        <div className="flex gap-4 rounded-md border border-jutro-new-warm-gray-200 p-4">
          {diagnosticTestTimeOptions.map(({ label, value }) => (
            <ToggleButton
              key={value}
              text={label}
              active={value === timeData.time}
              size="condensed"
              onClick={() => {
                if (value !== "INTERWAL") {
                  setTimeData({
                    time: value,
                    timeUnit: null,
                    timeValue: null,
                  });
                  return;
                }

                setTimeData({
                  time: value,
                  ...diagnosticTestTimeUnitOptions[0].value,
                });
              }}
            />
          ))}
        </div>

        {timeData.time === "INTERWAL" ? (
          <>
            <span className="font-label text-jutro-new-warm-gray-800">
              Interwał
            </span>
            <div className="flex flex-wrap gap-4 rounded-md border border-jutro-new-warm-gray-200 p-4">
              {diagnosticTestTimeUnitOptions.map(({ label, value }) => (
                <ToggleButton
                  key={JSON.stringify(value)}
                  text={label}
                  active={
                    value.timeUnit === timeData.timeUnit &&
                    value.timeValue === timeData.timeValue
                  }
                  size="condensed"
                  onClick={() => {
                    setTimeData((prev) => ({
                      ...prev,
                      ...value,
                    }));
                  }}
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </ConfirmDialog>
  );
};

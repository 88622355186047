import { ApolloError, useApolloClient } from "@apollo/client";
import { Button, Input } from "@jutro/ui";
import dayjs from "dayjs";
import { add } from "ramda";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";
import { Checkbox, Loader } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  EventSingleDocument,
  EventSingleQuery,
  EventSingleQueryVariables,
  ReportGenerateDocument,
  ReportGenerateQuery,
  ReportGenerateQueryVariables,
  SwiadGenerateDocument,
  SwiadGenerateQuery,
  SwiadGenerateQueryVariables,
  useEventSearchQuery,
  useLoadUMXMutation,
  useReportCleanMutation,
  useReportPrepareMutation,
  useSwiadPrepareMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { JSONView } from "views/NFZReports/components/JSONView";
import {
  declarationGenreOptions,
  downloadFile,
  organizationSelectOptions,
  periodSymbolOptions,
  typeOptions,
} from "views/NFZReports/tools";
import { FormType } from "views/NFZReports/types";

export const NFZReports = () => {
  const [timerValue, setTimerValue] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerValue(add(100));
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [timerActive]);

  const [organizationId, setOrganizationId] = useState("PM");
  const [periodSymbol, setPeriodSymbol] = useState(dayjs().format("YYYY-MM"));
  const [type, setType] = useState<FormType>("DEKLARACJA");
  const [swiadVersion, setSwiadVersion] = useState("1");

  const [declarationGenre, setDeclarationGenre] = useState("DOCTOR");
  const [realRun, setRealRun] = useState(false);
  const [search, setSearch] = useState("");
  const [popup, setPopup] = useState<false | string>(false);
  const [preparePopup, setPreparePopup] = useState<false | string>(false);

  const [isUmxModalOpen, setIsUmxModalOpen] = useState(false);
  const [umxContent, setUmxContent] = useState("");

  const client = useApolloClient();

  const handleOnError = (e: ApolloError) => {
    setTimerActive(false);
    toaster.error(`Błąd: ${e.message}`);
  };

  const { data, loading } = useEventSearchQuery({
    variables: {
      organizationId,
      periodSymbol,
      type,
      search,
    },
  });

  const [loadUMX] = useLoadUMXMutation({
    variables: {
      umxContent,
      organizationId,
    },
  });

  const [prepareReport] = useReportPrepareMutation({
    variables: {
      organizationId,
      periodSymbol,
      declarationGenre,
      realRun,
    },
    onCompleted: (result) => {
      setTimerActive(false);
      setPreparePopup(JSON.parse(result.doctorReportPrepare));
    },
    onError: handleOnError,
  });

  const [prepareSwiad] = useSwiadPrepareMutation({
    variables: {
      organizationId,
      periodSymbol,
      version: swiadVersion,
      realRun,
    },
    onCompleted: ({ doctorSwiadPrepare }) => {
      setTimerActive(false);

      if (doctorSwiadPrepare === "ok") {
        toaster.success("Przygotowano świadczenie");
        return;
      }

      setPreparePopup(JSON.parse(doctorSwiadPrepare));
    },
    onError: handleOnError,
  });

  const [cleanReports] = useReportCleanMutation({
    variables: {
      organizationId,
      periodSymbol,
      type,
    },
  });

  const handleGenerate = async () => {
    if (type === "SWIADCZENIE") {
      const res = await client.query<
        SwiadGenerateQuery,
        SwiadGenerateQueryVariables
      >({
        query: SwiadGenerateDocument,
        variables: {
          organizationId,
          periodSymbol,
          version: swiadVersion,
        },
      });

      downloadFile("report.xml", res.data.doctorSwiadGenerate);
      return;
    }

    const res = await client.query<
      ReportGenerateQuery,
      ReportGenerateQueryVariables
    >({
      query: ReportGenerateDocument,
      variables: {
        organizationId,
        periodSymbol,
        version: "1",
      },
    });

    downloadFile("report.xml", res.data.doctorReportGenerate);
  };

  if (preparePopup)
    return (
      <div className="flex h-screen flex-col gap-4 overflow-auto py-3">
        <Button
          text="Zakończ"
          variant="negative"
          full={false}
          onClick={() => {
            setPreparePopup(false);
          }}
        />

        <div className="flex flex-col gap-6 rounded-lg bg-white px-10 py-4">
          {Object.keys(preparePopup).map((key) => (
            <div key={key} className="flex flex-col gap-2">
              <h3 className="font-heading-2">{key}</h3>
              <div key={key} className="flex flex-col gap-1">
                {/* @ts-expect-error */}
                {preparePopup[key].map((patientId) => (
                  <div key={patientId}>
                    <a
                      href={`/patients/${patientId}/profile`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {patientId}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  if (popup)
    return (
      <div className="h-screen overflow-auto py-3">
        <Button
          text="Zakończ"
          variant="negative"
          full={false}
          onClick={() => {
            setPopup(false);
          }}
        />

        <JSONView content={popup} />
      </div>
    );

  if (timerActive)
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="font-paragraph-0 rounded-lg bg-white p-10 shadow-lg">
          Generowanie:
          {Math.floor(timerValue / 1000)}s
        </div>
      </div>
    );

  return (
    <>
      <div className="flex h-full gap-4">
        {/* Left section */}
        <div className="flex w-1/3 flex-col justify-between gap-4 rounded-lg bg-white p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <span className="font-heading-4">Województwo</span>
              <ReactSelect
                options={organizationSelectOptions}
                value={organizationSelectOptions.find(
                  ({ value }) => value === organizationId,
                )}
                onChange={(data) => setOrganizationId(data!.value)}
                styles={adjustedToInputSelectStyles<string>()}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="font-heading-4">Okres</span>
              <ReactSelect
                options={periodSymbolOptions}
                value={periodSymbolOptions.find(
                  ({ value }) => value === periodSymbol,
                )}
                onChange={(data) => setPeriodSymbol(data!.value)}
                styles={adjustedToInputSelectStyles<string>()}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="font-heading-4">Typ</span>
              <ReactSelect
                options={typeOptions}
                value={typeOptions.find(({ value }) => value === type)}
                onChange={(data) => setType(data!.value)}
                styles={adjustedToInputSelectStyles<FormType>()}
              />
            </div>

            {type === "SWIADCZENIE" ? (
              <Input
                label="Wersja"
                value={swiadVersion}
                type="number"
                isFloat
                onChange={(e) => setSwiadVersion(e.target.value)}
              />
            ) : null}

            {type === "DEKLARACJA" ? (
              <div className="flex flex-col gap-2">
                <span className="font-heading-4">Deklaracja</span>
                <ReactSelect
                  options={declarationGenreOptions}
                  value={declarationGenreOptions.find(
                    ({ value }) => value === declarationGenre,
                  )}
                  onChange={(data) => setDeclarationGenre(data!.value)}
                  styles={adjustedToInputSelectStyles<string>()}
                />
              </div>
            ) : null}

            <Button
              onClick={() => {
                setIsUmxModalOpen(true);
              }}
              text="Dodaj UMX"
              type="button"
              variant="secondary"
            />
          </div>

          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-2">
              <Checkbox
                label="Przygotuj faktycznie"
                value={realRun}
                onChange={(checked) => setRealRun(checked)}
              />
              <Button
                onClick={() => {
                  setTimerValue(0);
                  setTimerActive(true);

                  if (type === "SWIADCZENIE") {
                    prepareSwiad();
                    return;
                  }

                  prepareReport();
                }}
                text="Przygotuj"
                type="button"
                variant="secondary"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Button
                onClick={() => {
                  toaster.promise(handleGenerate(), {
                    loading: "Ładowanie...",
                    success: "Wygenerowano plik XML",
                    error: (err: ApolloError) => `Błąd: ${err.message}`,
                  });
                }}
                text="Generuj XML"
                type="button"
                variant="primary"
              />

              <Button
                onClick={() => {
                  toaster.promise(cleanReports(), {
                    loading: "Ładowanie...",
                    success: "Wyczyszczono raporty",
                    error: (err: ApolloError) => `Błąd: ${err.message}`,
                  });
                }}
                text="Wyczyść"
                type="button"
                variant="negative"
              />
            </div>
          </div>
        </div>

        {/* Right section */}
        <div className="flex w-full flex-col gap-4">
          <div className="rounded-lg bg-white p-2">
            <Input value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>

          <div className="overflow-auto">
            {loading ? (
              <Loader color="darkBlue" />
            ) : (
              data?.doctorReports.map((event) => (
                <div
                  key={event.sourceId}
                  onClick={async () => {
                    const res = await client.query<
                      EventSingleQuery,
                      EventSingleQueryVariables
                    >({
                      query: EventSingleDocument,
                      variables: {
                        organizationId,
                        periodSymbol,
                        type,
                        sourceId: event.sourceId,
                        version: event.version,
                      },
                    });

                    setPopup(res.data.doctorReports[0].data);
                  }}
                >
                  {event.sourceId}
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={isUmxModalOpen}
        setOpen={setIsUmxModalOpen}
        title=""
        confirmText="Dodaj UMX"
        submitVariant="primary"
        onConfirm={() => {
          loadUMX();
        }}
      >
        <div className="flex flex-row items-start gap-2">
          <textarea
            className="min-h-40 w-full rounded-lg border border-jutro-new-warm-gray-300 p-2 text-xs focus:border-jutro-new-blue-500 focus:outline-none focus:ring-jutro-new-blue-500"
            value={umxContent}
            onChange={(e) => setUmxContent(e.target.value)}
          />
        </div>
      </ConfirmDialog>
    </>
  );
};

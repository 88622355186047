import { SolidCircleRemoveIcon } from "@jutro/ui";

export const VisitArchiveEmptyInfo = () => {
  return (
    <div className="flex flex-1 items-center justify-center rounded-lg bg-white p-4">
      <div className="flex flex-col items-center gap-4 p-4">
        <div className="text-jutro-new-warm-gray-200 [&>svg]:!h-16 [&>svg]:!w-16">
          <SolidCircleRemoveIcon />
        </div>
        <h3 className="font-heading-3">Brak wyników spełniających kryteria</h3>
      </div>
    </div>
  );
};

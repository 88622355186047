import { AddCircleIcon, BinIcon, Button } from "@jutro/ui";
import { useMemo } from "react";
import DatePicker from "react-date-picker";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { InputWrapper } from "components/TagFormNew/InputWrapper";
import { TagFormSchema } from "components/TagFormNew/hooks";
import { CalendarIcon } from "components/components/CalendarIcon";
import { useDoctorMedicineSubtitlesTagsQuery } from "lib/graphql/megaSchema";

export const MedicineListSection = () => {
  const { control, watch } = useFormContext<TagFormSchema>();

  const currentType = watch("type");
  const { data } = useDoctorMedicineSubtitlesTagsQuery({
    variables: { type: currentType! },
    skip: !currentType,
  });

  const subtitlesOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    const { doctorMedicineSubtitles } = data;

    return doctorMedicineSubtitles.map(({ subtitle }) => {
      return { label: subtitle, value: subtitle };
    });
  }, [data]);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data.medicineList",
  });

  const isChronicOrDrugs = ["REGULAR_DRUG", "CHRONIC_DISEASE"].includes(
    currentType!,
  );

  if (isChronicOrDrugs) {
    return null;
  }

  return (
    <>
      {fields.map(({ id }, index) => {
        return (
          <div key={id} className="flex items-center gap-8">
            <InputWrapper header="Data">
              <Controller
                name={`data.medicineList.${index}.date`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="date-picker-wrapper">
                    <DatePicker
                      format="yyyy-MM-dd"
                      calendarIcon={<CalendarIcon size={32} />}
                      monthPlaceholder="MM"
                      yearPlaceholder="RRRR"
                      dayPlaceholder="DD"
                      clearIcon={null}
                      value={value}
                      onChange={(option) => onChange(option as Date)}
                      locale="pl"
                    />
                  </div>
                )}
              />
            </InputWrapper>
            <InputWrapper header="Podtytuł">
              <Controller
                name={`data.medicineList.${index}.subtitle`}
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CreatableSelect
                    isMulti={false}
                    placeholder={"Kliknij, aby uzupełnić"}
                    formatCreateLabel={() => "Dodaj nowy podtytuł..."}
                    options={subtitlesOptions}
                    name={name}
                    onChange={(val) => {
                      onChange(val?.value);
                    }}
                    ref={ref}
                    value={value ? { value: value, label: value } : undefined}
                    noOptionsMessage={() =>
                      "Brak wyników, dodaj nowy podtytuł.."
                    }
                  />
                )}
              />
            </InputWrapper>
            {index !== 0 && (
              <div className="mt-7">
                <Button
                  full={false}
                  icon={<BinIcon />}
                  label="Usuń"
                  tooltipPosition="top"
                  size="condensed"
                  variant="negativeGhost"
                  onClick={() => {
                    remove(index);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
      <Button
        full={false}
        size="condensed"
        variant="ghost"
        type="button"
        onClick={() => {
          append({ date: new Date(), subtitle: "" });
        }}
        icon={<AddCircleIcon />}
        text="Dodaj kolejną datę"
      />
    </>
  );
};

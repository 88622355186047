import { DoctorChatMessagesQuery } from "lib/graphql/megaSchema";
import { ArrayElement } from "lib/types";
import { MessageHeader } from "./NewMessageUtils/MessageHeader";

type Assignment = Exclude<
  ArrayElement<DoctorChatMessagesQuery["doctorChatMessages"]>["assignment"],
  null | undefined
>;

type Props = {
  positions: Assignment["positions"];
};

export const ImageExamPaymentMessage = ({ positions }: Props) => {
  if (!positions) {
    return null;
  }

  const calculatedPrice = positions.reduce((total, position) => {
    if (position?.isNFZ) {
      return total;
    }

    return total + (position?.price ?? 0);
  }, 0);

  const summedPrice = positions.reduce((total, position) => {
    return total + (position?.price ?? 0);
  }, 0);

  const finalPrice = summedPrice - calculatedPrice;

  return (
    <div className="calcContainer font-geologica relative my-9 ml-0">
      <MessageHeader mine={false} />
      <div className="rounded-lg rounded-tl-none bg-jutro-new-warm-gray-50">
        <div className="font-paragraph-2 whitespace-pre-line break-words px-2 py-3 text-jutro-new-blue-950">
          Badania, które zostały zlecone pacjentowi
        </div>
        <div className="flex w-full flex-col overflow-hidden rounded-bl-lg rounded-br-lg bg-white">
          <div className="flex w-full flex-col border border-jutro-new-warm-gray-50 pb-3 pr-3 pt-4">
            <div className="flex flex-col gap-y-4 p-3 py-0">
              <div className="flex flex-col justify-center gap-3">
                {positions.map((position) => {
                  if (!position) {
                    return null;
                  }

                  const { isNFZ, serviceName, price, serviceId } = position;

                  return (
                    <div
                      key={serviceId}
                      className="flex items-center justify-between"
                    >
                      <div className="font-paragraph-2 w-4/5">
                        {serviceName}
                      </div>
                      <div
                        className={`font-paragraph-2 ${
                          isNFZ ? "text-jutro-new-green-400" : "text-black"
                        }`}
                      >
                        {`${(price ?? 0).toFixed(2)} zł`}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="font-paragraph-2 flex items-center justify-between">
                  <span>Całkowity koszt</span>
                  <span>{summedPrice.toFixed(2)} zł</span>
                </div>
                <div className="font-paragraph-2 flex items-center justify-between">
                  <span>Opłaca Jutro Medical</span>
                  <span className="text-jutro-new-green-400">
                    -{finalPrice.toFixed(2)} zł
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <span className="font-paragraph-2">Podsumowanie</span>
                <span className="font-heading-3">
                  {calculatedPrice.toFixed(2)} zł
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

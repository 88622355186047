import {
  ScheduledVisitAppointmentIconStylesProps,
  getScheduledVisitAppointmentIconStyles,
} from "./styles";

export const VisitConfirmedBySmsIcon = (
  props: ScheduledVisitAppointmentIconStylesProps,
) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={getScheduledVisitAppointmentIconStyles(props)}
    >
      <path
        d="M0.812012 8.00226C0.812012 3.58398 4.39373 0.0022583 8.81201 0.0022583C13.2303 0.0022583 16.812 3.58398 16.812 8.00226C16.812 12.4205 13.2303 16.0023 8.81201 16.0023C4.39373 16.0023 0.812012 12.4205 0.812012 8.00226Z"
        fill="#ECFDF9"
      />
      <g clipPath="url(#clip0_1040_8271)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25193 0.0194812C6.91661 0.111257 5.61974 0.542009 4.48475 1.27071C3.45744 1.93028 2.5511 2.86881 1.93187 3.91427C0.538542 6.2666 0.437518 9.15642 1.66341 11.5943C2.54293 13.3433 4.04725 14.7215 5.85993 15.4389C7.10661 15.9323 8.42018 16.1027 9.76394 15.9454C11.5355 15.738 13.1947 14.9312 14.4678 13.6581C15.4463 12.6795 16.1343 11.5036 16.5223 10.1463C16.6542 9.68471 16.7635 9.04934 16.7898 8.59026C16.7958 8.48687 16.8068 8.40226 16.8143 8.40226C16.8218 8.40226 16.8279 8.21463 16.8279 7.98532C16.8279 7.75601 16.8217 7.5722 16.8142 7.57687C16.8066 7.58154 16.7956 7.50457 16.7896 7.40582C16.7606 6.92666 16.6579 6.33812 16.5153 5.83427C15.6406 2.74374 13.0081 0.470649 9.83593 0.0667932C9.34965 0.00488923 8.73251 -0.0135428 8.25193 0.0194812ZM8.31593 1.01998C7.36371 1.10036 6.57459 1.31345 5.74392 1.71449C4.34936 2.38775 3.18998 3.5514 2.51245 4.95785C2.168 5.67287 1.95582 6.40409 1.85656 7.21827C1.81603 7.55076 1.81624 8.45671 1.85693 8.78626C1.94622 9.50958 2.10801 10.1179 2.38173 10.7596C3.03614 12.2938 4.26979 13.5848 5.76393 14.2989C6.51123 14.656 7.18073 14.8501 8.03593 14.9576C8.35574 14.9978 9.26813 14.9978 9.58793 14.9576C10.4671 14.8471 11.1384 14.6493 11.9079 14.2738C13.3886 13.5513 14.5899 12.2848 15.2418 10.759C15.5152 10.119 15.6778 9.50826 15.7669 8.78626C15.8077 8.45631 15.8079 7.55054 15.7672 7.21827C15.5497 5.43879 14.7495 3.91423 13.4119 2.73105C12.5167 1.93913 11.3715 1.37577 10.1752 1.13878C9.71022 1.04667 9.46309 1.02334 8.89993 1.01839C8.61833 1.01591 8.35553 1.01663 8.31593 1.01998ZM0.818926 8.00226C0.818926 8.23547 0.821166 8.33087 0.823886 8.21426C0.826622 8.09766 0.826622 7.90687 0.823886 7.79027C0.821166 7.67366 0.818926 7.76906 0.818926 8.00226Z"
          fill="#029C7B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.06916 7.5443C4.06916 5.15407 6.26112 3.32555 8.81916 3.32555C11.3772 3.32555 13.5692 5.15407 13.5692 7.5443C13.5692 7.55401 13.5689 7.56373 13.5683 7.57342C13.5061 8.64051 13.0589 9.64881 12.3098 10.4113C11.595 11.1388 10.6516 11.5954 9.64077 11.7062L7.52101 13.2601C7.36906 13.3714 7.1674 13.388 6.99936 13.3028C6.83131 13.2176 6.72541 13.0452 6.72541 12.8568V11.3451C6.02701 11.062 5.41398 10.5976 4.95136 9.99707C4.41213 9.29708 4.10396 8.44671 4.06954 7.56377C4.06929 7.55729 4.06916 7.55079 4.06916 7.5443ZM5.06918 7.53462C5.0974 8.20674 5.33293 8.85376 5.74356 9.38681C6.15618 9.92245 6.72518 10.3165 7.37173 10.5144C7.58189 10.5787 7.72541 10.7727 7.72541 10.9925V11.8703L9.16106 10.818C9.23637 10.7628 9.32562 10.7298 9.41872 10.7227C10.2432 10.6599 11.017 10.3003 11.5964 9.71046C12.1725 9.12412 12.5177 8.34987 12.5691 7.52984C12.5601 5.81919 10.9485 4.32555 8.81916 4.32555C6.6878 4.32555 5.07524 5.82197 5.06918 7.53462Z"
          fill="#029C7B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1702 6.04506C11.3681 6.23774 11.3722 6.55429 11.1795 6.75211L8.62653 9.37316C8.52539 9.47699 8.38418 9.53182 8.23947 9.52345C8.09477 9.51508 7.96082 9.44432 7.87234 9.32952L6.60879 7.69015C6.44021 7.47143 6.48086 7.15747 6.69957 6.98889C6.91829 6.82032 7.23225 6.86096 7.40083 7.07968L8.31244 8.26244L10.4632 6.05436C10.6559 5.85654 10.9724 5.85238 11.1702 6.04506Z"
          fill="#029C7B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1040_8271">
          <path
            d="M0.812012 2.00226C0.812012 0.897689 1.70744 0.0022583 2.81201 0.0022583H14.812C15.9166 0.0022583 16.812 0.897689 16.812 2.00226V14.0023C16.812 15.1068 15.9166 16.0023 14.812 16.0023H2.81201C1.70744 16.0023 0.812012 15.1068 0.812012 14.0023V2.00226Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import { replacePolishDiacritics } from "@jutro/tools";
import { Input, SearchIcon } from "@jutro/ui";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "components/new";
import { SideSheet } from "components/new/Drawer";
import { useDoctorGetMySeenPostsQuery } from "lib/graphql/megaSchema";
import { usePosts } from "lib/hooks/news";
import { NewsPostOverview } from "./Overview";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const NewsPostList = ({ open, onClose }: Props) => {
  const [searchText, setSearchText] = useState("");
  const { data: posts, loading: isLoading } = usePosts();
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location]);

  const { data: mySeenPostsData } = useDoctorGetMySeenPostsQuery();

  if (!isLoading && posts?.length === 0)
    <div className="grid h-screen place-items-center gap-20 rounded-lg bg-white p-4">
      <div className="font-display-2 text-jutro-new-warm-gray-700">
        nie ma żadnych artykułów
      </div>
    </div>;

  return (
    <SideSheet
      isShown={open}
      onCloseComplete={() => {
        onClose();
      }}
      width={800}
      shouldCloseOnEscapePress={false}
    >
      <section style={{ margin: "27px 30px 27px 30px", color: "#425A70" }}>
        <h1 className="font-display-3">Aktualności</h1>

        <hr className="my-3" />

        <Input
          id="news-search-input"
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sideElement={<SearchIcon size="sm" />}
          sideElementPosition="start"
        />

        <div className="mt-3 flex flex-col space-y-2">
          {isLoading ? (
            <div className="h-96">
              <Loader />
            </div>
          ) : posts && posts.length > 0 ? (
            posts
              .filter((post) =>
                searchText
                  ? replacePolishDiacritics(JSON.stringify(post)).match(
                      new RegExp(replacePolishDiacritics(searchText), "gi"),
                    )
                  : true,
              )
              .map((post) => (
                <NewsPostOverview
                  key={post.id}
                  data={post}
                  seenPostsIds={mySeenPostsData?.doctorMe.seenPosts}
                />
              ))
          ) : (
            <div>Brak aktualności</div>
          )}
        </div>
      </section>
    </SideSheet>
  );
};

import { Controller, useFormContext } from "react-hook-form";
import { Radio } from "components/new/Radio";

type Option = {
  label: string;
  optionValue: string | number | boolean | null;
};

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  options: Option[];
};

export const ControlledRadio = ({ label, disabled, options, name }: Props) => {
  const { control } = useFormContext();
  return (
    <div className="flex flex-col gap-2">
      <p className="font-paragraph-2">{label}</p>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <Radio
            disabled={disabled}
            color="green"
            size="xs"
            error={error}
            {...field}
            onChange={onChange}
            position="row"
            options={options}
          />
        )}
      />
    </div>
  );
};

import { useEffect, useState } from "react";
import { ThreadOverview } from "components/Outercom/Thread/Overview";
import { OUTERCOM_URL, channelNameMap } from "components/Outercom/tools";
import { ChannelName, Thread } from "components/Outercom/types";
import { getParams } from "lib/tools/getParams";

const params = getParams();

const channelNames = params.channel
  ? [params.channel as ChannelName]
  : (Object.keys(channelNameMap) as ChannelName[]);

export const ThreadsView = () => {
  const [threads, setThreads] = useState<
    Partial<Record<ChannelName, Thread[]>>
  >({});

  const fetchThreads = () =>
    channelNames.map((channelName) => {
      fetch(`${OUTERCOM_URL}/threads?channelName=${channelName}`)
        .then((res) => res.json())
        .then((json) => {
          setThreads((p) => ({ ...p, [channelName]: json }));
        });
    });

  useEffect(() => {
    fetchThreads();

    const intervalId = setInterval(() => {
      fetchThreads();
    }, 10 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className={`${
        params.channel ? "flex" : "font-paragraph-2 grid grid-cols-5"
      } h-screen divide-x bg-jutro-new-warm-gray-50`}
    >
      {channelNames.map((channelName) => {
        const channelThreads = threads[channelName];

        if (!channelThreads) return null;

        return (
          <div key={channelName} className="flex w-full flex-col gap-2 p-2">
            <div className="font-heading-1">
              <a
                className="hover:opacity-75"
                href={`?page=outercom-threads&channel=${channelName}`}
              >
                {channelNameMap[channelName]}
              </a>
            </div>

            {channelThreads.map((thread) => (
              <ThreadOverview key={thread.id} thread={thread} isPanel />
            ))}
          </div>
        );
      })}
    </div>
  );
};

import {
  ScheduledVisitAppointmentIconStylesProps,
  getScheduledVisitAppointmentIconStyles,
} from "./styles";

export const VisitMessageSentIcon = (
  props: ScheduledVisitAppointmentIconStylesProps,
) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={getScheduledVisitAppointmentIconStyles(props)}
    >
      <path
        d="M0 8.39368C0 3.9754 3.58172 0.393677 8 0.393677C12.4183 0.393677 16 3.9754 16 8.39368C16 12.812 12.4183 16.3937 8 16.3937C3.58172 16.3937 0 12.812 0 8.39368Z"
        fill="#EBF2FF"
      />
      <g clipPath="url(#clip0_892_18425)">
        <g clipPath="url(#clip1_892_18425)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.4963 0.406983C5.97414 0.496633 4.43338 1.0613 3.22031 1.9741C1.4628 3.29658 0.344949 5.20818 0.0621699 7.37473C-0.0401978 8.15899 -0.0153613 9.05775 0.130859 9.86169C0.318388 10.8926 0.756093 11.9637 1.35171 12.8489C2.57482 14.6668 4.48505 15.9089 6.62574 16.2783C7.52573 16.4335 8.474 16.4335 9.37399 16.2783C10.9106 16.0131 12.3468 15.2933 13.4857 14.2176C14.8567 12.9226 15.707 11.2442 15.9451 9.3632C16.0358 8.64628 16.013 7.76439 15.8844 7.01956C15.5914 5.32147 14.743 3.74939 13.4797 2.56343C11.8588 1.04195 9.73177 0.27534 7.4963 0.406983ZM7.54751 1.474C6.32898 1.57097 5.27263 1.91701 4.27864 2.54483C3.45482 3.06517 2.67134 3.84864 2.15101 4.67246C1.57896 5.57817 1.24342 6.5293 1.10923 7.62554C1.06652 7.9744 1.06652 8.81297 1.10923 9.16183C1.24342 10.2581 1.57896 11.2092 2.15101 12.1149C2.67134 12.9387 3.45482 13.7222 4.27864 14.2425C5.18303 14.8138 6.13881 15.1509 7.23172 15.2844C7.5808 15.327 8.41954 15.327 8.76801 15.2843C9.86162 15.1505 10.8175 14.8132 11.7211 14.2425C12.5449 13.7222 13.3284 12.9387 13.8487 12.1149C14.4194 11.2114 14.7566 10.2554 14.8905 9.16183C14.9332 8.81336 14.9332 7.97462 14.8906 7.62554C14.7571 6.53263 14.4199 5.57685 13.8487 4.67246C13.3284 3.84864 12.5449 3.06517 11.7211 2.54483C10.822 1.97693 9.85507 1.63464 8.78508 1.50549C8.56493 1.47892 7.74718 1.45811 7.54751 1.474ZM7.9004 5.2041C7.73173 5.23136 7.58985 5.34558 7.51115 5.51745L7.47137 5.60439L7.47032 7.05824L7.46928 8.51208L7.50668 8.60224C7.53976 8.68199 7.74132 8.89014 9.25277 10.4054C10.1937 11.3487 11.0005 12.1439 11.0483 12.1752C11.3979 12.4035 11.8602 12.1526 11.8622 11.7336C11.8633 11.4879 11.9747 11.6146 10.1565 9.79312L8.52903 8.16266L8.52886 6.88271L8.52869 5.60277L8.48844 5.51631C8.4374 5.4067 8.35987 5.3182 8.26749 5.26405C8.20358 5.2266 8.16479 5.21547 8.02547 5.19452C8.00669 5.19171 7.95041 5.19601 7.9004 5.2041Z"
            fill="#0F52BE"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_892_18425">
          <path
            d="M0 2.39368C0 1.28911 0.895431 0.393677 2 0.393677H14C15.1046 0.393677 16 1.28911 16 2.39368V14.3937C16 15.4982 15.1046 16.3937 14 16.3937H2C0.895431 16.3937 0 15.4982 0 14.3937V2.39368Z"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_892_18425">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.393677)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import { AddCircleIcon, Button } from "@jutro/ui";
import { Loader } from "components/new";
import { useDoctorUpcomingVisitsQuery } from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { UpcomingVisitsList } from "views/Patient/Profile/UpcomingVisits/UpcomingVisitsList";
import { usePatientData } from "views/Patient/hooks";

export const UpcomingVisits = () => {
  const { data: patientData } = usePatientData({ from: "cache-only" });
  const {
    data: visitData,
    loading,
    error,
  } = useDoctorUpcomingVisitsQuery({
    variables: {
      patientId: patientData?.id ?? "",
    },
    skip: !patientData?.id,
    fetchPolicy: "no-cache",
  });

  const { addTile } = useTileSystem();

  if (!patientData?.id) return <Loader />;

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <div className="font-label text-jutro-new-warm-gray-800">
          Nadchodzące wizyty
        </div>

        <Button
          full={false}
          text="Umów wizytę"
          type="button"
          size="condensed"
          variant={
            visitData?.doctorUpcomingVisits &&
            visitData.doctorUpcomingVisits.length > 0
              ? "ghost"
              : "secondary"
          }
          icon={<AddCircleIcon />}
          onClick={() => {
            const id = `schedule-visit-form-create-${patientData.id}`;
            addTile(
              {
                id,
                name: "schedule-visit-form",
                data: { id, mode: "create", patientId: patientData.id },
              },
              TileColumn.Right,
            );
          }}
        />
      </div>
      <UpcomingVisitsList data={visitData} loading={loading} error={error} />
    </div>
  );
};

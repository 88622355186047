import { FetchPolicy } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDoctorVisitQuery } from "lib/graphql/megaSchema";

export const useVisitData = ({
  from,
}: {
  from: Extract<FetchPolicy, "network-only" | "cache-only" | "cache-first">;
}) => {
  const { visitId } = useParams<{ visitId: string }>();

  const { data, loading } = useDoctorVisitQuery({
    fetchPolicy: from,
    skip: !visitId,
    variables: {
      id: visitId ?? "",
    },
  });

  return {
    data: data?.doctorVisit,
    loading,
  };
};

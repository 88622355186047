import { Option } from "@jutro/types";
import { DoctorMSProcedureType } from "lib/graphql/megaSchema";

export const procedureTypeOptions: Option<DoctorMSProcedureType>[] = [
  { value: "MIDWIFE_EDUCATIONAL_VISIT", label: "Edukacja przedporodowa" },
  { value: "PATRONAGE_VISIT", label: "Patronaż" },
  { value: "MEDICAL_ACT", label: "Czynność medyczna" },
  { value: "TUBERCULOSIS_EDUCATION", label: "Profilaktyka gruźlicy" },
  { value: "DRUGPASS", label: "Ordynacja lekowa" },
  { value: "EKG", label: "Badanie EKG" },
  { value: "USG", label: "Badanie USG" },
  { value: "BANDAGE", label: "Zmiana opatrunku" },
  { value: "OBJECT", label: "Ciało obce" },
  { value: "STITCHES", label: "Szwy" },
  { value: "EDUCATION", label: "Edukacja" },
  { value: "CHUK_EDUCATION", label: "Edukacja CHUK" },
  { value: "CHUK_MEASUREMENTS", label: "Pomiary CHUK" },
  { value: "SPIROMETRY", label: "Spirometria" },
  { value: "NURSE_ADVICE", label: "Osobista infekcyjna" },
];

const activeProcedureTitleMap = procedureTypeOptions.reduce(
  (acc, { value, label }) => {
    acc[value] = label;
    return acc;
  },
  {} as Record<string, string>,
);

export const procedureTitleMap: Record<string, string> = {
  // legacy procedures
  MEDICINE: "Iniekcje",
  ANALYSIS: "Badania laboratoryjne",
  VACCINE: "Szczepienie",
  RTG: "RTG",
  CT: "CT",
  SPIRO: "Spirometria",
  VACCINATION: "Szczepienie",
  ...activeProcedureTitleMap,
};

import { Button } from "@jutro/ui";
import { useFormContext } from "react-hook-form";
import { TagFormSchema } from "components/TagFormNew/hooks";
import { TagForm } from "components/TagFormNew/types";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { useSubmitSection } from "./hooks";

type Props = {
  tagForm: TagForm;
};

export const SubmitSection = ({ tagForm }: Props) => {
  const { handleSubmit } = useFormContext<TagFormSchema>();
  const { type } = tagForm;

  const {
    createPatientTagLoading,
    handleDelete,
    deletePatientTagLoading,
    updatePatientTagLoading,
    onSubmit,
    handleOnSubmit,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
  } = useSubmitSection(tagForm);

  return (
    <div className="flex items-center gap-2">
      <div
        className={type === "update" ? "basis-3/5" : "basis-full"}
        onClick={handleOnSubmit}
      >
        <Button
          text={`Zatwierdź ${type === "create" ? "utworzenie" : "edycję"} taga`}
          onClick={handleSubmit(onSubmit)}
          loading={createPatientTagLoading || updatePatientTagLoading}
        />
      </div>
      {type === "update" && (
        <div className="basis-2/5">
          <Button
            text="Usuń tag"
            type="button"
            variant="negative"
            onClick={() => {
              setIsDeleteDialogOpen(true);
            }}
          />
        </div>
      )}
      <ConfirmDialog
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        confirmText="Usuń tag"
        title="Usuwanie taga"
        onConfirm={async () => {
          await handleDelete();
          setIsDeleteDialogOpen(false);
        }}
        loading={deletePatientTagLoading}
      />
    </div>
  );
};

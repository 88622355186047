import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@jutro/ui";
import { omit } from "lodash";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReactModal from "react-modal";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { PaymentModalBody } from "components/PatientDocs/Ordered/PaymentModal/PaymentModalBody";
import { EcommercePacketSummary } from "components/PatientDocs/Ordered/PaymentModal/PaymentModalSummary/EcommercePacketSummary";
import { LabExamsSummary } from "components/PatientDocs/Ordered/PaymentModal/PaymentModalSummary/LabExamsSummary";
import { PaymentOptionSelect } from "components/PatientDocs/Ordered/PaymentModal/PaymentOptionSelect";
import { VoucherCodeInput } from "components/PatientDocs/Ordered/PaymentModal/VoucherCodeInput";
import { useHandleEcommercePackets } from "components/PatientDocs/Ordered/PaymentModal/useHandleEcommercePackets";
import {
  AppliedVoucherCodeData,
  OrderedTestListItem,
  PaymentModalSchema,
  RawPaymentLogInput,
} from "components/PatientDocs/Ordered/types";
import {
  fixedPriceValue,
  isEcommercePacket,
  paymentModalSchema,
} from "components/PatientDocs/Ordered/utils";
import {
  DoctorLaboratoryExaminationsDocument,
  DoctorMSPaymentLogEntryInput,
  DoctorProceduresDocument,
  useDoctorLogPaymentMutation,
} from "lib/graphql/megaSchema";
import { PacketsResponseData } from "lib/hooks/useAsyncOptions";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";

const getPaymentButtonText = (
  data: PacketsResponseData | OrderedTestListItem[],
  appliedVoucherCodeData: AppliedVoucherCodeData,
) => {
  const ecommercePacket = isEcommercePacket(data);

  if (ecommercePacket) {
    if (!appliedVoucherCodeData.discounts) {
      return `Zapłacono ${fixedPriceValue(data.totalPrice)}`;
    }

    if (appliedVoucherCodeData.totalPrice === 0 || data.totalPrice === 0) {
      return "Opłać kodem/bonem";
    }

    return `Zapłacono ${fixedPriceValue(appliedVoucherCodeData.totalPrice)}`;
  }

  return "Oznacz jako opłacone";
};

const prepareInput = async (
  rawInput: RawPaymentLogInput,
): Promise<DoctorMSPaymentLogEntryInput> => {
  if (rawInput.bankTransferPayment === undefined) {
    return rawInput as DoctorMSPaymentLogEntryInput;
  }

  const moneyTransferProofFiles = await Promise.all(
    rawInput.bankTransferPayment.moneyTransferProofFiles.map(uploadFile),
  );

  return {
    bankTransferPayment: { moneyTransferProofFiles },
  };
};

type Props = {
  data: OrderedTestListItem[] | PacketsResponseData;
  isPaymentModalOpen: boolean;
  patientId: string;
  patientEmail?: string | null;
  setIsPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
  onCtaClick?: (value: null) => void;
};

export const PaymentModal = ({
  data,
  isPaymentModalOpen,
  patientId,
  patientEmail,
  setIsPaymentModalOpen,
  onCtaClick,
}: Props) => {
  const [logPayment] = useDoctorLogPaymentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      DoctorLaboratoryExaminationsDocument,
      DoctorProceduresDocument,
    ],
  });

  const { id } = data as PacketsResponseData;

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm<PaymentModalSchema>({
    resolver: zodResolver(paymentModalSchema),
    shouldFocusError: false,
    defaultValues: { paymentMethod: "POS" },
  });

  const posPaymentNumberValue = watch("posPayment.paymentNumber");
  const paymentSelectValue = watch("paymentMethod");

  const ecommercePacket = isEcommercePacket(data);

  const {
    appliedVoucherCodeData,
    isVoucherCodeLoading,
    checkoutIsPaying,
    voucherCodeInput,
    voucherCodeError,
    onVoucherCodeApply,
    setVoucherCodeInput,
    clearCheckout,
    onPayChekoutClick,
    setVoucherCodeError,
  } = useHandleEcommercePackets({
    packetId: id,
    patientId,
    patientEmail,
    posPaymentNumberValue,
    setIsPaymentModalOpen,
  });

  let paidIdsList: string[];

  if (Array.isArray(data)) {
    paidIdsList = data.filter(isLaboratoryExamination).map((test) => test.id);
  }

  const handleLabPayment = async (data: RawPaymentLogInput) => {
    const paymentMethod = await prepareInput(data);

    logPayment({
      variables: { paidIds: paidIdsList, paymentMethod },
    });

    reset({ paymentMethod: undefined });
    setIsPaymentModalOpen(false);
  };

  const submitPaymentLogForm = handleSubmit((data) => {
    if (ecommercePacket) {
      if (voucherCodeInput.length > 0) {
        setVoucherCodeError({
          error: true,
          message: "Dodaj kod przed opłaceniem badań",
        });
        return;
      }
      onPayChekoutClick();
      clearCheckout();
      reset({});

      if (onCtaClick) onCtaClick(null);

      return;
    }

    const dataToSend = omit(data, ["paymentMethod"]);

    toaster.promise(handleLabPayment(dataToSend), {
      error: "Wystąpił błąd w przetwarzaniu płatności",
      loading: "Oznaczam badania jako opłacone",
      success: "Badania zostały oznaczone jako opłacone",
    });
  });

  const handleCtaClick = () => {
    setIsPaymentModalOpen(false);
    reset({ paymentMethod: undefined });
    clearCheckout();
    if (onCtaClick) onCtaClick(null);
  };

  useEffect(() => {
    if (appliedVoucherCodeData.totalPrice === 0) {
      setValue("posPayment.paymentNumber", "-");
    }
  }, [appliedVoucherCodeData.totalPrice]);

  return (
    <ReactModal
      isOpen={isPaymentModalOpen}
      shouldCloseOnEsc
      className="font-paragraph-2 z-50 flex h-full w-full flex-col items-center justify-center gap-2 text-jutro-new-warm-gray-800"
      ariaHideApp={false}
      style={{
        overlay: { zIndex: 50, backgroundColor: "rgba(24, 24, 26, 0.5)" },
      }}
    >
      <div className="flex w-[400px] flex-col gap-6 rounded-lg bg-white p-6 shadow-lg">
        <form onSubmit={submitPaymentLogForm} className="flex flex-col gap-4">
          <div>
            <h4 className="font-heading-4">Wybierz metodę płatności</h4>
          </div>

          <div className="flex flex-col gap-4">
            {isEcommercePacket(data) ? (
              <EcommercePacketSummary
                data={data}
                appliedVoucherCodeData={appliedVoucherCodeData}
              />
            ) : (
              <LabExamsSummary data={data} />
            )}

            {ecommercePacket && appliedVoucherCodeData.totalPrice !== 0 && (
              <VoucherCodeInput
                isVoucherCodeLoading={isVoucherCodeLoading}
                onVoucherCodeApply={onVoucherCodeApply}
                setVoucherCodeError={setVoucherCodeError}
                setVoucherCodeInput={setVoucherCodeInput}
                voucherCodeError={voucherCodeError}
                voucherCodeInput={voucherCodeInput}
                discounts={appliedVoucherCodeData.discounts}
              />
            )}

            {(appliedVoucherCodeData.totalPrice ||
              appliedVoucherCodeData.totalPrice !== 0) && (
              <div className="flex flex-col gap-2">
                <PaymentOptionSelect
                  control={control}
                  ecommercePacket={ecommercePacket}
                  errors={errors}
                  reset={reset}
                />

                <PaymentModalBody
                  paymentSelectValue={paymentSelectValue}
                  control={control}
                  errors={errors}
                />
              </div>
            )}
          </div>

          <div className="flex justify-end gap-2">
            <Button
              onClick={handleCtaClick}
              text="Wróć"
              variant="ghost"
              full={false}
            />
            <Button
              full={false}
              type="submit"
              text={getPaymentButtonText(data, appliedVoucherCodeData)}
              loading={checkoutIsPaying || isVoucherCodeLoading}
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

import { Option } from "@jutro/types";
import {
  ArrowPointingDownIcon,
  ArrowPointingUpIcon,
  Button,
  CloseIcon,
  DashboardIcon,
  Input,
  MenuIcon,
  SearchIcon,
  Select,
} from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useMemo, useTransition } from "react";
import { SnippetType } from "lib/graphql/doctor";
import { useDoctorGetDoctorsQuery } from "lib/graphql/megaSchema";
import { useFlag } from "lib/hooks/flags";
import { useOnce } from "lib/hooks/useOnce";
import { useGetDoctorOptions } from "views/Visits/PerDay/components/Controls/DoctorSelect/hooks";
import {
  categoryAtom,
  keepCurrentControlsAtom,
  orderAtom,
  searchAtom,
  selectedOwnerAtom,
  selectedUsageAtom,
  snippetListShowContentAtom,
  tagAtom,
  typeAtom,
  visibilityAtom,
} from "../SnippetListTile/atoms";
import {
  chatCategoryOptions,
  docsCategoryOptions,
  snippetCategoryOptions,
  snippetTypeOptions,
  snippetUsageOptions,
  snippetVisibilityOptions,
} from "../tools";
import {
  SnippetCategoryFilter,
  SnippetTypeFilter,
  SnippetVisiblityFilter,
} from "../types";

const visibilityOptions: Option<SnippetVisiblityFilter>[] = [
  { label: "Publicze i prywatne", value: "ALL" },
  ...snippetVisibilityOptions,
];

const typeOptions: Option<SnippetTypeFilter>[] = [
  { label: "Wszystkie", value: "ALL" },
  ...snippetTypeOptions,
];

type Props = {
  visibleCounter: number;
  totalCounter: number;
  tagOptions: Option<string>[];
  mode?: "admin" | "settings";
  type?: SnippetType;
  category?: SnippetCategoryFilter;
};

export const SnippetControls = ({
  visibleCounter,
  totalCounter,
  mode,
  type,
  category,
  tagOptions,
}: Props) => {
  const [order, setOrder] = useAtom(orderAtom);
  const [search, setSearch] = useAtom(searchAtom);
  const [selectedVisiblity, setSelectedVisiblity] = useAtom(visibilityAtom);
  const [selectedType, setSelectedType] = useAtom(typeAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(categoryAtom);
  const [selectedTag, setSelectedTag] = useAtom(tagAtom);
  const [isSelectTypeTransitionPending, startSelectTypeTransition] =
    useTransition();
  const [isSelectCategoryTransitionPending, startSelectCategoryTransition] =
    useTransition();
  const [isSelectTagTransitionPending, startSelectTagTransition] =
    useTransition();
  const [isSelectDoctorTransitionPending, startSelectDoctorTransition] =
    useTransition();
  const [isSelectVisibilityTransitionPending, startSelectVisibilityTransition] =
    useTransition();
  const [isSelectUsageTransitionPending, startSelectUsageTransition] =
    useTransition();
  const [isChangeSortOrderTransitionPending, startChangeSortOrderTransition] =
    useTransition();
  const [selectedOwner, setSelectedOwner] = useAtom(selectedOwnerAtom);
  const [keepCurrentControls, setKeepCurrentControls] = useAtom(
    keepCurrentControlsAtom,
  );

  useDoctorGetDoctorsQuery();

  const isPending =
    isSelectTypeTransitionPending ||
    isSelectCategoryTransitionPending ||
    isSelectTagTransitionPending ||
    isSelectDoctorTransitionPending ||
    isSelectVisibilityTransitionPending ||
    isSelectUsageTransitionPending;

  const doctorsOptions = useGetDoctorOptions();
  const [selectedUsage, setSelectedUsage] = useAtom(selectedUsageAtom);

  const [snippetListShowContent, setSnippetListShowContent] = useAtom(
    snippetListShowContentAtom,
  );

  const snippetsHeaderOnlyModeEnabled = useFlag(
    "feat-snippets-header-only-mode",
  );

  useOnce(() => {
    if (keepCurrentControls) {
      setKeepCurrentControls(false);
      return;
    }

    if (type) setSelectedType(type);
    if (category) setSelectedCategory(category);
    if (mode === "settings") setSelectedVisiblity("PRIVATE");
  });

  const filteredCategories = useMemo(
    () => [
      { value: "ALL", label: "Wszystkie" },
      ...snippetCategoryOptions.filter((category) => {
        if (selectedType === "ALL") return true;

        if (selectedType === "CHAT")
          return Object.values(chatCategoryOptions).find(
            (c) => c.value === category.value,
          );

        if (selectedType === "DOCS")
          return Object.values(docsCategoryOptions).find(
            (c) => c.value === category.value,
          );

        return category.value === "usg";
      }),
    ],
    [selectedType, snippetCategoryOptions],
  );

  const resetControls = () => {
    startSelectTypeTransition(() => {
      setSelectedType("ALL");
    });

    startSelectCategoryTransition(() => {
      setSelectedCategory("ALL");
    });

    startSelectTagTransition(() => {
      setSelectedTag("ALL");
    });

    startSelectVisibilityTransition(() => {
      setSelectedVisiblity("ALL");
    });

    startSelectDoctorTransition(() => {
      setSelectedOwner(null);
    });

    startSelectUsageTransition(() => {
      setSelectedUsage("ALL");
    });

    setSearch("");
  };

  useEffect(() => {
    if (!tagOptions.find((o) => o.value === selectedTag)) {
      setSelectedTag("ALL");
    }
  }, [tagOptions, selectedTag]);

  useEffect(() => {
    if (!filteredCategories.find((o) => o.value === selectedCategory)) {
      setSelectedCategory("ALL");
    }
  }, [selectedType, filteredCategories, selectedCategory]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className={mode === "admin" ? "col-span-3" : "col-span-6"}>
        <Input
          label="Szukaj"
          sideElement={<SearchIcon />}
          value={search}
          placeholder="minimum 3 znaki"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sideElementPosition="start"
          error={search.length < 3 && search.length > 0}
        />
      </div>
      <div className={mode === "admin" ? "col-span-3" : "col-span-6"}>
        <Select
          options={visibilityOptions}
          label="Widoczność"
          placeholder="Widoczność"
          value={
            visibilityOptions.find((o) => o.value === selectedVisiblity) ?? null
          }
          disabled={isSelectVisibilityTransitionPending}
          onChange={(selectedOption) => {
            if (!selectedOption) return;
            startSelectVisibilityTransition(() => {
              setSelectedVisiblity(selectedOption.value);
            });
          }}
        />
      </div>

      <div className={mode === "admin" ? "col-span-3" : "col-span-4"}>
        <Select
          options={typeOptions}
          label="Typ"
          placeholder="Typ"
          value={typeOptions.find((o) => o.value === selectedType) ?? null}
          disabled={isSelectTypeTransitionPending}
          onChange={(selectedOption) => {
            if (!selectedOption) return;
            startSelectTypeTransition(() => {
              setSelectedType(selectedOption.value);
            });
          }}
        />
      </div>
      <div className={mode === "admin" ? "col-span-3" : "col-span-4"}>
        <Select
          options={filteredCategories}
          label="Kategoria"
          placeholder="Kategoria"
          value={
            filteredCategories.find((o) => o.value === selectedCategory) ?? null
          }
          disabled={isSelectCategoryTransitionPending}
          onChange={(selectedOption) => {
            if (!selectedOption) return;
            startSelectCategoryTransition(() => {
              setSelectedCategory(
                selectedOption.value as SnippetCategoryFilter,
              );
            });
          }}
        />
      </div>
      <div className={mode === "admin" ? "col-span-3" : "col-span-4"}>
        <Select
          options={tagOptions}
          label="Tagi"
          placeholder="Tagi"
          value={tagOptions.find((o) => o.value === selectedTag) ?? null}
          disabled={isSelectTagTransitionPending}
          onChange={(selectedOption) => {
            if (!selectedOption) return;
            startSelectTagTransition(() => {
              setSelectedTag(selectedOption.value);
            });
          }}
        />
      </div>

      {mode === "admin" && (
        <div className="col-span-3">
          <Select
            options={snippetUsageOptions}
            label="Użycie"
            placeholder="Użycie"
            value={
              snippetUsageOptions.find((o) => o.value === selectedUsage) ?? null
            }
            disabled={isSelectUsageTransitionPending}
            onChange={(selectedOption) => {
              if (!selectedOption) return;
              startSelectUsageTransition(() => {
                setSelectedUsage(selectedOption.value);
              });
            }}
          />
        </div>
      )}

      {mode === "admin" && (
        <div className="col-span-6 flex">
          <Select
            key={selectedOwner}
            placeholder="Kliknij, aby wyszukać..."
            noOptionsMessage="Brak wyników."
            isSearchable
            label="Właściciel"
            disabled={isSelectDoctorTransitionPending}
            options={doctorsOptions}
            onChange={(selectedOption) => {
              if (!selectedOption) return;
              startSelectDoctorTransition(() => {
                setSelectedOwner(selectedOption.value.id);
              });
            }}
            value={
              doctorsOptions.find((d) => d.value.id === selectedOwner) ?? null
            }
          />
          <div className="mt-auto">
            <Button
              icon={<CloseIcon />}
              full={false}
              disabled={!selectedOwner || isSelectDoctorTransitionPending}
              label="Wyczyść właściciela"
              variant="ghost"
              tooltipPosition="bottom"
              onClick={() => {
                startSelectDoctorTransition(() => {
                  setSelectedOwner(null);
                });
              }}
            />
          </div>
        </div>
      )}

      <div className="col-span-12 flex justify-between">
        <div className="my-2.5 flex gap-1">
          <div className="font-label">
            {isPending ? "..." : visibleCounter} / {totalCounter}
          </div>
          <Button
            size="condensed"
            text="Pokaż wszystkie"
            variant="ghost"
            full={false}
            loading={isPending}
            onClick={resetControls}
          />
        </div>

        <div className="my-2.5 flex gap-1">
          <Button
            onClick={() => {
              startChangeSortOrderTransition(() => {
                setOrder((p) => (p === "ASC" ? "DESC" : "ASC"));
              });
            }}
            variant="ghost"
            loading={isChangeSortOrderTransitionPending}
            icon={
              order === "ASC" ? (
                <ArrowPointingDownIcon size="sm" />
              ) : (
                <ArrowPointingUpIcon size="sm" />
              )
            }
            text="Najbardziej popularne"
            full={false}
            size="condensed"
          />

          {snippetsHeaderOnlyModeEnabled && (
            <>
              <Button
                onClick={() => {
                  setSnippetListShowContent(false);
                }}
                variant={snippetListShowContent ? "ghost" : "secondary"}
                icon={<MenuIcon />}
                full={false}
                label={"Pokazuj tylko nagłówek szablonów"}
                size="condensed"
                tooltipPosition="bottom"
              />

              <Button
                onClick={() => {
                  setSnippetListShowContent(true);
                }}
                variant={snippetListShowContent ? "secondary" : "ghost"}
                icon={<DashboardIcon />}
                full={false}
                label={"Pokazuj całą treść szablonów"}
                size="condensed"
                tooltipPosition="bottom"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

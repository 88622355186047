import { Banner } from "@jutro/ui";
import { useState } from "react";
import { useVisitData } from "views/Visit/hooks";

export const DoctorInterviewVirtualAssistantInsightBanner = () => {
  const [show, setShow] = useState(true);
  const { data: visit } = useVisitData({ from: "cache-only" });

  if (
    !visit?.preInterview ||
    visit.preInterview.length === 0 ||
    visit.status === "SUCCESS" ||
    visit.status === "ARCHIVE" ||
    !show
  )
    return null;

  return (
    <Banner
      type="warning"
      title="Zapoznaj się z wywiadem medycznym"
      description="Poniżej znajdują się informacje zebrane przez wirtualnego asystenta na podstawie jego rozmowy z pacjentem. Narzędzie ma charakter pomocniczy, nie dokonuje analizy oraz nie zastępuje Twoich decyzji medycznych."
      handleClose={() => setShow(false)}
    />
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input, MessageBox, Select } from "@jutro/ui";
import { envVars } from "envvars";
import { Controller, useForm } from "react-hook-form";
import { Loader } from "components/new";
import { useGetNumberOfPrescriptionRequestsQuery } from "lib/graphql/megaSchema";
import { httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import { useAsyncDoctorSelectOptions } from "views/PrescriptionRequest/hooks";
import {
  PrescriptionForceRequestSchemaType,
  prescriptionForceRequestSchema,
  visitLengthOptions,
} from "views/PrescriptionRequest/utils";

const defaultFormValues = {
  howManyVisits: "",
  selectedDoctor: null,
  visitLength: visitLengthOptions[visitLengthOptions.length - 1],
};

export const PrescriptionRequest = () => {
  const { loadDoctorsOptions } = useAsyncDoctorSelectOptions();

  const {
    data: numberOfPrescriptionRequests,
    loading: numberOfPrescriptionRequestsLoading,
    refetch,
  } = useGetNumberOfPrescriptionRequestsQuery();

  const { control, handleSubmit, watch, reset } =
    useForm<PrescriptionForceRequestSchemaType>({
      resolver: zodResolver(prescriptionForceRequestSchema),
      mode: "onChange",
      defaultValues: defaultFormValues,
    });

  const { howManyVisits, selectedDoctor, visitLength } = watch();

  const sendForcePrescriptionVisitsRequest = async () => {
    if (!selectedDoctor || !selectedDoctor.value) return;

    const url = `${
      httpConfig[envVars.REACT_APP_CONFIG]
    }/prescription_requests_emergency?auth=Ldi62YkU8KTJ4nuAHB4biwdEqjV8GgxbwpL&doctorId=${
      selectedDoctor.value
    }&visitLength=${visitLength.value}&amount=${howManyVisits}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    reset(defaultFormValues);

    refetch();

    return response;
  };

  const submitForcePrescriptionVisitsRequest = handleSubmit(() => {
    toaster.promise(sendForcePrescriptionVisitsRequest(), {
      error: "Coś poszło nie tak - zgłoś to do działu IT",
      loading: "Przydzielam wizyty",
      success: "Przydzielono wizyty",
    });
  });

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
        <div className="rounded-lg">
          {numberOfPrescriptionRequestsLoading ? (
            <Loader size="xs" />
          ) : (
            <MessageBox
              text={`Oczekujące zamówienia receptowe: ${numberOfPrescriptionRequests?.doctorNumberOfPrescriptionRequests ?? "Brak danych"}`}
            />
          )}
        </div>

        <form
          onSubmit={submitForcePrescriptionVisitsRequest}
          className="flex w-[400px] flex-col justify-center gap-4"
        >
          <Controller
            control={control}
            name="selectedDoctor"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                isAsync
                isSearchable
                label="Wybierz lekarza"
                placeholder="Wpisz imię lub nazwisko"
                loadOptions={loadDoctorsOptions}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="howManyVisits"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label="Wybierz ilość wizyt"
                placeholder="Wpisz liczbę wizyt"
                type="number"
                maxLength={3}
                onChange={onChange}
                value={value}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="visitLength"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label="Wybierz czas trwania wizyty"
                placeholder="Wpisz czas trwania wizyty"
                options={visitLengthOptions}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Button type="submit" text="Przypisz" />
        </form>
      </div>
    </div>
  );
};

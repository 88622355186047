import { z } from "zod";

export const prescriptionForceRequestSchema = z.object({
  selectedDoctor: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .nullable()
    .refine((data) => data, { message: "To pole jest wymagane" }),
  howManyVisits: z
    .string()
    .min(1, { message: "To pole jest wymagane" })
    .max(3, { message: "maksymalnie 3 cyfyr" })
    .regex(/^\d+$/, {
      message: "Wpisz wyłącznie cyfry",
    }),
  visitLength: z.object(
    {
      label: z.string(),
      value: z.number(),
    },
    { message: "Wybierz czas trwania wizyty" },
  ),
});

export type PrescriptionForceRequestSchemaType = z.infer<
  typeof prescriptionForceRequestSchema
>;

export const visitLengthOptions = [
  {
    label: "1 minuta",
    value: 1,
  },
  {
    label: "2 minuty",
    value: 2,
  },
  {
    label: "3 minuty",
    value: 3,
  },
  {
    label: "4 minuty",
    value: 4,
  },
  {
    label: "5 minut",
    value: 5,
  },
];

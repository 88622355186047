import { capitalize } from "@jutro/tools";
import { useMemo } from "react";
import {
  DoctorMSOrganizationIdVojevodship,
  useDoctorOrganizationsQuery,
} from "lib/graphql/megaSchema";

export type organizationClinicOption = {
  value: string;
  label: string;
  latitude?: number | null;
  longitude?: number | null;
};

export const useVojevodshipOptions = () => {
  const { data: organizationsData } = useDoctorOrganizationsQuery();

  const vojevodshipsWithCities = useMemo(() => {
    const vojevodships =
      organizationsData?.doctorOrganizations.filter(
        (organization) => organization.id.length === 2,
      ) ?? [];

    const cities =
      organizationsData?.doctorOrganizations.filter(
        (organization) => organization.id.length === 3,
      ) ?? [];

    const mappedOrganizations = cities.map((city) => {
      const vojevodshipId = city.id.slice(0, 2);
      const vojevodship = vojevodships.find(
        (vojevodship) => vojevodship.id === vojevodshipId,
      );

      return {
        value: vojevodshipId as DoctorMSOrganizationIdVojevodship,
        label: `${capitalize(vojevodship?.name ?? "")} (${city.name})`,
      };
    });

    return mappedOrganizations;
  }, [organizationsData?.doctorOrganizations]);

  const vojevodshipsOptions = useMemo(() => {
    const results = organizationsData?.doctorOrganizations;

    if (!results) {
      return [];
    }

    const vojevodshipOrganizations = organizationsData.doctorOrganizations
      .filter((organization) => organization.id.length === 2)
      .map((organization) => {
        return {
          label: `${capitalize(organization.name)}`,
          value: organization.id,
        };
      });

    return [{ label: "Wszystkie", value: "P" }, ...vojevodshipOrganizations];
  }, [organizationsData?.doctorOrganizations]);

  const vojevodshipsAndClinicsOptions = useMemo(() => {
    const facilities = [];

    const results = organizationsData?.doctorOrganizations;

    if (!results) {
      return [];
    }

    for (const result of results) {
      const isResultAStreet = result.id.length === 4;

      if (!isResultAStreet) {
        continue;
      }

      const cityOfCorespondingStreet = results.find(
        ({ id }) => result.id.includes(id) && id.length === 3,
      );

      facilities.push({
        value: result.id + "%",
        label: `${result.displayName} (${cityOfCorespondingStreet?.displayName})`,
      });
    }

    const vojevodshipOrganizations = organizationsData.doctorOrganizations
      .filter((organization) => organization.id.length === 2)
      .map((organization) => {
        return {
          label: `${capitalize(organization.name)} telemedyczne`,
          value: organization.id,
        };
      });

    return [
      { label: "Prywatne telemedyczne", value: "P" },
      ...vojevodshipOrganizations,
      ...facilities,
    ];
  }, [organizationsData?.doctorOrganizations]);

  const vojevodshipIdsArray = useMemo(() => {
    const vojevodshipIdsArray: string[] = [];

    organizationsData?.doctorOrganizations
      .filter((organization) => organization.id.length <= 2)
      .forEach((organization) => {
        vojevodshipIdsArray.push(organization.id);
      });

    return vojevodshipIdsArray;
  }, [organizationsData]);

  const organizationsClinicsOptions = useMemo(() => {
    const clinicsOptions: organizationClinicOption[] = [];

    organizationsData?.doctorOrganizations
      .filter((organization) => organization.id.length === 4)
      .forEach((organization) => {
        clinicsOptions.push({
          value: organization.id,
          label: `${organization.name}, ${organization.placeData?.city}`,
          latitude: organization.placeData?.latitude ?? undefined,
          longitude: organization.placeData?.longtitude ?? undefined,
        });
      });

    const sortedClinicsOptions = clinicsOptions.sort((a, b) => {
      const x = a.value;
      const y = b.value;
      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    });

    return sortedClinicsOptions;
  }, [organizationsData?.doctorOrganizations]);

  if (!organizationsData) {
    return {
      vojevodshipsAndClinicsOptions: [],
      organizations: [],
      vojevodshipsWithCities: [],
      vojevodshipIdsArray: [],
      organizationsClinicsOptions: [],
      vojevodshipsOptions: [],
    };
  }
  const { doctorOrganizations } = organizationsData;

  return {
    vojevodshipsAndClinicsOptions,
    organizations: doctorOrganizations,
    vojevodshipIdsArray,
    vojevodshipsWithCities,
    organizationsClinicsOptions,
    vojevodshipsOptions,
  };
};

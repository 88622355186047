import { useMemo } from "react";
import { Cell } from "react-table";
import { DoctorMSMedicalRecordEntity } from "lib/graphql/megaSchema";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

type Props = {
  cell: Cell<DoctorMSMedicalRecordEntity, any>;
};

export const MedicalDocumentationItem = ({ cell }: Props) => {
  const { visibleTiles } = useTileSystem();

  const {
    getCellProps,
    render,
    row: {
      original: { fileId },
    },
  } = cell;

  const isFileOpen = useMemo(() => {
    return visibleTiles.some((tile) => {
      if (typeof tile === "string") return;

      return tile.name === "file-preview" && tile.data.fileId === fileId;
    });
  }, [visibleTiles, fileId]);

  return (
    <div
      {...getCellProps()}
      className={`w-5 px-4 py-2 ${
        isFileOpen && `bg-jutro-new-warm-gray-100`
      } overflow-ellipsis whitespace-nowrap`}
    >
      {render("Cell")}
    </div>
  );
};

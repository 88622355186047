import { Defined } from "@jutro/types";
import { unique } from "radash";
import { IpomFormSchema } from "components/IpomForm/schema";
import {
  DoctorMSPrescription,
  DoctorPatientMessagesQuery,
  DoctorVisitQuery,
} from "lib/graphql/megaSchema";

type VisitPatientTags = Defined<
  Defined<DoctorVisitQuery["doctorVisit"]>["patient"]
>["tags"];

type TagRegularDrug = {
  name: string;
  box: string;
  ean: string;
  form: string;
  dosage: string;
  expirationAt: string;
  interval?: string;
};

export const extractVisitDrugs = (
  patientMessagesData: Defined<DoctorPatientMessagesQuery>,
  visitId: string,
) => {
  return (
    patientMessagesData.doctorGetMessagesForPatient.filter(
      ({ type, visit }) => type === "PRESCRIPTION" && visit.id === visitId,
    ) as DoctorMSPrescription[]
  ).flatMap(({ drugs }) => drugs);
};

export const extractRegularDrugsFromTags = (
  tags?: VisitPatientTags,
): TagRegularDrug[] => {
  return (
    tags
      ?.filter((tag) => tag.type === "REGULAR_DRUG")
      .map(({ data, expirationAt }) => ({
        ...data.drug,
        interval: data.interval,
        expirationAt,
      })) || []
  );
};

const transformDrugs = ({
  visitDrugs,
  regularDrugsFromTags,
}: {
  visitDrugs: DoctorMSPrescription["drugs"];
  regularDrugsFromTags: TagRegularDrug[];
}): IpomFormSchema["medicines"] => {
  const drugsFromTags = regularDrugsFromTags
    .filter(({ interval }) => interval)
    .map(({ name, box, ean, form, dosage, interval, expirationAt }) => ({
      active: true,
      ean,
      name,
      dose: dosage,
      form,
      time: expirationAt,
      dosage: interval!,
      quantity: box,
    }));

  const drugsFromVisit = visitDrugs.map(
    ({ ean, drug, dose, form, interval, quantity }) => ({
      active: true,
      ean: ean!,
      name: drug,
      dose,
      form,
      dosage: interval!,
      quantity,
    }),
  );

  return [...drugsFromTags, ...drugsFromVisit];
};

export const filterUniqueDrugs = (
  existingDrugs: IpomFormSchema["medicines"],
  visitDrugs: DoctorMSPrescription["drugs"],
  regularDrugsFromTags: TagRegularDrug[],
) => {
  const transformedDrugs = transformDrugs({ visitDrugs, regularDrugsFromTags });

  const updatedExistingDrugs = existingDrugs.map((drug) => {
    const matchingRegularDrug = transformedDrugs.find(
      (regularDrug) => regularDrug.ean === drug.ean,
    );
    if (matchingRegularDrug && matchingRegularDrug.dosage !== drug.dosage) {
      return { ...drug, interval: matchingRegularDrug.dosage };
    }

    return drug;
  });

  return unique(
    [...updatedExistingDrugs, ...transformedDrugs],
    (drug) => drug.ean,
  );
};

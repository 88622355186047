import dayjs from "dayjs";
import { envVars } from "envvars";
import { Scrollbars } from "react-custom-scrollbars";
import { StrapiPostEntity } from "lib/graphql/megaSchema";

type Props = {
  data: StrapiPostEntity;
};

export const NewsPostDetail = ({ data }: Props) => {
  return (
    <div className="grid h-screen grid-cols-3 gap-20 rounded-lg bg-white p-8">
      <div className="col-span-1 flex flex-col gap-6">
        <div className="font-display-2 xl:font-display-1">
          {data.attributes?.title}
        </div>

        <div>
          publikacja:{" "}
          {dayjs(data.attributes?.createdAt).format("YYYY/MM/DD@HH:mm")}
        </div>

        <div className="font-heading-2">{data.attributes?.excerpt}</div>
      </div>

      <div className="col-span-2">
        {/* @ts-ignore */}
        <Scrollbars autoHide>
          <div
            className="prose-xl mb-12 max-w-prose"
            dangerouslySetInnerHTML={{
              __html:
                data.attributes?.html.replaceAll(
                  `/uploads`,
                  `${envVars.REACT_APP_STRAPI_URL}/uploads`,
                ) || "",
            }}
          />
        </Scrollbars>
      </div>
    </div>
  );
};

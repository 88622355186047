import { Avatar } from "@jutro/ui";
import { envVars } from "envvars";
import { DoctorsSelectQuery } from "lib/graphql/megaSchema";
import { avatarConfig } from "lib/tools/httpConfigs";

type Doctor = DoctorsSelectQuery["doctorDoctors"][number];

export const getDoctorOption = (doctor: Doctor) => ({
  label: `${doctor.firstName} ${doctor.lastName}`,
  value: doctor,
  leftElement: (
    <Avatar
      type="photo"
      imgSrc={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${doctor.id}`}
      size="sm"
    />
  ),
});

import {
  BellAlertIcon,
  EnvelopeOpenIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import {
  currentMessagesAtom,
  currentThreadAtom,
} from "components/Outercom/atoms";
import { channelNameMap } from "components/Outercom/tools";
import { Thread } from "components/Outercom/types";
import { MessageContent } from "../Detail/Message/Content";
import { getThreadSlackLink } from "./tools";

type Props = {
  thread: Thread;
  isPanel?: boolean;
};

export const ThreadOverview = ({ thread, isPanel = false }: Props) => {
  const isRead = thread.unread === 0;
  const [, setCurrentThread] = useAtom(currentThreadAtom);
  const [, setCurrentMessages] = useAtom(currentMessagesAtom);

  return (
    <div
      key={thread.id}
      onClick={() => {
        if (isPanel) {
          window.open(getThreadSlackLink(thread), "_blank")?.focus();
        } else {
          setCurrentMessages([]);
          setCurrentThread(thread);
        }
      }}
      className={`flex w-full cursor-pointer gap-1.5 border p-2 transition-opacity duration-200 ease-in-out hover:opacity-60 ${
        thread.closed
          ? "text-jutro-new-warm-gray-900 opacity-30"
          : (isPanel ? thread.lastMessageFromSlack : isRead)
            ? "bg-white text-jutro-new-warm-gray-900"
            : "bg-jutro-new-rose-500"
      }`}
    >
      {!isPanel && (
        <div>
          {thread.closed ? (
            <LockClosedIcon className="mt-0.5 h-5 w-5" />
          ) : isRead ? (
            <EnvelopeOpenIcon className="mt-0.5 h-5 w-5" />
          ) : (
            <BellAlertIcon className="mt-0.5 h-5 w-5" />
          )}
        </div>
      )}

      <div>
        {!isPanel && <span>{channelNameMap[thread.channelName]}: </span>}
        <span className="inline-block min-w-max">
          {dayjs(thread.updatedAt).format("DD MMM o HH:mm")}
        </span>
      </div>

      <MessageContent
        isPreview
        content={thread.lastMessage || thread.threadAuthor || ""}
        fromSlack={thread.lastMessageFromSlack}
      />
    </div>
  );
};

import { Option } from "@jutro/types";
import { FieldError } from "react-hook-form";
import { GroupBase, StylesConfig } from "react-select";
import { z } from "zod";
import { isProcedure } from "components/ExaminationPanel/utils/isProcedure";
import {
  ExaminationTypeSelect,
  OrderedCategorySelect,
  OrderedTestListItem,
  PaymentTypeSelect,
  ProceduresType,
  RawPaymentLogInput,
} from "components/PatientDocs/Ordered/types";
import {
  DoctorMSLaboratoryExaminationEntity,
  DoctorMSProcedureEntity,
} from "lib/graphql/megaSchema";
import { PacketsResponseData } from "lib/hooks/useAsyncOptions";

export const paymentModalSchema = z.object({
  paymentMethod: z.string({ required_error: "To pole jest wymagane" }),
  anotherPaymentMethod: z
    .object({
      comment: z
        .string({ required_error: "To pole jest wymagane" })
        .trim()
        .min(1, { message: "To pole jest wymagane" }),
    })
    .optional(),
  bankTransferPayment: z
    .object({
      moneyTransferProofFiles: z.array(z.any()).min(1),
    })
    .optional(),
  customerComplaint: z
    .object({
      comment: z
        .string({ required_error: "To pole jest wymagane" })
        .trim()
        .min(1, { message: "To pole jest wymagane" }),
    })
    .optional(),
  employeeBenefit: z
    .object({
      benefit: z.string(),
    })
    .optional(),
  posPayment: z
    .object({
      paymentNumber: z
        .string({ required_error: "To pole jest wymagane" })
        .trim()
        .min(1, { message: "To pole jest wymagane" }),
    })
    .optional(),
});

export const paymentLogInputDefault: RawPaymentLogInput = {
  anotherPaymentMethod: undefined,
  bankTransferPayment: undefined,
  customerComplaint: undefined,
  employeeBenefit: undefined,
  posPayment: undefined,
};

export const paymentMethodOptions: Option<string>[] = [
  { value: "POS", label: "Terminal POS" },
  { value: "BANK-TRANSFER", label: "Przelew" },
  { value: "CUSTOMER-COMPLAINT", label: "Reklamacja" },
  { value: "EMPLOYEE-BENFIT", label: "Benefit Pracowniczy" },
  { value: "OTHER", label: "Inne" },
];

export const orderedTestTypeFilterOptions: ExaminationTypeSelect[] = [
  { value: "ALL", label: "Wszystkie" },
  { value: "LAB", label: "Badanie laboratoryjne" },
  { value: "PROCEDURE", label: "Procedura medyczna" },
];

export const paymentStatusOptions: PaymentTypeSelect[] = [
  { value: "ALL", label: "Wszystkie" },
  { value: "UNPAID", label: "Do opłacenia" },
  { value: "PAID", label: "Do wykonania" },
];

export const orderCategoryOptions: OrderedCategorySelect[] = [
  { value: "LAB", label: "Badania laboratoryjne" },
  { value: "PROCEDURES", label: "Procedury medyczne" },
  { value: "PACKETS", label: "Pakiety badań" },
];

export const fixedPriceValue = (price: number | null | undefined) => {
  if (price === 0) {
    return price.toFixed(2) + " zł";
  }

  if (!price) {
    return "nieznana kwota";
  }

  return price.toFixed(2) + " zł";
};

export const isEcommercePacket = (e: any): e is PacketsResponseData => {
  return e.type === "ECOMMERCE";
};

export const priceListSum = (priceList: number[]) => {
  return priceList.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
};

export const getLabelText = (
  data: DoctorMSLaboratoryExaminationEntity | DoctorMSProcedureEntity,
) => {
  if (isProcedure(data)) {
    const proceduresMap: Record<ProceduresType, string> = {
      BANDAGE: "Zmiana opatrunku",
      CHUK_EDUCATION: "Edukacja Chuk",
      CHUK_MEASUREMENTS: "Pomiary Chuk",
      DRUGPASS: data.medication?.name
        ? `Ordynacja lekowa: ${data.medication.name}`
        : "Ordynacja lekowa",
      EDUCATION: "Edukacja",
      EKG: "EKG",
      MEDICAL_ACT: "Czynność medyczna",
      OBJECT: "Ciało obce",
      STITCHES: "Szwy",
      USG: data.usg?.service?.name
        ? `${data.usg.service.name}`
        : `${data.usg?.referral?.procedureName}`,
      TUBERCULOSIS_EDUCATION: "Profilaktyka gruźlicy",
      PATRONAGE_VISIT: "Patronaż",
      MIDWIFE_EDUCATIONAL_VISIT: "Edukacja przedporodowa",
      SPIROMETRY: "Spirometria",
    };

    return (
      proceduresMap[data.type as ProceduresType] ?? "Nieznany typ procedury"
    );
  }

  return data.assignment.name;
};

export const getPaymentMethod = (data: OrderedTestListItem) => {
  const labExam = data as DoctorMSLaboratoryExaminationEntity;

  const { paymentLog, sourceInfo } = labExam;

  if (paymentLog?.info.anotherPaymentMethod) {
    return "Inna metoda płatności";
  }

  if (paymentLog?.info.bankTransferPayment) {
    return "Przelew";
  }

  if (paymentLog?.info.employeeBenefit) {
    return "Benefit pracowniczy";
  }

  if (paymentLog?.info.anotherPaymentMethod) {
    return "Inna metoda płatności";
  }

  if (paymentLog?.info.posPayment || sourceInfo) {
    return "Terminal POS";
  }

  return "Nieznana forma płatności";
};

export const filtersCustomStyles: StylesConfig = {
  container: (base) => ({
    ...base,
    maxHeight: 22,
    minHeight: 22,
    display: "flex",
    border: "none",
  }),
  control: (base) => ({
    ...base,
    maxHeight: 22,
    minHeight: 22,
    border: "none",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    padding: "0 8px 0 8px",
    gap: 8,
    cursor: "pointer",
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: 22,
    minHeight: 22,
    maxWidth: 200,
    padding: 0,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 300,
  }),
  singleValue: (base) => ({
    ...base,
    display: "inline-block",
  }),
  input: (base) => ({
    ...base,
    maxHeight: 22,
    minHeight: 22,
    margin: 0,
    padding: 0,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    margin: 0,
    color: "black",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    maxHeight: 22,
    alignContent: "center",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    height: 16,
    width: 16,
    padding: 0,
    display: "flex",
    alignItems: "center",
    color: "black",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 8,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
  }),
  option: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    padding: 8,
    cursor: "pointer",
  }),
};

export const customSelectStyles = <T>(
  error?: FieldError | string | null,
): StylesConfig<Option<T>, false, GroupBase<Option<T>>> => ({
  container: (base) => ({
    ...base,
    maxHeight: 40,
    minHeight: 40,
    margin: 0,
    flex: 1,
  }),
  control: (base, state) => ({
    ...base,
    maxHeight: 40,
    minHeight: 40,
    paddingRight: 8,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: state.isDisabled ? "#F2F2F7" : "white",
    borderColor: state.isFocused ? "#0A40A1" : error ? "#FF375F" : "#C6C6CD",
    boxShadow: "none",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    pointerEvents: "auto",
    color: "#3E3E43",
    "&:hover": {
      border: state.isDisabled ? "1px solid ##C6C6CD" : "1px solid #0A40A1",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    margin: 0,
    color: "#C9C6C5",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    color: "#3E3E43",
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    color: "#3E3E43",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    maxHeight: 40,
    gap: 8,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    height: 20,
    width: 20,
    padding: 0,
    display: state.isDisabled ? "none" : "flex",
    alignItems: "center",
    color: "#3E3E43",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 8,
    color: "#3E3E43",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 300,
    color: "#3E3E43",
    zIndex: 60,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    padding: 8,
    cursor: "pointer",
    backgroundColor: isSelected ? "#0A40A1" : "",
    color: isSelected ? "white" : "#3E3E43",
    "&:hover": {
      backgroundColor: "#E0EBFD",
      color: "#3E3E43",
    },
  }),
});

export const adjustedToInputSelectStyles = <T>(
  error?: FieldError | string | null,
): StylesConfig<Option<T>, false, GroupBase<Option<T>>> => ({
  container: (base) => ({
    ...base,
    maxHeight: 42,
    minHeight: 42,
    margin: 0,
  }),
  control: (base, state) => ({
    ...base,
    maxHeight: 42,
    minHeight: 42,
    paddingRight: 8,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: state.isDisabled ? "#F2F2F7" : "white",
    borderColor: state.isFocused ? "#0A40A1" : error ? "#FF375F" : "#C6C6CD",
    boxShadow: "none",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    pointerEvents: "auto",
    color: "#3E3E43",
    "&:hover": {
      border: state.isDisabled ? "1px solid #C6C6CD" : "1px solid #0A40A1",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    margin: 0,
    color: "#8E8E93",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    color: "#3E3E43",
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    color: "#3E3E43",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    maxHeight: 40,
    gap: 8,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    height: 20,
    width: 20,
    padding: 0,
    display: state.isDisabled ? "none" : "flex",
    alignItems: "center",
    color: "#3E3E43",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 8,
    color: "#3E3E43",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 300,
    color: "#3E3E43",
    zIndex: 60,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    fontSize: 16,
    fontWeight: 300,
    padding: 8,
    cursor: "pointer",
    backgroundColor: isSelected ? "#0A40A1" : "",
    color: isSelected ? "white" : "#3E3E43",
    "&:hover": {
      backgroundColor: "#E0EBFD",
      color: "#3E3E43",
    },
  }),
});

import { Scrollbar } from "components/new";
import { SlotsCreatorForm } from "views/SlotsCreator/components/SlotsCreatorForm";

export const SlotsCreator = () => {
  return (
    <Scrollbar>
      <div className="mx-auto flex h-full flex-col items-center justify-center gap-16 bg-white px-5 py-10">
        <h1 className="font-display-1">Slots Creator</h1>
        <SlotsCreatorForm />
      </div>
    </Scrollbar>
  );
};

import { Tag } from "@jutro/ui";
import { ComponentProps } from "react";
import { DoctorMSPrescriptionStatus } from "lib/graphql/megaSchema";

const prescriptionStatusToColorMap: Record<
  DoctorMSPrescriptionStatus,
  ComponentProps<typeof Tag>["color"]
> = {
  CANCELED: "rose",
  COMPLETED: "green",
  ISSUED: "blue",
  PARTIALLY_COMPLETED: "green",
};

const prescriptionStatusToTextMap: Record<DoctorMSPrescriptionStatus, string> =
  {
    CANCELED: "Anulowany",
    COMPLETED: "Zrealizowany",
    ISSUED: "Wystawiony",
    PARTIALLY_COMPLETED: "Częściowo Zrealizowany",
  };

export const getPrescriptionStatusTagConfig = ({
  status,
}: {
  status: DoctorMSPrescriptionStatus;
}) => {
  const text = prescriptionStatusToTextMap[status];
  const color = prescriptionStatusToColorMap[status];

  return { text, color };
};

export const getDrugName = (drugInfo: string): string => {
  const words = drugInfo.split(" ");

  // Szukamy pierwszego słowa z liczbą (czyli początku dawki)
  const doseIndex = words.findIndex((word) => /\d/.test(word));

  // Jeśli nie ma liczby, to cała nazwa jest tylko nazwą leku
  if (doseIndex === -1) return drugInfo;

  // Nazwa leku to wszystko przed pierwszą liczbą
  return words.slice(0, doseIndex).join(" ");
};

export const getDrugBoxAmount = (drugName: string): string | null => {
  const match = drugName.match(
    /(\d+\s*(szt\.|dawek|but\.|ml|g))(?:\s*\(.*\))?$/i,
  );

  return match ? match[0] : null;
};

const drugForms = [
  "tabletki powlekane",
  "tabl. powl.",
  "tabl.",
  "kaps. o przedł. uwalnianiu",
  "kaps.",
  "aerozol",
  "maść",
  "zaw.",
  "but.",
];

const removeDrugForm = (dose: string) => {
  for (const form of drugForms) {
    const index = dose.toLowerCase().indexOf(form);
    if (index !== -1) {
      const str = dose.slice(index);
      return dose.replace(str, "").trim();
    }
  }

  return dose.trim();
};

export const getDrugDose = (drugInfo: string) => {
  const name = getDrugName(drugInfo);
  const boxAmount = getDrugBoxAmount(drugInfo);

  let dosePart = drugInfo.replace(name, "").trim();
  if (boxAmount) {
    dosePart = dosePart.replace(boxAmount, "").trim();
  }

  // Usuwamy przecinek na końcu
  const dose = dosePart.replace(/,$/, "").trim();

  if (dose.length === 0) return null;

  return removeDrugForm(dose);
};

export const getDrugForm = (drugInfo: string) => {
  const drugForm = drugForms.find((drugForm) =>
    drugInfo.toLowerCase().includes(drugForm),
  );

  return drugForm ?? null;
};

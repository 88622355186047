import { DoctorMeQuery } from "lib/graphql/megaSchema";
import { procedureTitleMap } from "lib/tools/procedureTitleMap";
import { VisitsItem } from "views/Visits/PerDay/components/SlotList/types";

export const getSelectedDoctorFromCurrentUser = (
  currentUser: DoctorMeQuery["doctorMe"],
): Pick<DoctorMeQuery["doctorMe"], "id" | "firstName" | "lastName"> => {
  const { id, firstName, lastName } = currentUser;
  return { id, firstName, lastName };
};

export const getProcedureVisitTypeLabel = (
  procedures: VisitsItem["procedures"],
  laboratoryExaminations: VisitsItem["laboratoryExaminations"],
) => {
  const procedureElement = procedures[0];
  if (procedures.length === 0 && laboratoryExaminations.length === 0) {
    return "Brak dodanych badań/procedur";
  }

  if (procedures.length === 0) {
    return "Badanie lab";
  }

  if (procedures.length === 1) {
    return procedureTitleMap[procedureElement.type];
  }

  if (procedures.length > 1) {
    return `${procedureTitleMap[procedureElement.type]} (+${procedures.length - 1})`;
  }

  return "Brak dodanych procedur/badań";
};

export const getVisitType = (visit: VisitsItem) => {
  if (visit.type === "VIRTUAL" && !visit.prescriptionRequestId) {
    return (
      <span className="font-paragraph-1 text-jutro-new-green-400">
        Telemedyczna
      </span>
    );
  }

  if (visit.type === "VIRTUAL" && visit.prescriptionRequestId) {
    return (
      <span className="font-paragraph-1 text-jutro-new-orange-400">
        Receptowa
      </span>
    );
  }

  if (visit.type === "STANDARD") {
    return (
      <span className="font-paragraph-1 text-jutro-new-blue-800">Osobista</span>
    );
  }

  return (
    <span className="font-paragraph-1 text-jutro-new-blue-800">Zabiegowa</span>
  );
};

export const getProcedureVisitAdditionalInfo = (
  procedures: VisitsItem["procedures"],
  laboratoryExaminations: VisitsItem["laboratoryExaminations"],
) => {
  const procedureElement = procedures[0];
  const laboratoryExaminationElement = laboratoryExaminations[0];
  const fallbackCopy = "";

  if (laboratoryExaminations.length === 1) {
    return laboratoryExaminationElement.assignment.name;
  }

  if (laboratoryExaminations.length > 1) {
    return `${laboratoryExaminationElement.assignment.name} + ${
      laboratoryExaminations.length - 1
    } innych badań laboratoryjnych`;
  }

  if (!procedureElement) {
    return fallbackCopy;
  }

  if (procedureElement.usg) {
    return procedureElement.usg.referral?.procedureName;
  }

  if (procedureElement.ekg) {
    return fallbackCopy;
  }

  if (procedureElement.education) {
    if (!procedureElement.education.comment) {
      return fallbackCopy;
    }

    return procedureElement.education.comment;
  }

  if (procedureElement.chukEducation) {
    if (!procedureElement.chukEducation.comment) {
      return fallbackCopy;
    }

    return procedureElement.chukEducation.comment;
  }

  if (procedureElement.chukMeasurements) {
    if (!procedureElement.chukMeasurements.comment) {
      return fallbackCopy;
    }

    return procedureElement.chukMeasurements.comment;
  }

  return fallbackCopy;
};

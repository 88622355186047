import { Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { ActionsList } from "components/ExaminationPanel/ProcedurePanel/TestsList";
import { useProcedureFormValidation } from "components/ExaminationPanel/ProcedurePanel/hooks/useProcedureFormValidation";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { isUsg } from "components/ExaminationPanel/utils/isUsg";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorDraftVisitsDocument,
  useDoctorCloseVisitMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { useVisitData } from "views/Visit/hooks";
import { isVisitClosedAtom, visitTestsAtom } from "../atoms";

export const ProcedurePanel = () => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  const [closeVisitMutation] = useDoctorCloseVisitMutation({
    variables: { id: currentVisit?.id ?? "" },
    refetchQueries: [DoctorDraftVisitsDocument],
    awaitRefetchQueries: true,
  });
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [usgConfirmModal, setUsgConfirmModal] = useState(false);

  const [visitTests] = useAtom(visitTestsAtom);

  const [isVisitClosed, setIsVisitClosed] = useAtom(isVisitClosedAtom);

  useEffect(() => {
    setIsVisitClosed(Boolean(currentVisit?.snapshotAt));
  }, [currentVisit?.snapshotAt]);

  const closeVisit = async () => {
    toaster.notify("Zamykam poradę...");

    await closeVisitMutation()
      .then(() => {
        toaster.success("Wizyta zamknięta!");
      })
      .then(() => {
        navigate("/", { replace: true });
      })
      .catch(() => {
        toaster.error("Wystąpił błąd...");
      });
  };

  const areSampleIdsPresent =
    visitTests.filter((e) => isLaboratoryExamination(e) && !e.sampleId)
      .length === 0;

  const { shouldRunAction, handleSetShouldTrigger, resetActionStatus } =
    useProcedureFormValidation();

  const handleCloseVisit = () => {
    if (isUsgInCurrentVisit) {
      setUsgConfirmModal(true);
      return;
    }

    setConfirmModal(true);
  };

  useEffect(() => {
    if (!shouldRunAction) {
      return;
    }

    resetActionStatus();
    handleCloseVisit();
  }, [shouldRunAction]);

  const isUsgInCurrentVisit = Boolean(visitTests.find((e) => isUsg(e)));

  return (
    <div className="extendedGrid col-start-2 col-end-4 h-full overflow-y-hidden">
      <div className="flex h-full flex-col gap-4">
        {visitTests.length !== 0 && (
          <div className="overflow-y-scroll">
            <ActionsList />
          </div>
        )}
        <div className={`flex flex-1 flex-col gap-3`}>
          {/* @ts-ignore */}
          <Droppable droppableId="middle">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex h-full min-h-32 w-full items-center justify-center rounded-lg bg-white p-4"
              >
                <div
                  className={`${
                    snapshot.isDraggingOver && "bg-blue-50"
                  } flex h-full w-full items-center justify-center rounded-lg border border-dashed`}
                >
                  <span className="font-paragraph-1">
                    Przeciągnij i upuść, aby dodać nową pozycję
                  </span>
                </div>
              </div>
            )}
          </Droppable>

          <Button
            onClick={() => {
              if (visitTests.length === 0) {
                handleCloseVisit();
                return;
              }

              handleSetShouldTrigger();
            }}
            disabled={!areSampleIdsPresent || visitTests.length === 0}
            type="button"
            text={isVisitClosed ? "Zamknij" : "Zapisz i zamknij"}
          />
        </div>
      </div>
      <ConfirmDialog
        title="Czy chcesz zapisać i opuścić wizytę?"
        text="Po zapisaniu wizyty będziesz mieć możliwość jej edycji do końca dnia. Następnie system automatycznie zablokuje możliwość jej edycji."
        confirmText="Zapisz i opuść wizytę"
        open={usgConfirmModal}
        setOpen={setUsgConfirmModal}
        onConfirm={() => navigate("/visits/per-day")}
      />

      <ConfirmDialog
        title="Zamknięcie wizyty"
        text={`Czy na pewno chcesz ${
          isVisitClosed ? "zamknąć" : "zapisać i zamknąć"
        } wizytę?`}
        confirmText="Zatwierdź"
        open={confirmModal}
        setOpen={setConfirmModal}
        onConfirm={() => {
          if (isVisitClosed) {
            navigate("/", { replace: true });
            return;
          }
          closeVisit();
        }}
      />
    </div>
  );
};

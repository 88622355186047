import { Pagination, Table } from "@jutro/ui";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import {
  visitsArchiveCurrentPageAtom,
  visitsArchiveTotalPagesAtom,
} from "views/Visits/Archive/atoms";
import { VisitArchiveEmptyInfo } from "views/Visits/Archive/components/VisitsArchiveTable/VisitArchiveEmptyInfo";
import { visitsArchiveTableColumns } from "views/Visits/Archive/components/VisitsArchiveTable/columns";
import { ArchivedVisit } from "views/Visits/Archive/types";

type Props = {
  data: ArchivedVisit[];
};

export const VisitsArchiveTable = ({ data }: Props) => {
  const navigate = useNavigate();

  const [totalPages] = useAtom(visitsArchiveTotalPagesAtom);
  const [currentPage, setCurrentPage] = useAtom(visitsArchiveCurrentPageAtom);

  if (data.length === 0) {
    return <VisitArchiveEmptyInfo />;
  }

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-x-auto">
      <Table
        columns={visitsArchiveTableColumns}
        data={data}
        selectionRowMode="single"
        selectedRow={null}
        setSelectedRow={(_visit) => {
          const visit = _visit as ArchivedVisit;

          if (!visit) {
            return;
          }

          navigate(`/visits/${visit.id}`);
        }}
      />

      {totalPages ? (
        <div className="flex items-center justify-center rounded-lg bg-white p-4">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
};

import { Maybe } from "@jutro/types";
import { DoctorGetDoctorsQuery } from "lib/graphql/megaSchema";

export const getDeclaredMedic = (
  doctorsData: DoctorGetDoctorsQuery | undefined,
  chosenNpwz: Maybe<string>,
) => {
  if (!doctorsData || !chosenNpwz) {
    return null;
  }

  const medic = doctorsData.doctorDoctors.find(
    ({ npwz }) => npwz === chosenNpwz,
  );

  if (!medic) {
    return null;
  }

  const { id, adultDeclarable, childrenDeclarable, firstName, lastName } =
    medic;

  const name = `${firstName} ${lastName}`;

  return {
    id,
    name,
    isDeclarable: Boolean(adultDeclarable || childrenDeclarable),
  };
};

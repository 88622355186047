import { ImgHTMLAttributes } from "react";

export const RightPlant = (props: ImgHTMLAttributes<unknown>) => {
  return (
    <img
      className="absolute bottom-0 right-1/4 hidden h-1/4 xl:right-40 xl:block"
      {...props}
    />
  );
};

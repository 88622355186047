import { lazy } from "react";

export const lazyImport = <
  T extends React.ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I
>(
  factory: () => Promise<I>,
  name: K
) => lazy(() => factory().then((module) => ({ default: module[name] })));

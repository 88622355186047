import { ImgHTMLAttributes } from "react";

export const LeftPlant = (props: ImgHTMLAttributes<unknown>) => {
  return (
    <img
      className="absolute bottom-0 right-1/3 hidden h-1/4 xl:right-1/4 xl:block"
      {...props}
    />
  );
};

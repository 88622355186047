import { useAtom } from "jotai";
import { ScreenLoader } from "components/new";
import { usePerDayVisitsQuery } from "lib/graphql/megaSchema";
import {
  selectedDateAtom,
  selectedDoctorAtom,
  showTodoOnlyAtom,
} from "views/Visits/PerDay/atoms";
import { SlotInfo } from "views/Visits/PerDay/components/SlotList/SlotInfo/index";
import { VisitGroup } from "views/Visits/PerDay/components/SlotList/VisitGroup/index";
import { useSlots } from "views/Visits/PerDay/components/SlotList/hooks";

export const SlotList = () => {
  const [selectedDate] = useAtom(selectedDateAtom);
  const [selectedDoctor] = useAtom(selectedDoctorAtom);
  const [showTodoOnly] = useAtom(showTodoOnlyAtom);

  const {
    data: visits,
    loading: loadingVisits,
    error: errorVisits,
  } = usePerDayVisitsQuery({
    variables: {
      doctorId: selectedDoctor?.id,
      forDay: selectedDate,
    },
    pollInterval: 30 * 1000,
    fetchPolicy: "no-cache",
  });

  const slots = useSlots(visits?.doctorGetVisitsPerDayForDoctor, showTodoOnly);

  if (loadingVisits) return <ScreenLoader />;

  if (errorVisits) return <SlotInfo isError text="Coś poszło nie tak..." />;

  if (slots.length === 0) return <SlotInfo text="Brak wizyt." />;

  return slots.map((slot) => (
    <VisitGroup
      key={slot.hour}
      title={`${slot.hour}:00 - ${slot.hour}:59`}
      visits={slot.visits}
    />
  ));
};

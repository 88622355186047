import { MessageBox, SolidCircleAlertIcon } from "@jutro/ui";

export const MaintenanceModeView = () => {
  return (
    <div className="grid h-screen place-items-center">
      <div className="flex max-w-[520px] flex-col gap-4">
        <div className="flex justify-center text-jutro-new-rose-400">
          <SolidCircleAlertIcon size="lg" />
        </div>

        <MessageBox
          text="Obecnie prowadzimy prace techniczne, aby ulepszyć naszą aplikację. Już wkrótce będziecie mogli z niej ponownie korzystać.<br /><br />Przepraszamy za niedogodności i dziękujemy za cierpliwość"
          color="rose"
          full={false}
        />
      </div>
    </div>
  );
};

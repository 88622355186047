import { Button } from "@jutro/ui";
import { PropsWithChildren } from "react";

type Props = {
  removeButtonDisabled: boolean;
  onRemoveButtonClick: () => void;
};

export const ProcedureFormHeader = ({
  children,
  removeButtonDisabled,
  onRemoveButtonClick,
}: PropsWithChildren<Props>) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-2">{children}</div>
      <Button
        full={false}
        variant="negative"
        size="condensed"
        text="Usuń z listy"
        disabled={removeButtonDisabled}
        onClick={onRemoveButtonClick}
      />
    </div>
  );
};

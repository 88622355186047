import { PatientVitality } from "lib/types";

export const fileParser = (examination: PatientVitality) => {
  const fileArr: string[] = [];
  const imageArr: string[] = [];
  if (!examination.overrideFiles)
    return {
      files: fileArr,
      images: imageArr,
    };
  if (examination.overrideFiles.filter((e) => e).length <= 0)
    return {
      files: fileArr,
      images: imageArr,
    };
  examination.overrideFiles
    .map((e) => e)
    .sort((file) => {
      const extension = file.split(".")[file.split(".").length - 1] ?? "";
      return ["jpg", "png", "jpeg", "heic"].includes(extension.toLowerCase())
        ? 1
        : -1;
    })
    .map((file) => {
      const extension = file.split(".")[file.split(".").length - 1] ?? "";
      if (["jpg", "png", "jpeg", "heic"].includes(extension.toLowerCase())) {
        imageArr.push(file);
      } else {
        return fileArr.push(file);
      }

      return undefined;
    });

  return {
    files: fileArr.filter((e) => e),
    images: imageArr,
  };
};

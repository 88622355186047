import { envVars } from "envvars";
import Cookies from "js-cookie";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useOrderedTests } from "components/PatientDocs/Ordered/hooks/useOrderedTests";
import {
  AppliedVoucherCodeData,
  CreateCheckoutMutationBody,
  PayCheckoutMutationBody,
  VoucherCodeError,
  VoucherCreationMutationBody,
} from "components/PatientDocs/Ordered/types";
import { ecommercePosConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";

type Props = {
  packetId: string;
  patientId: string;
  patientEmail?: string | null;
  posPaymentNumberValue?: string;
  setIsPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const useHandleEcommercePackets = ({
  packetId,
  patientId,
  patientEmail,
  posPaymentNumberValue,
  setIsPaymentModalOpen,
}: Props) => {
  const [voucherCodeInput, setVoucherCodeInput] = useState<string>("");
  const [checkout, setCheckout] = useState<AppliedVoucherCodeData | null>(null);
  const [checkoutId, setCheckoutId] = useState<string | null>(null);
  const [voucherCodeError, setVoucherCodeError] =
    useState<VoucherCodeError | null>(null);

  const { labExamsRefetch } = useOrderedTests({ patientId });

  const CREATE_CHECKOUT_API_URL = `${
    ecommercePosConfig[envVars.REACT_APP_CONFIG]
  }/ecommerce/checkouts/single-packets`;

  const ADD_VOUCHER_API_URL = `${
    ecommercePosConfig[envVars.REACT_APP_CONFIG]
  }/ecommerce/checkouts/${checkoutId}/vouchers`;

  const PAY_CHECKOUT_API_URL = `${
    ecommercePosConfig[envVars.REACT_APP_CONFIG]
  }/ecommerce/checkouts/${checkoutId}/payments/pos`;

  const createCheckoutMutation = useMutation(
    (body: CreateCheckoutMutationBody) =>
      fetch(CREATE_CHECKOUT_API_URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      }),
  );

  const voucherAddMutation = useMutation((body: VoucherCreationMutationBody) =>
    fetch(ADD_VOUCHER_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }),
  );

  const payCheckoutMutation = useMutation((body: PayCheckoutMutationBody) =>
    fetch(PAY_CHECKOUT_API_URL, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${Cookies.get("JWT")}`,
      },
    }),
  );

  useEffect(() => {
    handleCreateCheckout();
  }, [packetId]);

  const handleCreateCheckout = async () => {
    if (!packetId) {
      return;
    }

    if (!patientEmail) {
      toaster.error("Pacjent nie podał adresu email");
      return;
    }

    try {
      const packetData = {
        packetId,
        patientId,
        patientEmail,
        source: "medical-app.pos-ecommerce",
      };
      const response = await createCheckoutMutation.mutateAsync(packetData);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setCheckoutId(responseData.checkoutId);
    } catch (error: any) {
      throw new Error();
    }
  };

  const handleAddPromoCode = async (voucherCode: string | null) => {
    if (!voucherCode) {
      return;
    }

    try {
      const packetData = {
        promoCode: voucherCode,
      };

      const response = await voucherAddMutation.mutateAsync(packetData);

      if (!response.ok) {
        setVoucherCodeError({
          error: true,
          message: "Podany kod jest nieprawidłowy",
        });
        throw new Error("Network response was not ok");
      }

      const responseData: AppliedVoucherCodeData = await response.json();
      setVoucherCodeInput("");
      setCheckout(responseData);
      setVoucherCodeError(null);
    } catch (error: any) {
      throw new Error();
    }
  };

  const onVoucherCodeApply = () => {
    if (!voucherCodeInput) {
      setVoucherCodeError({
        error: true,
        message: "Wpisz kod rabatowy",
      });
      return;
    }

    toaster.promise(handleAddPromoCode(voucherCodeInput), {
      loading: "Sprawdzam kod rabatowy...",
      success: "Kod rabatowy dodany poprawnie!",
      error: "Nieprawidłowy kod rabatowy",
    });
  };

  const handlePayCheckout = async (paymentId: string) => {
    if (voucherCodeInput) {
      setVoucherCodeError({
        error: true,
        message: "Dodaj kod rabatowy do koszyka",
      });
      return;
    }
    try {
      const dataToSend = {
        posTransaction: paymentId,
      };

      const response = await payCheckoutMutation.mutateAsync(dataToSend);

      setTimeout(labExamsRefetch, 2500);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      clearCheckout();
      setIsPaymentModalOpen(false);
    } catch (error: any) {
      throw new Error();
    }
  };

  const onPayChekoutClick = () => {
    if (!posPaymentNumberValue) {
      return;
    }

    toaster.promise(handlePayCheckout(posPaymentNumberValue), {
      loading: "Opłacam badania...",
      success: "Badania zostały opłacone pomyślnie!",
      error: "Wystąpił błąd podczas opłacania badań",
    });
  };

  const clearCheckout = () => {
    setCheckout(null);
    setVoucherCodeError(null);
    setCheckoutId(null);
    setVoucherCodeInput("");
  };

  const appliedVoucherCodeData: AppliedVoucherCodeData = {
    checkoutId: checkoutId,
    discounts: checkout?.discounts,
    giftCards: checkout?.giftCards,
    products: checkout?.products,
    promotion: checkout?.promotion,
    totalPrice: checkout?.totalPrice,
    voucher: checkout?.voucher,
  };

  return {
    isVoucherCodeLoading: voucherAddMutation.isLoading,
    checkoutIsPaying: payCheckoutMutation.isLoading,
    appliedVoucherCodeData: appliedVoucherCodeData,
    voucherCodeInput,
    voucherCodeError,
    onVoucherCodeApply,
    setVoucherCodeInput,
    clearCheckout,
    onPayChekoutClick,
    setVoucherCodeError,
  };
};

import { FetchPolicy } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDoctorGetPatientQuery } from "lib/graphql/megaSchema";

export const usePatientData = ({
  from,
}: {
  from: Extract<FetchPolicy, "network-only" | "cache-only" | "cache-first">;
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data, loading } = useDoctorGetPatientQuery({
    fetchPolicy: from,
    skip: !patientId,
    variables: {
      id: patientId,
    },
  });

  return {
    data: data?.doctorPatient,
    loading,
  };
};

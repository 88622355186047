import dayjs from "dayjs";
import { DoctorMSDrug, DoctorMSTime } from "lib/graphql/megaSchema";
import { CopyDrugToPrescription } from "views/Visit/RightPanel/NewPrescription/components/Overlays/CopyButton/index";
import { DrugDetails } from "views/Visit/RightPanel/NewPrescription/components/Overlays/DrugDetails";

type Props = {
  drugs: DoctorMSDrug[];
  writeup: DoctorMSTime;
};

export const RecentPrescription = ({ drugs, writeup }: Props) => {
  const calcualteDaysPastSinceVisit = (visitDate: string) => {
    const currentDate = dayjs();
    const daysPassed = currentDate.diff(visitDate, "day");

    return `${dayjs(writeup.iso).format(
      "DD.MM.YYYY",
    )}  (${daysPassed} dni temu)`;
  };

  return drugs.map((drug) => (
    <div key={drug.ean} className="flex gap-4 pt-4 first-of-type:pt-0">
      <div className="flex flex-1 flex-col gap-1">
        <DrugDetails
          name={drug.drug}
          form={drug.form}
          dose={drug.dose}
          box={drug.package}
          boxAmount={drug.boxAmount}
          issueDate={calcualteDaysPastSinceVisit(writeup.iso)}
        />
      </div>

      <CopyDrugToPrescription drugDetails={drug} />
    </div>
  ));
};

import { QuestionIcon, Tooltip } from "@jutro/ui";
import { DoctorVisitQuery } from "lib/graphql/megaSchema";
import { Maybe } from "lib/types";

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

type SelectLabelProps = {
  value: Maybe<string>;
  chronicDiseaseTag?: Patient["tags"][number];
};

export const SelectLabel = ({ value, chronicDiseaseTag }: SelectLabelProps) => {
  if (!value) return null;

  const [code, name] = value.split(/(?<=^\S+)\s/);

  if (!code || !name) {
    return null;
  }

  return (
    <div className="flex flex-1 items-center justify-between">
      <span className="font-paragraph-1 flex flex-1 pr-2">{name}</span>
      <div className="font-paragraph-1 z-1 flex cursor-pointer items-center rounded-sm bg-jutro-new-green-400 px-2 py-0.5 font-semibold text-white">
        {chronicDiseaseTag && (
          <Tooltip
            position="left"
            disabled
            trigger={
              <div className="mr-1">
                <QuestionIcon size="sm" />
              </div>
            }
            content={<></>}
          />
        )}
        {code}
      </div>
    </div>
  );
};

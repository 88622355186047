import { LazyQueryExecFunction } from "@apollo/client";
import {
  AddUserIcon,
  LogoutIcon,
  MessageRemoveIcon,
  MessageTimeIcon,
} from "@jutro/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChatButtonPopover } from "components/new/ChatButtonPopover";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorMeQuery,
  DoctorNextPatientQuery,
  Exact,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { usePatientActivityActions } from "./usePatientActivityActions";

type Props = {
  patientId: string;
  nextPatient: LazyQueryExecFunction<
    DoctorNextPatientQuery,
    Exact<{
      [key: string]: never;
    }>
  >;
  user: DoctorMeQuery["doctorMe"] | null;
};

export const NewChatMenu = ({ patientId, nextPatient, user }: Props) => {
  const [isWaitingForPatientModalOpen, setIsWaitingForPatientModalOpen] =
    useState(false);
  const [isLeavePatientModalOpen, setIsLeavePatientModalOpen] = useState(false);
  const [isClosePatientModalOpen, setIsClosePatientModalOpen] = useState(false);
  const [isNextPatientModalOpen, setIsNextPatientModalOpen] = useState(false);

  const { closePatient, waitingForPatient, unpinPatient } =
    usePatientActivityActions(patientId);

  const navigate = useNavigate();

  if (!user || user.genre === "DOCTOR") {
    return null;
  }

  return (
    <div className="mt-2 flex h-full gap-2">
      <ChatButtonPopover
        text="Czekam na pacjenta"
        colorType="gray"
        onClick={() => {
          setIsWaitingForPatientModalOpen(true);
        }}
        icon={<MessageTimeIcon />}
      />
      <ChatButtonPopover
        text="Odepnij się"
        colorType="blue"
        onClick={() => {
          setIsLeavePatientModalOpen(true);
        }}
        icon={<LogoutIcon />}
      />
      <ChatButtonPopover
        text="Zamknij"
        colorType="red"
        onClick={() => {
          setIsClosePatientModalOpen(true);
        }}
        icon={<MessageRemoveIcon />}
      />
      <ChatButtonPopover
        text="Następny pacjent"
        colorType="green"
        icon={<AddUserIcon />}
        onClick={() => {
          setIsNextPatientModalOpen(true);
        }}
      />
      <ConfirmDialog
        title={`Czy na pewno chcesz poczekać na pacjenta?`}
        confirmText="Czekam na pacjenta"
        open={isWaitingForPatientModalOpen}
        setOpen={setIsWaitingForPatientModalOpen}
        onConfirm={() => {
          waitingForPatient(() => {
            toaster.success("Oznaczono, że czekasz na pacjenta");
            navigate("/");
          });
        }}
        submitVariant="primary"
      />
      <ConfirmDialog
        title={`Czy na pewno chcesz odpiąć się od pacjenta?`}
        confirmText="Odepnij się"
        open={isLeavePatientModalOpen}
        setOpen={setIsLeavePatientModalOpen}
        onConfirm={() => {
          unpinPatient(() => {
            toaster.success("Odpięto od pacjenta");
            navigate("/");
          });
        }}
        submitVariant="primary"
      />
      <ConfirmDialog
        title={`Czy na pewno chcesz zamknąć pacjenta?`}
        confirmText="Zamknij"
        open={isClosePatientModalOpen}
        setOpen={setIsClosePatientModalOpen}
        onConfirm={() => {
          closePatient(() => {
            toaster.success("Zamknięto pacjenta");
            navigate("/");
          });
        }}
        submitVariant="primary"
      />
      <ConfirmDialog
        title={`Czy na pewno chcesz przejść do następnego pacjenta?`}
        confirmText="Następny pacjent"
        open={isNextPatientModalOpen}
        setOpen={setIsNextPatientModalOpen}
        onConfirm={() => {
          nextPatient();
        }}
        submitVariant="primary"
      />
    </div>
  );
};

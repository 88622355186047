import { DoctorMSPatientTagType } from "lib/graphql/megaSchema";

export const tagTypes: {
  type: DoctorMSPatientTagType;
  name: string;
}[] = [
  {
    type: "CHRONIC_DISEASE",
    name: "Choroby przewlekłe",
  },
  {
    type: "REGULAR_DRUG",
    name: "Stałe przyjmowane leki",
  },
  {
    type: "ALLERGY",
    name: "Alergie",
  },
  {
    type: "ADDITIONAL",
    name: "Informacje dodatkowe",
  },
  {
    type: "SPEC_CONSULTATIONS",
    name: "Konsultacje specjalistyczne",
  },
  {
    type: "HOSPITALIZATION",
    name: "Hospitalizacje",
  },
  {
    type: "SURGICAL_PROCEDURES",
    name: "Zabiegi operacyjne",
  },
  {
    type: "VACCINES",
    name: "Szczepienia",
  },
];

import { MessageBox } from "@jutro/ui";
import { DoctorVisitQuery } from "lib/graphql/megaSchema";

type Props = {
  tags?: NonNullable<DoctorVisitQuery["doctorVisit"]["patient"]>["tags"];
};

export const ChronicDiseasesMessageBox = ({ tags }: Props) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <MessageBox
      color="orange"
      text={`Pacjent ze ${
        tags.length > 1
          ? "stwierdzonymi chorobami przewlekłymi, zweryfikuj rozpoznania"
          : "stwierdzoną chorobą przewlekłą, zweryfikuj rozpoznanie"
      }`}
    />
  );
};

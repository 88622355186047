import {
  ScheduledVisitAppointmentIconStylesProps,
  getScheduledVisitAppointmentIconStyles,
} from "./styles";

export const VisitDefaultStatusIcon = (
  props: ScheduledVisitAppointmentIconStylesProps,
) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={getScheduledVisitAppointmentIconStyles(props)}
    >
      <path
        d="M0.791992 8.00113C0.791992 3.58287 4.3737 0 8.79195 0C13.2102 0 16.7919 3.58287 16.7919 8.00113C16.7919 12.4194 13.2102 16.0023 8.79195 16.0023C4.3737 16.0023 0.791992 12.4194 0.791992 8.00113Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2883 0.0145265C6.76613 0.104177 5.22537 0.668847 4.0123 1.58164C2.25479 2.90412 1.13694 4.81572 0.854162 6.98228C0.751794 7.76653 0.776631 8.6653 0.922851 9.46923C1.11038 10.5002 1.54808 11.5712 2.1437 12.4565C3.36681 14.2743 5.27704 15.5164 7.41773 15.8858C8.31772 16.0411 9.26599 16.0411 10.166 15.8858C11.7026 15.6207 13.1388 14.9009 14.2777 13.8251C15.6487 12.5302 16.499 10.8518 16.737 8.97074C16.8278 8.25383 16.8049 7.37193 16.6764 6.62711C16.3834 4.92902 15.535 3.35694 14.2716 2.17098C12.6508 0.64949 10.5238 -0.117116 8.2883 0.0145265ZM8.33951 1.08154C7.12098 1.17852 6.06463 1.52456 5.07063 2.15237C4.24681 2.67271 3.46334 3.45618 2.943 4.28C2.37095 5.18571 2.03541 6.13684 1.90123 7.23309C1.85852 7.58194 1.85852 8.42052 1.90123 8.76937C2.03541 9.86561 2.37095 10.8167 2.943 11.7225C3.46334 12.5463 4.24681 13.3297 5.07063 13.8501C5.97503 14.4213 6.9308 14.7585 8.02371 14.8919C8.37279 14.9346 9.21153 14.9345 9.56 14.8919C10.6536 14.758 11.6095 14.4208 12.5131 13.8501C13.3369 13.3297 14.1204 12.5463 14.6407 11.7225C15.2114 10.8189 15.5486 9.86298 15.6825 8.76937C15.7251 8.42091 15.7252 7.58216 15.6826 7.23309C15.5491 6.14017 15.2119 5.1844 14.6407 4.28C14.1204 3.45618 13.3369 2.67271 12.5131 2.15237C11.6139 1.58447 10.6471 1.24219 9.57707 1.11304C9.35692 1.08646 8.53917 1.06565 8.33951 1.08154Z"
        fill="#92929B"
      />
    </svg>
  );
};

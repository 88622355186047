import { Button } from "@jutro/ui";
import { envVars } from "envvars";
import { useState } from "react";
import { LabExamParameterGroup } from "./components/LabExamParameterGroup";
import { VisitIdInput } from "./components/VisitIdInput";
import { Scrollbar } from "components/new";
import {
  DoctorTemplateOfLabExamFragment,
  useDoctorDiagnosticExaminationTemplateLazyQuery,
} from "lib/graphql/megaSchema";
import { isProductionEnvironment } from "lib/tools";
import { httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";

export const DiagSimulator = () => {
  const [examinationTemplates, setExaminationTemplates] = useState<
    DoctorTemplateOfLabExamFragment[]
  >([]);
  const [visitId, setVisitId] = useState("");

  const [getDiagnosticExaminationTemplate] =
    useDoctorDiagnosticExaminationTemplateLazyQuery();

  const pathwaysApplySimulatorTokenExists = Boolean(
    envVars.REACT_APP_PATHWAYS_APPLY_SIMULATION_TOKEN,
  );

  if (isProductionEnvironment()) {
    return null;
  }

  const fetchTemplates = async (visitId: string) => {
    const result = await getDiagnosticExaminationTemplate({
      variables: { visitId },
    });

    if (result.error) {
      throw new Error(`${result.error}`);
    }

    setExaminationTemplates(result.data?.doctorGetTemplatesForVisit ?? []);
  };

  const getExaminationTemplatesData = async (visitId: string) => {
    setVisitId(visitId);

    await toaster.promise(fetchTemplates(visitId), {
      error: "Nie pobrano badań dla podanego ID",
      loading: "Pobieram badania dla wizyty",
      success: "Pobrano badania dla wizyty",
    });
  };

  const handleDeleteFromList = (serviceId: string) => {
    const fitleredExaminationTemplates = examinationTemplates.filter(
      (getTemplatesForVisit) =>
        getTemplatesForVisit.assignment.foreignServiceId !== serviceId,
    );

    setExaminationTemplates(fitleredExaminationTemplates);
  };

  const handleApplyPathways = () => {
    const sendPathwaysRequest = async () => {
      await fetch(
        `${
          httpConfig[envVars.REACT_APP_CONFIG]
        }/pathways/apply-recommended-lab-exams?visitId=${visitId}`,
        {
          method: "POST",
          headers: {
            Authorization:
              envVars.REACT_APP_PATHWAYS_APPLY_SIMULATION_TOKEN ?? "",
            "Content-Type": "application/json",
          },
        },
      );
    };

    toaster.promise(sendPathwaysRequest(), {
      error:
        "Nie udało się zaaplikować pathways, sprawdź logi w konsoli przeglądarki",
      loading: "Aplikuję pathways",
      success: "Zaaplikowano pathways",
    });
  };

  return (
    <Scrollbar>
      <div className="mx-auto flex flex-col items-center justify-center gap-5 bg-white px-5 py-10">
        <h1 className="font-display-1">Diag Simulator</h1>
        <VisitIdInput onGetExaminationsTemplate={getExaminationTemplatesData} />
        {examinationTemplates.map((template) => (
          <LabExamParameterGroup
            key={template.assignment.foreignServiceId}
            name={template.assignment.name}
            visitId={visitId}
            serviceId={template.assignment.foreignServiceId}
            initialParams={template.params ?? []}
            deleteFromList={handleDeleteFromList}
          />
        ))}
      </div>
      {pathwaysApplySimulatorTokenExists && (
        <div className="mx-auto my-5 w-1/4">
          <Button text="Zaaplikuj pathwaysy" onClick={handleApplyPathways} />
        </div>
      )}
    </Scrollbar>
  );
};

import { SolidCircleRemoveIcon } from "@jutro/ui";

export const EmptyPrescriptionList = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <div className="text-jutro-new-warm-gray-200 [&>svg]:!size-16">
        <SolidCircleRemoveIcon />
      </div>
      <div className="font-heading-3">Brak recept do wyświetlenia</div>
    </div>
  );
};

export type NfzEventColor = "rose" | "green" | "orange" | "black" | "gray";

export const nfzEventColorMap: Record<
  NfzEventColor,
  { textColor: string; iconColor: string }
> = {
  black: {
    iconColor: "text-jutro-new-warm-gray-800",
    textColor: "text-jutro-new-warm-gray-800",
  },
  rose: {
    iconColor: "text-jutro-new-rose-500",
    textColor: "text-jutro-new-rose-700",
  },
  green: {
    iconColor: "text-jutro-new-green-500",
    textColor: "text-jutro-new-green-700",
  },
  orange: {
    iconColor: "text-jutro-new-orange-400",
    textColor: "text-jutro-new-orange-700",
  },
  gray: {
    iconColor: "text-jutro-new-warm-gray-400",
    textColor: "text-jutro-new-warm-gray-700",
  },
};

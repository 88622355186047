import dayjs from "dayjs";
import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { currentUserAtom } from "lib/atoms/auth";
import { getSelectedDoctorFromCurrentUser } from "./tools";

export const showTodoOnlyAtom = atom(false);

export const selectedDateAtom = atom(dayjs());

export const selectedDoctorAtom = atomWithDefault((get) => {
  const currentUser = get(currentUserAtom);
  if (!currentUser) return null;
  return getSelectedDoctorFromCurrentUser(currentUser);
});

import { Option } from "@jutro/types";
import dayjs from "dayjs";
import { FormType } from "views/NFZReports/types";

export const organizationSelectOptions: Option<string>[] = [
  { value: "PM", label: "Woj. Mazowieckie" },
  { value: "PK", label: "Woj. Małopolskie" },
  { value: "PG", label: "Woj. Pomorskie" },
  { value: "PL", label: "Woj. Lubelskie" },
  { value: "PB", label: "Woj. Podlaskie" },
  { value: "PE", label: "Woj. Łódzkie" },
  { value: "PD", label: "Woj. Dolnośląskie" },
  { value: "PS", label: "Woj. Śląskie" },
  { value: "PP", label: "Woj. Wielkopolskie" },
];

export const periodSymbolOptions: Option<string>[] = (() => {
  const startOf2024 = dayjs("2024-01");

  const monthsSinceStartOf2024 = dayjs().diff(startOf2024, "month");

  return Array.from({ length: monthsSinceStartOf2024 + 3 }, (_, index) => {
    const baseDate =
      index <= monthsSinceStartOf2024
        ? startOf2024.add(index, "month")
        : dayjs().add(index - monthsSinceStartOf2024, "month");

    return {
      label: baseDate.format("YYYY-MM"),
      value: baseDate.format("YYYY-MM"),
    };
  });
})();

export const typeOptions: Option<FormType>[] = [
  { value: "DEKLARACJA", label: "Deklaracja" },
  { value: "SWIADCZENIE", label: "Świadczenie" },
];

export const declarationGenreOptions: Option<string>[] = [
  { value: "DOCTOR", label: "Lekarska" },
  { value: "NURSE", label: "Pielęgniarska" },
  { value: "MIDWIFE", label: "Położnicza" },
];

export const downloadFile = (filename: string, text: string) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text),
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

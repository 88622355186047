import { Input } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
  ExaminationFieldValues,
  UtilType,
} from "components/ExaminationPanel/types";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";
import { useDoctorPackagesLazyQuery } from "lib/graphql/megaSchema";
import {
  DrugLabel,
  getDrugDescription,
} from "views/Visit/RightPanel/NewPrescription/components/DrugSelect/Label";

export const UtilColumn = ({
  isDrugpass = false,
  header,
  type,
  index,
  util,
  value,
  id,
  disabled,
  error,
}: {
  isDrugpass: boolean;
  header: string;
  type: UtilType;
  index: number;
  util: any;
  value: string;
  id: string;
  disabled: boolean;
  error: any;
}) => {
  const [getPackages] = useDoctorPackagesLazyQuery();

  const inputName = `${type}[${index}].${value}` as any;

  const isDrugField = type === "drug" && value === "name";

  const { control } = useFormContext<ExaminationFieldValues>();

  const loadDrugOptions = async (name: string) => {
    const { data } = await getPackages({ variables: { name } });

    if (!data || data.doctorPackages.length === 0) {
      return [];
    }

    return data.doctorPackages.map((drug) => {
      const { dosage, form, box, name } = drug;
      return {
        value: `${name} ${getDrugDescription(dosage, form, box)}`,
        label: <DrugLabel searchedDrug={drug} />,
        ...drug,
      };
    });
  };

  if (!isDrugField) {
    return (
      <Controller
        name={inputName}
        control={control}
        defaultValue={`${util.name}`}
        render={({ field, fieldState: { error } }) => (
          <Input
            label={header}
            key={id}
            placeholder="Kliknij, aby uzupełnić"
            disabled={disabled}
            {...field}
            error={error?.message}
          />
        )}
      />
    );
  }

  if (index !== 0 || !isDrugpass) {
    return (
      <div className="flex flex-col gap-2">
        <p className="font-heading-4">Nazwa leku</p>
        <Controller
          name={inputName}
          control={control}
          render={({ field: { name, onChange, value, ref, onBlur } }) => (
            <AsyncSelect
              isDisabled={disabled}
              className="font-paragraph-2"
              placeholder="Wyszukaj lek"
              name={name}
              styles={adjustedToInputSelectStyles(error)}
              loadOptions={loadDrugOptions}
              onChange={(drug) => {
                onChange(drug?.value);
              }}
              value={value ? { label: value, value } : null}
              onBlur={onBlur}
              ref={ref}
              loadingMessage={() => "Szukam..."}
              noOptionsMessage={() => "Brak wyników."}
            />
          )}
        />
      </div>
    );
  }

  return (
    <Controller
      name={inputName}
      control={control}
      defaultValue={`${util.name}`}
      render={({ field, fieldState: { error } }) => (
        <Input
          label={header}
          key={id}
          placeholder="Kliknij, aby uzupełnić"
          disabled
          {...field}
          error={error?.message}
        />
      )}
    />
  );
};

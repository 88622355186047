import { Button, ChevronDownIcon, CloseIcon } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { Fragment, useEffect, useRef, useState } from "react";
import { Scrollbars, positionValues } from "react-custom-scrollbars";
import { NewChatInput } from "components/components/NewChatInput";
import { Loader } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSUserGenre,
  DoctorVisitQuery,
  useDoctorGetEmployeesQuery,
} from "lib/graphql/megaSchema";
import {
  getCurrentWorkspace,
  useTileSystem,
} from "lib/tools/createWorkspace/useTileSystem";
import { Maybe } from "lib/types";
import { usePatientData } from "views/Patient/hooks";
import { useVisitData } from "views/Visit/hooks";
import { NewChatMenu } from "./ChatMenu/NewChatMenu";
import { Message } from "./Message/Message";
import { useChat } from "./hooks";

const SCROLL_TO_BOTTOM_BREAKPOINT = 0.8;

const returnUserInfo = (
  firstName: Maybe<string>,
  lastName: Maybe<string>,
  idFromMessage: Maybe<string>,
) => {
  const isUserInfoValid = firstName && lastName;

  if (isUserInfoValid) {
    return `${firstName} ${lastName}`;
  }

  if (idFromMessage) {
    return "Pacjent";
  }

  return "Brak danych o tym, kto";
};

const returnDisabledMessage = (
  currentUserGenre: Maybe<DoctorMSUserGenre>,
  currentVisit?: DoctorVisitQuery["doctorVisit"],
) => {
  if (!currentVisit) {
    if (currentUserGenre === "DOCTOR") {
      return "Lekarz nie może pisać na czacie bez kopii roboczej";
    }

    return null;
  }

  const { startOn, snapshotAt, leftOn } = currentVisit;

  const isVisitBeforeStart = currentUserGenre === "DOCTOR" && !startOn;

  if (isVisitBeforeStart) {
    return `Lekarz nie może pisać na czacie przed rozpoczęciem wizyty!`;
  }

  const isVisitOver = currentUserGenre === "DOCTOR" && (leftOn || snapshotAt);

  if (isVisitOver) {
    return `Lekarz nie może pisać na czacie po zakończeniu kontaktu z pacjentem!`;
  }

  return null;
};

type Props = {
  patientId: string;
};

export const Chat = ({ patientId }: Props) => {
  const {
    chatMessages,
    loading,
    user,
    loadOlder,
    isLoadingOldMessages,
    nextPatient,
    pausePatient,
    isConfirmationToGoToNextPatientOpen,
    setIsConfirmationToGoToNextPatientOpen,
    scrollbar,
    setScrollbar,
    latestMessage,
  } = useChat(patientId);

  const [currentUser] = useAtom(currentUserAtom);
  const { data: currentVisit } = useVisitData({ from: "cache-only" });
  const { data: currentPatient } = usePatientData({ from: "cache-only" });

  const disabledMessage = returnDisabledMessage(
    currentUser?.genre,
    currentVisit,
  );

  const { removeTile } = useTileSystem();
  const currentWorkspace = getCurrentWorkspace();
  const [isScrollToBottomVisible, setIsScrollToBottomVisible] = useState(false);

  const prevScrollbarVaules = useRef<positionValues | null>(null);

  const { data: employeesData } = useDoctorGetEmployeesQuery();

  useEffect(() => {
    const values = scrollbar?.getValues();

    const shouldScrollDown =
      latestMessage?.author?.id === currentUser?.id ||
      (values?.top ?? 0) > SCROLL_TO_BOTTOM_BREAKPOINT;

    if (!shouldScrollDown) {
      return;
    }

    scrollbar?.scrollToBottom();
  }, [latestMessage]);

  useEffect(() => {
    if (!scrollbar) {
      return;
    }

    scrollbar.scrollToBottom();
  }, [scrollbar]);

  useEffect(() => {
    if (isLoadingOldMessages) {
      prevScrollbarVaules.current = scrollbar?.getValues() ?? null;
      return;
    }

    if (!prevScrollbarVaules.current) {
      return;
    }

    const currentValues = scrollbar?.getValues();

    if (!currentValues) {
      return;
    }

    scrollbar?.scrollTop(
      currentValues.scrollTop +
        currentValues.scrollHeight -
        prevScrollbarVaules.current.scrollHeight,
    );
  }, [isLoadingOldMessages, scrollbar]);

  if (loading) return <Loader />;

  const handleScroll = () => {
    const values = scrollbar?.getValues();

    if (!values) {
      return;
    }

    if (values.top === 1) {
      setIsScrollToBottomVisible(false);
    }

    if (values.top < SCROLL_TO_BOTTOM_BREAKPOINT && !isScrollToBottomVisible) {
      setIsScrollToBottomVisible(true);
    }

    if (values.scrollTop > values.clientHeight / 4) {
      return;
    }

    const hadMore = loadOlder();

    if (!hadMore) {
      return;
    }

    scrollbar?.scrollToTop();
  };

  return (
    <>
      <div className="flex h-full flex-col rounded-lg bg-white">
        {currentWorkspace === "patient-profile" && (
          <div className="font-heading-4 flex w-full justify-between p-4">
            <div>
              Czat z {currentPatient?.firstName} {currentPatient?.lastName}
            </div>

            <div>
              <Button
                icon={<CloseIcon />}
                label="close chat tile"
                withTooltip={false}
                variant="ghost"
                size="condensed"
                onClick={() => {
                  removeTile("chat");
                }}
              />
            </div>
          </div>
        )}

        <Scrollbars
          className="w-full rounded-lg bg-white"
          autoHide
          onScroll={handleScroll}
          ref={(s) => {
            setScrollbar(s);
          }}
        >
          <div className="relative flex h-full flex-col justify-between">
            <div className="px-3">
              {isLoadingOldMessages && (
                <div className="flex items-center justify-center">
                  <Loader />
                </div>
              )}

              {chatMessages.map((message, index) => {
                const newDateChecker =
                  index > 0
                    ? dayjs(message.createdAt)
                        .startOf("day")
                        .isSame(
                          dayjs(chatMessages[index - 1].createdAt).startOf(
                            "day",
                          ),
                        )
                    : false;

                const isSpecialMessage = message.content[0] === "[";

                const specialContents = isSpecialMessage
                  ? message.content.replace(/\[|\]/g, "").split(",")
                  : [];

                if (specialContents.length > 0) {
                  const userData = specialContents
                    .map((e) => {
                      const [key, value] = e.split(":");

                      if (
                        !["doctorId", "createdBy", "snapshotBy"].includes(key)
                      )
                        return null;

                      const findUserData = employeesData?.doctorDoctors.find(
                        (e) => e.id === value,
                      );

                      const statusMap: Record<string, string> = {
                        doctorId: "udziela porady",
                        createdBy: "tworzy wizytę",
                        snapshotBy: "zamyka wizytę",
                      };

                      return {
                        ...findUserData,
                        status: statusMap[key],
                        id: value,
                      };
                    })
                    .filter((e) => e)[0];

                  if (userData) {
                    const { firstName, lastName, status, id } = userData;

                    const userInfo = returnUserInfo(firstName, lastName, id);

                    return (
                      <div
                        key={message.id}
                        className="mb-10 mt-10 flex justify-center"
                      >
                        <hr className="flex w-full flex-col gap-4 text-jutro-new-warm-gray-300" />
                        <span className="m font-geologica font-paragraph-2 absolute -mt-2.5 bg-white px-4 text-center text-jutro-new-warm-gray-800">
                          {`${userInfo} ${status}`}
                        </span>
                        <span className="font-geologica font-paragraph-2 absolute mt-2 text-jutro-new-warm-gray-800">
                          {dayjs(message.createdAt).format(
                            "DD MMMM YYYY HH:mm",
                          )}
                        </span>
                      </div>
                    );
                  }
                }

                return (
                  <Fragment key={message.id}>
                    {!newDateChecker && (
                      <div className="my-7 flex justify-center">
                        <hr className="w-full text-jutro-new-warm-gray-300" />
                        <span className="font-geologica font-paragraph-2 absolute -mt-2.5 bg-white px-4 text-center text-jutro-new-warm-gray-800">
                          {dayjs(message.createdAt).isToday()
                            ? "Dzisiaj"
                            : dayjs(message.createdAt).isYesterday()
                              ? "Wczoraj"
                              : dayjs(message.createdAt).format("DD MMMM YYYY")}
                        </span>
                      </div>
                    )}
                    <Message message={message} />
                  </Fragment>
                );
              })}
            </div>
            <div
              className={`sticky bottom-0 left-0 my-4 w-full transition-all duration-500 ease-in-out ${
                isScrollToBottomVisible
                  ? "opacity-100"
                  : "pointer-events-none opacity-0"
              }`}
            >
              <div className="flex w-full justify-center">
                <div className="absolute bottom-10">
                  <Button
                    full={false}
                    icon={<ChevronDownIcon />}
                    variant="secondary"
                    label="Przewiń do dołu"
                    tooltipPosition="top"
                    onClick={() => {
                      scrollbar?.scrollToBottom();
                      setIsScrollToBottomVisible(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
        <div className="flex w-full flex-col gap-4 p-4">
          <NewChatInput
            patientId={patientId}
            disabledMessage={disabledMessage}
          />
          <NewChatMenu
            patientId={patientId}
            nextPatient={nextPatient}
            user={user}
          />
        </div>
      </div>

      <ConfirmDialog
        open={isConfirmationToGoToNextPatientOpen}
        setOpen={setIsConfirmationToGoToNextPatientOpen}
        onConfirm={() => {
          pausePatient();
          nextPatient();
        }}
        title="Czy na pewno chcesz przejść do następnego? Obecna wizyta ma priorytet."
        text=""
        confirmText="Chcę"
      />
    </>
  );
};

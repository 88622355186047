import { CalendarClockIcon, CloseIcon } from "@jutro/ui";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import { getDayFromDatePickervalue } from "lib/tools/getDayFromDatePickervalue";
import "./styles.css";

export type DatePickerWrapperProps = {
  big: boolean;
  errors?: boolean | string;
};

export type Value = Date | number | string | null;

export type CustomDatePickerProps = {
  value?: Value;
  onChange?: (v: string | null) => void;
  onBlur?: (v: Value) => void;
  minDate?: Value;
  maxDate?: Value;
  big?: boolean;
  disabled?: boolean;
  errors?: boolean | string;
  clearIcon?: boolean;
  format?: string;
  locale?: string;
};

export const CustomDatePicker = ({
  value = null,
  onChange = () => {},
  onBlur = () => {},
  errors,
  minDate = null,
  maxDate = null,
  big = false,
  clearIcon,
  disabled = false,
  format = "yyyy-MM-dd",
  locale,
}: CustomDatePickerProps) => (
  <div
    className={`custom-date-picker-wrapper ${
      big && "custom-date-picker-wrapper-big"
    } ${errors && "custom-date-picker-wrapper-errors"}`}
  >
    <DatePicker
      format={format}
      locale={locale}
      disabled={disabled}
      onChange={(value) => {
        if (!value) {
          onChange(null);
          onBlur(value);
        } else {
          onChange(
            dayjs(getDayFromDatePickervalue(value)).format("YYYY-MM-DD"),
          );
        }
      }}
      value={value ? new Date(value) : null}
      calendarIcon={<CalendarClockIcon size="sm" />}
      clearIcon={
        clearIcon ? (
          <div className="text-jutro-new-rose-500">
            <CloseIcon size="sm" />
          </div>
        ) : null
      }
      dayPlaceholder="DD"
      monthPlaceholder="MM"
      yearPlaceholder="RRRR"
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
    />
  </div>
);

import { Option } from "@jutro/types";
import { DoctorMSSlotType } from "lib/graphql/megaSchema";

export type SlotDuration = 10 | 12 | 15 | 20 | 30;

export type DurationOption = Option<SlotDuration>;

export const slotTypeMap: Record<DoctorMSSlotType, string> = {
  COLLECTION: "Wizyta w punkcie pobrań",
  PERSONAL: "Osobista",
  PROCEDURES: "Zabiegowa",
  TELEMEDICAL: "Telemedyczna na NFZ",
  TELEMEDICAL_PRIVATE: "Telemedyczna prywatnie",
  TELEMEDICAL_NEW_CITIES: "Prywatna poza województwem pacjenta",
  INFECTIONS: "Infekcyjna",
  NURSE_VISIT: "Infekcyjna pielęgniarska",
  INFECTIONS_PRIVATE: "Infekcyjna prywatna",
  INFECTIONS_PUBLIC: "Infekcyjna publiczna",
  SPECIALIST: "Specjalistyczna",
  USG: "Badanie USG",
  MIDWIFE: "Położnicza",
  EXPRESS: "Ekspresowa",
  DIETARY: "Dietetyczna",
  HOME: "Domowa",
  SPECIALIST_DOCTOR: "Specjalistyczna w opiece koordynowanej",
};

export const slotTypeOptions = Object.entries(slotTypeMap).map(
  ([value, label]) =>
    ({
      label,
      value,
    }) as Option<DoctorMSSlotType>,
);

export const telemedicalSlotsTypesWithVojevodship: DoctorMSSlotType[] = [
  "TELEMEDICAL",
  "TELEMEDICAL_NEW_CITIES",
  "EXPRESS",
];

export const telemedicalSlotTypes: DoctorMSSlotType[] = [
  ...telemedicalSlotsTypesWithVojevodship,
  "TELEMEDICAL_PRIVATE",
  "INFECTIONS",
];

export const durationOptions: DurationOption[] = [
  {
    label: "10 minut",
    value: 10,
  },
  {
    label: "12 minut",
    value: 12,
  },
  {
    label: "15 minut",
    value: 15,
  },
  { label: "20 minut", value: 20 },
  {
    label: "30 minut",
    value: 30,
  },
];

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CalendarClockIcon, Input } from "@jutro/ui";
import dayjs from "dayjs";
import { envVars } from "envvars";
import DateTimePicker from "react-datetime-picker";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Checkbox } from "components/new";
import { isProductionEnvironment } from "lib/tools";
import { httpConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import {
  SlotsCreatorSchemaType,
  getCustomSlotsCreatorSelectStyles,
  organizationIdOptions,
  slotTypeOptions,
  slotsCreatorSchema,
  staffListOptions,
} from "views/SlotsCreator/tools";

export const SlotsCreatorForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SlotsCreatorSchemaType>({
    mode: "onChange",
    resolver: zodResolver(slotsCreatorSchema),
  });

  if (isProductionEnvironment()) {
    return null;
  }

  const sendCreateSlotsRequest = async (data: SlotsCreatorSchemaType) => {
    const url = new URL(
      `${httpConfig[envVars.REACT_APP_CONFIG]}/staging/addTimeEntry`,
    );

    url.searchParams.set("organizationId", data.organizationId);
    url.searchParams.set("doctorId", data.staffId);
    url.searchParams.set("start", data.dateFrom);
    url.searchParams.set("end", data.dateTo);
    url.searchParams.set("duration", data.duration);
    url.searchParams.set("slotType", data.slotType);
    url.searchParams.set(
      "childrenOnly",
      data.childrenOnly === true ? "true" : "false",
    );

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    reset();
    return response;
  };

  const submitCreateSlotsForm = handleSubmit((data) => {
    toaster.promise(sendCreateSlotsRequest(data), {
      loading: "Tworzę sloty",
      success: "Stworzono sloty",
      error: "Nie udało się stworzyć slotów",
    });
  });

  return (
    <div className="flex w-96 flex-col gap-6 rounded-lg border-[2px] border-jutro-new-blue-800 p-4 shadow-lg">
      <form className="flex flex-col gap-2" onSubmit={submitCreateSlotsForm}>
        <Controller
          control={control}
          name="organizationId"
          render={({ field: { onChange, value, ...field } }) => (
            <Select
              placeholder="Wybierz lokalizację"
              options={organizationIdOptions}
              value={
                value
                  ? organizationIdOptions.find((o) => o.value === value)
                  : null
              }
              onChange={(option) => {
                if (!option) {
                  return;
                }
                onChange(option.value);
              }}
              styles={getCustomSlotsCreatorSelectStyles(
                "organizationId",
                errors,
              )}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="slotType"
          render={({ field: { onChange, value, ...field } }) => (
            <Select
              placeholder="Wybierz typ slotu"
              options={slotTypeOptions}
              value={
                value ? slotTypeOptions.find((o) => o.value === value) : null
              }
              onChange={(option) => {
                if (!option) {
                  return;
                }
                onChange(option.value);
              }}
              styles={getCustomSlotsCreatorSelectStyles("slotType", errors)}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="staffId"
          render={({ field: { onChange, value, ...field } }) => (
            <Select
              placeholder="Wybierz osobę"
              options={staffListOptions}
              value={
                value ? staffListOptions.find((o) => o.value === value) : null
              }
              onChange={(option) => {
                if (!option) {
                  return;
                }
                onChange(option.value);
              }}
              styles={getCustomSlotsCreatorSelectStyles("staffId", errors)}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="dateFrom"
          render={({ field: { onChange, value } }) => (
            <DateTimePicker
              format="yyyy-MM-dd HH:mm"
              value={value}
              minDate={dayjs().toDate()}
              maxDate={undefined}
              disableClock
              calendarIcon={<CalendarClockIcon size="sm" />}
              clearIcon={null}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="RRRR"
              hourPlaceholder="GG"
              minutePlaceholder="MM"
              amPmAriaLabel="none"
              onChange={(option) => {
                if (!option) {
                  return;
                }
                onChange(option.toISOString());
              }}
              className={`declaration-date-time-picker h-[38px] w-full rounded-[4px] border-[1px] border-jutro-new-warm-gray-300 py-[6px] ${
                errors.dateFrom
                  ? "border-red-500 outline-none"
                  : "focus:outline-jutro-new-blue-500"
              }`}
            />
          )}
        />

        <Controller
          control={control}
          name="dateTo"
          render={({ field: { onChange, value } }) => (
            <DateTimePicker
              format="yyyy-MM-dd HH:mm"
              value={value}
              minDate={dayjs().toDate()}
              maxDate={undefined}
              disableClock
              calendarIcon={<CalendarClockIcon size="sm" />}
              clearIcon={null}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="RRRR"
              hourPlaceholder="GG"
              minutePlaceholder="MM"
              onChange={(option) => {
                if (!option) {
                  return;
                }
                onChange(option.toISOString());
              }}
              className={`declaration-date-time-picker h-[38px] w-full rounded-[4px] border-[1px] border-jutro-new-warm-gray-300 py-[6px] ${
                errors.dateTo
                  ? "border-red-500 outline-none"
                  : "focus:outline-jutro-new-blue-500"
              }`}
            />
          )}
        />

        <Controller
          control={control}
          name="duration"
          render={({ field: { onChange, value, ...field } }) => (
            <Input
              type="number"
              placeholder="Długość slotu w minutach"
              value={value ? value : ""}
              min={1}
              max={60}
              onChange={onChange}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="childrenOnly"
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <Checkbox value={value} onChange={onChange} label="Pediatria" />
          )}
        />

        <Button size="condensed" type="submit" text="Stwórz sloty" />
      </form>
    </div>
  );
};

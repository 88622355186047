import { Tag, Tooltip } from "@jutro/ui";
import { useState } from "react";
import { ImmunizationCardStatusModal } from "components/PatientDocs/Overview/ImmunizationCardStatus/ImmunizationCardStatusModal";
import { DoctorMSFullAddress } from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { Maybe } from "lib/types";

type Props = {
  immunizationCardStatus: string;
  immunizationCardStatusComment?: string | null;
  declaredIn: Maybe<string>;
  patientAddress?: DoctorMSFullAddress | null;
  patientAge: number;
  patientId: string;
};

export const ImmunizationCardStatus = ({
  declaredIn,
  immunizationCardStatus,
  immunizationCardStatusComment,
  patientAddress,
  patientAge,
  patientId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { organizationsClinicsOptions } = useVojevodshipOptions();

  const immunizationCardLocation = organizationsClinicsOptions.find(
    (o) => o.value === immunizationCardStatus,
  );

  const handleStatusChange = () => {
    setIsModalOpen(true);
  };

  if (declaredIn === "Brak") {
    if (
      immunizationCardStatus === "SYSTEM" ||
      immunizationCardStatus === "OUTSIDE_SYSTEM"
    ) {
      return null;
    }

    if (immunizationCardStatus.startsWith("P")) {
      return (
        <>
          <Tooltip
            position="bottom"
            content={immunizationCardLocation?.label ?? ""}
            triggerAsChild={false}
            trigger={
              <Tag
                text="KARTA UODPORNIENIA: KARTA DO ODESŁANIA"
                color="rose"
                onClick={handleStatusChange}
              />
            }
          />

          <ImmunizationCardStatusModal
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            patientId={patientId}
            patientAddress={patientAddress}
            immunizationCardStatus={immunizationCardStatus}
            immunizationCardStatusComment={immunizationCardStatusComment}
            organizationsClinicsOptions={organizationsClinicsOptions}
          />
        </>
      );
    }
  }

  if (declaredIn && patientAge >= 18) {
    return null;
  }

  if (immunizationCardStatus === "SYSTEM") {
    return (
      <>
        <Tooltip
          position="bottom"
          content="Kliknij, aby zmienić status"
          triggerAsChild={false}
          trigger={
            <Tag
              text="KARTA UODPORNIENIA: CZEKAMY NA KARTĘ"
              color="rose"
              onClick={handleStatusChange}
            />
          }
        />

        <ImmunizationCardStatusModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          patientId={patientId}
          patientAddress={patientAddress}
          immunizationCardStatus={immunizationCardStatus}
          immunizationCardStatusComment={immunizationCardStatusComment}
          organizationsClinicsOptions={organizationsClinicsOptions}
        />
      </>
    );
  }

  if (immunizationCardStatus.startsWith("P")) {
    return (
      <>
        <Tooltip
          position="bottom"
          content={immunizationCardLocation?.label ?? ""}
          triggerAsChild={false}
          trigger={
            <Tag
              text="KARTA UODPORNIENIA: NA STANIE"
              color="green"
              onClick={handleStatusChange}
            />
          }
        />

        <ImmunizationCardStatusModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          patientId={patientId}
          patientAddress={patientAddress}
          immunizationCardStatus={immunizationCardStatus}
          immunizationCardStatusComment={immunizationCardStatusComment}
          organizationsClinicsOptions={organizationsClinicsOptions}
        />
      </>
    );
  }

  if (immunizationCardStatus === "OUTSIDE_SYSTEM") {
    return (
      <>
        <Tooltip
          position="bottom"
          content={immunizationCardStatusComment ?? ""}
          triggerAsChild={false}
          trigger={
            <Tag
              text="KARTA UODPORNIENIA: POZA POZ"
              color="gray"
              onClick={handleStatusChange}
            />
          }
        />

        <ImmunizationCardStatusModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          patientId={patientId}
          patientAddress={patientAddress}
          immunizationCardStatus={immunizationCardStatus}
          immunizationCardStatusComment={immunizationCardStatusComment}
          organizationsClinicsOptions={organizationsClinicsOptions}
        />
      </>
    );
  }
};

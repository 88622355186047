import { useAtom } from "jotai";
import { currentLocaleAtom, locales } from "lib/atoms";

export const CurrentLocaleSelect = () => {
  const [currentLocale, setCurrentLocale] = useAtom(currentLocaleAtom);

  return (
    <div className="fixed right-2 top-2 z-50">
      <div className="flex gap-1">
        {locales.map((locale) => (
          <div
            key={locale}
            className={`w-6 text-center font-bold ${
              locale === currentLocale
                ? "cursor-not-allowed bg-jutro-new-blue-800 text-white"
                : "cursor-pointer bg-white hover:opacity-50"
            }`}
            onClick={() => {
              if (locale === currentLocale) return;
              setCurrentLocale(locale);
            }}
          >
            {locale}
          </div>
        ))}
      </div>
    </div>
  );
};

import dayjs from "dayjs";
import { ScheduleFormSchema } from "components/ScheduleVisitForm/tools/form";
import { ScheduleVisitTileData } from "components/ScheduleVisitForm/types";
import {
  DoctorDraftVisitsDocument,
  DoctorGetPatientDocument,
  DoctorMSSlotType,
  DoctorPatientProfileVisitDocument,
  DoctorUpcomingVisitsDocument,
  Maybe,
  useDoctorCreateVisitSlotMutation,
  useDoctorForceCreateVisitMutation,
  useDoctorForceEditVisitMutation,
  useDoctorUpdateVisitSlotMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";

type Props = {
  scheduleVisitTileData: ScheduleVisitTileData;
};

export const useScheduleVisitMutations = ({ scheduleVisitTileData }: Props) => {
  const { mode, patientId, visitData } = scheduleVisitTileData;

  const [createVisitSlotMutation, { loading: createVisitLoading }] =
    useDoctorCreateVisitSlotMutation({
      refetchQueries: [
        DoctorPatientProfileVisitDocument,
        DoctorGetPatientDocument,
        DoctorDraftVisitsDocument,
      ],
    });

  const [forceCreateVisitMutation, { loading: forceCreateVisitLoading }] =
    useDoctorForceCreateVisitMutation();

  const [forceEditVisitMutation, { loading: forceEditVisitLoading }] =
    useDoctorForceEditVisitMutation();

  const [updateVisitSlotMutation, { loading: updateVisitLoading }] =
    useDoctorUpdateVisitSlotMutation({
      refetchQueries: [
        DoctorPatientProfileVisitDocument,
        DoctorGetPatientDocument,
        DoctorDraftVisitsDocument,
      ],
    });

  const createVisit = async (slotId: Maybe<number>, patientId: string) => {
    if (!slotId) {
      return;
    }

    const createVisitPromise = createVisitSlotMutation({
      variables: { slotIds: [slotId], patientId },
      refetchQueries: [DoctorUpcomingVisitsDocument],
      awaitRefetchQueries: true,
    });

    await toaster.promise(createVisitPromise, {
      success: "Poprawnie utworzono wizytę!",
      loading: "Tworzę wizytę",
      error: "Nie udało się utworzyć wizyty",
    });
  };

  const forceCreateVisit = async (
    formData: ScheduleFormSchema,
    patientId: string,
  ) => {
    const {
      plannedDate,
      plannedTime,
      doctorId,
      slotDuration,
      slotType,
      organizationId,
      officeId,
    } = formData;

    if (
      !doctorId ||
      !plannedDate ||
      !plannedTime ||
      !slotDuration ||
      !slotType
    ) {
      return;
    }

    const forceCreateVisitPromise = forceCreateVisitMutation({
      variables: {
        patientId,
        input: {
          doctorId,
          plannedStart: dayjs(`${plannedDate} ${plannedTime}`).toISOString(),
          duration: slotDuration,
          slotType: slotType as DoctorMSSlotType,
          organizationId: officeId ?? organizationId ?? "P",
        },
      },
      refetchQueries: [DoctorUpcomingVisitsDocument],
      awaitRefetchQueries: true,
    });

    await toaster.promise(forceCreateVisitPromise, {
      success: "Poprawnie wymuszono wizytę!",
      loading: "Wymuszam wizytę",
      error: "Nie udało się wymusić wizyty",
    });
  };

  const editVisit = async (
    slotId: Maybe<number>,
    visitId: string | undefined,
  ) => {
    if (!visitId || !slotId) {
      return;
    }

    const updateVisitPromise = updateVisitSlotMutation({
      variables: { id: visitId, slotIds: [slotId] },
      refetchQueries: [DoctorUpcomingVisitsDocument],
      awaitRefetchQueries: true,
    });

    await toaster.promise(updateVisitPromise, {
      success: "Poprawnie edytowano wizytę!",
      loading: "Edytuję wizytę",
      error: (err) => {
        if (err.message === "Cannot modify visit two hours before it.") {
          return "Nie można edytować wizyty dwie godziny przed planowanym rozpoczęciem";
        }

        return "Nie udało się edytować wizyty";
      },
    });
  };

  const forceEditVisit = async (
    formData: ScheduleFormSchema,
    visitId: string | undefined,
  ) => {
    const {
      plannedDate,
      plannedTime,
      doctorId,
      slotDuration,
      slotType,
      organizationId,
      officeId,
    } = formData;

    if (
      !doctorId ||
      !plannedDate ||
      !plannedTime ||
      !slotDuration ||
      !slotType ||
      !visitId
    ) {
      return;
    }

    const forceEditVisitPromise = forceEditVisitMutation({
      variables: {
        id: visitId,
        input: {
          doctorId,
          plannedStart: dayjs(`${plannedDate} ${plannedTime}`).toISOString(),
          duration: slotDuration,
          slotType: slotType as DoctorMSSlotType,
          organizationId: officeId ?? organizationId ?? "P",
        },
      },
      refetchQueries: [DoctorUpcomingVisitsDocument],
      awaitRefetchQueries: true,
    });

    await toaster.promise(forceEditVisitPromise, {
      success: "Poprawnie wymuszono edycję wizyty!",
      error: "Nie udało się wymusić edycji wizyty",
      loading: "Wymuszam edycję wizyty",
    });
  };

  const handleConfirm = async (formData: ScheduleFormSchema) => {
    const { chosenSlotId } = formData;

    if (mode === "create") {
      await createVisit(chosenSlotId, patientId);
      return;
    }

    if (mode === "forceCreate") {
      await forceCreateVisit(formData, patientId);
      return;
    }

    if (mode === "edit") {
      await editVisit(chosenSlotId, visitData?.id);
      return;
    }

    await forceEditVisit(formData, visitData?.id);
  };

  const loading =
    forceCreateVisitLoading ||
    forceEditVisitLoading ||
    createVisitLoading ||
    updateVisitLoading;

  return {
    handleConfirm,
    loading,
  };
};

import { unique } from "radash";
import { IpomFormSchema } from "components/IpomForm/schema";
import {
  DoctorGetIpomCodesQuery,
  DoctorMSObservationIpom,
  DoctorVisitQuery,
} from "lib/graphql/megaSchema";

type VisitDiagnosis = DoctorVisitQuery["doctorVisit"]["doctorDiagnosis"];

const transformDoctorDiagnosis = (
  visitDiagnosis: VisitDiagnosis,
): IpomFormSchema["observations"] =>
  (visitDiagnosis ?? []).map((diagnosis) => {
    const [code, name] = diagnosis!.code!.split(/(?<=^\S+)\s/);

    return {
      value: code,
      name,
      active: true,
    };
  });

export const filterUniqueDiagnoses = (
  ipomCodesData: DoctorGetIpomCodesQuery,
  existingDiagnoses: IpomFormSchema["observations"],
  visitDiagnosis: VisitDiagnosis,
  existingIpomDiagnosis: DoctorMSObservationIpom[],
) => {
  const transformedVisitDiagnosis = transformDoctorDiagnosis(visitDiagnosis);

  const existingIpomDiagnosisValues = existingIpomDiagnosis.map((d) => ({
    ...d,
    active: true,
  }));

  return unique(
    [
      ...existingDiagnoses,
      ...existingIpomDiagnosisValues,
      ...transformedVisitDiagnosis,
    ],
    ({ value }) => value,
  )
    .filter(({ value }) =>
      ipomCodesData.doctorGetIpomCodes.some((code) => value.startsWith(code)),
    )
    .filter(
      ({ value }) =>
        existingIpomDiagnosisValues.some((d) => d.value === value) ||
        transformedVisitDiagnosis.some((d) => d.value === value),
    );
};

import { DiamondDashIcon } from "@jutro/ui";
import { DoctorMSUnknownEvent } from "lib/graphql/megaSchema";
import { Maybe } from "lib/types";
import { NfzEvent, NfzEventPrefix } from "../NfzEvent";
import { EventHandler } from "../NfzEvent/infer";

export const UnknownEventHandler: EventHandler<DoctorMSUnknownEvent> = ({
  event,
}) => {
  const { type, data, error } = event;
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.UNKNOWN}
      statusText="Błąd"
      icon={<DiamondDashIcon size="sm" />}
      color="rose"
      tooltip={<TooltipConent data={data} type={type} error={error} />}
    />
  );
};

type TooltipConentProps = {
  type: string;
  data: any;
  error: Maybe<string>;
};

const TooltipConent = ({ type, data, error }: TooltipConentProps) => {
  return (
    <div className="z-50 flex flex-col gap-1">
      <span>{`Wystąpił błąd (skontaktuj się z działem technicznym): ${error}`}</span>
      <span>{type}</span>
      <span className="break-words">{JSON.stringify(data)}</span>
    </div>
  );
};

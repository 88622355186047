export const assignmentTypesMap: Record<string, string> = {
  SPIRO: "Spirometrię",
  EKG: "EKG",
  MEDICINE: "Iniekcję",
  DRUGPASS: "Podanie leku",
  ANALYSIS: "Badanie laboratoryjne",
  VACCINE: "Szczepienie",
  RTG: "RTG",
  USG: "USG",
  BANDAGE: "Zmianę opatrunku",
  STITCHES: "Szwy",
  OBJECT: "Ciało obce",
  CT: "CT",
};

export const vaccinePairs: Record<string, string> = {
  noBariers:
    "Stwierdzono brak przeciwskazań do przeprowadzenia obowiązkowego szczepienia ochronnego w okresie do 24 godzin od przeprowadzenia badania kwalifikacyjnego.",
  moreTime:
    "Stwierdzono przeciwwskazania do przeprowadzenia obowiązkowego szczepienia ochronnego dające podstawy do odroczenia wykonania szczepienia.",
  specialist:
    "Stwierdzono przeciwwskazania do przeprowadzenia obowiązkowego szczepienia ochronnego, dające podstawy do długotrwałego odroczenia wykonania szczepienia oraz skierowano na konsultację specjalistyczną do poradni.",
};

export const recommendationTitleMap: Record<string, string> = {
  covid: "Podejrzenie COVID-19",
  "ikp-share-prescriptions-and-referrals":
    "Instrukcja udostępniania medycznej dokumentacji — recepty i skierowania",
  "ikp-share-prev-clinic":
    "Instrukcja udostępniania medycznej dokumentacji — poprzednia przychodnia",
};

export const referralTypesMap = [
  {
    name: "Skierowanie na zabiegi fizjoterapeutyczne",
    key: "basic-rehabilitation",
  },
  {
    name: "Skierowanie na zabiegi fizjoterapeutyczne (domowe)",
    key: "home-rehabilitation",
  },
  { name: "Skierowanie do poradni specjalistycznej", key: "specialist-clinic" },
  { name: "Skierowanie do pracowni diagnostycznej", key: "diagnostic-clinic" },
  { name: "Skierowanie do szpitala", key: "hospital" },
  {
    name: "Skierowanie do szpitala psychiatrycznego",
    key: "psychiatric-hospital",
  },
];

import { Button } from "@jutro/ui";
import { Dispatch, SetStateAction } from "react";

type Props = {
  isFirstPage: boolean;
  isLastPage: boolean;
  isOnePageFile: boolean;
  isModal: boolean;
  addFileText: string;
  file: string;
  setPageNumber: Dispatch<SetStateAction<number>>;
  handleClose?: () => void;
  handleAbort?: () => void;
};

export const PdfPreviewActionButtons = ({
  isFirstPage,
  isLastPage,
  isOnePageFile,
  isModal,
  addFileText,
  file,
  setPageNumber,
  handleAbort,
  handleClose,
}: Props) => {
  return (
    <>
      {!isOnePageFile && (
        <div className="flex gap-4">
          <Button
            full={false}
            disabled={isFirstPage}
            variant="secondary"
            size="condensed"
            onClick={() => setPageNumber((prev) => prev - 1)}
            text="Poprzednia strona"
          />

          <Button
            full={false}
            disabled={isLastPage}
            variant="secondary"
            size="condensed"
            onClick={() => setPageNumber((prev) => prev + 1)}
            text="Następna strona"
          />
        </div>
      )}

      {isModal ? (
        <div className="flex gap-2">
          <Button
            full={false}
            size="condensed"
            onClick={handleAbort}
            variant="ghost"
            text="Anuluj"
          />

          <Button
            full={false}
            size="condensed"
            onClick={handleClose}
            text={addFileText}
          />
        </div>
      ) : (
        <div>
          <a href={file}>
            <Button full={false} size="condensed" text="Pobierz plik" />
          </a>
        </div>
      )}
    </>
  );
};

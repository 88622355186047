import { BinIcon, BinUndoIcon, Button } from "@jutro/ui";
import { UseFormHandleSubmit } from "react-hook-form";
import {
  CreateShiftFormSchemaType,
  SlotActionModalMode,
} from "views/StaffScheduleCreator/utils";

type Props = {
  modalMode: SlotActionModalMode;
  isDeletable: boolean;
  loading: boolean;
  onCloseModal: () => void;
  handleSubmit: UseFormHandleSubmit<CreateShiftFormSchemaType>;
  onSubmit: (
    formData: CreateShiftFormSchemaType,
    mode: SlotActionModalMode,
  ) => Promise<void>;
};

export const ButtonsController = ({
  isDeletable,
  loading,
  modalMode,
  onCloseModal,
  handleSubmit,
  onSubmit,
}: Props) => {
  const handleCloseModal = () => {
    onCloseModal();
  };
  if (isDeletable) {
    return (
      <div className="flex flex-1 justify-end gap-2">
        <Button
          text="Anuluj"
          variant="ghost"
          type="button"
          full={false}
          onClick={handleCloseModal}
        />
        <Button
          text="Przywróć"
          icon={<BinUndoIcon />}
          loading={loading}
          full={false}
          onClick={handleSubmit((data) => onSubmit(data, "cancelDelete"))}
        />
      </div>
    );
  }

  if (modalMode === "edit") {
    return (
      <div className="flex flex-1 justify-between">
        <Button
          text="Usuń"
          variant="negative"
          icon={<BinIcon />}
          full={false}
          onClick={handleSubmit((data) => onSubmit(data, "delete"))}
        />

        <Button text="Anuluj" full={false} onClick={handleCloseModal} />
      </div>
    );
  }

  return (
    <div className="flex flex-1 justify-end gap-2">
      <Button
        text="Anuluj"
        variant="ghost"
        full={false}
        onClick={handleCloseModal}
      />
      <Button
        text="Utwórz"
        full={false}
        onClick={handleSubmit((data) => onSubmit(data, "create"))}
      />
    </div>
  );
};

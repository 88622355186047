import { PropsWithChildren } from "react";
import { HandleUser } from "./components/HandleUser";
import { Layout } from "./components/Layout";
import { Providers } from "./components/Providers";

export const RouteWrapper = ({ children }: PropsWithChildren<unknown>) => (
  <Providers>
    <HandleUser>
      <Layout>{children}</Layout>
    </HandleUser>
  </Providers>
);

import { useAtom } from "jotai";
import { Locale, currentLocaleAtom } from "lib/atoms";
import en from "lib/locales/en.json";
import pl from "lib/locales/pl.json";
import ua from "lib/locales/ua.json";

type LocaleKey = keyof typeof pl;

const localeMap: Record<Locale, Record<LocaleKey, string>> = {
  en,
  pl,
  ua,
};

export const useTranslation = () => {
  const [currentLocale] = useAtom(currentLocaleAtom);

  return {
    currentLocale,
    t: (key: LocaleKey) => localeMap[currentLocale][key],
  };
};

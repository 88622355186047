import { Button } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSUserGenre,
  DoctorNextPatientsDocument,
  DoctorPatientsDocument,
  DoctorVisitDocument,
  useDoctorClosePatientMutation,
  useDoctorUnpinAllPatientsMutation,
  useDoctorUnpinPatientMutation,
} from "lib/graphql/megaSchema";
import { Patient, useNextPatients } from "lib/hooks/useNextPatients";
import { lastMessageMap } from "lib/tools/lastMessageMap";
import { toaster } from "lib/tools/toaster";
import { Maybe } from "lib/types";

export const ActiveVisitsTab = () => {
  const [isUnpinning, setIsUnpinning] = useState<Maybe<string>>(null);
  const [currentUser] = useAtom(currentUserAtom);
  const [unpinPatientMutation] = useDoctorUnpinPatientMutation({
    refetchQueries: [
      DoctorVisitDocument,
      DoctorPatientsDocument,
      DoctorNextPatientsDocument,
    ],
    awaitRefetchQueries: true,
  });
  const { patients, refetchPatients } = useNextPatients();

  const [unpinAllPatientsMutation] = useDoctorUnpinAllPatientsMutation({
    refetchQueries: [
      DoctorVisitDocument,
      DoctorPatientsDocument,
      DoctorNextPatientsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const enhancedUnpin = async (patientId: string | "all") => {
    if (isUnpinning) {
      return;
    }

    setIsUnpinning(patientId);

    if (patientId === "all") {
      await unpinAllPatientsMutation();
    } else {
      await unpinPatientMutation({ variables: { id: patientId } });
    }

    await refetchPatients();

    setIsUnpinning(null);
  };

  const patientTabTitleMap: Record<DoctorMSUserGenre, string> = {
    DOCTOR: `Moi pacjenci (${patients.mineAwaitingResponse}/${patients.mine.length})`,
    ASSISTANT: `Moi pacjenci (${patients.mineAwaitingResponse}/${patients.mine.length})`,
    get ["NURSE"]() {
      return this["ASSISTANT"];
    },
    STAFF: "",
    PATIENT: "",
    MODERATOR: "",
    CLINIC_MANAGER: "",
  };

  return (
    <div className="mb-6 flex flex-col gap-y-4">
      <PatientList
        title={currentUser?.genre ? patientTabTitleMap[currentUser.genre] : ""}
        patientList={patients.mine}
        unpin={enhancedUnpin}
        isFunctionButtonsEnabled
      />

      <PatientList
        title={`Lista oczekujących (${patients.others.length})`}
        patientList={patients.others}
      />
    </div>
  );
};

type patientListProps = {
  title: string;
  patientList: Patient[];
  unpin?: (patientId: string | "all") => void;
  isFunctionButtonsEnabled?: boolean;
};

const PatientList = ({
  title,
  patientList,
  unpin,
  isFunctionButtonsEnabled,
}: patientListProps) => {
  const navigate = useNavigate();

  const [closePatientMutation] = useDoctorClosePatientMutation({
    refetchQueries: [DoctorNextPatientsDocument],
  });

  const closePatient = (patientId: string) => {
    closePatientMutation({ variables: { id: patientId } })
      .then(() => {
        toaster.success("Zamknięto pacjenta!");
      })
      .catch(() => {
        toaster.success("Coś poszło nie tak!");
      });
  };

  return (
    <div>
      <div className="font-paragraph-2 flex w-full flex-col gap-y-4 rounded-lg bg-white p-4 text-jutro-new-warm-gray-800">
        <div className="flex items-center gap-x-2">
          <span>{title}</span>
        </div>
        <div className="w-full flex-col rounded-lg border p-2">
          <div className="flex w-full items-center gap-x-8 p-2">
            <div className="flex items-center gap-x-8">
              <div className="w-8">LP</div>
              <div className="w-64">Imię i nazwisko</div>
              <div className="w-20">ID</div>
              <div className="w-24">Czas oczekiwania</div>
            </div>
            <div className="w-full">Ostatnia wiadomość</div>
          </div>
          {patientList.length > 0 ? (
            patientList.map((e, item) => {
              const patientIdNumber = `${e.patientCardNumber.slice(
                0,
                3,
              )}-${e.patientCardNumber.slice(3, 6)}`;

              return (
                <div
                  onClick={() => navigate(`/patients/${e.id}/chat`)}
                  key={e.id}
                  className={`cursor-pointer border-t pt-2 ${
                    e.awaitingResponse ? "font-semibold" : "font-normal"
                  } hover:bg-jutro-new-warm-gray-50`}
                >
                  <div className="flex w-full items-center gap-x-8 p-2">
                    <div className="flex items-center gap-x-8">
                      <div className="w-8">{item + 1}</div>
                      <div
                        data-hj-suppress
                        className="w-64"
                      >{`${e.firstName} ${e.lastName}`}</div>
                      <div className="w-20">{patientIdNumber}</div>
                      <div className="w-24">
                        {dayjs(e.lastPatientMessageBlock?.iso).unix() > 0
                          ? dayjs(e.lastPatientMessageBlock?.iso).fromNow(true)
                          : "-"}
                      </div>
                    </div>
                    <div className="flex w-full items-center justify-between">
                      <div className="w-full">
                        {e.lastChatMessage?.content
                          ? lastMessageMap(e.lastChatMessage.content)
                          : "-"}
                      </div>
                    </div>

                    {isFunctionButtonsEnabled && (
                      <div className="flex cursor-pointer gap-x-2">
                        <Button
                          full={false}
                          variant="negative"
                          size="condensed"
                          text="Zakończ"
                          onClick={(event) => {
                            closePatient(e.id);
                            event.stopPropagation();
                          }}
                        />
                        <Button
                          full={false}
                          variant="secondary"
                          size="condensed"
                          text="Odepnij się"
                          onClick={(event) => {
                            unpin && unpin(e.id);
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="font-paragraph-2 border-t pt-2">
              <span className="p-2">Brak wyników.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import {
  DoctorVisitQuery,
  useDoctorICD10LazyQuery,
} from "lib/graphql/megaSchema";
import { getIcd10Option } from "views/Visit/RightPanel/Visit/components/Icd10Select/tools";

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

export const useSearchIcd10Options = (
  tags: Patient["tags"] | undefined,
  alreadySelectedOptions?: Visit["doctorDiagnosis"],
) => {
  const [getIcd10s] = useDoctorICD10LazyQuery();

  const icd10Options = async (search: string) => {
    const { data } = await getIcd10s({ variables: { search } });

    if (!data || data.doctorICD10.length === 0) {
      return [];
    }

    return data.doctorICD10.map((icd10) => getIcd10Option(icd10));
  };

  const alreadySelectedCodes = alreadySelectedOptions?.map(
    (option) => option?.code ?? "",
  );

  const isAlreadySelected = (icd10: string) =>
    alreadySelectedCodes?.some((selectedCode) => {
      return icd10.startsWith(selectedCode);
    });

  const filterSelectedValues = (
    values: Awaited<ReturnType<typeof icd10Options>>,
  ) => values.filter((icd10) => !isAlreadySelected(icd10.value));

  const searchIcd10Options = async (search: string) => {
    if (search !== "") {
      const options = await icd10Options(search);
      const filteredOptions = filterSelectedValues(options);

      return filteredOptions.map((option) => getIcd10Option(option.value));
    }

    const mappedTags = tags
      ?.filter((tag) => tag.type === "CHRONIC_DISEASE")
      .map((tag) => getIcd10Option(tag.data.name));

    return filterSelectedValues(mappedTags ?? []);
  };

  return {
    searchIcd10Options,
  };
};

import { DoctorMSUserGenre } from "lib/graphql/megaSchema";
import { isPathMatching } from "lib/tools";

type Mode = "EVERYWHERE" | "NOWHERE";

type RouteAccessRules = { mode: Mode; except: string[] };

const genreAllowedRoutesMap: Record<DoctorMSUserGenre, RouteAccessRules> = {
  STAFF: {
    mode: "NOWHERE",
    except: ["/patients", "/patients/*/profile", "/schedule", "/news/*"],
  },
  PATIENT: {
    mode: "NOWHERE",
    except: [],
  },
  DOCTOR: {
    mode: "EVERYWHERE",
    except: ["/patients/*/profile", "/visits/active"],
  },
  NURSE: {
    mode: "EVERYWHERE",
    except: ["/visits/active"],
  },
  ASSISTANT: {
    mode: "EVERYWHERE",
    except: [],
  },
  MODERATOR: {
    mode: "EVERYWHERE",
    except: [],
  },
  CLINIC_MANAGER: {
    mode: "EVERYWHERE",
    except: [],
  },
};

export const isRouteNotAllowedForUserGenre = (
  pathname: string,
  currentUserGenre: DoctorMSUserGenre,
) => {
  const { mode, except } = genreAllowedRoutesMap[currentUserGenre];
  const isExceptRoute = except.some((route) => isPathMatching(pathname, route));

  return mode === "EVERYWHERE" ? isExceptRoute : !isExceptRoute;
};

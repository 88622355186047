import { envVars } from "envvars";
import defaultAvatar from "assets/default-avatar.png";
import { avatarConfig } from "lib/tools/httpConfigs";

type Props = {
  userId: string | undefined | null;
  size?: "xs" | "s" | "small" | "medium" | "large";
};

const sizeMap = {
  xs: "w-4 h-4",
  s: "w-5 h-5",
  small: "w-6 h-6",
  medium: "w-8 h-8",
  large: "w-10 h-10",
};

export const Avatar = ({ userId, size = "small" }: Props) => (
  <img
    className={`${sizeMap[size]} relative rounded-full`}
    src={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${userId}`}
    onError={(e: any) => {
      e.target.onerror = null;
      e.target.src = defaultAvatar;
    }}
    alt="user avatar"
  />
);

type Props = {
  text: string;
  isError?: boolean;
};

export const SlotInfo = ({ text, isError = false }: Props) => (
  <div className="font-label flex w-full justify-center rounded-lg bg-white px-4 py-8">
    <div className="flex w-full justify-center rounded-lg border py-4">
      <span className={isError ? "text-red-500" : ""}>{text}</span>
    </div>
  </div>
);

import { zodResolver } from "@hookform/resolvers/zod";
import { determineAge, determineBirthday, determineSex } from "@jutro/tools";
import { Avatar } from "@jutro/ui";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  DoctorGetPatientDocument,
  DoctorMSDoctor,
  DoctorMSNfzDeclarationInputPatientMultiple,
  DoctorMSPatient,
  DoctorNfzHistoryDocument,
  DoctorPatientProfileVisitDocument,
  useDoctorCreateNFZEventMutation,
  useDoctorGenerateDeclarationPdfQueryLazyQuery,
} from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { avatarConfig } from "lib/tools/httpConfigs";
import { isPastVisit } from "lib/tools/isPastVisit";
import { DeclarationCreatorForm } from "views/Patient/Profile/types";
import { useDeclarationHistory } from "views/Patient/Profile/useDeclarationHistory";
import {
  prepareDeclarationData,
  prepareNfzEventInput,
} from "views/Patient/Profile/utils";

export const declarationCreatorSchema = z
  .object({
    pesel: z.string().optional(),
    doctorDeclaration: z.boolean(),
    nurseDeclaration: z.boolean(),
    midwifeDeclaration: z.boolean(),
    date: z.date({
      required_error: "To pole jest wymagane",
      invalid_type_error: "To pole jest wymagane",
    }),
    organizationId: z
      .object({
        value: z.union([
          z.literal("PATIENT"),
          z.literal("PB"),
          z.literal("PC"),
          z.literal("PD"),
          z.literal("PE"),
          z.literal("PF"),
          z.literal("PG"),
          z.literal("PK"),
          z.literal("PL"),
          z.literal("PM"),
          z.literal("PN"),
          z.literal("PO"),
          z.literal("PP"),
          z.literal("PR"),
          z.literal("PS"),
          z.literal("PT"),
          z.literal("PZ"),
        ]),
        label: z.string(),
      })
      .nullable(),
    type: z.object(
      {
        label: z.string(),
        value: z.object({
          form: z.union([
            z.literal("APP"),
            z.literal("EPUAP"),
            z.literal("IKP"),
            z.literal("PAPER"),
          ]),
          source: z.union([
            z.literal("APP"),
            z.literal("EMPTY"),
            z.literal("EPUAP"),
            z.literal("IKP"),
            z.literal("PAPERFIRSTVISIT"),
            z.literal("PAPERORGANIC"),
            z.literal("PAPERSPECIAL"),
          ]),
        }),
      },
      { required_error: "To pole jest wymagane" },
    ),
    legalGuardian: z
      .object(
        {
          value: z.object(
            {
              id: z.string(),
              firstName: z.string(),
              lastName: z.string(),
              phone: z.string(),
              email: z.string().nullable(),
              patientCardNumber: z.string(),
              address: z
                .object({
                  city: z.string(),
                  houseNumber: z.string(),
                  flatnumber: z.string().optional(),
                  street: z.string(),
                  zipCode: z.string(),
                })
                .nullable(),
            },
            {
              required_error: "To pole jest wymagane",
            },
          ),
          label: z.string(),
        },
        { invalid_type_error: "To pole jest wymagane" },
      )
      .optional(),
    doctorDeclarationData: z
      .object({
        staffData: z
          .object({
            npwz: z.string(),
            id: z.string().nullable(),
          })
          .nullable(),
        legacyDoctors: z.boolean().optional(),
        howManyProviderChanges: z.string(),
        facilityChangeReason: z
          .union([
            z.literal("ANOTHER_FACT"),
            z.literal("ANOTHER_PROVIDER_REASON"),
            z.literal("CHANGE_OF_ADDRESS_OF_RESIDENCE"),
            z.literal("REACHING_ADULTHOOD_WITH_PEDIATRICIAN"),
            z.literal("SERVICE_PROVIDER_CEASING"),
          ])
          .optional(),
        otherReasonProviderChange: z.string().optional(),
      })
      .nullable(),
    nurseDeclarationData: z
      .object({
        staffData: z
          .object({
            npwz: z.string(),
            id: z.string().nullable(),
          })
          .nullable(),
        howManyProviderChanges: z.string(),
        facilityChangeReason: z
          .union([
            z.literal("ANOTHER_FACT"),
            z.literal("ANOTHER_PROVIDER_REASON"),
            z.literal("CHANGE_OF_ADDRESS_OF_RESIDENCE"),
            z.literal("REACHING_ADULTHOOD_WITH_PEDIATRICIAN"),
            z.literal("SERVICE_PROVIDER_CEASING"),
          ])
          .optional(),
        otherReasonProviderChange: z.string().optional(),
      })
      .nullable(),
    midwifeDeclarationData: z
      .object({
        staffData: z
          .object({
            npwz: z.string(),
            id: z.string().nullable(),
          })
          .nullable(),
        howManyProviderChanges: z.string(),
        facilityChangeReason: z
          .union([
            z.literal("ANOTHER_FACT"),
            z.literal("ANOTHER_PROVIDER_REASON"),
            z.literal("CHANGE_OF_ADDRESS_OF_RESIDENCE"),
            z.literal("REACHING_ADULTHOOD_WITH_PEDIATRICIAN"),
            z.literal("SERVICE_PROVIDER_CEASING"),
          ])
          .optional(),
        otherReasonProviderChange: z.string().optional(),
      })
      .nullable(),
  })
  .refine(
    (values) => {
      if (values.doctorDeclarationData?.howManyProviderChanges !== "many") {
        return true;
      }

      return values.doctorDeclarationData.facilityChangeReason;
    },
    {
      path: ["doctorDeclarationData.facilityChangeReason"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (values) => {
      if (values.doctorDeclarationData?.howManyProviderChanges !== "many") {
        return true;
      }

      if (
        values.doctorDeclarationData.facilityChangeReason !==
        "ANOTHER_PROVIDER_REASON"
      ) {
        return true;
      }

      return values.doctorDeclarationData.otherReasonProviderChange;
    },
    {
      path: ["doctorDeclarationData.otherReasonProviderChange"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (values) => {
      if (values.nurseDeclarationData?.howManyProviderChanges !== "many") {
        return true;
      }

      return values.nurseDeclarationData.facilityChangeReason;
    },
    {
      path: ["nurseDeclarationData.facilityChangeReason"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (values) => {
      if (values.nurseDeclarationData?.howManyProviderChanges !== "many") {
        return true;
      }

      if (
        values.nurseDeclarationData.facilityChangeReason !==
        "ANOTHER_PROVIDER_REASON"
      ) {
        return true;
      }

      return values.nurseDeclarationData.otherReasonProviderChange;
    },
    {
      path: ["nurseDeclarationData.otherReasonProviderChange"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (values) => {
      if (values.midwifeDeclarationData?.howManyProviderChanges !== "many") {
        return true;
      }

      return values.midwifeDeclarationData.facilityChangeReason;
    },
    {
      path: ["midwifeDeclarationData.facilityChangeReason"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (values) => {
      if (values.midwifeDeclarationData?.howManyProviderChanges !== "many") {
        return true;
      }

      if (
        values.midwifeDeclarationData.facilityChangeReason !==
        "ANOTHER_PROVIDER_REASON"
      ) {
        return true;
      }

      return values.midwifeDeclarationData.otherReasonProviderChange;
    },
    {
      path: ["midwifeDeclarationData.otherReasonProviderChange"],
      message: "To pole jest wymagane",
    },
  )
  .refine(
    (values) => {
      if (determineAge(values.pesel ?? "") >= 18) {
        return true;
      }

      return values.legalGuardian?.value;
    },
    { path: ["legalGuardian"], message: "To pole jest wymagane" },
  );

const isOlderThanThreeMonths = (birthdate: string) => {
  const currentDate = dayjs();
  const threeMonthsAgo = currentDate.subtract(3, "month");
  return dayjs(birthdate).isBefore(threeMonthsAgo);
};

type Props = {
  patient: DoctorMSPatient;
  doctors: DoctorMSDoctor[];
};

export const handleOpenPdfInNewTab = (base64Pdf: string) => {
  const pdfData = `data:application/pdf;base64,${base64Pdf}`;

  const blob = new Blob([], { type: "application/pdf" });

  const url = URL.createObjectURL(blob);
  const w = window.open(url);

  if (w) {
    w.document.write(
      `<embed width="100%" height="100%" src="${pdfData}" type="application/pdf" />`,
    );
  }
};

export const useDeclarationCreatorForm = ({ doctors, patient }: Props) => {
  const { addTile } = useTileSystem();

  const { declarations } = useDeclarationHistory();

  const [generateDeclarationPdf, { loading: generatePDFLoading }] =
    useDoctorGenerateDeclarationPdfQueryLazyQuery();

  const { vojevodshipsWithCities } = useVojevodshipOptions();

  const [createNFZEvent, { loading: createNfzEventLoading }] =
    useDoctorCreateNFZEventMutation({
      refetchQueries: [
        DoctorGetPatientDocument,
        DoctorPatientProfileVisitDocument,
        DoctorNfzHistoryDocument,
      ],
    });

  const isPatientNotAdult = determineAge(patient.pesel ?? "") < 18;

  const patientBirthday = determineBirthday(patient.pesel ?? "");

  const newestVisit =
    patient.visits.length > 0
      ? patient.visits[patient.visits.length - 1]
      : null;

  const isVisitLockingRegistrationSource = newestVisit
    ? Boolean(
        newestVisit.type !== "PROCEDURE" &&
          newestVisit.status !== "ARCHIVE" &&
          newestVisit.plannedStart &&
          !isPastVisit(newestVisit) &&
          newestVisit.createdBy === patient.id,
      )
    : false;

  const isPatientRegisteredFromMobile = Boolean(
    !declarations.length && isVisitLockingRegistrationSource,
  );

  const isMidwifeAvailableForPatient =
    (!isOlderThanThreeMonths(patientBirthday) &&
      determineSex(patient.pesel) === "M") ||
    determineSex(patient.pesel) === "K";

  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = useForm<DeclarationCreatorForm>({
    defaultValues: {
      date: new Date(),
      doctorDeclaration: true,
      nurseDeclaration: true,
      midwifeDeclaration: isMidwifeAvailableForPatient,
      doctorDeclarationData: {
        legacyDoctors: false,
        howManyProviderChanges: "few",
      },
      nurseDeclarationData: {
        howManyProviderChanges: "few",
      },
      midwifeDeclarationData: {
        howManyProviderChanges: "few",
      },
      organizationId: vojevodshipsWithCities.find(
        (o) => o.value === patient.nfzStatus.whereCanDeclare,
      ),
      pesel: patient.pesel ?? "",
    },
    resolver: zodResolver(declarationCreatorSchema),
    mode: "onChange",
  });

  const {
    organizationId,
    legalGuardian,
    doctorDeclaration,
    midwifeDeclaration,
    nurseDeclaration,
    doctorDeclarationData,
    nurseDeclarationData,
    midwifeDeclarationData,
    date,
  } = watch();

  const isDoctor = (e: DoctorMSDoctor) => e.genre === "DOCTOR";
  const isNurse = (e: DoctorMSDoctor) =>
    e.genre === "NURSE" && e.npwz?.endsWith("P");
  const isMidwife = (e: DoctorMSDoctor) =>
    e.genre === "NURSE" && e.npwz?.endsWith("A");
  const declarationFilter = (e: DoctorMSDoctor) =>
    isPatientNotAdult ? e.childrenDeclarable : e.adultDeclarable;
  const organizationFilter = (e: DoctorMSDoctor) =>
    e.declaringToOrganizationId === organizationId?.value;
  const mapToSelectOptions = (e: DoctorMSDoctor) => {
    return {
      label: `${e.firstName} ${e.lastName}`,
      value: { npwz: e.npwz, id: e.id },
      leftElement: (
        <Avatar
          type="photo"
          imgSrc={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${e.id}`}
          size="sm"
        />
      ),
    };
  };

  const allStaff = doctors;
  const availableStaff = allStaff
    .filter(declarationFilter)
    .filter(organizationFilter);
  const availableDoctors = doctorDeclarationData?.legacyDoctors
    ? allStaff
        .filter(isDoctor)
        .filter(organizationFilter)
        .map(mapToSelectOptions)
    : availableStaff.filter(isDoctor).map(mapToSelectOptions);
  const availableNurse = availableStaff.filter(isNurse).map(mapToSelectOptions);
  const availableMidwife = availableStaff
    .filter(isMidwife)
    .map(mapToSelectOptions);

  useEffect(() => {
    if (!availableDoctors.length || !doctorDeclaration) {
      setValue("doctorDeclaration", false);
      setValue("doctorDeclarationData", null);
      return;
    }

    setValue("doctorDeclarationData.howManyProviderChanges", "few");

    setValue("doctorDeclarationData.staffData", {
      npwz: availableDoctors[0]?.value?.npwz ?? "",
      id: availableDoctors[0]?.value?.id,
    });
  }, [organizationId, doctorDeclaration]);

  useEffect(() => {
    if (!availableNurse.length || !nurseDeclaration) {
      setValue("nurseDeclaration", false);
      setValue("nurseDeclarationData", null);
      return;
    }

    setValue("nurseDeclarationData.howManyProviderChanges", "few");

    setValue("nurseDeclarationData.staffData", {
      npwz: availableNurse[0]?.value.npwz ?? "",
      id: availableNurse[0]?.value.id,
    });
  }, [organizationId, nurseDeclaration]);

  useEffect(() => {
    if (!availableMidwife.length || !midwifeDeclaration) {
      setValue("midwifeDeclaration", false);
      setValue("midwifeDeclarationData", null);
      return;
    }

    setValue("midwifeDeclarationData.howManyProviderChanges", "few");

    setValue("midwifeDeclarationData.staffData", {
      npwz: availableMidwife[0]?.value.npwz ?? "",
      id: availableMidwife[0]?.value.id,
    });
  }, [organizationId, midwifeDeclaration]);

  useEffect(() => {
    if (!availableDoctors.length) {
      setValue("doctorDeclaration", false);
      setValue("doctorDeclarationData", null);
      return;
    }

    if (!doctorDeclarationData?.legacyDoctors) {
      setValue("doctorDeclarationData.howManyProviderChanges", "few");

      setValue("doctorDeclarationData.staffData", {
        npwz: availableDoctors[0]?.value.npwz ?? "",
        id: availableDoctors[0]?.value?.id,
      });
    }
  }, [doctorDeclarationData?.legacyDoctors]);

  useEffect(() => {
    if (isPatientRegisteredFromMobile) {
      setValue("type", {
        label: "Wizyta pierwszorazowa w placówce",
        value: {
          form: "PAPER",
          source: "PAPERFIRSTVISIT",
        },
      });
    }
  }, [isPatientRegisteredFromMobile]);

  const generateDeclarationsData = () => {
    const declarations: DoctorMSNfzDeclarationInputPatientMultiple[] = [];

    if (!organizationId) {
      return declarations;
    }

    if (doctorDeclaration) {
      const doctorDeclarationDetails = prepareDeclarationData(
        doctorDeclarationData,
        date,
        "DOCTOR",
        patient.id,
        organizationId.value,
      );

      declarations.push(doctorDeclarationDetails);
    }

    if (nurseDeclaration) {
      const nurseDeclarationDetails = prepareDeclarationData(
        nurseDeclarationData,
        date,
        "NURSE",
        patient.id,
        organizationId.value,
      );

      declarations.push(nurseDeclarationDetails);
    }

    if (midwifeDeclaration) {
      const midwifeDeclarationDetails = prepareDeclarationData(
        midwifeDeclarationData,
        date,
        "MIDWIFE",
        patient.id,
        organizationId.value,
      );

      declarations.push(midwifeDeclarationDetails);
    }

    return declarations;
  };

  const handleGenerateDeclarationPdf = async () => {
    trigger();
    if (!isValid) {
      return;
    }

    const { data } = await generateDeclarationPdf({
      variables: {
        representativeId: isPatientNotAdult
          ? legalGuardian?.value.id
          : undefined,
        request: generateDeclarationsData(),
      },
    });

    handleOpenPdfInNewTab(data?.doctorDoctorGeneratePdf ?? "");
  };

  const addNewDeclaration = async (data: DeclarationCreatorForm) => {
    const {
      doctorDeclaration,
      doctorDeclarationData,
      nurseDeclaration,
      nurseDeclarationData,
      midwifeDeclaration,
      midwifeDeclarationData,
      date,
      organizationId,
      type: {
        value: { form, source },
      },
      legalGuardian,
    } = data;

    if (!organizationId) {
      return;
    }

    if (doctorDeclaration) {
      await prepareNfzEventInput(
        patient.id,
        "DEKLARACJA",
        date,
        organizationId.value,
        "DOCTOR",
        form,
        source,
        doctorDeclarationData,
        createNFZEvent,
        legalGuardian?.value.id,
      );
    }

    if (nurseDeclaration) {
      await prepareNfzEventInput(
        patient.id,
        "DEKLARACJA",
        date,
        organizationId.value,
        "NURSE",
        form,
        source,
        nurseDeclarationData,
        createNFZEvent,
        legalGuardian?.value.id,
      );
    }

    if (midwifeDeclaration) {
      await prepareNfzEventInput(
        patient.id,
        "DEKLARACJA",
        date,
        organizationId.value,
        "MIDWIFE",
        form,
        source,
        midwifeDeclarationData,
        createNFZEvent,
        legalGuardian?.value.id,
      );
    }
    reset();
    addTile("patient-declaration-history", TileColumn.Right);
  };

  return {
    control,
    vojevodshipsWithCities,
    isPatientNotAdult,
    availableDoctors,
    availableNurse,
    availableMidwife,
    legalGuardian,
    doctorDeclaration,
    midwifeDeclaration,
    nurseDeclaration,
    doctorDeclarationData,
    nurseDeclarationData,
    midwifeDeclarationData,
    generatePDFLoading,
    createNfzEventLoading,
    isMidwifeAvailableForPatient,
    isPatientRegisteredFromMobile,
    handleSubmit,
    handleGenerateDeclarationPdf,
    addNewDeclaration,
  };
};

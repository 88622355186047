export const copyset = [
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Od kiedy występują u Ciebie objawy infekcji?",
    answer: "Od 1 do 2 dni",
    entry: "Infekcja dróg oddechowych od: 1-2 dni",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Od kiedy występują u Ciebie objawy infekcji?",
    answer: "Od 3 do 4 dni",
    entry: "Infekcja dróg oddechowych od: 3-4dni",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Od kiedy występują u Ciebie objawy infekcji?",
    answer: "Powyżej 5 dni",
    entry: "Infekcja dróg oddechowych >5dni",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jaka jest obecnie temperatura Twojego ciała? *",
    answer: "Poniżej 37,5 °C",
    entry: "Temperatura ciała: prawidłowa",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jaka jest obecnie temperatura Twojego ciała? *",
    answer: "Pomiędzy 37,5 a 38 °C (stan podgorączkowy)",
    entry: "Temperatura ciała: stan podgorączkowy",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jaka jest obecnie temperatura Twojego ciała? *",
    answer: "Powyżej 38 °C (gorączka)",
    entry: "Temperatura ciała: gorączka >38st.C",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie nasilone zmęczenie? *",
    answer: "Tak",
    entry: "Nasilone zmęczenie: tak",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie nasilone zmęczenie? *",
    answer: "Nie",
    entry: "Nasilone zmęczenie: neguje",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question:
      'Czy występują u Ciebie bóle kości, mięśni, stawów lub ogólne uczucie "rozbicia"?',
    answer: "Tak",
    entry: "Bóle kostno-mięśniowe/rozbicie: tak",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question:
      'Czy występują u Ciebie bóle kości, mięśni, stawów lub ogólne uczucie "rozbicia"?',
    answer: "Nie",
    entry: "Bóle kostno-mięśniowe/rozbicie: nie",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy boli Cię głowa? *",
    answer: "Tak",
    entry: "Bóle głowy: tak",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy boli Cię głowa? *",
    answer: "Nie",
    entry: "Bóle głowy: neguje",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie katar? *",
    answer: "Tak",
    entry: "Katar: tak",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie katar? *",
    answer: "Nie",
    entry: "Katar: neguje",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy wystąpiła u Ciebie utrata węchu, bądź smaku? *",
    answer: "Tak",
    entry: "Utrata węchu lub smaku: tak",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy wystąpiła u Ciebie utrata węchu, bądź smaku? *",
    answer: "Nie",
    entry: "Utrata węchu lub smaku: nie",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Wskaż miejsce występowania bólu",
    answer: "Wyraźnie jednostronny",
    entry: "Bóle zatok: tak, wyraźnie jednostronny",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Wskaż miejsce występowania bólu",
    answer: "Obustronny",
    entry: "Bóle zatok: tak, obustronnie",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Wskaż miejsce występowania bólu",
    answer: "ALL",
    entry: "Bóle zatok - pacjent wskazuje miejsce - [ANSWER]",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question:
      "Czy występuje u Ciebie ból zatok (ból głowy w okolicy czoła lub wokół nosa)?",
    answer: "Nie",
    entry: "Bóle zatok: neguje.",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jaki charakter ma kaszel?",
    answer: "Głównie suchy, męczący",
    entry: "Kaszel: suchy, męczący",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jaki charakter ma kaszel?",
    answer: "Produktywny, z odkrztuszaniem wydzieliny",
    entry: "Kaszel: produktywny, z odksztuszaniem wydzieliny",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie kaszel?",
    answer: "Nie",
    entry: "Kaszel: neguje",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jakie widzisz wizualne zmiany w wyglądzie gardła?",
    answer: "Wyraźnie zaczerwienione gardło",
    entry: "Bóle gardła: tak, z wyraźnym zaczerwienieniem",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jakie widzisz wizualne zmiany w wyglądzie gardła?",
    answer: "Na migdałkach obecny jest biały nalot",
    entry: "Bóle gardła: tak, na migdałach obecny biały nalot",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jakie widzisz wizualne zmiany w wyglądzie gardła?",
    answer: "Nie widzę żadnych zmian",
    entry: "Bóle gardła: tak, neguje zmiany wizualne",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie ból gardła?",
    answer: "Nie",
    entry: "Bóle gardła: neguje",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Podaj nazwy stosowanych do tej pory leków w leczeniu objawowym",
    answer: "ALL",
    entry: "Dotychczas stosowane leki objawowe: [ANSWER]",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy stosujesz leczenie objawowe? *",
    answer: "Nie",
    entry: "Dotychczas nie stosowane leki objawowe.",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "W jaki sposób objawia się Twoja duszność?",
    answer: "ALL",
    entry: "Duszność: tak, w postaci: [ANSWER]",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy występuje u Ciebie duszność? *",
    answer: "Nie",
    entry: "Duszność: neguje",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Jakie dodatkowe objawy u Ciebie występują lub wystąpiły?",
    answer: "ALL",
    entry: "Zgłasza dodatkowe objawy:[ANSWER]",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question:
      "Czy poza zgłoszonymi objawami występują lub wystąpiły jakieś dodatkowe objawy?",
    answer: "Nie",
    entry: "Nie zgłasza innych dolegliwości",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy obecnie przebywasz na kwarantannie?",
    answer: "Tak",
    entry: "Kwarantanna: tak",
  },
  {
    ID: "1IvymqvBkrqBzYLWCdBfT1VgmuNftzOPKGCvh2VWyxgQ",
    title: "03_Podejrzewam COVID-19 (Ankieta infekcyjna)",
    question: "Czy obecnie przebywasz na kwarantannie?",
    answer: "Nie",
    entry: "Kwarantanna: nie",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Czy wystąpiły u Ciebie jakiekolwiek objawy infekcji?",
    answer: "Tak",
    entry: "Przebieg infekcji objawowy.",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Czy wystąpiły u Ciebie jakiekolwiek objawy infekcji?",
    answer: "Nie",
    entry: "Przebieg bezobjawowy.",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Podaj datę pierwszych objawów infekcji",
    answer: "ALL",
    entry: "Infekcja, od: [ANSWER]",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Pozostają bez zmian",
    entry: "Aktualnie dolegliwości bez zmian",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Nasiliły się",
    entry: "Aktualnie dolegliwości nasiliły się",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Zmniejszyły się",
    entry: "Aktualnie dolegliwości ustępują",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Nie posiadam już żadnych objawów (dolegliwości)",
    entry: "Aktualnie bez dolegliwości.",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Wskaż występujące w dniu dzisiejszym objawy infekcji",
    answer: "ALL",
    entry: "Zgłasza: [ANSWER]",
  },
  {
    ID: "1PvA80MWzPdxg3nsirZCmG3-0HUl9HtnGeW5zTAFNqyI",
    title: "04_Mam wynik POZYTYWNY (+)",
    question: "Podaj datę wykonania testu COVID-19",
    answer: "ALL",
    entry: "Data wykonania testu: [ANSWER]",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Data potwierdzenia wyniku negatywnego",
    answer: "ALL",
    entry: "Data uzyskania wyniku: [ANSWER]",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Od kiedy występują u Ciebie objawy infekcji?",
    answer: "Od 1 do 2 dni",
    entry: "Infekcja od: 1-2 dni",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Od kiedy występują u Ciebie objawy infekcji?",
    answer: "Od 3 do 4 dni",
    entry: "Infekcja od: 3-4 dni",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Od kiedy występują u Ciebie objawy infekcji?",
    answer: "Powyżej 5 dni",
    entry: "Infekcja ponad 5 dni",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jaka jest obecnie temperatura Twojego ciała? *",
    answer: "Poniżej 37,5 °C",
    entry: "Temperatura ciała: prawidłowa",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jaka jest obecnie temperatura Twojego ciała? *",
    answer: "Pomiędzy 37,5 a 38 °C (stan podgorączkowy)",
    entry: "Temperatura ciała: stan podgorączkowy",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jaka jest obecnie temperatura Twojego ciała? *",
    answer: "Powyżej 38 °C (gorączka)",
    entry: "Temperatura ciała: gorączka >38st.C",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie nasilone zmęczenie? *",
    answer: "Tak",
    entry: "Nasilone zmęczenie: tak",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie nasilone zmęczenie? *",
    answer: "Nie",
    entry: "Nasilone zmęczenie: neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question:
      'Czy występują u Ciebie bóle kości, mięśni, stawów lub ogólne uczucie "rozbicia"?',
    answer: "Tak",
    entry: "Bóle kostno-mięśniowe/rozbicie: tak",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question:
      'Czy występują u Ciebie bóle kości, mięśni, stawów lub ogólne uczucie "rozbicia"?',
    answer: "Nie",
    entry: "Bóle kostno-mięśniowe/rozbicie: nie",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jakie widzisz wizualne zmiany w wyglądzie gardła?",
    answer: "Wyraźnie zaczerwienione gardło",
    entry: "Bóle gardła: tak, z wyraźnym zaczerwienieniem",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jakie widzisz wizualne zmiany w wyglądzie gardła?",
    answer: "Na migdałkach obecny jest biały nalot",
    entry: "Bóle gardła: tak, na migdałach obecny biały nalot",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jakie widzisz wizualne zmiany w wyglądzie gardła?",
    answer: "Nie widzę żadnych zmian",
    entry: "Bóle gardła: tak, zmiany wizualne w wyglądzie gardła neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie ból gardła?",
    answer: "Nie",
    entry: "Bóle gardła: neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jaki charakter ma kaszel?",
    answer: "Głównie suchy, męczący",
    entry: "Kaszel: suchy, męczący",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jaki charakter ma kaszel?",
    answer: "Produktywny, z odkrztuszaniem wydzieliny",
    entry: "Kaszel: produktywny, z odksztuszaniem wydzieliny",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie kaszel?",
    answer: "Nie",
    entry: "Kaszel: neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie katar? *",
    answer: "Tak",
    entry: "Katar: tak",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie katar? *",
    answer: "Nie",
    entry: "Katar: neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy boli Cię głowa? *",
    answer: "Tak",
    entry: "Bóle głowy: tak",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy boli Cię głowa? *",
    answer: "Nie",
    entry: "Bóle głowy: neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Wskaż miejsce występowania bólu",
    answer: "Wyraźnie jednostronny",
    entry: "Bóle zatok: tak, wyraźnie jednostronny",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Wskaż miejsce występowania bólu",
    answer: "Obustronny",
    entry: "Bóle zatok: tak, obustronnie",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Wskaż miejsce występowania bólu",
    answer: "ALL",
    entry: "Bóle zatok - pacjent wskazuje miejsce - [ANSWER]",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question:
      "Czy występuje u Ciebie ból zatok (ból głowy w okolicy czoła lub wokół nosa)? *",
    answer: "Nie",
    entry: "Bóle zatok: neguje.",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Podaj nazwy stosowanych do tej pory leków w leczeniu objawowym",
    answer: "ALL",
    entry: "Dotychczas stosowane leki objawowe: [ANSWER]",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy stosujesz leczenie objawowe?",
    answer: "Nie",
    entry: "Dotychczas nie stosowane leki objawowe.",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "W jaki sposób objawia się Twoja duszność?",
    answer: "ALL",
    entry: "Duszność: tak, zgłasza: [ANSWER]",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Czy występuje u Ciebie duszność?",
    answer: "Nie",
    entry: "Duszność: neguje",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question: "Jakie dodatkowe objawy u Ciebie występują lub wystąpiły?",
    answer: "ALL",
    entry: "Zgłasza inne dolegliwości: [ANSWER]",
  },
  {
    ID: "1aoqs2cTBXNMA1OjbnbTYYiAiBDsp1ZKwalTXZjTlFV8",
    title: "05_Mam wynik NEGATYWNY (-)",
    question:
      "Czy poza zgłoszonymi objawami występują lub wystąpiły jakieś dodatkowe objawy?",
    answer: "Nie",
    entry: "Inne dolegliwości neguje",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question: "Data planowanego zakończenia izolacji",
    answer: "ALL",
    entry: "Wizyta kontrolna przez zakończeniem izolacji (infekcja COVID-19)",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Pozostają bez zmian",
    entry: "Aktualnie dolegliwości bez zmian",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Nasiliły się",
    entry: "Aktualnie dolegliwości nasiliły się",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Zmniejszyły się",
    entry: "Aktualnie dolegliwości ustępują",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question: "Od czasu zlecenia testu do dnia dzisiejszego objawy infekcji",
    answer: "Nie posiadam już żadnych objawów (dolegliwości)",
    entry: "Aktualnie bez dolegliwości.",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question: "Wskaż występujące w ciągu ostatnich 3 dni objawy infekcji",
    answer: "ALL",
    entry: "Zgłasza: [ANSWER]",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question:
      "Czy po zakończeniu izolacji potrzebujesz dodatkowego zwolnienia lekarskiego, aby dojść do siebie po infekcji COVID-19?",
    answer: "Tak",
    entry: "Wystawiono ZLA. Brak wskazań do przedłużenia izolacji.",
  },
  {
    ID: "1ldNhetf30f0AyAq9dlQAkOLvXrK_niINf2qLu0K6dv8",
    title: "06_Zakończenie izolacji",
    question:
      "Czy po zakończeniu izolacji potrzebujesz dodatkowego zwolnienia lekarskiego, aby dojść do siebie po infekcji COVID-19?",
    answer: "Nie",
    entry: "Brak wskazań do przedłużenia izolacji.",
  },
  {
    ID: "18lhjEJvHxg-g_c5Wl54v2L6kfm4DeXdHJiUid3O0PCY",
    title: "07_Recepta na leki stałe",
    question:
      "Podaj datę ostatniej kontroli u lekarza prowadzącego Twoje leczenie",
    answer: "ALL",
    entry:
      "Przedłużenie leków stałych - teleporada. Ostatnia kontrola lekarska: [ANSWER]",
  },
  {
    ID: "18lhjEJvHxg-g_c5Wl54v2L6kfm4DeXdHJiUid3O0PCY",
    title: "07_Recepta na leki stałe",
    question:
      "Czy od ostatniej wizyty lekarskiej nastąpiły zmiany w Twoim stanie zdrowia?",
    answer: "Nie",
    entry: "Neguje zmianę stanu zdrowia;",
  },
  {
    ID: "18lhjEJvHxg-g_c5Wl54v2L6kfm4DeXdHJiUid3O0PCY",
    title: "07_Recepta na leki stałe",
    question: "Co się zmieniło od ostatniej wizyty lekarskiej?",
    answer: "ALL",
    entry: "Zmiana stanu zdrowia od ostatniej wizyty pod postacią:  [ANSWER]",
  },
  {
    ID: "18lhjEJvHxg-g_c5Wl54v2L6kfm4DeXdHJiUid3O0PCY",
    title: "07_Recepta na leki stałe",
    question:
      "Podaj nazwy leków, na które potrzebujesz recepty — nazwa leku, dawka, dawkowanie, liczba opakowań — np. J_Medical (10mg, 2x1t, 1op.)",
    answer: "ALL",
    entry: "Przedłużono leki stałe.",
  },
  {
    ID: "1gMcszpbvbKl7blpLqFF4YFfY5K9dxjfIvXGJjRRYbuI",
    title: "08_Opieka specjalisty — skierowanie",
    question: "Do jakiej poradni potrzebujesz skierowanie?",
    answer: "ALL",
    entry:
      "Pisemna prośba o skierowanie na leczenie przewlekłe do poradni: [ANSWER]",
  },
  {
    ID: "1gMcszpbvbKl7blpLqFF4YFfY5K9dxjfIvXGJjRRYbuI",
    title: "08_Opieka specjalisty — skierowanie",
    question: "Od kiedy pozostajesz pod kontrolą poradni specjalistycznej?",
    answer: "ALL",
    entry: "Pod opieką poradni od: [ANSWER]",
  },
  {
    ID: "1gMcszpbvbKl7blpLqFF4YFfY5K9dxjfIvXGJjRRYbuI",
    title: "08_Opieka specjalisty — skierowanie",
    question: "Podaj datę ostatniej wizyty wybranej poradni specjalistycznej",
    answer: "ALL",
    entry: "Ostatnia wizyta w poradni: [ANSWER]",
  },
  {
    ID: "17e0eDbHmzCvnsTBKlSpNND_C-14Fy9SMhUSbNwvsxcM",
    title: "09_Badania kontrolne",
    question: "Podaj datę ostatnich badań kontrolnych",
    answer: "ALL",
    entry:
      "Neguje aktualne dolegliwości. \nOstatnio badania wykonane: [ANSWER]",
  },
  {
    ID: "17e0eDbHmzCvnsTBKlSpNND_C-14Fy9SMhUSbNwvsxcM",
    title: "09_Badania kontrolne",
    question: "Jakie to były badania kontrolne?",
    answer: "ALL",
    entry: "Wykonano: [ANSWER]",
  },
  {
    ID: "1MpKOP_nBfd44SeekSjiGF67Y4KiZEpClWVhzlJwl2FQ",
    title: "10_Dokumentacja medyczna",
    question:
      "Zaznacz rodzaj dokumentu oraz cel wydania dokumentacji medycznej",
    answer: "ALL",
    entry: "Prośba o wystawienie dokumentacji: [ANSWER]",
  },
  {
    ID: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    title: "11_Kwestionariusz wywiadu (Szczepienia ochronne)",
    question: "Czy chorujesz dziś objawowo? (Np. przeziębienie)",
    answer: "Tak",
    entry: "Aktualnie zgłasza mierne objawy infekcji.",
  },
  {
    ID: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    title: "11_Kwestionariusz wywiadu (Szczepienia ochronne)",
    question: "Czy chorujesz dziś objawowo? (Np. przeziębienie)",
    answer: "Nie",
    entry: "Aktualnie neguje objawy infekcji.",
  },
  {
    ID: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    title: "11_Kwestionariusz wywiadu (Szczepienia ochronne)",
    question: "Zaznacz alergie, które występują u Ciebie:",
    answer: "ALL",
    entry: "Alergie: [ANSWER]",
  },
  {
    ID: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    title: "11_Kwestionariusz wywiadu (Szczepienia ochronne)",
    question: "Czy masz na coś uczulenie?",
    answer: "Nie",
    entry: "Alergie: neguje",
  },
  {
    ID: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    title: "11_Kwestionariusz wywiadu (Szczepienia ochronne)",
    question: "Na którą z wymienionych chorób chorujesz?",
    answer: "ALL",
    entry: "Choroby przewlekłe:  [ANSWER]",
  },
  {
    ID: "1EhOXRHRjYnLn-i4nrv8FTFNJo5ZNMjkAysFPM9z2U_M",
    title: "11_Kwestionariusz wywiadu (Szczepienia ochronne)",
    question:
      "Czy chorujesz przewlekle na chorobę serca, astmę lub inną chorobę płuc, nerek, chorobę metaboliczną (np. cukrzycę), niedokrwistość lub inną chorobę krwi?",
    answer: "Nie",
    entry: "Choroby przewlekłe: neguje",
  },
  {
    ID: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    title: "Ból (ogólna)",
    question:
      "Jak oceniasz nasilenie dolegliwości bólowych? (0 = Brak bólu; 10 = Ból jest nie do zniesienia)",
    answer: "ALL",
    entry: "Ból w skali VAS: [ANSWER]",
  },
  {
    ID: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    title: "Ból (ogólna)",
    question:
      "Gdzie dokładnie odczuwasz ból? (Np. brzuch, po prawej stronie, na wysokości pępka)",
    answer: "ALL",
    entry: "Lokalizacja dolegliwości: [ANSWER]",
  },
  {
    ID: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    title: "Ból (ogólna)",
    question:
      "W którą stronę promieniuje ból? (Np. lewa ręka, od nadgarstka do łokcia)",
    answer: "ALL",
    entry: "Promieniowanie bólu: [ANSWER]",
  },
  {
    ID: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    title: "Ból (ogólna)",
    question: "Czy ból promieniuje?",
    answer: "Nie",
    entry: "Promieniowanie bólu: neguje",
  },
  {
    ID: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    title: "Ból (ogólna)",
    question:
      "Czy w miejscu bólu, bądź w miejscu jego promieniowania pojawił się jeden z poniższych objawów?",
    answer: "ALL",
    entry: "W miejscu dolegliwości dodatkowo zgłasza: [ANSWER]",
  },
  {
    ID: "1fbFVkkxpk1ZX3029P3I-crUAzL0VznbyWyv0Wye1soo",
    title: "Ból (ogólna)",
    question: "Jakie badania były dotychczas wykonane?",
    answer: "ALL",
    entry: "Dotychczas wykonana diagnostyka: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Od kiedy występują objawy bólowe?",
    answer: "ALL",
    entry: "Dolegliwości bólowe jamy brzusznej od: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question:
      "Jak oceniasz nasilenie dolegliwości bólowych? (0 = Brak bólu; 10 = Ból jest nie do zniesienia)",
    answer: "ALL",
    entry: "Ból w skali VAS: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Poniżej 37,5 °C",
    entry: "Gorączka: neguje",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Pomiędzy 37,5 a 38 °C (stan podgorączkowy)",
    entry: "Gorączka: stan podgorączkowy",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Powyżej 38 °C (gorączka)",
    entry: "Gorączka: gorączka",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question:
      "Kiedy dotykasz swojego brzucha to jak jest on napięty? (badanie przeprowadź leżąc na plecach z nogami wyprostowanymi)",
    answer: "Brzuch jest miękki",
    entry: "Neguje napięcie brzucha (brzuch miękki w samobadaniu)",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question:
      "Kiedy dotykasz swojego brzucha to jak jest on napięty? (badanie przeprowadź leżąc na plecach z nogami wyprostowanymi)",
    answer: "Brzuch jest twardy",
    entry: "Zgłasza napięcie brzucha (brzuch twardy w samobadaniu)",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question:
      "Kiedy dotykasz swojego brzucha to jak jest on napięty? (badanie przeprowadź leżąc na plecach z nogami wyprostowanymi)",
    answer: "Brzuch jest deskowaty (wyczuwalne silne napięcie jego mięśni)",
    entry: "Zgłasza napięcie brzucha (brzuch deskowaty w samobadaniu)",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "W którym miejscu boli Cię brzuch?",
    answer: "ALL",
    entry: "Lokalizacja dolegliwości: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Czy ból brzucha promieniuje do innych części ciała?",
    answer: "Nie",
    entry: "Promieniowanie bólu: neguje",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "W którą stronę promieniuje ból?",
    answer: "ALL",
    entry: "Promieniowanie bólu: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Określ charakter tego bólu",
    answer: "ALL",
    entry: "Charakter bólu: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Czy występują objawy towarzyszące?",
    answer: "Nie",
    entry: "Neguje objawy towarzyszące",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Wskaż objawy towarzyszące",
    answer: "ALL",
    entry: "Dodatkowo zgłasza: [ANSWER]",
  },
  {
    ID: "11VIiXddJugbLl3C3wIMW2k1aqNv9WmbBld3-HIxYmX8",
    title: "Ból brzucha",
    question: "Podaj wartość zmierzonego ciśnienia (np. 120 / 79)",
    answer: "ALL",
    entry: "Zmierzone aktualne ciśnienie tętnicze: [ANSWER]",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Od kiedy występują objawy bólowe?",
    answer: "ALL",
    entry: "Dolegliwości bólowe głowy od: [ANSWER]",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Określ wystąpienie bólu głowy",
    answer: "Pojawił się nagle",
    entry: "Początek dolegliwości nagły.",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Określ wystąpienie bólu głowy",
    answer: "Narastał stopniowo",
    entry: "Dolegliwości początkowo niewielkie, z narastaniem.",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question:
      "Jak oceniasz nasilenie dolegliwości bólowych? (0 = Brak bólu; 10 = Ból jest nie do zniesienia)",
    answer: "ALL",
    entry: "Ból w skali VAS: [ANSWER]",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Uraz głowy w ostatnim czasie?",
    answer: "Tak",
    entry: "W wywiadzie uraz głowy",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Uraz głowy w ostatnim czasie?",
    answer: "Nie",
    entry: "Uraz głowy neguje",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy podobne dolegliwości zdarzały się w przeszłości?",
    answer: "Tak",
    entry: "Podobne dolegliwości zdarzały się w przeszłości",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy podobne dolegliwości zdarzały się w przeszłości?",
    answer: "Nie",
    entry: "Dolegliwości pierwszy raz w życiu.",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie zawroty głowy?",
    answer: "Tak",
    entry: "Zgłasza zawroty głowy.",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie zawroty głowy?",
    answer: "Nie",
    entry: "Neguje zawroty głowy",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie nudności?",
    answer: "Tak",
    entry: "Zgłasza nudności.",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie nudności?",
    answer: "Nie",
    entry: "Neguje nudności.",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question:
      "Czy przed wystąpieniem dolegliwości bólowych pojawiły się poniższe objawy?",
    answer: "ALL",
    entry: "Zgłasza objawy aury migrenowej: [ANSWER]",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question:
      "Czy przed wystąpieniem dolegliwości bólowych pojawiły się poniższe objawy?",
    answer: "Nie wystąpiły takie dolegliwości",
    entry: "Neguje objawy aury migrenowej",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie jakieś objawy dodatkowe?",
    answer: "ALL",
    entry: "Dodatkowo zgłasza: [ANSWER]",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występuje u Ciebie światłowstręt?",
    answer: "Tak",
    entry: "Zgłasza światłowstręt",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występuje u Ciebie światłowstręt?",
    answer: "Nie",
    entry: "Neguje światłowstręt",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie wymioty?",
    answer: "Tak",
    entry: "Zgłasza wymioty",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Czy występują u Ciebie wymioty?",
    answer: "Nie",
    entry: "Neguje wymioty",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Jednostronnie",
    entry: "Ból połowiczny, jednostronny",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Obustronnie",
    entry: "Ból obustronny",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "W okolicy potylicznej",
    entry: "Ból w okolicy potylicznej",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Opasująco całą głowę",
    entry: "Ból opasujący całą głowę",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "W skroniach",
    entry: "Ból lokalizuję w skroniach",
  },
  {
    ID: "1i5AtpeFKKldROGnUzipqgf5xB_6QfoYirUOkXo1cmwQ",
    title: "Ból głowy",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "ALL",
    entry: "Ból z lokalizacją: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Od kiedy występują objawy bólowe?",
    answer: "ALL",
    entry: "Początek dolegliwości: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Jak oceniasz nasilenie dolegliwości bólowych? (0 = Brak bólu; 10 = Ból jest nie do zniesienia)",
    answer: "ALL",
    entry: "Dolegliwości bólowe w skali VAS: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Określ wystąpienie bólu",
    answer: "Narastał stopniowo",
    entry: "Dolegliwości bólowe ze stopniowym narastaniem",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Określ wystąpienie bólu",
    answer: "Pojawił się nagle",
    entry: "Nagły charakter wystąpienia dolegliwości bólowych.",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Poniżej 37,5 °C",
    entry: "Gorączkę neguje",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Pomiędzy 37,5 a 38 °C (stan podgorączkowy)",
    entry: "Stan podgorączkowy",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Powyżej 38 °C (gorączka)",
    entry: "Gorączka >38st.",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Odcinek szyjny",
    entry: "Dolegliwości w odc. C kręgosłupa",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Odcinek piersiowy",
    entry: "Dolegliwości w odc. Th kręgosłupa",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Odcinek lędźwiowy",
    entry: "Dolegliwości w odc. L kręgosłupa",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "Odcinek krzyżowy?",
    entry: "Dolegliwości w odc. S kręgosłupa",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "Tak, do brzucha",
    entry: "Promieniowanie bólu do brzucha",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "Tak, do pachwiny",
    entry: "Promieniowanie bólu do pachwiny",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "Tak, do biodra",
    entry: "Promieniowanie bólu do biodra",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "Tak, przez biodro do kolana",
    entry: "Promieniowanie bólu do kolana przez staw biodrowy",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "Tak, przez całą kończynę dolną do kostki",
    entry: "Promieniowanie bólu wzdłuż kończyny dolnej",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "Nie, jest punktowy",
    entry: "Neguje promieniowanie, ból jest punktowy",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy ból gdzieś promieniuje?",
    answer: "ALL",
    entry: "Pacjent opisuje promieniowanie bólu: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia wypróżnień?",
    answer: "Tak, nietrzymanie moczu lub stolca",
    entry: "Potwierdza objaw alarmowy: nietrzymanie moczu/stolca",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia wypróżnień?",
    answer: "Tak, zaparcia",
    entry: "Potwierdza objaw alarmowy: brak możliwości wypróżnienia",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia wypróżnień?",
    answer:
      "Tak, często oddaję mocz lub mam dolegliwości bólowe przy mikcji (pieczenie, bolesne parcie)",
    entry: "Zgłasza objawy dyzuryczne (pieczenie, parcie)",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia wypróżnień?",
    answer: "Nie",
    entry: "Neguje zaburzenia pracy zwieraczy.",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Czy występują u Ciebie zaburzenia chodu lub osłabienie siły mięśniowej?",
    answer: "Tak, opada mi stopa",
    entry: "Potwierdza objaw alarmowy: opadanie stopy",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Czy występują u Ciebie zaburzenia chodu lub osłabienie siły mięśniowej?",
    answer: "Tak, jedną lub obie nogi mam słabsze",
    entry:
      "Potwierdza objaw alarmowy: osłabienie siły mięśniowej kończyn dolnych",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Czy występują u Ciebie zaburzenia chodu lub osłabienie siły mięśniowej?",
    answer: "Nie, choć trudno mi się poruszać ze względu na ból",
    entry:
      "Zgłasza trudności z poruszaniem z powodu nasilonych dolegliwości bólowych",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Czy występują u Ciebie zaburzenia chodu lub osłabienie siły mięśniowej?",
    answer: "Nie występują",
    entry: "Neguje zaburzenia chodu i zmianę siły mięśniowej.",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia czucia?",
    answer:
      'Tak, skóra na kończynie dolnej jest "zdrętwiała" (w obrębie pośladków, krocza, tylnej części uda, podudzia i stopy)',
    entry:
      "Potwierdza objaw alarmowy: zaburzenia czucia skórnego w zakresie kończyny dolnej",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia czucia?",
    answer: "Nie",
    entry: "Neguje zaburzenia czucia skórnego w zakresie kończyny dolnej",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie zaburzenia czucia?",
    answer: "ALL",
    entry: "Zgłasza dodatkowo: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Uraz kręgosłupa w przeszłości?",
    answer: "Tak",
    entry: "W wywiadzie w przeszłości uraz kręgosłupa",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Uraz kręgosłupa w przeszłości?",
    answer: "Nie",
    entry: "Neguje urazy kręgosłupa w przeszłości.",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jakikolwiek przebyty zabieg operacyjny w ostatnim czasie?",
    answer: "Tak, urologiczny",
    entry: "W wywiadzie po zabiegu urologicznym ( )",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jakikolwiek przebyty zabieg operacyjny w ostatnim czasie?",
    answer: "Tak, ginekologiczny",
    entry: "W wywiadzie po zabiegu ginekologicznym ( )",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jakikolwiek przebyty zabieg operacyjny w ostatnim czasie?",
    answer: "Nie",
    entry: "Neguje wywiad operacyjny (w ostatnim czasie)",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Jakikolwiek przebyty zabieg operacyjny w ostatnim czasie?",
    answer: "ALL",
    entry: "Zabiegi operacyjne: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Czy aktualnie chorujesz na nowotwór lub wystąpił on w przeszłości?",
    answer: "Tak",
    entry: "Potwierdza wywiad nowotworowy ( )",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question:
      "Czy aktualnie chorujesz na nowotwór lub wystąpił on w przeszłości?",
    answer: "Nie",
    entry: "Neguje wywiad nowotworowy.",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy występują u Ciebie dodatkowe objawy, takie jak:",
    answer: "ALL",
    entry: "Zgłasza dodatkowe objawy: [ANSWER]",
  },
  {
    ID: "1hxPeawPs8fSWSOGQ6PKUSCo36q5HTPGVIXgmGtSg4sc",
    title: "Ból kręgosłupa",
    question: "Czy wcześniej była przeprowadzana diagnostyka, taka jak:",
    answer: "ALL",
    entry: "Po diagnostyce obrazowej/specjalistycznej: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question:
      "Jak oceniasz nasilenie dolegliwości bólowych? (0 = Brak bólu; 10 = Ból jest nie do zniesienia)",
    answer: "ALL",
    entry: "Dolegliwości bólowe w skali VAS: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Od kiedy występują objawy bólowe?",
    answer: "ALL",
    entry: "Dolegliwości od: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Określ wystąpienie bólu w klatce piersiowej",
    answer: "Narastał stopniowo",
    entry: "Dolegliwości bólowe ze stopniowym narastaniem",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Określ wystąpienie bólu w klatce piersiowej",
    answer: "Pojawił się nagle",
    entry: "Nagły charakter wystąpienia dolegliwości bólowych.",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Gdzie odczuwasz dolegliwości?",
    answer: "ALL",
    entry: "Główna lokalizacja dolegliwości: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Jaki jest obszar bólu?",
    answer: "Rozlany (wielkości otwartej dłoni)",
    entry: "Ból o charakterze rozlanym",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Jaki jest obszar bólu?",
    answer: "Pomiędzy rozlanym, a punktowym",
    entry: "Ból trudny do określenia, między rozlanym a punktowym",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Jaki jest obszar bólu?",
    answer: "Punktowy",
    entry: "Ból o charakterze punktowym",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Jaki jest obszar bólu?",
    answer: "ALL",
    entry: "Ból o charakterze: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Wskaż w którą stronę promieniuje ból",
    answer: "ALL",
    entry: "Ból promieniuje: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Czy ból promieniuje do innych części ciała?",
    answer: "Nie",
    entry: "Neguje promieniowanie bólu",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Określ charakter tego bólu",
    answer: "ALL",
    entry: "Charakter bólu: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Czy występują objawy dodatkowe?",
    answer: "ALL",
    entry: "Dodatkowo zgłasza: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Czy w przeszłości zdarzały Ci się podobne dolegliwości?",
    answer: "Tak",
    entry: "Podobne dolegliwości zdarzały się w przeszłości",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Czy w przeszłości zdarzały Ci się podobne dolegliwości?",
    answer: "Nie",
    entry: "Dolegliwości pierwszorazowo w życiu",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Podaj wartość zmierzonego ciśnienia (np. 120 / 79)",
    answer: "ALL",
    entry: "Wartość zmierzonego ciśnienia: [ANSWER]",
  },
  {
    ID: "1xj2b23s9tBiUcgYTH_tNFYTLUlipzzIW9QGJFpnGIH8",
    title: "Ból w klatce piersiowej",
    question: "Czy masz aktualnie możliwość zmierzenia ciśnienia teraz?",
    answer: "Nie",
    entry: "Brak możliwości zmierzenia aktualnego ciśnienia",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Czy pozostajesz pod stałą opieką lekarza psychiatry?",
    answer: "Tak",
    entry: "Pacjent pod opieką lekarza psychiatry.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Czy pozostajesz pod stałą opieką lekarza psychiatry?",
    answer: "Nie",
    entry: "Pacjent bez wywiadu stałego leczenia psychiatrycznego",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Data rozpoznania schorzenia",
    answer: "ALL",
    entry: "Choruje od: [ANSWER]",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Data ostatniej konsultacji psychiatrycznej",
    answer: "ALL",
    entry: "Ostatnio konsultowany: [ANSWER]",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question:
      "Czy od ostatniej konsultacji u psychiatry Twój psychiczny stan zdrowia się zmienił?",
    answer: "Tak, poprawił się",
    entry:
      "Zgłasza poprawę stanu zdrowia psychicznego od czasu ostatniej wizyty lekarskiej.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question:
      "Czy od ostatniej konsultacji u psychiatry Twój psychiczny stan zdrowia się zmienił?",
    answer: "Tak, pogorszył się",
    entry:
      "Zgłasza pogorszenie stanu zdrowia psychicznego od czasu ostatniej wizyty lekarskiej.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question:
      "Czy od ostatniej konsultacji u psychiatry Twój psychiczny stan zdrowia się zmienił?",
    answer: "Nie, pozostaje bez zmian",
    entry: "Nie zgłasza zmian w stanie zdrowia psychicznego.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Czy występuje u Ciebie uzależnienie?",
    answer: "Tak, od alkoholu",
    entry: "Zgłasza uzależnienie od alkoholu",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Czy występuje u Ciebie uzależnienie?",
    answer: "Tak, od środków psychoaktywnych lub narkotyków",
    entry: "Zgłasza uzależnienie od SPA.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Czy występuje u Ciebie uzależnienie?",
    answer: "Tak, od leków",
    entry: "Zgłasza uzależnienie od leków.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question: "Czy występuje u Ciebie uzależnienie?",
    answer: "Nie występuje",
    entry: "Neguje uzależnienie od alkoholu, leków i SPA.",
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question:
      "Czy aktualnie odczuwasz myśli rezygnacyjne, zdarzają Ci się myśli samobójcze?",
    answer: "Tak",
    entry: 'Potwierdza myśli rezygnacyjne/myśli "S',
  },
  {
    ID: "1VY7FwsKEB_mMuffKvj_6tbEcRUX857QCCHMuWDri-Fs",
    title: "Choroby psychiczne",
    question:
      "Czy aktualnie odczuwasz myśli rezygnacyjne, zdarzają Ci się myśli samobójcze?",
    answer: "Nie",
    entry: 'Neguje myśli rezygnacyjne/myśli "S',
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question:
      "Jakiekolwiek zabiegi stomatologiczne w ostatnim czasie? (np. Usunięcie zęba, leczenie kanałowe)",
    answer: "Tak",
    entry: "W wywiadzie zabieg stomatologiczny ( )",
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question:
      "Jakiekolwiek zabiegi stomatologiczne w ostatnim czasie? (np. Usunięcie zęba, leczenie kanałowe)",
    answer: "Nie",
    entry: "Neguje zabiegi stomatologiczne w ostatnim czasie.",
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question: "Jak długo utrzymuje się krwawienie z dziąseł?",
    answer: "ALL",
    entry: "Dolegliwości od: [ANSWER]",
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question: "Czy występują któreś z poniższych objawów?",
    answer: "ALL",
    entry: "Dodatkowo zgłasza: [ANSWER]",
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question: "Czy mycie zębów w ostatnim czasie mogło spowodować uraz?",
    answer: "Tak",
    entry: "Możliwy uraz dziąseł podczas szczotkowania",
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question: "Czy mycie zębów w ostatnim czasie mogło spowodować uraz?",
    answer: "Nie",
    entry: "Neguje możliwość wystąpienia urazu podczas szczotkowania.",
  },
  {
    ID: "1vI80hNOtgVTkiRvsPmkV63Njfv_gQ3wq5XAXtUXBlTc",
    title: "Krwawienie z dziąseł",
    question: "Czy stosujesz leczenie objawowe? (np. Aspiryna lub Polopiryna)",
    answer: "Tak",
    entry: "Potwierdza stosowanie salicylanów w ostatnim czasie.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy podczas oddawania moczu występuje ból?",
    answer: "Tak",
    entry: "Zgłasza dolegliwości bólowe podczas mikcji.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy podczas oddawania moczu występuje ból?",
    answer: "Nie",
    entry: "Neguje dolegliwości bólowe podczas mikcji.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question:
      "Jak oceniasz nasilenie dolegliwości bólowych? (0 = Brak bólu; 10 = Ból jest nie do zniesienia)",
    answer: "ALL",
    entry: "Ból w skali VAS: [ANSWER]",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Poniżej 37,5 °C",
    entry: "Gorączkę neguje",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Pomiędzy 37,5 a 38 °C (stan podgorączkowy)",
    entry: "Obecny stan podgorączkowy",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Jaka jest obecnie temperatura Twojego ciała?",
    answer: "Powyżej 38 °C (gorączka)",
    entry: "Obecna gorączka.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy podczas oddawania moczu występuje pieczenie?",
    answer: "Tak",
    entry: "Zgłasza pieczenie podczas mikcji",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy podczas oddawania moczu występuje pieczenie?",
    answer: "Nie",
    entry: "Neguje pieczenie podczas mikcji.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy występuje krwiomocz?",
    answer: "Tak, mocz jest nieco zabarwiony krwią",
    entry: "Zgłasza krwinkomocz",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy występuje krwiomocz?",
    answer: "Tak, mocz jest krwistoczerwony",
    entry: "Zgłasza krwiomocz",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy występuje krwiomocz?",
    answer: "Nie",
    entry: "Neguje obecność krwi w moczu",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy występuje ból okolicy nerek?",
    answer: "Tak",
    entry: "Potwierdza dolegliwości bólowe w rzucie nerek",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy występuje ból okolicy nerek?",
    answer: "Nie",
    entry: "Neguje bóle okolicy nerek.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy występują któryś z poniższych objawów?",
    answer: "ALL",
    entry: "Dodatkowo zgłasza: [ANSWER]",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy zakażenia układu moczowego często nawracają?",
    answer: "Tak",
    entry: "W wywiadzie nawracające ZUMy",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy zakażenia układu moczowego często nawracają?",
    answer: "Nie",
    entry: "Neguje nawroty ZUM.",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy zastosowano jakieś leki objawowe do tej pory?",
    answer: "Tak, przeciwgorączkowe",
    entry: "Przyjęte leki: przeciwgorączkowe",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy zastosowano jakieś leki objawowe do tej pory?",
    answer: "Tak, Furaginę",
    entry: "Przyjęte leki: Furagina",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy zastosowano jakieś leki objawowe do tej pory?",
    answer: "Nie",
    entry: "Nie przyjęto leków objawowych",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy zastosowano jakieś leki objawowe do tej pory?",
    answer: "ALL",
    entry: "Przyjęto leki: [ANSWER]",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy po przyjętych lekach nastąpiła poprawa?",
    answer: "Tak",
    entry: "Z poprawą",
  },
  {
    ID: "1QSvixl2KpVNjvzEfwnbbqNQx9FTf0jjAp105mugv5XM",
    title: "Objawy dyzuryczne",
    question: "Czy po przyjętych lekach nastąpiła poprawa?",
    answer: "Nie",
    entry: "Bez poprawy.",
  },
  {
    ID: "1GRr8FPc4943BiU-2MIh7s8-Na9yeWHydkvoJbwIl6Ic",
    title: "Poparzenie",
    question: "Kiedy doszło do oparzenia?",
    answer: "ALL",
    entry: "Poprzenie w dniu: [ANSWER]",
  },
  {
    ID: "1GRr8FPc4943BiU-2MIh7s8-Na9yeWHydkvoJbwIl6Ic",
    title: "Poparzenie",
    question: "Jaką substancją doszło do oparzenia?",
    answer: "ALL",
    entry: "Substancja uszkadzająca skórę:[ANSWER]",
  },
  {
    ID: "1GRr8FPc4943BiU-2MIh7s8-Na9yeWHydkvoJbwIl6Ic",
    title: "Poparzenie",
    question: "Jak część ciała została oparzona?",
    answer: "ALL",
    entry: "Uszkodzenia ciała:[ANSWER]",
  },
  {
    ID: "1GRr8FPc4943BiU-2MIh7s8-Na9yeWHydkvoJbwIl6Ic",
    title: "Poparzenie",
    question: "Czy wystąpiły bąble z wydzieliną surowiczą?",
    answer: "Tak",
    entry: "Po oparzeniu bąble z treścią surowiczą",
  },
  {
    ID: "1GRr8FPc4943BiU-2MIh7s8-Na9yeWHydkvoJbwIl6Ic",
    title: "Poparzenie",
    question: "Czy wystąpiły bąble z wydzieliną surowiczą?",
    answer: "Nie",
    entry: "Neguje zmiany skórne pod postacią bąbli.",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Podaj datę i orientacyjną godzinę stosunku seksualnego",
    answer: "ALL",
    entry: "Data możliwego zapłodnienia: [ANSWER]",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Podaj datę ostatniej miesiączki",
    answer: "ALL",
    entry: "Data ostatniej miesiączki: [ANSWER]",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy jesteś w ciąży?",
    answer: "Nie",
    entry: "Ciążę neguje",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy jesteś w ciąży?",
    answer: "Tak",
    entry: "Ciążę potwierdza",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy wykonałaś test ciążowy?",
    answer: "Tak, wynik jest negatywny",
    entry: "Wykonany test ciążowy - negatywny",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy wykonałaś test ciążowy?",
    answer: "Nie",
    entry: "Nie wykonywała testu ciążowego",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy jesteś pod opieką lekarza ginekologa?",
    answer: "Tak",
    entry: "Pod stałą opieką ginekologiczną",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy jesteś pod opieką lekarza ginekologa?",
    answer: "Nie",
    entry: "Neguje stałą opiekę ginekologiczną.",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question:
      "Czy kiedykolwiek lekarz poinformował Cię że nie możesz przyjmować antykoncepcji doustnej?",
    answer: "Nie",
    entry:
      "Neguje przekazanie przez ginekologa przeciwskazań do przyjmowania antykoncepcji.",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question:
      "Czy kiedykolwiek lekarz poinformował Cię że nie możesz przyjmować antykoncepcji doustnej?",
    answer: "Tak",
    entry:
      "Potwierdza przekazanie przez ginekologa przeciwskazań do przyjmowania antykoncepcji.",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy chorujesz na choroby przewlekłe?",
    answer: "Nie",
    entry: "Choroby przewlekłe neguje",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Na jakie choroby przewlekłe chorujesz?",
    answer: "ALL",
    entry: "Choroby przewlekłe: [ANSWER]",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy przyjmujesz leki na stałe?",
    answer: "Nie",
    entry: "Neguje leki stałe",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy przyjmujesz leki na stałe?",
    answer: "Tak",
    entry: "Przyjmuje leki stałe.",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Jakie leki przyjmujesz na stałe?",
    answer: "ALL",
    entry: "Leki stałe: [ANSWER]",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Czy jesteś uczulona na leki?",
    answer: "Nie",
    entry: "Alergie na leki neguje",
  },
  {
    ID: "1BZU3jS3g2ZmFaE3ZKUdXa9u1qvTdohf-jd3uVajeQkA",
    title: "Wizyta awaryjna",
    question: "Na jakie leki jesteś uczulona?",
    answer: "ALL",
    entry: "Alergie na leki: [ANSWER]",
  },
];

import { Defined } from "@jutro/types";
import { SolidCircleAlertIcon, SolidCircleCheckIcon, Tag } from "@jutro/ui";
import dayjs from "dayjs";
import {
  DoctorMSConfirmationType,
  DoctorMSPatientTagEntity,
} from "lib/graphql/megaSchema";
import {
  SourceTag,
  StatusColor,
} from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/types";
import {
  getColor,
  getDayDiff,
} from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/utils";

const confirmationTypeMap: Record<DoctorMSConfirmationType, string> = {
  DOCUMENTATION: "Potwierdzone dokumentacją medyczną",
  EXAMINATION: "Potwierdzone rozpoznaniem lekarza",
  INTERVIEW: "Z wywiadu z pacjentem",
};

type ConfirmationIconProps = {
  color: StatusColor;
};

export const ConfirmationIcon = ({ color }: ConfirmationIconProps) => {
  if (color === "warning") {
    return (
      <div className="text-jutro-new-orange-500">
        <SolidCircleAlertIcon size="sm" />
      </div>
    );
  }

  return (
    <div className="text-jutro-new-warm-gray-500">
      <SolidCircleCheckIcon size="sm" />
    </div>
  );
};

type ConfirmationTextProps = {
  confirmationType: SourceTag["confirmationType"];
  createdAt: string;
};

export const ConfirmationText = ({
  confirmationType,
  createdAt,
}: ConfirmationTextProps) => {
  if (!confirmationType) {
    return <div>Brak potwierdzenia</div>;
  }

  if (confirmationType === "EXAMINATION") {
    const formattedDate = dayjs(createdAt).format("DD.MM.YYYY");

    return (
      <div>
        {confirmationTypeMap[confirmationType]} na wizycie z dnia{" "}
        {formattedDate}
      </div>
    );
  }

  return <div>{confirmationTypeMap[confirmationType]}</div>;
};

type Props = {
  sourceTag?: DoctorMSPatientTagEntity | null;
};

const confirmationSourceMap: Record<
  Defined<ConfirmationTextProps["confirmationType"]>,
  string
> = {
  DOCUMENTATION: "Dokumentacja medyczna",
  EXAMINATION: "Potwierdzone rozpoznaniem",
  INTERVIEW: "Z wywiadu z pacjentem",
};

export const Confirmation = ({ sourceTag }: Props) => {
  if (!sourceTag) {
    return null;
  }

  const { expirationAt, confirmationType, type } = sourceTag;

  const dayDiff = getDayDiff(expirationAt);

  const color = getColor(dayDiff, confirmationType, type);

  return (
    <div className="flex items-center gap-2">
      <Tag
        color="green"
        text={
          confirmationType
            ? confirmationSourceMap[confirmationType]
            : "Brak potwierdzenia"
        }
      />

      <ConfirmationIcon color={color} />
    </div>
  );
};

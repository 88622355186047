import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useNavigate } from "react-router";
import { currentUserAtom } from "lib/atoms/auth";
import { useDoctorForceCreateVisitMutation } from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";

export const useCreateProcedureVisit = () => {
  const navigate = useNavigate();
  const [forceCreateVisitMutation] = useDoctorForceCreateVisitMutation();
  const [currentUser] = useAtom(currentUserAtom);
  const createProcedureVisitAndRedirect = async ({
    patientId,
    organizationId,
  }: {
    patientId: string;
    organizationId: string;
  }) => {
    if (!currentUser) {
      return;
    }

    try {
      const { data } = await forceCreateVisitMutation({
        variables: {
          patientId,
          input: {
            doctorId: currentUser.id,
            duration: 10,
            organizationId,
            plannedStart: dayjs(),
            slotType: "PROCEDURES",
          },
        },
      });

      if (!data) {
        return;
      }

      const { doctorForceCreateVisit } = data;

      navigate(`/visits/${doctorForceCreateVisit.id}`);
    } catch (e) {
      toaster.error("Wystąpił błąd podczas tworzenia wizyty zabiegowej");
    }
  };

  return { createProcedureVisitAndRedirect };
};

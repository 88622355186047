import { FieldError } from "react-hook-form";

type Props = {
  error?: FieldError;
  pickedGender: string | undefined;
  disabled?: boolean;
  onChange?: (...event: string[]) => void;
};

export const GenderSelect = ({
  error,
  pickedGender,
  disabled,
  onChange,
}: Props) => {
  const genderArray = ["Żeńska", "Męska"];
  return (
    <ul className="flex gap-2">
      {genderArray.map((gender) => (
        <li
          key={gender}
          className={`font-paragraph-1 flex flex-1 items-center justify-center rounded-lg border-[1px] px-4 py-2 text-jutro-new-warm-gray-800 ${
            error
              ? "border-jutro-new-rose-600"
              : "border-jutro-new-warm-gray-300"
          } ${
            gender === pickedGender
              ? disabled
                ? "bg-jutro-new-warm-gray-100"
                : "bg-jutro-new-blue-800 text-white"
              : "bg-white"
          } ${
            disabled
              ? "cursor-not-allowed hover:border-jutro-new-warm-gray-300"
              : "cursor-pointer hover:border-jutro-new-blue-800"
          }`}
          onClick={() => {
            if (disabled || !onChange) return;
            onChange(gender);
          }}
        >
          {gender}
        </li>
      ))}
    </ul>
  );
};

import { SearchedDrug } from "views/Visit/RightPanel/NewPrescription/types";

export const getDrugDescription = (dose: string, form: string, box: string) => {
  if (!dose) {
    return `${form} - ${box}`;
  }

  return `${dose} / ${form} ${box}`;
};

type Props = {
  searchedDrug?: SearchedDrug;
};

export const DrugLabel = ({ searchedDrug }: Props) => {
  if (!searchedDrug) {
    return null;
  }

  const { name, dosage, form, box } = searchedDrug;

  return (
    <div className="flex items-center divide-x">
      <div className="font-paragraph-2 pr-2 text-black">{name}</div>
      <div className="font-paragraph-2 pl-2 text-jutro-new-warm-gray-600">
        {getDrugDescription(dosage, form, box)}
      </div>
    </div>
  );
};

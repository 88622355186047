import { Option } from "@jutro/types";
import { BinIcon, Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { RoundedWrapper } from "components/new/RoundedWrapper";
import { selectedVisitTabAtom } from "lib/atoms";
import { DoctorMSDiagnosis, DoctorVisitQuery } from "lib/graphql/megaSchema";
import { useComplexIcd10Search } from "lib/hooks/useAsyncOptions";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { CustomSelectStyles, Maybe } from "lib/types";
import { mainDiagnosisInterviewAtom } from "views/Visit/RightPanel/Visit/atoms";
import { SelectLabel } from "./SelectLabel";

const customStyles: CustomSelectStyles = {
  container: (base, { isFocused, isDisabled, hasValue }) => ({
    ...base,
    minHeight: 57,
    padding: "7px 8px",
    marginBottom: isFocused ? "-1px" : "0px",
    borderWidth: isFocused && !hasValue ? 3 : 1,
    borderRadius: 8,
    borderColor: isFocused && !hasValue ? "#005fcc" : "#C7C7CC",
    backgroundColor: isDisabled ? "#C9C6C5" : "white",
    caretColor: hasValue ? "transparent" : "black",
  }),
  control: (base, { isDisabled, hasValue }) => ({
    ...base,
    borderWidth: hasValue ? 1 : 0,
    borderRadius: 8,
    borderColor: "#C9C6C5",
    backgroundColor: isDisabled ? "#C9C6C5" : "white",
    cursor: hasValue ? "not-allowed" : "text",
    boxShadow: "0",
    "&:hover": {
      borderColor: "transparent",
    },
  }),
  input: (base) => ({
    ...base,
    margin: "0px",
    padding: "0px",
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.isFocused ? "none" : "block",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px",
    paddingRight: 4,
  }),
  singleValue: (base) => ({
    ...base,
    whiteSpace: "pre-line",
    margin: "0px",
    padding: "8px 0 8px 8px",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 30,
    padding: 0,
    marginTop: 10,
    borderRadius: 8,
  }),
  menuList: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  option: (base) => ({
    ...base,
    margin: 0,
    cursor: "pointer",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    "&:last-of-type": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  }),
};

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

type SelectBaseProps = {
  header: string;
  disabled: boolean;
  tags?: Patient["tags"] | undefined;
  ctaCopy?: string;
};

type SelectProps = {
  onChange: (option: Option<string, JSX.Element> | null) => void;
  onClear?: () => void;
  value: Maybe<string>;
} & SelectBaseProps;

export const Icd10SelectMdx = ({
  header,
  disabled,
  tags,
  ctaCopy = "",
}: SelectBaseProps) => {
  const { watch, control } = useFormContext();
  const { searchIcd10Options } = useComplexIcd10Search(tags, []);

  const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);
  const [, setMainDiagnosis] = useAtom(mainDiagnosisInterviewAtom);

  const doctorDiagnosis = watch("doctorDiagnosis") as
    | DoctorMSDiagnosis[]
    | undefined;

  const value = useMemo(
    () => doctorDiagnosis?.find((d) => d.grade === "MAIN")?.code,
    [doctorDiagnosis],
  );
  const { addTile } = useTileSystem();
  const valueFieldIndex = useMemo(
    () => doctorDiagnosis?.findIndex((d) => d.grade === "MAIN"),
    [doctorDiagnosis],
  );

  const tag = tags?.find((tag) => {
    return tag.data.name === value;
  });

  const { append, update, remove } = useFieldArray({
    name: "doctorDiagnosis",
    control,
  });

  const handleMainDiagnosisChange = (e: Option<string, JSX.Element> | null) => {
    if (valueFieldIndex !== undefined && valueFieldIndex !== -1) {
      update(valueFieldIndex, { code: e?.value, grade: "MAIN" });
      return;
    }

    append({ code: e?.value, grade: "MAIN" });
  };

  useEffect(() => {
    setMainDiagnosis(value || null);

    return () => {
      setMainDiagnosis(null);
    };
  }, [value]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="font-paragraph-2 flex w-full justify-between text-jutro-new-warm-gray-800">
          <span>{header}</span>
          <span
            className="font-paragraph-2 cursor-pointer text-jutro-new-blue-800"
            onClick={() => {
              setSelectedVisitTab("prescription");
              addTile("visit-ordered-drugs", TileColumn.Center);
            }}
          >
            {ctaCopy}
          </span>
        </div>
      </div>
      <AsyncSelect
        isClearable
        blurInputOnSelect
        className="group font-paragraph-1 flex-1"
        placeholder="Wpisz nazwę choroby lub kod ICD10"
        noOptionsMessage={(e) =>
          e.inputValue === "" ? null : "Brak rozpoznań do wyboru"
        }
        loadingMessage={() => "Ładowanie..."}
        value={
          value
            ? {
                value,
                label: (
                  <div>
                    <SelectLabel value={value} chronicDiseaseTag={tag} />
                  </div>
                ),
              }
            : null
        }
        onChange={handleMainDiagnosisChange}
        loadOptions={searchIcd10Options}
        styles={customStyles}
        isDisabled={disabled}
        components={{
          ClearIndicator: () => (
            <Button
              variant="negativeGhost"
              size="condensed"
              type="button"
              full={false}
              onClick={() => {
                if (valueFieldIndex === undefined || valueFieldIndex === -1) {
                  return;
                }

                remove(valueFieldIndex);
              }}
              icon={<BinIcon />}
              label="Usuń"
              tooltipPosition="top"
            />
          ),
        }}
      />
    </div>
  );
};

export const Icd10Select = ({
  header,
  disabled,
  value,
  tags,
  ctaCopy = "",
  onChange,
  onClear,
}: SelectProps) => {
  const { searchIcd10Options } = useComplexIcd10Search(tags, []);
  const { addTile } = useTileSystem();
  const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);

  const tag = tags?.find((tag) => {
    return tag.data.name === value;
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="font-paragraph-2 flex w-full justify-between text-jutro-new-warm-gray-800">
          <span>{header}</span>
          <span
            className="font-paragraph-2 cursor-pointer text-jutro-new-blue-800"
            onClick={() => {
              setSelectedVisitTab("prescription");
              addTile("visit-ordered-drugs", TileColumn.Center);
            }}
          >
            {ctaCopy}
          </span>
        </div>
      </div>
      <AsyncSelect
        isClearable
        blurInputOnSelect
        className="group font-paragraph-1 flex-1"
        placeholder="Wpisz nazwę choroby lub kod ICD10"
        noOptionsMessage={(e) =>
          e.inputValue === "" ? null : "Brak rozpoznań do wyboru"
        }
        loadingMessage={() => "Ładowanie..."}
        value={
          value
            ? {
                value,
                label: (
                  <div>
                    <SelectLabel value={value} chronicDiseaseTag={tag} />
                  </div>
                ),
              }
            : null
        }
        onChange={onChange}
        loadOptions={searchIcd10Options}
        styles={customStyles}
        isDisabled={disabled}
        components={{
          ClearIndicator: () => (
            <Button
              variant="negativeGhost"
              size="condensed"
              type="button"
              full={false}
              onClick={onClear}
              icon={<BinIcon />}
              label="Usuń"
              tooltipPosition="top"
            />
          ),
        }}
      />
    </div>
  );
};

export const MultipleSelect = ({
  disabled,
  tags,
}: {
  disabled: boolean;
  tags?: Patient["tags"] | undefined;
}) => {
  const { control, watch } = useFormContext<{
    doctorDiagnosis: Visit["doctorDiagnosis"];
  }>();

  const { doctorDiagnosis } = watch();

  const { append, remove } = useFieldArray({
    name: "doctorDiagnosis",
    control,
  });

  const supplementaryDiagnosis = doctorDiagnosis?.filter((e) => {
    if (!e?.code) {
      return false;
    }

    return e.grade === "SUPPLEMENTARY";
  });

  const { searchIcd10Options } = useComplexIcd10Search(
    tags,
    supplementaryDiagnosis,
  );

  const isListDisplayable =
    supplementaryDiagnosis && supplementaryDiagnosis.length > 0;

  const handleSupplementaryDiagnosisChange = (
    e: Option<string, JSX.Element> | null,
  ) => {
    if (e === null) {
      return;
    }

    const diagnosis = {
      code: e.value,
      grade: "SUPPLEMENTARY" as const,
    };

    append(diagnosis);
  };

  return (
    <div
      className={
        disabled
          ? "flex flex-col gap-2 bg-jutro-new-warm-gray-300"
          : "flex flex-col gap-2"
      }
    >
      {isListDisplayable && (
        <RoundedWrapper>
          <div>
            {supplementaryDiagnosis.map((e) => {
              if (!e || !e.code) {
                return null;
              }

              const tag = tags?.find((tag) => {
                return tag.data.name === e.code;
              });

              return (
                <div
                  key={e.code}
                  className="mb-2 flex items-center justify-between gap-1 rounded-[8px] border-[1px] border-jutro-new-warm-gray-100 py-2 pl-2 last:mb-0"
                >
                  <div className="flex flex-1 items-center justify-between gap-2">
                    <SelectLabel value={e.code} chronicDiseaseTag={tag} />
                  </div>
                  {!disabled && (
                    <Button
                      full={false}
                      icon={<BinIcon />}
                      variant="negativeGhost"
                      label="Usuń"
                      tooltipPosition="top"
                      size="condensed"
                      onClick={() => {
                        const valueFieldIndex = doctorDiagnosis?.findIndex(
                          (d) =>
                            d?.grade === "SUPPLEMENTARY" && d.code === e.code,
                        );

                        if (
                          valueFieldIndex === undefined ||
                          valueFieldIndex === -1
                        ) {
                          return;
                        }

                        remove(valueFieldIndex);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </RoundedWrapper>
      )}

      <AsyncSelect
        key={supplementaryDiagnosis?.length}
        blurInputOnSelect
        className="font-paragraph-1 min-h-[54px] items-center gap-2 rounded-[8px] border-[1px] border-jutro-new-warm-gray-300 px-2 py-[7px]"
        placeholder="Wpisz nazwę choroby lub kod ICD10"
        noOptionsMessage={() => "Brak rozpoznań do wyboru"}
        loadingMessage={() => "Ładowanie..."}
        value={null}
        isDisabled={disabled}
        onChange={handleSupplementaryDiagnosisChange}
        loadOptions={searchIcd10Options}
        defaultOptions
        styles={customStyles}
      />
    </div>
  );
};

import { useAtom } from "jotai";
import * as R from "ramda";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { TagForm } from "components/TagFormNew/types";
import {
  DoctorGetDocumentsDocument,
  DoctorMedicineSubtitlesTagsDocument,
  DoctorPatientProfileVisitDocument,
  DoctorPatientTagsDocument,
  DoctorRegularDrugsDocument,
  DoctorVisitDocument,
  useDoctorCreatePatientTagMutation,
  useDoctorDeletePatientTagMutation,
  useDoctorUpdatePatientTagMutation,
} from "lib/graphql/megaSchema";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { toaster } from "lib/tools/toaster";
import { TagFormSchema } from "../../hooks";
import { chosenDocumentsAtom, hasDocumentErrorAtom } from "../atoms";

const refetchQueries = [
  DoctorPatientProfileVisitDocument,
  DoctorVisitDocument,
  DoctorGetDocumentsDocument,
  DoctorMedicineSubtitlesTagsDocument,
  DoctorPatientTagsDocument,
  DoctorRegularDrugsDocument,
];

export const useSubmitSection = (tagForm: TagForm) => {
  const { getValues } = useFormContext<TagFormSchema>();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [createPatientTag, { loading: createPatientTagLoading }] =
    useDoctorCreatePatientTagMutation({ refetchQueries });

  const [updatePatientTag, { loading: updatePatientTagLoading }] =
    useDoctorUpdatePatientTagMutation({ refetchQueries });

  const [deletePatientTag, { loading: deletePatientTagLoading }] =
    useDoctorDeletePatientTagMutation({
      refetchQueries,
    });

  const { removeTile } = useTileSystem();
  const [, setHasDocumentError] = useAtom(hasDocumentErrorAtom);

  const [chosenDocuments, setChosenDocuments] = useAtom(chosenDocumentsAtom);

  const handleDelete = async () => {
    if (!tagForm.tag?.id) {
      return;
    }

    await deletePatientTag({
      variables: { id: tagForm.tag.id },
    })
      .then(() => {
        toaster.success("Poprawnie usunięto tag");
        removeTile(tagForm.id);
      })
      .catch(() => {
        toaster.error("Nie udało się usunąć taga");
      });
  };

  const onSubmit = async (formData: TagFormSchema) => {
    const { type, confirmationType } = formData;

    const { data, ...rest } = formData;

    // @ts-ignore
    const dataWithOmittedDrug = R.omit(["drug"], { data });

    const dataToSend = { ...rest, ...dataWithOmittedDrug };

    if (!type) {
      return;
    }

    if (confirmationType === "DOCUMENTATION" && chosenDocuments.length === 0) {
      setHasDocumentError(true);
      return;
    }

    if (tagForm.type === "create") {
      await createPatientTag({
        variables: {
          fileIds:
            confirmationType === "DOCUMENTATION"
              ? chosenDocuments.map(({ id }) => id)
              : [],
          // @ts-ignore
          input: { ...dataToSend, type },
        },
      })
        .then(() => {
          toaster.success("Poprawnie utworzono taga");
          removeTile(tagForm.id);
        })
        .catch(() => {
          toaster.error("Nie udało się utworzyć taga");
        });
      return;
    }

    if (!tagForm.tag?.id) {
      return;
    }

    await updatePatientTag({
      variables: {
        id: tagForm.tag.id,
        fileIds:
          confirmationType === "DOCUMENTATION"
            ? chosenDocuments.map(({ id }) => id)
            : [],
        // @ts-ignore
        input: { ...dataToSend, type },
      },
    })
      .then(() => {
        toaster.success("Poprawnie edytowano taga");
        removeTile(tagForm.id);
      })
      .catch(() => {
        toaster.error("Nie udało się edytować taga");
      });
  };

  const handleOnSubmit = () => {
    if (
      getValues().confirmationType === "DOCUMENTATION" &&
      chosenDocuments.length === 0
    ) {
      setHasDocumentError(true);
    }
  };

  return {
    createPatientTag,
    createPatientTagLoading,
    updatePatientTag,
    updatePatientTagLoading,
    handleDelete,
    deletePatientTagLoading,
    onSubmit,
    chosenDocuments,
    setChosenDocuments,
    handleOnSubmit,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
  };
};

import dayjs from "dayjs";
import { useMemo } from "react";
import { DoctorGetPatientQuery } from "lib/graphql/megaSchema";
import { WORK_HOURS } from "lib/tools/workHours";

export const useIsAfterWorkPatient = (
  patient: DoctorGetPatientQuery["doctorPatient"],
) => {
  return useMemo(() => {
    if (!patient?.lastPatientMessageBlock?.iso) return false;
    const time = dayjs(patient.lastPatientMessageBlock.iso);
    const endTime = dayjs().hour(WORK_HOURS.END);
    return time.isSame(endTime, "hour") || time.isAfter(endTime, "hour");
  }, [patient]);
};

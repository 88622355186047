import { groupBy } from "ramda";
import { CurrentPatient } from "components/PatientDocs/types";

type Answer = {
  value: string | number;
  title: string;
};

const isUnkownArray = (x: unknown): x is unknown[] => Array.isArray(x);

const parseAnswerText = (value: any) => {
  if (typeof value !== "string") return "";
  return value.replaceAll(/(\[\[(.*)\]\])/g, "");
};

const handleAnswerValue = (answerValue: Pick<Answer, "value">) => {
  if (isUnkownArray(answerValue)) {
    return answerValue
      .map((i) => parseAnswerText(i))
      .filter((i) => i)
      .join(", ");
  }

  return parseAnswerText(answerValue);
};

const groupAnswers = groupBy((e: Answer) => {
  const surveyTitle = e.title.match(/(\[\[(.*)\]\])/);

  return surveyTitle?.length
    ? surveyTitle[0].replaceAll("[", "").replaceAll("]", "")
    : "Ankieta wstępna";
});

export const parseAnswers = (patient: CurrentPatient) => {
  if (!patient.oneTimeSurvey) return {};

  const answers = Object.keys(patient.oneTimeSurvey).map((key) => {
    const answerValue = patient.oneTimeSurvey[key];

    return {
      title: parseAnswerText(key),
      value: handleAnswerValue(answerValue),
    };
  });

  return groupAnswers(answers);
};

export const handlePOChP = (parsedAnswers: Record<string, Answer[]>) => {
  if (!("POChP" in parsedAnswers)) return parsedAnswers;

  const sumOfPoints = parsedAnswers["POChP"].reduce((agg, obj) => {
    if (typeof obj.value !== "number") return agg;
    if (obj.title.indexOf("(0 =") === -1) return agg;
    return agg + obj.value;
  }, 0);

  return {
    ...parsedAnswers,
    POChP: [
      ...parsedAnswers.POChP,
      { title: "[[POChP]] Suma punktów:", value: sumOfPoints },
    ],
  };
};

export const answerPriorityMap: Record<string, number> = {
  "Czy leczysz się na jakieś choroby przewlekłe?": 8,
  "Czy przyjmujesz leki na stałe?": 7,
  "Podaj nazwy leków, które przyjmujesz na stałe": 6,
  "Czy masz alergie na leki?": 5,
  "Czy jesteś w ciąży?": 4,
  "Czy jesteś w ciąży? (Jeśli nie dotyczy, wybierz NIE)": 3,
  "Czy karmisz piersią? ": 2,
  "Czy karmisz piersią? (Jeśli nie dotyczy, wybierz NIE)": 1,
};

export type ReferralItemProps = {
  title: string;
  description: string;
};

export const ReferralItem = ({ title, description }: ReferralItemProps) => {
  return (
    <div className="flex flex-col">
      <span className="font-semibold">{title}</span>
      <span>{description}</span>
    </div>
  );
};

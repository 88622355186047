import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { NewsPostDetail } from "components/News/Post/Detail";
import { ScreenLoader } from "components/new";
import {
  DoctorGetMySeenPostsDocument,
  useDoctorGetMySeenPostsQuery,
  useDoctorMarkPostsAsSeenMutation,
} from "lib/graphql/megaSchema";
import { usePost } from "lib/hooks/news";

export const PostView = () => {
  const { slug } = useParams<{ slug: string }>();

  const [markPostsAsSeen] = useDoctorMarkPostsAsSeenMutation({
    refetchQueries: [DoctorGetMySeenPostsDocument],
  });

  const { data, loading: isLoading } = usePost(slug!);
  const { data: meData } = useDoctorGetMySeenPostsQuery();

  useEffect(() => {
    const isSeenPostId = meData?.doctorMe.seenPosts.some(
      (postId) => postId === data?.id,
    );

    if (!data?.id || isSeenPostId) return;
    markPostsAsSeen({ variables: { ids: [data.id] } });
  }, [data]);

  if (isLoading) return <ScreenLoader />;

  if (!data)
    return (
      <div className="grid h-screen place-items-center gap-20 rounded-lg bg-white p-8">
        <div className="font-display-2 text-jutro-new-warm-gray-700">
          nie ma takiego artykułu
        </div>
      </div>
    );

  return <NewsPostDetail data={data} />;
};

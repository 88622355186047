import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { SizeMe } from "react-sizeme";
import DefaultImage from "assets/default-imag.svg?react";
import { Loader } from "components/new/Loader";
import { PdfPreviewActionButtons } from "components/new/PdfPreview/PdfPreviewActionbuttons";
import { Scrollbar } from "components/new/Scrollbar";

type Props = {
  file: string;
  addFileText?: string;
  isModal?: boolean;
  handleAbort?: () => void;
  handleClose?: () => void;
};

export const PdfPreview = ({
  file,
  addFileText = "Dodaj do wiadomości",
  isModal,
  handleAbort,
  handleClose,
}: Props) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isFileLoadedSuccessfully, setIsFileLoadedSuccessfully] =
    useState(true);

  useEffect(() => {
    setIsFileLoadedSuccessfully(true);
  }, [file]);

  if (!isFileLoadedSuccessfully) {
    return (
      <div className="flex h-full w-full justify-center">
        <DefaultImage className={`w-1/4 stroke-current`} />
      </div>
    );
  }

  const onePageFile = numPages === 1;
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  return (
    <>
      <Scrollbar>
        <SizeMe>
          {({ size }) => (
            <Document
              file={file}
              className="h-full"
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
              }}
              loading={
                <div className="mx-auto my-auto">
                  <Loader color="darkBlue" size="md" />
                </div>
              }
              onError={() => setIsFileLoadedSuccessfully(false)}
            >
              <Page
                pageNumber={pageNumber}
                width={size.width ? size.width : 1}
                className="flex items-center justify-center"
              />
            </Document>
          )}
        </SizeMe>
      </Scrollbar>

      <div
        className={`flex w-full ${
          onePageFile ? "items-stretch justify-end" : "justify-between"
        }`}
      >
        <PdfPreviewActionButtons
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          isOnePageFile={onePageFile}
          addFileText={addFileText}
          isModal={Boolean(isModal)}
          file={file}
          setPageNumber={setPageNumber}
          handleAbort={handleAbort}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

import { PropsWithChildren, createContext, useContext, useState } from "react";

type Error = {
  isError: boolean;
  message: string;
};

export type ErrorContextType = {
  error: Error;
  updateError: (error: Error) => void;
};

const defaultErrorValue = {
  isError: false,
  message: "",
};

export const ErrorContext = createContext<ErrorContextType>({
  error: defaultErrorValue,
  updateError: () => {},
});

export const ErrorContextProvider = ({ children }: PropsWithChildren) => {
  const [error, setError] = useState<Error>(defaultErrorValue);

  const updateError = (error: Error) => {
    setError(error);
  };

  return (
    <ErrorContext.Provider value={{ error, updateError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = () => useContext(ErrorContext);

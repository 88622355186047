import { useQuery } from "@apollo/client";
import { isValidPesel } from "@jutro/tools";
import { z } from "zod";
import { DoctorPersonalIDNumbersDocument } from "lib/graphql/megaSchema";

const buildFormSchema = (
  currentPatientPesel: string,
  callQuery: ReturnType<typeof useImperativeQuery>,
) => {
  return z
    .object({
      firstName: z.string().min(1),
      lastName: z.string().min(1),
      phone: z.string().regex(/\d{9}/).length(9),
      email: z.string().email("Niepoprawny email").nullable().or(z.literal("")),
      birthDate: z.string().optional(),
      gender: z.string().optional(),
      peselNotGivenYet: z.boolean(),
      pesel: z.string().refine(
        async (peselValue) => {
          if (peselValue !== currentPatientPesel) {
            const { data } = await callQuery({ peselValue });
            return data?.patients?.length > 0 ? false : true;
          }

          return true;
        },
        { message: "Już istnieje pacjent z takim numerem pesel!" },
      ),
      flatNumber: z.string().nullable().optional(),
      address: z
        .object({
          city: z.string().nullable(),
          houseNumber: z.string().nullable(),
          latitude: z.number().nullable(),
          longitude: z.number().nullable(),
          municipality: z.string().nullable(),
          state: z.string().nullable(),
          street: z.string().nullable(),
          teryt: z.string().nullable(),
          zipCode: z.string().nullable(),
        })
        .nullable(),
    })
    .refine((fields) => {
      if (fields.peselNotGivenYet && fields.pesel.length === 12) {
        return true;
      }
      if (
        !fields.peselNotGivenYet &&
        fields.pesel.length === 11 &&
        isValidPesel(fields.pesel)
      ) {
        return true;
      }

      return fields.peselNotGivenYet;
    });
};

const useImperativeQuery = (query: any) => {
  const { refetch } = useQuery(query, { skip: true });
  const imperativelyCallQuery = (variables: any) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

export const useFormSchema = (currentPatientPesel: string) => {
  const callQuery = useImperativeQuery(DoctorPersonalIDNumbersDocument);

  const schema = buildFormSchema(currentPatientPesel, callQuery);

  return schema;
};

export type FormSchema = z.infer<ReturnType<typeof buildFormSchema>>;

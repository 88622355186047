import { Button, CalendarIcon } from "@jutro/ui";
import { useState } from "react";
import {
  IpomFormSchema,
  diagnosticTestTimeOptions,
  diagnosticTestTimeUnitOptions,
} from "components/IpomForm/schema";
import { DiagnosticTestModal } from "./DiagnosticTestModal";

type DiagnosticTest = IpomFormSchema["scheduledDiagnosticTests"][number];

type Props = {
  disabled?: boolean;
  diagnosticTest: DiagnosticTest;
  onTimeChange: (
    diagnosticTest: Pick<DiagnosticTest, "time" | "timeUnit" | "timeValue">,
  ) => void;
};

export const DiagnosticTestElement = ({
  disabled,
  diagnosticTest,
  onTimeChange,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { name, active, time, timeUnit, timeValue } = diagnosticTest;

  const timeLabel = diagnosticTestTimeOptions.find(
    ({ value }) => value === time,
  )?.label;

  const timeUnitLabel = diagnosticTestTimeUnitOptions.find(
    ({ value }) => value.timeUnit === timeUnit && value.timeValue === timeValue,
  )?.label;

  const buttonExecutionText = `${timeLabel}${time === "INTERWAL" ? `, co ${timeUnitLabel}` : ""}`;

  return (
    <>
      <div className="flex flex-col gap-2">
        <p className="font-paragraph-1">{name}</p>

        <Button
          icon={<CalendarIcon />}
          size="condensed"
          variant="ghost"
          type="button"
          full={false}
          disabled={!active || disabled}
          onClick={() => setIsModalOpen(true)}
          text={buttonExecutionText}
        />
      </div>

      <DiagnosticTestModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        diagnosticTest={diagnosticTest}
        onTimeChange={onTimeChange}
      />
    </>
  );
};

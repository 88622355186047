import { BinIcon, Button } from "@jutro/ui";
import { UtilType } from "components/ExaminationPanel/types";
import { headers } from "components/ExaminationPanel/utils/maps";
import { UtilColumn } from "./UtilColumn";

export const UtilElement = ({
  isDrugpass = false,
  type,
  util,
  index,
  disabled,
  errors,
  remove = () => {},
}: {
  isDrugpass?: boolean;
  type: UtilType;
  index: number;
  util?: any;
  disabled: boolean;
  errors: any;
  remove?: (index?: number | number[]) => void;
}) => {
  const { id } = util;

  return (
    <div className="flex items-center gap-4">
      <div
        className={`flex flex-1 items-center justify-center gap-4 rounded-lg ${type !== "measurement" && "border p-4"}`}
      >
        <div className="grid w-full grid-cols-4 gap-4">
          {Object.keys(util)
            .filter((e) => e !== "id" && e !== "__typename")
            .map((k) => (
              <UtilColumn
                isDrugpass={isDrugpass}
                header={headers[type][k]}
                type={type}
                index={index}
                util={util}
                id={id}
                value={k}
                key={headers[type][k]}
                disabled={disabled}
                error={errors?.[k]}
              />
            ))}
        </div>

        {index !== 0 && (
          <div className="mt-8">
            <Button
              full={false}
              variant="negativeGhost"
              size="condensed"
              icon={<BinIcon />}
              label="Usuń"
              tooltipPosition="top"
              onClick={() => remove(index)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

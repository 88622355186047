import { envVars } from "envvars";
import { objectify } from "radash";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchWithTimeout } from "lib/tools/fetchWithTimeout";

type Flag = {
  enabled: boolean;
  feature: {
    name: string;
  };
};

type FlagName =
  | "idle-timer"
  | "shortcuts"
  | "current-locale-select"
  | "maintenance-mode"
  | "ipom"
  | "feat-snippets-header-only-mode";

const flagsDefaultValues: Record<FlagName, boolean> = {
  "current-locale-select": false,
  "idle-timer": false,
  "maintenance-mode": false,
  shortcuts: false,
  ipom: false,
  "feat-snippets-header-only-mode": false,
};

export const FLAGSMITH_URL = "https://flagsmith.jutromedical.com/api/v1";

export const fetchFlag = (flagName: FlagName) => {
  return fetchWithTimeout(`${FLAGSMITH_URL}/flags/`, {
    headers: {
      "x-environment-key": envVars.REACT_APP_FLAGSMITH_CLIENT_ENV_KEY,
    },
  })
    .then((response) => response.json())
    .then((data: Flag[]) => {
      const flags = objectify(data, (flag) => flag.feature.name as FlagName);
      return flags[flagName].enabled;
    })
    .catch((error) => {
      console.error(error);
      return flagsDefaultValues[flagName];
    });
};

export const useFlag = (flagName: FlagName) => {
  const { data, isLoading } = useQuery(`flags-${flagName}`, () =>
    fetchFlag(flagName),
  );

  if (isLoading || data === undefined) return flagsDefaultValues[flagName];

  return data;
};

export const useMaintenanceMode = () => {
  const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState(false);
  const [maintenanceModeLoading, setMaintenanceModeLoading] = useState(true);

  useEffect(() => {
    fetchFlag("maintenance-mode")
      .then(setMaintenanceModeEnabled)
      .finally(() => setMaintenanceModeLoading(false));
  }, []);

  return [maintenanceModeEnabled, maintenanceModeLoading];
};

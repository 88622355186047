import { DateInput, Input } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { ErrorMessage } from "components/TagFormNew/ErrorMessage";
import { InputWrapper } from "components/TagFormNew/InputWrapper";
import { TagFormSchema } from "components/TagFormNew/hooks";
import { getNameHeader } from "components/TagFormNew/utils";
import { useDoctorPackagesLazyQuery } from "lib/graphql/megaSchema";
import { useAsyncIcd10Options } from "lib/hooks/useAsyncOptions";
import { DrugLabel } from "views/Visit/RightPanel/NewPrescription/components/DrugSelect/Label";

export const NameSection = () => {
  const {
    control,
    setValue,
    formState: {
      errors: { data },
    },
    watch,
  } = useFormContext<TagFormSchema>();

  const [currentType, legacyDrugName, confirmationType] = watch([
    "type",
    "data.name",
    "confirmationType",
  ]);

  const { icd10Options } = useAsyncIcd10Options();

  const [getPackages] = useDoctorPackagesLazyQuery();

  const loadDrugOptions = async (name: string) => {
    const { data } = await getPackages({ variables: { name } });

    if (!data || data.doctorPackages.length === 0) {
      return [];
    }

    return data.doctorPackages.map((drug) => {
      return {
        value: drug,
        label: <DrugLabel searchedDrug={drug} />,
      };
    });
  };

  if (!currentType) {
    return null;
  }

  const nameHeader = getNameHeader(currentType);

  if (currentType === "REGULAR_DRUG") {
    return (
      <>
        <InputWrapper header={nameHeader}>
          {/* TODO: try to pass data.ean instead of data.drug to trigger rerender */}
          <pre className="hidden">{JSON.stringify(watch("data.drug"))}</pre>
          <Controller
            control={control}
            name="data.drug"
            render={({ field: { onChange, value, ref, onBlur } }) => {
              const currentValue = () => {
                if (!legacyDrugName && !value?.ean) {
                  return null;
                }

                return {
                  value: value?.ean,
                  label: value?.ean ? (
                    <DrugLabel searchedDrug={value} />
                  ) : (
                    legacyDrugName
                  ),
                };
              };

              return (
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isDisabled={confirmationType === "EXAMINATION"}
                  noOptionsMessage={() => "Brak wyników"}
                  placeholder={"Wybierz z listy"}
                  loadOptions={loadDrugOptions}
                  onChange={(option) => {
                    onChange(option?.value);
                    setValue("data.ean", option?.value?.ean);
                  }}
                  value={currentValue()}
                  ref={ref}
                  onBlur={onBlur}
                />
              );
            }}
          />
          {data?.ean?.message && <ErrorMessage text={data.ean.message} />}
        </InputWrapper>

        <div className="flex gap-2">
          <Controller
            name="data.interval"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label="Dawkowanie*"
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="expirationAt"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Data ważności"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </>
    );
  }

  return (
    <InputWrapper header={nameHeader}>
      <Controller
        control={control}
        name="data.name"
        render={({ field: { onChange, value, ref, onBlur, name } }) =>
          currentType === "CHRONIC_DISEASE" ? (
            <AsyncSelect
              cacheOptions
              isDisabled={confirmationType === "EXAMINATION"}
              defaultOptions
              noOptionsMessage={() => "Brak wyników"}
              placeholder={"Wybierz z listy"}
              loadOptions={icd10Options}
              onChange={(option) => {
                onChange(option?.value);
              }}
              value={value ? { value, label: <span>{value}</span> } : null}
              ref={ref}
              onBlur={onBlur}
            />
          ) : (
            <Input
              placeholder="Kliknij, aby uzupełnić"
              name={name}
              onChange={(val) => onChange(val)}
              value={value ?? ""}
              ref={ref}
            />
          )
        }
      />
      {data?.name?.message && <ErrorMessage text={data.name.message} />}
    </InputWrapper>
  );
};

import {
  Button,
  CalendarClockIcon,
  CloseIcon,
  Input,
  NotesIcon,
} from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import DateTimePicker from "react-datetime-picker";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { customSelectStyles } from "components/PatientDocs/Ordered/utils";
import { Checkbox } from "components/new";
import { Dropzone } from "components/new/Dropzone/Dropzone";
import { SelectWrapper } from "components/new/SelectWrapper";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { patientDeclarationAtom } from "views/Patient/Profile/atoms";
import { useDeclarationEditForm } from "views/Patient/Profile/useDeclarationEditForm";
import {
  declarationTypeOptions,
  howManyProviderChangesOptions,
  providerChangeReasons,
} from "views/Patient/Profile/utils";

const getCreatorName = (
  files: string[],
  source: string,
  patientFullName: string,
  staffFullName: string,
) => {
  if (source === "EPUAP" || source === "IKP") {
    return patientFullName;
  }

  if (source === "PAPER" && files.length > 0) {
    return staffFullName;
  }

  if (source === "PAPER" && files.length === 0) {
    return "Brak danych";
  }
};

export const DeclarationEditForm = () => {
  const [patientDeclaration] = useAtom(patientDeclarationAtom);

  const { removeTile } = useTileSystem();

  const {
    control,
    isSubmitting,
    isValid,
    addNewDeclarationFile,
    patientFullName,
  } = useDeclarationEditForm();

  const { vojevodshipsWithCities } = useVojevodshipOptions();

  if (!patientDeclaration) {
    return <span>Brak deklaracji</span>;
  }

  const {
    doctor,
    vojevodship,
    source,
    declarationGenerationParams: {
      anotherProviderReason,
      frequentFacilityChangeReason,
    },
    files,
    createdAt,
    marketingSource,
    declarationUploadStaff,
  } = patientDeclaration;

  const staffFullName = `${declarationUploadStaff?.firstName} ${declarationUploadStaff?.lastName}`;

  const creatorName = getCreatorName(
    files,
    source,
    patientFullName,
    staffFullName,
  );

  return (
    <form className="flex flex-col gap-2" onSubmit={addNewDeclarationFile}>
      <div className="flex items-center justify-between rounded-lg bg-white p-4">
        <h4 className="font-paragraph-2 text-jutro-new-warm-gray-700">
          Edycja deklaracji
        </h4>
        <Button
          full={false}
          variant="ghost"
          size="condensed"
          icon={<CloseIcon />}
          label="Zamknij"
          tooltipPosition="bottom"
          onClick={() => removeTile("patient-declaration-edit")}
        />
      </div>

      <div className="flex flex-col gap-2 rounded-lg bg-white p-4">
        <div className="flex w-max gap-2">
          <SelectWrapper label="Typ deklaracji" labelSize="xs">
            <div className="flex gap-2 rounded-lg border p-2">
              <Controller
                name="genre"
                control={control}
                render={() => (
                  <>
                    <Checkbox
                      label="Lekarska"
                      xs
                      value={doctor.genre === "DOCTOR" ? true : false}
                      disabled={doctor.genre === "DOCTOR" ? false : true}
                    />
                    <Checkbox
                      label="Pielęgniarska"
                      xs
                      value={
                        doctor.genre === "NURSE" && doctor.npwz?.endsWith("P")
                          ? true
                          : false
                      }
                      disabled={
                        doctor.genre === "NURSE" && doctor.npwz?.endsWith("P")
                          ? false
                          : true
                      }
                    />
                    <Checkbox
                      label="Położnicza"
                      xs
                      value={
                        doctor.genre === "NURSE" && doctor.npwz?.endsWith("A")
                          ? true
                          : false
                      }
                      disabled={
                        doctor.genre === "NURSE" && doctor.npwz?.endsWith("A")
                          ? false
                          : true
                      }
                    />
                  </>
                )}
              />
            </div>
          </SelectWrapper>

          <div>
            <Controller
              control={control}
              name="date"
              render={() => (
                <SelectWrapper label="Data złożenia" labelSize="xs">
                  <DateTimePicker
                    format="yyyy-MM-dd"
                    disableClock
                    disabled
                    clearIcon={null}
                    calendarIcon={<CalendarClockIcon size="sm" />}
                    value={createdAt}
                    className={`declaration-date-time-picker font-paragraph-2 h-[34px] rounded-lg border-[1px] text-jutro-new-warm-gray-800 focus-within:border-jutro-new-blue-800 hover:border-jutro-new-blue-800`}
                  />
                </SelectWrapper>
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            control={control}
            name="organizationId"
            render={() => (
              <SelectWrapper label="Województwo" labelSize="xs">
                <ReactSelect
                  value={vojevodshipsWithCities.find(
                    (o) => o.value === vojevodship,
                  )}
                  isDisabled
                  styles={customSelectStyles()}
                />
              </SelectWrapper>
            )}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="type"
            render={({ field: { value } }) => (
              <SelectWrapper label="Forma i źródło deklaracji" labelSize="xs">
                <ReactSelect
                  //@ts-ignore
                  value={
                    marketingSource === "EMPTY"
                      ? {
                          label: "Brak źródła deklaracji",
                          ...value,
                        }
                      : declarationTypeOptions.find(
                          (o) => o.value.source === marketingSource,
                        )
                  }
                  isDisabled
                  styles={customSelectStyles()}
                />
              </SelectWrapper>
            )}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="npwz"
            render={() => (
              <SelectWrapper label="Na kogo" labelSize="xs">
                <ReactSelect
                  value={{
                    label: `${doctor.firstName} ${doctor.lastName}`,
                    value: doctor.npwz,
                  }}
                  isDisabled
                  styles={customSelectStyles()}
                />
              </SelectWrapper>
            )}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="howManyProviderChanges"
            render={() => (
              <SelectWrapper
                label="Który raz dokonujesz wyboru POZ w tym roku?"
                labelSize="xs"
              >
                <ReactSelect
                  isDisabled
                  value={
                    frequentFacilityChangeReason
                      ? howManyProviderChangesOptions[1]
                      : howManyProviderChangesOptions[0]
                  }
                  styles={customSelectStyles()}
                />
              </SelectWrapper>
            )}
          />
        </div>

        {frequentFacilityChangeReason && (
          <div>
            <Controller
              control={control}
              name="facilityChangeReason"
              render={() => (
                <SelectWrapper label="Przyczyna" labelSize="xs">
                  <ReactSelect
                    isDisabled
                    value={providerChangeReasons.find(
                      (o) => o.value === frequentFacilityChangeReason,
                    )}
                    styles={customSelectStyles()}
                  />
                </SelectWrapper>
              )}
            />
          </div>
        )}

        {anotherProviderReason && (
          <div>
            <Controller
              control={control}
              name="facilityChangeReason"
              render={() => (
                <SelectWrapper
                  label="Przyczyny powstałe po stronie świadczeniodawcy"
                  labelSize="xs"
                >
                  <ReactSelect
                    isDisabled
                    value={{
                      label: anotherProviderReason,
                      value: anotherProviderReason,
                    }}
                    styles={customSelectStyles()}
                  />
                </SelectWrapper>
              )}
            />
          </div>
        )}

        <div>
          <div className="flex flex-col gap-2">
            <Controller
              control={control}
              rules={{ required: true }}
              name="files"
              render={({ field: { value, onChange } }) => (
                <SelectWrapper label="Plik" labelSize="xs">
                  <div className="flex flex-col gap-2">
                    <Dropzone
                      addFileText="Dodaj plik"
                      maxFiles={1}
                      isModal
                      selectedFiles={value ?? []}
                      onFilesListUpdate={onChange}
                    />
                  </div>
                </SelectWrapper>
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            control={control}
            name="comment"
            render={({ field: { value, onChange } }) => (
              <SelectWrapper label="Komentarz" labelSize="xs">
                <Input value={value ?? ""} onChange={onChange} />
              </SelectWrapper>
            )}
          />
        </div>

        <div className="flex justify-between">
          <div className="font-paragraph-2">
            <span>Utworzone przez: </span>
            <span className="font-bold">{creatorName}</span>
          </div>
          <span className="font-paragraph-2 text-jutro-new-warm-gray-300">
            {dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
          </span>
        </div>

        <Button
          type="submit"
          text="Edytuj"
          icon={<NotesIcon />}
          loading={isSubmitting}
          disabled={!isValid}
        />
      </div>
    </form>
  );
};

import { FC } from "react";

type NfzEvent = {
  __typename?: string;
};

type EventHandlerProps<TEvent> = {
  event: TEvent;
};

export type EventHandler<TEvent> = FC<EventHandlerProps<TEvent>>;

type Mapping = Record<string, EventHandler<any>>;

type NfzEventToComponentProps = {
  mapping: Mapping;
  event: NfzEvent;
};

export const NfzEventToComponent: FC<NfzEventToComponentProps> = ({
  mapping,
  event,
}) => {
  for (const eventName in mapping) {
    if (eventName === event.__typename) {
      const Handler = mapping[eventName];
      return <Handler event={event} />;
    }
  }

  //added for debugging purposes
  console.error(
    `No handler found for event: ${
      event.__typename
    }, available mappings: ${Object.keys(mapping)}`,
  );

  return null;
};

import { DoctorMSEwusCheckEvent } from "lib/graphql/megaSchema";
import { EventHandler, NfzEventToComponent } from "../../../NfzEvent/infer";
import { EwusGreenEventHandler } from "./EwusGreen";
import { EwusRedEventHandler } from "./EwusRed";

const mapping = {
  DoctorMSEwusRedEvent: EwusRedEventHandler,
  DoctorMSEwusGreenEvent: EwusGreenEventHandler,
};

export const EwusCheckEventHandler: EventHandler<DoctorMSEwusCheckEvent> = ({
  event,
}) => {
  return <NfzEventToComponent mapping={mapping} event={event.event} />;
};

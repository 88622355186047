import { ReactElement } from "react";
import {
  AuthorImage,
  AuthorSection,
  MessageContainer,
  MessageFooter,
} from "./components";

type Props = {
  authorImage: string;
  authorName: string;
  messageContent: ReactElement | string;
  mine: boolean;
  footerContent: string;
  footerBackground: string;
  footerClick: () => void;
  noArrow: boolean;
  footerDisabled: boolean;
  messageTime: string;
};

export const MessageWithFooter = ({
  authorImage,
  authorName,
  messageContent,
  mine,
  footerContent,
  footerBackground,
  footerClick,
  footerDisabled,
  messageTime,
}: Props) => (
  <MessageContainer mine={mine}>
    <AuthorSection mine={mine}>
      <AuthorImage src={authorImage} />
      {authorName}
    </AuthorSection>
    <div
      className={`flex flex-col rounded-lg p-3 ${
        mine ? "gap-2 bg-jutro-new-blue-500" : "gap-0 bg-jutro-new-warm-gray-50"
      }`}
    >
      <div
        className={`whitespace-pre-line break-words px-3 pb-[6px] pt-2 text-[15px] ${
          mine ? "text-white" : "text-jutro-new-blue-950"
        }`}
      >
        {messageContent}
      </div>
      <div
        className={`text-xs text-white ${mine ? "text-left" : "text-right"}`}
      >
        {messageTime}
      </div>
      <MessageFooter
        footerBackground={footerBackground}
        onClick={footerClick}
        disabled={footerDisabled}
      >
        {footerContent}
      </MessageFooter>
    </div>
  </MessageContainer>
);

import dayjs from "dayjs";
import pl from "dayjs/locale/pl";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { HotkeysProvider } from "./Hotkeys";
import { IdleTimer } from "./IdleTimer";

dayjs.locale(pl);

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

const queryClient = new QueryClient();

export const Providers = ({ children }: PropsWithChildren<unknown>) => (
  <QueryClientProvider client={queryClient}>
    <HotkeysProvider />
    <IdleTimer />

    {children}
  </QueryClientProvider>
);

import { is, repeat } from "ramda";

type Props = {
  content: string;
};

export const JSONView = ({ content }: Props) => {
  const formatJson = (c: any, indent: number): string => {
    const indentsSmaller = repeat("\t", indent - 1).join("");
    const indents = repeat("\t", indent).join("");

    if (is(Object, c))
      return `{\n${Object.keys(c)
        .map((key) => `${indents}${key}: ${formatJson(c[key], indent + 1)}`)
        .join("\n")}\n${indentsSmaller}}`;

    if (is(Array, c))
      return `[\n${c
        // @ts-expect-error
        .map((value) => `${indents}${formatJson(value, indent + 1)}`)
        .join("\n")}\n${indentsSmaller}]`;

    return c;
  };

  return <div className="whitespace-pre">{formatJson(content, 1)}</div>;
};

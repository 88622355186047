import { Avatar } from "components/new";
import { DoctorMSUserGenre } from "lib/graphql/megaSchema";

const getDoctorRole = (
  genre: DoctorMSUserGenre,
  npwz: string | null | undefined,
) => {
  if (!npwz) {
    return "";
  }

  const isDoctor = genre === "DOCTOR";
  const isNurse = genre === "NURSE" && npwz.endsWith("P");

  if (isDoctor) {
    return "Lekarka rodzinna / Lekarz rodzinny";
  }

  if (isNurse) {
    return "Pielęgniarka / Pielęgniarz";
  }

  return "Położna / Położny";
};

type Props = {
  doctorId: string;
  doctorFullName: string;
  genre: DoctorMSUserGenre;
  npwz: string | null | undefined;
};

export const DoctorDetailsTooltip = ({
  doctorFullName,
  doctorId,
  genre,
  npwz,
}: Props) => {
  return (
    <div className="w-[420px] text-base text-jutro-new-warm-gray-700">
      <div className="rounded-t-lg bg-jutro-new-warm-gray-100 px-2 py-1">
        <h5>Szczegóły osoby</h5>
      </div>

      <div className="flex items-start justify-between p-2">
        <div className="flex flex-col">
          <span className="font-bold">{doctorFullName}</span>
          <span>{getDoctorRole(genre, npwz)}</span>
        </div>
        <Avatar userId={doctorId} size="large" />
      </div>
    </div>
  );
};

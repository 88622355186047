import { Counter } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import { IpomListItem } from "./IpomList/IpomListItem";
import { IpomFormSchema } from "./schema";

type Props = {
  disabled?: boolean;
};

export const EducationSection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <span className="font-label text-jutro-new-warm-gray-800">
          Porady edukacyjne
        </span>

        <span className="font-label text-jutro-new-warm-gray-800">
          Liczba wizyt
        </span>
      </div>

      <div className="flex flex-col gap-1">
        <Controller
          control={control}
          name="diet"
          render={({ field: { value, onChange } }) => (
            <IpomListItem
              leftElement="Edukacja dietetyczna"
              rightElement={
                <Counter
                  min={0}
                  max={3}
                  value={value}
                  onIncrement={() => onChange(value + 1)}
                  onDecrement={() => onChange(value - 1)}
                  disabled={disabled}
                />
              }
            />
          )}
        />

        <Controller
          control={control}
          name="nurse"
          render={({ field: { value, onChange } }) => (
            <IpomListItem
              leftElement="Edukacja pielęgniarska"
              rightElement={
                <Counter
                  min={0}
                  max={6}
                  value={value}
                  onIncrement={() => onChange(value + 1)}
                  onDecrement={() => onChange(value - 1)}
                  disabled={disabled}
                />
              }
            />
          )}
        />
      </div>
    </div>
  );
};

import dayjs from "dayjs";

export const createChildPesel = (
  birthDate: string | undefined,
  gender: string | undefined,
) => {
  if (!birthDate || !gender) {
    return;
  }
  const originalDate = dayjs(birthDate);
  const formattedDate = originalDate.format("DD-MM-YYYY");

  return `${formattedDate.slice(8, 10)}${
    Number(formattedDate.slice(3, 5)) + 20
  }${formattedDate.slice(0, 2)}0000${gender === "Męska" ? 1 : 0}0`;
};

import { zodResolver } from "@hookform/resolvers/zod";
import { determineAge, outDuplicates } from "@jutro/tools";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ScheduleFormSchema,
  prepareDefaultValue,
  scheduleFormSchema,
} from "components/ScheduleVisitForm/tools/form";
import { getUniqueDoctors } from "components/ScheduleVisitForm/tools/getUniqueDoctors";
import { telemedicalSlotTypes } from "components/ScheduleVisitForm/tools/staticOptions";
import { transformToSelectOptions } from "components/ScheduleVisitForm/tools/transformToSelectOptions";
import { ScheduleVisitTileData } from "components/ScheduleVisitForm/types";
import {
  DoctorGetSlotsQuery,
  DoctorGetSlotsQueryVariables,
  DoctorMSSelectOption,
  DoctorMSSlotType,
  useDoctorGetDoctorsLazyQuery,
  useDoctorGetPatientQuery,
  useDoctorGetSlotsLazyQuery,
} from "lib/graphql/megaSchema";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { toaster } from "lib/tools/toaster";

type Props = {
  scheduleVisitTileData: ScheduleVisitTileData;
};

export const useScheduleVisitForm = ({ scheduleVisitTileData }: Props) => {
  const { patientId, mode, visitData } = scheduleVisitTileData;

  const [getSlots, { loading: isLoadingSlots }] = useDoctorGetSlotsLazyQuery();

  const [getDoctors] = useDoctorGetDoctorsLazyQuery();

  const { organizations } = useVojevodshipOptions();

  const { data } = useDoctorGetPatientQuery({
    fetchPolicy: "network-only",
    variables: {
      id: patientId,
    },
  });

  const methods = useForm<ScheduleFormSchema>({
    resolver: zodResolver(scheduleFormSchema),
    defaultValues: prepareDefaultValue(visitData),
  });

  const { watch, setValue } = methods;

  const { slotType, organizationId, doctorId, areAdultDoctorsVisible } =
    watch();

  const patientData = data?.doctorPatient;
  const patientPesel = patientData?.pesel;
  const patientAge = determineAge(patientPesel ?? "");
  const isPatientChild = patientAge < 18 || patientPesel?.length === 12;

  const [doctorsOptions, setDoctorsOptions] = useState<DoctorMSSelectOption[]>(
    [],
  );
  const [slots, setSlots] = useState<DoctorGetSlotsQuery["doctorGetSlots"]>([]);

  const isChosenSlotTypeTelemedical =
    slotType && telemedicalSlotTypes.includes(slotType as DoctorMSSlotType);

  const isViewForceable = mode === "forceEdit" || mode === "forceCreate";

  const getAndSetSlots = async (
    variables: DoctorGetSlotsQueryVariables,
    isPatientChild?: boolean,
  ) => {
    let childrenSlots: DoctorGetSlotsQuery["doctorGetSlots"] = [];

    if (isPatientChild) {
      await getSlots({
        variables: { ...variables, isChild: true },
        fetchPolicy: "no-cache",
      })
        .then(({ data }) => {
          if (!data) {
            childrenSlots = [];
            return;
          }

          childrenSlots = data.doctorGetSlots;
        })
        .catch(() => {
          toaster.error("Wystąpił błąd w pobieraniu terminów");
        });
    }

    await getSlots({
      variables,
      fetchPolicy: "no-cache",
    })
      .then(({ data }) => {
        if (!data) {
          setSlots([]);
          return;
        }

        setSlots(
          [...data.doctorGetSlots, ...childrenSlots]
            .filter(outDuplicates({ by: ["slot_id"] }))
            .sort((a, b) => (dayjs(a.time).isAfter(dayjs(b.time)) ? 1 : -1)),
        );
      })
      .catch(() => {
        toaster.error("Wystąpił błąd w pobieraniu terminów");
      });
  };

  useEffect(() => {
    if ((!organizationId && slotType !== "TELEMEDICAL_PRIVATE") || !slotType) {
      return;
    }

    setValue("chosenSlotId", null);
    setValue("doctorId", null);

    getAndSetSlots(
      {
        type: slotType as DoctorMSSlotType,
        organizationId:
          slotType === "TELEMEDICAL_PRIVATE" ? null : organizationId,
      },
      isPatientChild,
    );
  }, [organizationId, slotType, areAdultDoctorsVisible]);

  useEffect(() => {
    if (mode === "forceCreate" || mode === "forceEdit") {
      getDoctors()
        .then(({ data }) => {
          if (!data) {
            setDoctorsOptions([]);
            return;
          }

          setDoctorsOptions(transformToSelectOptions(data.doctorDoctors));
        })
        .catch(() => {
          toaster.error("Nie udało się pobrać listy lekarzy");
        });

      return;
    }

    const uniqueDoctors = getUniqueDoctors(slots);

    if (!areAdultDoctorsVisible && isPatientChild) {
      const filteredDoctors = uniqueDoctors.filter((o) => o.childrenAvailable);
      setDoctorsOptions(transformToSelectOptions(filteredDoctors));
      return;
    }

    setDoctorsOptions(transformToSelectOptions(uniqueDoctors));
  }, [slots, mode, areAdultDoctorsVisible]);

  const organizationsOptions = useMemo(() => {
    const sortedOrganizations = [...organizations].sort((a, b) => {
      const aCity = a.placeData?.city ?? "a";
      const bCity = b.placeData?.city ?? "a";
      return aCity < bCity ? -1 : 1;
    });

    return sortedOrganizations.map(({ displayName, id, placeData }) => ({
      label: `${displayName}${placeData ? ` (${placeData.city})` : ""}`,
      value: id,
    }));
  }, [organizations]);

  const officeOptions = useMemo(() => {
    if (!organizationId) {
      return [];
    }

    return organizations
      .filter(({ id }) => id.includes(organizationId) && id !== organizationId)
      .map(({ displayName, id }) => ({
        label: displayName,
        value: id,
      }));
  }, [organizationId, organizations]);

  const filteredSlots = useMemo(() => {
    return slots.filter((slot) => {
      if (!doctorId && !areAdultDoctorsVisible && isPatientChild) {
        return slot.doctor.childrenAvailable;
      }

      if (!doctorId) {
        return slot;
      }

      return slot.doctor.id === doctorId;
    });
  }, [slots, doctorId, areAdultDoctorsVisible]);

  return {
    organizationsOptions,
    doctorsOptions,
    officeOptions,
    filteredSlots,
    isLoadingSlots,
    isChosenSlotTypeTelemedical,
    isViewForceable,
    isPatientChild,
    methods,
  };
};

import { AddIcon, Button, CloseIcon, MessageBox } from "@jutro/ui";
import { useAtom } from "jotai";
import { ReactNode, Suspense, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ProcedurePanel } from "components/ExaminationPanel/ProcedurePanel";
import { IpomForm } from "components/IpomForm";
import { PatientDocs } from "components/PatientDocs";
import { ScheduleVisitForm } from "components/ScheduleVisitForm";
import { SnippetFormTile } from "components/Snippet/SnippetFormTile";
import { SnippetListTile } from "components/Snippet/SnippetListTile";
import { TagFormNew } from "components/TagFormNew";
import { Loader } from "components/new";
import { Chat } from "components/new/Chat";
import { FilePreview } from "components/new/MedicalDocumentation/FilePreview";
import { PopupMenu } from "components/new/PopupMenu";
import { PatientIdProvider } from "lib/tools/createWorkspace/PatientIdProvider";
import { TileElement } from "lib/tools/createWorkspace/TileElement";
import { workspaceWidthAtom } from "lib/tools/createWorkspace/atoms";
import { isDataTile } from "lib/tools/createWorkspace/tools";
import {
  BasicTile,
  Tile,
  TileColumn,
  TileConfig,
  TileWidth,
} from "lib/tools/createWorkspace/types";
import {
  getVisibleTiles,
  useTileSystem,
} from "lib/tools/createWorkspace/useTileSystem";
import { DeclarationCreatorFormWrapper } from "views/Patient/Profile/DeclarationCreatorForm";
import { DeclarationEditForm } from "views/Patient/Profile/DeclarationEditForm";
import { DeclarationHistory } from "views/Patient/Profile/DeclarationHistory";
import { EwusStatement } from "views/Patient/Profile/EwusStatement";
import { PatientEvents } from "views/Patient/Profile/PatientEvents";
import { RightSection } from "views/Visit/RightPanel";
import { DrugInfo } from "views/Visit/RightPanel/NewPrescription/components/Overlays/DrugInfo";
import { OrderedDrugs } from "views/Visit/RightPanel/NewPrescription/components/Overlays/OrderedDrugs";
import { PreviousDrugs } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs";
import { VirtualAssistantChat } from "views/Visit/RightPanel/Visit/components/VirtualAssistantChat";

export const tileConfig: TileConfig = {
  "snippets-admin": {
    element: <SnippetListTile mode="admin" />,
    width: 2,
    label: "Szablony",
  },
  "snippet-settings": {
    element: ({ type, category }) => (
      <SnippetListTile
        key={"settings" + type + category}
        mode="settings"
        type={type}
        category={category}
      />
    ),
    width: 1,
    label: "Szablony",
  },
  "snippet-form": {
    element: (data) => <SnippetFormTile {...data} />,
    width: 1,
  },
  chat: {
    element: (
      <PatientIdProvider>
        {(patientId) => <Chat patientId={patientId} />}
      </PatientIdProvider>
    ),
    width: 1,
  },
  "ipom-form": {
    element: <IpomForm />,
    width: 1,
  },
  "virtual-assistant-chat": {
    element: <VirtualAssistantChat />,
    width: 1,
  },
  "docs-of-patient": {
    element: <PatientDocs />,
    width: 1,
  },
  "tag-form": {
    element: (tagForm) => <TagFormNew tagForm={tagForm} />,
    width: 1,
  },
  "file-preview": {
    element: ({ fileId }) => <FilePreview fileId={fileId} />,
    width: 1,
  },
  "schedule-visit-form": {
    element: (scheduleVisitTileData) => (
      <ScheduleVisitForm scheduleVisitTileData={scheduleVisitTileData} />
    ),
    width: 1,
  },
  "patient-events": { width: 1, element: <PatientEvents /> },
  "patient-declaration-history": { width: 1, element: <DeclarationHistory /> },
  "patient-declaration-creator": {
    width: 1,
    element: <DeclarationCreatorFormWrapper />,
  },
  "patient-declaration-edit": { width: 1, element: <DeclarationEditForm /> },
  "patient-ewus-statement": { width: 1, element: <EwusStatement /> },
  "visit-procedure-panel": { element: <ProcedurePanel />, width: 2 },
  "visit-docs": { element: <RightSection />, width: 1 },
  "visit-previous-drugs": {
    element: <PreviousDrugs />,
    width: 1,
  },
  "visit-ordered-drugs": {
    element: <OrderedDrugs />,
    width: 1,
  },
  "visit-drug-info": {
    width: 1,
    element: (drug) => <DrugInfo drug={drug} />,
  },
};

const tileNumberMap = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
  5: "grid-cols-5",
} as const;

export type HandledTileNumber = keyof typeof tileNumberMap;

export const createWorkspace = (
  tiles: Tile[][],
  { tileNumber }: { tileNumber: HandledTileNumber } = { tileNumber: 3 },
) => {
  return (
    <div className={`grid h-full w-full gap-4 ${tileNumberMap[tileNumber]}`}>
      {getVisibleTiles(tiles).map((tile) => {
        // const basicTileOrId = isDataTile(tile) ? tile.id : tile;

        return (
          <TileWrapper
            key={isDataTile(tile) ? tile.id : tile}
            // header={
            //   <div className="flex justify-between">
            //     <SwapTileIcons basicTileOrId={basicTileOrId} />
            //     <div className="flex gap-2">
            //       <AddTileIcon />
            //       <RemoveTileIcon basicTileOrId={basicTileOrId} />
            //     </div>
            //   </div>
            // }
            tile={tile}
          />
        );
      })}
    </div>
  );
};

export const AddTileIcon = () => {
  const { addTile } = useTileSystem();
  const [width] = useAtom(workspaceWidthAtom);
  const [showSelect, setShowSelect] = useState(false);

  const addBasicTileRight = (basicTile: BasicTile) => {
    setShowSelect(false);
    addTile(basicTile, width as TileColumn);
  };

  return (
    <div className="relative">
      <Button
        label="dodaj kafel"
        withTooltip
        tooltipPosition="bottom"
        onClick={() => {
          setShowSelect((p) => !p);
        }}
        icon={<AddIcon />}
        variant="ghost"
        size="condensed"
        full={false}
      />
      <PopupMenu
        active={showSelect}
        position="bottom-left"
        items={[
          { id: "0", label: "Chat", action: () => addBasicTileRight("chat") },
          {
            id: "1",
            label: "Procedure panel",
            action: () => addBasicTileRight("visit-procedure-panel"),
          },
          {
            id: "2",
            label: "Visit docs",
            action: () => addBasicTileRight("visit-docs"),
          },
          {
            id: "3",
            label: "Recent drugs",
            action: () => addBasicTileRight("visit-previous-drugs"),
          },
          {
            id: "4",
            label: "Ordered drugs",
            action: () => addBasicTileRight("visit-ordered-drugs"),
          },
          // {
          //   id: "6",
          //   label: "patient-events",
          //   action: () => addBasicTileRight("patient-events"),
          // },
          // {
          //   id: "8",
          //   label: "patient-declaration-history",
          //   action: () => addBasicTileRight("patient-declaration-history"),
          // },
          // {
          //   id: "9",
          //   label: "patient-declaration-creator",
          //   action: () => addBasicTileRight("patient-declaration-creator"),
          // },
          // {
          //   id: "10",
          //   label: "patient-declaration-edit",
          //   action: () => addBasicTileRight("patient-declaration-edit"),
          // },
          // {
          //   id: "11",
          //   label: "patient-ewus-statement",
          //   action: () => addBasicTileRight("patient-ewus-statement"),
          // },
        ]}
      />
    </div>
  );
};

type Props = {
  basicTileOrId: BasicTile | string;
};

export const RemoveTileIcon = ({ basicTileOrId }: Props) => {
  const { removeTile } = useTileSystem();

  return (
    <Button
      label="usuń kafelek"
      withTooltip
      tooltipPosition="bottom"
      onClick={() => {
        removeTile(basicTileOrId);
      }}
      icon={<CloseIcon />}
      variant="ghost"
      size="condensed"
      full={false}
    />
  );
};

const TileWrapper = ({ tile, header }: { tile: Tile; header?: ReactNode }) => {
  const isTileWithData = isDataTile(tile);

  const name = isTileWithData ? tile.name : tile;
  const data = isTileWithData ? tile.data : undefined;

  const { element: _element, width } = tileConfig[name];

  const element =
    typeof _element === "function" && data
      ? (_element as (ctx: typeof data) => JSX.Element)(data)
      : (_element as JSX.Element);

  return (
    <ErrorBoundary fallback={<TileErrorFallback width={width} />}>
      <Suspense fallback={<Loader screen />}>
        <TileElement width={width}>
          {header}
          {element}
        </TileElement>
      </Suspense>
    </ErrorBoundary>
  );
};

const TileErrorFallback = ({ width }: { width: TileWidth }) => {
  return (
    <TileElement width={width}>
      <div className="grid h-full place-items-center rounded-lg bg-jutro-new-rose-50 p-4">
        <MessageBox text="Coś poszło nie tak" color="rose" full={false} />
      </div>
    </TileElement>
  );
};

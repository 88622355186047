import { Maybe, Option } from "@jutro/types";
import { z } from "zod";
import {
  DoctorMSAddress,
  DoctorMSDeclarationMarketingSource,
  DoctorMSDeclarationType,
  DoctorMSFrequentFacilityChangeReason,
  DoctorMSInsuranceStatementPatientReason,
  DoctorMSInsuranceStatementRepresentativeReason,
} from "lib/graphql/megaSchema";
import { declarationCreatorSchema } from "views/Patient/Profile/useDeclarationCreatorForm";

export type DeclarationCreatorForm = z.infer<typeof declarationCreatorSchema>;

export enum StatementType {
  SELF = "SELF",
  REPRESENTATIVE = "REPRESENTATIVE",
}

type DeclarationTypeOptionsValue = {
  form: DoctorMSDeclarationType;
  source: DoctorMSDeclarationMarketingSource;
};

export type DeclarationTypeOptions = Option<DeclarationTypeOptionsValue>;
export type ProviderChangeReasons =
  Option<DoctorMSFrequentFacilityChangeReason>;

export type GroupedRepresentativeOptions = {
  label: string;
  options: Option<DoctorMSInsuranceStatementRepresentativeReason>[];
};

export type GroupedSelfOptions = {
  label: string;
  options: Option<DoctorMSInsuranceStatementPatientReason>[];
};

export type DeclarationGenre = "DOCTOR" | "NURSE" | "BOTH";

export type GenOption = Option<DeclarationGenre>;

export type StaffSelect = {
  id: string;
  npwz: string | null | undefined;
};

export type DeclarationFromAndSource = {
  form: string;
  source: string;
};

export type LegalGuardian = {
  value: {
    address: DoctorMSAddress;
    email: string;
    patientCardNumber: string;
    phone: string;
    firstName: string;
    lastName: string;
  };
};

export type StaffDeclarationData = {
  staffData: {
    id?: string | null;
    npwz: string;
  } | null;
  howManyProviderChanges: string;
  legacyDoctors?: boolean | undefined;
  facilityChangeReason?:
    | "ANOTHER_FACT"
    | "ANOTHER_PROVIDER_REASON"
    | "CHANGE_OF_ADDRESS_OF_RESIDENCE"
    | "REACHING_ADULTHOOD_WITH_PEDIATRICIAN"
    | "SERVICE_PROVIDER_CEASING"
    | undefined;
  otherReasonProviderChange?: string | undefined;
} | null;

export type AvailableStaffOptionValue = {
  npwz: Maybe<string> | undefined;
  id: string | undefined;
} | null;

export type StaffDataFieldNames =
  | "doctorDeclarationData.staffData"
  | "nurseDeclarationData.staffData"
  | "midwifeDeclarationData.staffData";

export type HowManyProviderChangesFieldNames =
  | "doctorDeclarationData.howManyProviderChanges"
  | "nurseDeclarationData.howManyProviderChanges"
  | "midwifeDeclarationData.howManyProviderChanges";

export type FacilityChangeReasonFieldNames =
  | "doctorDeclarationData.facilityChangeReason"
  | "nurseDeclarationData.facilityChangeReason"
  | "midwifeDeclarationData.facilityChangeReason";

export type OtherReasonProviderChangeFieldNames =
  | "doctorDeclarationData.otherReasonProviderChange"
  | "nurseDeclarationData.otherReasonProviderChange"
  | "midwifeDeclarationData.otherReasonProviderChange";

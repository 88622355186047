import { Select } from "@jutro/ui";
import { useAtom } from "jotai";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { ProcedureSubForm } from "components/PatientDocs/Ordered/ProcedureSubForm";
import { OrderedCategoryType } from "components/PatientDocs/Ordered/types";
import { customSelectStyles } from "components/PatientDocs/Ordered/utils";
import {
  DoctorLaboratoryExaminationsDocument,
  DoctorMSProcedureType,
  useDoctorCreateLaboratoryExaminationsMutation,
} from "lib/graphql/megaSchema";
import {
  PacketsResponseData,
  useAsyncPacketsOptions,
  useAsyncTestsOptions,
} from "lib/hooks/useAsyncOptions";
import { procedureTypeOptions } from "lib/tools/procedureTitleMap";
import { toaster } from "lib/tools/toaster";
import { referralLoadingAtom } from "views/Visit/RightPanel/Referral/atoms";
import { useVisitData } from "views/Visit/hooks";

export const NewOrderedTestForm = ({
  patientId,
  type,
  visitId,
  onPacketClick,
  isValidationRequired = true,
}: {
  type: OrderedCategoryType | undefined;
  patientId: string;
  visitId: string;
  onPacketClick?: (input: PacketsResponseData) => void;
  isValidationRequired?: boolean;
}) => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  const [procedureType, setProcedureType] = useState<DoctorMSProcedureType>();

  const [referralLoading] = useAtom(referralLoadingAtom);

  const { loadTestsOptions } = useAsyncTestsOptions();

  const { loadPacketsOptions } = useAsyncPacketsOptions();

  const [createLabExaminations] = useDoctorCreateLaboratoryExaminationsMutation(
    {
      refetchQueries: [DoctorLaboratoryExaminationsDocument],
      awaitRefetchQueries: true,
    },
  );

  if (!type) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      {type === "PROCEDURES" && (
        <div>
          <Select
            placeholder="Kliknij i wybierz z listy"
            disabled={referralLoading}
            onChange={(option) => setProcedureType(option?.value)}
            value={
              procedureTypeOptions.find((o) => o.value === procedureType) ??
              null
            }
            options={procedureTypeOptions}
          />
        </div>
      )}

      {type === "LAB" && (
        <AsyncSelect
          loadingMessage={() => "Szukam..."}
          noOptionsMessage={() => "Brak wyników."}
          placeholder="Wpisz nazwę badania"
          styles={customSelectStyles()}
          loadOptions={(search) =>
            loadTestsOptions(
              search,
              "LABORATORY",
              currentVisit?.availableImaginingOrganizationId,
            )
          }
          onChange={async (labExamination: any) => {
            const { cito, id, isNfz } = labExamination;

            await createLabExaminations({
              variables: {
                data: [
                  {
                    patientId: patientId,
                    cito,
                    serviceId: id,
                    payment: isNfz ? "FREE" : "UNPAID",
                    createdOnVisitId: currentVisit?.id ?? null,
                  },
                ],
                createMessageOnChat: false,
              },
            })
              .then(() =>
                toaster.success("Poprawnie dodano nowe badanie laboratoryjne"),
              )
              .catch(() => {
                toaster.warning("Coś poszło nie tak!");
              });
          }}
          value={null}
        />
      )}

      {type === "PACKETS" && (
        <AsyncSelect
          loadingMessage={() => "Szukam..."}
          noOptionsMessage={() => "Brak wyników"}
          placeholder="Wpisz nazwę pakietu badań"
          styles={customSelectStyles()}
          loadOptions={(search) => loadPacketsOptions(search)}
          onChange={(input: any) => {
            if (!input || !onPacketClick) {
              return;
            }
            onPacketClick(input);
          }}
          value={null}
        />
      )}

      {type === "PROCEDURES" && procedureType && (
        <ProcedureSubForm
          type={procedureType}
          visitId={visitId}
          patientId={patientId}
          isValidationRequired={isValidationRequired}
          resetProcedureType={() => {
            setProcedureType(undefined);
          }}
        />
      )}
    </div>
  );
};

import { Defined } from "@jutro/types";
import { Avatar, MessageBox, SolidCircleRemoveIcon } from "@jutro/ui";
import { envVars } from "envvars";
import { Fragment } from "react";
import { avatarConfig } from "lib/tools/httpConfigs";
import { getDeclaredMedic } from "views/Patient/Profile/NfzStatus/tools";

type Props = {
  label: string;
  medic: ReturnType<typeof getDeclaredMedic>;
};
export const MedicDeclarationInfo = ({ label, medic }: Props) => {
  return (
    <div className="grid gap-1">
      <div className="font-label">{label}</div>

      {medic ? (
        <MedicDeclarationContent {...medic} />
      ) : (
        <div className="text-jutro-new-warm-gray-600 *:font-label *:!text-left">
          <MessageBox color="warmGray" text="Brak deklaracji" />
        </div>
      )}
    </div>
  );
};

const MedicDeclarationContent = ({
  id,
  name,
  isDeclarable,
}: Defined<Props["medic"]>) => {
  return (
    <Fragment>
      <div
        className={`flex items-center gap-2 rounded-lg bg-jutro-new-warm-gray-50 p-2.5 ${!isDeclarable ? "outline outline-2 -outline-offset-2 outline-jutro-new-orange-400" : ""}`}
      >
        <Avatar
          type="photo"
          imgSrc={`${avatarConfig[envVars.REACT_APP_CONFIG]}/${id}`}
          size="sm"
        />

        <span className="font-paragraph-1">{name}</span>
      </div>

      {!isDeclarable && (
        <div className="flex items-center gap-1 text-jutro-new-orange-600">
          <SolidCircleRemoveIcon size="sm" />

          <span className="font-label text-jutro-new-orange-800">
            Osoba nieaktywna
          </span>
        </div>
      )}
    </Fragment>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "@jutro/ui";
import { useAtom } from "jotai";
import Cookies from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { authStatusAtom } from "lib/atoms/auth";
import { useDoctorAuthorizeMfaLazyQuery } from "lib/graphql/megaSchema";

const authorizeMfaSchema = z.object({
  mfaCode: z.string(),
});

type AuthorizeMfaType = z.infer<typeof authorizeMfaSchema>;

type Props = {
  phone: string;
};

export const SendMfa = ({ phone }: Props) => {
  const [, setAuthStatus] = useAtom(authStatusAtom);

  const [authorizeMfa, { loading }] = useDoctorAuthorizeMfaLazyQuery({
    fetchPolicy: "network-only",
  });

  const { control, handleSubmit } = useForm<AuthorizeMfaType>({
    resolver: zodResolver(authorizeMfaSchema),
    defaultValues: {
      mfaCode: "",
    },
  });

  const handleAuthorizeMfa = handleSubmit(async (formData) => {
    const { mfaCode } = formData;

    const { data, error } = await authorizeMfa({
      variables: { mfaCode, phone },
    });

    if (!data?.doctorAuthorizeMFA || error) {
      return;
    }

    const { jwt, refreshToken } = data.doctorAuthorizeMFA;

    Cookies.set("JWT", jwt ?? "");
    Cookies.set("RT", refreshToken ?? "");
    setAuthStatus("AUTHORIZED");
  });

  return (
    <form onSubmit={handleAuthorizeMfa}>
      <div className="my-4 flex flex-col gap-4 rounded-lg bg-white p-8">
        <Controller
          control={control}
          name="mfaCode"
          render={({ field, fieldState: { error } }) => (
            <Input
              maxLength={6}
              {...field}
              height="md"
              error={error?.message}
              placeholder="Twój kod"
            />
          )}
        />
        <Button type="submit" text="Przejdź dalej" loading={loading} />
      </div>
    </form>
  );
};

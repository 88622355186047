import { Button } from "@jutro/ui";
import { omit } from "ramda";
import { useEffect, useState } from "react";
import { DoctorTemplateParamsFragment } from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";

type Props = {
  initialParams: DoctorTemplateParamsFragment[];
  onModalClose: () => void;
  onParamsSave: (editedparams: DoctorTemplateParamsFragment[]) => void;
};

const defaultParamsValue = `[
  {
    "name": "",
    "value": "",
    "unit": "",
    "min": "",
    "max": "",
    "text": ""
  }
]`;

export const TemplateModal = ({
  initialParams,
  onModalClose,
  onParamsSave,
}: Props) => {
  const [editedParams, setEditedParams] = useState("");

  useEffect(() => {
    const newInitialParams = initialParams.map((param) => {
      return omit(["__typename"], param);
    });

    const stringifiedInitialParams = JSON.stringify(newInitialParams, null, 4);

    setEditedParams(
      stringifiedInitialParams === "[]"
        ? defaultParamsValue
        : stringifiedInitialParams,
    );
  }, []);

  const handleParamsSave = () => {
    try {
      const parsedParamsJson = JSON.parse(editedParams);

      onParamsSave(parsedParamsJson);
      onModalClose();
    } catch (error) {
      toaster.error(
        `Błedny format, pamiętaj aby dodać "," po kadym parametrze oraz sprawdź czy tekst znajudje się w " "`,
      );
    }
  };

  return (
    <div className="relative z-[100] h-auto w-auto rounded-lg bg-white p-10">
      <h3 className="mb-5 text-center">Dodaj wyniki ręcznie</h3>
      <div>
        <textarea
          className="font-paragraph-1 mb-2 resize-none rounded-md border-2 border-black p-2"
          onChange={(e) => setEditedParams(e.target.value)}
          value={editedParams}
          cols={50}
          rows={20}
        ></textarea>
        <div className="mx-auto flex w-full justify-between">
          <Button
            full={false}
            text="Zamknij"
            variant="negative"
            onClick={onModalClose}
          />
          <Button
            full={false}
            text="Zapisz parametry"
            onClick={handleParamsSave}
          />
        </div>
      </div>
    </div>
  );
};

import { PropsWithChildren } from "react";
import { Maybe } from "lib/types";

type MessageWrapperProps = {
  mine?: Maybe<boolean>;
};

export const MessageWrapper = ({
  children,
  mine,
}: PropsWithChildren<MessageWrapperProps>) => {
  return (
    <div
      className={`my-9 flex flex-col gap-2 ${
        !mine
          ? "bg-jutro-new-warm-gray-50 text-jutro-new-warm-gray-800"
          : "ml-28 bg-jutro-new-blue-500 text-white"
      } calcContainer font-paragraph-2 relative rounded-lg p-4`}
    >
      {children}
    </div>
  );
};

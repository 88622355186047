import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";
import { useDoctorPackagesLazyQuery } from "lib/graphql/megaSchema";
import { useAsyncTestsOptions } from "lib/hooks/useAsyncOptions";
import {
  DrugLabel,
  getDrugDescription,
} from "views/Visit/RightPanel/NewPrescription/components/DrugSelect/Label";

type Props = {
  label: string;
  name: string;
  placeholder?: string;
  type: "USG" | "DRUG";
  disabled?: boolean;
};

export const ControlledAsyncSelect = ({
  name,
  label,
  type,
  placeholder = "Kliknij aby wyszukać lub dodać..",
  disabled,
}: Props) => {
  const { control, setValue, watch } = useFormContext();

  const [option, setOption] = useState<SingleValue<any>>();

  const { loadTestsOptions } = useAsyncTestsOptions();
  const [getPackages] = useDoctorPackagesLazyQuery();

  const loadDrugOptions = async (name: string) => {
    const { data } = await getPackages({ variables: { name } });

    if (!data || data.doctorPackages.length === 0) {
      return [];
    }

    return data.doctorPackages.map((drug) => {
      const { dosage, form, box, name } = drug;
      return {
        value: `${name} ${getDrugDescription(dosage, form, box)}`,
        label: <DrugLabel searchedDrug={drug} />,
        ...drug,
      };
    });
  };

  const getUsgOptions = async (inputValue: string) => {
    const tests = await loadTestsOptions(inputValue, "IMAGE", "PM");

    const filteredTests = tests.filter(
      (test) => test.name.includes("USG") && !test.isNfz,
    );

    const mappedTests = filteredTests.map((test) => {
      return { value: test.id, label: test.name };
    });

    return mappedTests;
  };

  const loadOptions = type === "DRUG" ? loadDrugOptions : getUsgOptions;

  const syncValue = watch(name);

  useEffect(() => {
    if (type === "DRUG" && syncValue) {
      setOption({ value: syncValue, label: syncValue });
    }
  }, [syncValue]);

  return (
    <div className="flex flex-col gap-2">
      <p className="font-paragraph-2">{label}</p>
      <Controller
        name={name}
        control={control}
        render={({
          field: { value, onChange, ...field },
          fieldState: { error },
        }) => (
          <AsyncSelect
            isDisabled={disabled}
            placeholder={placeholder}
            loadingMessage={() => "Szukam..."}
            noOptionsMessage={() => "Brak wyników."}
            loadOptions={loadOptions}
            styles={adjustedToInputSelectStyles(error)}
            value={option}
            onChange={(option) => {
              setOption(option);
              onChange(option?.value);

              if (type === "DRUG") {
                setValue("drug[0].name", option?.value);
              }
            }}
            {...field}
          />
        )}
      />
    </div>
  );
};

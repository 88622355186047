import { PropsWithChildren } from "react";
import { VariantProps, tv } from "tailwind-variants";

const getStyles = tv({
  base: "grid",
  variants: {
    columns: {
      1: "grid-cols-1",
      2: "grid-cols-2",
      4: "grid-cols-4",
    },
    gap: {
      xs: "gap-1",
      sm: "gap-2",
      md: "gap-4",
      none: "gap-0",
    },
    direction: {
      column: "grid-flow-row",
      row: "grid-flow-col grid-cols-none",
    },
  },
  defaultVariants: {
    columns: 1,
    gap: "md",
  },
});

type WrapperVariants = VariantProps<typeof getStyles>;

export const FormBox = ({
  gap,
  columns,
  children,
  direction,
}: PropsWithChildren<WrapperVariants>) => {
  const styles = getStyles({ columns, gap, direction });

  return <div className={styles}>{children}</div>;
};

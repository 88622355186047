import dayjs from "dayjs";
import "moment/locale/pl";
import { DoctorScheduledVisitsQuery } from "lib/graphql/megaSchema";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  getAssignmentTypes,
  getResources,
  mapEventToCategoryId,
} from "views/Schedule/utils";

export const prepareEvents = ({
  data,
  visitTypeToResourceId,
}: {
  data: DoctorScheduledVisitsQuery | undefined;
  visitTypeToResourceId: any;
}) => {
  if (!data?.doctorScheduledVisits) return [];
  return data.doctorScheduledVisits
    .map((visit) => {
      if (!visit.plannedStart?.iso || !visit.duration || !visit.type) {
        return null;
      }

      return {
        ...visit,
        resourceId:
          getResources(data).find(
            (r) => r.resourceId === visitTypeToResourceId(visit),
          )?.resourceId || 1,
        categoryId: mapEventToCategoryId(visit),
        start: new Date(visit.plannedStart.iso),
        end: dayjs(visit.plannedStart.iso)
          .add(visit.duration, "minutes")
          .toDate(),
        title: `${visit.patient?.firstName} ${visit.patient?.lastName}`,
        pesel: `${visit.patient?.pesel}`,
        patient: `${visit.patient?.firstName} ${visit.patient?.lastName}`,
        visitId: visit.id,
        patientId: visit.patient?.id,
        phone: visit.patient?.phone,
        tags: getAssignmentTypes(
          visit.laboratoryExaminations,
          visit.procedures,
        ),
      };
    })
    .filter((e): e is NonNullable<typeof e> => Boolean(e));
};

import { CircleInfoIcon, Tooltip } from "@jutro/ui";
import dayjs from "dayjs";
import { Prescription } from "components/PatientDocs/Prescriptions/types";

type Props = {
  content: Omit<Prescription, "drugs">;
};

export const PrescriptionDetailsTooltip = ({
  content: { issueDate, issuedBy, writeup, place },
}: Props) => {
  return (
    <Tooltip
      position="bottom-start"
      triggerAsChild={false}
      trigger={<CircleInfoIcon />}
      content={
        <div className="flex min-w-[154px] flex-col whitespace-nowrap">
          <div className="rounded-t-lg bg-jutro-new-warm-gray-100 p-2 font-medium">
            Szczegóły recepty
          </div>

          <div className="flex flex-col gap-2 p-2">
            {writeup && (
              <div>
                Wystawiono dnia{" "}
                <b>{dayjs(writeup).format("YYYY-MM-DD HH:mm")}</b>
              </div>
            )}

            {issueDate && (
              <div>
                Data realizacji od dnia{" "}
                <b>{dayjs(issueDate).format("YYYY-MM-DD")}</b>
              </div>
            )}

            {issuedBy && (
              <div className="whitespace-normal">
                Wystawiony przez <b className="">{issuedBy}</b>
              </div>
            )}

            {place && (
              <div className="flex flex-col">
                <div>Miejsce wystawienia</div>{" "}
                <b className="whitespace-normal">{place}</b>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

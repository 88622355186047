import { DiamondSlashIcon } from "@jutro/ui";
import { DoctorMSIncorrectDeclarationEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../NfzEvent";
import { EventHandler } from "../../NfzEvent/infer";

export const IncorrectDeclarationEventHandler: EventHandler<
  DoctorMSIncorrectDeclarationEvent
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_CHECK}
      statusText="Niepoprawna deklaracja"
      icon={<DiamondSlashIcon size="sm" />}
      color="orange"
      tooltip={event.reason}
    />
  );
};

import { ImgHTMLAttributes } from "react";

export const Medic = (props: ImgHTMLAttributes<unknown>) => {
  return (
    <img
      className="absolute bottom-0 right-1/3 hidden h-4/5 xl:right-52 xl:block 2xl:right-72"
      {...props}
    />
  );
};

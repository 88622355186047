import { BinIcon, Button } from "@jutro/ui";
import { useState } from "react";
import { Row } from "react-table";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorGetDocumentsDocument,
  DoctorMSMedicalRecordEntity,
  useDoctorArchiveDocumentMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";

export type ActionButtonProps = {
  row: Row<DoctorMSMedicalRecordEntity>;
};

export const ActionButton = ({ row }: ActionButtonProps) => {
  const [deleteDocumentOpen, setDeleteDocumentOpen] = useState(false);
  const file = row.original;

  const [archiveDocument] = useDoctorArchiveDocumentMutation({
    refetchQueries: [DoctorGetDocumentsDocument],
    awaitRefetchQueries: true,
  });

  const removeFile = async (document: DoctorMSMedicalRecordEntity) => {
    return await archiveDocument({
      variables: {
        id: document.id,
      },
    });
  };

  return (
    <div className="flex space-x-1">
      <Button
        full={false}
        variant="negativeGhost"
        icon={<BinIcon />}
        label="Usuń"
        size="condensed"
        tooltipPosition="top"
        onClick={() => setDeleteDocumentOpen(true)}
      />

      <ConfirmDialog
        title="Usuwanie dokumentu"
        text={`Czy na pewno chcesz usunąć dokument o nazwie ${file.name}?`}
        open={deleteDocumentOpen}
        onConfirm={() => {
          removeFile(file)
            .then(() =>
              toaster.success(`Dokument ${file.name} został usunięty!`),
            )
            .catch(() => toaster.warning("Nie udało się usunąć dokumentu!"));
        }}
        setOpen={setDeleteDocumentOpen}
      />
    </div>
  );
};

import { Badge } from "components/new";
import { Diagnosis } from "lib/graphql/doctor";
import { Maybe } from "lib/types";

type Props = {
  doctorDiagnosis: Maybe<(Diagnosis | null)[]>;
};

export const DoctorDiagnosis = ({ doctorDiagnosis }: Props) => {
  if (!doctorDiagnosis || doctorDiagnosis.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1.5">
      {doctorDiagnosis.map((dd) => {
        if (!dd || !dd.code) {
          return null;
        }

        const [code, text] = dd.code.split(/(?<=^\S+)\s/);

        return (
          <div key={code} className="flex items-center gap-1">
            <Badge key={code} color="green" text={code} />
            <div className="font-paragraph-1 font-semibold text-jutro-new-warm-gray-800">
              {text}
            </div>
          </div>
        );
      })}
    </div>
  );
};

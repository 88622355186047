import { envVars } from "envvars";
import jutroLogo from "assets/logoLight.svg";
import { MessageHeader } from "components/NewMessageUtils/MessageHeader";
import { MessageWrapper } from "components/NewMessageUtils/MessageWrapper";
import { Tile } from "components/new/Tile";
import { avatarConfig } from "lib/tools/httpConfigs";
import { useVisitData } from "views/Visit/hooks";

export const VirtualAssistantChat = () => {
  const { data } = useVisitData({ from: "cache-only" });

  const messages = data?.preInterview ?? [];

  return (
    <Tile
      title="Rozmowa z wirtualnym asystentem"
      basicTileOrId="virtual-assistant-chat"
    >
      {messages.map((message, idx) => (
        <MessageWrapper mine={message.role === "user"} key={idx}>
          <MessageHeader
            mine={message.role === "user"}
            authorImage={
              message.role === "user"
                ? `${avatarConfig[envVars.REACT_APP_CONFIG]}/${data?.patient?.id}`
                : jutroLogo
            }
            authorName={
              message.role === "user"
                ? `${data?.patient?.firstName} ${data?.patient?.lastName}`
                : "WIRTUALNY ASYSTENT"
            }
          />
          {message.content === "[<file>]"
            ? "Wysłano plik. Został on automatycznie dołączony do dokumentacji medycznej."
            : message.content}
          {/* <div className="font-paragraph-2">{message.time}</div> */}
        </MessageWrapper>
      ))}
    </Tile>
  );
};

import dayjs from "dayjs";
import { range } from "ramda";
import { useMemo } from "react";
import { PerDayVisitsQuery } from "lib/graphql/megaSchema";
import { isPastVisit } from "lib/tools/isPastVisit";
import { WORK_HOURS } from "lib/tools/workHours";
import { Slot } from "./types";

export const prepareSlots = (
  visits: PerDayVisitsQuery["doctorGetVisitsPerDayForDoctor"],
  onlyTodo = false,
  currentTime?: dayjs.Dayjs,
) => {
  const workHours = range(WORK_HOURS.START, WORK_HOURS.END);

  const slots = workHours.map((hour) => {
    const hourVisits = visits.filter((visit) => {
      const isInTheHour = visit.plannedStart
        ? dayjs(visit.plannedStart.iso).hour() === hour
        : false;

      const isTodo =
        visit.status !== "SUCCESS" && !isPastVisit(visit, currentTime);

      return isInTheHour && (onlyTodo ? isTodo : true);
    });

    return {
      hour,
      visits: hourVisits,
    };
  });

  return slots.filter((slot) => slot.visits.length > 0);
};

export const useSlots = (
  visits: PerDayVisitsQuery["doctorGetVisitsPerDayForDoctor"] | undefined,
  onlyTodo: boolean,
): Slot[] => {
  return useMemo(() => {
    if (!visits) return [];
    return prepareSlots(visits, onlyTodo);
  }, [visits, onlyTodo]);
};

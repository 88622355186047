import { Value } from "react-date-picker/dist/cjs/shared/types";

export const getDayFromDatePickervalue = (value: Value) => {
  if (!value) {
    throw new Error("Cannot get date from null value");
  }

  if (Array.isArray(value)) {
    throw new Error("Cannot get date from range value");
  }

  return value;
};

import { capitalize } from "radash";
import { AppliedVoucherCodeData } from "components/PatientDocs/Ordered/types";
import { fixedPriceValue } from "components/PatientDocs/Ordered/utils";
import { PacketsResponseData } from "lib/hooks/useAsyncOptions";

type Props = {
  data: PacketsResponseData;
  appliedVoucherCodeData: AppliedVoucherCodeData;
};

export const EcommercePacketSummary = ({
  data,
  appliedVoucherCodeData,
}: Props) => {
  const { price, name, promotion, totalPrice } = data;
  const { voucher, totalPrice: totalCheckoutPrice } = appliedVoucherCodeData;

  return (
    <div className="font-geologica font-paragraph-2 flex flex-col text-jutro-new-warm-gray-800">
      <div className="font-paragraph-2 flex flex-col gap-2 border-b-[1px] border-jutro-new-warm-gray-200 pb-2">
        <div className="flex items-end justify-between">
          <div className="font-paragraph-2 w-[250px] truncate">
            <span className="font-paragraph-2">{capitalize(name)}</span>
          </div>

          <div className="flex flex-col">
            <span
              className={`${
                promotion && "font-light line-through"
              } font-paragraph-2 text-right`}
            >
              {fixedPriceValue(price)}
            </span>
            {promotion && <span>{fixedPriceValue(totalPrice)}</span>}
          </div>
        </div>

        {voucher && (
          <div className="flex justify-between">
            <span>{`Kod ${voucher.name}`}</span>
            <span>{`-${fixedPriceValue(voucher.discount)}`}</span>
          </div>
        )}
      </div>

      {appliedVoucherCodeData.giftCards && (
        <>
          <div className="font-paragraph-2 flex justify-between border-b-[1px] border-jutro-new-warm-gray-200 py-2">
            <span>Suma</span>
            <span>
              {fixedPriceValue(
                data.totalPrice -
                  (appliedVoucherCodeData.voucher?.discount ?? 0),
              )}
            </span>
          </div>

          <div>
            <ul className="flex flex-col gap-2 pt-2">
              {appliedVoucherCodeData.giftCards.map((giftCard) => (
                <li key={giftCard.code}>
                  <div>
                    <div className="flex justify-between">
                      <span>{`Bon prezentowy ${giftCard.code}`}</span>
                      <span>{`-${fixedPriceValue(giftCard.balance)}`}</span>
                    </div>
                    <div className="flex flex-col text-xs font-normal">
                      {giftCard.balanceAfterPayment > 0 && (
                        <span>{`Pozostanie ${fixedPriceValue(
                          giftCard.balanceAfterPayment,
                        )} do wykorzystania`}</span>
                      )}
                      <span>{giftCard.description}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      <div className="font-heading-4 flex justify-between pt-2">
        <span>Do zapłaty</span>
        <span>
          {appliedVoucherCodeData.totalPrice ||
          appliedVoucherCodeData.totalPrice === 0
            ? fixedPriceValue(totalCheckoutPrice)
            : fixedPriceValue(totalPrice)}
        </span>
      </div>
    </div>
  );
};

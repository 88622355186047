import { DragDropContext } from "react-beautiful-dnd";
import { ScreenLoader } from "components/new";
import { OverTheTopInfo } from "components/new/OverTheTopInfo";
import { useOnDragEnd } from "lib/hooks/useDragEnd.hooks";
import { useIsAfterWorkPatient } from "lib/hooks/useIsAfterWorkPatient";
import { useWorkspace } from "lib/tools/createWorkspace/useWorkspace";
import { usePatientData } from "views/Patient/hooks";

export const PatientChatWorkspace = () => {
  const { data, loading } = usePatientData({ from: "network-only" });
  const isAfterWorkPatient = useIsAfterWorkPatient(data);
  const { onDragEnd } = useOnDragEnd();

  const workspace = useWorkspace({ name: "patient-chat", width: 3 });

  if (loading) return <ScreenLoader />;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {workspace}

      {isAfterWorkPatient && (
        <OverTheTopInfo text="- - PACJENT NAPISAŁ PO GODZINACH PRACY PRZYCHODNI - -" />
      )}
    </DragDropContext>
  );
};

import { envVars } from "envvars";
import { CheckableElementProps } from "components/TagFormNew/types";
import { Checkbox } from "components/new";
import { fileConfig } from "lib/tools/httpConfigs";

export const ImageElement = ({
  document,
  isChecked,
  handleChecked,
}: CheckableElementProps) => {
  const { fileId } = document;

  const handleChange = () => {
    if (isChecked) {
      handleChecked((prev) => prev.filter(({ id }) => id !== document.id));
      return;
    }

    handleChecked((prev) => [...prev, document]);
  };

  return (
    <div
      onClick={handleChange}
      className="relative cursor-pointer bg-base-100 shadow-xl"
    >
      <div className="absolute right-2 top-2">
        <Checkbox value={isChecked} />
      </div>
      <img
        src={fileConfig[envVars.REACT_APP_CONFIG] + fileId}
        alt={fileId}
        className="h-[200px] w-[300px] rounded-lg object-cover"
      />
    </div>
  );
};

import { Controller, useFormContext } from "react-hook-form";

type Props = {
  label: string;
  name: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  labelWeight?: "medium" | "bold";
};

export const ControlledTextarea = ({
  name,
  label,
  placeholder = "Kliknij, aby uzupełnić",
  disabled,
  labelWeight = "medium",
  rows,
}: Props) => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      <p
        className={`text-xs ${
          labelWeight === "medium" ? "font-medium" : "font-bold"
        }`}
      >
        {label}
      </p>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <textarea
            {...field}
            className={`min-h-28 w-full rounded-lg border p-2 text-xs focus:border-jutro-new-blue-500 focus:outline-none focus:ring-jutro-new-blue-500 ${error ? "border-jutro-new-rose-500" : "border-jutro-new-warm-gray-300"}`}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
          />
        )}
      />
    </div>
  );
};

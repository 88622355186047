import { atom } from "jotai";
import { OrderedTestListItem } from "components/PatientDocs/Ordered/types";
import {
  DoctorMSLaboratoryExaminationEntity,
  DoctorMSProcedureEntity,
} from "lib/graphql/megaSchema";

/**
* The atom to manage ordered test examinations. Stores tests and filtered tests.

* Atom is mainly used in two hooks:
- useOrderedTests
- useCheckboxHandlers
 */
export const orderedTestsAtom = atom<OrderedTestListItem[]>([]);

export const visitTestsAtom = atom<
  (DoctorMSProcedureEntity | DoctorMSLaboratoryExaminationEntity)[]
>([]);

export const testsLoadingAtom = atom(false);

export const isVisitClosedAtom = atom(false);

export const checkedTestsAtom = atom<string[]>([]);

import { Option } from "@jutro/types";
import { Button, MonthPicker, Select } from "@jutro/ui";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { NavigateAction } from "react-big-calendar";

type Props = {
  day: Date;
  vojevodshipsAndClinicsOptions: Option<string>[];
  selectedOrganizationId: string;
  onNavigate: (navigate: NavigateAction) => void;
  setDay: Dispatch<SetStateAction<Date>>;
  setSelectedOrganizationId: Dispatch<SetStateAction<string>>;
};

export const Toolbar = ({
  day,
  vojevodshipsAndClinicsOptions,
  selectedOrganizationId,
  setDay,
  onNavigate,
  setSelectedOrganizationId,
}: Props) => {
  const label = dayjs(day).format("dddd D MMMM").toWellFormed();

  return (
    <div className="flex w-3/4 flex-col gap-4">
      <div className="font-heading-2">
        <span>Harmonogram, {label}</span>
      </div>

      <div className="flex gap-4">
        <Button
          text="Dzisiaj"
          variant="secondary"
          full={false}
          onClick={() => onNavigate("TODAY")}
        />

        <MonthPicker
          value={day}
          maxDetail="month"
          format="D MMMM YYYY"
          onChange={(date) => {
            setDay(date as Date);
          }}
          onLeftArrowClick={() => onNavigate("PREV")}
          onRightArrowClick={() => onNavigate("NEXT")}
        />

        <div className="min-w-[300px] flex-1">
          <Select
            placeholder="Wybierz z listy"
            isSearchable
            options={vojevodshipsAndClinicsOptions}
            value={
              vojevodshipsAndClinicsOptions.find(
                (o) => o.value === selectedOrganizationId,
              ) ?? null
            }
            onChange={(org) => {
              if (!org) return;

              setSelectedOrganizationId(org.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

import { DoctorMSInsuranceStatusEvent } from "lib/graphql/megaSchema";
import { EventHandler, NfzEventToComponent } from "../../NfzEvent/infer";
import { EwusCheckEventHandler } from "./EwusCheck";
import { InsuranceContributionCertificateEventHandler } from "./InsuranceContributionCertificate";
import { PatientInsuranceStatementEventHandler } from "./PatientInsuranceStatement";

const mapping = {
  DoctorMSInsuranceContributionCertificateEvent:
    InsuranceContributionCertificateEventHandler,
  DoctorMSPatientInsuranceStatementEvent: PatientInsuranceStatementEventHandler,
  DoctorMSEwusCheckEvent: EwusCheckEventHandler,
};

export const InsuranceStatusEventHandler: EventHandler<
  DoctorMSInsuranceStatusEvent
> = ({ event }) => {
  return <NfzEventToComponent mapping={mapping} event={event.status} />;
};

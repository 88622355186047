import logoText from "assets/text-jutro.svg";

type Props = {
  phone: string;
};

export const WelcomeTexts = ({ phone }: Props) => {
  return (
    <>
      <div className="mb-8 h-16">
        <img src={logoText} alt="logo" />
      </div>

      <div className="font-display-3 text-left text-jutro-new-warm-gray-700">
        {phone
          ? `Wpisz 6-cyfrowy kod, który został wysłany sms'em na numer ${phone}`
          : "Zaloguj się do aplikacji medycznej"}
      </div>

      {!phone && (
        <div className="font-heading-2 mt-1 text-left text-jutro-new-warm-gray-700">
          Fajnie, że jesteś z nami!
        </div>
      )}
    </>
  );
};

import { AddCircleIcon, Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";
import { focusOnSectionAtom } from "components/FocusableWrapper/atom";
import { IpomList } from "components/IpomForm/IpomList";
import { IpomListItem } from "components/IpomForm/IpomList/IpomListItem";
import { IpomFormSchema } from "components/IpomForm/schema";
import { selectedVisitTabAtom } from "lib/atoms";
import { DrugElement } from "./DrugElement";

type Props = {
  disabled?: boolean;
};

export const DrugsSection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();
  const [, setFocusOnSection] = useAtom(focusOnSectionAtom);
  const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <span className="font-label text-jutro-new-warm-gray-800">
          Farmakoterapia*
        </span>

        <Button
          variant="secondary"
          size="condensed"
          icon={<AddCircleIcon />}
          text="Dodaj leki"
          type="button"
          full={false}
          disabled={disabled}
          onClick={() => {
            setFocusOnSection("prescription-select");
            setSelectedVisitTab("prescription");
          }}
        />
      </div>

      <Controller
        control={control}
        name="medicines"
        render={({
          field: { value, onChange, ref },
          fieldState: { error },
        }) => (
          <IpomList
            emptyListText="Brak leków do wyświetlenia"
            error={error}
            ref={ref}
          >
            {value.map((drug) => (
              <IpomListItem
                key={drug.ean}
                active={drug.active}
                leftElement={<DrugElement drug={drug} />}
                disabled={disabled}
                toggleActive={() =>
                  onChange(
                    value.map((currentItem) =>
                      currentItem.name === drug.name
                        ? { ...currentItem, active: !currentItem.active }
                        : currentItem,
                    ),
                  )
                }
              />
            ))}
          </IpomList>
        )}
      />
    </div>
  );
};

type Props = {
  size: Sizes;
};

type Sizes = 14 | 16 | 24 | 32 | 36 | 40;

export const CalendarIcon = ({ size }: Props) => {
  const sizeMap: Record<Sizes, string> = {
    14: "w-3.5 h-3.5",
    16: "w-4 h-4",
    24: "w-6 h-6",
    32: "w-8 h-8",
    36: "w-9 h-9",
    40: "w-10 h-10",
  };

  return (
    <div
      className={`${sizeMap[size]} grid cursor-pointer place-items-center rounded-lg stroke-current p-2`}
    >
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.25 17.25H2.25C1.85218 17.25 1.47064 17.092 1.18934 16.8107C0.908035 16.5294 0.75 16.1478 0.75 15.75V3.75C0.75 3.35218 0.908035 2.97064 1.18934 2.68934C1.47064 2.40804 1.85218 2.25 2.25 2.25H15.75C16.1478 2.25 16.5294 2.40804 16.8107 2.68934C17.092 2.97064 17.25 3.35218 17.25 3.75V8.25"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 6.75H17.25"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 3.75V0.75"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.75 3.75V0.75"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 17.25C11.25 18.8413 11.8821 20.3674 13.0074 21.4926C14.1326 22.6179 15.6587 23.25 17.25 23.25C18.8413 23.25 20.3674 22.6179 21.4926 21.4926C22.6179 20.3674 23.25 18.8413 23.25 17.25C23.25 15.6587 22.6179 14.1326 21.4926 13.0074C20.3674 11.8821 18.8413 11.25 17.25 11.25C15.6587 11.25 14.1326 11.8821 13.0074 13.0074C11.8821 14.1326 11.25 15.6587 11.25 17.25Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.902 17.25H17.25V14.599"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

import { z } from "zod";
import { telemedicalSlotTypes } from "components/ScheduleVisitForm/tools/staticOptions";
import { ScheduleVisitTileData } from "components/ScheduleVisitForm/types";
import { DoctorMSSlotType } from "lib/graphql/megaSchema";

export const scheduleFormSchema = z.object({
  slotType: z.string().nullable(),
  organizationId: z.string().nullable(),
  doctorId: z.string().nullable(),
  areAdultDoctorsVisible: z.boolean(),
  officeId: z.string().nullable(),
  slotDuration: z.number().nullable(),
  chosenSlotId: z.number().nullable(),
  plannedTime: z.string().nullable(),
  plannedDate: z.string().nullable(),
});

export type ScheduleFormSchema = z.infer<typeof scheduleFormSchema>;

export const defaultScheduleFormData: ScheduleFormSchema = {
  slotType: null,
  organizationId: null,
  doctorId: null,
  areAdultDoctorsVisible: false,
  officeId: null,
  slotDuration: null,
  chosenSlotId: null,
  plannedTime: null,
  plannedDate: null,
};

export const prepareDefaultValue = (
  editedVisitData: ScheduleVisitTileData["visitData"],
): ScheduleFormSchema => {
  if (!editedVisitData) {
    return defaultScheduleFormData;
  }

  const isSlotTypeTelemedical = telemedicalSlotTypes.includes(
    editedVisitData.slotType as DoctorMSSlotType,
  );

  const editedVisitOrganizationId = isSlotTypeTelemedical
    ? editedVisitData.organizationId
    : editedVisitData.organizationId?.slice(0, -1);

  return {
    ...defaultScheduleFormData,
    slotType: editedVisitData.slotType ?? null,
    slotDuration: editedVisitData.duration ?? null,
    organizationId: editedVisitOrganizationId ?? null,
    officeId: editedVisitData.organizationId ?? null,
  };
};

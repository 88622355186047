import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { Option } from "@jutro/types";
import {
  DoctorCreateNFZEventMutation,
  DoctorMSDeclarationMarketingSource,
  DoctorMSDeclarationType,
  DoctorMSNFZEventInput,
  DoctorMSNFZEventType,
  DoctorMSOrganizationIdVojevodship,
  Exact,
} from "lib/graphql/megaSchema";
import {
  DeclarationTypeOptions,
  GenOption,
  GroupedRepresentativeOptions,
  GroupedSelfOptions,
  ProviderChangeReasons,
  StaffDeclarationData,
  StatementType,
} from "views/Patient/Profile/types";

export const statementTypeOptions: Option<StatementType>[] = [
  { value: StatementType.SELF, label: "W swoim imieniu" },
  {
    value: StatementType.REPRESENTATIVE,
    label: "P. ustawowy / Opiekun prawny lub faktyczny",
  },
];

export const groupedSelfOptions: GroupedSelfOptions[] = [
  {
    label:
      "Ustawa z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych (Dz. U. z 2020 r. poz. 1398, z późn.zm.)",
    options: [
      {
        value: "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
        label: "Objęcie ubezpieczeniem zdrowotnym w Rzeczypospolitej Polskiej",
      },
      {
        value: "ART_54_UST_1",
        label: "Uzyskanie decyzji, o której mowa w art. 54 ust. 1 tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_4_LIT_A",
        label: "Art. 2 ust. 1 pkt 4 lit. a tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_4_LIT_B",
        label: "Art. 2 ust. 1 pkt 4 lit. b tej ustawy",
      },
      {
        value: "ART_67_UST_4_7",
        label: "Art. 67 ust. 4-7 tej ustawy",
      },
    ],
  },
  {
    label:
      "Ustawa z dnia 12 marca 2022 r. o pomocy obywatelom Ukrainy w związku z konfliktem zbrojnym na terytorium tego państwa (Dz. U. 2022 poz. 583, 682,683 i 684)",
    options: [
      {
        value: "UA",
        label: "art. 37 ust. 1",
      },
    ],
  },
];

export const groupedRepresentativeOptions: GroupedRepresentativeOptions[] = [
  {
    label:
      "Ustawa z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych (Dz. U. z 2021 r. poz. 1285, z późn.zm.)",
    options: [
      {
        value: "OBJECIE_UBEZPIECZENIEM_ZDROWOTNYM_W_RZECZYPOSPOLITEJ_POLSKIEJ",
        label: "Objęcie ubezpieczeniem zdrowotnym w Rzeczypospolitej Polskiej",
      },
      {
        value: "ART_54_UST_1",
        label: "Uzyskanie decyzji, o której mowa w art. 54 ust. 1 tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_3_LIT_A",
        label: "Art. 2 ust. 1 pkt 3 lit. a tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_3_LIT_B",
        label: "Art. 2 ust. 1 pkt 3 lit. b tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_3_LIT_C",
        label: "Art. 2 ust. 1 pkt 3 lit. c tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_4_LIT_A",
        label: "Art. 2 ust. 1 pkt 4 lit. a tej ustawy",
      },
      {
        value: "ART_2_UST_1_PKT_4_LIT_B",
        label: "Art. 2 ust. 1 pkt 4 lit. b tej ustawy",
      },
      {
        value: "ART_67_UST_4_7",
        label: "Art. 67 ust. 4-7 tej ustawy",
      },
    ],
  },
  {
    label:
      "Ustawa z dnia 12 marca 2022 r. o pomocy obywatelom Ukrainy w związku z konfliktem zbrojnym na terytorium tego państwa (Dz. U. 2022 poz. 583, 682,683 i 684)",
    options: [
      {
        value: "UA",
        label: "art. 37 ust. 1",
      },
    ],
  },
];

export const declarationTypeOptions: DeclarationTypeOptions[] = [
  {
    label: "Deklaracje papierowe organiczne",
    value: {
      form: "PAPER",
      source: "PAPERORGANIC",
    },
  },
  {
    label: "Wizyta pierwszorazowa w placówce",
    value: {
      form: "PAPER",
      source: "PAPERFIRSTVISIT",
    },
  },
  {
    label: "IKP",
    value: {
      form: "IKP",
      source: "IKP",
    },
  },
  {
    label: "EPUAP mail",
    value: {
      form: "EPUAP",
      source: "EPUAP",
    },
  },
  {
    label: "Akcje specjalne",
    value: {
      form: "PAPER",
      source: "PAPERSPECIAL",
    },
  },
];

export const providerChangeReasons: ProviderChangeReasons[] = [
  {
    label: "Zmiana miejsca zamieszkania",
    value: "CHANGE_OF_ADDRESS_OF_RESIDENCE",
  },
  {
    label: "Poprzednia przychodnia zakończyła działalność",
    value: "SERVICE_PROVIDER_CEASING",
  },
  {
    label: "Osiągnięcie 18. roku życia",
    value: "REACHING_ADULTHOOD_WITH_PEDIATRICIAN",
  },
  {
    label: "Inna okoliczność",
    value: "ANOTHER_FACT",
  },
  {
    label: "Z innych przyczyn powstałych po stronie świadczeniodawcy",
    value: "ANOTHER_PROVIDER_REASON",
  },
];

export const genOptions: GenOption[] = [
  { label: "lekarz", value: "DOCTOR" },
  { label: "pielęgniarka", value: "NURSE" },
  { label: "obie", value: "BOTH" },
];

export const howManyProviderChangesOptions = [
  { label: "Po raz pierwszy lub drugi", value: "few" },
  { label: "Trzeci raz lub kolejny", value: "many" },
];

export const prepareNfzEventInput = async (
  patientId: string,
  type: DoctorMSNFZEventType,
  date: Date,
  organizationId: DoctorMSOrganizationIdVojevodship,
  genre: string,
  declarationType: DoctorMSDeclarationType,
  declarationSource: DoctorMSDeclarationMarketingSource,
  staffDeclarationData: StaffDeclarationData,
  createNFZEvent: (
    options?: MutationFunctionOptions<
      DoctorCreateNFZEventMutation,
      Exact<{
        input: DoctorMSNFZEventInput;
      }>
    >,
  ) => Promise<FetchResult<DoctorCreateNFZEventMutation>>,
  legalGuardianId?: string,
) => {
  const input = {
    patientId: patientId,
    type: type,
    executionDate: date,
    organizationId: organizationId,
    data: {
      genre: genre,
      date,
      type: declarationType,
      source: declarationSource,
      npwz: staffDeclarationData?.staffData?.npwz,
      frequentFacilityChangeReason:
        staffDeclarationData?.howManyProviderChanges === "few"
          ? undefined
          : staffDeclarationData?.facilityChangeReason,
      anotherProviderReason:
        staffDeclarationData?.howManyProviderChanges === "few" ||
        staffDeclarationData?.facilityChangeReason !== "ANOTHER_PROVIDER_REASON"
          ? undefined
          : staffDeclarationData.otherReasonProviderChange,
      signerId: legalGuardianId ? legalGuardianId : undefined,
    },
  };

  await createNFZEvent({
    variables: { input: input },
  });
};

export const prepareDeclarationData = (
  staffDeclarationData: StaffDeclarationData,
  date: Date,
  type: string,
  patientId: string,
  organizationId: DoctorMSOrganizationIdVojevodship,
) => {
  return {
    staffId: staffDeclarationData?.staffData?.id ?? "",
    generationDate: date,
    type,
    patientId,
    electronicallySignedDocument: false,
    organizationId,
    facilityChangeReason:
      staffDeclarationData?.howManyProviderChanges === "few"
        ? undefined
        : staffDeclarationData?.facilityChangeReason,
    anotherProviderReason:
      staffDeclarationData?.howManyProviderChanges === "few" ||
      staffDeclarationData?.facilityChangeReason !== "ANOTHER_PROVIDER_REASON"
        ? undefined
        : staffDeclarationData.otherReasonProviderChange,
  };
};

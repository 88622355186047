import { TextArea as JutroTextArea } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import { OpenSnippetListButton } from "components/Snippet/OpenSnippetListButton";

type TextAreaProps = {
  disabled?: boolean;
  placeholder?: string;
  label: string;
  usgProcedure?: boolean;
  snippetType?: "recommendation" | "physicalExamination" | "usg" | "memo";
  withClear?: boolean;
  name: string;
  rows?: number;
};

export const TextArea = ({
  disabled = false,
  placeholder = "Kliknij, aby uzupełnić",
  name,
  label,
  snippetType = "recommendation",
  usgProcedure,
  rows = 6,
}: TextAreaProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { value, onChange, ...restField } }) => (
        <JutroTextArea
          {...restField}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          value={value}
          label={label}
          rows={rows}
          sideLabelElement={
            <OpenSnippetListButton
              size="condensed"
              category={snippetType}
              type={usgProcedure ? "USG" : "DOCS"}
              disabled={disabled}
            />
          }
        />
      )}
    />
  );
};

import { FetchResult } from "@apollo/client";
import { determineAge } from "@jutro/tools";
import {
  Avatar,
  Button,
  ChatBubblesIcon,
  DocumentIcon,
  Tag,
  TextArea,
  Tooltip,
} from "@jutro/ui";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ipomFormModeAtom } from "components/IpomForm/atoms";
import { ImmunizationCardStatus } from "components/PatientDocs/Overview/ImmunizationCardStatus/index";
import { insuranceStatusTagColorMap } from "components/PatientDocs/Overview/tools";
import { formatPatientCardNumber } from "components/PatientDocs/tools";
import { CurrentPatient } from "components/PatientDocs/types";
import { currentUserAtom } from "lib/atoms/auth";
import { useDoctorIsPatientQualifiedForChukQuery } from "lib/graphql/megaSchema";
import { useFlag } from "lib/hooks/flags";
import { useForm } from "lib/hooks/useForm";
import { usePatientInsuranceStatus } from "lib/hooks/usePatientInsuranceStatus";
import { insuranceStatusTextMap } from "lib/hooks/usePatientInsuranceStatus/tools";
import {
  organizationClinicOption,
  useVojevodshipOptions,
} from "lib/hooks/useVojevodshipOptions";
import { TileColumn } from "lib/tools/createWorkspace/types";
import {
  getCurrentWorkspace,
  useTileSystem,
} from "lib/tools/createWorkspace/useTileSystem";
import { avatarConfig } from "lib/tools/httpConfigs";
import { Maybe } from "lib/types";
import { PatientAgeTag } from "./PatientAgeTag/PatientAgeTag";

const getDeclaredInPlace = (
  declaredIn: Maybe<string>,
  vojevodshipsWithCities: organizationClinicOption[],
): Maybe<string> => {
  return vojevodshipsWithCities.find(({ value }) => value === declaredIn)
    ?.label;
};

const getDeclarationInfo = (
  declaredIn: Maybe<string>,
  vojevodshipsWithCities: organizationClinicOption[],
  fallback: string,
): { declaredIn: Maybe<string>; color: "green" | "rose" } => {
  return {
    color: declaredIn ? "green" : "rose",
    declaredIn:
      getDeclaredInPlace(declaredIn, vojevodshipsWithCities) ?? fallback,
  };
};

type Props = {
  patient: CurrentPatient;
  savePatientData: (
    key: string,
    value: string | boolean,
  ) => Promise<FetchResult | undefined>;
};

export const PatientDocsHeader = ({ patient, savePatientData }: Props) => {
  const { register, data } = useForm({
    object: {
      patientInfo: patient.secretData?.description ?? "",
    },
  });

  const [, setIpomFormMode] = useAtom(ipomFormModeAtom);
  const { addTile } = useTileSystem();

  const isIpomEnabled = useFlag("ipom");
  const patientHasIPOM = Boolean(patient.ipomCode) && isIpomEnabled;

  const { loading, insuranceStatus } = usePatientInsuranceStatus(patient.id);
  const text = insuranceStatusTextMap[insuranceStatus];

  const navigate = useNavigate();

  const { guardians } = patient;

  const patientAge = determineAge(patient.pesel ?? "");

  const { vojevodshipsWithCities } = useVojevodshipOptions();

  const { color: declarationBadgeColor, declaredIn } = useMemo(() => {
    return getDeclarationInfo(
      patient.nfzStatus.declaredIn,
      vojevodshipsWithCities,
      "Brak",
    );
  }, [patient.nfzStatus.declaredIn]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
        <div className="flex flex-col items-start gap-2">
          {patientHasIPOM && (
            <Button
              text="Opieka koordynowana: IPOM"
              variant="secondary"
              size="condensed"
              type="button"
              full={false}
              icon={<DocumentIcon />}
              onClick={() => {
                setIpomFormMode("preview");
                addTile("ipom-form", TileColumn.Center);
              }}
            />
          )}
          <Tag
            text={`Deklaracja: ${declaredIn}`}
            color={declarationBadgeColor}
          />
          {!loading && (
            <Tag
              text={`Status ubezpieczenia: ${text}`}
              color={insuranceStatusTagColorMap[insuranceStatus]}
            />
          )}
          {patient.guardians.length > 0 && (
            <Tooltip
              content="Kliknij, aby przejść do profilu"
              position="bottom"
              triggerAsChild={false}
              trigger={
                <Tag
                  onClick={() =>
                    navigate(`/patients/${guardians[0].id}/profile`)
                  }
                  text={`Przedstawiciel ustawowy: ${guardians[0].firstName} ${
                    guardians[0].lastName
                  } (${formatPatientCardNumber(guardians[0].patientCardNumber)})`.toUpperCase()}
                  color="gray"
                />
              }
            />
          )}
          <ImmunizationCardStatus
            immunizationCardStatus={patient.immunizationCardStatus}
            immunizationCardStatusComment={patient.immunizationCardComment}
            declaredIn={declaredIn}
            patientId={patient.id}
            patientAddress={patient.address}
            patientAge={patientAge}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
        <TextArea
          label="Informacje o pacjencie"
          {...register("patientInfo")}
          placeholder="Dodatkowe informacje o pacjencie."
          onBlur={() => savePatientData("description", data.patientInfo)}
          isResizable={false}
        />
      </div>
    </div>
  );
};

export const PatientDocsHeaderTop = ({ patient }: Pick<Props, "patient">) => {
  const [currentUser] = useAtom(currentUserAtom);
  const { addTile, removeTile, getTileInfo } = useTileSystem();

  const { data: IsPatientQualifiedForChukData } =
    useDoctorIsPatientQualifiedForChukQuery({
      variables: {
        id: patient.id,
      },
      fetchPolicy: "no-cache",
    });

  const isChatTileOpenInRightColumn =
    getTileInfo("chat").position === TileColumn.Right;

  const isPatientProfileWorkspace = getCurrentWorkspace() === "patient-profile";

  const getAvatarProps = () => {
    const defaultAvatars = ["patient-avatar.png", "female-avatar.png"];

    if (defaultAvatars.includes(patient.avatar)) {
      return { initials: `${patient.firstName?.[0]}${patient.lastName?.[0]}` };
    }

    return {
      imgSrc: `${avatarConfig[envVars.REACT_APP_CONFIG]}/${patient.id}`,
    };
  };

  if (!patient.firstName || !patient.lastName) return null; // the type is wrong here

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
      <div className="flex gap-2">
        <Avatar size="lg" {...getAvatarProps()} />
        <div className="flex flex-1 flex-col gap-1.5">
          <span className="font-heading-4">{`${patient.firstName} ${patient.lastName}`}</span>
          <div className="flex flex-wrap gap-2">
            {patient.pesel && patient.pesel.length === 11 ? (
              <Tag text={patient.pesel} color="gray" />
            ) : null}

            {patient.pesel && <PatientAgeTag pesel={patient.pesel} />}

            <Tag
              text={formatPatientCardNumber(patient.patientCardNumber)}
              color="gray"
              variant="inverted"
            />

            {IsPatientQualifiedForChukData?.doctorPatient
              ?.isQualifiedToCHUK && (
              <Tag text="Kwalifikacja do CHUK" color="orange" />
            )}
            <Tag
              text={patient.patientPublicSince ? "TRYB NFZ" : "TRYB POZA NFZ"}
              color="gray"
            />
          </div>
        </div>
        {isPatientProfileWorkspace && currentUser?.genre !== "STAFF" && (
          <div>
            <Button
              icon={<ChatBubblesIcon />}
              label="open chat tile"
              onClick={() => {
                if (isChatTileOpenInRightColumn) {
                  removeTile("chat");
                } else {
                  addTile("chat", TileColumn.Right);
                }
              }}
              withTooltip={false}
              variant={isChatTileOpenInRightColumn ? "primary" : "ghost"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

import { NetworkStatus } from "@apollo/client";
import { determineBirthday } from "@jutro/tools";
import { pick } from "ramda";
import { useEffect, useState } from "react";
import { getUserGender } from "components/PatientDocs/tools";
import { AddVital } from "components/PatientDocs/types";
import {
  DoctorGetPatientDocument,
  DoctorPatientProfileVisitDocument,
  useDoctorCreateVitalMutation,
  useDoctorPatientProfileVisitQuery,
  useDoctorRevokeAssignmentMutation,
  useDoctorUpdatePatientMutation,
} from "lib/graphql/megaSchema";
import { PatientVitality } from "lib/types";

type PatientData = {
  firstName: any;
  lastName: any;
  citizenship: any;
  email: any;
  phone: any;
  pesel: any;
  passportNumber: any;
};

const defaultsPatientFormData = {
  address: {},
  survey: {},
  birthDate: "",
  gender: "",
  peselNotGivenYet: false,
  flatNumber: "",
};

type PatientFormData = PatientData & typeof defaultsPatientFormData;

export const usePatientDocs = (patientId: string | null) => {
  const [currentExamination, setCurrentExamination] = useState<
    PatientVitality[] | null
  >(null);
  const [patientFormData, setPatientData] = useState(defaultsPatientFormData);

  const {
    data: patientData,
    loading: patientLoading,
    error: patientError,
    networkStatus,
  } = useDoctorPatientProfileVisitQuery({
    variables: { id: patientId },
    skip: !patientId,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (patientData && patientData.doctorPatient) {
      const { doctorPatient } = patientData;

      const { survey, address } = doctorPatient;

      setPatientData({
        ...doctorPatient,
        survey: survey || {},
        address: address || {},
        birthDate: determineBirthday(doctorPatient.pesel ?? ""),
        gender: getUserGender(doctorPatient.pesel),
        peselNotGivenYet: doctorPatient.pesel?.length === 12,
        flatNumber: doctorPatient.address?.flatNumber ?? "",
      });
    }
  }, [patientData]);

  const [updatePatient, { loading: updatePatientLoading }] =
    useDoctorUpdatePatientMutation({
      refetchQueries: [
        DoctorGetPatientDocument,
        DoctorPatientProfileVisitDocument,
      ],
      awaitRefetchQueries: true,
    });

  const savePatientData = async (key: string, value: string | boolean) => {
    if (!patientData?.doctorPatient?.id) return;

    return await updatePatient({
      variables: {
        id: patientData.doctorPatient.id,
        input: {
          secretData: {
            [key]: value,
          },
        },
      },
    });
  };

  const [revokeAssignmentMutation] = useDoctorRevokeAssignmentMutation({
    refetchQueries: [DoctorPatientProfileVisitDocument],
  });

  const revokeAssignment = (id: string) => {
    revokeAssignmentMutation({ variables: { id } });
  };

  const savePatientDetails = async (basicFormInput = patientFormData) => {
    const input = pick(
      [
        "firstName",
        "lastName",
        "citizenship",
        "address",
        "email",
        "phone",
        "pesel",
        "passportNumber",
      ],
      basicFormInput as PatientFormData,
    );

    return await updatePatient({
      variables: {
        id: patientData?.doctorPatient?.id ?? "",
        input: {
          ...input,
          //@ts-ignore
          address: input.address,
        },
      },
    });
  };

  const [addVitalMutation] = useDoctorCreateVitalMutation({
    refetchQueries: [DoctorPatientProfileVisitDocument],
  });

  const addVital = ({
    files,
    customName,
    customComment,
    customDate,
  }: AddVital) => {
    if (!patientId) return;

    addVitalMutation({
      variables: {
        input: {
          type: "OTHER",
          patientId,
          overrideFiles: files,
        },
        specificInput: {
          customName,
          customComment,
          customDate,
        },
      },
    });
  };

  return {
    patientData,
    patientError,
    patientLoading: patientLoading || networkStatus === NetworkStatus.refetch,
    currentExamination,
    patientFormData,
    updatePatientLoading,
    savePatientData,
    setCurrentExamination,
    savePatientDetails,
    addVital,
    revokeAssignment,
  };
};

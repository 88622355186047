import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { Loader } from "components/new/Loader";
import {
  DoctorGetDocumentsDocument,
  useDoctorCreateDocumentMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { uploadFile } from "lib/tools/uploadFile";
import { Maybe } from "lib/types";

type DropzoneProps = {
  patientId: Maybe<string>;
};

export const MedicalDocumentationDropzone = ({ patientId }: DropzoneProps) => {
  const [createDocument] = useDoctorCreateDocumentMutation({
    refetchQueries: [DoctorGetDocumentsDocument],
    awaitRefetchQueries: true,
  });

  const { getRootProps, getInputProps, open, isDragActive, acceptedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: {
        "image/*": [".jpeg", ".jpg", ".png"],
        "application/pdf": [".pdf"],
      },
      onDropRejected: () =>
        toaster.error(
          "Wystąpił błąd. Akceptowane typy plików: .jpeg, .jpg, .pdf, .png",
        ),
    });

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const submitFiles = async () => {
      if (acceptedFiles.length === 0) {
        return;
      }
      try {
        await addFiles(acceptedFiles);
        toaster.success("Dodano plik!");
      } catch {
        toaster.success("Nie udało się dodać pliku!");
      }
    };
    submitFiles();
  }, [acceptedFiles]);

  const addFiles = async (acceptedFiles: readonly FileWithPath[]) => {
    try {
      const temp = () =>
        Promise.all(
          acceptedFiles.map(async (file) => ({
            name: file.name,
            createdAt: dayjs().format("YYYY-MM-DD HH:mm"),
            source: "DRIVE" as const,
            fileId: await uploadFile(file),
          })),
        );

      setIsUploading(true);
      const newData = await temp();
      setIsUploading(false);

      newData.forEach((document) => {
        createDocument({
          variables: {
            input: {
              ...document,
              patientId: patientId ?? "",
            },
          },
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      className={`${
        isDragActive ? "bg-jutro-new-warm-gray-200" : "bg-white"
      } font-paragraph-2 max-h-14 rounded-lg border border-jutro-new-warm-gray-300 p-2 text-center`}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        {isUploading ? (
          <Loader size="xs" />
        ) : (
          <>
            <span className="font-paragraph-2">Przeciągnij i upuść lub </span>
            <input
              type="button"
              onClick={open}
              className="inline cursor-pointer bg-white text-jutro-new-green-400"
              value="wybierz z dysku"
            />
          </>
        )}
      </div>
    </div>
  );
};

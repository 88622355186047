import { Maybe } from "@jutro/types";
import dayjs from "dayjs";
import { DoctorPatientInsuranceStatusQuery } from "lib/graphql/megaSchema";
import { InsuranceStatus } from "lib/hooks/usePatientInsuranceStatus/types";

const isDateToday = (createdAt: Maybe<string>) => {
  if (!createdAt) {
    return false;
  }

  return dayjs(createdAt).isToday();
};

export const getInsuranceStatus = (
  patientInsuranceStatus:
    | DoctorPatientInsuranceStatusQuery["doctorPatientInsuranceStatus"]
    | undefined,
): InsuranceStatus => {
  const wasPositiveToday = isDateToday(
    patientInsuranceStatus?.latestPositive?.createdAt,
  );

  if (wasPositiveToday) {
    return "greenOrStatement";
  }

  const wasInsuranceCheckedToday = isDateToday(
    patientInsuranceStatus?.current?.createdAt,
  );

  if (!wasInsuranceCheckedToday) {
    return "check";
  }

  if (
    !patientInsuranceStatus ||
    !patientInsuranceStatus.current ||
    patientInsuranceStatus.current.__typename === "DoctorMSUnknownEvent"
  ) {
    return "error";
  }

  const { __typename } = patientInsuranceStatus.current.status;

  if (__typename === "DoctorMSInsuranceContributionCertificateEvent") {
    return "contributionCertificate";
  }

  if (__typename === "DoctorMSPatientInsuranceStatementEvent") {
    return "statement";
  }

  const { __typename: ewusEventTypename } =
    patientInsuranceStatus.current.status.event;

  if (ewusEventTypename === "DoctorMSEwusGreenEvent") {
    return "green";
  }

  return "red";
};

export const insuranceStatusTextMap: Record<InsuranceStatus, string> = {
  greenOrStatement: "Pacjent “zielony” lub oświadczenie",
  check: "Status nieznany",
  error: "Błąd",
  contributionCertificate: "Zaświadczenie od płatnika składek",
  statement: "Pacjent “zielony” lub oświadczenie",
  green: "Pacjent “zielony” lub oświadczenie",
  red: "Pacjent “czerwony”",
};

export const wasPatientInsuranceStatementEventEmittedToday = (
  patientLatestEwusStatus:
    | DoctorPatientInsuranceStatusQuery["doctorPatientInsuranceStatus"]
    | undefined,
) => {
  if (!patientLatestEwusStatus) {
    return false;
  }

  const { current } = patientLatestEwusStatus;

  if (!current) {
    return false;
  }

  const { __typename } = current;

  if (__typename === "DoctorMSUnknownEvent") {
    return false;
  }

  if (current.status.__typename !== "DoctorMSPatientInsuranceStatementEvent") {
    return false;
  }

  return isDateToday(current.status.createdAt);
};

import { Button, CallIcon } from "@jutro/ui";
import { Loader } from "components/new";
import { Props } from "./types";

export const Actions = ({ isDisabled, isLoading, onClick }: Props) => {
  if (isLoading) {
    return (
      <div className="flex h-12 w-12 items-center justify-center">
        <Loader size="xs" />
      </div>
    );
  }

  return (
    <Button
      full={false}
      icon={<CallIcon />}
      label="Nawiąż połączenie"
      tooltipPosition="top"
      onClick={onClick}
      variant="ghost"
      disabled={isDisabled}
    />
  );
};

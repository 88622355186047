import { Maybe, Option } from "@jutro/types";
import { Select } from "@jutro/ui";
import { DoctorMSDiagnosis, DoctorVisitQuery } from "lib/graphql/megaSchema";
import { getIcd10Option } from "views/Visit/RightPanel/Visit/components/Icd10Select/tools";
import { useSearchIcd10Options } from "views/Visit/RightPanel/Visit/components/Icd10Select/useSearchIcd10Options";

type Visit = NonNullable<DoctorVisitQuery["doctorVisit"]>;

type Patient = NonNullable<Visit["patient"]>;

type Props = {
  label?: string;
  disabled?: boolean;
  tags?: Patient["tags"];
  value: Maybe<string>;
  onChange: (option: Option<string> | null) => void;
  alreadySelectedOptions?: DoctorMSDiagnosis[];
  error?: string;
  onBlur?: () => void;
  handleClear?: () => void;
};

export const Icd10Select = ({
  label,
  disabled = false,
  tags,
  value,
  onChange,
  alreadySelectedOptions,
  error,
  onBlur,
  handleClear,
}: Props) => {
  const { searchIcd10Options } = useSearchIcd10Options(
    tags,
    alreadySelectedOptions,
  );

  return (
    <Select
      label={label}
      placeholder="Wpisz nazwę choroby lub kod ICD10"
      noOptionsMessage="Brak rozpoznań do wyboru"
      disabled={disabled}
      isSearchable
      isAsync
      loadOptions={searchIcd10Options}
      value={value ? getIcd10Option(value, handleClear) : null}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
    />
  );
};

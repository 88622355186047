import { useAtom } from "jotai";
import { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { currentUserAtom } from "lib/atoms/auth";
import { useOnDragEnd } from "lib/hooks/useDragEnd.hooks";
import { useWorkspace } from "lib/tools/createWorkspace/useWorkspace";
import { isStaffManagerId, isTestUserPhone } from "lib/tools/auth";

export const SnippetsAdminWorkspace = () => {
  const workspace = useWorkspace({ name: "snippets-admin", width: 3 });
  const { onDragEnd } = useOnDragEnd();
  const [currentUser] = useAtom(currentUserAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isStaffManagerId(currentUser?.id) &&
      !isTestUserPhone(currentUser?.phone)
    ) {
      navigate("/");
    }
  }, []);

  return <DragDropContext onDragEnd={onDragEnd}>{workspace}</DragDropContext>;
};

import { SolidCircleAlertIcon } from "@jutro/ui";
import { Children, PropsWithChildren, forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { EmptyIpomListInfo } from "./EmptyIpomListInfo";

type Props = {
  emptyListText: string;
  error?: FieldError;
};

export const IpomList = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  ({ error, emptyListText, children }, ref) => {
    const childLength = Children.count(children);

    return (
      <div className="flex flex-col gap-1" tabIndex={0} ref={ref}>
        {childLength > 0 ? (
          children
        ) : (
          <EmptyIpomListInfo text={emptyListText} error={Boolean(error)} />
        )}

        {error && (
          <div className="font-label flex items-center gap-1 text-jutro-new-rose-600">
            <SolidCircleAlertIcon size="sm" />
            <p className="text-jutro-new-rose-800">{error.message}</p>
          </div>
        )}
      </div>
    );
  },
);

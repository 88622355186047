import { Button, Tooltip } from "@jutro/ui";
import ObjectID from "bson-objectid";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Block } from "components/Block";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { ChukQualification } from "components/PatientDocs/Ordered/ChukQualification";
import { Filters } from "components/PatientDocs/Ordered/Filters";
import { NewOrderedTestSection } from "components/PatientDocs/Ordered/NewOrderedTestSection";
import { OrderedTestsList } from "components/PatientDocs/Ordered/OrderedTestsList";
import { PaymentModal } from "components/PatientDocs/Ordered/PaymentModal";
import { useHandleTestsCheckboxes } from "components/PatientDocs/Ordered/hooks/useHandleTestsCheckboxes";
import { Scrollbar } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import {
  DoctorLaboratoryExaminationsDocument,
  DoctorProceduresDocument,
  useDoctorIsPatientQualifiedForChukQuery,
  useDoctorUpdateLaboratoryExaminationMutation,
  useDoctorUpdateProcedureMutation,
} from "lib/graphql/megaSchema";
import { PacketsResponseData } from "lib/hooks/useAsyncOptions";
import { toaster } from "lib/tools/toaster";
import { useVisitData } from "views/Visit/hooks";

type Props = {
  patientId: string;
  patientEmail?: string | null;
};

export const OrderedTests = ({ patientId, patientEmail }: Props) => {
  const { data: currentVisit } = useVisitData({ from: "cache-only" });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedPacket, setSelectedPacket] =
    useState<PacketsResponseData | null>(null);

  const { visitId } = useParams<{ visitId: string }>();

  const {
    selectedPaidTestsList,
    selectedNotPaidTestsList,
    selectedTestsList,
    selectedLabExamIdsList,
    selectedProceduresIdsList,
  } = useHandleTestsCheckboxes();

  const [updateProcedure, { loading }] = useDoctorUpdateProcedureMutation({
    refetchQueries: [DoctorProceduresDocument],
  });

  const [updateLaboratoryExaminations, { loading: updateLabExamsLoading }] =
    useDoctorUpdateLaboratoryExaminationMutation({
      awaitRefetchQueries: true,
      refetchQueries: [DoctorLaboratoryExaminationsDocument],
    });

  const { data: IsPatientQualifiedForChukData } =
    useDoctorIsPatientQualifiedForChukQuery({
      variables: {
        id: patientId,
      },
      fetchPolicy: "no-cache",
    });

  const deleteElement = async () => {
    if (selectedLabExamIdsList.length > 0) {
      try {
        await updateLaboratoryExaminations({
          variables: {
            ids: selectedLabExamIdsList,

            data: { cancelled: true },
          },
        });
      } catch (e) {
        toaster.warning("Nie udało się usunąć badania laboratoryjnego.");
      }
    }

    if (selectedProceduresIdsList.length > 0) {
      try {
        await Promise.all(
          selectedProceduresIdsList.map(async (procedureId: string) => {
            await updateProcedure({
              variables: {
                procedureId,
                data: {
                  cancelled: true,
                },
                dataInput: {},
              },
            });
          }),
        );
      } catch (e) {
        toaster.warning("Nie udało się usunąć procedury medycznej.");
      }
    }
  };

  const handlePacketClick = (input: PacketsResponseData) => {
    setSelectedPacket(input);
    setIsPaymentModalOpen(true);
  };

  const handleAddProcedures = async () => {
    try {
      const labExamsPromises = selectedLabExamIdsList.map(
        async (labExamId: string) => {
          const groupId = new ObjectID().toHexString();
          await updateLaboratoryExaminations({
            variables: {
              ids: labExamId,

              data: { groupId, visitId },
            },
          });
        },
      );

      const proceduresPromises = selectedProceduresIdsList.map(
        async (procedureId: string) => {
          await updateProcedure({
            variables: {
              dataInput: {},
              procedureId,
              data: {
                visitId,
              },
            },
          });
        },
      );

      await Promise.all([...labExamsPromises, ...proceduresPromises]);
    } catch (e) {
      toaster.warning("Nie udało się dodać procedur medycznych.");
    }
  };

  const isPaymentButtonVisible =
    selectedNotPaidTestsList.length > 0 && selectedPaidTestsList.length === 0;

  const isAddTestsToVisitButtonVisible =
    currentVisit?.type === "PROCEDURE" &&
    selectedNotPaidTestsList.length === 0 &&
    selectedPaidTestsList.length > 0;

  return (
    <Scrollbar>
      <div className="flex h-full flex-col gap-4">
        {IsPatientQualifiedForChukData?.doctorPatient?.isQualifiedToCHUK && (
          <Block>
            <ChukQualification />
          </Block>
        )}

        <NewOrderedTestSection
          patientId={patientId}
          onPacketClick={handlePacketClick}
        />

        <div className="flex h-full flex-col justify-between gap-4">
          <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
            <Filters patientId={patientId} visitId={visitId} />
            <OrderedTestsList patientId={patientId} />
          </div>

          {(selectedLabExamIdsList.length > 0 ||
            selectedProceduresIdsList.length > 0) && (
            <div className="sticky bottom-0 grid h-full max-h-12 w-full bg-transparent">
              <div className="flex w-full gap-2 self-end rounded-lg bg-white p-2">
                {isAddTestsToVisitButtonVisible && (
                  <Button
                    full={false}
                    onClick={handleAddProcedures}
                    text="Dodaj do wizyty"
                    disabled={loading || updateLabExamsLoading}
                    loading={loading || updateLabExamsLoading}
                    size="condensed"
                  />
                )}
                {isPaymentButtonVisible && (
                  <Button
                    full={false}
                    onClick={() => {
                      setIsPaymentModalOpen(true);
                      setSelectedPacket(null);
                    }}
                    text="Wybierz metodę płatności"
                    size="condensed"
                  />
                )}
                {selectedTestsList.length > 0 && (
                  <Tooltip
                    disabled={
                      !selectedTestsList.some(
                        (test) =>
                          isLaboratoryExamination(test) &&
                          test.payment === "PAID",
                      )
                    }
                    position="top"
                    content="Nie można usunąć opłaconych przez pacjenta badań"
                    trigger={
                      <div>
                        <Button
                          full={false}
                          onClick={() => setIsDeleteModalOpen(true)}
                          text="Usuń zaznaczone"
                          variant="negative"
                          disabled={
                            loading ||
                            selectedTestsList.some(
                              (test) =>
                                isLaboratoryExamination(test) &&
                                test.payment === "PAID",
                            )
                          }
                          loading={loading}
                          size="condensed"
                        />
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmDialog
        title="Usuń zaznaczone pozycje"
        text="Czy na pewno chcesz usunąć zaznaczone pozycje?"
        confirmText="Usuń"
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onConfirm={deleteElement}
      />
      <PaymentModal
        data={selectedPacket ?? selectedNotPaidTestsList}
        onCtaClick={selectedPacket ? setSelectedPacket : undefined}
        patientEmail={patientEmail}
        patientId={patientId}
        isPaymentModalOpen={isPaymentModalOpen}
        setIsPaymentModalOpen={setIsPaymentModalOpen}
      />
    </Scrollbar>
  );
};

import { AddCircleIcon, Banner, Button } from "@jutro/ui";
import { envVars } from "envvars";
import { useParams } from "react-router-dom";
import { Loader } from "components/new";
import {
  useDoctorGetPatientDocsLazyQuery,
  useDoctorGetPatientQuery,
} from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { fileConfig } from "lib/tools/httpConfigs";
import { toaster } from "lib/tools/toaster";
import { EwusStatus } from "views/Patient/Profile/EwusStatus";
import { NfzEventList } from "views/Patient/Profile/NfzEventList";
import { NfzStatus } from "views/Patient/Profile/NfzStatus";
import { UpcomingVisits } from "views/Patient/Profile/UpcomingVisits";

export const PatientEvents = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { addTile } = useTileSystem();

  const { data, loading } = useDoctorGetPatientQuery({
    variables: {
      id: patientId,
    },
  });

  const [getPatientDocs, { loading: patientDocsLoading }] =
    useDoctorGetPatientDocsLazyQuery();

  const handleGetPatientDocs = async () => {
    toaster.notify("Generuje dokumentację pacjenta");

    if (!patientId) {
      toaster.error("Brak id pacjenta!");

      return;
    }
    try {
      const { data } = await getPatientDocs({ variables: { patientId } });

      if (!data) {
        throw new Error();
      }

      const { doctorGetPatientDocs: docsId } = data;

      window
        .open(`${fileConfig[envVars.REACT_APP_CONFIG]}${docsId}`, "_blank")
        ?.focus();
    } catch {
      toaster.error("Nie udało się wygenerować dokumentacji pacjenta");
    }
  };

  if (loading || !data?.doctorPatient) return <Loader screen />;

  const isPatientDeclared = Boolean(data.doctorPatient.nfzStatus.declaredIn);

  return (
    <div className="relative flex h-full flex-col gap-y-2 overflow-y-auto">
      <UpcomingVisits />

      {!isPatientDeclared && (
        <Banner
          title="Brak deklaracji"
          description="Pacjent musi złożyć deklarację przed rozpoczęciem wizyty"
          full
          type="negative"
          actionButton={{
            icon: <AddCircleIcon />,
            text: "Dodaj deklarację",
            onClick: () => {
              addTile("patient-declaration-creator", TileColumn.Right);
            },
          }}
        />
      )}

      <NfzStatus />
      <EwusStatus />
      <NfzEventList />

      <div className="sticky inset-x-0 bottom-0 rounded-lg bg-white p-4">
        <Button
          text="Wygeneruj dokumentację pacjenta"
          loading={patientDocsLoading}
          onClick={handleGetPatientDocs}
        />
      </div>
    </div>
  );
};

import { TextArea } from "@jutro/ui";
import { Controller, useFormContext } from "react-hook-form";
import { IpomFormSchema } from "components/IpomForm/schema";
import { OpenSnippetListButton } from "components/Snippet/OpenSnippetListButton";

type Props = {
  disabled?: boolean;
};

export const SummarySection = ({ disabled }: Props) => {
  const { control } = useFormContext<IpomFormSchema>();

  return (
    <div className="flex flex-col gap-1 rounded-lg bg-white p-4">
      <Controller
        control={control}
        name="summary"
        render={({ field, fieldState: { error } }) => (
          <TextArea
            {...field}
            disabled={disabled}
            label="Dodatkowe zalecenia*"
            sideLabelElement={
              <OpenSnippetListButton
                size="condensed"
                category="recommendation"
                type="DOCS"
                disabled={disabled}
              />
            }
            rows={2}
            error={error?.message}
          />
        )}
      />
    </div>
  );
};

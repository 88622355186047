import { MultiValue } from "react-select";
import { ArrayElement } from "lib/types";
import { prepareEvents } from "views/Schedule/tools";

export type MappedEvent = {
  showOn?: string | null;
  startOn?: string | null;
  snapshotAt?: string | null;
  leftOn?: string | null;
};

export enum EventCategoryType {
  DEFAULT = "DEFAULT",
  SHOW_ON = "SHOW_ON",
  START_ON = "START_ON",
  CLOSED = "CLOSED",
  NO_SHOW = "NO_SHOW",
  LEFT_ON = "LEFT_ON",
}

export enum ResourceType {
  "Telemedyczne" = 1,
  "Osobiste" = 2,
  "Zabiegowe" = 3,
  "USG" = 4,
}

export enum TileColor {
  JUTRO_GRAY_DARK = "#333330",
  JUTRO_BLUE = "#0A40A1",
  JUTRO_GREEN = "#26C299",
  JUTRO_GREEN_DIM = "#78d6bd",
  JUTRO_GRAY_LIGHT = "#C9C6C5",
}

export type SelectedDoctors = MultiValue<{
  label: string;
  value: string;
}>;

export type Resource = Exclude<
  ArrayElement<ReturnType<typeof prepareEvents>>,
  null
>;

import { MutableRefObject } from "react";

const roundNumber = (num: number) => Math.round(num);

export const scaleCoordinates = (
  crop: {
    unit: string;
    width: number;
    height: number;
    x: number;
    y: number;
  },
  imageRef: MutableRefObject<any>
) => {
  const width =
    imageRef?.current?.height > 600
      ? (imageRef?.current?.width / imageRef?.current?.height) * 600
      : imageRef?.current?.width;
  const height =
    imageRef?.current?.height > 600 ? 600 : imageRef?.current?.height;
  const normalWidth = imageRef?.current?.naturalWidth;
  const normalHeight = imageRef?.current?.naturalHeight;
  return {
    left: (crop.x / width) * normalWidth,
    top: (crop.y / height) * normalHeight,
    right: ((crop.x + crop.width) / width) * normalWidth,
    bottom: ((crop.y + crop.height) / height) * normalHeight,
  };
};

export const roundCoordinates = (scaledCoordinates: {
  left: number;
  top: number;
  right: number;
  bottom: number;
}) => {
  return {
    left: roundNumber(scaledCoordinates.left),
    top: roundNumber(scaledCoordinates.top),
    right: roundNumber(scaledCoordinates.right),
    bottom: roundNumber(scaledCoordinates.bottom),
  };
};

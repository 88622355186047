import { Drug } from "views/Visit/RightPanel/NewPrescription/types";
import { NO_DATA_MESSAGE } from "./tools";

export const PaymentTable = ({ paymentLevels }: Drug) => {
  if (paymentLevels.length === 0) {
    return <span>{NO_DATA_MESSAGE}</span>;
  }

  return (
    <table className="w-full">
      {paymentLevels.map(({ detailedDescription, price, paymentKey }) => {
        return (
          <tr key={paymentKey} className="font-paragraph-2">
            <td className="w-1/2 border p-2">{`Odpłatność ${paymentKey}`}</td>
            <td className="border p-2">
              <div className="flex flex-col gap-1">
                <span className="font-semibold">{`${price} zł`}</span>
                <span>{detailedDescription}</span>
              </div>
            </td>
          </tr>
        );
      })}
    </table>
  );
};

import { Transition } from "@headlessui/react";
import { ReactElement } from "react";

type LinkItem = {
  label: string;
  link: string;
  id: string;
};

export type ActionItem = {
  id: string;
  label: string;
  action: (() => void) | (() => Promise<void>);
};

type Item = LinkItem | ActionItem | null;

type Props = {
  position: "bottom-left" | "top-right" | "bottom-right";
  items: Item[];
  header?: ReactElement;
  active?: boolean;
};

const positionMap = {
  "top-right": "bottom-0 left-full ml-2",
  "bottom-left": "right-0 top-full mt-2",
  "bottom-right": "left-0 top-full mt-2",
};

export const PopupMenu = ({ position, items, header, active }: Props) => (
  <Transition
    show={active}
    enter="transition-opacity duration-150"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className={`${positionMap[position]} no-scrollbar absolute z-50 max-h-96 w-48 overflow-scroll rounded-lg bg-white py-3 shadow-lg ring-1 ring-black ring-opacity-5`}
    >
      <div>{header}</div>

      {items.map((item) => {
        if (!item) {
          return null;
        }
        if ("link" in item) {
          return (
            <div
              id={item.id}
              key={item.label}
              className="font-paragraph-2 block cursor-pointer text-left text-jutro-new-warm-gray-700 hover:bg-jutro-new-warm-gray-100"
            >
              <a
                target="_blank"
                href={item.link}
                className="block w-full px-4 py-2"
                rel="noreferrer"
              >
                <span className="font-paragraph-2 text-jutro-new-warm-gray-700">
                  {item.label}
                </span>
              </a>
            </div>
          );
        }

        return (
          <div
            id={item.id}
            key={item.label}
            className="font-paragraph-2 block cursor-pointer px-4 py-2 text-left text-jutro-new-warm-gray-700 hover:bg-jutro-new-warm-gray-100"
            onClick={item.action}
          >
            <span className="font-paragraph-2 text-jutro-new-warm-gray-700">
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  </Transition>
);

import { Navigate } from "react-router-dom";
import { ScreenLoader } from "components/new";
import { useVisitData } from "views/Visit/hooks";

export const VisitWorkspaceHandler = () => {
  const { data, loading } = useVisitData({ from: "network-only" });

  if (!data || loading) return <ScreenLoader />;

  if (!data.organizationId) {
    return (
      <div className="font-heading-1 grid h-full place-content-center">
        Wybrana wizyta nie posiada przypisanej placówki. Zgłoś błąd do działu
        technicznego.
      </div>
    );
  }

  if (data.type === "PROCEDURE") {
    return <Navigate to="procedure" replace />;
  }

  return <Navigate to="advice" replace />;
};

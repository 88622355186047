import { Banner, CircleLoaderIcon, SendIcon } from "@jutro/ui";
import React, { useState } from "react";

type PrescriptionInfoAlertProps = {
  isVisitInprogress: boolean;
  sendRecommendation: (() => void) | null;
};

export const PrescriptionInfoAlert: React.FC<PrescriptionInfoAlertProps> = ({
  sendRecommendation,
  isVisitInprogress,
}) => {
  const [isSending, setIsSending] = useState(false);

  if (sendRecommendation) {
    return (
      <Banner
        title="Nie widzisz recept, a powinny tu być?"
        description="Na Twoją prośbę pacjent może ręcznie udostępnić Jutro Medical historię wystawionych recept"
        actionButton={{
          onClick: async () => {
            try {
              if (isSending) return;

              setIsSending(true);

              await sendRecommendation();
            } finally {
              setIsSending(false);
            }
          },
          text: "Wyślij instrukcję na czacie",
          icon: isSending ? (
            <div className="animate-spin">
              <CircleLoaderIcon />
            </div>
          ) : (
            <SendIcon />
          ),
        }}
      />
    );
  }

  if (isVisitInprogress) return null;

  return (
    <Banner
      description="Dostęp do historii recept pacjenta jest możliwy wyłącznie podczas
  trwającej wizyty, ponieważ korzystanie z systemu P1 jest rejestrowane."
    />
  );
};

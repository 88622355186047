import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  orderedTestsAtom,
  visitTestsAtom,
} from "components/ExaminationPanel/atoms";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import { isProcedure } from "components/ExaminationPanel/utils/isProcedure";
import {
  Checkable,
  ExaminationTypeValues,
  OrderedTestListItem,
  PaymentTypeValues,
} from "components/PatientDocs/Ordered/types";
import {
  orderedTestTypeFilterOptions,
  paymentStatusOptions,
} from "components/PatientDocs/Ordered/utils";
import { sortLabExaminationsAndProceduresByCitoAndDate } from "components/PatientDocs/tools";
import {
  DoctorLaboratoryExaminationsQuery,
  DoctorMSLaboratoryExaminationEntity,
  DoctorMSProcedureEntity,
  DoctorProceduresQuery,
  useDoctorLaboratoryExaminationsQuery,
  useDoctorProceduresQuery,
} from "lib/graphql/megaSchema";
import { isAdviceProcedure } from "lib/tools";

const getProcedures = (proceduresData: DoctorProceduresQuery | undefined) => {
  if (!proceduresData) {
    return [];
  }

  const { doctorProcedures } = proceduresData;

  if (doctorProcedures.length === 0) {
    return [];
  }

  return doctorProcedures
    .filter((e) => !e.cancelled)
    .filter((e) => !isAdviceProcedure(e.type))
    .map(
      (e) => ({ ...e, checked: false }) as DoctorMSProcedureEntity & Checkable,
    );
};

const getLaboratoryExaminations = (
  labExaminationsData: DoctorLaboratoryExaminationsQuery | undefined,
) => {
  if (!labExaminationsData) {
    return [];
  }

  const { doctorLaboratoryExaminations } = labExaminationsData;

  if (doctorLaboratoryExaminations.length === 0) {
    return [];
  }

  return doctorLaboratoryExaminations
    .filter((e) => !e.cancelled)
    .map(
      (e) =>
        ({ ...e, checked: false }) as DoctorMSLaboratoryExaminationEntity &
          Checkable,
    );
};

const filterOrderedTestsByType = ({
  orderedTests,
  type,
}: {
  orderedTests: OrderedTestListItem[];
  type: ExaminationTypeValues;
}) => {
  if (type === "PROCEDURE") {
    return orderedTests.filter((test) => isProcedure(test));
  }

  if (type === "LAB") {
    return orderedTests.filter((test) => isLaboratoryExamination(test));
  }

  return orderedTests;
};

const filterOrderedTestsByPaymentStatus = ({
  orderedTests,
  paymentStatus,
}: {
  orderedTests: OrderedTestListItem[];
  paymentStatus: PaymentTypeValues;
}) => {
  if (paymentStatus === "PAID") {
    return orderedTests.filter(
      (test) => isProcedure(test) || test.payment === "PAID",
    );
  }

  if (paymentStatus === "UNPAID") {
    return orderedTests.filter(
      (test) => isLaboratoryExamination(test) && test.payment === "UNPAID",
    );
  }

  return orderedTests;
};

type Props = {
  patientId: string;
  visitId?: string;
};

export const useOrderedTests = ({ patientId, visitId }: Props) => {
  const [examinationTypeFilterValue, setExaminationTypeFilterValue] = useState(
    orderedTestTypeFilterOptions[0],
  );
  const [paymentStatusFilter, setPaymentStatusFilter] = useState(
    paymentStatusOptions[0],
  );

  const [allOrderedTests, setAllOrderedTests] = useState<OrderedTestListItem[]>(
    [],
  );

  const [orderedTests, setOrderedTests] = useAtom(orderedTestsAtom);
  const [, setVisitTests] = useAtom(visitTestsAtom);

  const {
    data: proceduresData,
    loading: proceduresLoading,
    error: proceduresError,
  } = useDoctorProceduresQuery({
    fetchPolicy: "no-cache",
    variables: {
      patientId,
    },
  });

  const {
    data: labExaminationsData,
    loading: labExaminationsLoading,
    error: labExaminationsError,
    refetch: labExamsRefetch,
  } = useDoctorLaboratoryExaminationsQuery({
    fetchPolicy: "no-cache",
    variables: {
      patientId,
    },
  });

  // Set ordered tests data
  useEffect(() => {
    const merged = [
      ...getProcedures(proceduresData),
      ...getLaboratoryExaminations(labExaminationsData),
    ];

    const orderedTestsWithoutVisits = merged.filter((test) => !test.visitId);

    setAllOrderedTests(orderedTestsWithoutVisits);
    setOrderedTests(orderedTestsWithoutVisits);

    if (visitId) {
      setVisitTests(merged.filter((e) => e.visitId === visitId) as any);
    }
  }, [proceduresData, labExaminationsData, visitId]);

  // Filtering ordered tests
  useEffect(() => {
    const filteredTestsByType = filterOrderedTestsByType({
      orderedTests: sortedItems,
      type: examinationTypeFilterValue.value,
    });
    const filteredTestsByPaymentStatus = filterOrderedTestsByPaymentStatus({
      orderedTests: filteredTestsByType,
      paymentStatus: paymentStatusFilter.value,
    });
    setOrderedTests(filteredTestsByPaymentStatus);
  }, [examinationTypeFilterValue, paymentStatusFilter]);

  const sortedItems =
    sortLabExaminationsAndProceduresByCitoAndDate(allOrderedTests);

  const notPaidTestsList = orderedTests.filter((test) => {
    if (isLaboratoryExamination(test) && !isProcedure(test)) {
      return test.payment === "UNPAID";
    }
  });

  const paidTestsList = orderedTests.filter((test) => {
    if (isProcedure(test)) {
      return !isAdviceProcedure(test.type);
    }

    return test.payment !== "UNPAID";
  });

  return {
    notPaidTestsList,
    paidTestsList,
    loading: proceduresLoading || labExaminationsLoading,
    error: proceduresError || labExaminationsError,
    // Filters
    examinationTypeFilterValue,
    setExaminationTypeFilterValue,
    paymentStatusFilter,
    setPaymentStatusFilter,
    // Refetch
    labExamsRefetch,
  };
};

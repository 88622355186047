import { Button } from "@jutro/ui";
import { RunOptions, compile, run } from "@mdx-js/mdx";
import * as provider from "@mdx-js/react";
import { MDXContent } from "mdx/types";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as runtime from "react/jsx-runtime";
import { ChukBaseInfoSection } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/Chuk/ChukBaseInfoSection";
import { DynamicInputsGroup } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/DynamicInputsGroup";
import { DynamicSelect } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/DynamicSelect";
import { UsgHeaderSection } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/Usg/UsgHeaderSection";
import { Block } from "components/components/Doctor/Block";
import { BannerIpom } from "components/mdx/BannerIpom";
import { DoctorDiagnosis } from "components/mdx/DoctorDiagnosis";
import { ExaminationHeaderWithContent } from "components/mdx/ExaminationHeaderWithContent";
import { FormBox } from "components/mdx/FormBox";
import { LabelText } from "components/mdx/LabelText";
import { ControlledAsyncSelect } from "components/mdx/MdxInputs/ControlledAsyncSelect";
import { ControlledCheckbox } from "components/mdx/MdxInputs/ControlledCheckbox";
import { ControlledCheckboxGroup } from "components/mdx/MdxInputs/ControlledCheckboxGroup";
import { ControlledInput } from "components/mdx/MdxInputs/ControlledInput";
import { ControlledRadio } from "components/mdx/MdxInputs/ControlledRadio";
import { ControlledScoreInput } from "components/mdx/MdxInputs/ControlledScoreInput";
import { ControlledSelect } from "components/mdx/MdxInputs/ControlledSelect";
import { ControlledTextarea } from "components/mdx/MdxInputs/ControlledTextarea";
import { FileInput } from "components/mdx/MdxInputs/FileInput";
import { ProcedureFormHeader } from "components/mdx/ProcedureFormHeader";
import { Badge } from "components/new";
import { MdTextarea } from "components/new/MdTextarea";
import { RoundedWrapper } from "components/new/RoundedWrapper";
import { ChronicDiseasesMessageBox } from "views/Visit/RightPanel/Visit/components/ChronicDiseasesMessageBox";
import { CoexistsDiagnosisSelect } from "views/Visit/RightPanel/Visit/components/Icd10Select/CoexistsDiagnosisSelect";
import { MainDiagnosisSelect } from "views/Visit/RightPanel/Visit/components/Icd10Select/MainDiagnosisSelect";
import {
  Icd10SelectMdx,
  MultipleSelect,
} from "views/Visit/RightPanel/Visit/components/Select";
import { SendRecommendationButton } from "views/Visit/RightPanel/Visit/components/SendRecommendationButton";
import { TextArea } from "views/Visit/RightPanel/Visit/components/TextArea";

type Props = {
  context: string;
  mdx: string;
};

export const MdxPlaygroundCompile = ({ context, mdx }: Props) => {
  const [Component, setComponent] = useState<{
    Content: MDXContent;
  } | null>(null);
  const [error, setError] = useState("");

  const form = useForm();

  useEffect(() => {
    (async () => {
      setError("");
      if (!mdx) {
        return;
      }

      try {
        const compiledCode = await compile(mdx, {
          outputFormat: "function-body",
        });

        const result = await run(context + compiledCode, {
          ...provider,
          ...(runtime as any),
          Fragment: <></>,
          baseUrl: import.meta.url,
        } as RunOptions);

        setComponent({ Content: result.default });
      } catch (e) {
        setError("Błąd w MDX");
      }
    })();
  }, [mdx, context]);

  if (error) {
    return (
      <div className="flex flex-col gap-2">
        <span className="font-lg font-bold">Wystąpił błąd!</span>

        <span className="text-red-500">{error}</span>
      </div>
    );
  }

  if (!mdx) {
    return <div>Stwórz MDX</div>;
  }

  if (!Component) return <div>Ładowanie...</div>;

  return (
    <div className="flex h-full flex-col justify-between gap-2">
      <FormProvider {...form}>
        <Component.Content
          components={{
            Badge,
            UsgHeaderSection,
            RoundedWrapper,
            ChukBaseInfoSection,
            FormBox,
            BannerIpom,
            LabelText,
            FocusableWrapper: (props) => <div>{props.children}</div>,
            ProcedureFormHeader: (props) => (
              <ProcedureFormHeader
                {...props}
                onRemoveButtonClick={() => alert("CLICKED REMOVE")}
              />
            ),

            DoctorDiagnosis,
            ExaminationHeaderWithContent,

            ControlledInput,
            ControlledRadio,
            ControlledCheckbox,
            ControlledCheckboxGroup,
            FileInput,
            ControlledTextarea,
            DynamicInputsGroup,
            ControlledSelect,
            ControlledAsyncSelect,
            ControlledScoreInput,
            TextArea,
            DynamicSelect,

            PrintUsgButton: (props) => (
              <Button
                {...props}
                full={false}
                onClick={() => alert("CLICKED PRINT")}
              />
            ),
            RedirectToChatButton: (props) => (
              <Button
                {...props}
                full={false}
                onClick={() => alert("CLICKED REDIRECT")}
              />
            ),

            Block,
            MdTextarea,
            Icd10Select: Icd10SelectMdx,
            ChronicDiseasesList: ChronicDiseasesMessageBox,
            MultipleSelect,
            MainDiagnosisCombobox: MainDiagnosisSelect,
            CoexistsDiagnosisCombobox: CoexistsDiagnosisSelect,
            SendRecommendationButton: () => (
              <SendRecommendationButton
                isLoading={false}
                recommendationsMessageId={""}
              />
            ),
          }}
          procedure={{ files: [], id: "testId" }}
          disabled={false}
          riskGroupSelectValue={""}
          bmi={""}
        />
      </FormProvider>

      <pre>
        {Object.values(form.watch()).length > 0
          ? JSON.stringify(form.watch(), null, 2)
          : null}
      </pre>
    </div>
  );
};

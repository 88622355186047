import { tv } from "tailwind-variants";

const getStyles = tv({
  base: "rounded-lg bg-jutro-new-warm-gray-50 p-4",
  variants: {
    error: {
      true: "outline outline-2 -outline-offset-2 outline-jutro-new-rose-600",
    },
  },
});

type Props = {
  text: string;
  error?: boolean;
};

export const EmptyIpomListInfo = ({ text, error }: Props) => {
  return (
    <div className={getStyles({ error: Boolean(error) })}>
      <p className="font-paragraph-2 text-center text-jutro-new-warm-gray-600">
        {text}
      </p>
    </div>
  );
};

import { Button, CloseIcon, Tabs } from "@jutro/ui";
import { useState } from "react";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { RecentlyIssuedDrugs } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RecentlyIssuedDrugs";
import { RegularDrugs } from "./RegularDrugs";

const tabs = [
  { label: "Ostatnio wystawione", value: "recently-issued-drugs" },
  { label: "Leki stałe", value: "regular-drugs" },
];

export const PreviousDrugs = () => {
  const { removeTile } = useTileSystem();
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <div className="h-full w-full rounded-lg text-jutro-new-warm-gray-800">
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
        <div className="flex items-center justify-between">
          <span className="font-heading-2">Poprzednie leki</span>

          <Button
            full={false}
            variant="ghost"
            size="condensed"
            icon={<CloseIcon />}
            label="Zamknij"
            tooltipPosition="bottom"
            onClick={() => removeTile("visit-previous-drugs")}
          />
        </div>

        <Tabs
          options={tabs}
          value={currentTab}
          onChange={setCurrentTab}
          backgroundColor="gray"
        />

        {currentTab.value === "regular-drugs" ? (
          <RegularDrugs />
        ) : (
          <RecentlyIssuedDrugs />
        )}
      </div>
    </div>
  );
};

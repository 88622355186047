import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { useEffect } from "react";
import { HandledTileNumber, createWorkspace } from "lib/tools/createWorkspace";
import {
  defaultWorkspaceTilesMap,
  workspaceTilesAtom,
  workspaceTilesInjectAtom,
  workspaceWidthAtom,
} from "lib/tools/createWorkspace/atoms";
import { Workspace } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

type Props = {
  width: HandledTileNumber;
  name: Workspace;
};

export const useWorkspace = ({ width, name }: Props) => {
  const [workspaceTiles, setWorkspaceTiles] = useAtom(workspaceTilesAtom);
  const [workspaceTilesInject, setWorkspaceTilesInject] = useAtom(
    workspaceTilesInjectAtom,
  );
  const [workspaceWidth, setWorkspaceWidth] = useAtom(workspaceWidthAtom);
  const { addTile } = useTileSystem();

  useEffect(() => {
    setWorkspaceWidth(width);
    setWorkspaceTiles(defaultWorkspaceTilesMap[name].map((tile) => [tile]));

    workspaceTilesInject.forEach(({ tile, column }) => {
      addTile(tile, column);
    });

    setWorkspaceTilesInject(() => []);

    return () => {
      setWorkspaceWidth(RESET);
      setWorkspaceTiles(() => []);
    };
  }, []);

  const workspace = createWorkspace(workspaceTiles, {
    tileNumber: workspaceWidth,
  });

  return workspace;
};

import { Input, MonthPicker, Pill, Select } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import {
  visitsArchiveDateAtom,
  visitsArchiveFiltersAtom,
  visitsArchiveSearchAtom,
  visitsArchiveVojevodshipIdAtom,
} from "views/Visits/Archive/atoms";
import { visitsArchiveFiltersOptions } from "views/Visits/Archive/tools";

export const VisitsArchiveFilters = () => {
  const { vojevodshipsOptions } = useVojevodshipOptions();
  const [vojevodshipId, setVojevodshipId] = useAtom(
    visitsArchiveVojevodshipIdAtom,
  );
  const [startDate, setStartDate] = useAtom(visitsArchiveDateAtom);
  const [filters, setFilters] = useAtom(visitsArchiveFiltersAtom);

  const [searchUrlValue, setSearchUrlValue] = useAtom(visitsArchiveSearchAtom);
  const [searchValue, setSearchValue] = useState("");
  const isTyping = useRef(false);

  const debouncedSetSearchUrlValue = useDebouncedCallback((value: string) => {
    setSearchUrlValue(value);
    isTyping.current = false;
  }, 300);

  useEffect(() => {
    if (!isTyping.current || searchValue === searchUrlValue) {
      return;
    }

    debouncedSetSearchUrlValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (isTyping.current) {
      return;
    }

    setSearchValue(searchUrlValue);
  }, [searchUrlValue]);

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-4">
      <div className="flex items-center gap-2">
        <MonthPicker
          value={startDate.toDate()}
          onChange={(date) => {
            const stringDate = date?.toString();
            setStartDate(dayjs(stringDate).startOf("month"));
          }}
          onLeftArrowClick={() => {
            setStartDate((prev) => prev.subtract(1, "month"));
          }}
          onRightArrowClick={() => {
            setStartDate((prev) => prev.add(1, "month"));
          }}
        />
        <div className="w-64">
          <Select
            value={
              vojevodshipsOptions.find((o) => o.value === vojevodshipId) ?? null
            }
            options={vojevodshipsOptions}
            placeholder="Wybierz placówkę"
            onChange={(org) => setVojevodshipId(org?.value ?? "")}
          />
        </div>
        <div className="w-64">
          <Input
            value={searchValue}
            placeholder="PESEL lub nr karty pacjenta"
            onChange={(e) => {
              isTyping.current = true;
              setSearchValue(e.target.value);
            }}
          />
        </div>

        <div className="w-64">
          <Select
            value={filters}
            options={visitsArchiveFiltersOptions}
            placeholder="Filtruj"
            onRemove={({ value }) => {
              setFilters((prev) =>
                prev.filter((option) => option.value !== value),
              );
            }}
            onChange={(option) => {
              if (
                !option ||
                (option.value === "SHOW_ALL" && filters.length === 0)
              ) {
                return;
              }

              if (option.value === "SHOW_ALL") {
                setFilters([]);
                return;
              }

              setFilters((prev) => [...prev, option]);
            }}
          />
        </div>
      </div>

      {filters.length > 0 ? (
        <div className="flex items-center gap-2">
          {filters.map(({ value, label }) => (
            <div key={value} className="h-fit">
              <Pill
                label={label}
                onClick={() =>
                  setFilters((prev) =>
                    prev.filter((option) => option.value !== value),
                  )
                }
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

import { Button, SolidCircleAlertIcon } from "@jutro/ui";
import { useAtom } from "jotai";
import { forwardRef } from "react";
import { tv } from "tailwind-variants";
import { focusOnSectionAtom } from "components/FocusableWrapper/atom";
import { IpomFormSchema } from "components/IpomForm/schema";
import { selectedVisitTabAtom } from "lib/atoms";
import { DiagnosisItem } from "./DiagnosisItem";

const getStyles = tv({
  base: "flex flex-col gap-1 rounded border border-jutro-new-warm-gray-200 p-2",
  variants: {
    error: {
      true: "outline outline-2 -outline-offset-2 outline-jutro-new-rose-600",
    },
  },
});

type Props = {
  doctorDiagnosis: IpomFormSchema["observations"];
  onItemClick: (item: IpomFormSchema["observations"][number]) => void;
  disabled?: boolean;
  error?: string;
};

export const DiagnosisItemArea = forwardRef<HTMLInputElement, Props>(
  ({ doctorDiagnosis, onItemClick, disabled, error }, ref) => {
    const [, setFocusOnSection] = useAtom(focusOnSectionAtom);
    const [, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);

    return (
      <div className="flex flex-col gap-0.5" ref={ref} tabIndex={0}>
        <div className="flex justify-between">
          <span className="font-label text-jutro-new-warm-gray-800">
            Rozpoznanie*
          </span>

          <Button
            variant="secondary"
            size="condensed"
            text="Zmień rozpoznanie"
            type="button"
            full={false}
            disabled={disabled}
            onClick={() => {
              setFocusOnSection("visit-diagnosis");
              setSelectedVisitTab("visit");
            }}
          />
        </div>
        <div className={getStyles({ error: Boolean(error) })}>
          {doctorDiagnosis.length ? (
            doctorDiagnosis.map((item) => (
              <DiagnosisItem
                key={item.value}
                item={item}
                disabled={disabled}
                onClick={() => onItemClick(item)}
              />
            ))
          ) : (
            <span className="font-paragraph-2 text-center text-jutro-new-warm-gray-600">
              Brak rozpoznań do wyświetlenia
            </span>
          )}
        </div>

        {error && (
          <div className="font-label flex items-center gap-1 text-jutro-new-rose-600">
            <SolidCircleAlertIcon size="sm" />
            <p className="text-jutro-new-rose-800">{error}</p>
          </div>
        )}
      </div>
    );
  },
);

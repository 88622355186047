import { AddIcon, BinIcon, Button, EyeOnIcon } from "@jutro/ui";
import { DoctorMSShiftOutputDynamic } from "lib/graphql/megaSchema";

type ShiftProps = {
  shift: DoctorMSShiftOutputDynamic;
  selectedOrganization: string | undefined;
  isDeletable: boolean;
  openModal: (mode: "create" | "edit") => void;
};

export const Shift = ({
  shift,
  selectedOrganization,
  isDeletable,
  openModal,
}: ShiftProps) => {
  return (
    <div
      className="relative flex h-[68px] w-full flex-col items-center justify-center rounded-lg bg-white py-2"
      style={{
        background: `${selectedOrganization === shift.placeId ? shift.background : "white"}`,
        color: `${selectedOrganization === shift.placeId ? shift.textColor : "black"}`,
        border: `${selectedOrganization === shift.placeId ? "none" : "1px solid #C6C6CD"}`,
      }}
    >
      {isDeletable ? (
        <div className={`text-${shift.textColor}`}>
          <BinIcon />
        </div>
      ) : (
        <>
          <span className="rounded-lg text-center">{`${shift.hourStart}`}</span>
          <span className="rounded-lg text-center">{`${shift.hourEnd}`}</span>
        </>
      )}

      <div
        className={`absolute right-0 top-0 z-10 flex h-full w-full flex-col items-center gap-1 rounded-lg bg-white p-1 opacity-0 hover:opacity-100 ${selectedOrganization === shift.placeId && "border border-jutro-new-warm-gray-300"}`}
      >
        <Button
          label="editSlot"
          icon={<EyeOnIcon size="sm" />}
          variant="ghost"
          withTooltip={false}
          size="condensed"
          onClick={() => openModal("edit")}
        />
        <Button
          label="addSlot"
          icon={<AddIcon size="sm" />}
          variant="ghost"
          withTooltip={false}
          size="condensed"
          onClick={() => openModal("create")}
        />
      </div>
    </div>
  );
};

import { Option } from "@jutro/types";
import { MedicalDocument } from "lib/atoms";
import { DoctorMSPatientTagType } from "lib/graphql/megaSchema";

export const isImage = (name: string) => {
  const lastDotIndex = name.lastIndexOf(".");
  const extension = name.slice(lastDotIndex + 1).toLowerCase();

  return ["jpg", "png", "jpeg", "heic"].includes(extension);
};

export const typeOptions: Option<DoctorMSPatientTagType>[] = [
  {
    value: "ADDITIONAL",
    label: "Informacje dodatkowe",
  },
  {
    value: "ALLERGY",
    label: "Alergie",
  },
  {
    value: "CHRONIC_DISEASE",
    label: "Choroby przewlekłe",
  },
  {
    value: "REGULAR_DRUG",
    label: "Stale przyjmowane leki",
  },
  {
    value: "VACCINES",
    label: "Szczepienia",
  },
  {
    value: "SURGICAL_PROCEDURES",
    label: "Zabiegi operacyjne",
  },
  {
    value: "HOSPITALIZATION",
    label: "Hospitalizacje",
  },
  {
    value: "SPEC_CONSULTATIONS",
    label: "Konsultacje specjalistyczne",
  },
];

export const outDocuments = (
  { tagId }: MedicalDocument,
  editedTagId?: string,
) => {
  if (!tagId) {
    return true;
  }

  return tagId === editedTagId;
};

export const getNameHeader = (currentType: DoctorMSPatientTagType) => {
  switch (currentType) {
    case "CHRONIC_DISEASE":
      return "Nazwa choroby (ICD10)";
    case "REGULAR_DRUG":
      return "Nazwa leku stałego";
    default:
      return "Tytuł";
  }
};

import { useMemo } from "react";
import { useDoctorsSelectQuery } from "lib/graphql/megaSchema";
import { getDoctorOption } from "views/Visits/PerDay/components/Controls/DoctorSelect/tools";

export const useGetDoctorOptions = () => {
  const { data, loading } = useDoctorsSelectQuery();

  const doctorsOptions = useMemo(() => {
    if (loading || !data) return [];

    return data.doctorDoctors.map(getDoctorOption);
  }, [data, loading]);

  return doctorsOptions;
};

import { DoctorMSFacilityChangeEvent } from "lib/graphql/megaSchema";
import { EventHandler, NfzEventToComponent } from "../../../NfzEvent/infer";
import { FacilityChangeInVoivodeshipEventHandler } from "./FacilityChangeInVoivodeship";
import { FacilityChangeOutsideVoivodeshipEventHandler } from "./FacilityChangeOutsideVoivodeship";

const mapping = {
  DoctorMSFacilityChangeInVoivodeshipEvent:
    FacilityChangeInVoivodeshipEventHandler,
  DoctorMSFacilityChangeOutsideVoivodeshipEvent:
    FacilityChangeOutsideVoivodeshipEventHandler,
};

export const FacillityChangeHandler: EventHandler<
  DoctorMSFacilityChangeEvent
> = ({ event }) => {
  return <NfzEventToComponent mapping={mapping} event={event.info} />;
};

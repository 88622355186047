import { NavigationButton } from "@jutro/ui";
import { ComponentProps, useMemo } from "react";
import {
  EdmGetAllSnippetTagsDocument,
  EdmGetAllSnippetsDocument,
  useEdmCreateSnippetMutation,
  useEdmUpdateSnippetMutation,
} from "lib/graphql/megaSchema";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { useEvent } from "lib/tools/events";
import { getDelayRefetchQueryFn } from "lib/tools/getDelayRefetchQueryFn";
import { SnippetFormData, SnippetFormInput } from "../types";
import { SnippetForm } from "./SnippetForm";

export const SnippetFormTile = ({ id, ...props }: SnippetFormData) => {
  const { removeTile } = useTileSystem();
  const isCreateMode = props.mode === "create";

  const options = {
    refetchQueries: [EdmGetAllSnippetsDocument, EdmGetAllSnippetTagsDocument],
    awaitRefetchQueries: true,
    onQueryUpdated: getDelayRefetchQueryFn(100),
  };

  const [createSnippetMutation] = useEdmCreateSnippetMutation(options);
  const [updateSnippetMutation] = useEdmUpdateSnippetMutation(options);

  const sendEvent = useEvent();

  const createSnippet = async (input: SnippetFormInput) => {
    const { data } = await createSnippetMutation({
      variables: {
        input: {
          ...input,
          category: input.category ?? "",
          type: input.type,
        },
      },
    });

    if (data) {
      sendEvent("SNIPPET_CREATED", { snippet_id: data.edmCreateSnippet.id });
    }
  };

  const updateSnippet = async (id: string, input: SnippetFormInput) => {
    const { data } = await updateSnippetMutation({ variables: { id, input } });

    if (data) {
      sendEvent("SNIPPET_UPDATED", { snippet_id: data.edmUpdateSnippet.id });
    }
  };

  const handleCloseTile = () => {
    removeTile(id);
  };

  const snippetFormProps: ComponentProps<typeof SnippetForm> = useMemo(() => {
    if (isCreateMode) {
      return {
        mode: "create",
        onSubmit: createSnippet,
        handleCloseForm: handleCloseTile,
        type: props.type,
        category: props.category,
      };
    }

    return {
      mode: "update",
      snippetData: props.snippetData,
      onSubmit: updateSnippet,
      handleCloseForm: handleCloseTile,
    };
  }, [props.mode]);

  return (
    <div className="h-full rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <div className="font-heading-3">
          {isCreateMode ? "Nowy szablon" : "Edycja szablonu"}
        </div>

        <NavigationButton onClick={handleCloseTile} />
      </div>

      <SnippetForm {...snippetFormProps} />
    </div>
  );
};

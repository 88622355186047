import { analysisTypeMap } from "../../components/PatientDocs/tools";
import { PatientVitality } from "lib/types";

export const mapAnalysisType = (vital: PatientVitality | undefined) => {
  if (!vital) return "";

  const type = vital.type;

  if (type === "OTHER") return vital.customName || "";

  if (type === "ANALYSIS")
    return (
      analysisTypeMap[vital.analysisType.toUpperCase()] || vital.analysisType
    );

  if (vital.operationType)
    return (
      analysisTypeMap[vital.operationType.toUpperCase()] || vital.operationType
    );

  return analysisTypeMap[type.toUpperCase()] || type;
};

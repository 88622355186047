import { useApolloClient } from "@apollo/client";
import ObjectID from "bson-objectid";
import { useAtom } from "jotai";
import { DropResult } from "react-beautiful-dnd";
import { orderedTestsAtom } from "components/ExaminationPanel/atoms";
import { isLaboratoryExamination } from "components/ExaminationPanel/utils/isLaboratoryExamination";
import {
  DoctorLaboratoryExaminationDragInfoDocument,
  DoctorLaboratoryExaminationDragInfoQuery,
  DoctorLaboratoryExaminationDragInfoQueryVariables,
  DoctorLaboratoryExaminationSampleDocument,
  DoctorLaboratoryExaminationSampleQuery,
  DoctorLaboratoryExaminationSampleQueryVariables,
  DoctorLaboratoryExaminationsDocument,
  DoctorProceduresDocument,
  DoctorVisitQuery,
  useDoctorUpdateLaboratoryExaminationMutation,
  useDoctorUpdateProcedureMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { Maybe } from "lib/types";

export const useOnDragEnd = (
  currentVisit?: DoctorVisitQuery["doctorVisit"],
) => {
  const client = useApolloClient();

  const getGroupInfo = async (groupId: string) => {
    const { data } = await client.query<
      DoctorLaboratoryExaminationSampleQuery,
      DoctorLaboratoryExaminationSampleQueryVariables
    >({
      query: DoctorLaboratoryExaminationSampleDocument,
      variables: {
        groupId,
      },
    });

    const [laboratoryExamination] = data.doctorLaboratoryExaminations;

    if (!laboratoryExamination) {
      return {};
    }

    const { sampleId, assignment } = laboratoryExamination;

    return { sampleId, groupMaterial: assignment?.material };
  };

  const getLaboratoryExaminationInfo = async (
    laboratoryExaminationId: string,
  ) => {
    const { data } = await client.query<
      DoctorLaboratoryExaminationDragInfoQuery,
      DoctorLaboratoryExaminationDragInfoQueryVariables
    >({
      query: DoctorLaboratoryExaminationDragInfoDocument,
      fetchPolicy: "no-cache",
      variables: {
        ids: laboratoryExaminationId,
      },
    });

    const [laboratoryExamination] = data.doctorLaboratoryExaminations;

    if (!laboratoryExamination) {
      return {};
    }

    const { payment, assignment } = laboratoryExamination;

    return { payment, laboratoryExaminationMaterial: assignment?.material };
  };

  const [updateLabExaminations] = useDoctorUpdateLaboratoryExaminationMutation({
    refetchQueries: [DoctorLaboratoryExaminationsDocument],
  });

  const [updateProcedure] = useDoctorUpdateProcedureMutation({
    refetchQueries: [DoctorProceduresDocument],
  });

  const handleUpdateLabexaminations = async (
    groupId: string,
    ids: string[] | string,
    visitId?: Maybe<string>,
    sampleId?: Maybe<string>,
  ) => {
    try {
      await updateLabExaminations({
        variables: {
          data: {
            groupId,
            visitId,
            sampleId,
          },
          ids,
        },
      });
    } catch (e) {
      toaster.warning("Nie udało się przenieść badania laboratoryjnego.");
    }
  };

  const [orderedTests] = useAtom(orderedTestsAtom);

  const labExaminationsIds = orderedTests
    .filter((e) => isLaboratoryExamination(e))
    .map((e) => e.id);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!currentVisit || !destination) {
      return;
    }

    if (currentVisit.snapshotAt) {
      toaster.warning("Wizyta jest zamknięta. Wyłączono opcję edycji.");
      return;
    }

    //drop procedure from left to the middle
    if (
      source.droppableId === "proceduresAndExaminations" &&
      destination.droppableId === "middle" &&
      !labExaminationsIds.includes(draggableId)
    ) {
      try {
        await updateProcedure({
          variables: {
            dataInput: {},
            procedureId: draggableId,
            data: {
              visitId: currentVisit.id,
            },
          },
        });
      } catch (e) {
        toaster.warning("Nie udało się przenieść procedury.");
      }

      return;
    }

    //generate random id in order to be able to group laboratory examinations by samples
    const groupId = new ObjectID().toHexString();

    const { payment, laboratoryExaminationMaterial } =
      await getLaboratoryExaminationInfo(draggableId);

    const { sampleId, groupMaterial } = await getGroupInfo(
      destination.droppableId,
    );

    //forbid dropping tile when either source or destination is vaccine
    if (
      Number(laboratoryExaminationMaterial === "szczepienie") ^
        Number(groupMaterial === "szczepienie") &&
      destination.droppableId !== "middle"
    ) {
      toaster.warning("Nie możesz przenieść tej procedury/badania");
      return;
    }

    if (payment === "UNPAID") {
      toaster.warning("Aby wykonać tę akcję, należy najpierw opłacić badanie");
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    }

    //drop laboratoryExamination from left to the middle
    if (
      source.droppableId === "proceduresAndExaminations" &&
      destination.droppableId === "middle" &&
      labExaminationsIds.includes(draggableId)
    ) {
      await handleUpdateLabexaminations(groupId, draggableId, currentVisit.id);

      return;
    }

    //drop laboratoryExamination from one sample to another sample
    if (
      source.droppableId !== "proceduresAndExaminations" &&
      destination.droppableId !== "middle"
    ) {
      await handleUpdateLabexaminations(
        destination.droppableId,
        draggableId,
        undefined,
        sampleId,
      );

      return;
    }

    //drop laboratoryExamination from left to sample
    if (
      source.droppableId === "proceduresAndExaminations" &&
      destination.droppableId !== "middle" &&
      labExaminationsIds.includes(draggableId)
    ) {
      await handleUpdateLabexaminations(
        destination.droppableId,
        draggableId,
        currentVisit.id,
        sampleId,
      );

      return;
    }

    //drop laboratoryExamination from sample to the middle
    if (
      source.droppableId !== "proceduresAndExaminations" &&
      destination.droppableId === "middle"
    ) {
      await handleUpdateLabexaminations(groupId, draggableId, undefined, null);

      return;
    }

    //fallback in case none of the conditions were fulfilled
    toaster.error("Nie udało się wykonać akcji");
  };

  return {
    onDragEnd,
  };
};

import { MedicalDocument } from "lib/atoms";
import {
  DoctorGetDocumentsDocument,
  DoctorMSMedicalDocumentSource,
  useDoctorCreateDocumentMutation,
  useDoctorUpdateDocumentMutation,
} from "lib/graphql/megaSchema";
import { Maybe } from "lib/types";
import { MedicalDocumentationDropzone } from "./MedicalDocumentationDropzone";
import { MedicalDocumentationTable } from "./Table";

type MedicalDocumentationListProps = {
  title?: string;
  patientId: Maybe<string>;
  loading: boolean;
  documents: MedicalDocument[];
  withUpload?: boolean;
  isDisplayOnly?: boolean;
};

export type Document = {
  id: string;
  name?: string;
  createdAt?: string;
  tagId?: string;
  starred?: boolean;
  source?: DoctorMSMedicalDocumentSource;
  fileId?: string;
};

export const MedicalDocumentationList = ({
  title,
  withUpload,
  patientId,
  loading,
  documents,
  isDisplayOnly = false,
}: MedicalDocumentationListProps) => {
  const [, { loading: createDocumentLoading }] =
    useDoctorCreateDocumentMutation({
      refetchQueries: [DoctorGetDocumentsDocument],
      awaitRefetchQueries: true,
    });

  const [, { loading: updateDocumentLoading }] =
    useDoctorUpdateDocumentMutation({
      refetchQueries: [DoctorGetDocumentsDocument],
      awaitRefetchQueries: true,
    });

  return (
    <div className={`flex-col ${title && "p-4"} rounded-lg bg-white`}>
      {title && (
        <div className="flex justify-between pb-2 align-middle">
          <span className="font-paragraph-2 pb-2 text-jutro-new-warm-gray-700">
            {title}
          </span>
        </div>
      )}

      <MedicalDocumentationTable
        data={documents}
        loading={updateDocumentLoading || createDocumentLoading || loading}
        isDisplayOnly={isDisplayOnly}
      />
      {withUpload && <MedicalDocumentationDropzone patientId={patientId} />}
    </div>
  );
};

import { Button, CalendarIcon } from "@jutro/ui";
import { useState } from "react";
import {
  IpomFormSchema,
  controlVisitTimeOptions,
  controlVisitTimeUnitOptions,
} from "components/IpomForm/schema";
import { ControlVisitExecutionModal } from "./ControlVisitExecutionModal";

type Props = {
  disabled?: boolean;
  visit: IpomFormSchema["controlVisits"][number];
  onExecutionChange: (visit: IpomFormSchema["controlVisits"][number]) => void;
};

export const ControlVisitElement = ({
  disabled,
  visit,
  onExecutionChange,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { time, timeUnit, timeValue } = visit;

  const timeLabel = controlVisitTimeOptions.find(
    ({ value }) => value === time,
  )?.label;

  const timeUnitLabel = controlVisitTimeUnitOptions.find(
    ({ value }) => value.timeUnit === timeUnit && value.timeValue === timeValue,
  )?.label;

  const buttonExecutionText = `${timeLabel}${time === "POOKRCZASIE" ? `, ${timeUnitLabel}` : ""}`;

  return (
    <>
      <div className="flex flex-col gap-2">
        <p className="font-paragraph-1">Wizyta kontrolna</p>

        <Button
          icon={<CalendarIcon />}
          size="condensed"
          variant="ghost"
          type="button"
          full={false}
          disabled={disabled}
          onClick={() => setIsModalOpen(true)}
          text={buttonExecutionText}
        />
      </div>

      <ControlVisitExecutionModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        visit={visit}
        onExecutionChange={onExecutionChange}
      />
    </>
  );
};

import { DiamondArrowsIcon } from "@jutro/ui";
import { DoctorMSFacilityChangeOutsideVoivodeshipEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../../NfzEvent";
import { EventHandler } from "../../../NfzEvent/infer";

export const FacilityChangeOutsideVoivodeshipEventHandler: EventHandler<
  DoctorMSFacilityChangeOutsideVoivodeshipEvent
> = ({ event }) => {
  const { insuranceProviderInfo, insuranceProviderInfoUrl, nfzComment } = event;
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_CHECK}
      statusText="Zmiana placówki (poza woj.)"
      color="orange"
      icon={<DiamondArrowsIcon size="sm" />}
      tooltip={
        <TooltipConent
          insuranceProviderInfo={insuranceProviderInfo}
          nfzComment={nfzComment}
        />
      }
      externalLink={insuranceProviderInfoUrl}
    />
  );
};

type TooltipConentProps = {
  insuranceProviderInfo: string;
  nfzComment: string;
};

const TooltipConent = ({
  nfzComment,
  insuranceProviderInfo,
}: TooltipConentProps) => {
  return (
    <div className="flex flex-col gap-1">
      <span>{nfzComment}</span>
      <span>{insuranceProviderInfo}</span>
    </div>
  );
};

type Props = {
  header?: any;
  children?: any;
  wide?: boolean;
  icon?: any;
  background?: string;
  height?: number;
  overflowY?: any;
};

export const Block = ({
  header,
  children,
  wide = false,
  icon,
  background,
  height,
  overflowY,
}: Props) => (
  <div
    style={{
      overflowY: overflowY,
      height: height,
      gap: 16,
      display: "flex",
      flexDirection: "column",
      padding: 16,
      background: background || "#fff",
      borderRadius: 8,
      width: "100%",
      position: "relative",
      gridColumn: wide ? "span 2 / span 2" : "",
    }}
  >
    {header && (
      <div
        style={{
          color: "#234361",
          marginBottom: 12,
          fontWeight: 500,
          fontSize: 12,
          paddingBottom: 4,
          lineHeight: "16px",
        }}
      >
        {header}
        {icon}
      </div>
    )}
    <div className="h-full">{children}</div>
  </div>
);

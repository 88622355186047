import { useApolloClient } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { determineAge } from "@jutro/tools";
import { Button, Input, Toggle } from "@jutro/ui";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { adjustedToInputSelectStyles } from "components/PatientDocs/Ordered/utils";
import { SideSheet } from "components/new/Drawer";
import { InfoBox } from "components/new/InfoBox";
import { SelectWrapper } from "components/new/SelectWrapper";
import {
  DoctorGetPatientDocument,
  DoctorPatientProfileVisitDocument,
  useDoctorCreatePatientMutation,
} from "lib/graphql/megaSchema";
import { createChildPesel } from "lib/tools/createChildPesel";
import { fetchPlaces } from "lib/tools/fetchPlaces";
import { toaster } from "lib/tools/toaster";
import { GenderSelect } from "views/Patients/components/GenderSelect";
import {
  defaultAddressValues,
  patientCreationSchema,
  patientCreationSchemaDefaults,
} from "views/Patients/tools";
import { PatientCreationSchema } from "views/Patients/types";

export enum TestId {
  Drawer = "CreateNewPatientDrawer",
}

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  setShowCreatePanel: Dispatch<SetStateAction<boolean>>;
};

export const CreatePatientDrawer = ({
  isShown,
  setIsShown,
  setShowCreatePanel,
}: Props) => {
  const [peselNotGivenYet, setPeselNotGivenYet] = useState<boolean>(false);

  const { control, handleSubmit, reset, watch, setValue } =
    useForm<PatientCreationSchema>({
      defaultValues: patientCreationSchemaDefaults,
      resolver: zodResolver(patientCreationSchema),
    });
  const client = useApolloClient();
  const providedPesel = watch("pesel");
  const pickedGender = watch("gender");
  const birthDate = watch("birthDate");
  const userAge = determineAge(providedPesel);
  const isChild = providedPesel.length === 11 && userAge < 18;

  const [createPatientMutation, { loading: createPatientLoading }] =
    useDoctorCreatePatientMutation({
      refetchQueries: [
        DoctorGetPatientDocument,
        DoctorPatientProfileVisitDocument,
      ],
    });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<PatientCreationSchema> = async (formData) => {
    const createPatientPromise = createPatientMutation({
      variables: {
        ...formData,
        languages: ["PL"],
      },
    });

    const result = await toaster.promise(createPatientPromise, {
      success: "Poprawnie utworzono konto pacjenta",
      loading: "Trwa tworzenie nowego konta pacjenta...",
      error: "Nie udało się utworzyć pacjenta",
    });

    if (!result || !result.data?.doctorCreatePatient) {
      return;
    }

    const { doctorCreatePatient: patientId } = result.data;

    setShowCreatePanel(false);
    setIsShown(false);
    reset({});

    navigate(`/patients/${patientId}/profile`);
  };

  useEffect(() => {
    if (peselNotGivenYet) {
      if (birthDate && pickedGender) {
        setValue("pesel", createChildPesel(birthDate, pickedGender) ?? "");
        return;
      }
      setValue("pesel", "00000000000");
      setValue("phone", "000000000");
      return;
    }

    setValue("pesel", "");
    setValue("phone", "");
    setValue("gender", "");
    setValue("birthDate", "");
  }, [peselNotGivenYet, birthDate, pickedGender]);

  useEffect(() => {
    if (isChild && userAge < 18) {
      setValue("phone", "000000000");
    }
  }, [providedPesel]);

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => {
        setShowCreatePanel(false);
        setIsShown(false);
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid={TestId.Drawer}
        className="flex max-w-[500px] flex-col gap-4 overflow-y-auto p-4"
      >
        <div className="text-left font-semibold">Utwórz konto pacjenta</div>
        <div className="flex w-full items-start gap-4">
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { onChange, ...field },
              fieldState: { error },
            }) => (
              <div className="flex-1">
                <Input
                  label="Imię"
                  placeholder="np. Jan Maria"
                  onChange={(e) => onChange(e.target.value.trim())}
                  {...field}
                  error={error?.message}
                />
              </div>
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({
              field: { onChange, ...field },
              fieldState: { error },
            }) => (
              <div className="flex-1">
                <Input
                  label="Nazwisko"
                  placeholder="np. Kowalska"
                  onChange={(e) => onChange(e.target.value.trim())}
                  {...field}
                  error={error?.message}
                />
              </div>
            )}
          />
        </div>

        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="pesel"
            render={({ field: { value, ...field }, fieldState: { error } }) => (
              <div className="flex-1">
                <Input
                  label="Numer PESEL"
                  maxLength={11}
                  type="number"
                  disabled={peselNotGivenYet}
                  placeholder={peselNotGivenYet ? "" : "np. 12345678901"}
                  value={peselNotGivenYet ? "" : value}
                  {...field}
                  error={error?.message}
                />
              </div>
            )}
          />
          <Controller
            name="peselNotGivenYet"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <Toggle
                label="Brak nadanego numeru PESEL"
                isEnabled={value}
                setIsEnabled={(e) => {
                  onChange(e);
                  setPeselNotGivenYet(e);
                }}
                {...field}
              />
            )}
          />
        </div>

        {peselNotGivenYet && (
          <>
            <Controller
              name="birthDate"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="flex flex-col gap-2">
                  <SelectWrapper label="Data urodzenia">
                    <DateTimePicker
                      format="yyyy-MM-dd"
                      minDate={dayjs().subtract(3, "month").toDate()}
                      maxDate={dayjs().toDate()}
                      disableClock
                      clearIcon={null}
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="RRRR"
                      hourPlaceholder="GG"
                      minutePlaceholder="MM"
                      calendarIcon={false}
                      value={value}
                      disableCalendar={!peselNotGivenYet}
                      onChange={(date) => {
                        if (!date) return;
                        onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      className={`slots-creator-date-time-picker font-paragraph-1 h-[42px] w-full rounded-lg !border-[1px] !border-solid !border-jutro-new-warm-gray-300 px-[10px] py-[6px] text-jutro-new-warm-gray-800 focus-within:border-jutro-new-blue-800 hover:border-jutro-new-blue-800 ${
                        error
                          ? "border-jutro-new-rose-600"
                          : "border-jutro-new-warm-gray-100"
                      }`}
                    />
                  </SelectWrapper>
                  <InputError message={error?.message} />
                </div>
              )}
            />

            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <div className="flex flex-col gap-2">
                  <SelectWrapper label="Płeć (nadana przy urodzeniu)">
                    <GenderSelect
                      onChange={onChange}
                      error={error}
                      pickedGender={pickedGender}
                    />
                  </SelectWrapper>
                  <InputError message={error?.message} />
                </div>
              )}
            />
          </>
        )}

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <Controller
              name="address"
              control={control}
              render={({
                field: { name, onChange },
                fieldState: { error },
                formState: { errors },
              }) => (
                <div className="flex flex-col gap-2">
                  <SelectWrapper label="Adres zamieszkania">
                    <AsyncSelect
                      isDisabled={false}
                      defaultValue={null}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      styles={adjustedToInputSelectStyles<string | unknown>(
                        error,
                      )}
                      name={name}
                      className="font-paragraph-2"
                      placeholder={"Kliknij aby wyszukać"}
                      loadingMessage={() => "Szukam..."}
                      noOptionsMessage={() => "Brak wyników."}
                      loadOptions={(query: string) =>
                        fetchPlaces(client, query)
                      }
                      onChange={(option) =>
                        onChange(option?.value ?? defaultAddressValues)
                      }
                    />
                  </SelectWrapper>
                  <InputError message={errors.address?.city?.message} />
                </div>
              )}
            />
          </div>

          <Controller
            control={control}
            name="flatNumber"
            render={({ field: { onChange, value } }) => (
              <Input
                label="Numer mieszkania"
                placeholder="np. 12"
                onChange={(e) => onChange(e.target.value.trim())}
                value={value ?? ""}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="phone"
          render={({ field, fieldState: { error } }) => (
            <div className="flex flex-col gap-2">
              <Input
                label="Numer telefonu"
                maxLength={9}
                error={error?.message}
                type="number"
                placeholder="np. 570 690 376"
                disabled={peselNotGivenYet || isChild}
                {...field}
              />
            </div>
          )}
        />

        {(isChild || peselNotGivenYet) && (
          <InfoBox
            infos={[
              <>
                Pacjenci poniżej 18 roku życia nie mogą posiadać indywidualnego
                konta, dlatego pole z numerem telefonu jest automatycznie
                uzupełniane zerami i nie można tego zmienić.
              </>,
            ]}
          />
        )}

        <div className="flex flex-col gap-4">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label="Adres e-mail"
                placeholder="np. kontakt@jutromedical.com"
                error={error?.message}
                onChange={(e) => onChange(e.target.value.trim())}
                value={value ?? ""}
              />
            )}
          />
        </div>

        <InfoBox
          infos={[
            <>
              Jeśli tworzysz konto na podstawie uzyskanej wcześniej deklaracji i
              nie ma możliwości uzyskania adresu e-mail, pole to jest
              nieobowiązkowe.
            </>,
          ]}
        />

        <Button
          text="Utwórz konto pacjenta"
          type="submit"
          loading={createPatientLoading}
        />
      </form>
    </SideSheet>
  );
};

type InputErrorProps = {
  message?: string;
};

const InputError = ({ message }: InputErrorProps) => {
  if (!message) {
    return null;
  }

  return <p className="text-xs text-jutro-new-rose-600">{message}</p>;
};

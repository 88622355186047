import { Tooltip } from "@jutro/ui";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { DoctorMSNfzEventBase } from "lib/graphql/megaSchema";
import { LeftEventTriangle } from "views/Patient/Profile/NfzEventList/NfzEvent/assets/LeftEventTriangle";
import {
  NfzEventColor,
  nfzEventColorMap,
} from "views/Patient/Profile/NfzEventList/NfzEvent/tools";

export enum NfzEventPrefix {
  DECLARATION_CHECK = "Sprawdzenie deklaracji",
  DECLARATION_SUBMIT = "Złozenie deklaracji",
  INSURANCE_STATUS = "Status ubezpieczenia",
  UNKNOWN = "Nieznany status",
}

type NfzEventProps = {
  event: DoctorMSNfzEventBase;
  prefix: NfzEventPrefix;
  statusText: string;
  color: NfzEventColor;
  externalLink?: string;
  icon: ReactNode;
  tooltip?: ReactNode;
};

export const NfzEvent = ({
  event,
  prefix,
  statusText,
  color,
  externalLink,
  tooltip,
  icon,
}: NfzEventProps) => {
  const { textColor, iconColor } = nfzEventColorMap[color];

  return (
    <li className="mb-2 ml-4">
      <div className="absolute -left-1 mt-3.5 h-2 w-2 rounded-full bg-jutro-new-warm-gray-300" />
      <Tooltip
        disabled={!tooltip}
        position="top-start"
        trigger={
          <div className="flex max-w-max cursor-pointer flex-col gap-1">
            <div className="flex items-center">
              <LeftEventTriangle />
              <div className="font-paragraph-2 flex max-w-max items-center gap-1 rounded-r-lg bg-jutro-new-warm-gray-50 py-[5px] pr-2">
                <span>{prefix}: </span>
                <span className={iconColor}>{icon}</span>
                <span className={textColor}>{statusText}</span>

                {externalLink && (
                  <a className="link" href={externalLink}>
                    Więcej informacji
                  </a>
                )}
              </div>
            </div>
            <span className="font-geologica ml-2 text-xs font-light leading-[15px] text-jutro-new-warm-gray-500">
              {dayjs(event.createdAt).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
        }
        content={
          <div className="font-paragraph-2 max-w-lg rounded-lg">
            <header className="rounded-t-lg bg-jutro-new-warm-gray-100 p-2 font-medium">
              <span> {statusText}</span>
            </header>
            <div className="px-2 py-2">{tooltip}</div>
          </div>
        }
      />
    </li>
  );
};

import { Checkbox, Input, Select } from "@jutro/ui";
import { OptionWithSideElements } from "@jutro/ui/dist/Select/tools";
import { Control, Controller } from "react-hook-form";
import {
  AvailableStaffOptionValue,
  DeclarationCreatorForm,
  FacilityChangeReasonFieldNames,
  HowManyProviderChangesFieldNames,
  OtherReasonProviderChangeFieldNames,
  StaffDataFieldNames,
  StaffDeclarationData,
} from "views/Patient/Profile/types";
import {
  howManyProviderChangesOptions,
  providerChangeReasons,
} from "views/Patient/Profile/utils";

type Props = {
  control: Control<DeclarationCreatorForm>;
  name: StaffDataFieldNames;
  howManyProviderChangesName: HowManyProviderChangesFieldNames;
  facilityChangeReasonName: FacilityChangeReasonFieldNames;
  otherReasonProviderChange: OtherReasonProviderChangeFieldNames;
  label: string;
  legacyStaffCheckbox?: boolean;
  availableStaff: OptionWithSideElements<AvailableStaffOptionValue>[];
  declaredStaffData: StaffDeclarationData;
};

export const DeclaredStaffCard = ({
  control,
  name,
  howManyProviderChangesName,
  facilityChangeReasonName,
  otherReasonProviderChange,
  label,
  legacyStaffCheckbox = false,
  availableStaff,
  declaredStaffData,
}: Props) => {
  return (
    <div className="flex flex-col gap-1 rounded-lg bg-white p-4">
      <div className="flex gap-2 [&>div>label]:truncate [&>div]:max-w-[50%]">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              isSearchable={false}
              error={error?.message}
              label={label}
              placeholder="Wybierz z listy"
              options={availableStaff}
              value={
                availableStaff.find((o) => o.value?.npwz === value?.npwz) ?? {
                  label: "Brak dostępnego personelu",
                  value: null,
                }
              }
              onChange={(option) => onChange(option?.value)}
              disabled={!availableStaff.length}
            />
          )}
        />

        <Controller
          control={control}
          name={howManyProviderChangesName}
          render={({ field: { onChange, value } }) => (
            <Select
              isSearchable={false}
              label="Który raz w tym roku dokonujesz wyboru POZ"
              placeholder="Wybierz z listy"
              options={howManyProviderChangesOptions}
              value={
                !availableStaff.length
                  ? { label: "", value: null }
                  : (howManyProviderChangesOptions.find(
                      (o) => o.value === value,
                    ) ?? null)
              }
              onChange={(option) => onChange(option?.value)}
            />
          )}
        />
      </div>

      {legacyStaffCheckbox && (
        <div>
          <Controller
            name="doctorDeclarationData.legacyDoctors"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                name="legacyDoctors"
                label="Pokaż niedeklarujących lekarzy"
                checked={value as boolean}
                onChange={() => onChange(!value)}
              />
            )}
          />
        </div>
      )}

      {declaredStaffData?.howManyProviderChanges === "many" && (
        <div className="flex flex-col gap-4">
          <Controller
            control={control}
            name={facilityChangeReasonName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Select
                  isSearchable={false}
                  error={error?.message}
                  label="Przyczyna"
                  placeholder="Wybierz z listy"
                  options={providerChangeReasons}
                  value={
                    providerChangeReasons.find((o) => o.value === value) ?? null
                  }
                  onChange={(option) => onChange(option?.value)}
                />
              </>
            )}
          />

          {declaredStaffData.facilityChangeReason ===
            "ANOTHER_PROVIDER_REASON" && (
            <div>
              <Controller
                control={control}
                name={otherReasonProviderChange}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder="Podaj powód"
                    label="Przyczyny powstałe po stronie świadczeniodawcy"
                    onChange={onChange}
                    value={value ?? ""}
                    maxLength={35}
                    error={error?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

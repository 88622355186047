import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "components/new/Checkbox";

type Option = {
  label: string;
  optionValue: string | number | boolean | null;
};

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  options: Option[];
};

export const ControlledCheckboxGroup = ({
  name,
  label,
  options,
  disabled,
}: Props) => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      <p className="font-paragraph-2">{label}</p>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            {options.map(({ label, optionValue }) => (
              <Checkbox
                key={`${label}+${optionValue}`}
                color="text-jutro-new-green-400"
                xs
                disabled={disabled}
                label={label}
                value={value.includes(optionValue)}
                error={error}
                onChange={() => {
                  if (value.includes(optionValue)) {
                    onChange(
                      value.filter(
                        (value: Option["optionValue"]) => value !== optionValue,
                      ),
                    );
                    return;
                  }

                  onChange([...value, optionValue]);
                }}
              />
            ))}
          </div>
        )}
      />
    </div>
  );
};

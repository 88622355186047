import { StatusColor } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/types";
import { getStatusInfo } from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/utils";

const statusColorMap: Record<StatusColor, string> = {
  success: "text-jutro-new-green-400",
  warning: "text-jutro-new-orange-400",
};

type StatusInfoProps = {
  statusInfo: ReturnType<typeof getStatusInfo>;
  color: StatusColor;
};

export const Status = ({ statusInfo, color }: StatusInfoProps) => {
  const { status, info } = statusInfo;

  return (
    <div className="font-paragraph-2 flex items-center gap-1 p-2">
      <span>Status: </span>
      <span className={`font-semibold ${statusColorMap[color]}`}>{status}</span>
      <span> {info}</span>
    </div>
  );
};

import { AddCircleIcon, BinIcon, Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { selectedVisitTabAtom } from "lib/atoms";
import { useCopyButton } from "views/Visit/RightPanel/NewPrescription/components/Overlays/CopyButton/useCopyButton";

type Props = {
  drugDetails: any;
  orderedAmount?: number;
};

export const CopyDrugToPrescription = ({
  drugDetails,
  orderedAmount,
}: Props) => {
  const {
    handleAddDrugToPrescription,
    handleRemoveDrugFromPrescription,
    isDrugAddedToPrescription,
  } = useCopyButton();

  const [selectedVisitTab, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);

  const isDrugDeletable = isDrugAddedToPrescription(drugDetails?.ean);

  const manageDrug = () => {
    if (isDrugDeletable) {
      handleRemoveDrugFromPrescription(drugDetails?.ean);
      return;
    }

    handleAddDrugToPrescription(drugDetails, orderedAmount ?? 1);

    if (selectedVisitTab !== "prescription") {
      setSelectedVisitTab("prescription");
    }
  };

  return (
    <Button
      onClick={manageDrug}
      type="button"
      full={false}
      icon={isDrugDeletable ? <BinIcon /> : <AddCircleIcon />}
      iconPosition="left"
      text={isDrugDeletable ? "Usuń z recepty" : "Dodaj do recepty"}
      variant={isDrugDeletable ? "negativeGhost" : "ghost"}
      size="condensed"
    />
  );
};

import { Option } from "@jutro/types";
import { Tabs } from "@jutro/ui";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { useEffect, useMemo } from "react";
import { selectedVisitTabAtom } from "lib/atoms";
import { currentUserAtom } from "lib/atoms/auth";
import { DoctorVisitQuery } from "lib/graphql/megaSchema";
import { VisitTab } from "views/Visit/RightPanel/TabsSection/types";

type Props = {
  currentVisit: DoctorVisitQuery["doctorVisit"];
};

export const TabsSection = ({ currentVisit }: Props) => {
  const [selectedVisitTab, setSelectedVisitTab] = useAtom(selectedVisitTabAtom);
  const [currentUser] = useAtom(currentUserAtom);

  const isVisitClosed =
    Boolean(currentVisit.snapshotAt) || !currentVisit.startOn;

  const currentUserIsVisitOwner = Boolean(
    currentVisit.doctor?.id === currentUser?.id,
  );

  const isDoctorAdviceVisit = Boolean(
    currentVisit.procedures.some(({ type }) => type === "DOCTOR_ADVICE"),
  );

  const visitTabs: Option<VisitTab>[] = useMemo(
    () => [
      {
        label: "Wizyta",
        value: "visit",
      },
      {
        label: "Recepta",
        value: "prescription",
        disabled: isVisitClosed || !currentUserIsVisitOwner,
      },
      {
        label: "Zwolnienie",
        value: "leave",
        disabled: currentUser?.genre !== "DOCTOR" || !currentUserIsVisitOwner,
      },
      {
        label: "Skierowanie",
        value: "referral",
        disabled: isVisitClosed || !currentUserIsVisitOwner,
      },
      {
        label: "Zaświadczenie",
        value: "testimonial",
        disabled: isVisitClosed || !currentUserIsVisitOwner,
      },
      {
        label: "Świadczenia",
        value: "medicalServices",
        disabled: !isDoctorAdviceVisit || !currentVisit.isPublicVisit,
      },
    ],
    [currentVisit],
  );

  useEffect(() => {
    setSelectedVisitTab(RESET);
  }, [currentVisit.id]);

  return (
    <Tabs
      options={visitTabs}
      value={visitTabs.find(({ value }) => value === selectedVisitTab)!}
      onChange={({ value }) => setSelectedVisitTab(value)}
      width="hug"
      withPopoverMenu
      withGradients
    />
  );
};

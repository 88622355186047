import { Control, Controller, FieldErrors } from "react-hook-form";
import { PaymentModalSchema } from "components/PatientDocs/Ordered/types";
import { Dropzone } from "components/new/Dropzone/Dropzone";

type Props = {
  control: Control<PaymentModalSchema> | undefined;
  errors: FieldErrors<PaymentModalSchema>;
};

export const BankTransferPaymentModal = ({ control, errors }: Props) => {
  return (
    <>
      <span>Potwierdzenie przelewu</span>
      <Controller
        control={control}
        name="bankTransferPayment.moneyTransferProofFiles"
        render={({ field: { onChange, value, ...field } }) => (
          <div className="flex flex-col gap-2">
            <Dropzone
              maxFiles={1}
              addFileText="Dodaj plik"
              selectedFiles={value ?? []}
              onFilesListUpdate={onChange}
              isModal
              size="xs"
              {...field}
            />

            {errors.bankTransferPayment?.moneyTransferProofFiles && (
              <span className="text-jutro-new-rose-600">
                Ta metoda płatności wymaga dodania pliku
              </span>
            )}
          </div>
        )}
      />
    </>
  );
};

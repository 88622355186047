import { Tooltip } from "@jutro/ui";
import { useAtom } from "jotai";
import { Avatar } from "components/new";
import { currentUserAtom } from "lib/atoms/auth";
import { DoctorMSPatientTagType } from "lib/graphql/megaSchema";
import { TileColumn } from "lib/tools/createWorkspace/types";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";
import { toaster } from "lib/tools/toaster";
import { Maybe, PatientDocsTag } from "lib/types";
import {
  ConfirmationIcon,
  ConfirmationText,
} from "views/Visit/RightPanel/NewPrescription/components/Overlays/Confirmation";
import {
  getColor,
  getDayDiff,
  getStatusInfo,
} from "views/Visit/RightPanel/NewPrescription/components/Overlays/PreviousDrugs/RegularDrugs/utils";
import { Status } from "views/Visit/RightPanel/NewPrescription/components/Overlays/Status";

const displayLatestDoctor = (
  firstName: Maybe<string>,
  lastName: Maybe<string>,
  defaultString: string,
): string => {
  return firstName && lastName ? `${firstName} ${lastName}` : defaultString;
};

const getLatestDoctor = (
  history: PatientDocsTag["history"],
): { id: Maybe<string>; firstName: Maybe<string>; lastName: Maybe<string> } => {
  if (!history || history.length === 0) {
    return {
      id: null,
      firstName: null,
      lastName: null,
    };
  }

  const latestUser = history[history.length - 1].user;

  const { firstName, lastName, id } = latestUser;

  return {
    id,
    firstName,
    lastName,
  };
};

export const Tag = (tag: PatientDocsTag) => {
  const [currentUser] = useAtom(currentUserAtom);

  const isDoctor = currentUser?.genre === "DOCTOR";
  const { addTile } = useTileSystem();
  const handleClick = () => {
    if (
      !isDoctor &&
      ["REGULAR_DRUG", "CHRONIC_DISEASE"].includes(
        tag.type as DoctorMSPatientTagType,
      )
    ) {
      toaster.error(
        "Nie mozesz edytować chorób przewlekłych i stale przyjmowanych leków nie będąc lekarzem.",
      );
      return;
    }

    addTile(
      {
        id: tag.id,
        name: "tag-form",
        data: {
          id: tag.id,
          type: "update",
          tag,
        },
      },
      TileColumn.Center,
    );
  };

  const {
    data,
    confirmationType,
    type,
    history,
    expirationAt,
    createdAt,
    createdBy,
  } = tag;

  const isTooltipDisplayed =
    ["CHRONIC_DISEASE", "REGULAR_DRUG"].includes(
      type as DoctorMSPatientTagType,
    ) && confirmationType;

  const { firstName, lastName, id: doctorId } = getLatestDoctor(history);

  const displayedDoctor = displayLatestDoctor(firstName, lastName, "-");

  const dayDiff = getDayDiff(expirationAt);

  const statusInfo = getStatusInfo(dayDiff);

  const color = getColor(
    dayDiff,
    confirmationType,
    type as DoctorMSPatientTagType,
  );

  return (
    <Tooltip
      disabled={!isTooltipDisplayed}
      position="top-end"
      trigger={
        <div
          className="font-geologica font-caption flex h-max w-max max-w-max cursor-pointer items-center gap-1 truncate rounded-sm bg-jutro-new-warm-gray-100 px-2 text-jutro-new-warm-gray-800"
          onClick={handleClick}
        >
          {isTooltipDisplayed && (
            <div>
              <ConfirmationIcon color={color} />
            </div>
          )}
          <span className="truncate">{data.drug?.name || data.name}</span>
        </div>
      }
      content={
        <div className="font-paragraph-2 rounded-lg bg-white text-jutro-new-warm-gray-800">
          <header className="rounded-t-lg bg-jutro-new-warm-gray-100 p-2 font-medium">
            <span> {data.drug?.name || data.name}</span>
          </header>

          <div>
            <div className="flex flex-col divide-y-[1px]">
              {type === "REGULAR_DRUG" && (
                <Status statusInfo={statusInfo} color={color} />
              )}

              {tag?.data?.interval && (
                <div className="p-2">Dawkowanie: {tag.data.interval}</div>
              )}

              <div className="flex items-center gap-1 p-2">
                <div>
                  <ConfirmationIcon color={color} />
                </div>
                <span>
                  <ConfirmationText
                    createdAt={createdAt.iso}
                    confirmationType={confirmationType}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="px-2 pb-2">
            {(history || createdBy) && (
              <div className="flex items-center gap-1 rounded-lg border border-jutro-new-warm-gray-100 p-2">
                <Avatar userId={doctorId} />
                <span className="font-paragraph-2">
                  {history
                    ? `Ostatnio edytowane przez ${displayedDoctor}`
                    : `Ostatnio dodane przez ${displayLatestDoctor(
                        createdBy?.firstName,
                        createdBy?.lastName,
                        "-",
                      )}`}
                </span>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

import {
  Dialog,
  DialogBackdrop,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Button } from "@jutro/ui";
import {
  ComponentProps,
  Dispatch,
  Fragment,
  PropsWithChildren,
  SetStateAction,
} from "react";

type Width = "md" | "lg" | "xl" | "xxl";

type ConfirmDialogProps = {
  title?: string;
  text?: string;
  confirmDisabled?: boolean;
  width?: Width;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  onClose?: () => void;
  confirmText?: string;
  loading?: boolean;
  submitVariant?: ComponentProps<typeof Button>["variant"];
  shouldHideOverflow?: boolean;
  isResponsive?: boolean;
};

const widthMap: Record<Width, string> = {
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-screen-sm",
  xxl: "max-w-4xl",
};

export const ConfirmDialog = ({
  title,
  text = "Czy na pewno chcesz wykonać tę akcję?",
  confirmDisabled = false,
  width = "md",
  open,
  setOpen,
  loading,
  onConfirm,
  onClose = () => {},
  confirmText = "Usuń",
  submitVariant = "negative",
  children,
  shouldHideOverflow = true,
  isResponsive = false,
}: PropsWithChildren<ConfirmDialogProps>) => {
  const closeModal = () => {
    setOpen(false);
  };
  const closeModalWithAction = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="min-h-screen px-4 text-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogBackdrop
              className="fixed inset-0 bg-overlay-dark"
              onClick={closeModal}
            />
          </TransitionChild>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`my-8 inline-block transform rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all ${
                !isResponsive && `w-full ${widthMap[width]} `
              } ${shouldHideOverflow && "overflow-hidden"}`}
            >
              <DialogTitle
                as="h3"
                className="font-heading-3 text-jutro-new-warm-gray-900"
              >
                {title}
              </DialogTitle>
              <div className="mt-2 flex flex-col justify-center">
                {children ? (
                  children
                ) : (
                  <p className="font-paragraph-2 text-jutro-new-warm-gray-500">
                    {text}
                  </p>
                )}
              </div>

              <div className="mt-4 flex justify-end gap-2">
                <Button
                  full={false}
                  id="cancel-confirm-view"
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    onClose();
                    closeModal();
                  }}
                  text="Anuluj"
                />

                <Button
                  full={false}
                  type="button"
                  id="success-confirm-view"
                  onClick={closeModalWithAction}
                  disabled={confirmDisabled}
                  loading={loading}
                  variant={submitVariant}
                  text={confirmText}
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

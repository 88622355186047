import { Button } from "@jutro/ui";

type Props = {
  searchInputValue: string;
  onSearchInputValueReset: () => void;
  onModalOpen: () => void;
};

export const EmptyDoctorsList = ({
  searchInputValue,
  onSearchInputValueReset,
  onModalOpen,
}: Props) => {
  const handleInputValueChange = () => {
    onSearchInputValueReset();
  };

  const handleModalOpen = () => {
    onModalOpen();
  };

  return (
    <div className="flex h-full min-w-[1814px] items-center justify-center bg-white">
      <div className="flex flex-col items-center gap-2">
        {searchInputValue ? (
          <>
            <span className="text-lg font-bold">
              Wpisana fraza - {searchInputValue} - nie pasuje do żadnej z osób w
              naszej bazie
            </span>
            <span>
              Sprawdź, czy imię i nazwisko zostały poprawnie napisane lub
              spróbuj wpisać tylko nazwisko.
            </span>
            <Button
              text="Wyczyść wyszukiwanie"
              full={false}
              onClick={handleInputValueChange}
            />
          </>
        ) : (
          <div className="flex flex-col items-center gap-2">
            <span className="text-lg font-bold">Brak osób do wyświetlenia</span>
            <span>
              W wybranej lokalizacji nie utworzono jeszcze żadnej zmiany.
            </span>
            <Button
              text="Utwórz zmianę"
              full={false}
              onClick={handleModalOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};

import { MessageElement } from "components/new/Chat/Message/Message";
import { ReferralItem } from "./Item";
import { getReferralItems } from "./getReferralItems";

type Props = {
  referral: Exclude<MessageElement["referral"], null | undefined>;
};

export const ReferralMessage = ({ referral }: Props) => {
  const items = getReferralItems(referral);

  return (
    <div className="flex flex-col gap-3">
      <span>e-Skierowanie zostało wysłane do pacjenta poprawnie.</span>
      {items.map(({ title, description }) => {
        if (!description) {
          return null;
        }

        return (
          <ReferralItem key={title} title={title} description={description} />
        );
      })}
      {referral.urgent && (
        <span className="font-caption text-jutro-new-rose-500">Pilne!</span>
      )}
    </div>
  );
};

/* eslint-disable no-case-declarations */
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  fromPromise,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { envVars } from "envvars";
import { createClient } from "graphql-ws";
import Cookies from "js-cookie";
import {
  DoctorRefreshTokenDocument,
  DoctorRefreshTokenMutation,
} from "lib/graphql/megaSchema";
import { doctorConfig, websocketConfig } from "lib/tools/httpConfigs";

const logout = () => {
  Cookies.remove("JWT");
  Cookies.remove("RT");
  window.location.replace("/");
};

const getRefreshedTokens = async (
  client: ApolloClient<NormalizedCacheObject>,
  jwt?: string,
  refreshToken?: string,
) => {
  if (!jwt || !refreshToken) {
    return;
  }

  const { data } = await client.mutate<DoctorRefreshTokenMutation>({
    mutation: DoctorRefreshTokenDocument,
    variables: {
      data: {
        jwt,
        refreshToken,
      },
    },
  });

  if (!data?.doctorRefreshToken) {
    return;
  }

  const { jwt: newJwt, refreshToken: newRt } = data.doctorRefreshToken;

  return { newJwt, newRt };
};

const getAuthToken = (): string | null => {
  const token = Cookies.get("JWT");
  return token ? `Bearer ${token}` : null;
};

export const createApolloClient = () => {
  const authLink = setContext((request, { headers }) => {
    const authToken = getAuthToken();

    return {
      headers: { ...headers, authorization: authToken },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: websocketConfig[envVars.REACT_APP_CONFIG],
      shouldRetry: () => true,
      retryAttempts: 5,
      isFatalConnectionProblem: () => false,
      connectionParams: () => {
        const authToken = getAuthToken();

        return {
          authorization: authToken,
        };
      },
      on: {
        /* eslint-disable */
        closed: (error) => {
          console.log(
            `GraphQL subscription websocket connection closed: ${
              (error as any).reason
            }`,
          );
        },
        connecting: () => {
          console.log("GraphQL subscription websocket connecting");
        },
        connected: () =>
          console.log("GraphQL subscription websocket connected"),
        error: (error) => {
          console.log(
            `GraphQL subscription websocket error: ${(error as any).reason}`,
          );
        },
        /* eslint-enable */
      },
    }),
  );

  const httpLink = new HttpLink({
    uri: doctorConfig[envVars.REACT_APP_CONFIG],
    credentials: "include",
  });

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (!graphQLErrors) {
      return;
    }

    for (const err of graphQLErrors) {
      if (err.extensions?.code !== "UNAUTHENTICATED") {
        continue;
      }

      if (operation.operationName === "RefreshToken") {
        logout();
        return;
      }

      const oldJwt = Cookies.get("JWT");
      const oldRt = Cookies.get("RT");

      const refreshedTokenPromise = getRefreshedTokens(
        client,
        oldJwt,
        oldRt,
      ).then((refreshedTokens) => {
        if (!refreshedTokens) {
          logout();
          return;
        }

        const { newJwt, newRt } = refreshedTokens;

        Cookies.set("JWT", newJwt ?? "");
        Cookies.set("RT", newRt ?? "");

        operation.setContext({
          headers: {
            authorization: `Bearer ${newJwt}`,
          },
        });
      });

      return fromPromise(refreshedTokenPromise).flatMap(() =>
        forward(operation),
      );
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: from([errorLink, authLink, splitLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: import.meta.env.DEV,
  });

  return client;
};

import { Dispatch, SetStateAction, useCallback } from "react";
import { FileRejection, FileWithPath, useDropzone } from "react-dropzone";
import { FileWithPreview } from "components/new/Dropzone/Dropzone";
import { toaster } from "lib/tools/toaster";

const allowedFiletypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

type Props = {
  selectedFiles: FileWithPreview[];
  maxFiles: number;
  size?: string;
  setIsFilePdf: Dispatch<SetStateAction<boolean>>;
  setIsFilePreviewModalOpen: Dispatch<SetStateAction<boolean>>;
  onFilesListUpdate: (files: FileWithPreview[]) => void;
};

export const DropArea = ({
  maxFiles,
  selectedFiles,
  size,
  setIsFilePdf,
  setIsFilePreviewModalOpen,
  onFilesListUpdate,
}: Props) => {
  const onDrop = useCallback(
    (acceptedFiles: FileWithPreview[], rejectedFiles: FileRejection[]) => {
      acceptedFiles.forEach((file) => {
        const fileType = file.type;
        const isAllowed = Boolean(
          allowedFiletypes.find((type) => type === fileType),
        );

        if (!isAllowed) {
          toaster.error(
            `Zły format pliku, dozwolone formaty: ${allowedFiletypes.map(
              (type) => " " + type,
            )}`,
          );
          return;
        }

        setIsFilePreviewModalOpen(true);

        setIsFilePdf(isPdf(file));
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        onFilesListUpdate([...selectedFiles, file]);

        toaster.success("Dodano plik");
      });

      if (rejectedFiles.length > maxFiles) {
        toaster.error(`Wystąpił błąd. Maksymalna liczba plików: ${maxFiles}`);
      }
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    multiple: maxFiles === 1 ? false : true,
    noKeyboard: true,
  });

  const isPdf = (file: FileWithPath) =>
    file.name.slice(file.name.length - 3).toLowerCase() === "pdf";

  return (
    <div
      className={`${
        isDragActive ? "bg-jutro-new-warm-gray-200" : "bg-white"
      } font-paragraph-2 flex h-10 cursor-pointer items-center justify-center rounded-lg border border-jutro-new-warm-gray-100 p-2 text-center hover:border-jutro-new-blue-800`}
      onClick={open}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <>
          <span
            className={`font-paragraph-2 cursor-pointer text-jutro-new-warm-gray-800 ${size}`}
          >
            Przeciągnij i upuść lub{" "}
          </span>
          <input
            type="button"
            className={`font-paragraph-2 inline cursor-pointer bg-white text-jutro-new-green-400 transition-all hover:text-opacity-80 hover:underline ${size} ${
              isDragActive ? "bg-jutro-new-warm-gray-200" : "bg-white"
            }`}
            value="wybierz z dysku"
          />
        </>
      </div>
    </div>
  );
};

import { useState } from "react";

export const FileInput = ({ id, fileRegister, plainFile = false }) => {
  const [fileName, setFileName] = useState("");

  return (
    <label htmlFor={id} className="relative inline-block h-10 cursor-pointer">
      <input
        type="file"
        id={id}
        className="min-w-56 opacity-0"
        onChange={(e) => {
          const file = e.target.files[0];
          setFileName(file.name);
          if (plainFile) return fileRegister.onChange(file);
          const reader = new FileReader();
          reader.onload = (e) => fileRegister.onChange(e.target.result);
          reader.readAsText(file);
        }}
      />

      <span className="z-5 absolute left-0 right-0 top-0 h-full select-none rounded-lg border border-jutro-new-warm-gray-300 bg-white px-4 py-2 text-jutro-new-warm-gray-700 shadow-sm">
        {fileName ? fileName : "Kliknij, aby dodać..."}
      </span>

      <span className="z-6 absolute bottom-0 right-0 top-0 block rounded-lg border border-jutro-new-warm-gray-300 bg-jutro-new-warm-gray-200 px-4 py-2 text-jutro-new-warm-gray-700">
        Załącz plik
      </span>
    </label>
  );
};

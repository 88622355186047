import {
  DoctorMSPatientTagEntity,
  DoctorRequestedDrugFragment,
} from "lib/graphql/megaSchema";
import { Confirmation } from "views/Visit/RightPanel/NewPrescription/components/Overlays/Confirmation/index";
import { CopyDrugToPrescription } from "views/Visit/RightPanel/NewPrescription/components/Overlays/CopyButton/index";
import { DrugDetails } from "views/Visit/RightPanel/NewPrescription/components/Overlays/DrugDetails";

type Props = {
  drug: DoctorRequestedDrugFragment;
  sourceTag?: DoctorMSPatientTagEntity | null;
};

export const OrderedDrug = ({ drug, sourceTag }: Props) => {
  const { details } = drug;

  return (
    <div className="flex gap-4 pt-4 first-of-type:pt-0">
      <div className="flex flex-1 flex-col gap-1">
        <DrugDetails
          name={details.name}
          form={details.form}
          dose={details.dosage}
          box={details.box}
          boxAmount={drug.quantity}
        />
        <Confirmation sourceTag={sourceTag} />
      </div>

      <div>
        <CopyDrugToPrescription
          drugDetails={drug.details}
          orderedAmount={drug.quantity}
        />
      </div>
    </div>
  );
};

import { Defined } from "@jutro/types";
import { envVars } from "envvars";
import Cookies from "js-cookie";
import { nanoid } from "nanoid";
import { IpomFormSchema } from "components/IpomForm/schema";
import {
  DoctorGetIpomCodesQuery,
  DoctorGetIpomQuery,
  DoctorLaboratoryExaminationsQuery,
  DoctorMSIPOMDetailsInput,
  DoctorPatientMessagesQuery,
  DoctorProceduresQuery,
  DoctorVisitQuery,
} from "lib/graphql/megaSchema";
import { httpConfig } from "lib/tools/httpConfigs";
import { filterUniqueDiagnoses } from "./DiagnosisSection/tools";
import {
  extractLabExams,
  extractSpirometryProcedure,
  filterUniqueLabExams,
} from "./DiagnosticTestsSection/tools";
import {
  extractRegularDrugsFromTags,
  extractVisitDrugs,
  filterUniqueDrugs,
} from "./DrugsSection/tools";

type PrepareIpomFormDataInput = {
  visitData: Defined<DoctorVisitQuery["doctorVisit"]>;
  labExamsData: Defined<DoctorLaboratoryExaminationsQuery>;
  proceduresData: Defined<DoctorProceduresQuery>;
  patientMessagesData: Defined<DoctorPatientMessagesQuery>;
  ipomCodesData: DoctorGetIpomCodesQuery;
  formDataFromAtom: IpomFormSchema;
  existingIpomData: DoctorGetIpomQuery | undefined;
};

export const prepareIpomFormData = ({
  visitData,
  labExamsData,
  proceduresData,
  patientMessagesData,
  ipomCodesData,
  formDataFromAtom,
  existingIpomData,
}: PrepareIpomFormDataInput) => {
  const updatedFormData = { ...formDataFromAtom };

  updatedFormData.observations = filterUniqueDiagnoses(
    ipomCodesData,
    updatedFormData.observations,
    visitData.doctorDiagnosis,
    existingIpomData?.doctorGetIpom?.observations ?? [],
  );

  const visitDrugs = extractVisitDrugs(patientMessagesData, visitData.id);

  const regularDrugsFromTags = extractRegularDrugsFromTags(
    visitData.patient?.tags,
  );

  updatedFormData.medicines = filterUniqueDrugs(
    updatedFormData.medicines,
    visitDrugs,
    regularDrugsFromTags,
  );

  const visitLabExams = extractLabExams(labExamsData, visitData.id);

  const visitSpirometryProcedures = extractSpirometryProcedure(
    proceduresData,
    visitData.id,
  );

  updatedFormData.scheduledDiagnosticTests = filterUniqueLabExams(
    updatedFormData.scheduledDiagnosticTests,
    visitSpirometryProcedures,
    visitLabExams,
  );

  return updatedFormData;
};

export const transformIpomFormDataToInput = (
  data: IpomFormSchema,
): DoctorMSIPOMDetailsInput => {
  return {
    ...data,
    observations: data.observations
      .filter((item) => item.active)
      .map(({ active, ...item }) => item),
    medicines: data.medicines
      .filter((item) => item.active)
      .map(({ active, ...item }) => item),
    diet: Array.from({ length: data.diet }, () => ({ type: "NZPL" })),
    nurse: Array.from({ length: data.nurse }, () => ({ type: "ZPL" })),
    scheduledDiagnosticTests: data.scheduledDiagnosticTests
      .filter((item) => item.active)
      .map(({ active, ...item }) => item),
    controlVisits: data.controlVisits.map(({ id, ...item }) => item),
  };
};

export const transformIpomDataToForm = (
  data: Defined<DoctorGetIpomQuery["doctorGetIpom"]>,
): IpomFormSchema => {
  return {
    ...data,
    observations:
      data.observations?.map((item) => ({ ...item, active: true })) ?? [],
    medicines: (data.medicines ?? []).map((item) => ({
      ...item,
      active: true,
    })) as IpomFormSchema["medicines"],
    diet: data.diet?.length ?? 0,
    nurse: data.nurse?.length ?? 0,
    scheduledDiagnosticTests: (data.scheduledDiagnosticTests ?? []).map(
      (item) => ({
        ...item,
        active: true,
      }),
    ) as IpomFormSchema["scheduledDiagnosticTests"],
    controlVisits: (data.controlVisits ?? []).map((item) => ({
      ...item,
      id: nanoid(),
    })) as IpomFormSchema["controlVisits"],
    specialistVisits: (data.specialistVisits ??
      []) as IpomFormSchema["specialistVisits"],
  };
};

export const downloadIpomPDF = async (fileUrl: string | undefined) => {
  if (!fileUrl) {
    return null;
  }

  const response = await fetch(
    `${httpConfig[envVars.REACT_APP_CONFIG]}${fileUrl}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("JWT")}`,
      },
    },
  );

  const blob = await response.blob();

  return blob;
};

import { envVars } from "envvars";
import defaultAvatar from "assets/default-avatar.png";
import { httpConfig } from "lib/tools/httpConfigs";

type Props = {
  userId: string;
  duration: number;
  modal: boolean;
  size?: "sm" | "md" | "lg";
};

const sizeMap = {
  sm: "w-4 h-4",
  md: "w-7 h-7",
  lg: "w-10 h-10",
};

export const StaffAvatar = ({
  userId,
  duration,
  modal,
  size = "md",
}: Props) => {
  if (!userId) {
    return <div>Brak danych</div>;
  }

  return (
    <img
      src={`${httpConfig[envVars.REACT_APP_CONFIG]}/avatar/${userId}`}
      className={`${modal ? "" : "absolute right-1"} ${
        sizeMap[size]
      } mr-2 inline transform rounded-full ${
        duration === 10 ? "top-2" : "top-5"
      }
          `}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = defaultAvatar;
      }}
    />
  );
};

import { atom, useAtom } from "jotai";
import { useLayoutEffect, useRef } from "react";

export type KeyframeAnimation = CSSAnimation & { effect: KeyframeEffect };

const stashedTimeAtom = atom<number | null>(null);

export const useSynchronizedAnimation = (animationName: string) => {
  const [stashedTime, setStashedTime] = useAtom(stashedTimeAtom);
  const ref = useRef(null);

  useLayoutEffect(() => {
    const allAnimations = document.getAnimations() as KeyframeAnimation[];

    const animations = allAnimations.filter(
      (animation) => animation.animationName === animationName
    );

    const mainAnimation = animations.find(
      (animation) => animation.effect.target === ref.current
    );

    if (!mainAnimation) return;

    if (mainAnimation === animations[0] && stashedTime) {
      mainAnimation.currentTime = stashedTime;
    }

    if (mainAnimation !== animations[0]) {
      mainAnimation.currentTime = animations[0].currentTime;
    }

    return () => {
      if (mainAnimation === animations[0]) {
        setStashedTime(mainAnimation.currentTime as number | null);
      }
    };
  }, [animationName]);

  return ref;
};

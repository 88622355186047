import StarFalse from "assets/star-false.svg?react";
import StarTrue from "assets/star-true.svg?react";

type Props = {
  onClick: any;
  starred: boolean | null | undefined;
};

export const Star = ({ onClick, starred }: Props) => (
  <button onClick={onClick}>{starred ? <StarTrue /> : <StarFalse />}</button>
);

import { CloseIcon } from "@jutro/ui";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

type DrawerPosition = "right" | "left";

type Props = {
  isShown?: boolean;
  width?: number | string;
  onCloseComplete?: () => void;
  position?: DrawerPosition;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEscapePress?: boolean;
};

const parseWidth = (width: string | number | undefined) => {
  if (typeof width === "number") return `${width}px`;
  if (typeof width === "string") return width;
  return "initial";
};

export const SideSheet = ({
  children,
  isShown = false,
  width = undefined,
  onCloseComplete = undefined,
  position = "right",
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEscapePress = true,
}: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerZIndex, setDrawerZIndex] = useState("-z-50");
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const componentMounted = useRef(false);
  const drawerWrapperRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const drawerWidth = parseWidth(width);
  const drawerContentWidth = drawerRef.current?.offsetWidth;

  const handleOnCloseComplete = () => {
    timeoutRef.current = setTimeout(() => {
      componentMounted.current = false;
      setDrawerZIndex("-z-50");
      if (onCloseComplete instanceof Function) onCloseComplete();
    }, 250);
  };

  const handleOnCloseByButton = () => {
    setIsOpen(false);
    handleOnCloseComplete();
  };

  const handleOnCloseByOverlay = () => {
    if (shouldCloseOnOverlayClick) {
      setIsOpen(false);
      handleOnCloseComplete();
    }
  };

  const handleOnCloseByEscape = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (shouldCloseOnEscapePress && event.key.toUpperCase() === "ESCAPE") {
      setIsOpen(false);
      handleOnCloseComplete();
    }
  };

  const renderCloseIconPosition = () => {
    if (position === "right") {
      return { right: width ? drawerWidth : drawerContentWidth };
    }

    return { left: width ? drawerWidth : drawerContentWidth };
  };

  useEffect(() => {
    if (isShown && !isOpen && !componentMounted.current) {
      setIsOpen(true);
      setDrawerZIndex("z-50");
      componentMounted.current = true;
      drawerWrapperRef.current?.focus();
    }
  }, [isShown, isOpen]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      tabIndex={0}
      ref={drawerWrapperRef}
      onKeyDown={handleOnCloseByEscape}
      className={`${
        position === "right" && "drawer-end"
      } fixed top-0 h-screen w-screen ${
        !shouldCloseOnOverlayClick ? "left-[100000px]" : "left-0"
      } ${drawerZIndex}`}
    >
      <input
        id="my-drawer"
        type="checkbox"
        className="drawer-toggle"
        checked={isOpen}
        readOnly
      />
      <div className="drawer-side relative overflow-hidden">
        <label
          htmlFor="my-drawer"
          className={`drawer-overlay ${
            !shouldCloseOnOverlayClick ? "hidden" : "block"
          }`}
          style={{
            cursor: !shouldCloseOnOverlayClick ? "default" : "pointer",
          }}
          onClick={handleOnCloseByOverlay}
        >
          <span
            onClick={handleOnCloseByButton}
            className={`${
              isOpen ? "" : "hidden"
            } absolute top-0 m-3 cursor-pointer rounded-full bg-slate-800 p-2 text-white`}
            style={renderCloseIconPosition()}
          >
            <CloseIcon size="sm" />
          </span>
        </label>
        <div
          ref={drawerRef}
          className={`fixed top-0 flex h-screen flex-col gap-4 overflow-y-auto bg-white shadow-2xl ${
            position === "right" ? "right-0" : "left-0"
          }`}
          style={{ width: drawerWidth }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

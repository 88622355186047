import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button } from "@jutro/ui";
import { useAtom } from "jotai";
import { clone, equals, pickBy } from "ramda";
import { useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Block } from "components/Block";
import { ChukBaseInfoSection } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/Chuk/ChukBaseInfoSection";
import { DynamicInputsGroup } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/DynamicInputsGroup";
import { DynamicSelect } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/DynamicSelect";
import { UsgHeaderSection } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/Usg/UsgHeaderSection";
import { useCalculateChukMeasurementsBmi } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/hooks/useCalculateChukMeasurementsBmi";
import { useGenerateUsgFile } from "components/ExaminationPanel/ProcedurePanel/ProcedureElement/hooks/useGenerateUsgFile";
import { useProcedureFormValidation } from "components/ExaminationPanel/ProcedurePanel/hooks/useProcedureFormValidation";
import {
  isVisitClosedAtom,
  testsLoadingAtom,
} from "components/ExaminationPanel/atoms";
import { ProcedureWithVisit } from "components/ExaminationPanel/types";
import { FormBox } from "components/mdx/FormBox";
import { LabelText } from "components/mdx/LabelText";
import { ControlledAsyncSelect } from "components/mdx/MdxInputs/ControlledAsyncSelect";
import { ControlledCheckbox } from "components/mdx/MdxInputs/ControlledCheckbox";
import { ControlledCheckboxGroup } from "components/mdx/MdxInputs/ControlledCheckboxGroup";
import { ControlledInput } from "components/mdx/MdxInputs/ControlledInput";
import { ControlledRadio } from "components/mdx/MdxInputs/ControlledRadio";
import { ControlledScoreInput } from "components/mdx/MdxInputs/ControlledScoreInput";
import { ControlledSelect } from "components/mdx/MdxInputs/ControlledSelect";
import { ControlledTextarea } from "components/mdx/MdxInputs/ControlledTextarea";
import { FileInput } from "components/mdx/MdxInputs/FileInput";
import { ProcedureFormHeader } from "components/mdx/ProcedureFormHeader";
import { Badge } from "components/new";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { RoundedWrapper } from "components/new/RoundedWrapper";
import {
  DoctorProceduresDocument,
  useDoctorUnpinProcedureMutation,
  useDoctorUpdateProcedureMutation,
} from "lib/graphql/megaSchema";
import { procedureTitleMap } from "lib/tools/procedureTitleMap";
import { toaster } from "lib/tools/toaster";
import { TextArea } from "views/Visit/RightPanel/Visit/components/TextArea";
import { useRenderMdxForm } from "views/Visit/RightPanel/Visit/hooks/useRenderMdxForm";
import { useVisitData } from "views/Visit/hooks";

export const ProcedureElement = ({ procedure }: ProcedureWithVisit) => {
  const [updateProcedure] = useDoctorUpdateProcedureMutation();

  const { data: visit } = useVisitData({ from: "cache-only" });
  const getUsgResultsFile = useGenerateUsgFile();

  const [confirmModal, setConfirmModal] = useState(false);

  const [isVisitClosed] = useAtom(isVisitClosedAtom);
  const [testLoading] = useAtom(testsLoadingAtom);

  const { Component, formData, loading, schema } = useRenderMdxForm({
    procedureType: procedure.type,
    formType: "edit",
    visitId: visit!.id,
    procedureId: procedure.id,
    skip: testLoading,
  });

  const methods = useForm({
    mode: "onChange",
    resolver: ajvResolver(schema),
  });

  const { watch, formState, trigger } = methods;

  const {
    shouldTrigger,
    handleSetIsValid,
    unregisterForm,
    setIsProcedureLoading,
  } = useProcedureFormValidation();

  const bodyMeasurements = watch("chukMeasurements.bodyMeasurements");

  const getBmi = useCalculateChukMeasurementsBmi({
    height: bodyMeasurements?.height,
    weight: bodyMeasurements?.weight,
    type: procedure.type,
  });

  const watchedData = watch();

  const [unpinProcedure] = useDoctorUnpinProcedureMutation({
    awaitRefetchQueries: true,
    refetchQueries: [DoctorProceduresDocument],
  });

  const recentlySynchronizedDataRef = useRef<FieldValues | null>(null);

  const onSubmit = (data: any) => {
    const predicate = (val: Record<string, unknown>[] | undefined) => {
      return val?.length !== 0;
    };

    const dataInput = {
      //@ts-ignore
      ...pickBy(predicate, data),
      isEditingLocked: true,
    };

    if (dataInput.usg) {
      if (dataInput.usg.service) {
        dataInput.usg.serviceId =
          dataInput.usg?.service.foreignServiceId.toString();
      }

      delete dataInput.usg.referral;
      delete dataInput.usg.service;
    }

    updateProcedure({
      variables: {
        procedureId: procedure.id,
        dataInput,
      },
      refetchQueries: [DoctorProceduresDocument],
    }).catch(() => toaster.warning("Nie udało się zapisać procedury!"));
  };

  useEffect(() => {
    if (
      isVisitClosed ||
      !formState.isDirty ||
      equals(watchedData, recentlySynchronizedDataRef.current)
    ) {
      return;
    }

    recentlySynchronizedDataRef.current = clone(watchedData);

    setTimeout(() => {
      onSubmit(watchedData);
    }, 2000);
  }, [watchedData]);

  useEffect(() => {
    handleSetIsValid(procedure.id, true);
    if (!formData) {
      return;
    }

    Object.entries(formData).forEach(([key, values]) => {
      methods.setValue(key, values, { shouldDirty: false });
    });

    recentlySynchronizedDataRef.current = clone(watch());

    return () => {
      unregisterForm(procedure.id);
    };
  }, [formData]);

  useEffect(() => {
    (async () => {
      if (shouldTrigger) {
        const result = await trigger();
        handleSetIsValid(procedure.id, result);
      }
    })();
  }, [shouldTrigger]);

  useEffect(() => {
    setIsProcedureLoading(loading);
  }, [loading]);

  const isSynchronized = useMemo(
    () =>
      JSON.stringify(watchedData) ===
      JSON.stringify(recentlySynchronizedDataRef.current),
    [watchedData, recentlySynchronizedDataRef, Component],
  );

  if (!Component || loading) return null;

  return (
    <Block key={procedure.id}>
      <FormProvider {...methods}>
        <Component.Content
          components={{
            Badge,
            UsgHeaderSection,
            RoundedWrapper,
            ChukBaseInfoSection,
            FormBox,
            LabelText,
            ProcedureFormHeader: (props) => (
              <ProcedureFormHeader
                {...props}
                removeButtonDisabled={
                  props.removeButtonDisabled || !isSynchronized
                }
                onRemoveButtonClick={() => setConfirmModal(true)}
              />
            ),
            ControlledInput,
            ControlledRadio,
            ControlledCheckbox,
            ControlledCheckboxGroup,
            FileInput,
            ControlledTextarea,
            DynamicInputsGroup,
            ControlledSelect,
            ControlledAsyncSelect,
            ControlledScoreInput,
            TextArea,
            DynamicSelect,

            PrintUsgButton: (props) => (
              <Button
                {...props}
                full={false}
                disabled={!isSynchronized}
                loading={!isSynchronized}
                onClick={() => {
                  getUsgResultsFile(procedure.id);
                }}
              />
            ),
            RedirectToChatButton: (props) => (
              <Button
                {...props}
                full={false}
                size="regular"
                onClick={() => {
                  if (!visit?.patient?.id) {
                    return;
                  }

                  window.open(`/patients/${visit.patient.id}/chat`);
                }}
              />
            ),
          }}
          procedure={procedure}
          disabled={isVisitClosed}
          riskGroupSelectValue={watch("tuberculosisEducation.riskGroup")}
          bmi={getBmi}
        />
      </FormProvider>

      <ConfirmDialog
        title={`Czy na pewno chcesz usunąć z wizyty procedurę ${
          procedureTitleMap[procedure.type]
        }?`}
        text="Usunięta procedura pojawi się w zakładce Zlecone."
        confirmText="Usuń z listy"
        open={confirmModal}
        setOpen={setConfirmModal}
        onConfirm={async () => {
          try {
            await unpinProcedure({
              variables: {
                id: procedure.id,
              },
            });
          } catch (e) {
            toaster.warning("Nie udało się usunąć procedury medycznej");
          }
        }}
      />
    </Block>
  );
};

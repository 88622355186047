import { Button, CloseIcon } from "@jutro/ui";
import { useTileSystem } from "lib/tools/createWorkspace/useTileSystem";

export type HeaderVariant = "recent" | "regular" | "ordered";

type HeaderProps = {
  variant: HeaderVariant;
};

const nameMap: Record<HeaderVariant, string> = {
  recent: "Ostatnio wystawiane leki",
  regular: "Lista leków stałych",
  ordered: "Zamówione leki",
};

export const Header = ({ variant }: HeaderProps) => {
  const { removeTile } = useTileSystem();
  return (
    <div className="flex items-center justify-between">
      <span className="font-heading-2 text-black">{nameMap[variant]}</span>
      <Button
        full={false}
        variant="ghost"
        size="condensed"
        icon={<CloseIcon />}
        label="Zamknij"
        tooltipPosition="bottom"
        onClick={() => removeTile("visit-ordered-drugs")}
      />
    </div>
  );
};

import { envVars } from "envvars";
import { DoctorMSProcedureType } from "lib/graphql/megaSchema";

export const isAdviceProcedure = (type: DoctorMSProcedureType) => {
  return type === "DOCTOR_ADVICE" || type === "NURSE_ADVICE";
};

export const isPathMatching = (pathname: string, route: string) => {
  const regex = new RegExp(`^${route.replace(/\*/g, ".*")}$`);

  return regex.test(pathname);
};

export const isProductionEnvironment = () => {
  return envVars.REACT_APP_ENVIRONMENT === "PROD";
};

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

import { Button, DownloadIcon, SearchIcon } from "@jutro/ui";
import { envVars } from "envvars";
import { useAtom } from "jotai";
import { Checkbox, Loader } from "components/new";
import { imagePreviewAtom } from "lib/atoms";
import { workspaceTilesAtom } from "lib/tools/createWorkspace/atoms";
import { fileConfig } from "lib/tools/httpConfigs";
import { useChatImageInput } from "./ChatImageInput.hooks";

const ChatImage = ({ src, selected, onSelect, onPreview }) => (
  <div
    className="relative h-[250px] bg-cover bg-no-repeat"
    style={{ backgroundImage: `url("${src}")` }}
    onClick={onSelect}
  >
    <div className="absolute right-1 top-1">
      <Checkbox onClick={onSelect} value={selected} />
    </div>
    <div
      className="ml-1 mt-1 cursor-pointer text-white"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onPreview(src);
      }}
    >
      <SearchIcon size="sm" />
    </div>
  </div>
);

const ChatFile = ({ src, selected, onSelect, fileId }) => (
  <div className="flex w-full flex-row justify-between" onClick={onSelect}>
    <Checkbox onClick={onSelect} value={selected} label={fileId.substr(36)} />
    <a
      rel="noreferrer"
      href={src}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        label="Pobierz"
        tooltipPosition="top"
        icon={<DownloadIcon />}
        variant="ghost"
        size="condensed"
        full={false}
      />
    </a>
  </div>
);

const ChatInput = ({
  loading,
  data,
  header,
  children,
  emptyMessage,
  grid = false,
}) => (
  <div
    className={`mt-3 flex ${
      grid ? "max-h-60" : "max-h-32"
    } w-500 flex-col gap-2 overflow-y-auto`}
  >
    <div className="font-paragraph-2">{header}</div>

    <div
      className={`${
        grid ? "grid grid-cols-2 gap-3" : "flex flex-wrap gap-0.5"
      }`}
    >
      {loading && <Loader size="md" />}
      {children}
    </div>

    {!loading && !data && (
      <div className="font-paragraph-2">{emptyMessage}</div>
    )}
  </div>
);

export const ChatImageInput = ({ onSelect, selected, patientId }) => {
  const { imagesData, filesData, loading, setImagesSelected } =
    useChatImageInput(patientId, selected, onSelect);

  const [tiles] = useAtom(workspaceTilesAtom);

  const [, setImagePreview] = useAtom(imagePreviewAtom);

  if (!tiles.find((tile) => typeof tile === "string" && tile === "chat")) {
    return null;
  }

  return (
    <>
      <ChatInput
        data={imagesData}
        loading={loading}
        header="Zdjęcia z czatu"
        emptyMessage="Brak zdjęć na tym czacie"
        grid
      >
        {!loading &&
          imagesData.length !== 0 &&
          imagesData.map((fileId) => (
            <ChatImage
              key={fileId}
              src={`${fileConfig[envVars.REACT_APP_CONFIG]}` + fileId}
              selected={selected.find((e) => e === fileId)}
              onSelect={() => setImagesSelected(fileId)}
              onPreview={setImagePreview}
            />
          ))}
      </ChatInput>
      <ChatInput
        data={filesData}
        loading={loading}
        header="Pliki z czatu"
        emptyMessage="Brak plików na tym czacie"
      >
        {!loading &&
          filesData.length !== 0 &&
          filesData.map((fileId) => (
            <ChatFile
              key={fileId}
              src={`${fileConfig[envVars.REACT_APP_CONFIG]}` + fileId}
              selected={selected.find((e) => e === fileId)}
              onSelect={() => setImagesSelected(fileId)}
              fileId={fileId}
              id={fileId}
            />
          ))}
      </ChatInput>
    </>
  );
};

import { Control, Controller, FieldErrors } from "react-hook-form";
import { PaymentModalSchema } from "components/PatientDocs/Ordered/types";

type Props = {
  control: Control<PaymentModalSchema> | undefined;
  errors: FieldErrors<PaymentModalSchema>;
};

export const OtherPaymentModal = ({ control, errors }: Props) => {
  return (
    <Controller
      control={control}
      name="anotherPaymentMethod.comment"
      render={({ field: { onChange, value, ...field } }) => (
        <div>
          <textarea
            cols={30}
            rows={3}
            placeholder="Komentarz"
            className={`w-full rounded-lg border-[1px] border-jutro-new-warm-gray-100 p-2 text-xs font-normal text-jutro-new-warm-gray-800 outline-none placeholder:text-xs placeholder:font-normal placeholder:text-jutro-new-warm-gray-300 focus:border-[1px] focus:border-jutro-new-blue-800 ${
              errors.anotherPaymentMethod?.comment?.message
                ? "border-jutro-new-rose-600"
                : "border-jutro-new-warm-gray-100"
            }`}
            onChange={onChange}
            {...field}
          ></textarea>

          {errors.anotherPaymentMethod?.comment && (
            <span className="text-jutro-new-rose-600">
              {errors.anotherPaymentMethod.comment.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

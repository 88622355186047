import {
  Chart as ChartJS,
  CoreScaleOptions,
  LineElement,
  LinearScale,
  PointElement,
  Scale,
  ScriptableContext,
  TimeScale,
} from "chart.js";
import { Point } from "chart.js/dist/core/core.interaction";
import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  annotationPlugin
);

type Props = {
  min: number;
  max: number;
  dates: number[] | undefined;
  data: number[];
};

const getDataSetValue = (data: number | Point) => {
  if (typeof data !== "number") {
    return data.y;
  }

  return data;
};

const renderColor = (min: number, max: number) => {
  return (ctx: ScriptableContext<"line">) => {
    if (!min && !max) return "#ccc";
    const dataSetData = ctx.dataset.data[ctx.dataIndex];
    if (dataSetData && getDataSetValue(dataSetData) > max) return "#FF453A";
    if (dataSetData && getDataSetValue(dataSetData) < min) return "#319AD5";
    return "rgba(73, 191, 178, 0.4)";
  };
};

export const ChartBlock = ({ min, max, data, dates }: Props) => {
  const [ticksStep, setTicksStep] = useState(0);

  const minFromData = Math.min(...data);
  const maxFromData = Math.max(...data);
  const lowestMin = Math.min(minFromData, min);
  const highestMax = Math.max(maxFromData, max);

  const createTicksOnYAxis = (axis: Scale<CoreScaleOptions>) => {
    const stepSize = Math.abs(axis.ticks[0].value - axis.ticks[1].value);
    if (
      stepSize !== ticksStep &&
      (maxFromData >= axis.ticks[axis.ticks.length - 1].value ||
        minFromData <= axis.ticks[0].value)
    )
      setTicksStep(stepSize);
  };

  return (
    <Line
      width={587}
      height={224}
      data={{
        labels: dates,
        datasets: [
          {
            label: "Wartość",
            data,
            backgroundColor: "transparent",
            borderColor: "#e2e2e7",
            pointRadius: 5,
            pointBorderColor: renderColor(min, max),
            pointBackgroundColor: renderColor(min, max),
          },
        ],
      }}
      options={{
        scales: {
          y: {
            suggestedMin: lowestMin - ticksStep,
            suggestedMax: highestMax + ticksStep,
            afterBuildTicks: createTicksOnYAxis,
          },
          x: {
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "DD/MM/YYYY",
              },
            },
            bounds: "data",
            ticks: {
              source: "labels",
            },
          },
        },
        plugins: {
          annotation: {
            annotations: {
              box1: {
                type: "box",
                yMin: min,
                yMax: max,
                backgroundColor: "rgba(73, 191, 178, 0.1)",
                drawTime: "beforeDatasetsDraw",
                borderWidth: 0,
              },
            },
          },
        },
      }}
    />
  );
};

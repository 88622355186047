import { useOnClickOutside } from "@jutro/hooks";
import { useRef, useState } from "react";
import {
  DoctorNfzHistoryDocument,
  DoctorPatientInsuranceStatusDocument,
  useDoctorCheckPatientEwusStatusMutation,
} from "lib/graphql/megaSchema";
import { usePatientInsuranceStatus } from "lib/hooks/usePatientInsuranceStatus";
import { useVojevodshipOptions } from "lib/hooks/useVojevodshipOptions";
import { toaster } from "lib/tools/toaster";
import { usePatientData } from "views/Patient/hooks";

export const useEwusStatus = () => {
  const { data: patientData } = usePatientData({ from: "cache-only" });

  const [checkEwusMutation, { loading: isEwusLoading }] =
    useDoctorCheckPatientEwusStatusMutation({
      refetchQueries: [
        DoctorNfzHistoryDocument,
        DoctorPatientInsuranceStatusDocument,
      ],
    });

  const {
    insuranceStatus,
    latestCreatedAt,
    didPatientSignInsuranceStatementToday,
    loading: ewusStatusLoading,
  } = usePatientInsuranceStatus(patientData?.id);

  const { vojevodshipsWithCities } = useVojevodshipOptions();

  const [isVojevodShipMenuOpen, setIsVojevodShipMenuOpen] = useState(false);

  const vojevodShipRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(vojevodShipRef, () => setIsVojevodShipMenuOpen(false));

  const handleEwusCheck = async (vojevodship: string) => {
    if (!patientData?.pesel) {
      setIsVojevodShipMenuOpen(false);
      toaster.error("Pacjent nie posiada peselu");
      return;
    }

    if (didPatientSignInsuranceStatementToday) {
      setIsVojevodShipMenuOpen(false);
      toaster.error("Pacjent podpisał dziś oświadczenie");
      return;
    }

    await toaster.promise(
      checkEwusMutation({
        variables: { organizationId: vojevodship, patientId: patientData.id },
      }),
      {
        success: "Poprawnie sprawdzono status EWUŚ",
        error: "Nie udało się sprawdzić statusu EWUŚ",
        loading: "Sprawdzam status EWUŚ",
      },
    );

    setIsVojevodShipMenuOpen(false);
  };

  const vojevodShipMenuItems = vojevodshipsWithCities.map(
    ({ label, value }) => ({
      id: value,
      label,
      action: () => handleEwusCheck(value),
    }),
  );

  return {
    insuranceStatus,
    isEwusLoading,
    isVojevodShipMenuOpen,
    vojevodShipRef,
    setIsVojevodShipMenuOpen,
    vojevodShipMenuItems,
    latestCreatedAt,
    ewusStatusLoading,
  };
};

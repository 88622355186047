import {
  Button,
  CloseIcon,
  CollapseIcon,
  ExpandIcon,
  Input,
  LayoutLeftIcon,
  LayoutRightIcon,
  SearchIcon,
} from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { ChartBlock as Chart } from "components/Chart";
import { ExaminationItem } from "components/components/ExaminationItem";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { SideSheet } from "components/new/Drawer";
import {
  examinationDrawerPositionAtom,
  examinationDrawerWideAtom,
} from "lib/atoms";
import {
  DoctorPatientProfileVisitDocument,
  useDoctorDeleteVitalMutation,
  useDoctorUpdateVitalMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { Maybe, PatientVitality, PatientVitalityAnalysis } from "lib/types";
import { VitalDialog } from "../PatientDocs/VitalDialog";
import { SpecificInput } from "../types";
import { ParamsAnalysis } from "./ParamsAnalysis";
import { SpecificExamination } from "./SpecificExamination";
import {
  corruptedAnalysisTypeMap,
  getIconByType,
  parseParamName,
  typeMap,
} from "./utils";

type Props = {
  patientId: string;
  everyExaminationTypeSame: boolean;
  onClose: () => void;
  examinationList: PatientVitality[];
};

export const ExaminationDrawer = ({
  patientId,
  examinationList,
  onClose,
  everyExaminationTypeSame: everyExaminationTypeSameOuter,
}: Props) => {
  const everyExaminationTypeSame = useMemo(
    () => everyExaminationTypeSameOuter,
    [examinationList],
  );
  const [paramFilter, setParamFilter] = useState("");
  const [everyParamSame, setEveryParamSame] = useState(false);
  const [everyUnitSame, setEveryUnitSame] = useState(false);
  const [paramsToShow, setParamsToShow] = useState<
    PatientVitalityAnalysis["params"]
  >([]);
  const [position, setPosition] = useAtom(examinationDrawerPositionAtom);
  const [wide, setWide] = useAtom(examinationDrawerWideAtom);
  const [examination, setExamination] = useState<Maybe<PatientVitality>>(null);
  const [deleteVitalModalOpen, setDeleteVitalModalOpen] = useState(false);
  const [editVitalModeOpen, setEditVitalModeOpen] = useState(false);

  const [firstExamination] = examinationList;

  const [updateVitalMutation] = useDoctorUpdateVitalMutation({
    refetchQueries: [DoctorPatientProfileVisitDocument],
  });

  const [deleteVitalMutation, { loading }] = useDoctorDeleteVitalMutation({
    refetchQueries: [DoctorPatientProfileVisitDocument],
  });

  const updateVital = (id: string, specificInput: SpecificInput) => {
    updateVitalMutation({
      variables: { id, specificInput },
    })
      .then(() => toaster.success("Badanie edytowane!"))
      .catch(() => toaster.warning("Wystąpił błąd przy edytowaniu badania!"));
  };

  const deleteVital = (id: string) => {
    deleteVitalMutation({
      variables: { id },
    })
      .then(() => toaster.success("Badanie usuniętę!"))
      .catch(() => toaster.warning("Wystąpił błąd przy usuwaniu badania!"));
  };

  useEffect(() => {
    setParamFilter("");
  }, [examinationList]);

  useEffect(() => {
    setEveryParamSame(
      paramsToShow.every((p) => p.name === paramsToShow[0]?.name),
    );
    setEveryUnitSame(
      paramsToShow.every((p) => p.unit === paramsToShow[0]?.unit),
    );
  }, [paramsToShow, examinationList]);

  useEffect(() => {
    setParamsToShow(
      examinationList.flatMap((e) => {
        if (e.type !== "ANALYSIS") return [];
        return e.params.filter((p) =>
          parseParamName(p, e)
            .toLowerCase()
            .includes(paramFilter.toLowerCase()),
        );
      }),
    );
  }, [paramFilter, examinationList]);

  const setSideSheetWidth = () => {
    if (position === "right") {
      if (wide) return "64.7vw";
      return "32.3vw";
    }
    if (wide) return "66.91vw";
    return "34.5vw";
  };

  const renderChartData = () => {
    if (everyUnitSame)
      return paramsToShow
        .map((p) =>
          parseFloat(
            p.value.replace(",", ".").replace(">", "").replace("<", ""),
          ),
        )
        .reverse();
    return paramsToShow
      .filter((p) => p.unit === paramsToShow[1].unit)
      .map((p) =>
        parseFloat(p.value.replace(",", ".").replace(">", "").replace("<", "")),
      )
      .reverse();
  };

  return (
    <>
      <SideSheet
        position={position === "left" ? "left" : "right"}
        shouldCloseOnOverlayClick={false}
        isShown
        onCloseComplete={onClose}
        width={setSideSheetWidth()}
      >
        <div className="flex w-full justify-end gap-2 px-4 pt-2">
          <Button
            icon={
              position === "right" ? <LayoutLeftIcon /> : <LayoutRightIcon />
            }
            variant="ghost"
            size="condensed"
            onClick={() => setPosition(position === "right" ? "left" : "right")}
            label={`Przesuń w ${position === "right" ? "lewo" : "prawo"}`}
            tooltipPosition="bottom"
            full={false}
            id={position === "left" ? "left-side-sheet" : "right-side-sheet"}
          />

          <Button
            icon={wide ? <CollapseIcon /> : <ExpandIcon />}
            variant="ghost"
            size="condensed"
            onClick={() => setWide((prev) => !prev)}
            label={wide ? "Zwęź" : "Rozszerz"}
            tooltipPosition="bottom"
            full={false}
            id="change-size-side-sheet"
          />

          <Button
            icon={<CloseIcon />}
            variant="ghost"
            size="condensed"
            onClick={onClose}
            label="Zamknij"
            tooltipPosition="bottom"
            full={false}
            id="close-side-sheet"
          />
        </div>

        {examinationList
          .filter((e) => e.type === "ANALYSIS")
          .map((p) => (p.type === "ANALYSIS" ? p.params : undefined))
          .flat().length > 1 &&
          (!everyParamSame || paramFilter !== "") && (
            <div className="px-4">
              <Input
                value={paramFilter}
                onChange={(e) => setParamFilter(e.target.value)}
                sideElement={<SearchIcon size="sm" />}
                sideElementPosition="start"
                placeholder="Nazwa parametru"
              />
            </div>
          )}

        {everyExaminationTypeSame && everyParamSame && (
          <div className="-mb-10 p-4">
            <ExaminationItem
              icon={
                firstExamination.type === "ANALYSIS"
                  ? getIconByType(firstExamination.analysisType)
                  : null
              }
              examination={
                (firstExamination.type === "ANALYSIS" &&
                  corruptedAnalysisTypeMap[firstExamination.analysisType]) ||
                typeMap[firstExamination.type as keyof typeof typeMap] ||
                (firstExamination.type === "ANALYSIS" &&
                  firstExamination.analysisType)
              }
              date={null}
            />
          </div>
        )}
        <div className="flex flex-col gap-4">
          {examinationList.map((examination) => (
            <div key={examination.id} className="flex flex-col gap-4">
              {!examination.overrideFiles && examination.type === "ANALYSIS" ? (
                <ParamsAnalysis
                  examination={examination}
                  everyExaminationTypeSame={everyExaminationTypeSame}
                  everyParamSame={everyParamSame}
                  paramFilter={paramFilter}
                />
              ) : (
                <SpecificExamination
                  examination={examination}
                  setDeleteVitalModalOpen={setDeleteVitalModalOpen}
                  setEditVitalModeOpen={setEditVitalModeOpen}
                  setExamination={setExamination}
                />
              )}
            </div>
          ))}
        </div>

        <VitalDialog
          mode="update"
          data={examination}
          isOpen={editVitalModeOpen}
          setOpen={setEditVitalModeOpen}
          setCloseSlideSheet={onClose}
          submit={updateVital}
          patientId={patientId}
        />

        <div className="mt-3 px-4 py-2">
          {everyExaminationTypeSame &&
            everyParamSame &&
            paramsToShow.length > 1 && (
              <Chart
                min={
                  everyUnitSame
                    ? parseFloat(paramsToShow[0].min)
                    : parseFloat(paramsToShow[1].min)
                }
                max={
                  everyUnitSame
                    ? parseFloat(paramsToShow[0].max)
                    : parseFloat(paramsToShow[1].max)
                }
                data={renderChartData()}
                dates={examinationList
                  .filter((e) => {
                    if (e.type !== "ANALYSIS") return false;
                    return e.params.find((p) =>
                      parseParamName(p, e)
                        .toLowerCase()
                        .includes(paramFilter.toLowerCase()),
                    );
                  })
                  .map((v) => {
                    if (v.measured)
                      return new Date(v.measured.iso.split("T")[0]).getTime();
                    if (v.type === "OTHER")
                      return new Date(v.customDate.iso.split("T")[0]).getTime();
                    return new Date().getTime();
                  })
                  .reverse()}
              />
            )}
        </div>
      </SideSheet>
      <ConfirmDialog
        title="Usuń badanie"
        text="Czy na pewno chcesz usunąć badanie ?"
        confirmText="Usuń"
        loading={loading}
        open={deleteVitalModalOpen}
        setOpen={setDeleteVitalModalOpen}
        onConfirm={() => {
          if (!examination) return;
          deleteVital(examination.id);
          onClose();
        }}
      />
    </>
  );
};

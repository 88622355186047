import { Maybe } from "@jutro/types";
import { Tag } from "@jutro/ui";
import dayjs from "dayjs";
import { ComponentProps } from "react";
import {
  DoctorMSUserGenre,
  DoctorOrganizationsQuery,
  DoctorUpcomingVisitsQuery,
} from "lib/graphql/megaSchema";
import { isVisitScheduled } from "lib/tools/visit";

type Args = {
  visit: DoctorUpcomingVisitsQuery["doctorUpcomingVisits"][0];
};

type VisitTag = {
  validation: Maybe<boolean>;
  data: {
    text: string;
    color: ComponentProps<typeof Tag>["color"];
    variant?: ComponentProps<typeof Tag>["variant"];
  };
};

export const getDoctorRole = ({
  genre,
  npwz,
}: {
  genre: Maybe<DoctorMSUserGenre>;
  npwz: Maybe<string>;
}) => {
  if (!npwz) {
    return "";
  }

  if (genre === "DOCTOR") {
    return "Lekarz rodzinny";
  }

  if (genre === "NURSE" && npwz.endsWith("P")) {
    return "Pielęgniarka";
  }

  if (genre === "NURSE" && npwz.endsWith("A")) {
    return "Położna";
  }

  return null;
};

export const getVisitTags = ({ visit }: Args) => {
  const {
    type: visitType,
    slotType,
    showOn,
    plannedStart,
    snapshotAt,
    startOn,
  } = visit;

  const isScheduled = isVisitScheduled(plannedStart?.iso);

  const visitStatusTags: VisitTag[] = [
    {
      validation: !visitType,
      data: {
        text: "Kopia robocza",
        color: "black-or-white",
        variant: "inverted",
      },
    },
    {
      validation: visitType === "VIRTUAL",
      data: {
        text: "Telemedyczna",
        color: "green",
      },
    },
    {
      validation: visitType === "STANDARD",
      data: {
        text: "Osobista",
        color: "blue",
      },
    },
    {
      validation: visitType === "PROCEDURE",
      data: {
        text: "Zabiegowa",
        color: "rose",
      },
    },
    {
      validation: Boolean(snapshotAt),
      data: {
        text: "Zakończona",
        color: "green",
        variant: "inverted",
      },
    },
    {
      validation: Boolean(!snapshotAt && startOn),
      data: {
        text: "W trakcie",
        color: "blue",
        variant: "inverted",
      },
    },
    {
      validation:
        visitType && visitType === "VIRTUAL" && !snapshotAt && !startOn,
      data: {
        text: "Nierozpoczęta",
        color: "black-or-white",
        variant: "inverted",
      },
    },
    {
      validation:
        visitType !== "VIRTUAL" &&
        plannedStart &&
        isScheduled &&
        Boolean(showOn) &&
        !snapshotAt &&
        !startOn,
      data: {
        text: "Pacjent czeka",
        color: "blue",
        variant: "inverted",
      },
    },
    {
      validation: Boolean(
        !snapshotAt && !startOn && plannedStart && !isScheduled,
      ),
      data: {
        text: "Nieodwołana",
        color: "rose",
        variant: "inverted",
      },
    },
    {
      validation: visitType === "VIRTUAL",
      data: {
        text: slotType === "TELEMEDICAL_PRIVATE" ? "Poza NFZ" : "NFZ",
        color: "gray",
        variant: "inverted",
      },
    },
  ];

  return visitStatusTags
    .filter(({ validation }) => validation)
    .map(({ data }) => data);
};

export const getDate = (date: Maybe<string>) => {
  if (!date) {
    return null;
  }

  return dayjs(date).format("DD MMM YYYY (dddd, HH:mm)");
};

export const getUpcomingVisits = (
  visits: Maybe<DoctorUpcomingVisitsQuery["doctorUpcomingVisits"]>,
) => {
  if (!visits || visits.length === 0) {
    return null;
  }

  const upcomingVisits = visits.filter(({ plannedStart }) =>
    plannedStart ? dayjs().isBefore(dayjs(plannedStart.iso)) : false,
  );

  if (upcomingVisits.length === 0) {
    return null;
  }

  return upcomingVisits;
};

export const getPlaceName = ({
  organizations,
  organizationId,
}: {
  organizations: Maybe<DoctorOrganizationsQuery["doctorOrganizations"]>;
  organizationId: Maybe<string>;
}) => {
  if (!organizations || !organizationId || organizationId.length <= 2) {
    return null;
  }

  const places = organizations
    .filter(
      ({ id }) => organizationId.includes(id) && [3, 4].includes(id.length),
    )
    .map(({ displayName }) => displayName);

  return places.reverse().join(", ");
};

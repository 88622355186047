import { Dayjs } from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import {
  DoctorMSShiftOutputDynamic,
  GetDoctorShiftsDocument,
  useDoctorGetDoctorsQuery,
  useDoctorPublishShiftsMutation,
  useGetDoctorShiftsQuery,
} from "lib/graphql/megaSchema";
import {
  organizationClinicOption,
  useVojevodshipOptions,
} from "lib/hooks/useVojevodshipOptions";
import { toaster } from "lib/tools/toaster";

const sortShifts = (shifts: DoctorMSShiftOutputDynamic[] | undefined) => {
  return shifts?.sort((a, b) => {
    if (a.hourStart < b.hourStart) {
      return -1;
    }

    if (a.hourStart > b.hourStart) {
      return 1;
    }

    return 0;
  });
};

const DEFAULT_ORGANIZATION_ID = "PMWP";

const selectedOrganizationAtom = atomWithStorage(
  "selectedOrganization",
  DEFAULT_ORGANIZATION_ID,
);

type Props = {
  from: Dayjs;
  to: Dayjs;
  searchInput: string;
};

export const useScheduleCreator = ({ from, to, searchInput }: Props) => {
  const formattedStartDate = from.format("YYYY-MM-DD").toString();
  const formattedEndDate = to.add(1, "day").format("YYYY-MM-DD").toString();
  const [selectedOrganization, setSelectedOrganization] = useAtom(
    selectedOrganizationAtom,
  );

  const { data: allStaff } = useDoctorGetDoctorsQuery();
  const { organizationsClinicsOptions } = useVojevodshipOptions();

  const [PublishShifts, { loading: publishShiftsLoading }] =
    useDoctorPublishShiftsMutation();

  const {
    data: doctorShiftsData,
    loading: doctorShiftsLoading,
    error: doctorShiftsError,
  } = useGetDoctorShiftsQuery({
    variables: {
      from: formattedStartDate,
      to: formattedEndDate,
      placeId: selectedOrganization,
    },
    fetchPolicy: "network-only",
  });

  const handleOrganizationChange = (org: organizationClinicOption) => {
    setSelectedOrganization(org.value);
  };

  const handlePublishShifts = async () => {
    if (!selectedOrganization) {
      return;
    }

    const createPublishShiftsPromise = PublishShifts({
      variables: {
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
        place_id: selectedOrganization,
      },
      refetchQueries: [GetDoctorShiftsDocument],
    });

    await toaster.promise(createPublishShiftsPromise, {
      success: "Poprawnie opublikowano grafik",
      loading: "Publikuję grafik...",
      error: "Nie udało się opublikować grafiku",
    });
  };

  if (doctorShiftsError) {
    return {
      filteredMedicalStaff: [],
      medicalStaffSelectOptions: [],
      organizationsClinicsOptions,
      selectedOrganization,
      shifts: {
        data: [],
        loading: doctorShiftsLoading,
      },
      publishShiftsLoading,
      handleOrganizationChange,
      handlePublishShifts,
    };
  }

  const medicalStaffSelectOptions = allStaff?.doctorDoctors
    .filter((staff) => staff.genre === "DOCTOR" || staff.genre === "NURSE")
    .map((o) => {
      return {
        label: `${o.firstName} ${o.lastName}`,
        value: o.id,
        genre: o.genre,
        npwz: o.npwz,
      };
    });

  const filteredMedicalStaffByOrganization = medicalStaffSelectOptions?.filter(
    (staff) => doctorShiftsData?.doctorShifts.doctorIds.includes(staff.value),
  );

  const filteredMedicalStaffByOrganizationAndSearch =
    filteredMedicalStaffByOrganization?.filter((staff) =>
      staff.label
        .toLowerCase()
        .trim()
        .includes(searchInput.toLowerCase().trim()),
    );

  const sortedDoctorsShifts = sortShifts([
    ...(doctorShiftsData?.doctorShifts.shifts ?? []),
  ]);

  return {
    filteredMedicalStaff: filteredMedicalStaffByOrganizationAndSearch,
    medicalStaffSelectOptions,
    organizationsClinicsOptions,
    selectedOrganization,
    shifts: {
      data: sortedDoctorsShifts,
      loading: doctorShiftsLoading,
    },
    publishShiftsLoading,
    handleOrganizationChange,
    handlePublishShifts,
  };
};

import { ImagePreview } from "components/new/ImagePreview/ImagePreview";
import { PdfPreview } from "components/new/PdfPreview/PdfPreview";

type Props = {
  file: string;
  isPdf: boolean;
  addFileText: string;
  isModal: boolean;
  onClose: () => void;
  onAbort: () => void;
};

export const DropppedFilesPreview = ({
  file,
  isPdf,
  addFileText,
  isModal,
  onClose,
  onAbort,
}: Props) => {
  const handleClose = () => {
    onClose();
  };

  const handleAbort = () => {
    onAbort();
  };

  return (
    <div className="flex h-[80%] w-[600px] flex-col items-center justify-center gap-4 rounded-lg bg-white p-4">
      {isPdf ? (
        <PdfPreview
          file={file}
          isModal={isModal}
          addFileText={addFileText}
          handleAbort={handleAbort}
          handleClose={handleClose}
        />
      ) : (
        <ImagePreview
          file={file}
          isModal={isModal}
          addFileText={addFileText}
          handleAbort={handleAbort}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

import { DiamondArrowsIcon } from "@jutro/ui";
import { DoctorMSFacilityChangeInVoivodeshipEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../../NfzEvent";
import { EventHandler } from "../../../NfzEvent/infer";

export const FacilityChangeInVoivodeshipEventHandler: EventHandler<
  DoctorMSFacilityChangeInVoivodeshipEvent
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.DECLARATION_CHECK}
      statusText="Zmiana placówki (woj.)"
      color="orange"
      tooltip={event.nfzComment}
      icon={<DiamondArrowsIcon size="sm" />}
    />
  );
};

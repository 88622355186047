import { DiamondCheckIcon } from "@jutro/ui";
import { DoctorMSInsuranceContributionCertificateEvent } from "lib/graphql/megaSchema";
import { NfzEvent, NfzEventPrefix } from "../../NfzEvent";
import { EventHandler } from "../../NfzEvent/infer";

export const InsuranceContributionCertificateEventHandler: EventHandler<
  DoctorMSInsuranceContributionCertificateEvent
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.INSURANCE_STATUS}
      statusText="Zaświadczenie od płatnika składek"
      icon={<DiamondCheckIcon size="sm" />}
      color="green"
    />
  );
};

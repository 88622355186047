import { Option } from "@jutro/types";
import { DiamondCheckIcon } from "@jutro/ui";
import {
  DoctorMSInsuranceStatementPatientReason,
  DoctorMSPatientInsuranceStatementEvent,
} from "lib/graphql/megaSchema";
import { Maybe } from "lib/types";
import {
  NfzEvent,
  NfzEventPrefix,
} from "views/Patient/Profile/NfzEventList/NfzEvent";
import { EventHandler } from "views/Patient/Profile/NfzEventList/NfzEvent/infer";
import { groupedSelfOptions } from "views/Patient/Profile/utils";

const getReasonsOptions = () => {
  const mergedOptions: Option<DoctorMSInsuranceStatementPatientReason>[] = [];

  for (const groupedSelfOption of groupedSelfOptions) {
    const { options } = groupedSelfOption;

    mergedOptions.push(...options);
  }

  return mergedOptions;
};

export const PatientInsuranceStatementEventHandler: EventHandler<
  DoctorMSPatientInsuranceStatementEvent
> = ({ event }) => {
  return (
    <NfzEvent
      event={event}
      prefix={NfzEventPrefix.INSURANCE_STATUS}
      statusText="Podpisano oświadczenie"
      icon={<DiamondCheckIcon size="sm" />}
      color="green"
      tooltip={<TooltipContent reason={event.reason} />}
    />
  );
};

type TooltipConentProps = {
  reason: Maybe<string>;
};

const TooltipContent = ({ reason }: TooltipConentProps) => {
  const reasonsOptions = getReasonsOptions();

  const mappedReason = reasonsOptions.find(
    ({ value }) => value === reason,
  )?.label;

  return (
    <div className="flex flex-col gap-1">
      <span>{`Podstawa prawna: ${mappedReason ?? "-"}`}</span>
    </div>
  );
};

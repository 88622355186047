import { ToggleButton } from "@jutro/ui";
import { SetStateAction } from "jotai";
import { Dispatch, useEffect, useState } from "react";
import {
  IpomFormSchema,
  controlVisitTimeOptions,
  controlVisitTimeUnitOptions,
} from "components/IpomForm/schema";
import { ConfirmDialog } from "components/new/ConfirmDialog";

type VisitExecution = Omit<IpomFormSchema["controlVisits"][number], "id">;

type Props = {
  visit: IpomFormSchema["controlVisits"][number];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onExecutionChange: (visit: IpomFormSchema["controlVisits"][number]) => void;
};

export const ControlVisitExecutionModal = ({
  isOpen,
  setIsOpen,
  visit,
  onExecutionChange,
}: Props) => {
  const [executionData, setExecutionData] = useState<VisitExecution>(visit);

  useEffect(() => {
    setExecutionData(visit);
  }, [visit]);

  return (
    <ConfirmDialog
      open={isOpen}
      setOpen={(open) => {
        setIsOpen(open);
      }}
      confirmText="Zapisz"
      submitVariant="primary"
      onConfirm={() => onExecutionChange({ ...executionData, id: visit.id })}
      width="xl"
    >
      <div className="flex flex-col gap-4">
        <span className="font-label text-jutro-new-warm-gray-800">
          Termin wizyty kontrolnej
        </span>

        <div className="flex gap-4 rounded-md border border-jutro-new-warm-gray-200 p-4">
          {controlVisitTimeOptions.map(({ value, label }) => (
            <ToggleButton
              key={value}
              text={label}
              active={value === executionData.time}
              size="condensed"
              onClick={() => {
                if (value === "POWYKZLECZADAN") {
                  setExecutionData({
                    time: value,
                    timeUnit: null,
                    timeValue: null,
                  });
                  return;
                }

                setExecutionData({
                  time: value,
                  ...controlVisitTimeUnitOptions[0].value,
                });
              }}
            />
          ))}
        </div>

        {executionData.time === "POOKRCZASIE" ? (
          <>
            <span className="font-label text-jutro-new-warm-gray-800">
              Po jakim czasie?
            </span>
            <div className="flex flex-wrap gap-4 rounded-md border border-jutro-new-warm-gray-200 p-4">
              {controlVisitTimeUnitOptions.map(({ value, label }) => (
                <ToggleButton
                  key={JSON.stringify(value)}
                  text={label}
                  active={
                    value.timeUnit === executionData.timeUnit &&
                    value.timeValue === executionData.timeValue
                  }
                  size="condensed"
                  onClick={() => {
                    setExecutionData((prev) => ({
                      ...prev,
                      ...value,
                    }));
                  }}
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </ConfirmDialog>
  );
};

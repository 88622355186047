type ChatSystemProps = {
  icon: JSX.Element;
  title: any;
  subtitle: any;
  children: any;
  onClick?: any;
};

export const ChatSystem = ({
  icon,
  title,
  subtitle,
  children,
  onClick,
}: ChatSystemProps) => (
  <div
    className="font-geologica mb-4 max-w-[500px] rounded-lg border border-solid border-jutro-new-warm-gray-300 p-[10px] pt-4 text-jutro-new-blue-950"
    onClick={onClick}
  >
    <div className="float-left w-8 text-jutro-new-blue-800">{icon}</div>
    <div className="font-paragraph-2 ml-[50px]">
      <div className="font-semibold">{title}</div>
      <div dangerouslySetInnerHTML={{ __html: subtitle }} />
    </div>
    <div className="clear-both">{children}</div>
  </div>
);
